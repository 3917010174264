import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {TigerSharkConstraintTracker} from '../model/tiger-shark-constraint-tracker';

@Injectable({
	providedIn: 'root'
})
export class TigersharkConstraintTrackerService {
	private TRACKER_ENDPOINT = environment.apiUrl + 'tigerSharkConstraintTracker';
	private DASHBOARD_ENDPOINT = environment.apiUrl + 'tigerSharkConstraintTracker/dashboard';

	constructor(private http: HttpClient) {
	}

	public getConstraintIdsFromEmail(email: string): Observable<any> {
		return this.http.get(this.TRACKER_ENDPOINT + `/getConstraintIdsFromEmail/${email}`, {responseType: 'json'});
	}
	public getAllConstraintIdsForFordUser(): Observable<any> {
		return this.http.get(this.TRACKER_ENDPOINT + `/getAllConstraintIdsForFordUser`, {responseType: 'json'});
	}

	public getActualBusinessUnitFromConstraintIds(tigerSharkConstraintTracker: TigerSharkConstraintTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.TRACKER_ENDPOINT + `/getActualBusinessUnitFromConstraintId`, tigerSharkConstraintTracker, {responseType: 'json'});
	}
	// tslint:disable-next-line:max-line-length
	public getSupplierSiteCodeFromConstraintIdsAndActualBusinessUnit(tigerSharkConstraintTracker: TigerSharkConstraintTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.TRACKER_ENDPOINT + `/getSupplierSiteCodeFromConstraintIdAndActualBusinessUnit`, tigerSharkConstraintTracker, {responseType: 'json'});
	}
	// tslint:disable-next-line:max-line-length
	public getPlantCodesFromConstraintIdsAndActualBusinessUnitAndSupplierSiteCode(tigerSharkConstraintTracker: TigerSharkConstraintTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.TRACKER_ENDPOINT + `/getPlantCodesFromConstraintIdAndActualBusinessUnitAndSupplierSiteCode`, tigerSharkConstraintTracker, {responseType: 'json'});
	}

	// tslint:disable-next-line:max-line-length
	public getPartNumbersFromConstraintIdAndActualBusinessUnitAndSupplierSiteCodeAndPlantCode(tigerSharkConstraintTracker: TigerSharkConstraintTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.TRACKER_ENDPOINT + `/getPartNumbersFromConstraintIdAndActualBusinessUnitAndSupplierSiteCodeAndPlantCode`, tigerSharkConstraintTracker, {responseType: 'json'});
	}

	generateReportForSupplierUpdate(tigerSharkConstraintTracker: TigerSharkConstraintTracker) {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.DASHBOARD_ENDPOINT + `/generateReportForSupplierUpdate`, tigerSharkConstraintTracker, {responseType: 'blob'});
	}
	public getConstraintIdsFromEmailV2(tigerSharkConstraintTracker: TigerSharkConstraintTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.TRACKER_ENDPOINT + `/getActiveConstraintsFromSupplierEmail`, tigerSharkConstraintTracker, {responseType: 'json'});
	}
	getSupplierSiteCodes(tigerSharkConstraintTracker: TigerSharkConstraintTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.TRACKER_ENDPOINT + `/getSupplierSiteCodes`, tigerSharkConstraintTracker, {responseType: 'json'});
	}
	getPlantCodes(tigerSharkConstraintTracker: TigerSharkConstraintTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.TRACKER_ENDPOINT + `/getPlantCodes`, tigerSharkConstraintTracker, {responseType: 'json'});
	}
	getPartNumbers(tigerSharkConstraintTracker: TigerSharkConstraintTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.TRACKER_ENDPOINT + `/getPartNumbers`, tigerSharkConstraintTracker, {responseType: 'json'});
	}
	generateWppReport(tigerSharkConstraintTracker: TigerSharkConstraintTracker) {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.DASHBOARD_ENDPOINT + `/generateWppReport`, tigerSharkConstraintTracker, {responseType: 'blob'});
	}
	generatePreviousWeekWppReport(tigerSharkConstraintTracker: TigerSharkConstraintTracker) {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.DASHBOARD_ENDPOINT + `/generatePreviousWeekWppReport`, tigerSharkConstraintTracker, {responseType: 'blob'});
	}
}
