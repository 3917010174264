import {
	Component,
	OnInit
} from '@angular/core';
import {Router} from '@angular/router';
import {SupplierService} from '../../../home/services/supplier.service';
import {
	defaultDropDown,
	DropDown
} from '../model/drop-down';
import {
	FormBuilder,
	FormGroup,
	Validators
} from '@angular/forms';
import {Tier1EComponentShortageTrackerService} from '../service/tier1-e-component-shortage-tracker.service';
import {CustomDialogComponent} from '../../../custom-dialog/custom-dialog/custom-dialog.component';
import {
	DialogService,
	DynamicDialogRef,
	MessageService
} from 'primeng/api';

@Component({
	selector: 'drop-down-configuration',
	templateUrl: './drop-down-configuration.component.html',
	styleUrls: ['./drop-down-configuration.component.scss']
})
export class DropDownConfigurationComponent implements OnInit {

	private response: any;
	standardModuleNames: DropDown[] = [];
	semiConductorSuppliers: DropDown[] = [];
	selectedStandardModuleNames: DropDown[] = [];
	selectedSemiConductorSuppliers: DropDown[] = [];
	dropDownForm: FormGroup;
	display = false;
	displayDeleteDialog = false;
	dropDownObject: DropDown;
	isCreateOperation = false;
	isUpdateOperation = false;
	dropDownColumnName1 = 'Standard Module Name';
	dropDownColumnName2 = 'Semi Conductor Supplier';
	ref: DynamicDialogRef;
	formFieldName: any;
	isDisplayStandardModuleNameFilterData = false;
	isDisplaySemiConductorSupplierFilterData = false;
	deleteObject: DropDown;
	isMultiDelete = false;
	deletionDropDownColumnName: string;
	// tslint:disable-next-line:max-line-length
	constructor(private supplierService: SupplierService, private router: Router, private fb: FormBuilder, private tier1EComponentShortageService: Tier1EComponentShortageTrackerService, private dialogService: DialogService, private messageService: MessageService) {
	}

	ngOnInit() {

		this.router.navigate(['/']);
		return;

		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
			return;
		} else if (sessionStorage.getItem('uid').includes('-')) {
			this.router.navigate(['/']);
			return;
		}
		// this.supplierService.pushSelectedSurvey('SemiSupp');
		this.dropDownForm = this.fb.group({
			dropDownValue: ['', Validators.required]
		});
		// this.findAllDropDownData();
	}

	findAllDropDownData() {
		this.tier1EComponentShortageService.findAllDropDownData(this.dropDownColumnName1).subscribe((data) => {
			this.standardModuleNames = [];
			this.standardModuleNames = data;
		});
		this.tier1EComponentShortageService.findAllDropDownData(this.dropDownColumnName2).subscribe((data) => {
			this.semiConductorSuppliers = [];
			this.semiConductorSuppliers = data;
		});
	}
	adminScreen() {
		this.router.navigate(['/tier1-e-component-shortage-admin']);
	}

	enableCreateNewDataDialog(dropDownColumnName: string) {
		this.dropDownForm.reset();
		this.isCreateOperation = true;
		this.isUpdateOperation = false;
		this.dropDownObject = new DropDown(defaultDropDown);
		this.dropDownObject.columnName = dropDownColumnName;
		this.formFieldName = dropDownColumnName;
		this.display = true;
	}

	createData() {
		this.dropDownObject.dropDownValue = this.dropDownForm.get('dropDownValue').value;
		if (this.validateData(this.dropDownObject)) {
			this.tier1EComponentShortageService.createDropDownData(this.dropDownObject).subscribe((data) => {
				this.response = data;
				console.log(this.response);
				if (JSON.parse(this.response.success)) {
					this.display = false;
					this.pushDialog(this.response.message);
					this.findAllDropDownData();
				} else {
					this.pushDialog(this.response.message);
				}
			});
		} else {
			this.pushMessage('error', 'Error', 'Please fill the mandatory field');
		}
		}

	enableUpdateDataDialog(product: any, dropDownColumnName: string) {
		this.dropDownForm.reset();
		this.formFieldName = dropDownColumnName;
		this.isCreateOperation = false;
		this.isUpdateOperation = true;
		this.dropDownObject = new DropDown(defaultDropDown);
		this.dropDownObject.id = product['id'];
		this.dropDownObject.columnName = product['columnName'];
		this.dropDownObject.dropDownValue = product['dropDownValue'];
		this.dropDownObject.createdBy = product['createdBy'];
		this.dropDownObject.createdDate = product['createdDate'];
		this.dropDownObject.updatedBy = product['updatedBy'];
		this.dropDownObject.updatedDate = product['updatedDate'];
		this.dropDownForm.patchValue({dropDownValue: product['dropDownValue']});
		this.display = true;
	}
	updateData() {
		this.dropDownObject.dropDownValue = this.dropDownForm.get('dropDownValue').value;
		if (this.validateData(this.dropDownObject)) {
			this.tier1EComponentShortageService.updateDropDownData(this.dropDownObject).subscribe((data) => {
				this.response = data;
				console.log(this.response);
				if (JSON.parse(this.response.success)) {
					this.display = false;
					this.pushDialog(this.response.message);
					this.findAllDropDownData();
				} else {
					this.pushDialog(this.response.message);
				}
			});
		} else {
			this.pushMessage('error', 'Error', 'Please fill the mandatory field');
		}
	}
	deleteData(product: any) {
		this.tier1EComponentShortageService.deleteDropDownData(product['id']).subscribe((data) => {
			this.response = data;
			console.log(this.response);
			if (JSON.parse(this.response.success)) {
				this.pushDialog(this.response.message);
				this.findAllDropDownData();
			} else {
				this.pushDialog(this.response.message);
			}
		});
	}

	deleteSelectedData(dropDownColumnName: string) {
		let selectedDropDownData: any[];
		if (dropDownColumnName === this.dropDownColumnName1) {
			selectedDropDownData = this.selectedStandardModuleNames;
		} else {
			selectedDropDownData = this.selectedSemiConductorSuppliers;
		}
		if (selectedDropDownData.length > 0) {
			const idList = [];
			for (const item of selectedDropDownData) {
				idList.push(item.id);
			}
			this.tier1EComponentShortageService.deleteDropDownData(idList.join(',')).subscribe((data) => {
				this.response = data;
				console.log(this.response);
				if (JSON.parse(this.response.success)) {
					this.pushDialog(this.response.message);
					this.selectedStandardModuleNames = [];
					this.selectedSemiConductorSuppliers = [];
					this.findAllDropDownData();
				} else {
					this.pushDialog(this.response.message);
				}
			});
		}
	}
	disableDialog() {
		this.display = false;
		this.dropDownForm.reset();
	}
	pushDialog(dialogBody: any) {
		this.ref = this.dialogService.open(CustomDialogComponent, {
			header: 'Drop Down Config - Response',
			footer: '',
			data: dialogBody,
			width: '50%',
			contentStyle: { 'max-height': '1000px',
				overflow: 'auto' },
			baseZIndex: 10000,
		});
	}
	validateData(dropDownObject: DropDown): boolean {
		return dropDownObject.dropDownValue !== null && dropDownObject.dropDownValue.length > 0;
	}
	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 60000
		});
	}
	showStandardModuleNameDataFilters() {
		this.isDisplayStandardModuleNameFilterData = !this.isDisplayStandardModuleNameFilterData;
	}
	showSemiConductorSupplierDataFilters() {
		this.isDisplaySemiConductorSupplierFilterData = !this.isDisplaySemiConductorSupplierFilterData;
	}
	closeDialog() {
		this.displayDeleteDialog = false;
	}
	deleteConfirmationDialog(product: any) {
		this.isMultiDelete = false;
		this.displayDeleteDialog = true;
		this.deleteObject = product;
	}
	multiDeleteConfirmationDialog(dropDownColumnName: string) {
		this.deletionDropDownColumnName = dropDownColumnName;
		this.isMultiDelete = true;
		this.displayDeleteDialog = true;
	}
	proceedDeletion() {
		this.displayDeleteDialog = false;
		if (this.isMultiDelete) {
			this.deleteSelectedData(this.deletionDropDownColumnName);
		} else {
			this.deleteData(this.deleteObject);
		}
	}
}
