import {Component, OnInit, ViewChild} from '@angular/core';
import {T2Visibility} from '../model/t2_visibility';
import {T2VisibilityService} from '../services/t2-visibility.service';
import {ExcelUtility} from '../../tiger-shark-constraint-tracker/model/excel-utility';
import {IgxSpreadsheetComponent} from 'igniteui-angular-spreadsheet';
import {Sheet, Workbook, WorkbookSaveOptions} from 'igniteui-angular-excel';
import {MessageService} from 'primeng/api';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {SupplierService} from '../../../home/services/supplier.service';
import {T2DataRefreshService} from "../services/t2-data-refresh.service";
import {Router} from "@angular/router";

@Component({
	selector: 't2-visibility-administrator',
	templateUrl: './t2-visibility-administrator.component.html',
	styleUrls: ['./t2-visibility-administrator.component.scss']
})
export class T2VisibilityAdministratorComponent implements OnInit {
	private fileFromDatabase: any;
	submitStatus = true;
	businessName: any;
	t2vDataRefreshflag: boolean;

	// tslint:disable-next-line:max-line-length
	constructor(private supplierService: SupplierService, private t2VisibilityService: T2VisibilityService, private messageService: MessageService, private spinnerService: SpinnerService, private t2vDataRefreshService: T2DataRefreshService,private router: Router ) {
	}

	@ViewChild('spreadsheet', {static: false, read: IgxSpreadsheetComponent})
	public spreadsheet: IgxSpreadsheetComponent;

	ngOnInit() {
		 this.businessName = sessionStorage.getItem('Admin');
		this.router.navigate(['/']);
		if (this.businessName === 'T2Visibility' || this.businessName === null) {
			this.router.navigate(['/']);
			return;
		}

		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
			return;
		} else if (sessionStorage.getItem('uid').includes('-')) {
			this.router.navigate(['/']);
			return;
		}
		if (this.businessName === 'T2Visibility') {
			console.log('T2Visibility');
			// this.supplierService.pushSelectedSurvey('T2Visibility');
		} else if (this.businessName === 'T2Visibility Data Refresh') {
			console.log('T2Visibility Data Refresh');
			this.t2vDataRefreshflag = true;
			// this.supplierService.pushSelectedSurvey('T2Visibility Data Refresh');
		}
	}

	public openFile(input: any): void {
		this.spinnerService.show();
		if (input.files == null || input.files.length === 0) {
			return;
		}

		if(this.businessName === 'T2Visibility Data Refresh'){

			ExcelUtility.load(input.files[0]).then((w) => {

				const workbook = w;
				if ((workbook.worksheets(0).rows(3).cells(0).value) === 'T1 Site GSDB (5-digit code)'
					&& (workbook.worksheets(0).rows(3).cells(1).value) === 'Alternate Supplier Email Ids (Multiple Email Ids to be seperated by Semicolon (;))'
					&& (workbook.worksheets(0).rows(3).cells(2).value) === 'CC Emails Ids (Multiple Email Ids to be seperated by Semicolon (;))') {
					this.submitStatus = false;
					this.messageService.add({
						severity: 'success',
						summary: 'Success',
						detail: 'Uploaded',
						life: 300000
					});
					this.spreadsheet.workbook = w;
					this.spreadsheet.activeWorksheet.protect();
					this.spinnerService.hide();
				} else {
					this.spinnerService.hide();
					this.messageService.add({
						severity: 'error',
						summary: 'Error',
						detail: 'Format Mismatch Please Check',
						life: 300000
					});
				}
				console.log(this.spreadsheet.workbook.worksheets('Sheet1')._r);
			}, (e) => {
				console.error('Workbook Load Error:' + e);
				this.spinnerService.hide();
			});

		}else {
			ExcelUtility.load(input.files[0]).then((w) => {

				const workbook = w;
				if ((workbook.worksheets(0).rows(5).cells(0).value) === 'Parent Code'
					&& (workbook.worksheets(0).rows(5).cells(1).value) === 'Parent Name'
					&& (workbook.worksheets(0).rows(5).cells(2).value) === 'Ship Site Code'
					&& (workbook.worksheets(0).rows(5).cells(3).value) === 'Ship Site Name'
					&& (workbook.worksheets(0).rows(5).cells(4).value) === 'Ship Site Country'
					&& (workbook.worksheets(0).rows(5).cells(5).value) === 'Production Supplier Delivery Contact Ph. No'
					&& (workbook.worksheets(0).rows(5).cells(6).value) === 'Production Supplier Delivery Contact Mobile Ph. No'
					&& (workbook.worksheets(0).rows(5).cells(7).value) === 'Production Supplier Delivery Contact Email'
					&& (workbook.worksheets(0).rows(5).cells(8).value) === 'Corporate Delivery Contact'
					&& (workbook.worksheets(0).rows(5).cells(9).value) === 'Corporate Delivery Contact Ph. No'
					&& (workbook.worksheets(0).rows(5).cells(10).value) === 'Corporate Delivery Contact Mobile Ph. No'
					&& (workbook.worksheets(0).rows(5).cells(11).value) === 'Corporate Delivery Email'
					&& (workbook.worksheets(0).rows(5).cells(12).value) === 'Plant Manager'
					&& (workbook.worksheets(0).rows(5).cells(13).value) === 'Plant Manager Ph. No'
					&& (workbook.worksheets(0).rows(5).cells(14).value) === 'Plant Manager Mobile Ph. No'
					&& (workbook.worksheets(0).rows(5).cells(15).value) === 'Plant Manager Email'
					&& (workbook.worksheets(0).rows(5).cells(16).value) === 'MP&L Manager'
					&& (workbook.worksheets(0).rows(5).cells(17).value) === 'MP&L Manager Ph. No'
					&& (workbook.worksheets(0).rows(5).cells(18).value) === 'MP&L Manager Mobile Ph. No'
					&& (workbook.worksheets(0).rows(5).cells(19).value) === 'MP&L Manager Email'
					&& (workbook.worksheets(0).rows(5).cells(20).value) === 'Account Manager 1 Contact'
					&& (workbook.worksheets(0).rows(5).cells(21).value) === 'Account Manager 1 Contact Ph. No'
					&& (workbook.worksheets(0).rows(5).cells(22).value) === 'Account Manager 1 Contact Mobile Ph. No'
					&& (workbook.worksheets(0).rows(5).cells(23).value) === 'Account Manager 1 Contact Email'
					&& (workbook.worksheets(0).rows(5).cells(24).value) === 'Account Manager 2 Contact'
					&& (workbook.worksheets(0).rows(5).cells(25).value) === 'Account Manager 2 Contact Ph. No'
					&& (workbook.worksheets(0).rows(5).cells(26).value) === 'Account Manager 2 Contact Mobile Ph. No'
					&& (workbook.worksheets(0).rows(5).cells(27).value) === 'Account Manager 2 Contact Email'
				) {
					this.submitStatus = false;
					this.messageService.add({
						severity: 'success',
						summary: 'Success',
						detail: 'Uploaded',
						life: 100000
					});
					this.spreadsheet.workbook = w;
					this.spreadsheet.activeWorksheet.protect();
					this.spinnerService.hide();
				} else {
					this.messageService.add({
						severity: 'error',
						summary: 'Error',
						detail: 'Format Mismatch Please Check',
						life: 100000
					});
				}
				console.log(this.spreadsheet.workbook.worksheets('Sheet1')._r);
				this.spinnerService.hide();
			}, (e) => {
				console.error('Workbook Load Error:' + e);
				this.spinnerService.hide();
			});
		}
	}

	saveSpreadsheet() {

		if(this.businessName === 'T2Visibility Data Refresh'){
			this.saveSpreadsheetDataRefresh();
		}else{
			const opt = new WorkbookSaveOptions();
			opt.type = 'blob';
			const workbook = this.spreadsheet.workbook;
			workbook.save(opt, (d) => {
				this.t2VisibilityService.uploadAdminFile(d as File).subscribe((data) => {
						this.fileFromDatabase = data;
						ExcelUtility.load(this.fileFromDatabase).then((w) => {
							this.spreadsheet.workbook = w;
							this.spreadsheet.activeWorksheet.protect();
							console.log('Excel Loaded');
						});
						this.messageService.add({
							severity: 'success',
							summary: 'Success',
							detail: 'Saved',
							life: 100000
						});
						this.submitStatus = true;
					},
					error => {
						this.messageService.add({
							severity: 'error',
							summary: 'Error',
							detail: 'Try again',
							life: 100000
						});
					}
				);
			}, (e) => {
				// this.pushMessage('error', 'Error', 'Unable to Update Data, Please try again later');
			});
		}
	}

	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 100000
		});
	}
	saveSpreadsheetDataRefresh() {
		const opt = new WorkbookSaveOptions();
		opt.type = 'blob';
		const workbook = this.spreadsheet.workbook;
		workbook.save(opt, (d) => {
			const fileExt = ExcelUtility.getExtension(workbook.currentFormat);

			this.t2vDataRefreshService.uploadAdminDetails(d as File).subscribe((data) => {
					this.pushMessage('success', 'success',
						'Data Updated Successfully!');
					// this.isSubmitVisible = false;
					this.spreadsheet.workbook = new Workbook();
					// const s: Sheet = null;
					// this.spreadsheet.workbook.sheets().add(s);
					// this.router.navigate(['t2-visibility-data-refresh']);
				// this.generateReport(false);
				},
				error => {
					this.pushMessage('error', 'error',
						'Unable to Update Data, Please try again later');
				}
			);
		}, (e) => {
			this.pushMessage('error', 'Error', 'Unable to Update Data, Please try again later');
		});
	}

	AlternativeEmailConfig(){
		// console.log('Navigate to email Config');
		// console.log(sessionStorage.getItem('Admin'));
		this.router.navigate(['/t2v-data-refresh-email-config']);
	}

	generateReport(flag: boolean) {
		if(this.businessName === 'T2Visibility Data Refresh'){
			this.t2vDataRefreshService.getDataRefreshAdminReport().subscribe((data) => {
				this.spinnerService.show();
				this.fileFromDatabase = data;
				if(flag){
					this.t2vDataRefreshService.downLoadFileDocument(this.fileFromDatabase,'text/csv', 'T2_Visibility_Data_Refresh_SuperG')
				}else {
					ExcelUtility.load(this.fileFromDatabase).then((w) => {
						this.spreadsheet.workbook = w;
						// this.spreadsheet.activeWorksheet.protect();
						console.log('Excel Loaded');
						this.spinnerService.hide();
					});
				}
				this.spinnerService.hide();
				this.submitStatus = false;
			});
		}else {
			this.t2VisibilityService.downloadAdministratorDetailsExcel().subscribe((data) => {
				this.spinnerService.show();
				this.fileFromDatabase = data;
				ExcelUtility.load(this.fileFromDatabase).then((w) => {
					this.spreadsheet.workbook = w;
					this.spreadsheet.activeWorksheet.protect();
					console.log('Excel Loaded');
					this.spinnerService.hide();
				});
				this.submitStatus = false;
			});
		}
	}

}
