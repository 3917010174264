import {Injectable} from '@angular/core';
import {ClearToBuildScheduler} from '../model/clear-to-build-scheduler';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class ClearToBuildSacService {
	private SAC_SERVICE_ENDPOINT = environment.apiUrl + 'clearToBuildSac';

	constructor(private http: HttpClient) {
	}

	public adhocPull(scheduler: ClearToBuildScheduler): Observable<any> {
		return this.http.post(this.SAC_SERVICE_ENDPOINT + `/adhocPull`, scheduler);
	}
}
