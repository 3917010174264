import {Component, OnInit} from '@angular/core';
import {Tier1EComponentShortageTrackerService} from '../service/tier1-e-component-shortage-tracker.service';
import {SupplierService} from '../../../home/services/supplier.service';
import {Router} from '@angular/router';

@Component({
	selector: 'tier1-ecomponenet-shortage-help',
	templateUrl: './tier1-ecomponenet-shortage-help.component.html',
	styleUrls: ['./tier1-ecomponenet-shortage-help.component.scss']
})
export class Tier1EComponenetShortageHelpComponent implements OnInit {

	// tslint:disable-next-line:max-line-length
	constructor(private tier1EComponentShortageTrackerService: Tier1EComponentShortageTrackerService, private supplierService: SupplierService, private router: Router) {
	}
	admin: string;
	ngOnInit() {
		this.router.navigate(['/']);
		// this.supplierService.pushSelectedSurvey('SemiSupp');
		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
		}
	}

	download(contentType: string, num: number) {
		if (num === 1) {
			this.tier1EComponentShortageTrackerService.helpEndPointDownload(num).subscribe((data) => {
				this.tier1EComponentShortageTrackerService.downloadFileMp4(data, contentType, 'E Component Shortage Tracker Tutorial');
			});
		} else if (num === 2) {
			this.tier1EComponentShortageTrackerService.helpEndPointDownload(num).subscribe((data) => {
				this.tier1EComponentShortageTrackerService.downLoadFileDocument(data, contentType, 'E Component Supplier Training Document');
			});

		} else if (num === 3) {
			this.tier1EComponentShortageTrackerService.helpEndPointDownload(num).subscribe((data) => {
				this.tier1EComponentShortageTrackerService.downLoadFileDocument(data, contentType, 'FAQs');
			});

		}
	}
}
