import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UrlConsumerService} from './oauth/url-consumer.service';
import {OAuthCallbackComponent} from './oauth/oauth-callback.component';
import {LoginComponent} from './oauth/login.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {HelpComponent} from './help/help.component';
import {HomeComponent} from './home/home.component';
import {LandingComponent} from './landing/landing.component';
import {AuthGuardService} from './oauth/auth-guard.service';
import {BrowserIncompatableComponent} from './browser-incompatable/browser-incompatable.component';
// tslint:disable-next-line:max-line-length
import {Tier1EComponentShortageComponent} from './tier1-visibility-survey/tier1-e-component-shortage/tier1-e-component-shortage/tier1-e-component-shortage.component';
// tslint:disable-next-line:max-line-length
import {Tier1EComponentShortageAdminComponent} from './tier1-visibility-survey/tier1-e-component-shortage/tier1-e-component-shortage-admin/tier1-e-component-shortage-admin.component';
// tslint:disable-next-line:max-line-length
import {Tier1EComponentShortageDashboardComponent} from './tier1-visibility-survey/tier1-e-component-shortage/tier1-e-component-shortage-dashboard/tier1-e-component-shortage-dashboard.component';
// tslint:disable-next-line:max-line-length
import {SmashedDataDashboardComponent} from './tier1-visibility-survey/smashed-data-reporting/smashed-data-dashboard/smashed-data-dashboard.component';
import {NTierMasterAdminComponent} from './tier1-visibility-survey/n-tier-master-admin/n-tier-master-admin/n-tier-master-admin.component';
import {NoAccessForNTierComponent} from './tier1-visibility-survey/no-access-for-n-tier/no-access-for-n-tier.component';
// tslint:disable-next-line:max-line-length
import {TigerSharkConstraintTrackerComponent} from './tier1-visibility-survey/tiger-shark-constraint-tracker/tiger-shark-constraint-tracker/tiger-shark-constraint-tracker.component';
// tslint:disable-next-line:max-line-length
import {TigerSharkConstraintTrackerAdminComponent} from './tier1-visibility-survey/tiger-shark-constraint-tracker/tiger-shark-constraint-tracker-admin/tiger-shark-constraint-tracker-admin.component';
// tslint:disable-next-line:max-line-length
import {ValueStreamDataCollectionComponent} from './tier1-visibility-survey/value-stream-map/value-stream-data-collection-admin/value-stream-data-collection.component';
// tslint:disable-next-line:max-line-length
import {ValueStreamDataCollectionStepTwoComponent} from './tier1-visibility-survey/value-stream-map/value-stream-data-collection-two/value-stream-data-collection-two.component';
// tslint:disable-next-line:max-line-length
import {TigerSharkConstraintTrackerDashboardComponent} from './tier1-visibility-survey/tiger-shark-constraint-tracker/tiger-shark-constraint-tracker-dashboard/tiger-shark-constraint-tracker-dashboard.component';
// tslint:disable-next-line:max-line-length
// tslint:disable-next-line:max-line-length
import {ValueStreamDataCollectionUserComponent} from './tier1-visibility-survey/value-stream-map/value-stream-data-collection-user/value-stream-data-collection-user.component';
// tslint:disable-next-line:max-line-length
import {ValueStreamDataAdminColumnAccessComponent} from './tier1-visibility-survey/value-stream-map/value-stream-data-admin-column-access/value-stream-data-admin-column-access.component';
// tslint:disable-next-line:max-line-length
import {ValueStreamAdminColumnTemplateComponent} from './tier1-visibility-survey/value-stream-map/value-stream-admin-column-template/value-stream-admin-column-template.component';
// tslint:disable-next-line:max-line-length
import {SchedularComponent} from './tier1-visibility-survey/tiger-shark-constraint-tracker/schedular/schedular.component';
// tslint:disable-next-line:max-line-length
import {ValueStreamUserReportComponent} from './tier1-visibility-survey/value-stream-map/value-stream-user-report/value-stream-user-report.component';
// tslint:disable-next-line:max-line-length
import {ValueStreamAuditReportComponent} from './tier1-visibility-survey/value-stream-map/value-stream-audit-report/value-stream-audit-report.component';
// tslint:disable-next-line:max-line-length
import {ValueStreamDataReportApprovalComponent} from './tier1-visibility-survey/value-stream-map/value-stream-data-report-approval/value-stream-data-report-approval.component';
import {T2VisibilityComponent} from './tier1-visibility-survey/t2-visibility/t2-visibility-spreadsheet/t2-visibility.component';
import {LogoutComponent} from './logout/logout.component';
// tslint:disable-next-line:max-line-length
import {T2VisibilityDashboardComponent} from './tier1-visibility-survey/t2-visibility/t2-visibility-dashboard/t2-visibility-dashboard.component';
// tslint:disable-next-line:max-line-length
// tslint:disable-next-line:max-line-length
import {T2VisibilityAdministratorComponent} from './tier1-visibility-survey/t2-visibility/t2-visibility-administrator/t2-visibility-administrator.component';
import {QlikSenseDashboardComponent} from './qlik-sense-dashboard/qlik-sense-dashboard.component';
// tslint:disable-next-line:max-line-length
import {Tier1EComponenetShortageHelpComponent} from './tier1-visibility-survey/tier1-e-component-shortage/tier1-ecomponenet-shortage-help/tier1-ecomponenet-shortage-help.component';
// tslint:disable-next-line:max-line-length
// tslint:disable-next-line:max-line-length
import {T2VisibilityHelpTabComponent} from './tier1-visibility-survey/t2-visibility/t2-visibility-help-tab/t2-visibility-help-tab.component';
// tslint:disable-next-line:max-line-length
import {DropDownConfigurationComponent} from './tier1-visibility-survey/tier1-e-component-shortage/drop-down-configuration/drop-down-configuration.component';
// tslint:disable-next-line:max-line-length
import {SemiconductorSupplierSetupComponent} from './tier1-visibility-survey/tier1-e-component-shortage/semiconductor-supplier-setup/semiconductor-supplier-setup.component';
// tslint:disable-next-line:max-line-length
import {ECompShortageSemiconductorSupplierComponent} from './tier1-visibility-survey/tier1-e-component-shortage/e-comp-shortage-semiconductor-supplier/e-comp-shortage-semiconductor-supplier.component';
import {
	T2VisibilityAdminComponent
} from './tier1-visibility-survey/t2-visibility/t2-visibility-admin/t2-visibility-admin.component';
import {
	VsmUserTemplateDataUpdateComponent
} from './tier1-visibility-survey/value-stream-map/vsm-user-template-data-update/vsm-user-template-data-update.component';
import {
	SustainabilitySupplierAccessComponent
} from './tier1-visibility-survey/sustainability/sustainability-supplier-access/sustainability-supplier-access.component';
import {
	T2VisibilityEmailComponent
} from './tier1-visibility-survey/t2-visibility/t2-visibility-email/t2-visibility-email.component';
import {
	SustainabilitySurveyComponent
} from './tier1-visibility-survey/sustainability/sustainability-survey/sustainability-survey.component';
import {ClearToBuildAdminComponent} from './tier1-visibility-survey/clear-to-build/clear-to-build-admin/clear-to-build-admin.component';
// tslint:disable-next-line:max-line-length
import {ClearToBuildTrackerComponent} from './tier1-visibility-survey/clear-to-build/clear-to-build-tracker/clear-to-build-tracker.component';
import {ClearToBuildHelpComponent} from './tier1-visibility-survey/clear-to-build/clear-to-build-help/clear-to-build-help.component';
import {
	SustainabilitySurveyHelpTabComponent
} from './tier1-visibility-survey/sustainability/sustainability-survey-help-tab/sustainability-survey-help-tab.component';
// tslint:disable-next-line:max-line-length
import {ClearToBuildSchedulerComponent} from './tier1-visibility-survey/clear-to-build/clear-to-build-scheduler/clear-to-build-scheduler.component';
// tslint:disable-next-line:max-line-length
import {CtbAdminDropDownConfigurationComponent} from './tier1-visibility-survey/clear-to-build/ctb-admin-drop-down-configuration/ctb-admin-drop-down-configuration.component';
import {
	NonMicrochipTemplateComponent
} from "./tier1-visibility-survey/sustainability/non-microchip-template/non-microchip-template.component";
import {
	T2vDataRefreshAlternativeEmailConfigComponent
} from "./tier1-visibility-survey/t2-visibility/t2v-data-refresh-alternative-email-config/t2v-data-refresh-alternative-email-config.component";
import {
	T2VisibilityDataRefreshSpreedsheetComponent
} from "./tier1-visibility-survey/t2-visibility/t2-visibility-data-refresh-spreedsheet/t2-visibility-data-refresh-spreedsheet.component";
import {
	T2VisibilityDataRefreshComponent
} from "./tier1-visibility-survey/t2-visibility/t2-visibility-data-refresh/t2-visibility-data-refresh.component";
import {
	T2VDataRefreshHelpComponent
} from "./tier1-visibility-survey/t2-visibility/t2v-data-refresh-help/t2v-data-refresh-help.component";
import {
	T2VisibilityDataRefreshRecordEnrichmentComponent
} from "./tier1-visibility-survey/t2-visibility/t2-visibility-data-refresh-record-enrichment/t2-visibility-data-refresh-record-enrichment.component";
const routes: Routes = [
	{
		path: 'access_token',
		component: OAuthCallbackComponent,
		canActivate: [UrlConsumerService]
	},
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: 'dashboard/:mode',
		component: DashboardComponent
	},
	{
		path: 'home/:mode',
		component: HomeComponent
	},
	{
		path: 't2-visibility',
		component: T2VisibilityComponent,
		canActivate: [AuthGuardService]
	}, {
		path: 't2-visibility-help',
		component: T2VisibilityHelpTabComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 't2v-data-refresh-help',
		component: T2VDataRefreshHelpComponent,
		canActivate: [AuthGuardService]
	},{
		path: 'help-tab',
		component: SustainabilitySurveyHelpTabComponent,
		canActivate: [AuthGuardService]
	}, {
		path: 'sustainability-survey',
		component: SustainabilitySurveyComponent,
		canActivate: [AuthGuardService]
	}, {
		path: 'project-r-non-microchip',
		component: SustainabilitySurveyComponent,
		canActivate: [AuthGuardService]
	},{
		path: 'non-microchip-template',
		component: NonMicrochipTemplateComponent,
		canActivate: [AuthGuardService]
	}, {
		path: 'email-setup',
		component: T2VisibilityEmailComponent,
		canActivate: [AuthGuardService]
	}, {
		path: 't2-visibility-email',
		component: T2VisibilityEmailComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 't2-visibility-administrator',
		component: T2VisibilityAdministratorComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'supplier-access',
		component: SustainabilitySupplierAccessComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'clear-to-build-admin',
		component: ClearToBuildAdminComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'clear-to-build-tracker',
		component: ClearToBuildTrackerComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'clear-to-build-help',
		component: ClearToBuildHelpComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'clear-to-build-scheduler',
		component: ClearToBuildSchedulerComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'ctb-admin-dropdown-configuration',
		component: CtbAdminDropDownConfigurationComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 't2-visibility-dashboard',
		component: T2VisibilityDashboardComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 't2-visibility-data-refresh',
		component: T2VisibilityDataRefreshComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 't2-visibility-data-refresh-spreedsheet',
		component: T2VisibilityDataRefreshSpreedsheetComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 't2-visibility-admin',
		component: T2VisibilityAdminComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'logout',
		component: LogoutComponent
	},
	{
		path: 'qlik-sense-dashboard',
		component: QlikSenseDashboardComponent
	},
	{
		path: 'value-stream-data-collection-admin',
		component: ValueStreamDataCollectionComponent
	},
	{
		path: 'value-stream-data-collection-user',
		component: ValueStreamDataCollectionUserComponent
	},
	{
		path: 'value-stream-user-report',
		component: ValueStreamUserReportComponent
	},
	{
		path: 'value-stream-audit-report',
		component: ValueStreamAuditReportComponent
	},
	{
		path: 'value-stream-data-report-approval',
		component: ValueStreamDataReportApprovalComponent
	},
	{
		path: 'value-stream-admin-column-access',
		component: ValueStreamDataAdminColumnAccessComponent
	},
	{
		path: 'value-stream-admin-column-template',
		component: ValueStreamAdminColumnTemplateComponent
	},
	{
		path: 'value-stream-update-data',
		component: VsmUserTemplateDataUpdateComponent
	},
	{
		path: 'value-stream-data-collection-admin-step-two',
		component: ValueStreamDataCollectionStepTwoComponent
	},
	{
		path: 'tier1-e-component-shortage/:isSupplier',
		component: Tier1EComponentShortageComponent
	},
	{
		path: 'tier1-e-component-shortage-admin',
		component: Tier1EComponentShortageAdminComponent
	},
	{
		path: 'tier1-e-component-shortage-help',
		component: Tier1EComponenetShortageHelpComponent
	},
	{
		path: 'drop-down-configuration',
		component: DropDownConfigurationComponent,

	},
	{
		path: 'semiconductor-supplier-setup',
		component: SemiconductorSupplierSetupComponent,

	},
	{
		path: 'e-comp-semiconductor-supplier',
		component: ECompShortageSemiconductorSupplierComponent,

	},
	{
		path: 'tier1-e-component-shortage-dashboard',
		component: Tier1EComponentShortageDashboardComponent
	},
	{
		path: 'smashed-data-reporting',
		component: SmashedDataDashboardComponent
	},
	{
		path: 'n-tier-master-admin',
		component: NTierMasterAdminComponent
	},
	{
		path: 'no-access-for-n-tier',
		component: NoAccessForNTierComponent
	},
	{
		path: 'tiger-shark-constraint-tracker',
		component: TigerSharkConstraintTrackerComponent
	},
	{
		path: 'tiger-shark-constraint-tracker-admin',
		component: TigerSharkConstraintTrackerAdminComponent
	},
	{
		path: 'tiger-shark-constraint-tracker-dashboard',
		component: TigerSharkConstraintTrackerDashboardComponent
	},
	{
		path: 't2v-data-refresh-email-config',
		component: T2vDataRefreshAlternativeEmailConfigComponent
	},
	{
		path: 'record-enrichment',
		component: T2VisibilityDataRefreshRecordEnrichmentComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'scheduler',
		component: SchedularComponent
	},
	{
		path: 'help',
		component: HelpComponent
	},
	{
		path: 'landing',
		component: LandingComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: '',
		redirectTo: '/landing',
		pathMatch: 'full'
	},
	{
		path: 'browser-incompatable',
		component: BrowserIncompatableComponent
	},
	{
		path: '**', redirectTo: '/landing'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {useHash: true})],
	exports: [RouterModule],
	providers: []
})
export class AppRoutingModule {
}
