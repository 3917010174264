import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {TigerSharkConstraintTracker} from '../model/tiger-shark-constraint-tracker';
import {DatePipe} from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class TigersharkDashboardDownloadService {

	constructor(private http: HttpClient, private spinnerService: SpinnerService, private datepipe: DatePipe) {
	}

	private DASHBOARD_ENDPOINT = environment.apiUrl + 'tigerSharkConstraintTracker/dashboard';

	// downloadDashboardInExcel(tigerSharkConstraintTracker: TigerSharkConstraintTracker) {
	//
	// 	this.http.post(this.DASHBOARD_ENDPOINT + `/generateReport`, tigerSharkConstraintTracker, {responseType: 'blob'})
	// 		.subscribe((response) => {
	// 			this.downLoadFile(response, 'text/csv', tigerSharkConstraintTracker.constraintId.toString());
	// 			this.spinnerService.hide();
	//
	// 		});
	// }

	downLoadFile(data: any, type: string, constraintId: string, weekDate: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = 'WPP File #' + constraintId + '_' + this.datepipe.transform(weekDate, 'dd-MM-yyyy') + '.xlsx';
		anchor.href = url;
		anchor.click();
	}

	upload(fileToUpload: File): Observable<any> {
		const endpoint = this.DASHBOARD_ENDPOINT + '/upload';
		const formData: FormData = new FormData();
		formData.append('File', fileToUpload);
		return this.http.post(endpoint, formData);
	}
	public getActiveConstraintsForWeek(tigerSharkConstraintTracker: TigerSharkConstraintTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.DASHBOARD_ENDPOINT + `/getActiveConstraintsForWeek`, tigerSharkConstraintTracker, {responseType: 'json'});
	}

	getActualBusinessUnits(tigerSharkConstraintTracker: TigerSharkConstraintTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.DASHBOARD_ENDPOINT + `/getActualBusinessUnits`, tigerSharkConstraintTracker, {responseType: 'json'});
	}

	getPlantCodes(tigerSharkConstraintTracker: TigerSharkConstraintTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.DASHBOARD_ENDPOINT + `/getPlantCodes`, tigerSharkConstraintTracker, {responseType: 'json'});
	}
	getVehicleNames(tigerSharkConstraintTracker: TigerSharkConstraintTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.DASHBOARD_ENDPOINT + `/getVehicleNames`, tigerSharkConstraintTracker, {responseType: 'json'});
	}

	downloadConsolidatedReport(tigerSharkConstraintTracker: TigerSharkConstraintTracker) {

		this.http.post(this.DASHBOARD_ENDPOINT + `/generateConsolidatedReport`, tigerSharkConstraintTracker, {responseType: 'blob'})
			.subscribe((response) => {
				this.downLoadFile2(response, 'text/csv', tigerSharkConstraintTracker.weekDate.toString());
				this.spinnerService.hide();
			});
	}
	downLoadFile2(data: any, type: string, weekDate: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = 'Consolidated WPP_' + weekDate + '.xlsx';
		anchor.href = url;
		anchor.click();
	}
	getSupplierFileVersionNumbers(tigerSharkConstraintTracker: TigerSharkConstraintTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.DASHBOARD_ENDPOINT + `/getVersionNumbers`, tigerSharkConstraintTracker, {responseType: 'json'});
	}
	downloadSupplierFile(tigerSharkConstraintTracker: TigerSharkConstraintTracker) {

		this.http.post(this.DASHBOARD_ENDPOINT + `/downloadSupplierFile`, tigerSharkConstraintTracker, {responseType: 'blob'})
			.subscribe((response) => {
				// tslint:disable-next-line:max-line-length
				this.downLoadFile3(response, 'text/csv', tigerSharkConstraintTracker.constraintId.toString(), tigerSharkConstraintTracker.weekDate.toString(), tigerSharkConstraintTracker.versionNumber);
				this.spinnerService.hide();
			});
	}
	downLoadFile3(data: any, type: string, constraintId: string, weekDate: string, versionNumber: number) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = 'WPP File #' + constraintId + '_' + this.datepipe.transform(weekDate, 'dd-MM-yyyy') + '_V' + versionNumber + '.xlsx';
		anchor.href = url;
		anchor.click();
	}
}
