import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClearToBuildAdminComponent} from './clear-to-build-admin/clear-to-build-admin.component';
import { ClearToBuildTrackerComponent } from './clear-to-build-tracker/clear-to-build-tracker.component';
import { ClearToBuildHelpComponent } from './clear-to-build-help/clear-to-build-help.component';
import {CardModule} from 'primeng/card';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CalendarModule} from 'primeng/calendar';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import {IgxSpreadsheetModule} from 'igniteui-angular-spreadsheet';
import {FileUploadModule} from 'primeng/fileupload';
import {DropdownModule} from 'primeng/dropdown';
import {
	AccordionModule,
	DialogModule,
	ScrollPanelModule,
	TabViewModule,
	ToolbarModule
// tslint:disable-next-line:import-blacklist
} from 'primeng/primeng';
import { ClearToBuildSchedulerComponent } from './clear-to-build-scheduler/clear-to-build-scheduler.component';
import {TableModule} from 'primeng/table';
import { CtbAdminDropDownConfigurationComponent } from './ctb-admin-drop-down-configuration/ctb-admin-drop-down-configuration.component';
import { ClearToBuildMpnlAdminComponent } from './clear-to-build-mpnl-admin/clear-to-build-mpnl-admin.component';

@NgModule({
	// tslint:disable-next-line:max-line-length
	declarations: [ClearToBuildAdminComponent, ClearToBuildTrackerComponent, ClearToBuildHelpComponent, ClearToBuildSchedulerComponent, CtbAdminDropDownConfigurationComponent, ClearToBuildMpnlAdminComponent],
	imports: [
		CommonModule,
		CardModule,
		ReactiveFormsModule,
		CalendarModule,
		DropdownModule,
		MessageModule,
		MultiSelectModule,
		IgxSpreadsheetModule,
		FileUploadModule,
		TabViewModule,
		ToolbarModule,
		AccordionModule,
		ScrollPanelModule,
		DialogModule,
		TableModule,
		FormsModule
	]
})
export class ClearToBuildModule {
}
