import {
	Component,
	OnInit
} from '@angular/core';
import {Option} from '../../../home/model/ntier.option';
import {
	FormBuilder,
	FormGroup,
	Validators
} from '@angular/forms';
import {Router} from '@angular/router';
import {ValueStreamData} from '../model/value-stream-data';
import {MessageService} from 'primeng/api';
import {NTierMasterAdminService} from '../../n-tier-master-admin/service/n-tier-master-admin.service';
import {ValueStreamDataService} from '../service/value-stream-data.service';
import {SupplierService} from '../../../home/services/supplier.service';

@Component({
	selector: 'value-stream-data-collection-admin',
	templateUrl: './value-stream-data-collection.component.html',
	styleUrls: ['./value-stream-data-collection.component.scss']
})
export class ValueStreamDataCollectionComponent implements OnInit {
	yesNoOptions: Option[] = [];
	isDisabled = false;
	collectionForm: FormGroup;
	isReadOnly = false;
	currentQuarter = 'Q1';
	nextQuarter = 'Q2';
	valueStreamData: ValueStreamData;

	// tslint:disable-next-line:max-line-length
	constructor(private supplierService: SupplierService, public fb: FormBuilder, public messageService: MessageService, public router: Router, public masterAdminService: NTierMasterAdminService, public valueStreamDataService: ValueStreamDataService) {
	}

	ngOnInit() {
		this.router.navigate(['/']);
		// this.supplierService.pushSelectedSurvey('VSM');
		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
		} else {
			if ((sessionStorage.getItem('uid').includes('-'))) {
				this.masterAdminService.isSupplierHasSemiSuppliersAccess(sessionStorage.getItem('emailAddress')).subscribe((userHasAccess) => {
					if (!userHasAccess) {
						this.router.navigate(['/']);
					}
				});
			}
		}
		this.yesNoOptions = [
			{name: 'Yes', code: 'Yes'},
			{name: 'No', code: 'No'}];
		this.collectionForm = this.fb.group({
			tntvCommercialLeadContactName: [{value: '', disabled: this.isDisabled}, [Validators.required]],
			tntvCommercialLeadContactEmail: [{value: '', disabled: this.isDisabled}, [Validators.required, Validators.email]],
			tntvCurrentQuarterForecast: [{value: '', disabled: this.isDisabled}, [Validators.required]],
			tntvDateOfAssessment: [{value: '', disabled: this.isDisabled}, [Validators.required]],
			tntvNextQuarterForecast: [{value: '', disabled: this.isDisabled}, [Validators.required]],
			tntvPartsContainIc: [{value: 'Yes', disabled: this.isDisabled}, [Validators.required]]
		});
		const today = new Date();
		const currentMonth = today.getMonth();
		if (currentMonth >= 0 && currentMonth <= 2) {
			this.currentQuarter = 'Q1';
			this.nextQuarter = 'Q2';
		} else if (currentMonth >= 3 && currentMonth <= 5) {
			this.currentQuarter = 'Q2';
			this.nextQuarter = 'Q3';
		} else if (currentMonth >= 6 && currentMonth <= 8) {
			this.currentQuarter = 'Q3';
			this.nextQuarter = 'Q4';
		} else {
			this.currentQuarter = 'Q4';
			this.nextQuarter = 'Q1';
		}
	}

	onCreate() {
		this.isReadOnly = false;
		this.ngOnInit();
	}

	getContactInfo() {
		this.isReadOnly = true;
	}

	updateForm() {
		this.isReadOnly = false;
	}

	onReset() {
		this.collectionForm.reset();
		this.yesNoOptions = [
			{name: 'Yes', code: 'Yes'},
			{name: 'No', code: 'No'}];
	}

	onNext() {
		if (this.collectionForm.value.tntvPartsContainIc.name === 'Yes' && this.collectionForm.valid) {
			this.setupData();
			sessionStorage.setItem('collectionFormValue', JSON.stringify(this.valueStreamData));
			this.router.navigate(['/value-stream-data-collection-admin-step-two']);
		}
	}

	onSubmit() {
		if (this.collectionForm.value.tntvPartsContainIc.name === 'No' && this.collectionForm.valid) {
			this.setupData();
			this.valueStreamDataService.createValueStreamData(this.valueStreamData).subscribe(response => {
				this.valueStreamData = response;
				this.pushMessage('success', 'Success', 'Value stream data Id #' + response.id + ' has been created successfully');
				this.collectionForm.reset();
				this.ngOnInit();
			});
		}
	}

	pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 60000
		});
	}

	setupData() {
		this.valueStreamData = this.collectionForm.getRawValue() as ValueStreamData;
		// @ts-ignore
		this.valueStreamData.tntvCurrentQuarterForecast = this.valueStreamData.tntvCurrentQuarterForecast.name;
		// @ts-ignore
		this.valueStreamData.tntvNextQuarterForecast = this.valueStreamData.tntvNextQuarterForecast.name;
		// @ts-ignore
		this.valueStreamData.tntvPartsContainIc = this.valueStreamData.tntvPartsContainIc.name;
	}
}
