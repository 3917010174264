export class T2Visibility {
	id: number;
	t1SiteGSDB: string;
	t1SupplierSiteName: string;
	t2SupplierSiteName: string;
	dunsNo: string;
	t2SiteStreetAddress: string;
	t2SiteCity: string;
	t2SiteStateProvince: string;
	t2SitePostalZipcode: string;
	t2SiteCountry: string;
	t2SiteDistrict: string;
	mfgSiteOrShipSiteBoth: string;
	t2SupplierShipSiteName: string;
	shipDunsNo: string;
	t2ShipSiteStreetAddress: string;
	t2ShipSiteCity: string;
	t2ShipSiteStateProvince: string;
	t2ShipSitePostalZipcode: string;
	t2ShipSiteCountry: string;
	t2ShipSiteDistrict: string;
	source: string;
	createdBy: string;
	createdOn: Date;
	updatedBy: string;
	updatedOn: Date;
	uid: string;
	email: string;
	parent4DigitCode: string;
	gsdb5DigitCode: string;
}
