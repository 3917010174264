export class T2VisibilityEmail {

	templateName: any;
	emailContent: any;
	ccList: any;
	subject: any;
	businessName: any;

	constructor(t2VisibilityEmail: { templateName: string; emailContent: string; ccList: string; subject: string; businessName: string }) {
		Object.assign(this, t2VisibilityEmail);
	}
}

export const emptyT2Email = {
	templateName: '',
	emailContent: '',
	ccList: '',
	subject: '',
	businessName: ''
};
