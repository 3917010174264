import {
	Component,
	OnInit
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	Validators
} from '@angular/forms';
import {SmashedDataService} from '../service/smashed-data.service';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {NTierMasterAdminService} from '../../n-tier-master-admin/service/n-tier-master-admin.service';
import {Router} from '@angular/router';
import {SupplierService} from '../../../home/services/supplier.service';

@Component({
	selector: 'smashed-data-dashboard',
	templateUrl: './smashed-data-dashboard.component.html',
	styleUrls: ['./smashed-data-dashboard.component.scss']
})
export class SmashedDataDashboardComponent implements OnInit {
	isDisabled = false;
	// tslint:disable-next-line:max-line-length
	constructor(private supplierService: SupplierService, private fb: FormBuilder, private smashedDataService: SmashedDataService, private router: Router, private spinnerService: SpinnerService, private masterAdminService: NTierMasterAdminService) {
	}
	smashedFg: FormGroup;
	ngOnInit() {
		// this.supplierService.pushSelectedSurvey('ConsolidatedDashboard');
		// if (sessionStorage.getItem('uid') == null) {
		// 	this.router.navigate(['/']);
		// } else {
		// tslint:disable-next-line:max-line-length
		// 	this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Smashed Report Screen').subscribe(userHasAdminAccess => {
		// 		if (!userHasAdminAccess) {
		// tslint:disable-next-line:max-line-length
		// 			this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Main Admin Tab').subscribe(userHasMasterAccess => {
		// 				if (!userHasMasterAccess) {
		// 					this.router.navigate(['/']);
		// 				}
		// 			});
		// 		}
		// 	});
		// }
		this.router.navigate(['/']);
		this.smashedFg = this.fb.group({
		});
	}
	downloadSmashedData() {
		this.smashedDataService.generateSmashedDataInExcel(sessionStorage.getItem('uid'));
		this.spinnerService.show();
	}
}
