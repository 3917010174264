/* tslint:disable */
/* tslint:disable-next-line:indent */

import {
	Component,
	OnInit
} from '@angular/core';
import {Option} from '../../../home/model/ntier.option';
import {
	FormBuilder,
	FormGroup,
	Validators
} from '@angular/forms';
import {Router} from '@angular/router';
import {ValueStreamData} from '../model/value-stream-data';
import {ValueStreamDataService} from '../service/value-stream-data.service';
import {MessageService} from 'primeng/api';
import {NTierMasterAdminService} from '../../n-tier-master-admin/service/n-tier-master-admin.service';
import {SupplierService} from '../../../home/services/supplier.service';
@Component({
	selector: 'value-stream-data-collection-admin-step-two',
	templateUrl: './value-stream-data-collection-step-two.component.html',
	styleUrls: ['./value-stream-data-collection-step-two.component.scss']
})
export class ValueStreamDataCollectionStepTwoComponent implements OnInit {
	isDisabled = false;
	collectionForm: FormGroup;
	yesNoOptions: Option[] = [];
	isReadOnly = false;
	valueStreamData: ValueStreamData;
	constructor(private supplierService: SupplierService, private fb: FormBuilder,
				         private messageService: MessageService,
				         private router: Router,
				         private masterAdminService: NTierMasterAdminService,
				         private valueStreamDataService: ValueStreamDataService) {
	}
	ngOnInit() {
		this.router.navigate(['/']);
		// this.supplierService.pushSelectedSurvey('VSM');
		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
		} else {
			if ((sessionStorage.getItem('uid').includes('-'))) {
				this.masterAdminService.isSupplierHasSemiSuppliersAccess(sessionStorage.getItem('emailAddress')).subscribe((userHasAccess) => {
					if (!userHasAccess) {
						this.router.navigate(['/']);
					}
				});
			}
		}
		this.yesNoOptions = [
			{name: 'Yes', code: 'Yes'},
			{name: 'No', code: 'No'}];
		this.collectionForm = this.fb.group({
			tntvCommercialLeadContactName: [{value: '', disabled: this.isDisabled}, [Validators.required]],
			tntvCommercialLeadContactEmail: [{value: '', disabled: this.isDisabled}, [Validators.required, Validators.email]],
			tntvCurrentQuarterForecast: [{value: '', disabled: this.isDisabled}, [Validators.required]],
			tntvDateOfAssessment: [{value: '', disabled: this.isDisabled}, [Validators.required]],
			tntvNextQuarterForecast: [{value: '', disabled: this.isDisabled}, [Validators.required]],
			tntvPartsContainIc: [{value: 'Yes', disabled: this.isDisabled}, [Validators.required]],
			tntvWaferSupplierParentCode: [{value: '', disabled: this.isDisabled}, []],
			tntvWaferSupplierName: [{value: '', disabled: this.isDisabled}, []],
			tntvWaferSupplierLocation: [{value: '', disabled: this.isDisabled}, []],
			tntvWaferProcessTechnologyNode: [{value: '', disabled: this.isDisabled}, []],
			tntvNodeNM: [{value: '', disabled: this.isDisabled}, []],
			tntvWaferTransiteTimeDays: [{value: '', disabled: this.isDisabled}, []],
			tntvIcDeviceSourceSupplierName: [{value: '', disabled: this.isDisabled}, []],
			tntvManufacturerNameUnified: [{value: '', disabled: this.isDisabled}, []],
			tntvSupplierLocation: [{value: '', disabled: this.isDisabled}, []],
			tntvDeviceName: [{value: '', disabled: this.isDisabled}, []],
			tntvDevicePartNumber: [{value: '', disabled: this.isDisabled}, []],
			tntvElectronicAssemblyModuleSupplierName: [{value: '', disabled: this.isDisabled}, []],
			tntvSiteCode: [{value: '', disabled: this.isDisabled}, []],
			tntvPartName: [{value: '', disabled: this.isDisabled}, []],
			tntvFordPartNumber: [{value: '', disabled: this.isDisabled}, []],
			tntvCombinedModulePartNumber: [{value: '', disabled: this.isDisabled}, []],
			tntvUnnamedColumn: [{value: '', disabled: this.isDisabled}, []],
			icTransitTime: [{value: '', disabled: this.isDisabled}, []],
			tntvEndItemToFord: [{value: '', disabled: this.isDisabled}, []],
			tntvSupplierName: [{value: '', disabled: this.isDisabled}, []],
			tntvAssemblyPlant: [{value: '', disabled: this.isDisabled}, []],
			tntvGsdb: [{value: '', disabled: this.isDisabled}, []],
			tntvVehicle: [{value: '', disabled: this.isDisabled}, []],
			tntvFordPartNumber2: [{value: '', disabled: this.isDisabled}, []],
			tntvModuleTransitTime: [{value: '', disabled: this.isDisabled}, []],
			tntvIcTransitTimeDays: [{value: '', disabled: this.isDisabled}, []],
			tntvIntegrator1Name: [{value: '', disabled: this.isDisabled}, []],
		});
		if (sessionStorage.getItem('collectionFormValue')) {
			const formData = JSON.parse(sessionStorage.getItem('collectionFormValue'));
			this.collectionForm.patchValue(formData);
			console.log(this.collectionForm.value);
		}
	}

	onCreate() {
		this.isReadOnly = false;
		this.ngOnInit();
	}

	getContactInfo() {
		this.isReadOnly = true;
	}

	updateForm() {
		this.isReadOnly = false;
	}

	onReset() {
		this.collectionForm.reset();
		this.yesNoOptions = [
			{name: 'Yes', code: 'Yes'},
			{name: 'No', code: 'No'}];
	}

	onPrev() {
		this.router.navigate(['/value-stream-data-collection-admin']);
	}

	onSubmit() {
		if (this.collectionForm.valid) {
			this.valueStreamData = this.collectionForm.getRawValue() as ValueStreamData;
			// @ts-ignore
			this.valueStreamData.tntvEndItemToFord = this.valueStreamData.tntvEndItemToFord.name;
			console.log(JSON.stringify(this.valueStreamData.tntvEndItemToFord));
			this.valueStreamDataService.createValueStreamData(this.valueStreamData).subscribe(response => {
				this.valueStreamData = response;
				this.pushMessage('success', 'Success', 'Value stream data Id #' + response.id + ' has been created successfully');
				this.collectionForm.reset();
				sessionStorage.removeItem('collectionFormValue');
				this.ngOnInit();
			});
		}
	}

	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 60000
		});
	}
}
