import { saveAs } from 'file-saver'; // npm package: "file-saver": "^1.3.8"
import { Workbook } from 'igniteui-angular-excel';
import { WorkbookFormat } from 'igniteui-angular-excel';
import { WorkbookSaveOptions } from 'igniteui-angular-excel';

export class ExcelUtility {
	public static getExtension(format: WorkbookFormat) {
		console.log('getExtension');
		switch (format) {
			case WorkbookFormat.StrictOpenXml:
			case WorkbookFormat.Excel2007:
				return '.xlsx';
			case WorkbookFormat.Excel2007MacroEnabled:
				return '.xlsm';
			case WorkbookFormat.Excel2007MacroEnabledTemplate:
				return '.xltm';
			case WorkbookFormat.Excel2007Template:
				return '.xltx';
			case WorkbookFormat.Excel97To2003:
				return '.xls';
			case WorkbookFormat.Excel97To2003Template:
				return '.xlt';
		}
	}

	public static load(file: File): Promise<Workbook> {
		console.log('load');
		return new Promise<Workbook>((resolve, reject) => {
			ExcelUtility.readFileAsUint8Array(file).then((a) => {
				Workbook.load(a, null, (w) => {
					resolve(w);
				}, (e) => {
					reject(e);
				});
			}, (e) => {
				reject(e);
			});
		});
	}

	public static loadFromUrl(url: string): Promise<Workbook> {
		console.log('loadFromUrl');
		return new Promise<Workbook>((resolve, reject) => {
			const req = new XMLHttpRequest();
			req.open('GET', url, true);
			req.responseType = 'arraybuffer';
			req.onload = (d) => {
				const data = new Uint8Array(req.response);
				Workbook.load(data, null, (w) => {
					resolve(w);
					console.log('Success Response');
				}, (e) => {
					reject(e);
					console.log('Error Response');
				});
			};
			req.send();
		});
	}

	public static save(workbook: Workbook, fileNameWithoutExtension: string): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			const opt = new WorkbookSaveOptions();
			opt.type = 'blob';

			workbook.save(opt, (d) => {
				console.log('WorkBook Extension : ' + workbook.currentFormat);
				const fileExt = ExcelUtility.getExtension(workbook.currentFormat);
				console.log('WorkBook Extension : ' + workbook.currentFormat);
				const fileName = fileNameWithoutExtension + fileExt;
				saveAs(d as Blob, fileName);
				resolve(fileName);
			}, (e) => {
				reject(e);
			});
		});
	}

	private static readFileAsUint8Array(file: File): Promise<Uint8Array> {
		console.log('readFileAsUint8Array');
		return new Promise<Uint8Array>((resolve, reject) => {
			const fr = new FileReader();
			fr.onerror = (e) => {
				reject(fr.error);
			};

			if (fr.readAsBinaryString) {
				fr.onload = (e) => {
					const rs = (fr as any).resultString;
					const str: string = rs != null ? rs : fr.result;
					const result = new Uint8Array(str.length);
					for (let i = 0; i < str.length; i++) {
						result[i] = str.charCodeAt(i);
					}
					resolve(result);
				};
				fr.readAsBinaryString(file);
			} else {
				fr.onload = (e) => {
					resolve(new Uint8Array(fr.result as ArrayBuffer));
				};
				fr.readAsArrayBuffer(file);
			}
		});
	}
}
