export class SemiconductorSupplierAdmin {
	id: number;
	semiconductorSupplierName: string;
	semisupplierEmail: string;
	createdBy: string;
	createdDate: Date;
	updatedBy: string;
	updatedDate: Date;
	// constructor(semiconductorSupplier: SemiconductorSupplierAdmin) {
	// 	Object.assign(this, semiconductorSupplier);
	// }
}
/* tslint:disable */
// export const defaultDropDown = {
// 	'id': 0,
// 	'semiconductorSupplier': '',
// 	'supplierEmailId': '',
// 	'createdBy': '',
// 	'createdDate': null,
// 	'updatedBy': '',
// 	'updatedDate': null
// };
