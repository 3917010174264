import {Component, OnInit, ViewChild} from '@angular/core';
import {IgxSpreadsheetComponent} from 'igniteui-angular-spreadsheet';
import {SupplierService} from '../../../home/services/supplier.service';
import {ExcelUtility} from '../../tiger-shark-constraint-tracker/model/excel-utility';
import {T2VisibilityService} from '../services/t2-visibility.service';
import {WorkbookSaveOptions} from 'igniteui-angular-excel';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {T2Visibility} from '../model/t2_visibility';

@Component({
	selector: 't2-visibility',
	templateUrl: './t2-visibility.component.html',
	styleUrls: ['./t2-visibility.component.scss'],
	providers: [MessageService]
})
export class T2VisibilityComponent implements OnInit {

	display = false;
	submitStatus = true;
	fileFromDatabase: any;
	userLoginId: string;
	notifications = [];
	totalRecords: number;
	responsiveOptions: any[];
	cols: any[];
	noNumber = [];
	submitRemainder = false;
	displayDeclarationDialog = false;
	showConfirmButton = false;
	// tslint:disable-next-line:max-line-length
	constructor(
		private router: Router, private messageService: MessageService, private spinnerService: SpinnerService,
		private supplierService: SupplierService, private t2VisibilityService: T2VisibilityService) {
		this.responsiveOptions = [
			{
				breakpoint: '1024px',
				numVisible: 3,
				numScroll: 3
			},
			{
				breakpoint: '768px',
				numVisible: 2,
				numScroll: 2
			},
			{
				breakpoint: '560px',
				numVisible: 1,
				numScroll: 1
			}
		];
	}

	@ViewChild('spreadsheet', {static: false, read: IgxSpreadsheetComponent})
	public spreadsheet: IgxSpreadsheetComponent;

	ngOnInit() {
		// this.supplierService.pushSelectedSurvey('T2Visibility');
		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/login']);
		} else if (!sessionStorage.getItem('uid').includes('-')) {
			this.router.navigate(['/']);
		} else if (sessionStorage.getItem('uid').includes('-')) {
			this.router.navigate(['/']);
		}
		this.cols = [
			// {field: 'id', header: 'S.No'},
			{field: 'subject', header: 'Notifications'}
		];
		if (sessionStorage.getItem('uid').includes('-')) {
			this.initialLoad();
			this.allActiveSupplierNotifications();
		}
		this.t2VisibilityService.checkIfLessThanFiveSuppliers(sessionStorage.getItem('uid')).subscribe(isLessThanFiveSuppliers => {
			if (isLessThanFiveSuppliers) {
				this.showConfirmButton = true;
			} else {
			this.showConfirmButton = false;

		}});
	}

	private allActiveSupplierNotifications() {
		this.t2VisibilityService.getAllActiveSupplierNotifications().subscribe((data) => {
			this.notifications = data;
			this.totalRecords = this.notifications.length;
		});
	}

	public initialLoad() {
		const reportValue = 1;
		const onoffValue = 0;
		this.spinnerService.show();
		this.pushMessage('warn', 'Warning', 'Report Generation may take some time');
		this.t2VisibilityService.downloadT2Report(sessionStorage.getItem('uid'), reportValue, onoffValue).subscribe((data) => {
			this.fileFromDatabase = data;
			this.submitStatus = false;
			ExcelUtility.load(this.fileFromDatabase).then((w) => {
				this.spreadsheet.workbook = w;
				console.log('Excel Loaded');
				this.spinnerService.hide();
			});
		});
	}

	public openFile(input: any): void {
		this.spinnerService.show();
		if (input.files == null || input.files.length === 0) {
			return;
		}
		ExcelUtility.load(input.files[0]).then((w) => {
			this.spreadsheet.workbook = w;
			this.spinnerService.hide();
			this.submitStatus = false;
			this.submitRemainder = true;
			console.log(this.spreadsheet.workbook.worksheets('Sheet1')._r);
		}, (e) => {
			console.error('Workbook Load Error:' + e);
		});
	}

	showDeclarationDialog() {
		this.displayDeclarationDialog = true;
		this.t2VisibilityService.confirmationStatusUpdate(sessionStorage.getItem('uid')).subscribe((data) => {
			console.log('Confirmation status updated');
		}, (e) => {
			this.pushMessage('error', 'error', 'Confirmation status updation failed, Please try again later');

		});
	}

	downloadHistoricalT2Data() {
		const reportValue = 2;
		const onoffValue = 1;
		this.t2VisibilityService.downloadT2Report(sessionStorage.getItem('uid'), reportValue, onoffValue).subscribe((data) => {
			this.spinnerService.show();
			this.t2VisibilityService.downLoadFile(data, 'text/csv');
			this.spinnerService.hide();
		});
	}

	downloadBlankT2() {
		const reportValue = 3;
		const onoffValue = 1;
		this.t2VisibilityService.downloadT2Report(sessionStorage.getItem('uid'), reportValue, onoffValue).subscribe((data) => {
			this.spinnerService.show();
			this.t2VisibilityService.downLoadFile(data, 'text/csv');
			this.spinnerService.hide();
		});
	}

	downloadLatestT2Data() {
		const reportValue = 1;
		const onoffValue = 1;
		this.t2VisibilityService.downloadT2Report(sessionStorage.getItem('uid'), reportValue, onoffValue).subscribe((data) => {
			this.spinnerService.show();
			this.t2VisibilityService.downLoadFile(data, 'text/csv');
			this.spinnerService.hide();
		});
	}

	saveSpreadsheet() {
		this.spinnerService.show();
		const opt = new WorkbookSaveOptions();
		opt.type = 'blob';
		const workbook = this.spreadsheet.workbook;
		// @ts-ignore
		// @ts-ignore
		workbook.save(opt, (d) => {
			const fileExt = ExcelUtility.getExtension(workbook.currentFormat);
			let flag;
			for (let i = 6; (workbook.worksheets(1).rows(i).cells(0).value) !== null
			|| (workbook.worksheets(1).rows(i).cells(1).value) !== null
			|| (workbook.worksheets(1).rows(i).cells(2).value) !== null
			|| (workbook.worksheets(1).rows(i).cells(3).value) !== null
			|| (workbook.worksheets(1).rows(i).cells(4).value) !== null
			|| (workbook.worksheets(1).rows(i).cells(5).value) !== null
			|| (workbook.worksheets(1).rows(i).cells(6).value) !== null
			|| (workbook.worksheets(1).rows(i).cells(7).value) !== null
			|| (workbook.worksheets(1).rows(i).cells(10).value) !== null
			|| (workbook.worksheets(1).rows(i).cells(11).value) !== null
			|| (workbook.worksheets(1).rows(i).cells(12).value) !== null
			|| (workbook.worksheets(1).rows(i).cells(13).value) !== null
			|| (workbook.worksheets(1).rows(i).cells(14).value) !== null
			|| (workbook.worksheets(1).rows(i).cells(15).value) !== null
			|| (workbook.worksheets(1).rows(i).cells(16).value) !== null; i++) {

				const rowNumber = workbook.worksheets(1).rows(i).cells(0).rowIndex + 1;
				console.log('cell 10 value: ', (workbook.worksheets(1).rows(i).cells(10).value));

				if ((workbook.worksheets(1).rows(i).cells(10).value) === 'Yes'
					|| (workbook.worksheets(1).rows(i).cells(10).value) === 'MFG Only') {
					if ((workbook.worksheets(1).rows(i).cells(0).value) === null
						|| ((workbook.worksheets(1).rows(i).cells(0).value).trim()).length !== 5
						|| (workbook.worksheets(1).rows(i).cells(0).value) === '') {
						flag = 1;
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Check GSDB Codes in row number :  ' + rowNumber);
						this.display = false;
						break;
					} else if ((workbook.worksheets(1).rows(i).cells(1).value) === null
						|| (workbook.worksheets(1).rows(i).cells(1).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T1 Supplier Site Name in row number : ' + rowNumber);
						flag = 2;
						this.display = false;
						break;
					} else if ((workbook.worksheets(1).rows(i).cells(2).value) === null
						|| (workbook.worksheets(1).rows(i).cells(2).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Supplier Site Name (MFG) in row number : ' + rowNumber);
						flag = 3;
						this.display = false;
						break;
					} else if ((workbook.worksheets(1).rows(i).cells(3).value) === null
						|| (workbook.worksheets(1).rows(i).cells(3).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site Address (MFG) in row number : ' + rowNumber);
						flag = 4;
						this.display = false;
						break;
					} else if ((workbook.worksheets(1).rows(i).cells(4).value) === null
						|| (workbook.worksheets(1).rows(i).cells(4).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site City (MFG) in row number : ' + rowNumber);
						flag = 5;
						this.display = false;
						break;
					} else if ((workbook.worksheets(1).rows(i).cells(5).value) === null
						|| (workbook.worksheets(1).rows(i).cells(5).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site State/Province (MFG) in row number : ' + rowNumber);
						flag = 6;
						this.display = false;
						break;
					} else if ((workbook.worksheets(1).rows(i).cells(6).value) === null
						|| (workbook.worksheets(1).rows(i).cells(6).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site Postal/Zipcode (MFG) in row number : ' + rowNumber);
						flag = 7;
						this.display = false;
						break;
					} else if ((workbook.worksheets(1).rows(i).cells(7).value) === null
						|| (workbook.worksheets(1).rows(i).cells(7).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site Country (MFG) in row number : ' + rowNumber);
						flag = 8;
						this.display = false;
						break;
					} else {
						flag = 18;
						continue;
					}
				} else if ((workbook.worksheets(1).rows(i).cells(10).value) === 'Ship Only') {
					if ((workbook.worksheets(1).rows(i).cells(11).value) === null
						|| (workbook.worksheets(1).rows(i).cells(11).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Supplier Site Name (SHIP) in row number : ' + rowNumber);
						flag = 9;
						this.display = false;
						break;
					} else if ((workbook.worksheets(1).rows(i).cells(12).value) === null
						|| (workbook.worksheets(1).rows(i).cells(12).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site Address (SHIP) in row number : ' + rowNumber);
						flag = 10;
						this.display = false;
						break;
					} else if ((workbook.worksheets(1).rows(i).cells(13).value) === null
						|| (workbook.worksheets(1).rows(i).cells(13).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site City (SHIP) in row number : ' + rowNumber);
						flag = 11;
						this.display = false;
						break;
					} else if ((workbook.worksheets(1).rows(i).cells(14).value) === null
						|| (workbook.worksheets(1).rows(i).cells(14).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site State/Province (SHIP) in row number : ' + rowNumber);
						flag = 12;
						this.display = false;
						break;
					} else if ((workbook.worksheets(1).rows(i).cells(15).value) === null
						|| (workbook.worksheets(1).rows(i).cells(15).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site Postal/Zipcode (SHIP) in row number : ' + rowNumber);
						flag = 13;
						this.display = false;
						break;
					} else if ((workbook.worksheets(1).rows(i).cells(16).value) === null
						|| (workbook.worksheets(1).rows(i).cells(16).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site Country (SHIP) in row number : ' + rowNumber);
						flag = 14;
						this.display = false;
						break;
					} else {
						flag = 18;
						continue;
					}
				} else if ((workbook.worksheets(1).rows(i).cells(10).value) === null
					|| (workbook.worksheets(1).rows(i).cells(10).value) === ' ') {
					this.spinnerService.hide();
					this.pushMessage('error', 'error',
						'Please fill Column K from the dropdown in row number : ' + rowNumber);
					flag = 31;
					this.display = false;
					break;
				}  else if ((workbook.worksheets(1).rows(i).cells(10).value) === 'No') {
					if ((workbook.worksheets(1).rows(i).cells(0).value) === null
						|| ((workbook.worksheets(1).rows(i).cells(0).value).trim()).length !== 5
						|| (workbook.worksheets(1).rows(i).cells(0).value) === '') {
						flag = 1;
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Check GSDB Codes in row number :  ' + rowNumber);
						this.display = false;
						break;
					} else if ((workbook.worksheets(1).rows(i).cells(1).value) === null
						|| (workbook.worksheets(1).rows(i).cells(1).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T1 Supplier Site Name in row number : ' + rowNumber);
						flag = 2;
						this.display = false;
						break;
					} else if ((workbook.worksheets(1).rows(i).cells(2).value) === null
						|| (workbook.worksheets(1).rows(i).cells(2).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Supplier Site Name (MFG) in row number : ' + rowNumber);
						flag = 3;
						this.display = false;
						break;
					} else if ((workbook.worksheets(1).rows(i).cells(3).value) === null
						|| (workbook.worksheets(1).rows(i).cells(3).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site Address (MFG) in row number : ' + rowNumber);
						flag = 4;
						this.display = false;
						break;
					} else if ((workbook.worksheets(1).rows(i).cells(4).value) === null
						|| (workbook.worksheets(1).rows(i).cells(4).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site City (MFG) in row number : ' + rowNumber);
						flag = 5;
						this.display = false;
						break;
					} else if ((workbook.worksheets(1).rows(i).cells(5).value) === null
						|| (workbook.worksheets(1).rows(i).cells(5).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site State/Province (MFG) in row number : ' + rowNumber);
						flag = 6;
						this.display = false;
						break;
					} else if ((workbook.worksheets(1).rows(i).cells(6).value) === null
						|| (workbook.worksheets(1).rows(i).cells(6).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site Postal/Zipcode (MFG) in row number : ' + rowNumber);
						flag = 7;
						this.display = false;
						break;
					} else if ((workbook.worksheets(1).rows(i).cells(7).value) === null
						|| (workbook.worksheets(1).rows(i).cells(7).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site Country (MFG) in row number : ' + rowNumber);
						flag = 8;
						this.display = false;
						break;
					} else if ((workbook.worksheets(1).rows(i).cells(11).value) === null
						|| (workbook.worksheets(1).rows(i).cells(11).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Supplier Site Name (SHIP) in row number : ' + rowNumber);
						flag = 9;
						this.display = false;
						break;
					} else if ((workbook.worksheets(1).rows(i).cells(12).value) === null
						|| (workbook.worksheets(1).rows(i).cells(12).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site Address (SHIP) in row number : ' + rowNumber);
						flag = 10;
						this.display = false;
						break;
					} else if ((workbook.worksheets(1).rows(i).cells(13).value) === null
						|| (workbook.worksheets(1).rows(i).cells(13).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site City (SHIP) in row number : ' + rowNumber);
						flag = 11;
						this.display = false;
						break;
					} else if ((workbook.worksheets(1).rows(i).cells(14).value) === null
						|| (workbook.worksheets(1).rows(i).cells(14).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site State/Province (SHIP) in row number : ' + rowNumber);
						flag = 12;
						this.display = false;
						break;
					} else if ((workbook.worksheets(1).rows(i).cells(15).value) === null
						|| (workbook.worksheets(1).rows(i).cells(15).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site Postal/Zipcode (SHIP) in row number : ' + rowNumber);
						flag = 13;
						this.display = false;
						break;
					} else if ((workbook.worksheets(1).rows(i).cells(16).value) === null
						|| (workbook.worksheets(1).rows(i).cells(16).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site Country (SHIP) in row number : ' + rowNumber);
						flag = 14;
						this.display = false;
						break;
					} else {
						flag = 18;
						continue;
					}
				} else {
					this.spinnerService.hide();
					this.pushMessage('error', 'error',
						'The Value in Column K must be either "Yes" or "No" in row number : ' + rowNumber);
					flag = 30;
					this.display = false;
					break;
				}
			}
			if (flag === 18) {
				// tslint:disable-next-line:max-line-length
				this.t2VisibilityService.upload(d as File, sessionStorage.getItem('uid'), sessionStorage.getItem('emailAddress'), sessionStorage.getItem('fordSiteCode')).subscribe((data) => {
						this.spinnerService.hide();
						this.pushMessage('success', 'success',
							'Data Updated Successfully!');
						this.submitStatus = false;
						this.display = false;
						// this.spreadsheet.workbook = new Workbook();
						this.initialLoad();

					},
					error => {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Unable to Update Data, Please try again later');
					}
				);
			}

		}, (e) => {
			this.spinnerService.hide();
			this.pushMessage('error', 'Error', 'Unable to Update Data, Please try again later');
		});

	}

	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		// this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 8000
		});
	}
}
