import {
	Component,
	OnInit
} from '@angular/core';

@Component({
	selector: 'help',
	templateUrl: './help.component.html',
	styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
	fordExperiance = 'https://web.qpr.ford.com/sta/Absenteeism_%20Management_Ford\'s_Experience.pdf';
	howToRaiseTicket = 'https://web.qpr.ford.com/sta/How_To_Raise_A_Ticket_Or_IT_Request.pdf';
	helpLink = 'https://web.qpr.ford.com/sta/';
	oesaLink = 'https://www.oesa.org/coronavirus-updates-and-resources';
	faqLink = 'https://web.qpr.ford.com/sta/COVID-19%20Supplier%20Survey_Guide%20Book%20and%20Frequently%20Asked%20Questions.docx';
	// tslint:disable-next-line:max-line-length
	productionRestartRiskAssessmentLink = 'https://web.qpr.ford.com/sta/COVID%2019%20Supplier%20Risk%20Assessment%20-%20Online%20Survey%20-%20Final.pdf';

	constructor() {
	}

	ngOnInit() {
	}

}
