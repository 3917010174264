import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SupplierService} from '../../../home/services/supplier.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomDialogComponent} from '../../../custom-dialog/custom-dialog/custom-dialog.component';
import {DialogService, DynamicDialogRef, MessageService} from 'primeng/api';
import {CtbDropdown, defaultCtbDropdown} from '../model/ctb-dropdown';
import {ClearToBuildAdminService} from '../service/clear-to-build-admin.service';
import {NTierMasterAdminService} from '../../n-tier-master-admin/service/n-tier-master-admin.service';

@Component({
	selector: 'ctb-admin-drop-down-configuration',
	templateUrl: './ctb-admin-drop-down-configuration.component.html',
	styleUrls: ['./ctb-admin-drop-down-configuration.component.scss']
})
export class CtbAdminDropDownConfigurationComponent implements OnInit {
	private response: any;
	purchasingCoe: CtbDropdown[] = [];
	purchasingCoeManager: CtbDropdown[] = [];
	selectedPurchasingCoe: CtbDropdown[] = [];
	selectedPurchasingCoeManager: CtbDropdown[] = [];
	ctbDropDownForm: FormGroup;
	display = false;
	displayDeleteDialog = false;
	dropDownObject: CtbDropdown;
	isCreateOperation = false;
	isUpdateOperation = false;
	dropDownColumnName1 = 'Purchasing Coe';
	dropDownColumnName2 = 'Purchasing Coe Manager';
	ref: DynamicDialogRef;
	formFieldName: any;
	isDisplayPurchasingCoeFilterData = false;
	isDisplayPurchasingCoeManagerFilterData = false;
	deleteObject: CtbDropdown;
	isMultiDelete = false;
	deletionDropDownColumnName: string;

	// tslint:disable-next-line:max-line-length
	constructor(private supplierService: SupplierService, private router: Router, private fb: FormBuilder, private dialogService: DialogService, private messageService: MessageService, private clearToBuildAdminService: ClearToBuildAdminService, private masterAdminService: NTierMasterAdminService) {
	}

	ngOnInit() {
		this.router.navigate(['/']);
		// this.supplierService.pushSelectedSurvey('clearToBuild');
		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
		} else {
			// tslint:disable-next-line:max-line-length
			this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build Super Admin').subscribe(userHasCtbSuperAdminAccess => {
				if (!userHasCtbSuperAdminAccess) {
					this.router.navigate(['/']);
				}
			});
		}
		this.ctbDropDownForm = this.fb.group({
			dropDownValue: ['', Validators.required]
		});
		this.findAllCtbDropDownData();
	}

	adminScreen() {
		this.router.navigate(['/clear-to-build-admin']);
	}

	findAllCtbDropDownData() {
		this.clearToBuildAdminService.findAllCtbDropDownData(this.dropDownColumnName1).subscribe((data) => {
			this.purchasingCoe = [];
			this.purchasingCoe = data;
		});
		this.clearToBuildAdminService.findAllCtbDropDownData(this.dropDownColumnName2).subscribe((data) => {
			this.purchasingCoeManager = [];
			this.purchasingCoeManager = data;
		});
	}

	enableCreateNewDataDialog(dropDownColumnName: string) {
		this.ctbDropDownForm.reset();
		this.isCreateOperation = true;
		this.isUpdateOperation = false;
		this.dropDownObject = new CtbDropdown(defaultCtbDropdown);
		this.dropDownObject.columnName = dropDownColumnName;
		this.formFieldName = dropDownColumnName;
		this.display = true;
	}

	createData() {
		this.dropDownObject.dropDownValue = this.ctbDropDownForm.get('dropDownValue').value;
		if (this.validateData(this.dropDownObject)) {
			this.clearToBuildAdminService.createCtbDropDownData(this.dropDownObject).subscribe((data) => {
				this.response = data;
				console.log(this.response);
				if (JSON.parse(this.response.success)) {
					this.display = false;
					this.pushDialog(this.response.message);
					this.findAllCtbDropDownData();
				} else {
					this.pushDialog(this.response.message);
				}
			});
		} else {
			this.pushMessage('error', 'Error', 'Please fill the mandatory field');
		}
	}

	enableUpdateDataDialog(product: any, dropDownColumnName: string) {
		this.ctbDropDownForm.reset();
		this.formFieldName = dropDownColumnName;
		this.isCreateOperation = false;
		this.isUpdateOperation = true;
		this.dropDownObject = new CtbDropdown(defaultCtbDropdown);
		this.dropDownObject.id = product['id'];
		this.dropDownObject.columnName = product['columnName'];
		this.dropDownObject.dropDownValue = product['dropDownValue'];
		this.dropDownObject.createdBy = product['createdBy'];
		this.dropDownObject.createdDate = product['createdDate'];
		this.dropDownObject.updatedBy = product['updatedBy'];
		this.dropDownObject.updatedDate = product['updatedDate'];
		this.ctbDropDownForm.patchValue({dropDownValue: product['dropDownValue']});
		this.display = true;
	}

	updateData() {
		this.dropDownObject.dropDownValue = this.ctbDropDownForm.get('dropDownValue').value;
		if (this.validateData(this.dropDownObject)) {
			this.clearToBuildAdminService.updateCtbDropDownData(this.dropDownObject).subscribe((data) => {
				this.response = data;
				console.log(this.response);
				if (JSON.parse(this.response.success)) {
					this.display = false;
					this.pushDialog(this.response.message);
					this.findAllCtbDropDownData();
				} else {
					this.pushDialog(this.response.message);
				}
			});
		} else {
			this.pushMessage('error', 'Error', 'Please fill the mandatory field');
		}
	}

	deleteData(product: any) {
		this.clearToBuildAdminService.deleteCtbDropDownData(product['id']).subscribe((data) => {
			this.response = data;
			console.log(this.response);
			if (JSON.parse(this.response.success)) {
				this.pushDialog(this.response.message);
				this.findAllCtbDropDownData();
			} else {
				this.pushDialog(this.response.message);
			}
		});
	}

	deleteSelectedData(dropDownColumnName: string) {
		let selectedDropDownData: any[];
		if (dropDownColumnName === this.dropDownColumnName1) {
			selectedDropDownData = this.selectedPurchasingCoe;
		} else {
			selectedDropDownData = this.selectedPurchasingCoeManager;
		}
		if (selectedDropDownData.length > 0) {
			const idList = [];
			for (const item of selectedDropDownData) {
				idList.push(item.id);
			}
			this.clearToBuildAdminService.deleteCtbDropDownData(idList.join(',')).subscribe((data) => {
				this.response = data;
				console.log(this.response);
				if (JSON.parse(this.response.success)) {
					this.pushDialog(this.response.message);
					this.selectedPurchasingCoe = [];
					this.selectedPurchasingCoeManager = [];
					this.findAllCtbDropDownData();
				} else {
					this.pushDialog(this.response.message);
				}
			});
		}
	}

	disableDialog() {
		this.display = false;
		this.ctbDropDownForm.reset();
	}

	pushDialog(dialogBody: any) {
		this.ref = this.dialogService.open(CustomDialogComponent, {
			header: 'CTB Admin Dropdown Config - Response',
			footer: '',
			data: dialogBody,
			width: '50%',
			contentStyle: {
				'max-height': '1000px',
				overflow: 'auto'
			},
			baseZIndex: 10000,
		});
	}

	validateData(dropDownObject: CtbDropdown): boolean {
		return dropDownObject.dropDownValue !== null && dropDownObject.dropDownValue.length > 0;
	}

	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 60000
		});
	}

	showPurchasingCoeDataFilters() {
		this.isDisplayPurchasingCoeFilterData = !this.isDisplayPurchasingCoeFilterData;
	}

	showPurchasingCoeManagerDataFilters() {
		this.isDisplayPurchasingCoeManagerFilterData = !this.isDisplayPurchasingCoeManagerFilterData;
	}

	closeDialog() {
		this.displayDeleteDialog = false;
	}

	deleteConfirmationDialog(product: any) {
		this.isMultiDelete = false;
		this.displayDeleteDialog = true;
		this.deleteObject = product;
	}

	multiDeleteConfirmationDialog(dropDownColumnName: string) {
		this.deletionDropDownColumnName = dropDownColumnName;
		this.isMultiDelete = true;
		this.displayDeleteDialog = true;
	}

	proceedDeletion() {
		this.displayDeleteDialog = false;
		if (this.isMultiDelete) {
			this.deleteSelectedData(this.deletionDropDownColumnName);
		} else {
			this.deleteData(this.deleteObject);
		}
	}
}
