export class ClearToBuildAdmin {
	id: number;
	ctbId: string;
	ctbIsActive: string;
	launchMonth: string;
	launchWeek: string;
	supplierName: string;
	supplierCode: string;
	purchasingCoeLead: string;
	vehicleName: string;
	program: string;
	partCategory: string;
	businessUnit: string;
	mpnlCoeLead: string;
	commodityCode: string;
	pmtCategory: string;
	pmtName: string;
	buyer: string;
	buyerManager: string;
	ciBuyer: string;
	vpBuyer: string;
	productionSupplierContact: string;
	phNumber1: string;
	mblNumber1: string;
	plantManager: string;
	phNumber2: string;
	mblNumber2: string;
	mpnlManager: string;
	phNumber3: string;
	mblNumber3: string;
	corporateDeliveryChampion: string;
	phNumber4: string;
	mblNumber4: string;
	respondedContact: string;
	designation: string;
	contactNumber5: string;
	supplierEmailIdTo: string;
	supplierEmailIdCc: string;
	fordUsersEmailTo: string;
	fordUsersEmailCC: string;
	// admin proj fields
	projId: number;
	weekNumber: number;
	weekDate: Date;
	ctbMailSentDateAndTime: Date;
	followupDate1: Date;
	followupDate2: Date;
	supplierResponse: string;
	alignedForWpp: string;
	notAlignedReason: string;
	fileUploadedDateAndTime: Date;
	remarks: string;
	supportabilityRemarks: string;
	shipmentPlanAvailability: string;
	shortfallStartDate: string;
	rootCause: string;
	factors: string;
	actionInitiated: string;
	capacityConstraints: string;
	backlogRecovery: string;
	additionalRemarks: string;
	responseCategory: string;
	escalated: string;
	escalationStatus: string;
	escalationReason: string;
	opsBuyerMailDate: string;
	opsBuyerMeetingDate: string;
	opsSupplierJoinedMeeting: string;
	opsBuyerJoinedMeeting: string;
	opsBuyerActioned: string;
	opsSupplierResponded: string;
	opsWppFileReceived: string;
	ciBuyerMailDate: string;
	ciBuyerMeetingDate: string;
	ciSupplierJoinedMeeting: string;
	ciBuyerJoinedMeeting: string;
	ciBuyerActioned: string;
	ciSupplierResponded: string;
	ciWppFileReceived: string;
	level3EscalationDate: string;
	constraintClosureDate: string;
	createdBy: string;
	createdDate: Date;
	updatedBy: string;
	updatedDate: Date;
	reportDownloadedBy: string;
	reportUploadedBy: string;
	automationStatus: string;
	constructor(clearToBuildAdmin: ClearToBuildAdmin) {
		Object.assign(this, clearToBuildAdmin);
	}
}
