import {NgModule} from '@angular/core';
import {
	RouterModule,
	Routes
} from '@angular/router';
import {AuthGuardService} from '../../oauth/auth-guard.service';
import {TigerSharkConstraintTrackerComponent} from './tiger-shark-constraint-tracker/tiger-shark-constraint-tracker.component';
// tslint:disable-next-line:max-line-length
import {TigerSharkConstraintTrackerAdminComponent} from './tiger-shark-constraint-tracker-admin/tiger-shark-constraint-tracker-admin.component';
// tslint:disable-next-line:max-line-length
import {TigerSharkConstraintTrackerDashboardComponent} from './tiger-shark-constraint-tracker-dashboard/tiger-shark-constraint-tracker-dashboard.component';
// tslint:disable-next-line:max-line-length
import {SchedularComponent} from './schedular/schedular.component';

const routes: Routes = [
	{
		path: 'tiger-shark-constraint-tracker',
		component: TigerSharkConstraintTrackerComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'tiger-shark-constraint-tracker-admin',
		component: TigerSharkConstraintTrackerAdminComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'tiger-shark-constraint-tracker-dashboard',
		component: TigerSharkConstraintTrackerDashboardComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'scheduler',
		component: SchedularComponent,
		canActivate: [AuthGuardService]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class TigerSharkConstraintTrackerRoutingModule {
}
