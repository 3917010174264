/* tslint:disable */
//don't run checks here as the JSON output comes direct from the survey tool.
//we want to preserve the formatting to match
export const surveyJSON = {
	'pages': [
		{
			'name': 'pageSite',
			'elements': [
				{
					'type': 'radiogroup',
					'name': 'isSiteOpenPageSite',
					'title': 'Is the Site currently running production either fully or partially?. If No - What is the current opening assumption date? If running partially - Please provide partial operation details in the \'Site Readiness Comments\'  section.',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'openingAssumptionDatePageSite',
					'visible': false,
					'visibleIf': '{isSiteOpenPageSite} = \'no\'',
					'width': '10',
					'title': 'Opening Assumption (Best Estimation) date. If ready & waiting for Ford, enter today\'s date. ',
					'isRequired': true,
					'requiredIf': '{isSiteOpenPageSite} = \'no\'',
					'inputType': 'date',
					'max': '2999-12-31'
				},
				{
					'type': 'radiogroup',
					'name': 'doesSiteAllowFordStaPageSite',
					'title': 'Does your site currently allow Ford STA Visitors ? If you have concerns to allow STA now, please provide the best time for visit in the \'Site Readiness Comments\'  section.',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'radiogroup',
					'name': 'overallRatingPageSite',
					'title': 'Site Functioning Rating',
					'isRequired': true,
					'choices': [
						{
							'value': 'G',
							'text': 'Green - Site is currently running production.'
						},
						{
							'value': 'Y',
							'text': 'Yellow - Site is ready, able & permitted to start production when Ford requires parts. '
						},
						{
							'value': 'R',
							'text': 'Red - Site is not ready, not able or permitted to start production when Ford requires parts.'
						}
					]
				},
				{
					'type': 'comment',
					'name': 'overallcommentsPageSite',
					'title': 'Site Readiness Comments (Please provide additional details as necessary).',
					'rows': 3
				}
			],
			'title': 'Site Status'
		},
		{
			'name': 'pageC9PlanningAndManagement',
			'elements': [
				{
					'type': 'radiogroup',
					'name': 'emergencyResponseTeamInPlacePageC9PlanningAndManagement',
					'title': 'Emergency Response Team & Reporting Plan is established to track confirmed /suspected /quarantined cases? Reporting plan also includes customer communication. Also site specific protocols are developed.',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatAndWhenIsThePlanEmergencyResponse',
					'visibleIf': '{emergencyResponseTeamInPlacePageC9PlanningAndManagement} = \'yes\'',
					'title': 'If Yes - Please provide lead contact details.',
					'isRequired': true,
					'requiredIf': '{emergencyResponseTeamInPlacePageC9PlanningAndManagement} = \'yes\''
				},
				{
					'type': 'radiogroup',
					'name': 'identifiedAuthorizedCleaningServicePageC9PlanningAndManagement',
					'title': 'Have you Identified authorized cleaning service (internal or external)? If external is there a contract & Open PO is in place for immediate response ?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'radiogroup',
					'name': 'abletosupportfordproductionrequirements',
					'title': 'In the event of presumed COVID19 exposure, will you be able to support Ford production requirements without interruption?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'question1',
					'visibleIf': '{abletosupportfordproductionrequirements} = \'no\'',
					'title': 'If no, please provide details',
					'enableIf': '{abletosupportfordproductionrequirements} = \'no\'',
					'isRequired': true,
					'requiredIf': '{abletosupportfordproductionrequirements} = \'no\''
				},
				{
					'type': 'text',
					'name': 'estimatedMeanTimeToResumeProduction',
					'width': '10',
					'title': 'What is the estimated Mean time to resume production in the event of COVID exposure ? If you currently don\'t have this estimate but working on, please highlight the same in the comments section.',
					'description': 'in hours',
					'defaultValue': 0,
					'isRequired': true,
					'inputType': 'number',
					'min': '0',
					'max': '999'
				},
				{
					'type': 'radiogroup',
					'name': 'overallRatingPageC9PlanningAndManagement',
					'title': 'Overall Rating',
					'isRequired': true,
					'choices': [
						{
							'value': 'G',
							'text': 'Green - All questions are answered Yes  & comply to the stated  requirements'
						},
						{
							'value': 'Y',
							'text': 'Yellow - One or more questions are answered as No but the containment plan is either being developed or established '
						},
						{
							'value': 'R',
							'text': 'Red - One or more questions are answered as No but containment plan is not established'
						}
					]
				},
				{
					'type': 'comment',
					'name': 'OverallCommentsPageC9PlanningAndManagement',
					'title': 'Comments (Ex: Additional info on mean time to resume, potential interruption etc..) ',
					'rows': 3
				}
			],
			'title': 'COVID 19 Mitigation - Preventive & Reactive Measures',
			'description': 'Planning and Management'
		},
		{
			'name': 'pageC19ManagementPeople',
			'elements': [
				{
					'type': 'radiogroup',
					'name': 'communicationPlanEstablishedPageC19ManagementPeople',
					'title': 'In the event of a confirmed incident, do you have a Communication Plan – Initial and Ongoing (ability for immediate communication) established? Touchpoints with Group/Company for updates?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanCommEstablished',
					'visibleIf': '{communicationPlanEstablishedPageC19ManagementPeople} = \'no\'',
					'title': 'If no (What is the plan) ?'
				},
				{
					'type': 'radiogroup',
					'name': 'employeeEntrySurveyAbilityPageC19ManagementPeople',
					'title': 'In addition to following federal / local government requirements, does your company have the ability to confirm employee health status prior to employee entry to facility? E.g: Daily Screening (temperature scanning), Employee entry survey? ',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanEntrySurvey',
					'visibleIf': '{employeeEntrySurveyAbilityPageC19ManagementPeople} = \'no\'',
					'title': 'If no (What is the plan) ?',
					'enableIf': '{employeeEntrySurveyAbilityPageC19ManagementPeople} = \'no\''
				},
				{
					'type': 'radiogroup',
					'name': 'personalProtectionEquipmentAvailablePageC19ManagementPeople',
					'title': 'Is there an adequate quantity of Personal Protection Equipment (PPE) available onsite for all employees (Example: Masks, shields, gloves, etc.) and plans in place to maintain  required \n inventory for PPE?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanPpeAvailability',
					'visibleIf': '{personalProtectionEquipmentAvailablePageC19ManagementPeople} = \'no\'',
					'title': 'If no (What is the plan) ?',
					'enableIf': '{personalProtectionEquipmentAvailablePageC19ManagementPeople} = \'no\''
				},
				{
					'type': 'radiogroup',
					'name': 'overallRatingPageC19ManagementPeople',
					'title': 'Rating',
					'isRequired': true,
					'choices': [
						{
							'value': 'G',
							'text': 'Green - All questions are answered Yes  & comply to the stated  requirements'
						},
						{
							'value': 'Y',
							'text': 'Yellow - One or more questions are answered as No but the containment plan is either being developed or established '
						},
						{
							'value': 'R',
							'text': 'Red - One or more questions are answered as No but containment plan is not established'
						}
					]
				},
				{
					'type': 'comment',
					'name': 'OverallCommentsPageC19ManagementPeople',
					'title': 'Comments',
					'rows': 3
				}
			],
			'title': 'COVID 19 Mitigation - Preventive & Reactive Measures',
			'description': 'People'
		},
		{
			'name': 'pageC19ManagementFacility',
			'elements': [
				{
					'type': 'radiogroup',
					'name': 'entryExitProtocolEstablishedpPageC19ManagementFacility',
					'title': 'Facility Access Protocol established (Employees, visitors, logistics personnel - Anyone entering the facility)? Examples of protocol include Infrared thermometer checks if warranted; prohibiting access to employees demonstrating symptoms, exposure to COVID-19 case or travel in the last 14 days. Deactivate badge access- limiting door entry points? ',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanEntryExit',
					'visibleIf': '{entryExitProtocolEstablishedpPageC19ManagementFacility} = \'no\'',
					'title': 'If no (What is the plan) ?'
				},
				{
					'type': 'radiogroup',
					'name': 'reactionPlanEstablishedPageC19ManagementFacility',
					'title': 'Reaction plan is established if an incident occurs. Reaction plan includes contact tracing, home isolation, quarantine & return to work clearance. Complete checks to ensure site protocols are in place (Health & Safety, Operations) ?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanReactionPlan',
					'visibleIf': '{reactionPlanEstablishedPageC19ManagementFacility} = \'no\'',
					'title': 'If no (What is the plan) ?'
				},
				{
					'type': 'radiogroup',
					'name': 'cleanlinessPlanEstablishedForFacilityPageC19ManagementFacility',
					'title': 'Establish frequency for daily cleaning of high touch areas (e.g. Door handles, light switch, vending machines, refrigerator doors) , i.e. every 2-4 hours. Workstation pre and post shift cleaning and disinfecting?',
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPanCleanlinessStatus',
					'visibleIf': '{cleanlinessPlanEstablishedForFacilityPageC19ManagementFacility} = \'no\'',
					'title': 'If no (What is the plan) ?',
					'enableIf': '{cleanlinessPlanEstablishedForFacilityPageC19ManagementFacility} = \'no\''
				},
				{
					'type': 'radiogroup',
					'name': 'sufficientCleaningProductsAvailablePageC19ManagementFacility',
					'title': 'Are sufficient cleaning products available - Initial & Ongoing basis ?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanPeriodicCleanliness',
					'visibleIf': '{sufficientCleaningProductsAvailablePageC19ManagementFacility} = \'no\'',
					'title': 'If no (What is the plan) ?',
					'enableIf': '{sufficientCleaningProductsAvailablePageC19ManagementFacility} = \'no\''
				},
				{
					'type': 'radiogroup',
					'name': 'disciplinedZoneAreasPageC19ManagementFacility',
					'title': 'Has the site implemented social distancing policy? For example: Define disciplined ‘zone’ areas, employee mapping from gate to work station, utilize physical barriers and PPE as needed, stagger shift start time/lunch breaks  (consider expanding 5 day operations over 7 days with Ford Purchasing approval).  Maintain 6’ separation for employees, reinforce through signs, posters, markings on floor. Limit meetings to small groups (5), conduct large meetings through teleconference & WebEx? ',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanDisciplinedZone',
					'visibleIf': '{disciplinedZoneAreasPageC19ManagementFacility} = \'no\'',
					'title': 'If no (What is the plan) ?'
				},
				{
					'type': 'radiogroup',
					'name': 'reliefJobRotationPageC19ManagementFacility',
					'title': 'Relief / Job rotation plans established to maintain work cells & relief team is identified? ',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanReliefJob',
					'visibleIf': '{reliefJobRotationPageC19ManagementFacility} = \'no\'',
					'title': 'If no (What is the plan) ?'
				},
				{
					'type': 'radiogroup',
					'name': 'verifiedTieredSupplierPageC19ManagementFacility',
					'title': 'Verified tiered suppliers including Ford directed have COVID-19 mitigation & start up plans in place ?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanCanteenPublic',
					'visibleIf': '{verifiedTieredSupplierPageC19ManagementFacility} = \'no\'',
					'title': 'If no (What is the plan to complete & how many of them are at Risks) ?'
				},
				{
					'type': 'radiogroup',
					'name': 'overallRatingPageC19ManagementFacility',
					'title': 'Rating',
					'isRequired': true,
					'choices': [
						{
							'value': 'G',
							'text': 'Green - All questions are answered Yes  & comply to the stated  requirements'
						},
						{
							'value': 'Y',
							'text': 'Yellow - One or more questions are answered as No but the containment plan is either being developed or established'
						},
						{
							'value': 'R',
							'text': 'Red - One or more questions are answered as No but containment plan is not established'
						}
					]
				},
				{
					'type': 'comment',
					'name': 'OverallCommentsPageC19ManagementFacility',
					'title': 'Comments',
					'rows': 3
				}
			],
			'title': 'COVID 19 Mitigation - Preventive & Reactive Measures',
			'description': 'Facility'
		},
		{
			'name': 'pageSRPlanningManagement',
			'elements': [
				{
					'type': 'radiogroup',
					'name': 'shutdownStartupPlanpageSRPlanningManagement',
					'title': 'Startup management plan is available, has been formally reviewed, and validated with the management team.',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanShutdown',
					'visibleIf': '{shutdownStartupPlanpageSRPlanningManagement} = \'no\'',
					'title': 'If no (What is the plan)'
				},
				{
					'type': 'radiogroup',
					'name': 'reviewSchedulePageSRPlanningManagement',
					'title': 'Verified that tiered suppliers have established start up plans in place to support restart schedule?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanReviewSchedule',
					'visibleIf': '{reviewSchedulePageSRPlanningManagement} = \'no\'',
					'title': 'If no (What is the plan)'
				},
				{
					'type': 'radiogroup',
					'name': 'overallRatingPageSRPlanningManagement',
					'title': 'Planning Readiness Rating',
					'isRequired': true,
					'choices': [
						{
							'value': 'G',
							'text': 'Green - All questions are answered Yes  & comply to the stated  requirements'
						},
						{
							'value': 'Y',
							'text': 'Yellow - One or more questions are answered as No but the containment plan is either being developed or established'
						},
						{
							'value': 'R',
							'text': 'Red - One or more questions are answered as No but containment plan is not established'
						}
					]
				},
				{
					'type': 'comment',
					'name': 'OverallCommentsPageSRPlanningManagement',
					'title': 'Comments',
					'rows': 3
				}
			],
			'title': 'Site Readiness',
			'description': 'Planning / Management'
		},
		{
			'name': 'pageSRPeople',
			'elements': [
				{
					'type': 'radiogroup',
					'name': 'SPOCIdentifiedPageSRPeople',
					'title': 'Single Point of Contact identified (to answer customer questions during shutdown period)?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanSPOCIdentified',
					'visibleIf': '{SPOCIdentifiedPageSRPeople} = \'no\'',
					'title': 'If no (What is the plan)'
				},
				{
					'type': 'radiogroup',
					'name': 'emergencyContactListPageSRPeople',
					'title': 'Is there a contingency plan in place for a reduced labor force/ high absenteeism?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanEmergencyContactList',
					'visibleIf': '{emergencyContactListPageSRPeople} = \'no\'',
					'title': 'If no (What is the plan)'
				},
				{
					'type': 'radiogroup',
					'name': 'specificPeopleHaveBeenDesignatedPageSRPeople',
					'title': 'Specific people have been designated to perform all pre-startup activities?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanSpecificPeople',
					'visibleIf': '{specificPeopleHaveBeenDesignatedPageSRPeople} = \'no\'',
					'title': 'If no (What is the plan)',
					'enableIf': '{specificPeopleHaveBeenDesignatedPageSRPeople} = \'no\''
				},
				{
					'type': 'radiogroup',
					'name': 'planForProperMaintenancePageSRPeople',
					'title': 'Specific people have been designated to perform preventive maintenance activities?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanProperMaintenance',
					'visibleIf': '{planForProperMaintenancePageSRPeople} = \'no\'',
					'title': 'If no (What is the plan)'
				},
				{
					'type': 'radiogroup',
					'name': 'overallRatingPageSRPeople',
					'title': 'People Readiness Rating',
					'isRequired': true,
					'choices': [
						{
							'value': 'G',
							'text': 'Green - All questions are answered Yes  & comply to the stated  requirements'
						},
						{
							'value': 'Y',
							'text': 'Yellow - One or more questions are answered as No but the containment plan is either being developed or established'
						},
						{
							'value': 'R',
							'text': 'Red - One or more questions are answered as No but containment plan is not established'
						}
					]
				},
				{
					'type': 'comment',
					'name': 'OverallCommentsPageSRPeople',
					'title': 'Comments',
					'rows': 3
				}
			],
			'title': 'Site Readiness',
			'description': 'People'
		},
		{
			'name': 'pageSRSystems',
			'elements': [
				{
					'type': 'radiogroup',
					'name': 'customerReleaseSystemPageSRSystems',
					'title': 'Customer release system monitored daily for updated releases throughout shutdown (e.g. EDI)?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanCustomerRelease',
					'visibleIf': '{customerReleaseSystemPageSRSystems} = \'no\'',
					'title': 'If no (What is the plan)',
					'enableIf': '{customerReleaseSystemPageSRSystems} = \'no\''
				},
				{
					'type': 'radiogroup',
					'name': 'mpAndLPersonnelVerifyPageSRSystems',
					'title': 'Site logistics personnel verifies dunnage / expedite material status (Including quantities of dunnage accessible on-hand)? ',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanMpAndLPersonnelVerify',
					'visibleIf': '{mpAndLPersonnelVerifyPageSRSystems} = \'no\'',
					'title': 'If no (What is the plan)',
					'enableIf': '{mpAndLPersonnelVerifyPageSRSystems} = \'no\''
				},
				{
					'type': 'radiogroup',
					'name': 'overallRatingPageSRSystems',
					'title': 'Release Systems Rating',
					'isRequired': true,
					'choices': [
						{
							'value': 'G',
							'text': 'Green - All questions are answered Yes  & comply to the stated  requirements'
						},
						{
							'value': 'Y',
							'text': 'Yellow - One or more questions are answered as No but the containment plan is either being developed or established'
						},
						{
							'value': 'R',
							'text': 'Red - One or more questions are answered as No but containment plan is not established'
						}
					]
				},
				{
					'type': 'comment',
					'name': 'OverallCommentsPageSRSystems',
					'title': 'Comments',
					'rows': 3
				}
			],
			'title': 'Site Readiness',
			'description': 'Release Systems'
		},
		{
			'name': 'pageSRQuality',
			'elements': [
				{
					'type': 'radiogroup',
					'name': 'ensureInternalQuosPageSRQuality',
					'title': 'Ensure internal QOS is properly followed (prior to shutdown / at startup)?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanEnsureInternalQos',
					'visibleIf': '{ensureInternalQuosPageSRQuality} = \'no\'',
					'title': 'If no (What is the plan)',
					'enableIf': '{ensureInternalQuosPageSRQuality} = \'no\''
				},
				{
					'type': 'radiogroup',
					'name': 'increaseFrequencyOfInProcessPageSRQuality',
					'title': 'Increased frequency of in-process quality checks planned during the startup week?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanInreaseFrequencyOfInProcess',
					'visibleIf': '{increaseFrequencyOfInProcessPageSRQuality} = \'no\'',
					'title': 'If no (What is the plan)',
					'enableIf': '{increaseFrequencyOfInProcessPageSRQuality} = \'no\''
				},
				{
					'type': 'radiogroup',
					'name': 'validateOrRecalibratePageSRQuality',
					'title': 'Validate quality lab and in-process testing equipment prior to startup as required?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanValidateOrRecalibrate',
					'visibleIf': '{validateOrRecalibratePageSRQuality} = \'no\'',
					'title': 'If no (What is the plan)',
					'enableIf': '{validateOrRecalibratePageSRQuality} = \'no\''
				},
				{
					'type': 'radiogroup',
					'name': 'safeLaunchPlanDevelopedPageSRQuality',
					'title': 'CC & SC controls are re-verified for robustness during restart operation? ',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanSafeLaunchPlanDeveloped',
					'visibleIf': '{safeLaunchPlanDevelopedPageSRQuality} = \'no\'',
					'title': 'If no (What is the plan)',
					'enableIf': '{safeLaunchPlanDevelopedPageSRQuality} = \'no\''
				},
				{
					'type': 'radiogroup',
					'name': 'shippingRackDunnagePageSRQuality',
					'title': 'Shipping rack/dunnage inspection: Any repairs needed? Cleaning activities should be completed and reported?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPanShippingRackDunnage',
					'visibleIf': '{shippingRackDunnagePageSRQuality} = \'no\'',
					'title': 'If no (What is the plan)',
					'enableIf': '{shippingRackDunnagePageSRQuality} = \'no\''
				},
				{
					'type': 'radiogroup',
					'name': 'overallRatingPageSRQuality',
					'title': 'Quality Control Rating',
					'isRequired': true,
					'choices': [
						{
							'value': 'G',
							'text': 'Green - All questions are answered Yes  & comply to the stated  requirements'
						},
						{
							'value': 'Y',
							'text': 'Yellow - One or more questions are answered as No but the containment plan is either being developed or established'
						},
						{
							'value': 'R',
							'text': 'Red - One or more questions are answered as No but containment plan is not established'
						}
					]
				},
				{
					'type': 'comment',
					'name': 'OverallCommentsPageSRQuality',
					'title': 'Comments',
					'rows': 3
				}
			],
			'title': 'Site Readiness',
			'description': 'Quality Control'
		},
		{
			'name': 'pageSRMaterials',
			'elements': [
				{
					'type': 'radiogroup',
					'name': 'rawMaterialsAndPurchasedPartsPageSRMaterials',
					'title': 'Raw materials and purchased parts are at required inventory levels (if not, need plan to achieve)?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanRawMaterialsAndPurchasedParts',
					'visibleIf': '{rawMaterialsAndPurchasedPartsPageSRMaterials} = \'no\'',
					'title': 'If no (What is the plan)',
					'enableIf': '{rawMaterialsAndPurchasedPartsPageSRMaterials} = \'no\''
				},
				{
					'type': 'radiogroup',
					'name': 'rawMaterialsProcessMaterialsPageSRMaterials',
					'title': 'Raw materials, processed materials, in-process stock, and partial packs of finished goods checked at shutdown for identification of each container and ensuring FIFO? If you have any concerns with RM or purchased components, please highlight your concerns in the comments section below.',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanRawMaterialsProcessMaterials',
					'visibleIf': '{rawMaterialsProcessMaterialsPageSRMaterials} = \'no\'',
					'title': 'If no (What is the plan)',
					'enableIf': '{rawMaterialsProcessMaterialsPageSRMaterials} = \'no\''
				},
				{
					'type': 'radiogroup',
					'name': 'subTiersInformTheStatusPageSRMaterials',
					'title': 'Confirm Sub-Tiers communication of the status for material in transit, to avoid shortages or over inventory, before, during and after shutdown period?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanSubTiersInformTheStatus',
					'visibleIf': '{subTiersInformTheStatusPageSRMaterials} = \'no\'',
					'title': 'If no (What is the plan)',
					'enableIf': '{subTiersInformTheStatusPageSRMaterials} = \'no\''
				},
				{
					'type': 'radiogroup',
					'name': 'valueChainAssessmentsPageSRMaterials',
					'title': 'Value chain assessment including warehouse / shipping points are completed & all sites are functioning?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanValueChainAssessment',
					'visibleIf': '{valueChainAssessmentsPageSRMaterials} = \'no\'',
					'title': 'If no (What is the plan)',
					'enableIf': '{valueChainAssessmentsPageSRMaterials} = \'no\''
				},
				{
					'type': 'radiogroup',
					'name': 'overallRatingPageSRMaterials',
					'title': 'Material Readiness Rating',
					'isRequired': true,
					'choices': [
						{
							'value': 'G',
							'text': 'Green - All questions are answered Yes  & comply to the stated  requirements'
						},
						{
							'value': 'Y',
							'text': 'Yellow - One or more questions are answered as No but the containment plan is either being developed or established'
						},
						{
							'value': 'R',
							'text': 'Red - One or more questions are answered as No but containment plan is not established'
						}
					]
				},
				{
					'type': 'comment',
					'name': 'OverallCommentsPageSRMaterials',
					'title': 'Comments',
					'rows': 3
				}
			],
			'title': 'Site Readiness',
			'description': 'Materials'
		},
		{
			'name': 'pageSRMachineTooling',
			'elements': [
				{
					'type': 'radiogroup',
					'name': 'detailedPlanExistsPageSRMachineTooling',
					'title': 'A detailed plan exists for any planned preventative maintenance (identify all actions and validation methods)?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanDetailedPlanExists',
					'visibleIf': '{detailedPlanExistsPageSRMachineTooling} = \'no\'',
					'title': 'If no (What is the plan)',
					'enableIf': '{detailedPlanExistsPageSRMachineTooling} = \'no\''
				},
				{
					'type': 'radiogroup',
					'name': 'allSparePartsPageSRMachineTooling',
					'title': 'All spare parts / consumable items are at the required inventory levels prior to startup?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanAllSpareParts',
					'visibleIf': '{allSparePartsPageSRMachineTooling} = \'no\'',
					'title': 'If no (What is the plan)',
					'enableIf': '{allSparePartsPageSRMachineTooling} = \'no\''
				},
				{
					'type': 'radiogroup',
					'name': 'startupValidationPageSRMachineTooling',
					'title': 'Startup validation check sheet completed for all tooling/equipment (part of approved Safe Launch Plan)?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPanStartupValidation',
					'visibleIf': '{startupValidationPageSRMachineTooling} = \'no\'',
					'title': 'If no (What is the plan)',
					'enableIf': '{startupValidationPageSRMachineTooling} = \'no\''
				},
				{
					'type': 'radiogroup',
					'name': 'allInProcessControlPageSRMachineTooling',
					'title': 'All in-process control/monitoring systems (e.g., torque monitors, Poke-Yoke, etc…) are re-validated after shutdown period and prior to startup?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanAllInProcessControl',
					'visibleIf': '{allInProcessControlPageSRMachineTooling} = \'no\'',
					'title': 'If no (What is the plan)',
					'enableIf': '{allInProcessControlPageSRMachineTooling} = \'no\''
				},
				{
					'type': 'radiogroup',
					'name': 'allSpecialManufacturingProcessPageSRMachineTooling',
					'title': 'All Special Manufacturing Processes are re-validated after shutdown period. Special Manufacturing Processes include but are not limited to:\n',
					'description': '- Welding (All Types)\n- Electroplating\n- Plastic Molding\n- Machining\n- Heat Treating\n- Stamping\n- Painting\n- Electronic PCB & Final Asy\n- Casting',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanAllSpecialManufacturingProcess',
					'visibleIf': '{allSpecialManufacturingProcessPageSRMachineTooling} = \'no\'',
					'title': 'If no (What is the plan)',
					'enableIf': '{allSpecialManufacturingProcessPageSRMachineTooling} = \'no\''
				},
				{
					'type': 'radiogroup',
					'name': 'overallRatingPageSRMachineTooling',
					'title': 'Machine & Tooling Readiness Rating',
					'isRequired': true,
					'choices': [
						{
							'value': 'G',
							'text': 'Green - All questions are answered Yes  & comply to the stated  requirements'
						},
						{
							'value': 'Y',
							'text': 'Yellow - One or more questions are answered as No but the containment plan is either being developed or established'
						},
						{
							'value': 'R',
							'text': 'Red - One or more questions are answered as No but containment plan is not established'
						}
					]
				},
				{
					'type': 'comment',
					'name': 'OverallCommentsPageSRMachineTooling',
					'title': 'Comments',
					'rows': 3
				}
			],
			'title': 'Site Readiness',
			'description': 'Machine / Tooling'
		},
		{
			'name': 'PageSRFacility',
			'elements': [
				{
					'type': 'radiogroup',
					'name': 'aChampionToCheckPageSRFacility',
					'title': 'Supplier lead(s) designated to check the facility daily prior and during restart has been identified?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanAChampionToCheck',
					'visibleIf': '{aChampionToCheckPageSRFacility} = \'no\'',
					'title': 'If no (What is the plan)',
					'enableIf': '{aChampionToCheckPageSRFacility} = \'no\''
				},
				{
					'type': 'radiogroup',
					'name': 'electricalSwitchGearPageSRFacility',
					'title': 'Electrical switchgear, back-up generators and uninterruptable power supplies are properly aligned and set in the required mode for restart of production operation?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanSwitchGear',
					'visibleIf': '{electricalSwitchGearPageSRFacility} = \'no\'',
					'title': 'If no (What is the plan)',
					'enableIf': '{electricalSwitchGearPageSRFacility} = \'no\''
				},
				{
					'type': 'radiogroup',
					'name': 'allFireSafetyPageSRFacility',
					'title': 'All Fire, Safety and Security systems validated prior to startup and these systems remain functional during shutdown period?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'what_plan_all_fire_safety',
					'visibleIf': '{allFireSafetyPageSRFacility} = \'no\'',
					'title': 'If no (What is the plan)'
				},
				{
					'type': 'radiogroup',
					'name': 'allPersonalSafetyPageSRFacility',
					'title': 'All Personnel Safety systems validated at shutdown and prior to startup (i.e., eye wash stations, generators, etc.) and these systems remain functional during shutdown period ?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanAllPersonalSafety',
					'visibleIf': '{allPersonalSafetyPageSRFacility} = \'no\'',
					'title': 'If no (What is the plan)',
					'enableIf': '{allPersonalSafetyPageSRFacility} = \'no\''
				},
				{
					'type': 'radiogroup',
					'name': 'overallRatingPageSRFacility',
					'title': 'Facility Support System Readiness Rating',
					'isRequired': true,
					'choices': [
						{
							'value': 'G',
							'text': 'Green - All questions are answered Yes  & comply to the stated  requirements'
						},
						{
							'value': 'Y',
							'text': 'Yellow - One or more questions are answered as No but the containment plan is either being developed or established'
						},
						{
							'value': 'R',
							'text': 'Red - One or more questions are answered as No but containment plan is not established'
						}
					]
				},
				{
					'type': 'comment',
					'name': 'OverallCommentsPageSRFacility',
					'title': 'Comments',
					'rows': 3
				}
			],
			'title': 'Site Readiness',
			'description': 'Facility Support System'
		},
		{
			'name': 'pageSRMajorChanges',
			'elements': [
				{
					'type': 'radiogroup',
					'name': 'writtenValidationRelocatedToolingPageSRMajorChanges',
					'title': 'A written validation and customer-approved plan (SREA) exists for any tryout of new, rebuilt or relocated tooling/equipment?',
					'isRequired': true,
					'choices': [
						{
							'value': 'yes',
							'text': 'Yes'
						},
						{
							'value': 'no',
							'text': 'No'
						}
					]
				},
				{
					'type': 'text',
					'name': 'whatPlanAWrittenValidationRelocatedTooling',
					'visibleIf': '{writtenValidationRelocatedToolingPageSRMajorChanges} = \'no\'',
					'title': 'If no (What is the plan)',
					'enableIf': '{writtenValidationRelocatedToolingPageSRMajorChanges} = \'no\''
				},
				{
					'type': 'radiogroup',
					'name': 'overallRatingPageSRMajorChanges',
					'title': 'Major Facility Changes Rating',
					'isRequired': true,
					'choices': [
						{
							'value': 'G',
							'text': 'Green - All questions are answered Yes  & comply to the stated  requirements'
						},
						{
							'value': 'Y',
							'text': 'Yellow - One or more questions are answered as No but the containment plan is either being developed or established'
						},
						{
							'value': 'R',
							'text': 'Red - One or more questions are answered as No but containment plan is not established'
						}
					],
					'otherText': 'Yellow - '
				},
				{
					'type': 'comment',
					'name': 'overAllComments',
					'title': 'Comments',
					'titleLocation': 'top'
				}
			],
			'title': 'Site Readiness',
			'description': 'Major Facility Changes'
		},
		{
			'name': 'PageSROverall',
			'elements': [
				{
					'type': 'radiogroup',
					'name': 'overallRatingPageSROverall',
					'title': 'Overall Site Readiness Rating',
					'isRequired': true,
					'choices': [
						{
							'value': 'item1',
							'text': 'Green - Site currently meets an established COVID-19 Mitigation Plan, complies to the Site Readiness Expectations AND is ready to support Ford production.'
						},
						{
							'value': 'item2',
							'text': 'Yellow - Site establishing COVID-19 Mitigation Plan and Site Readiness compliance with some validation required AND is ready to support Ford production – Please explain in COMMENTS box below and specify a Date-To-Green.'
						},
						{
							'value': 'item3',
							'text': 'Red - Site is NOT ready to support Ford production -- Please explain in COMMENTS box below and specify a Date-To-Green.'
						}
					]
				},
				{
					'type': 'text',
					'name': 'datetogreenPageSROverall',
					'visibleIf': '{overallRatingPageSROverall} = \'item2\' or {overallRatingPageSROverall} = \'item3\'',
					'width': '10',
					'title': 'For any RED or YELLOW status elements within the supplier’s or sub-supplier’s control, please specify your Target Date when all elements will meet Green status. (Please provide date in MM/DD/YYYY format)',
					'isRequired': true,
					'requiredIf': '{overallRatingPageSROverall} = \'item2\' or {overallRatingPageSROverall} = \'item3\'',
					'inputType': 'date',
					'max': '2999-12-31'
				},
				{
					'type': 'comment',
					'name': 'overallCommentsPageSROverall',
					'title': 'Overall Survey Comments (Site facility restart, COVID preventive actions, people..etc.)',
					'isRequired': true
				}
			],
			'title': 'Overall Survey Summary',
			'description': 'Overall Survey Summary & Assessment Rating'
		}
	],
	'showPageNumbers': true,
	'showProgressBar': 'top',
	'questionTitlePattern': 'requireNumTitle'
};

export const lrSurveyJSON = {
	'pages': [
		{
			'name': 'pageLaunchReadiness',
			'elements': [
				{
					'type': 'dropdown',
					'name': 'attendancetrends',
					'title': 'Attendance Trends - Of key people in the launch sites; includes agency and/or direct hire employees released/furloughed - if there is a declining trend or cause for concern? (Detail any concerns in the comments section)',
					'isRequired': true,
					'choices': [
						{
							'value': 'G',
							'text': 'Green - No declining trend. Trend follows normal operating numbers'
						},
						{
							'value': 'Y',
							'text': 'Yellow - Some declining trend. Issue currently under monitor. PPAP timing delay not forecasted'
						},
						{
							'value': 'R',
							'text': 'Red - Significant decline trend. Actions under investigation'
						}
					]
				},
				{
					'type': 'comment',
					'name': 'attendancetrendscomments',
					'title': 'Comments',
					'isRequired': true,
					'rows': 2
				},
				{
					'type': 'dropdown',
					'name': 'hiringissues',
					'title': 'Hiring Issues - Any issues hiring the required personnel (due to released employees) to support launch timing with trained personnel? (Include any containment actions required in comments)',
					'isRequired': true,
					'choices': [
						{
							'value': 'G',
							'text': 'Green - Hiring is on track as per orginal plan (Including people required for all shifts) or all Hiring is completed'
						},
						{
							'value': 'Y',
							'text': 'Yellow - Some delay to orginal hiring plan. Plans are in place to recover & support required elements of Phased PPAP in line with Program Timing'
						},
						{
							'value': 'R',
							'text': 'Red - Significant delay to hiring plan. Actions for recovery are under investigation'
						}
					]
				},
				{
					'type': 'comment',
					'name': 'hiringissuescomments',
					'title': 'Comments',
					'isRequired': true,
					'rows': 2
				},
				{
					'type': 'dropdown',
					'name': 'nonhomelinetools',
					'title': 'Non-Home line Tools / BOP Elements (tooling/machines/gages) - what is the status of shipping of the final tools / equipment to Home line - are there challenges associated with the completion - shipment of hardware vs original timing.  (Detail the name and location of the company holding the material in the comments section)',
					'isRequired': true,
					'choices': [
						{
							'value': 'G',
							'text': 'Green - All Tools & Equipments are currently homeline or will be homeline as per orginal plan'
						},
						{
							'value': 'Y',
							'text': 'Yellow - Some delay to previously submitted plan. Plans are in place to recover & support the Phase 1 & Phase 3 as per orginal plan'
						},
						{
							'value': 'R',
							'text': 'Red - Significant delay compared to orginal plan. Actions for recovery are under investigation'
						}
					]
				},
				{
					'type': 'comment',
					'name': 'nonhomelinecomments',
					'title': 'Comments',
					'isRequired': true,
					'rows': 2
				},
				{
					'type': 'dropdown',
					'name': 'supplychainimpacts',
					'title': 'Do you have any Sub Tier Supply Chain impact due to raw material or component shortages (ex. PPAP timing delays, part availability for next build event, etc) due to COVID-19 situation? (Detail any concerns in the comments section)',
					'isRequired': true,
					'choices': [
						{
							'value': 'G',
							'text': 'Green - No Supply chain impacts including Raw Mtl or tier 2 parts'
						},
						{
							'value': 'Y',
							'text': 'Yellow - Some delays with either Raw Mtl or Tier 2 readiness. No impact to PPAP timing '
						},
						{
							'value': 'R',
							'text': 'Red - Significant delay to either Raw Mtl or Tier 2 readiness. No recovery plans. Impacts PPAP timing\t\t'
						}
					]
				},
				{
					'type': 'comment',
					'name': 'supplychainimpactcomments',
					'title': 'Comments',
					'isRequired': true,
					'rows': 2
				},
				{
					'type': 'dropdown',
					'name': 'downtime',
					'title': 'Do you have any Equipment prove out or production trial(s) delays as a result of cancelled travel plans from subject matter experts? (Detail any concerns and recovery plans / PPAP risk in the comments section)',
					'isRequired': true,
					'choices': [
						{
							'value': 'G',
							'text': 'Green - No delays to equipment prove out plans. Timing on track'
						},
						{
							'value': 'Y',
							'text': 'Yellow - Some delays with equipment proveouts. Recovery plan is in place'
						},
						{
							'value': 'R',
							'text': 'Red - Significant delay to equipment prove outs & PPAP timing is delayed'
						}
					]
				},
				{
					'type': 'comment',
					'name': 'downtimecomments',
					'title': 'Comments',
					'isRequired': true,
					'rows': 2
				},
				{
					'type': 'dropdown',
					'name': 'toolmovement',
					'title': 'Tool Movement for Engineering Changes - Do you have any issues with moving tools within specific regions due to COVID-19? (Detail any concerns in the comments section)',
					'isRequired': true,
					'choices': [
						{
							'value': 'G',
							'text': 'Green - No issues in supprting tooling changes driven by Engg changes'
						},
						{
							'value': 'Y',
							'text': 'Yellow - Few tool rooms are affected & alternate plans to support engg changes under investigation'
						},
						{
							'value': 'R',
							'text': 'Red - Significant issues with tool room availability. Alternate plans under investigation. Potential delay anticipated'
						}
					]
				},
				{
					'type': 'comment',
					'name': 'toolmovementcomments',
					'title': 'Comments',
					'isRequired': true,
					'rows': 2
				},
				{
					'type': 'dropdown',
					'name': 'issiterunning',
					'title': 'Is Production Site online to support launch activities?  If shutdown, add the anticipated start up date in the comments section',
					'isRequired': true,
					'choices': [
						{
							'value': 'G',
							'text': 'Green - Site is currrently running for launch activities'
						},
						{
							'value': 'Y',
							'text': 'Yellow - Site is not currently running for launch activities. Some remote work is happening. Start date based on government clearance'
						},
						{
							'value': 'R',
							'text': 'Red - Site is not running and all Launch activities are stopped temproraily. Not projected return data available. Potential impact to Launch'
						}
					]
				},
				{
					'type': 'comment',
					'name': 'issiterunningcomments',
					'title': 'Comments',
					'isRequired': true,
					'rows': 2
				},
				{
					'type': 'dropdown',
					'name': 'industrialization',
					'title': 'Is the Industrialization / New Model activities underway at the production site? (ex. Plant down but New Models is operating or All activities shutdown, etc.)',
					'isRequired': true,
					'choices': [
						{
							'value': 'G',
							'text': 'Green - Industrialization activities are currently happening'
						},
						{
							'value': 'Y',
							'text': 'Yellow - Site is not supporting any activities. Some remote work is happening. Start date based on government clearance'
						},
						{
							'value': 'R',
							'text': 'Red - All activities are stopped temproraily. Not projected return data available. Potential impact to Launch'
						}
					]
				},
				{
					'type': 'comment',
					'name': 'question2',
					'title': 'Comments',
					'isRequired': true,
					'rows': 2
				},
				{
					'type': 'dropdown',
					'name': 'isprogramteamworking',
					'title': 'Is the Program Team still working at full strength?',
					'isRequired': true,
					'choices': [
						{
							'value': 'G',
							'text': 'Green - Program team is working either onsite or remotely'
						},
						{
							'value': 'Y',
							'text': 'Yellow -  Partial team engaged either on site or remotely. '
						},
						{
							'value': 'R',
							'text': 'Red - Program team is not working either onsite or remotely'
						}
					]
				},
				{
					'type': 'comment',
					'name': 'isprogramteamworkingcomments',
					'title': 'Comments',
					'isRequired': true,
					'rows': 2
				},
				{
					'type': 'dropdown',
					'name': 'integrators',
					'title': 'Are the Integrators and Tool Shops still operating? (Include any delays in the comments section)',
					'isRequired': true,
					'choices': [
						{
							'value': 'G',
							'text': 'Green - Integrators & Tool shops are currently operating & not expect significant delays'
						},
						{
							'value': 'Y',
							'text': 'Yellow -  Integrators & Tool shops are partially operating & delays are anticipated'
						},
						{
							'value': 'R',
							'text': 'Red - Integrators & Tool shops are not operating & significant delays anticipated, or startup date is not defined'
						}
					]
				},
				{
					'type': 'comment',
					'name': 'integratorscomments',
					'title': 'Comments',
					'isRequired': true,
					'rows': 2
				},
				{
					'type': 'dropdown',
					'name': 'forecastppap',
					'title': 'Do you Forecast any PPAP timing delay?   If yes, will the next build event be impacted with lack of parts at the latest pedigree for the build? (Include any delays or pedigree risk in the comments section)',
					'isRequired': true,
					'choices': [
						{
							'value': 'G',
							'text': 'Green - PPAP timing delay not forecasted'
						},
						{
							'value': 'Y',
							'text': 'Yellow -  Some delay forcasted & next build pedigree not impacted'
						},
						{
							'value': 'R',
							'text': 'Red - Major PPAP delay forecasted or build event will not be supported with approved pedigree of parts. No recovery plan in place'
						}
					]
				},
				{
					'type': 'comment',
					'name': 'forecastppapcomments',
					'title': 'Comments',
					'isRequired': true,
					'rows': 2
				}
			],
			'title': 'Launch Readiness'
		}
	]
};
