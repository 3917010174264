import {NgModule} from '@angular/core';
import {DashboardComponent} from './dashboard.component';
import {DashboardService} from './services/dashboard.service';
import {PanelModule} from 'primeng/panel';
import {TableModule} from 'primeng/table';
import {CommonModule} from '@angular/common';
import {ButtonModule} from 'primeng/button';
import {TooltipModule} from 'primeng/tooltip';
import {ChartModule} from 'primeng/chart';

@NgModule({
	declarations: [DashboardComponent],
	imports: [
		PanelModule,
		TableModule,
		CommonModule,
		ButtonModule,
		TooltipModule,
		ChartModule
	],
	providers: [DashboardService]
})
export class DashboardModule {
}
