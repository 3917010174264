import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {OauthModule} from './oauth/oauth.module';
import {HomeModule} from './home/home.module';
import {
	HTTP_INTERCEPTORS,
	HttpClientModule
} from '@angular/common/http';
import {HttpRequestInterceptor} from './services/http-request-interceptor';
import {UserIdService} from './services/user-id.service';
import {NavComponent} from './nav/nav.component';
import {ButtonModule} from 'primeng/button';
import {MenubarModule} from 'primeng/menubar';
import {PanelMenuModule} from 'primeng/panelmenu';
import {GrowlModule} from 'primeng/growl';
import {SidebarModule} from 'primeng/sidebar';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HomeService} from './home/services/home.service';
import {DashboardAuthzService} from './services/dashboard-authz.service';
import {DashboardModule} from './dashboard/dashboard.module';
import {HelpComponent} from './help/help.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ChartModule} from 'primeng/chart';
import {LandingComponent} from './landing/landing.component';
import {MessageModule} from 'primeng/message';
import {FieldsetModule} from 'primeng/fieldset';
import {CardModule} from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import {FormsModule} from '@angular/forms';
import {BrowserIncompatableModule} from './browser-incompatable/browser-incompatable.module';
// tslint:disable-next-line:import-blacklist
import {SplitButtonModule, TabMenuModule} from 'primeng/primeng';
import {
	ConfirmationService,
	DialogService,
	MessageService
} from 'primeng/api';
import {Tier1EComponentShortageModule} from './tier1-visibility-survey/tier1-e-component-shortage/tier1-e-component-shortage.module';
import {DatePipe} from '@angular/common';
import {SmashedDataReportingModule} from './tier1-visibility-survey/smashed-data-reporting/smashed-data-reporting.module';
import {NoAccessForNTierComponent} from './tier1-visibility-survey/no-access-for-n-tier/no-access-for-n-tier.component';
// tslint:disable-next-line:max-line-length
import {TigerSharkConstraintTrackerModule} from './tier1-visibility-survey/tiger-shark-constraint-tracker/tiger-shark-constraint-tracker.module';
import {ValueStreamMapModule} from './tier1-visibility-survey/value-stream-map/value-stream-map.module';
import {T2VisibilityModule} from './tier1-visibility-survey/t2-visibility/t2-visibility.module';
import { LogoutComponent } from './logout/logout.component';
import {
	DynamicDialogModule
} from 'primeng/dynamicdialog';
import { CustomDialogComponent } from './custom-dialog/custom-dialog/custom-dialog.component';
import {SustainabilitySurveyModule} from './tier1-visibility-survey/sustainability/sustainability-survey.module';
import { QlikSenseDashboardComponent } from './qlik-sense-dashboard/qlik-sense-dashboard.component';
import {ClearToBuildModule} from './tier1-visibility-survey/clear-to-build/clear-to-build.module';

@NgModule({
	declarations: [
		AppComponent,
		NavComponent,
		HelpComponent,
		LandingComponent,
		NoAccessForNTierComponent,
		LogoutComponent,
		CustomDialogComponent,
		QlikSenseDashboardComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		OauthModule,
		HttpClientModule,
		HomeModule,
		DashboardModule,
		AppRoutingModule,
		ButtonModule,
		MenubarModule,
		PanelMenuModule,
		GrowlModule,
		SidebarModule,
		ChartModule,
		ValueStreamMapModule,
		T2VisibilityModule,
		SustainabilitySurveyModule,
		ProgressSpinnerModule,
		MessageModule,
		FieldsetModule,
		CardModule,
		BrowserIncompatableModule,
		Tier1EComponentShortageModule,
		TabMenuModule,
		SmashedDataReportingModule,
		TigerSharkConstraintTrackerModule,
		DynamicDialogModule,
		ClearToBuildModule,
		DialogModule,
		FormsModule,
		SplitButtonModule
	],
	providers: [
		DatePipe,
		UserIdService,
		HomeService,
		DashboardAuthzService,
		ConfirmationService,
		MessageService,
		DialogService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpRequestInterceptor,
			multi: true
		}],
	bootstrap: [AppComponent],
	entryComponents: [CustomDialogComponent],
})
export class AppModule {
}
