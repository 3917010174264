import {Component, OnInit} from '@angular/core';

// noinspection AngularMissingOrInvalidDeclarationInModule
@Component({
	selector: 'browser-incompatable',
	templateUrl: './browser-incompatable.component.html',
	styleUrls: ['./browser-incompatable.component.scss']
})
export class BrowserIncompatableComponent implements OnInit {

	constructor() {
	}

	ngOnInit() {
	}

}
