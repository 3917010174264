import {NgModule} from '@angular/core';
import {
	RouterModule,
	Routes
} from '@angular/router';

import {SmashedDataDashboardComponent} from './smashed-data-dashboard/smashed-data-dashboard.component';
import {AuthGuardService} from '../../oauth/auth-guard.service';
const routes: Routes = [
	{
		path: 'smashed-data-reporting',
		component: SmashedDataDashboardComponent,
		canActivate: [AuthGuardService]
	}
];
@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class SmashedDataReportingRoutingModule {
}
