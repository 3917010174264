import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DashboardData} from '../model/dashboard';

@Injectable({
	providedIn: 'root'
})
export class DashboardService {
	/*
		mockJSONData = [
			{
				"mfgGSDBCode": "AB001",
				"mfgSiteName": "AP24A-Hermosillo",
				"mfgCity": "Dearborn",
				"mfgCountry": "USA",
				"vehiclesSupported": "F150",
				"fordPlantsSupported": "DTP",
				"plantManagerContactName": "cyourman@ford.com",
				"plantManagerEmail": "cyourman",
				"assessmentPersonName": "cyourman@ford.com",
				"assessmentPersonEmail": "cyourman@ford.com",
				"assessmentDate": "2020-04-10T12:21:15.443+0000",
				"siteOpen": "Y",
				"productionStartDate": null,
				"visitors": "Y",
				"management": "G",
				"timeToResume": "4",
				"people": "G",
				"facility": "G",
				"management2": "G",
				"labor": "G",
				"systems": "G",
				"quality": "G",
				"material": " ",
				"equipment": "G",
				"facilities": "G",
				"majorChanges": "G",
				"overallComments": "No Challenges",
				"assessmentRating": null
			},
			{
				"mfgGSDBCode": "AB006",
				"mfgSiteName": "Ford-Ohio",
				"mfgCity": "Dearborn",
				"mfgCountry": "USA",
				"vehiclesSupported": "Mustang",
				"fordPlantsSupported": "DTP",
				"plantManagerContactName": "cyourman@ford.com",
				"plantManagerEmail": "cyourman",
				"assessmentPersonName": "cyourman@ford.com",
				"assessmentPersonEmail": "cyourman@ford.com",
				"assessmentDate": "2020-04-10T12:21:15.444+0000",
				"siteOpen": "N",
				"productionStartDate": null,
				"visitors": "Y",
				"management": "G",
				"timeToResume": "4",
				"people": "G",
				"facility": "G",
				"management2": "G",
				"labor": "G",
				"systems": "G",
				"quality": "G",
				"material": " ",
				"equipment": "G",
				"facilities": "G",
				"majorChanges": "G",
				"overallComments": "No Challenges",
				"assessmentRating": null
			},
			{
				"mfgGSDBCode": "AB001",
				"mfgSiteName": "AP24A-Hermosillo",
				"mfgCity": "Dearborn",
				"mfgCountry": "USA",
				"vehiclesSupported": "F150",
				"fordPlantsSupported": "DTP",
				"plantManagerContactName": "cyourman@ford.com",
				"plantManagerEmail": "cyourman",
				"assessmentPersonName": "cyourman@ford.com",
				"assessmentPersonEmail": "cyourman@ford.com",
				"assessmentDate": "2020-04-10T12:21:15.443+0000",
				"siteOpen": "Y",
				"productionStartDate": null,
				"visitors": "Y",
				"management": "G",
				"timeToResume": "4",
				"people": "G",
				"facility": "G",
				"management2": "G",
				"labor": "G",
				"systems": "G",
				"quality": "G",
				"material": " ",
				"equipment": "G",
				"facilities": "G",
				"majorChanges": "G",
				"overallComments": "No Challenges",
				"assessmentRating": null
			},
			{
				"mfgGSDBCode": "AB006",
				"mfgSiteName": "Ford-Ohio",
				"mfgCity": "Dearborn",
				"mfgCountry": "USA",
				"vehiclesSupported": "Mustang",
				"fordPlantsSupported": "DTP",
				"plantManagerContactName": "cyourman@ford.com",
				"plantManagerEmail": "cyourman",
				"assessmentPersonName": "cyourman@ford.com",
				"assessmentPersonEmail": "cyourman@ford.com",
				"assessmentDate": "2020-04-10T12:21:15.444+0000",
				"siteOpen": "N",
				"productionStartDate": null,
				"visitors": "Y",
				"management": "G",
				"timeToResume": "4",
				"people": "G",
				"facility": "G",
				"management2": "G",
				"labor": "G",
				"systems": "G",
				"quality": "G",
				"material": " ",
				"equipment": "G",
				"facilities": "G",
				"majorChanges": "G",
				"overallComments": "No Challenges",
				"assessmentRating": null
			},
			{
				"mfgGSDBCode": "AB001",
				"mfgSiteName": "AP24A-Hermosillo",
				"mfgCity": "Dearborn",
				"mfgCountry": "USA",
				"vehiclesSupported": "F150",
				"fordPlantsSupported": "DTP",
				"plantManagerContactName": "cyourman@ford.com",
				"plantManagerEmail": "cyourman",
				"assessmentPersonName": "cyourman@ford.com",
				"assessmentPersonEmail": "cyourman@ford.com",
				"assessmentDate": "2020-04-10T12:21:15.443+0000",
				"siteOpen": "Y",
				"productionStartDate": null,
				"visitors": "Y",
				"management": "G",
				"timeToResume": "4",
				"people": "G",
				"facility": "G",
				"management2": "G",
				"labor": "G",
				"systems": "G",
				"quality": "G",
				"material": " ",
				"equipment": "G",
				"facilities": "G",
				"majorChanges": "G",
				"overallComments": "No Challenges",
				"assessmentRating": null
			},
			{
				"mfgGSDBCode": "AB006",
				"mfgSiteName": "Ford-Ohio",
				"mfgCity": "Dearborn",
				"mfgCountry": "USA",
				"vehiclesSupported": "Mustang",
				"fordPlantsSupported": "DTP",
				"plantManagerContactName": "cyourman@ford.com",
				"plantManagerEmail": "cyourman",
				"assessmentPersonName": "cyourman@ford.com",
				"assessmentPersonEmail": "cyourman@ford.com",
				"assessmentDate": "2020-04-10T12:21:15.444+0000",
				"siteOpen": "N",
				"productionStartDate": null,
				"visitors": "Y",
				"management": "G",
				"timeToResume": "4",
				"people": "G",
				"facility": "G",
				"management2": "G",
				"labor": "G",
				"systems": "G",
				"quality": "G",
				"material": " ",
				"equipment": "G",
				"facilities": "G",
				"majorChanges": "G",
				"overallComments": "No Challenges",
				"assessmentRating": null
			},
			{
				"mfgGSDBCode": "AB001",
				"mfgSiteName": "AP24A-Hermosillo",
				"mfgCity": "Dearborn",
				"mfgCountry": "USA",
				"vehiclesSupported": "F150",
				"fordPlantsSupported": "DTP",
				"plantManagerContactName": "cyourman@ford.com",
				"plantManagerEmail": "cyourman",
				"assessmentPersonName": "cyourman@ford.com",
				"assessmentPersonEmail": "cyourman@ford.com",
				"assessmentDate": "2020-04-10T12:21:15.443+0000",
				"siteOpen": "Y",
				"productionStartDate": null,
				"visitors": "Y",
				"management": "G",
				"timeToResume": "4",
				"people": "G",
				"facility": "G",
				"management2": "G",
				"labor": "G",
				"systems": "G",
				"quality": "G",
				"material": " ",
				"equipment": "G",
				"facilities": "G",
				"majorChanges": "G",
				"overallComments": "No Challenges",
				"assessmentRating": null
			},
			{
				"mfgGSDBCode": "AB006",
				"mfgSiteName": "Ford-Ohio",
				"mfgCity": "Dearborn",
				"mfgCountry": "USA",
				"vehiclesSupported": "Mustang",
				"fordPlantsSupported": "DTP",
				"plantManagerContactName": "cyourman@ford.com",
				"plantManagerEmail": "cyourman",
				"assessmentPersonName": "cyourman@ford.com",
				"assessmentPersonEmail": "cyourman@ford.com",
				"assessmentDate": "2020-04-10T12:21:15.444+0000",
				"siteOpen": "N",
				"productionStartDate": null,
				"visitors": "Y",
				"management": "G",
				"timeToResume": "4",
				"people": "G",
				"facility": "G",
				"management2": "G",
				"labor": "G",
				"systems": "G",
				"quality": "G",
				"material": " ",
				"equipment": "G",
				"facilities": "G",
				"majorChanges": "G",
				"overallComments": "No Challenges",
				"assessmentRating": null
			},
			{
				"mfgGSDBCode": "AB001",
				"mfgSiteName": "AP24A-Hermosillo",
				"mfgCity": "Dearborn",
				"mfgCountry": "USA",
				"vehiclesSupported": "F150",
				"fordPlantsSupported": "DTP",
				"plantManagerContactName": "cyourman@ford.com",
				"plantManagerEmail": "cyourman",
				"assessmentPersonName": "cyourman@ford.com",
				"assessmentPersonEmail": "cyourman@ford.com",
				"assessmentDate": "2020-04-10T12:21:15.443+0000",
				"siteOpen": "Y",
				"productionStartDate": null,
				"visitors": "Y",
				"management": "G",
				"timeToResume": "4",
				"people": "G",
				"facility": "G",
				"management2": "G",
				"labor": "G",
				"systems": "G",
				"quality": "G",
				"material": " ",
				"equipment": "G",
				"facilities": "G",
				"majorChanges": "G",
				"overallComments": "No Challenges",
				"assessmentRating": null
			},
			{
				"mfgGSDBCode": "AB006",
				"mfgSiteName": "Ford-Ohio",
				"mfgCity": "Dearborn",
				"mfgCountry": "USA",
				"vehiclesSupported": "Mustang",
				"fordPlantsSupported": "DTP",
				"plantManagerContactName": "cyourman@ford.com",
				"plantManagerEmail": "cyourman",
				"assessmentPersonName": "cyourman@ford.com",
				"assessmentPersonEmail": "cyourman@ford.com",
				"assessmentDate": "2020-04-10T12:21:15.444+0000",
				"siteOpen": "N",
				"productionStartDate": null,
				"visitors": "Y",
				"management": "G",
				"timeToResume": "4",
				"people": "G",
				"facility": "G",
				"management2": "G",
				"labor": "G",
				"systems": "G",
				"quality": "G",
				"material": " ",
				"equipment": "G",
				"facilities": "G",
				"majorChanges": "G",
				"overallComments": "No Challenges",
				"assessmentRating": null
			},
			{
				"mfgGSDBCode": "AB001",
				"mfgSiteName": "AP24A-Hermosillo",
				"mfgCity": "Dearborn",
				"mfgCountry": "USA",
				"vehiclesSupported": "F150",
				"fordPlantsSupported": "DTP",
				"plantManagerContactName": "cyourman@ford.com",
				"plantManagerEmail": "cyourman",
				"assessmentPersonName": "cyourman@ford.com",
				"assessmentPersonEmail": "cyourman@ford.com",
				"assessmentDate": "2020-04-10T12:21:15.443+0000",
				"siteOpen": "Y",
				"productionStartDate": null,
				"visitors": "Y",
				"management": "G",
				"timeToResume": "4",
				"people": "G",
				"facility": "G",
				"management2": "G",
				"labor": "G",
				"systems": "G",
				"quality": "G",
				"material": " ",
				"equipment": "G",
				"facilities": "G",
				"majorChanges": "G",
				"overallComments": "No Challenges",
				"assessmentRating": null
			},
			{
				"mfgGSDBCode": "AB006",
				"mfgSiteName": "Ford-Ohio",
				"mfgCity": "Dearborn",
				"mfgCountry": "USA",
				"vehiclesSupported": "Mustang",
				"fordPlantsSupported": "DTP",
				"plantManagerContactName": "cyourman@ford.com",
				"plantManagerEmail": "cyourman",
				"assessmentPersonName": "cyourman@ford.com",
				"assessmentPersonEmail": "cyourman@ford.com",
				"assessmentDate": "2020-04-10T12:21:15.444+0000",
				"siteOpen": "N",
				"productionStartDate": null,
				"visitors": "Y",
				"management": "G",
				"timeToResume": "4",
				"people": "G",
				"facility": "G",
				"management2": "G",
				"labor": "G",
				"systems": "G",
				"quality": "G",
				"material": " ",
				"equipment": "G",
				"facilities": "G",
				"majorChanges": "G",
				"overallComments": "No Challenges",
				"assessmentRating": null
			}
		];
	*/

	constructor(private http: HttpClient) {
	}

	downloadSurveyInExcel(isLaunchSurveyDashboard: boolean) {
		const dashboardRole = sessionStorage.getItem('dashboard-role');
		const uid = sessionStorage.getItem('uid');
		const dashboardurl = isLaunchSurveyDashboard === true ? 'lrdashboards' : 'dashboards';
		this.http.get(environment.apiUrl + dashboardurl + '?dashboardRole=' + dashboardRole + '&uid='
			+ uid + '&mode=' + isLaunchSurveyDashboard, {responseType: 'blob'})
			.subscribe((response) => {
				this.downLoadFile(response, 'text/csv', isLaunchSurveyDashboard);
			});
	}

	downLoadFile(data: any, type: string, isLaunchSurveyDashboard: boolean) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = isLaunchSurveyDashboard ? 'New_Model_Survey.xlsx' : 'covid_dashboard.xlsx';
		anchor.href = url;
		anchor.click();
	}

	getWebDashboardData(isLaunchSurveyDashboard: boolean): Observable<DashboardData[]> {
		const dashboardRole = sessionStorage.getItem('dashboard-role');
		const uid = sessionStorage.getItem('uid');
		// tslint:disable-next-line:max-line-length
		return this.http.get(environment.apiUrl + 'dashboards/web/All?dashboardRole=' + dashboardRole + '&uid=' + uid + '&mode=' + isLaunchSurveyDashboard).pipe(map((dashboardData: DashboardData[]) => {
			return dashboardData;
		}));
	}

	getDisplayCount(islaunchDashboard: boolean) { //Inial Load for Filter
		let url = 'dashboardsummary/surveycount?surveyType=';
		if (islaunchDashboard) {
			url = url + 'newmodel';
		} else {
			url = url + 'covid19';
		}
		return this.http.get(environment.apiUrl + url, Object.assign({responseType: 'json'}))
			.pipe(
				map((summaryCountResult) => {
					return summaryCountResult['result'].summaryApi;
				})
			);
	}
}
