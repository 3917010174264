import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ValueStreamData} from '../model/value-stream-data';
import {VsmColumn} from '../model/vsm-column';
import {VsmUserColumnAccess} from '../model/vsm-user-column-access';
import {VsmTemplate} from '../model/vsm-template';

@Injectable({
	providedIn: 'root'
})
export class ValueStreamDataService {
	private VALUE_STREAM_DATA_ENDPOINT = environment.apiUrl + 'valuestreamdata';
	private VALUE_COLUMN_ENDPOINT = environment.apiUrl + 'vsm/srd/vsm-column';
	private VSM_REPORT_ENDPOINT = environment.apiUrl + 'vsm/srd/vsm-user-column';
	private VSM_REJECT_RECORD_ENDPOINT = environment.apiUrl + 'vsm/srd/vsm-data-approval/reject/';
	private VSM_UPDATE_USER_COLUMN_ACCESS_ENDPOINT = environment.apiUrl + 'vsm/srd/vsm-user-column-access/update';
	private VSM_USER_COLUMN_ACCESS_ENDPOINT = environment.apiUrl + 'vsm/srd/vsm-user-column-access/create';
	private VSM_USER_COLUMN_ACCESS_REMOVE_ENDPOINT = environment.apiUrl + 'vsm/srd/vsm-user-column-access/';
	private VALUE_TEMPLATE_ENDPOINT = environment.apiUrl + 'vsm/srd/vsm-template';
	private GET_SUPPLIER_ENDPOINT = environment.apiUrl + 'vsm/srd/vsm-template/get-supplier/';
	private GET_SUPPLIER_FORD_PART_NO = environment.apiUrl + 'vsm/srd/vsm-template/get-ford-part-no';
	private GET_TIER_2SUPPLIER_FORD_PART_NO = environment.apiUrl + 'vsm/srd/vsm-template/get-tier-2-supplier';
	private GET_TIER_2SUPPLIER_MPN = environment.apiUrl + 'vsm/srd/vsm-template/get-tier-2-mpn';
	private GET_TIER_1_SUPPLIER_ENDPOINT = environment.apiUrl + 'vsm/srd/vsm-template/get-tier1-supplier/';
	private GET_SUPPLIER_MPN = environment.apiUrl + 'vsm/srd/vsm-template/get-mpn/';
	private GET_FORD_PART_NUMBER_SUPPLIER = environment.apiUrl + 'vsm/srd/vsm-template/form-part-number/supplier/';
	private GET_CHIP_MPN_BY_CHIP_MPN_NAME = environment.apiUrl + 'vsm/srd/vsm-template/chip-mpn/chip-mpn-name/';
	private GET_FORD_PART_NO_BY_CHIP_MPN_AND_CHIP_MPN_NAME = environment.apiUrl + 'vsm/srd/vsm-template/ford-part-no/chip-mpn/chip-mpn-name/';
	private GET_USER_VALUE_TEMPLATE_ENDPOINT = environment.apiUrl + 'vsm/srd/vsm-user-template/';
	private GET_TEMPLATE_FILTER_ENDPOINT = environment.apiUrl + 'vsm/srd/vsm-template/filter/report/';
	private CREATE_VALUE_TEMPLATE_ENDPOINT = environment.apiUrl + 'vsm/srd/vsm-template/create';
	private DELETE_VALUE_TEMPLATE_ENDPOINT = environment.apiUrl + 'vsm/srd/vsm-template/delete/';
	private UPDATE_VALUE_TEMPLATE_ENDPOINT = environment.apiUrl + 'vsm/srd/vsm-template/update';
	private VALUE_TEMPLATE_COLUMN_ENDPOINT = environment.apiUrl + 'vsm/srd/vsm-template/column-list/';
	private GET_ALL_VSM_USERS_ENDPOINT = environment.apiUrl + 'vsm/srd/vsm-template/vsm-users';
	private GET_ALL_VSM_USER_AUDIT_REPORT_ENDPOINT = environment.apiUrl + 'vsm/srd/vsm-template/vsm-user/audit-report';
	private GET_ALL_VSM_DATA_APPROVAL_REPORT_ENDPOINT = environment.apiUrl + 'vsm/srd/vsm-template/vsm-data-approval';
	private GET_ALL_VSM_USERS_FILTER_ENDPOINT = environment.apiUrl + 'vsm/srd/vsm-template/vsm-users/filter';
	private GET_ALL_VSM_USER_AUDIT_REPORT_FILTER_ENDPOINT = environment.apiUrl + 'vsm/srd/vsm-template/vsm-user/audit-report-filter';
	private VSM_DATA_APPROVAL_FILTER_REPORT_ENDPOINT = environment.apiUrl + 'vsm/srd/vsm/data-approval-filter';
	private GET_VSM_COLUMN_ACCESS = environment.apiUrl + 'vsm/srd/vsm-template/vsm-column-access';

	constructor(private http: HttpClient) {
	}

	// tslint:disable-next-line:max-line-length
	createValueStreamData(valueStreamData: ValueStreamData): Observable<ValueStreamData> {
		console.log('create service is called');
		return this.http.post<ValueStreamData>(this.VALUE_STREAM_DATA_ENDPOINT, valueStreamData);
	}

	getAccessColumn(): Observable<VsmColumn> {
		console.log('get access column service is called');
		return this.http.get<VsmColumn>(this.VALUE_COLUMN_ENDPOINT);
	}

	getVsmTemplate(): Observable<VsmTemplate> {
		console.log('get access column service is called');
		return this.http.get<VsmTemplate>(this.VALUE_TEMPLATE_ENDPOINT);
	}

	getAllSupplier(source: string): Observable<any> {
		console.log('get access column service is called');
		return this.http.get<any>(this.GET_SUPPLIER_ENDPOINT + source);
	}

	getAllTier1Supplier(source: string): Observable<any> {
		console.log('get access column service is called');
		return this.http.get<any>(this.GET_TIER_1_SUPPLIER_ENDPOINT + source);
	}

	getAllMpn(source: string, supplierIds: string): Observable<any> {
		console.log('get access column service is called');
		return this.http.get<any>(this.GET_SUPPLIER_MPN + source + '/' + supplierIds);
	}

	getAllFordPartNo(source: string, supplierId: string): Observable<any> {
		console.log('get access column service is called');
		return this.http.post<any>(this.GET_SUPPLIER_FORD_PART_NO, {
			source,
			supplierId
		});
	}
	updateUserAccessColumn(vsmUserColumnAccess: VsmUserColumnAccess): Observable<VsmColumn> {
		console.log('get access column service is called');
		return this.http.post<VsmColumn>(this.VSM_UPDATE_USER_COLUMN_ACCESS_ENDPOINT, vsmUserColumnAccess);
	}
	getAllICSupplier(source: string, supplierId: string): Observable<any> {
		console.log('get access column service is called');
		return this.http.post<any>(this.GET_TIER_2SUPPLIER_FORD_PART_NO, {
			source,
			supplierId
		});
	}

	getAllTier1SupplierMPN(source: string, supplierId: string, tier2supplierIds: string) {
		return this.http.post<any>(this.GET_TIER_2SUPPLIER_MPN, {
			source,
			supplierId,
			tier2supplierIds
		});
	}

	getVsmUserTemplate(id): Observable<VsmTemplate> {
		console.log('get access column service is called');
		return this.http.get<VsmTemplate>(this.GET_USER_VALUE_TEMPLATE_ENDPOINT + id);
	}

	getTemplateAllData(source, subDataSource): Observable<VsmTemplate> {
		console.log('get access column service is called');
		return this.http.get<VsmTemplate>(this.GET_TEMPLATE_FILTER_ENDPOINT + source + '/' + subDataSource);
	}

	createUserAccessColumn(vsmUserColumnAccess: VsmUserColumnAccess): Observable<VsmColumn> {
		console.log('get access column service is called');
		return this.http.post<VsmColumn>(this.VSM_USER_COLUMN_ACCESS_ENDPOINT, vsmUserColumnAccess);
	}

	createVsmTemplate(vsmUserColumnAccess: any) {
		console.log('get access column service is called');
		return this.http.post(this.CREATE_VALUE_TEMPLATE_ENDPOINT, vsmUserColumnAccess);
	}
	deleteVsmTemplate(id: any) {
		console.log('get access column service is called');
		return this.http.delete(this.DELETE_VALUE_TEMPLATE_ENDPOINT + id);
	}
	vsmGetColumnAccessBasedOnUser(templateId, userName) {
		return this.http.get(this.GET_VSM_COLUMN_ACCESS + '/' + templateId + '/' + userName);
	}
	updateVsmTemplate(vsmUserColumnAccess: any) {
		console.log('get access column service is called');
		return this.http.put(this.UPDATE_VALUE_TEMPLATE_ENDPOINT, vsmUserColumnAccess);
	}

	removeUserAccessColumn(cdsid: string, templateId: string) {
		console.log('remove access column service is called');
		return this.http.delete<VsmColumn>(this.VSM_USER_COLUMN_ACCESS_REMOVE_ENDPOINT + cdsid + '/' + templateId);
	}

	generateReportForVsmUpdate(body: any) {
		return this.http.post(this.VSM_REPORT_ENDPOINT, body, {responseType: 'blob'});
	}

	downLoadFile(data: any, type: string, templateName: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = templateName.replace(/\s/g, '_') + '.xlsx';
		anchor.href = url;
		anchor.click();
	}
	// tslint:disable-next-line:max-line-length
	upload(fileToUpload: File, templateId: string, insertAccess: any, chipMpn: any, chipMpnName: any, supplierList: any, fordPartNo: any): Observable<any> {
		const endpoint = this.VSM_REPORT_ENDPOINT + '/upload/' + templateId + '/' + insertAccess;
		const formData: FormData = new FormData();
		formData.append('supplierList', supplierList);
		formData.append('chipMpnName', chipMpnName);
		formData.append('chipMpn', chipMpn);
		formData.append('fordPartNo', fordPartNo);
		formData.append('File', fileToUpload);
		return this.http.post(endpoint, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	}

	updateData(body: any): Observable<any> {
		const endpoint = this.VSM_REPORT_ENDPOINT + '/update-create';
		return this.http.post(endpoint, body);
	}

	rejectData(id): Observable<any> {
		const endpoint = this.VSM_REJECT_RECORD_ENDPOINT + id;
		return this.http.post(endpoint, {});
	}

	approvalRequestCheck(body: any): Observable<any> {
		const endpoint = this.VALUE_STREAM_DATA_ENDPOINT + '/vsm-report/approval-check/filter/' + body;
		return this.http.get(endpoint);
	}

	createApprovalRequest(body: any): Observable<any> {
		const endpoint = this.VSM_REPORT_ENDPOINT + '/create-approval';
		return this.http.post(endpoint, body);
	}

	getVsmTemplateColumn(id) {
		return this.http.get(this.VALUE_TEMPLATE_COLUMN_ENDPOINT + id);
	}

	getAllVsmUsers(page, pageSize) {
		return this.http.get(this.GET_ALL_VSM_USERS_ENDPOINT + '/' + page + '/' + pageSize);
	}

	vsmUserFilter(filterValue, page, pageSize) {
		return this.http.get(this.GET_ALL_VSM_USERS_FILTER_ENDPOINT + '/' + page + '/' + pageSize + '/' + filterValue);
	}

	getAllVsmUserAudit(page, pageSize) {
		return this.http.get(this.GET_ALL_VSM_USER_AUDIT_REPORT_ENDPOINT + '/' + page + '/' + pageSize);
	}

	vsmUserAuditFilter(filterValue, page, pageSize) {
		return this.http.get(this.GET_ALL_VSM_USER_AUDIT_REPORT_FILTER_ENDPOINT + '/' + page + '/' + pageSize + '/' + filterValue);
	}

	getAllVsmDataApproval(page, pageSize) {
		return this.http.get(this.GET_ALL_VSM_DATA_APPROVAL_REPORT_ENDPOINT + '/' + page + '/' + pageSize);
	}

	vsmDataApprovalFilter(filterValue, page, pageSize, status) {
		return this.http.get(this.VSM_DATA_APPROVAL_FILTER_REPORT_ENDPOINT + '/' + page + '/' + pageSize + '/' + status + '/' + filterValue);
	}

	vsmGetColumnAccess(templateId) {
		return this.http.get(this.GET_VSM_COLUMN_ACCESS + '/' + templateId);
	}

	getAllFordPartNumberSupplier(source: any, subSource: any, fordPartNumbers: string) {
		return this.http.post<any>(this.GET_FORD_PART_NUMBER_SUPPLIER + source + '/' + subSource, {
			fordPartNumbers
		});
	}
	getAllChipMpnDataByChipMpnName(source: any, subSource: any, chipMpnName: string) {
		return this.http.post<any>(this.GET_CHIP_MPN_BY_CHIP_MPN_NAME + source + '/' + subSource, {
			chipMpnName
		});
	}
	getAllFordPartNoDataByChipMpnNameAndChipMpn(source: any, subSource: any, chipMpnName: string, chipMpn: string) {
		return this.http.post<any>(this.GET_FORD_PART_NO_BY_CHIP_MPN_AND_CHIP_MPN_NAME + source + '/' + subSource, {
			chipMpnName,
			chipMpn
		});
	}
}
