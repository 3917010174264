import {
	Component,
	OnInit
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	Validators
} from '@angular/forms';
import {
	DialogService,
	DynamicDialogRef,
	MessageService
} from 'primeng/api';
import {SupplierService} from '../../../home/services/supplier.service';
import {Router} from '@angular/router';
import {CustomDialogComponent} from '../../../custom-dialog/custom-dialog/custom-dialog.component';
import {NTierMasterAdminService} from '../../n-tier-master-admin/service/n-tier-master-admin.service';
import {ClearToBuildSchedulerService} from '../service/clear-to-build-scheduler.service';
import {
	ClearToBuildScheduler,
	emptyClearToBuildScheduler
} from '../model/clear-to-build-scheduler';
import {ClearToBuildSacService} from '../service/clear-to-build-sac.service';

@Component({
	selector: 'clear-to-build-scheduler',
	templateUrl: './clear-to-build-scheduler.component.html',
	styleUrls: ['./clear-to-build-scheduler.component.scss']
})
export class ClearToBuildSchedulerComponent implements OnInit {
	adhocForm: FormGroup;
	updateForm: FormGroup;
	weekDays = [{weekDay: 'MON'}, {weekDay: 'TUE'}, {weekDay: 'WED'}, {weekDay: 'THU'}, {weekDay: 'FRI'}];
	hours = [];
	minutes = [];
	statusOption = [{status: 'Active'}, {status: 'Inactive'}];
	partOption = [{part: 'CTBReference_VOParts'}, {part: 'CTBReference_MFGParts'}, {part: 'VO_AND_MFG_Parts'}];
	partObject: any;
	selectedPart: any;
	adhocPartObject: any;
	selectedAdhocPart: any;
	ctbIds: any[] = [];
	selectedCtbIds: any[] = [];
	adhocCtbIds: any[] = [];
	selectedAdhocCtbIds: any[] = [];
	newSchedule: ClearToBuildScheduler;
	updateScheduleObject: ClearToBuildScheduler;
	display = false;
	updateDisplay = false;
	cols: any[];
	schedulesTemp: ClearToBuildScheduler[];
	schedules: ClearToBuildScheduler[];
	ctbId: any;
	isCreateButtonVisible = false;
	isUpdateButtonVisible = false;
	ref: DynamicDialogRef;

	// tslint:disable-next-line:max-line-length
	constructor(private supplierService: SupplierService, private router: Router, private fb: FormBuilder, private messageService: MessageService, private clearToBuildSchedulerService: ClearToBuildSchedulerService, private clearToBuildSacService: ClearToBuildSacService, private dialogService: DialogService, private masterAdminService: NTierMasterAdminService) {
	}

	ngOnInit() {
		// this.supplierService.pushSelectedSurvey('clearToBuild');
		this.router.navigate(['/']);
		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
		} else {
			// tslint:disable-next-line:max-line-length
			this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build Super Admin').subscribe(userHasCtbSuperAdminAccess => {
				if (!userHasCtbSuperAdminAccess) {
					this.router.navigate(['/']);
				}
			});
		}
		this.cols = [
			{field: 'weekDay', header: 'Week Day'},
			{field: 'hours', header: 'Hours (24 hours Format - UTC)'},
			{field: 'minutes', header: 'Minutes'},
			{field: 'part', header: 'Part Type'},
			{field: 'clearToBuildIds', header: 'CTB Ids'},
			{field: 'statusText', header: 'Status'}
		];
		this.findAllSchedules();
		this.adhocForm = this.fb.group({
			ctbIds: [{value: '', disabled: false}],
			adhocPart: [{value: '', disabled: false, validators: Validators.required}],
			adhocCtbIds: [{value: '', disabled: false, validators: Validators.required}]
		});
		this.updateForm = this.fb.group({
			weekDays: [{value: '', disabled: false, validators: Validators.required}],
			hours: [{value: '', disabled: false, validators: Validators.required}],
			minutes: [{value: '', disabled: false, validators: Validators.required}],
			part: [{value: '', disabled: false, validators: Validators.required}],
			ctbIds: [{value: '', disabled: false, validators: Validators.required}],
			status: [{value: '', disabled: false, validators: Validators.required}]
		});
		this.router.navigate(['/']);
	}

	findConstraintIds() {
		this.selectedCtbIds = [];
		this.ctbIds = [];
		console.log('Selected Part Type : ' + this.selectedPart);
		this.clearToBuildSchedulerService.getCtbIds(this.selectedPart).subscribe((data) => {
			if (data.length > 0) {
				this.ctbIds = data;
			}
		});
	}

	findCtbIdsForAdhocPull() {
		this.selectedAdhocCtbIds = [];
		this.adhocCtbIds = [];
		console.log('Selected Part Type : ' + this.selectedAdhocPart);
		this.clearToBuildSchedulerService.getCtbIds(this.selectedAdhocPart).subscribe((data) => {
			if (data.length > 0) {
				this.adhocCtbIds = data;
			}
		});
	}

	private findAllSchedules() {
		this.schedulesTemp = [];
		this.schedules = [];
		this.clearToBuildSchedulerService.getAllSchedules().subscribe((data) => {
			this.schedulesTemp = data;
			this.schedulesTemp.forEach(schedule => {
				if (schedule.status) {
					schedule.statusText = 'Active';
				} else {
					schedule.statusText = 'Inactive';
				}
				this.schedules.push(schedule);
			});
		});
	}

	createSchedule() {
		if (this.updateForm.get('weekDays').value === null || this.updateForm.get('status').value === null) {
			this.pushMessage('error', 'Error', 'Please check if all mandatory fields are filled & Valid.');
			// tslint:disable-next-line:max-line-length
		} else if (this.isDropDownNotValid(this.updateForm.get('weekDays').value.weekDay) || this.isDropDownNotValid(this.updateForm.get('status').value.status) || this.isHoursNotValid(this.updateForm.get('hours').value) || this.isMinutesNotValid(this.updateForm.get('minutes').value)) {
			this.pushMessage('error', 'Error', 'Please check if all mandatory fields are filled & Valid.');
		} else if (this.selectedCtbIds.length === 0) {
			this.pushMessage('error', 'Error', 'Please Select at least One Ctb Id.');
		} else {
			// this.newSchedule = this.updateForm.getRawValue() as Scheduler;
			this.newSchedule = new ClearToBuildScheduler(emptyClearToBuildScheduler);
			this.newSchedule.weekDay = this.updateForm.get('weekDays').value.weekDay;
			this.newSchedule.hours = this.updateForm.get('hours').value;
			this.newSchedule.minutes = this.updateForm.get('minutes').value;
			this.newSchedule.part = this.updateForm.get('part').value.part;
			this.newSchedule.status = (this.updateForm.get('status').value.status
				&& this.updateForm.get('status').value.status === 'Active') ? true : false;
			let ctbIds = '';
			this.selectedCtbIds.forEach(value => {
				ctbIds = ctbIds.concat(value.name + ',');
			});
			ctbIds = ctbIds.slice(0, ctbIds.lastIndexOf(','));
			this.newSchedule.clearToBuildIds = ctbIds;
			this.clearToBuildSchedulerService.createSchedule(this.newSchedule).subscribe((data) => {
				this.findAllSchedules();
				if (!(data === null)) {
					this.pushMessage('success', 'Success', 'Success: Schedule Id #' + data.id + ' has been Created successfully');
					this.updateDisplay = false;
					this.updateForm.reset();
					this.selectedCtbIds = [];
					this.ctbIds = [];
				} else {
					this.pushDialog('Please verify if Schedule already exists for the specific time');
				}
			});
		}
	}

	updateSchedule() {
		// tslint:disable-next-line:max-line-length
		if (this.isDropDownNotValid(this.updateForm.get('weekDays').value.weekDay) || this.isDropDownNotValid(this.updateForm.get('status').value.status) || this.isHoursNotValid(this.updateForm.get('hours').value) || this.isMinutesNotValid(this.updateForm.get('minutes').value)) {
			this.pushMessage('error', 'Error', 'Please check if all mandatory fields are filled & Valid.');
		} else if (!(this.selectedCtbIds.length > 0)) {
			this.pushMessage('error', 'Error', 'Please Select at least One Ctb Id.');
		} else {
			this.updateScheduleObject.weekDay = this.updateForm.get('weekDays').value.weekDay;
			this.updateScheduleObject.hours = this.updateForm.get('hours').value;
			this.updateScheduleObject.minutes = this.updateForm.get('minutes').value;
			this.updateScheduleObject.part = this.updateForm.get('part').value.part;
			this.updateScheduleObject.status = (this.updateForm.get('status').value.status
				&& this.updateForm.get('status').value.status === 'Active') ? true : false;
			let ctbIds = '';
			this.selectedCtbIds.forEach(value => {
				ctbIds = ctbIds.concat(value.name + ',');
			});
			ctbIds = ctbIds.slice(0, ctbIds.lastIndexOf(','));
			this.updateScheduleObject.clearToBuildIds = ctbIds;
			this.clearToBuildSchedulerService.checkExistence(this.updateScheduleObject).subscribe(exists => {
				if (!exists) {
					this.clearToBuildSchedulerService.updateSchedule(this.updateScheduleObject).subscribe((data) => {
						this.findAllSchedules();
						console.log('Success: Schedule Id #' + data.id + ' has been Updated successfully');
						this.pushMessage('success', 'Success', 'Success: Schedule Id #' + data.id + ' has been Updated successfully');
						this.updateDisplay = false;
						this.updateForm.reset();
						this.selectedCtbIds = [];
						this.ctbIds = [];
					}, error => {
						this.findAllSchedules();
						this.pushDialog('Please verify if Schedule already exists for the specific time');
						this.updateDisplay = false;
						this.updateForm.reset();
						this.selectedCtbIds = [];
						this.ctbIds = [];
					});
				} else {
					this.pushDialog('Please verify if any Schedule already exists for the specific time');
				}
			});
		}
	}

	onSubmit() {
		this.display = false;
		const scheduleObj = new ClearToBuildScheduler(emptyClearToBuildScheduler);
		let clearToBuildIds = '';
		this.selectedAdhocCtbIds.forEach(value => {
			clearToBuildIds = clearToBuildIds.concat(value.name + ',');
		});
		clearToBuildIds = clearToBuildIds.slice(0, clearToBuildIds.lastIndexOf(','));
		scheduleObj.clearToBuildIds = clearToBuildIds;
		scheduleObj.part = this.selectedAdhocPart;
		this.clearToBuildSacService.adhocPull(scheduleObj).subscribe((data) => {
			if (data) {
				this.pushDialog('ADHOC Pull Initiated');
			} else {
				this.pushDialog('ADHOC Pull failed, please try again later.');
			}
		});
	}

	enableAdhocDialog() {
		this.adhocPartObject = undefined;
		this.adhocForm.reset();
		this.adhocCtbIds = [];
		this.selectedAdhocCtbIds = [];
		this.display = true;
	}

	disableAdhocDialog() {
		this.display = false;
	}

	deleteSchedule(id: any) {
		this.clearToBuildSchedulerService.deleteSchedule(id).subscribe((data) => {
			if (data) {
				console.log('Success: Schedule has been Deleted successfully');
				this.pushMessage('success', 'Success', 'Success: Schedule has been Deleted successfully');
				this.findAllSchedules();
			} else {
				this.pushMessage('error', 'Error', 'Unable to Delete Schedule, Please try again');
			}
		});
	}

	enableUpdateScheduleDialog(data: any) {
		this.ctbIds = [];
		this.selectedCtbIds = [];
		this.isCreateButtonVisible = false;
		this.isUpdateButtonVisible = true;
		this.updateScheduleObject = new ClearToBuildScheduler(emptyClearToBuildScheduler);
		this.updateScheduleObject.id = data['id'];
		this.updateScheduleObject.createdBy = data['createdBy'];
		this.updateScheduleObject.createdDate = data['createdDate'];

		this.updateScheduleObject.weekDay = data['weekDay'];
		this.updateScheduleObject.hours = data['hours'];
		this.updateScheduleObject.minutes = data['minutes'];
		this.updateScheduleObject.part = data['part'];
		this.updateScheduleObject.clearToBuildIds = data['clearToBuildIds'];
		this.updateScheduleObject.status = data['status'];

		this.updateForm.patchValue({weekDays: {weekDay: data['weekDay']}});
		this.updateForm.patchValue({hours: data['hours']});
		this.updateForm.patchValue({minutes: data['minutes']});
		this.updateForm.patchValue({part: {part: data['part']}});
		if (data['status']) {
			this.updateForm.patchValue({status: this.statusOption[0]});
		} else {
			this.updateForm.patchValue({status: this.statusOption[1]});
		}
		const ctbIds = data['clearToBuildIds'];
		let ctbIdList = [];
		ctbIdList = ctbIds.split(',');

		let optionList = [];
		this.clearToBuildSchedulerService.getCtbIds(data['part']).subscribe((res) => {
			if (res.length > 0) {
				optionList = res;
			}
			const finalList = [];
			optionList.forEach(value => {
				this.ctbId = value.name;
				if (ctbIdList.includes(this.ctbId.toString())) {
					finalList.push(value);
					this.selectedCtbIds = finalList;
				}
			});
		});
		this.updateDisplay = true;
	}

	enableCreateScheduleDialog() {
		this.updateForm.reset();
		this.selectedCtbIds = [];
		this.ctbIds = [];
		this.updateDisplay = true;
		this.isCreateButtonVisible = true;
		this.isUpdateButtonVisible = false;
	}

	disableCreateUpdateDialog() {
		this.updateDisplay = false;
		this.updateForm.reset();
		this.selectedCtbIds = [];
		this.ctbIds = [];
	}

	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 60000
		});
	}

	pushDialog(dialogBody: any) {
		this.ref = this.dialogService.open(CustomDialogComponent, {
			header: 'CTB Scheduler',
			footer: '',
			data: dialogBody,
			width: '50%',
			contentStyle: {
				'max-height': '1000px',
				overflow: 'auto'
			},
			baseZIndex: 10000
		});
	}

	isNotDigit(value: number): boolean {
		return isNaN(value);
	}

	isHoursNotValid(value: number): boolean {
		if (value > 0 && value < 25) {
			return false;
		} else {
			return true;
		}
	}

	isMinutesNotValid(value: number): boolean {
		if ((value > 0 || value === 0) && value < 60) {
			return false;
		} else {
			return true;
		}
	}

	isDropDownNotValid(value: string) {
		if (value !== undefined) {
			return false;
		} else {
			return true;
		}
	}
}
