
export class ClearToBuildTracker {
	id: number;
	plantCode: string;
	plantName: string;
	partNumber: string;
	partDescription: string;
	actualBusinessUnit: string;
	overrideBusinessUnit: string;
	vehicleName: string;
	tradingCompany: string;
	averageProductionPartCapacity: number;
	maximumProductionPartCapacity: number;
	willMakeInDays: number;
	willMakeDate: Date;
	riskDate: Date;
	supplierSiteCode: string;
	siteName: string;
	siteLocationCountry: string;
	transitTime: string;
	tierIStockArrivalDate: Date;
	stockInWarehouse: number;
	stockInPlant: number;
	shipmentPlanAvailability: number;
	shipmentHorizonGap: number;
	thirdQGap: number;
	firstWkGapQtyPlant: number;
	projectedBalance2: number;
	createdBy: string;
	createdOn: Date;
	lastUpdatedBy: string;
	lastUpdatedOn: Date;
	clearToBuildId: string;
	isCurrentlyUsed: boolean;
	currentUser: string;
	weekNumber: number;
	weekDate: Date;
	supplierMailId: string;
	clearToBuildIdString: string;
	versionNumber: number;
}
