import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {AdminUsers} from '../model/admin-users';
import {AdminAccess} from '../model/admin-access';

@Injectable({
	providedIn: 'root'
})
export class NTierMasterAdminService {
	private MASTER_ADMIN_ENDPOINT = environment.apiUrl + 'nTierMasterAdmin';
	private SEMI_SUPPLIERS_TRACKER_ENDPOINT = environment.apiUrl + 'tier1EComponentShortageTracker/admin';
	private TIGER_SHARK_TRACKER_ENDPOINT = environment.apiUrl + 'tigerSharkConstraintTracker';
	private SUSTAINAINABILITY_SURVEY_TRACKER_ENDPOINT = environment.apiUrl + 'ss/admin';
	private CLEAR_TO_BUILD_ENDPOINT = environment.apiUrl + 'clearToBuildTracker';
	private PROJECT_R_MICROCHIP_ENDPOINT = environment.apiUrl + 'microchip/survey';
	private T2_ENDPOINT = environment.apiUrl + 't2visibility';

	constructor(private http: HttpClient) {
	}

	isUserPresentInAdminUser(userId: string): Observable<boolean> {
		return this.http.get<boolean>(this.MASTER_ADMIN_ENDPOINT + '/isAdminUser/');
	}

	createAdminUser(adminUser: AdminUsers): Observable<AdminUsers> {
		return this.http.post<AdminUsers>(this.MASTER_ADMIN_ENDPOINT + '/createAdminUser', adminUser);
	}

	isUserHasFunctionAdminAccess(userId: string, functionName: string): Observable<boolean> {
		return this.http.get<boolean>(this.MASTER_ADMIN_ENDPOINT + '/isUserHasSpecifiedFunctionAccess/' +  functionName);
	}

	createAdminAccessForUser(adminAccess: AdminAccess): Observable<AdminAccess> {
		return this.http.post<AdminAccess>(this.MASTER_ADMIN_ENDPOINT + '/createSpecifiedAdminAccessForUser/', adminAccess);
	}

	removeAdminAccessForUser(adminAccess: AdminAccess): Observable<boolean> {
		return this.http.post<boolean>(this.MASTER_ADMIN_ENDPOINT + '/removeSpecifiedAdminAccessForUser/', adminAccess);
	}

	isUserHasAnyFunctionAdminAccess(userId: string, functionList: string[]): Observable<string> {
		return this.http.get<string>(this.MASTER_ADMIN_ENDPOINT + '/isUserHasAnyGivenFunctionAccess/' + functionList);
	}

	isSupplierHasSemiSuppliersAccess(mailId: string): Observable<boolean> {
		return this.http.get<boolean>(this.SEMI_SUPPLIERS_TRACKER_ENDPOINT + '/SupplierAccess/' + mailId);
	}

	isSupplierHasSustainabilityAccess(mailId: string): Observable<boolean> {
		return this.http.get<boolean>(this.SUSTAINAINABILITY_SURVEY_TRACKER_ENDPOINT + '/SupplierAccess/' + mailId);
	}

	isSupplierHasProjectRNonMicrochipAccess(mailId: string): Observable<boolean> {
		return this.http.get<boolean>(this.SUSTAINAINABILITY_SURVEY_TRACKER_ENDPOINT + '/ProjectRSupplierAccess/' + mailId);
	}

	isSupplierHasSeparateSemiconductorSuppliersAccess(mailId: string): Observable<boolean> {
		return this.http.get<boolean>(this.SEMI_SUPPLIERS_TRACKER_ENDPOINT + '/semiconductorSupplier/SupplierAccess/' + mailId);
	}

	isSupplierHasTigerSharkAccess(mailId: string): Observable<boolean> {
		return this.http.get<boolean>(this.TIGER_SHARK_TRACKER_ENDPOINT + '/SupplierAccess/' + mailId);
	}

	retrieveAccessListForFunction(screenName: string): Observable<AdminAccess[]> {
		return this.http.get<AdminAccess[]>(this.MASTER_ADMIN_ENDPOINT + '/retrieveAccessListForFunction/' + screenName);
	}

	retrieveAccessListForUserId(userId: string): Observable<AdminAccess[]> {
		let adminAccess = {
            adminUserId: userId
		}
		return this.http.post<AdminAccess[]>(this.MASTER_ADMIN_ENDPOINT + '/retrieveAccessListForUserId/',adminAccess);
	}

	isSupplierHasClearToBuildAccess(mailId: string) {
		return this.http.get<boolean>(this.CLEAR_TO_BUILD_ENDPOINT + '/SupplierAccess/' + mailId);
	}

	isSupplierHasProjectRMicrochipAccess(mailId: string) {
		return this.http.get<boolean>(this.PROJECT_R_MICROCHIP_ENDPOINT + '/SupplierAccessforMicrochip/' + mailId);
	}

	isSupplierHasT2VDataRefreshAccess(mailId: string) {
		return this.http.get<boolean>(this.T2_ENDPOINT + '/SupplierAccessforT2VDataRefresh/' + mailId);
	}
}
