export class Scheduler {
	id: number;
	weekDay: string;
	hours: number;
	minutes: number;
	part: string;
	constraints: string;
	status: boolean;
	statusText: string;
	createdBy: string;
	createdDate: Date;
	updatedBy: string;
	updatedDate: Date;
	constructor(scheduler: Scheduler) {
		Object.assign(this, scheduler);
	}
}

/* tslint:disable */
export const emptyScheduler = {
	'id': 0,
	'weekDay': '',
	'hours': 0,
	'minutes': 0,
	'part': '',
	'constraints':'',
	'status': true,
	'statusText':'',
	'createdBy': '',
	'createdDate': new Date(),
	'updatedBy': '',
	'updatedDate': new Date()
};
