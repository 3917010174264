import {
	Component,
	OnInit,
	ViewChild
} from '@angular/core';
import {IgxSpreadsheetComponent} from 'igniteui-angular-spreadsheet';
import {ExcelUtility} from '../../tiger-shark-constraint-tracker/model/excel-utility';
import {NTierMasterAdminService} from '../../n-tier-master-admin/service/n-tier-master-admin.service';
import {Router} from '@angular/router';
import {ValueStreamDataService} from '../service/value-stream-data.service';
import {DialogService, DynamicDialogRef, MessageService} from 'primeng/api';
import {WorkbookSaveOptions} from 'igniteui-angular-excel';
import {SupplierService} from '../../../home/services/supplier.service';
import {CustomDialogComponent} from '../../../custom-dialog/custom-dialog/custom-dialog.component';

@Component({
	selector: 'value-stream-data-collection-admin-user',
	templateUrl: './value-stream-data-collection-user.component.html',
	styleUrls: ['./value-stream-data-collection-user.component.scss']
})
export class ValueStreamDataCollectionUserComponent implements OnInit {
	loadMemoryData = false;
	// tslint:disable-next-line:max-line-length
	constructor(private dialogService: DialogService, private supplierService: SupplierService, private masterAdminService: NTierMasterAdminService, private router: Router, private valueStreamDataService: ValueStreamDataService, private messageService: MessageService) {
	}

	selected = false;
	@ViewChild('spreadsheet', {static: false, read: IgxSpreadsheetComponent})
	public spreadsheet: IgxSpreadsheetComponent;
	userLoginId: string;
	emailAddress: string;
	ref: DynamicDialogRef;
	fileFromDatabase: any;
	submitStatus = false;
	selectedTemplate: any = null;
	supplierDisabled = false;
	tier1SupplierDisabled = false;
	diffrenceResult: any = null;
	templateList: any = [];
	supplierList: any = [];
	selectedFiles: any[] = [];
	supplierDatas: any[] = [];
	chipMpnList: any[] = [];
	chipMpnDatas: any[] = [];
	chipMpnNameList: any[] = [];
	chipMpnNameDatas: any[] = [];
	mpnList: any[] = [];
	mpnDatas: any[] = [];
	tier1SupplierList: any[] = [];
	tier1FordPartNumberList: any[] = [];
	tier1SupplierDatas: any[] = [];
	tier1FordPartNumberDatas: any[] = [];
	displayDialogue = false;
	displayDifferentDialogue = false;
	selectedRequests: any[] = [];
	dataSource: any = null;
	subDataSource: any = null;
	chipNpmNameDisabled: any = false;
	chipMpnDisabled: any = false;
	vsmColumns: any = [];
	newRecordValue: any;
	oldRecord: any;
	oldRecordValue: any;




	ngOnInit() {
		this.router.navigate(['/']);
		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
			return;
		}else if (sessionStorage.getItem('uid').includes('-')) {
			this.router.navigate(['/']);
			return;
		}
		// this.supplierService.pushSelectedSurvey('VSM');
		this.userLoginId = sessionStorage.getItem('userId');
		this.emailAddress = sessionStorage.getItem('emailAddress');
		console.log(this.emailAddress);
		this.getAllUserTemplate();
		// this.getAllSupplier();
	}

	close() {
		this.displayDialogue = false;
		console.log('calling on close');
	}

	public openFile(input: any): void {
		if (input.files == null || input.files.length === 0) {
			return;
		}
		ExcelUtility.load(input.files[0]).then((w) => {
			this.spreadsheet.workbook = w;
			this.submitStatus = true;
			console.log(this.spreadsheet.workbook.worksheets('Sheet1')._r);
		}, (e) => {
			console.error('Workbook Load Error:' + e);
		});
	}

	onVsmTemplateChange(event) {
		this.selectedTemplate = event.value;
		this.subDataSource = event.value.tntvSubDataSource;
		this.dataSource = event.value.tntvDataSource;
		this.tier1SupplierList = [];
		this.tier1FordPartNumberList = [];
		this.mpnDatas = [];
		this.supplierDatas = [];
		this.tier1SupplierDatas = [];
		this.tier1FordPartNumberDatas = [];
		this.mpnList = [];
		this.supplierDisabled = false;
		this.tier1SupplierDisabled = false;
		this.chipNpmNameDisabled = false;
		this.chipMpnDisabled = false;
		this.supplierList = [];
		this.chipMpnDatas = [];
		this.chipMpnNameDatas = [];
		this.getAllFilter();
	}

	private getAllFilter() {
		// tslint:disable-next-line:max-line-length
		this.valueStreamDataService.getTemplateAllData(this.selectedTemplate.tntvDataSource, this.selectedTemplate.tntvSubDataSource).subscribe((res: any) => {
			this.tier1FordPartNumberList = res.fordPartNo;
			this.tier1SupplierList = res.tier1SupplierName;
			this.chipMpnList = res.chipMpn;
			this.chipMpnNameList = res.chipMpnName;
			if (this.selectedTemplate.tntvSupplierIds) {

				const supplierIds = this.selectedTemplate.tntvSupplierIds.split('|');
				if (this.dataSource === 'COE' && this.subDataSource === 'VSM') {
					this.tier1SupplierDatas = [];
					for (const item of this.tier1SupplierList) {
						if (supplierIds.indexOf(item.name) !== -1) {
							this.tier1SupplierDatas.push(item);
						}
					}
					this.supplierDisabled = false;
					if (this.tier1SupplierDatas.length > 0) {
						this.supplierDisabled = true;
						this.getAllFordPartNumberSupplier();
					}
				} else if (this.dataSource === 'COE' && (this.subDataSource === 'MPN' || this.subDataSource === 'BOM')) {
					this.chipMpnNameDatas = [];
					for (const item of this.chipMpnNameList) {
						if (supplierIds.indexOf(item.name) !== -1) {
							this.chipMpnNameDatas.push(item);
						}
					}
					this.chipNpmNameDisabled = false;
					if (this.chipMpnNameDatas.length > 0) {
						this.chipNpmNameDisabled = true;
						this.getAllChipMpn();
						if (this.subDataSource === 'BOM') {
							this.getAllChipMpnFordPartNo();
						}
					}
				} else if (this.dataSource === 'COE' && this.subDataSource === 'NODE') {
					this.chipMpnDatas = [];
					for (const item of this.chipMpnList) {
						if (supplierIds.indexOf(item.name) !== -1) {
							this.chipMpnDatas.push(item);
						}
					}
					this.chipMpnDisabled = false;
					if (this.chipMpnDatas.length > 0) {
						this.chipMpnDisabled = true;
					}
				} else if (this.dataSource === 'CARESOFT' && this.subDataSource === 'DATA') {
					this.chipMpnNameDatas = [];
					for (const item of this.chipMpnNameList) {
						if (supplierIds.indexOf(item.name) !== -1) {
							this.chipMpnNameDatas.push(item);
						}
					}
					this.chipNpmNameDisabled = false;
					if (this.chipMpnNameDatas.length > 0) {
						this.chipNpmNameDisabled = true;
						this.getAllChipMpn();
					}
				} else if (this.dataSource === 'SUPPLIERBOM' && this.subDataSource === 'DATA') {
					this.chipMpnNameDatas = [];
					for (const item of this.chipMpnNameList) {
						if (supplierIds.indexOf(item.name) !== -1) {
							this.chipMpnNameDatas.push(item);
						}
					}
					this.chipNpmNameDisabled = false;
					if (this.chipMpnNameDatas.length > 0) {
						this.chipNpmNameDisabled = true;
						this.getAllChipMpn();
					}
				} else if (this.dataSource === 'INHOUSEBOM' && this.subDataSource === 'DATA') {
					this.chipMpnNameDatas = [];
					for (const item of this.chipMpnNameList) {
						if (supplierIds.indexOf(item.name) !== -1) {
							this.chipMpnNameDatas.push(item);
						}
					}
					this.chipNpmNameDisabled = false;
					if (this.chipMpnNameDatas.length > 0) {
						this.chipNpmNameDisabled = true;
						this.getAllChipMpn();
					}
				} else if (this.dataSource === 'SEMICONDUCTORDATA' && this.subDataSource === 'DATA') {
					this.chipMpnNameDatas = [];
					for (const item of this.chipMpnNameList) {
						if (supplierIds.indexOf(item.name) !== -1) {
							this.chipMpnNameDatas.push(item);
						}
					}
					this.chipNpmNameDisabled = false;
					if (this.chipMpnNameDatas.length > 0) {
						this.chipNpmNameDisabled = true;
						this.getAllChipMpn();
					}
				} else if (this.dataSource === 'SILICONEXPERT' && this.subDataSource === 'DATA') {
					this.chipMpnNameDatas = [];
					for (const item of this.chipMpnNameList) {
						if (supplierIds.indexOf(item.name) !== -1) {
							this.chipMpnNameDatas.push(item);
						}
					}
					this.chipNpmNameDisabled = false;
					if (this.chipMpnNameDatas.length > 0) {
						this.chipNpmNameDisabled = true;
						this.getAllChipMpn();
					}
				}
			}
		});
	}

	private getAllUserTemplate() {
		this.valueStreamDataService.getVsmUserTemplate(this.userLoginId).subscribe((res: any) => {
			this.templateList = res;
		});
	}

	private getAllSupplier() {
		this.valueStreamDataService.getAllSupplier(this.selectedTemplate.tntvDataSource).subscribe((res: any) => {
			this.supplierList = res;
			this.supplierDatas = [];
			if (this.selectedTemplate.tntvSupplierIds) {
				for (const resKey of this.supplierList) {
					if (this.selectedTemplate.tntvSupplierIds.indexOf(resKey.name) !== -1) {
						this.supplierDatas.push(resKey);
					}
				}
				if (this.supplierDatas.length > 0) {
					this.supplierDisabled = true;
					this.getSupplierData();
				}
			}
			console.log(this.supplierList);
		});
	}

	private getAllFordPartNumberSupplier() {
		if (this.tier1SupplierDatas.length > 0) {
			const arrayList = [];
			for (const item of this.tier1SupplierDatas) {
				arrayList.push(item.name);
			}
			// tslint:disable-next-line:max-line-length
			this.valueStreamDataService.getAllFordPartNumberSupplier(this.selectedTemplate.tntvDataSource, this.selectedTemplate.tntvSubDataSource, arrayList.join('|')).subscribe((res: any) => {
				this.tier1FordPartNumberList = res;
			});
		}
	}

	private getAllSupplierMPN() {
		if (this.supplierDatas.length > 0) {
			const arrayList = [];
			for (const item of this.supplierDatas) {
				arrayList.push(item.name);
			}
			console.log(arrayList);
			this.valueStreamDataService.getAllMpn(this.selectedTemplate.tntvDataSource, arrayList.join(',')).subscribe((res: any) => {
				console.log(res);
				this.mpnList = res;
			});
		}
	}

	private getAllFordPartNumber() {
		if (this.tier1SupplierDatas.length > 0) {
			const arrayList = [];
			for (const item of this.tier1SupplierDatas) {
				arrayList.push(item.name);
			}
			console.log(arrayList);
			this.valueStreamDataService.getAllFordPartNo(this.selectedTemplate.tntvDataSource, arrayList.join(',')).subscribe((res: any) => {
				console.log(res);
				this.tier1FordPartNumberList = res;
			});
		}
	}

	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 100000
		});
	}

	loadData() {
		// this.spreadsheet.workbook = new Workbook();
		if (this.selectedTemplate) {
			const chipMpnList = [];
			const chipMpnNameList = [];
			const tier1SupplierList = [];
			const fordPartNoList = [];
			if (this.chipMpnDatas.length > 0) {
				for (const item of this.chipMpnDatas) {
					chipMpnList.push(item.name);
				}
			}
			if (this.chipMpnNameDatas.length > 0) {
				for (const item of this.chipMpnNameDatas) {
					chipMpnNameList.push(item.name);
				}
			}
			if (this.tier1SupplierDatas.length > 0) {
				for (const item of this.tier1SupplierDatas) {
					tier1SupplierList.push(item.name);
				}
			}
			if (this.tier1FordPartNumberDatas.length > 0) {
				for (const item of this.tier1FordPartNumberDatas) {
					fordPartNoList.push(item.name);
				}
			}
			this.valueStreamDataService.generateReportForVsmUpdate({
				cdsid: this.userLoginId,
				templateId: this.selectedTemplate.id,
				blankGenrate: 'No',
				insertAccess: this.selectedTemplate.tntvAdminUserInsertAccess,
				chipMpn: chipMpnList.join('|'),
				chipMpnName: chipMpnNameList.join('|'),
				supplierName: tier1SupplierList.join('|'),
				fordPartNo: fordPartNoList.join('|')
			}).subscribe((data) => {
				this.loadMemoryData = true;
				this.fileFromDatabase = data;
				this.submitStatus = true;
				ExcelUtility.load(this.fileFromDatabase).then((w) => {
					this.spreadsheet.workbook = w;
					console.log('Excel Loaded');
					this.loadMemoryData = false;
				});
			});
		} else {
			this.pushDialog('Please select template');
		}
	}

	downloadData() {
		if (this.selectedTemplate) {
			const chipMpnList = [];
			const chipMpnNameList = [];
			const tier1SupplierList = [];
			const fordPartNoList = [];
			if (this.chipMpnDatas.length > 0) {
				for (const item of this.chipMpnDatas) {
					chipMpnList.push(item.name);
				}
			}
			if (this.chipMpnNameDatas.length > 0) {
				for (const item of this.chipMpnNameDatas) {
					chipMpnNameList.push(item.name);
				}
			}
			if (this.tier1SupplierDatas.length > 0) {
				for (const item of this.tier1SupplierDatas) {
					tier1SupplierList.push(item.name);
				}
			}
			if (this.tier1FordPartNumberDatas.length > 0) {
				for (const item of this.tier1FordPartNumberDatas) {
					fordPartNoList.push(item.name);
				}
			}
			this.valueStreamDataService.generateReportForVsmUpdate({
				cdsid: this.userLoginId,
				templateId: this.selectedTemplate.id,
				insertAccess: this.selectedTemplate.tntvAdminUserInsertAccess,
				chipMpn: chipMpnList.join('|'),
				chipMpnName: chipMpnNameList.join('|'),
				supplierName: tier1SupplierList.join('|'),
				fordPartNo: fordPartNoList.join('|'),
				blankGenrate: 'No'
			}).subscribe((data) => {
				this.valueStreamDataService.downLoadFile(data, 'text/csv', this.selectedTemplate.tntvVsmTemplateName);
			});
		} else {
			this.pushDialog('Please select template');
		}
	}

	downloadBlankData() {
		if (this.selectedTemplate) {
			const chipMpnList = [];
			const chipMpnNameList = [];
			const tier1SupplierList = [];
			const fordPartNoList = [];
			if (this.chipMpnDatas.length > 0) {
				for (const item of this.chipMpnDatas) {
					chipMpnList.push(item.name);
				}
			}
			if (this.chipMpnNameDatas.length > 0) {
				for (const item of this.chipMpnNameDatas) {
					chipMpnNameList.push(item.name);
				}
			}
			if (this.tier1SupplierDatas.length > 0) {
				for (const item of this.tier1SupplierDatas) {
					tier1SupplierList.push(item.name);
				}
			}
			if (this.tier1FordPartNumberDatas.length > 0) {
				for (const item of this.tier1FordPartNumberDatas) {
					fordPartNoList.push(item.name);
				}
			}
			this.valueStreamDataService.generateReportForVsmUpdate({
				cdsid: this.userLoginId,
				templateId: this.selectedTemplate.id,
				insertAccess: this.selectedTemplate.tntvAdminUserInsertAccess,
				blankGenrate: 'Yes',
				chipMpn: chipMpnList.join('|'),
				chipMpnName: chipMpnNameList.join('|'),
				supplierName: tier1SupplierList.join('|'),
				fordPartNo: fordPartNoList.join('|')
			}).subscribe((data) => {
				this.valueStreamDataService.downLoadFile(data, 'text/csv', this.selectedTemplate.tntvVsmTemplateName);
			});
		} else {
			this.pushDialog( 'Please select template');
		}
	}
	upload(event, form) {
		for (const file of event.files) {
			this.selectedFiles.push(file);
		}
		const chipMpnList = [];
		const chipMpnNameList = [];
		const tier1SupplierList = [];
		const fordPartNoList = [];
		if (this.chipMpnDatas.length > 0) {
			for (const item of this.chipMpnDatas) {
				chipMpnList.push(item.name);
			}
		}
		if (this.chipMpnNameDatas.length > 0) {
			for (const item of this.chipMpnNameDatas) {
				chipMpnNameList.push(item.name);
			}
		}
		if (this.tier1SupplierDatas.length > 0) {
			for (const item of this.tier1SupplierDatas) {
				tier1SupplierList.push(item.name);
			}
		}
		if (this.tier1FordPartNumberDatas.length > 0) {
			for (const item of this.tier1FordPartNumberDatas) {
				fordPartNoList.push(item.name);
			}
		}
		this.valueStreamDataService.upload(
			this.selectedFiles[0],
			this.selectedTemplate.id,
			this.selectedTemplate.tntvAdminUserInsertAccess,
			chipMpnList.join('|'),
			chipMpnNameList.join('|'),
			tier1SupplierList.join('|'),
			fordPartNoList.join('|')).subscribe((data) => { },
			error => {
				this.pushDialog('Unable to Update Data, Please try again later');
				form.clear();
				this.selectedFiles.pop();
			}
		);
	}

	selectAll() {
		console.log(this.selected);
		for (const dataKey of this.diffrenceResult.differentRows) {
			if (dataKey.update !== 3) {
				dataKey.selected = this.selected;
			}
		}
	}

	saveSpreadsheet() {
		if (this.selectedTemplate) {
			const chipMpnList = [];
			const chipMpnNameList = [];
			const tier1SupplierList = [];
			const fordPartNoList = [];
			if (this.chipMpnDatas.length > 0) {
				for (const item of this.chipMpnDatas) {
					chipMpnList.push(item.name);
				}
			}
			if (this.chipMpnNameDatas.length > 0) {
				for (const item of this.chipMpnNameDatas) {
					chipMpnNameList.push(item.name);
				}
			}
			if (this.tier1SupplierDatas.length > 0) {
				for (const item of this.tier1SupplierDatas) {
					tier1SupplierList.push(item.name);
				}
			}
			if (this.tier1FordPartNumberDatas.length > 0) {
				for (const item of this.tier1FordPartNumberDatas) {
					fordPartNoList.push(item.name);
				}
			}
			const opt = new WorkbookSaveOptions();
			opt.type = 'blob';
			const workbook = this.spreadsheet.workbook;
			workbook.save(opt, (d) => {
				this.valueStreamDataService.upload(d as File,
					this.selectedTemplate.id,
					this.selectedTemplate.tntvAdminUserInsertAccess,
					chipMpnList.join('|'),
					chipMpnNameList.join('|'),
					tier1SupplierList.join('|'),
					fordPartNoList.join('|')
				).subscribe((data) => {
						this.diffrenceResult = data;

						if (this.diffrenceResult.differentRows.length > 0) {
							this.displayDialogue = true;
							for (const dataKey of this.diffrenceResult.differentRows) {
								if (dataKey.update !== 3) {
									dataKey.selected = true;
								} else {
									dataKey.selected = false;
								}
							}
						} else {
							this.pushDialog('No update records found!');
						}
					},
					error => {
						this.pushDialog('Unable to Update Data, Please try again later');
					}
				);
			}, (e) => {
				this.pushDialog( 'Unable to Update Data, Please try again later');
			});
		} else {
			this.pushDialog('Please select template');
		}

	}

	updateData() {
		if (this.diffrenceResult && this.diffrenceResult.differentRows.length > 0) {
			const rows = [];
			for (const item of this.diffrenceResult.differentRows) {
				if (item.selected) {
					rows.push(item);
				}
				//console.log(JSON.stringify(item.oldRecord),"->oldrecord");
			}
			if (rows.length === 0) {
				this.pushMessage('error', 'error',
					'Please select atleast one record to update');
				return;
			}
			this.valueStreamDataService.createApprovalRequest({
				templateId: this.selectedTemplate.id,
				records: JSON.stringify(rows),
				headers: JSON.stringify(this.diffrenceResult.headers)
			}).subscribe((data) => {
				this.selected = false;
				this.diffrenceResult = null;
				this.submitStatus = false;
				this.displayDialogue = false;
				this.pushMessage('success', 'success',
					'Data approval request created successfully');
				this.loadData();
			});

		}
	}

	onMPNNameChange(event: any) {
		this.getAllChipMpn();
	}

	onMPNChange(event: any) {
		this.getAllChipMpnFordPartNo();
	}

	private getAllChipMpnFordPartNo() {
		const arrayList = [];
		const arrayListChipMpn = [];
		for (const item of this.chipMpnNameDatas) {
			arrayList.push(item.name);
		}
		for (const item of this.chipMpnDatas) {
			arrayListChipMpn.push(item.name);
		}
		this.valueStreamDataService.getAllFordPartNoDataByChipMpnNameAndChipMpn(this.selectedTemplate.tntvDataSource,
			this.selectedTemplate.tntvSubDataSource,
			arrayList.join('|'),
			arrayListChipMpn.join('|')).subscribe((res: any) => {
			this.tier1FordPartNumberList = res;
		});
	}
	private getAllChipMpn() {
		if (this.chipMpnNameDatas.length > 0) {
			const arrayList = [];
			for (const item of this.chipMpnNameDatas) {
				arrayList.push(item.name);
			}
			// tslint:disable-next-line:max-line-length
			this.valueStreamDataService.getAllChipMpnDataByChipMpnName(this.selectedTemplate.tntvDataSource, this.selectedTemplate.tntvSubDataSource, arrayList.join('|')).subscribe((res: any) => {
				this.chipMpnList = res;
			});
		}
	}

	onFordPartNumberChange(event: any) {
		console.log(event);
		this.getAllFordPartNumberSupplier();
	}

	onSupplierChange(event: any) {
		this.supplierDatas = event.value;
		this.mpnDatas = [];
		this.getSupplierData();
	}

	getSupplierData() {
		this.mpnDatas = [];
		if (this.selectedTemplate.tntvDataSource !== 'STP') {
			this.getAllTier1SupplierMPN();
		} else {
			this.getAllSupplierMPN();
		}
	}

	onTier1SupplierChange(event: any) {
		this.tier1SupplierDatas = event.value;
		this.getData();
	}
	getData() {
		this.tier1FordPartNumberDatas = [];
		this.supplierDatas = [];
		this.mpnDatas = [];
		this.getTier1Datas();
	}
	getTier1Datas() {
		this.tier1FordPartNumberList = [];
		this.getAllFordPartNumber();
		// this.getAllICSupplier();
	}
	resetFilter() {
		this.tier1SupplierList = [];
		this.tier1FordPartNumberList = [];
		this.mpnDatas = [];
		this.supplierDatas = [];
		this.tier1SupplierDatas = [];
		this.tier1FordPartNumberDatas = [];
		this.mpnList = [];
		this.supplierDisabled = false;
		this.tier1SupplierDisabled = false;
		this.chipNpmNameDisabled = false;
		this.chipMpnDisabled = false;
		this.supplierList = [];
		this.chipMpnDatas = [];
		this.chipMpnNameDatas = [];
		this.getAllFilter();
		// this.loadData();
	}
	private getAllTier1SupplierMPN() {
		if (this.supplierDatas.length > 0) {
			const arrayList = [];
			for (const item of this.tier1FordPartNumberDatas) {
				arrayList.push(item.name);
			}
			const tier2SupplierList = [];
			for (const item of this.supplierDatas) {
				tier2SupplierList.push(item.name);
			}
			console.log(arrayList);
			// tslint:disable-next-line:max-line-length
			this.valueStreamDataService.getAllTier1SupplierMPN(this.selectedTemplate.tntvDataSource, arrayList.join(','), tier2SupplierList.join(',')).subscribe((res: any) => {
				console.log(res);
				this.mpnList = res;
			});
		}
	}

	private getAllICSupplier() {
		if (this.tier1SupplierDatas.length > 0) {
			const arrayList = [];
			for (const item of this.tier1SupplierDatas) {
				arrayList.push(item.name);
			}
			console.log(arrayList);
			this.valueStreamDataService.getAllICSupplier(this.selectedTemplate.tntvDataSource, arrayList.join(',')).subscribe((res: any) => {
				console.log(res);
				this.supplierList = res;
			});
		}
	}

	onRowSelect(rowInfo) {
		console.log(JSON.stringify(rowInfo));
		// alert(JSON.stringify(rowInfo));
	}

	displayDialogueYes() {
		if (this.selectedRequests.length !== 0) {
			this.pushMessage('success', 'success', 'In progress');
			// alert(JSON.stringify(this.selectedRequests));
			this.displayDialogue = false;
		} else {
			this.pushDialog('Please select anyone!');
		}
	}
	clickOperation(item) {
		this.oldRecordValue = null;
		this.newRecordValue = null;
		this.displayDifferentDialogue = true;
		this.newRecordValue = item;
		console.log('new record - >', this.newRecordValue);
		this.oldRecord = item.oldRecord;
		this.oldRecordValue = JSON.parse(this.oldRecord);
		console.log('old record - >', this.oldRecordValue);
	}

	selectRow(checkValue) {
		if (checkValue) {
			this.selectedRequests = this.diffrenceResult;
		} else {
			this.selectedRequests = [];
		}
	}

	pushDialog(dialogBody: any) {
		this.ref = this.dialogService.open(CustomDialogComponent, {
			header: 'Value Stream Map',
			footer: '',
			data: dialogBody,
			width: '50%',
			contentStyle: { 'max-height': '1000px',
				overflow: 'auto' },
			baseZIndex: 10000,
		});
	}
}
