import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SustainabilitySurveyComponent} from './sustainability-survey/sustainability-survey.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IgxSpreadsheetModule} from 'igniteui-angular-spreadsheet';
import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {MessageModule} from 'primeng/message';
import {DropdownModule} from 'primeng/dropdown';
import {MultiSelectModule} from 'primeng/multiselect';
import {CarouselModule} from 'primeng/carousel';
import {TableModule} from 'primeng/table';
// tslint:disable-next-line:import-blacklist
import {
    ContextMenuModule,
    FieldsetModule,
    PaginatorModule, ScrollPanelModule,
    SplitButtonModule,
    ToolbarModule,
    TreeModule
} from 'primeng/primeng';
import {
	SustainabilitySupplierAccessComponent
} from './sustainability-supplier-access/sustainability-supplier-access.component';
import {ToastModule} from 'primeng/toast';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {
	SustainabilitySurveyHelpTabComponent
} from './sustainability-survey-help-tab/sustainability-survey-help-tab.component';
import {FileUploadModule} from 'primeng/fileupload';
import {NgxSegmentedBarModule} from './ngx-segmented-bar/ngx-segmented-bar.module';
import { NonMicrochipTemplateComponent } from './non-microchip-template/non-microchip-template.component';

@NgModule({
	declarations: [
		SustainabilitySurveyComponent,
		SustainabilitySupplierAccessComponent,
		SustainabilitySurveyHelpTabComponent,
		NonMicrochipTemplateComponent
	],
    imports: [
        CommonModule,
        IgxSpreadsheetModule,
        ButtonModule,
        DialogModule,
        MessageModule,
        ReactiveFormsModule,
        DropdownModule,
        MultiSelectModule,
        CarouselModule,
        TableModule,
        TreeModule,
        ContextMenuModule,
        ToolbarModule,
        FieldsetModule,
        FormsModule,
        PaginatorModule,
        ToastModule,
        ConfirmDialogModule,
        FileUploadModule,
        NgxSegmentedBarModule,
        NgxSegmentedBarModule,
        SplitButtonModule, ContextMenuModule, ScrollPanelModule
    ]
})

export class SustainabilitySurveyModule {
}
