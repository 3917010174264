import {
	Component,
	OnInit
} from '@angular/core';
import {SupplierService} from '../../../home/services/supplier.service';
import {Router} from '@angular/router';
import {Tier1EComponentShortageTrackerService} from '../../tier1-e-component-shortage/service/tier1-e-component-shortage-tracker.service';

@Component({
	selector: 'clear-to-build-help',
	templateUrl: './clear-to-build-help.component.html',
	styleUrls: ['./clear-to-build-help.component.scss']
})
export class ClearToBuildHelpComponent implements OnInit {

	// tslint:disable-next-line:max-line-length
	constructor(private supplierService: SupplierService, private router: Router, private tier1EComponentShortageTrackerService: Tier1EComponentShortageTrackerService) {
	}
	isFordUser = false;

	ngOnInit() {
		this.router.navigate(['/']);
		// this.supplierService.pushSelectedSurvey('clearToBuild');
		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
		} else {
			if (!sessionStorage.getItem('uid').includes('-')) {
				this.isFordUser = true;
			}
		}
	}
	download(contentType: string, num: number) {
		if (num === 9) {
			this.tier1EComponentShortageTrackerService.helpEndPointDownload(num).subscribe((data) => {
				this.tier1EComponentShortageTrackerService.downloadFileMp4(data, contentType, 'Clear To Build Tutorial');
			});
		} else if (num === 8) {
			this.tier1EComponentShortageTrackerService.helpEndPointDownload(num).subscribe((data) => {
				this.tier1EComponentShortageTrackerService.downLoadFileDocumentInPdf(data, contentType, 'Clear To Build Training Document');
			});

		} else if (num === 10) {
			this.tier1EComponentShortageTrackerService.helpEndPointDownload(num).subscribe((data) => {
				this.tier1EComponentShortageTrackerService.downLoadFileDocument(data, contentType, 'FAQs');
			});
		}
	}

}
