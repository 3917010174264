import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeComponent} from './home.component';
import {HomeRoutingModule} from './home-routing.module';
import {CalendarModule} from 'primeng/calendar';
import {
	FormsModule,
	ReactiveFormsModule
} from '@angular/forms';
import {HomeService} from './services/home.service';
import {GsdbCodesService} from './services/gsdb-codes.service';
import {SupplierService} from './services/supplier.service';
import {SurveyService} from './services/survey.service';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {MessageModule} from 'primeng/message';
import {ListboxModule} from 'primeng/listbox';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {CheckboxModule} from 'primeng/checkbox';
import {AccordionModule} from 'primeng/accordion';

@NgModule({
	declarations: [
		HomeComponent
	],
	imports: [
		CommonModule,
		HomeRoutingModule,
		CalendarModule,
		ReactiveFormsModule,
		AutoCompleteModule,
		MessageModule,
		FormsModule,
		ListboxModule,
		ButtonModule,
		DropdownModule,
		CheckboxModule,
		AccordionModule

	],
	providers: [
		HomeService,
		GsdbCodesService,
		SupplierService,
		SurveyService
	]
})
export class HomeModule {
}
