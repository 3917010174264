import {
	Component,
	OnInit,
	ViewChild
} from '@angular/core';
import {SupplierService} from '../../../home/services/supplier.service';
import {Router} from '@angular/router';
import {NTierMasterAdminService} from '../../n-tier-master-admin/service/n-tier-master-admin.service';
import {
	FormBuilder,
	FormGroup
} from '@angular/forms';
import {
	DialogService,
	DynamicDialogRef,
	MessageService
} from 'primeng/api';
import {ClearToBuildTracker} from '../model/clear-to-build-tracker';
import {ClearToBuildTrackerService} from '../service/clear-to-build-tracker.service';
import {DatePipe} from '@angular/common';
import {CustomDialogComponent} from '../../../custom-dialog/custom-dialog/custom-dialog.component';
import {
	Workbook,
	WorkbookSaveOptions
} from 'igniteui-angular-excel';
import {ExcelUtility} from '../../tiger-shark-constraint-tracker/model/excel-utility';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {IgxSpreadsheetComponent} from 'igniteui-angular-spreadsheet';
import {Option} from '../../../home/model/ntier.option';
import {ClearToBuildAdmin} from '../model/clear-to-build-admin';

@Component({
	selector: 'clear-to-build-tracker',
	templateUrl: './clear-to-build-tracker.component.html',
	styleUrls: ['./clear-to-build-tracker.component.scss']
})
export class ClearToBuildTrackerComponent implements OnInit {

	// tslint:disable-next-line:max-line-length
	constructor(private supplierService: SupplierService, private router: Router, private fb: FormBuilder, private masterAdminService: NTierMasterAdminService, private clearToBuildTrackerService: ClearToBuildTrackerService, private datePipe: DatePipe, private dialogService: DialogService, private messageService: MessageService, private spinnerService: SpinnerService) {
	}

	isFordUser = false;
	questionCount = 0;
	isSuperAdmin = false;
	isCoeAdmin = false;
	isMpnlAdmin = false;
	isMpnlSuperAdmin = false;
	isScreenAccess = false;
	isDisabled = false;
	displayDialog = false;
	isSubmitVisible = false;
	selectedFiles: any[] = [];
	fileFromDatabase: any;
	clearToBuildForm: FormGroup;
	clearToBuildIdsForUpload: any[] = [];
	selectedClearToBuildIdsForUpload: any[] = [];
	constraintIds: any[] = [];
	businessUnits: any[] = [];
	supplierSiteCodes: any[] = [];
	plantCodes: any[] = [];
	partNumbers: any[] = [];
	selectedConstraintIds: any[] = [];
	selectedBusinessUnits: any[] = [];
	selectedSupplierSiteCodes: any[] = [];
	selectedPlantCodes: any[] = [];
	selectedPartNumbers: any[] = [];
	versionNumbers: any[] = [];
	selectedVersionNumbers: any[] = [];
	ref: DynamicDialogRef;
	clearToBuildTracker: ClearToBuildTracker;
	response: any = {success: false, message: ''};
	// weekDateResponse: any = {weekDate: '', weekNumber: '', ctbId: ''};
	weekDateResponseTemp: any = {weekDate: '', weekNumber: ''};
	filterObject: any = {name: '', code: ''};
	selectedClearToBuildIds: any[] = [];
	clearToBuildIds: any[] = [];
	basicShow  = false;
	// tslint:disable-next-line:max-line-length
	qs1 = ''; qs2 = ''; qs3 = ''; qs4 = ''; qs5 = ''; qs6 = ''; qs7 = ''; qs8 = ''; qs9 = '';

	@ViewChild('spreadsheet', {static: false, read: IgxSpreadsheetComponent})
	public spreadsheet: IgxSpreadsheetComponent;

	ctbId: string;
	cellsData: any = [];

	ngOnInit() {
		this.router.navigate(['/']);
		// this.supplierService.pushSelectedSurvey('clearToBuild');
		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
		} else {
			if (!(sessionStorage.getItem('uid').includes('-'))) {
				this.isFordUser = true;
				// tslint:disable-next-line:max-line-length
				this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build Super Admin').subscribe(userHasCtbSuperAdminAccess => {
					if (userHasCtbSuperAdminAccess) {
						this.isSuperAdmin = userHasCtbSuperAdminAccess;
					} else {
						// tslint:disable-next-line:max-line-length
						this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build COE Admin').subscribe(userHasCtbCoeAdminAccess => {
						if (userHasCtbCoeAdminAccess) {
							this.isCoeAdmin = userHasCtbCoeAdminAccess;
						} else {
							// tslint:disable-next-line:max-line-length
							this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build MP&L Admin').subscribe(userHasMpnlAdminAccess => {
								if (userHasMpnlAdminAccess) {
									this.isMpnlAdmin = userHasMpnlAdminAccess;
								} else {
									// tslint:disable-next-line:max-line-length
									this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build MP&L Super Admin').subscribe(userHasMpnlSuperAdminAccess => {
										if (userHasMpnlSuperAdminAccess) {
											this.isMpnlSuperAdmin = userHasMpnlSuperAdminAccess;
										} else {
											// tslint:disable-next-line:max-line-length
											this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build Screen').subscribe(userHasCtbScreenAccess => {
												if (!userHasCtbScreenAccess) {
													this.router.navigate(['/']);
												}
											});
										}
									});
								}});
						}
						});
						}
				});
				// tslint:disable-next-line:max-line-length
				this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build Screen').subscribe(userHasCtbScreenAccess => {
					if (userHasCtbScreenAccess) {
						this.isScreenAccess = userHasCtbScreenAccess;
					}});
			} else {
				// tslint:disable-next-line:max-line-length
				this.masterAdminService.isSupplierHasClearToBuildAccess(sessionStorage.getItem('emailAddress')).subscribe((clearToBuildSupplierAccess) => {
					if (!clearToBuildSupplierAccess) {
						this.router.navigate(['/']);
					}
				});
			}
		}
		this.clearToBuildForm = this.fb.group({
			ctbIdList: [{value: '', disabled: this.isDisabled}],
			constraintIdList: [{value: '', disabled: this.isDisabled}],
			plantCodeList: [{value: '', disabled: this.isDisabled}],
			partNumberList: [{value: '', disabled: this.isDisabled}],
			actualBusinessUnitList: [{value: '', disabled: this.isDisabled}],
			supplierSiteCodeList: [{value: '', disabled: this.isDisabled}],
			weekDate: [{value: '', disabled: true}],
			weekDateCal: [{value: '', disabled: this.isDisabled}],
			weekNumber: [{value: '', disabled: true}],
			weekDateEmail: [{value: '', disabled: this.isDisabled}],
			constraintIds: [{value: ''}],
			actualBusinessUnits: [{value: ''}],
			supplierSiteCodes: [{value: ''}],
			plantCodes: [{value: ''}],
			partNumbers: [{value: ''}],
			versionNumbers: [{value: ''}],
			weekDateUpload: [{value: '', disabled: false}],
			weekNumberUpload: [{value: '', disabled: true}],
			clearToBuildIdUpload: [{value: ''}]
		});
		// if (!this.isFordUser) {
		// 	this.findLatestWeekAvailable();
		// }
	}

	submitQuestions = () => {
		const questionsObject = {
				weekDate: new Date(this.clearToBuildForm.controls['weekDate'].value),
				weekNumber: this.clearToBuildForm.controls['weekNumber'].value,
				q1RootCause: this.qs1,
				q2Factors: this.qs2,
				q3ActionInitiated: this.qs3,
				q4ConfirmOnActions: this.qs4,
				q5LeadTime: this.qs5,
				q6DppMeeting: this.qs6,
				q7ImpactFirst3Weeks: this.qs7,
				q8ImpactFrom4Weeks: this.qs8,
				q9PremiumFreight: this.qs9,
				//selectedRowsData:this.cellsData,
				ctbId: this.ctbId,
				supplierRespondedContact: sessionStorage.getItem('emailAddress')
			};

		console.log(questionsObject);
		this.qs1 = this.qs2 = this.qs3 = this.qs4 = this.qs5 = this.qs6 = this.qs7 = this.qs8 = this.qs9 = '';
		this.basicShow = false;
		this.clearToBuildTrackerService.submitQuestionaire(questionsObject).subscribe((dt: any) => {
			console.log(dt);
			this.pushMessage('success', 'Success',
				'Data Updated Successfully!');
		});
	}

	private findLatestWeekAvailable() {
		this.clearToBuildTrackerService.findLatestWeekAvailable(sessionStorage.getItem('emailAddress')).subscribe((response) => {
			// this.weekDateResponse = response;
			// if (this.weekDateResponse.weekDate.length > 0) {
			// 	this.clearToBuildForm.patchValue({weekDate: this.datePipe.transform(this.weekDateResponse.weekDate, 'yyyy-MM-dd')});
			// 	this.clearToBuildForm.patchValue({weekNumber: this.weekDateResponse.weekNumber});
			// 	this.clearToBuildTracker = new ClearToBuildTracker();
			// 	this.clearToBuildTracker.weekDate = this.clearToBuildForm.get('weekDate').value;
			// 	this.constraintIds = [];
			// 	this.filterObject.code = this.weekDateResponse.ctbId;
			// 	this.filterObject.name = this.weekDateResponse.ctbId;
			// 	this.constraintIds.push(this.filterObject);
			// 	// this.fetchConstraintIdsFromEmailAndWeekDate();
			// } else {
			// 	this.pushDialog('Data currently not available.');
			// }
			this.weekDateResponseTemp = response;
			if (this.weekDateResponseTemp.weekDate.length > 0) {
				this.clearToBuildForm.patchValue({weekDate: this.datePipe.transform(this.weekDateResponseTemp.weekDate, 'yyyy-MM-dd')});
				this.clearToBuildForm.patchValue({weekNumber: this.weekDateResponseTemp.weekNumber});
				this.clearToBuildTracker = new ClearToBuildTracker();
				this.clearToBuildTracker.weekDate = this.clearToBuildForm.get('weekDate').value;
				this.clearToBuildTracker.supplierMailId = sessionStorage.getItem('emailAddress');
				this.clearToBuildTrackerService.findCtbIdsFromEmailAndWeekDate(this.clearToBuildTracker).subscribe((ctbIds) => {
					this.constraintIds = [];
					this.constraintIds = ctbIds;
				});
			} else {
				this.pushDialog('Data currently not available.');
			}
		});
	}

	// private fetchConstraintIdsFromEmailAndWeekDate() {
	// 	this.clearToBuildTracker = new ClearToBuildTracker();
	// 	// @ts-ignore
	// 	this.clearToBuildTracker.weekDate = this.datePipe.transform(this.weekDateResponse.weekDate, 'yyyy-MM-dd');
	// 	this.clearToBuildTracker.supplierMailId = sessionStorage.getItem('emailAddress');
	// 	this.clearToBuildTrackerService.getCtbIdsFromEmailAndWeekDate(this.clearToBuildTracker).subscribe((data) => {
	// 		this.constraintIds = [];
	// 		this.constraintIds = data;
	// 		if (this.constraintIds.length === 0) {
	// 			this.pushDialog('CTB Ids for the specific week are not available.');
	// 		}
	// 	});
	// }

	onWeekDateChange() {
		const weekDate = this.clearToBuildForm.get('weekDateCal').value;
		const d = new Date(Date.UTC(weekDate.getFullYear(), weekDate.getMonth(), weekDate.getDate()));
		const dayNum = d.getUTCDay() || 7;
		d.setUTCDate(d.getUTCDate() + 4 - dayNum);
		const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
		// @ts-ignore
		const weekNum = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
		this.clearToBuildForm.patchValue({weekNumber: weekNum});
		this.constraintIds = [];
		this.businessUnits = [];
		this.supplierSiteCodes = [];
		this.plantCodes = [];
		this.partNumbers = [];
		this.selectedConstraintIds = [];
		this.selectedBusinessUnits = [];
		this.selectedSupplierSiteCodes = [];
		this.selectedPlantCodes = [];
		this.selectedPartNumbers = [];
		this.clearToBuildTracker = new ClearToBuildTracker();
		// @ts-ignore
		// tslint:disable-next-line:max-line-length
		this.clearToBuildTracker.weekDate = this.datePipe.transform(this.clearToBuildForm.controls['weekDateCal'].value, 'yyyy-MM-dd');
		if (this.isScreenAccess || this.isSuperAdmin || this.isMpnlAdmin || this.isMpnlSuperAdmin) {
			this.clearToBuildTrackerService.getActiveConstraintsForWeek(this.clearToBuildTracker).subscribe(data => {
				this.constraintIds = data;
				if (this.constraintIds.length === 0) {
					this.createDialogMessage(this.clearToBuildTracker.weekDate);
				}
			});
		} else if (this.isCoeAdmin) {
			// @ts-ignore
			const clearToBuildAdmin = new ClearToBuildAdmin();
			clearToBuildAdmin.weekDate = this.datePipe.transform(this.clearToBuildForm.controls['weekDateCal'].value, 'yyyy-MM-dd');
			clearToBuildAdmin.purchasingCoeLead = sessionStorage.getItem('emailAddress');
			this.clearToBuildTrackerService.getCtbIdsFromWeekDateAndEmailId(clearToBuildAdmin).subscribe(data => {
				this.constraintIds = data;
				if (this.constraintIds.length === 0) {
					this.createDialogMessage(clearToBuildAdmin.weekDate);
					}
			});
		}
	}

	onConstraintIdChange(event: any) {
		this.selectedConstraintIds = event.value;
		this.businessUnits = [];
		this.supplierSiteCodes = [];
		this.plantCodes = [];
		this.partNumbers = [];
		this.versionNumbers = [];
		this.selectedBusinessUnits = [];
		this.selectedSupplierSiteCodes = [];
		this.selectedPlantCodes = [];
		this.selectedPartNumbers = [];
		this.selectedVersionNumbers = [];

		if (this.selectedConstraintIds.length > 0) {
			const constraintList = [];
			for (const item of this.selectedConstraintIds) {
				constraintList.push(item.name);
			}
			this.clearToBuildTracker.clearToBuildIdString = constraintList.join('|');
			this.clearToBuildTracker.clearToBuildId = constraintList[0];
			this.clearToBuildTrackerService.getActualBusinessUnits(this.clearToBuildTracker).subscribe(data => {
				this.businessUnits = data;
			});
			this.clearToBuildTrackerService.getSupplierFileVersionNumbers(this.clearToBuildTracker).subscribe(data => {
				this.versionNumbers = data;
			});
		}
	}

	onBUChange(event: any) {
		this.selectedBusinessUnits = event.value;
		this.supplierSiteCodes = [];
		this.plantCodes = [];
		this.partNumbers = [];
		this.selectedSupplierSiteCodes = [];
		this.selectedPlantCodes = [];
		this.selectedPartNumbers = [];

		if (this.selectedBusinessUnits.length > 0) {
			const actualBuList = [];
			for (const item of this.selectedBusinessUnits) {
				actualBuList.push(item.name);
			}
			this.clearToBuildTracker.actualBusinessUnit = actualBuList.join('|');
			this.clearToBuildTrackerService.getSupplierSiteCodes(this.clearToBuildTracker).subscribe(data => {
				this.supplierSiteCodes = data;
			});
		}
	}

	onSupplierSiteCodeChange(event: any) {
		this.selectedSupplierSiteCodes = event.value;
		this.plantCodes = [];
		this.partNumbers = [];
		this.selectedPlantCodes = [];
		this.selectedPartNumbers = [];
		if (this.selectedSupplierSiteCodes.length > 0) {
			const supplierSiteCodeList = [];
			for (const item of this.selectedSupplierSiteCodes) {
				supplierSiteCodeList.push(item.name);
			}
			this.clearToBuildTracker.supplierSiteCode = supplierSiteCodeList.join('|');
			this.clearToBuildTrackerService.getPlantCodes(this.clearToBuildTracker).subscribe(data => {
				this.plantCodes = data;
			});
		}
	}

	onPlantCodeChange(event: any) {
		this.selectedPlantCodes = event.value;
		this.partNumbers = [];
		this.selectedPartNumbers = [];
		if (this.selectedPlantCodes.length > 0) {
			const plantCodesList = [];
			for (const item of this.selectedPlantCodes) {
				plantCodesList.push(item.name);
			}
			this.clearToBuildTracker.plantCode = plantCodesList.join('|');
			this.clearToBuildTrackerService.getPartNumbers(this.clearToBuildTracker).subscribe(data => {
				this.partNumbers = data;
			});
		}
	}

	onPartNumberChange(event: any) {
		this.selectedPartNumbers = event.value;
		if (this.selectedPartNumbers.length > 0) {
			const partNumbersList = [];
			for (const item of this.selectedPartNumbers) {
				partNumbersList.push(item.name);
			}
			this.clearToBuildTracker.partNumber = partNumbersList.join('|');
		}
	}

	triggerSubtotalFormula() {
		console.log('Triggering Subtotal Formula');
		const colNumber = 25;
		// tslint:disable-next-line:max-line-length
		this.spreadsheet.workbook.worksheets(0).rows(6).cells(colNumber).value = this.spreadsheet.workbook.worksheets(0).rows(6).cells(colNumber).value + 1;
		// tslint:disable-next-line:max-line-length
		this.spreadsheet.workbook.worksheets(0).rows(6).cells(colNumber).value = this.spreadsheet.workbook.worksheets(0).rows(6).cells(colNumber).value - 1;
		if (!(sessionStorage.getItem('uid').includes('-'))) {
		// tslint:disable-next-line:max-line-length
		this.spreadsheet.workbook.worksheets(0).rows(7).cells(colNumber).value = this.spreadsheet.workbook.worksheets(0).rows(7).cells(colNumber).value + 1;
		// tslint:disable-next-line:max-line-length
		this.spreadsheet.workbook.worksheets(0).rows(7).cells(colNumber).value = this.spreadsheet.workbook.worksheets(0).rows(7).cells(colNumber).value - 1;
		}
	}

	generateReport(downloadReport: boolean) {
		this.basicShow = false;
		const clearToBuildTracker = new ClearToBuildTracker();
		if (this.isFordUser) {
			// @ts-ignore
			// tslint:disable-next-line:max-line-length
			clearToBuildTracker.weekDate = this.datePipe.transform(this.clearToBuildForm.controls['weekDateCal'].value, 'yyyy-MM-dd');
		} else {
			// @ts-ignore
			// tslint:disable-next-line:max-line-length
			clearToBuildTracker.weekDate = this.datePipe.transform(this.clearToBuildForm.controls['weekDate'].value, 'yyyy-MM-dd');
		}
		if (clearToBuildTracker.weekDate !== null) {
			if (this.constraintIds.length > 0) {
				if (this.selectedConstraintIds.length > 0) {
					const constraintList = [];
					for (const item of this.selectedConstraintIds) {
						constraintList.push(item.name);
					}
					clearToBuildTracker.clearToBuildIdString = constraintList.join('|');
					if (this.selectedBusinessUnits.length > 0) {
						const actualBuList = [];
						for (const item of this.selectedBusinessUnits) {
							actualBuList.push(item.name);
						}
						clearToBuildTracker.actualBusinessUnit = actualBuList.join('|');
					}
					if (this.selectedSupplierSiteCodes.length > 0) {
						const supplierSiteCodeList = [];
						for (const item of this.selectedSupplierSiteCodes) {
							supplierSiteCodeList.push(item.name);
						}
						clearToBuildTracker.supplierSiteCode = supplierSiteCodeList.join('|');
					}
					if (this.selectedPlantCodes.length > 0) {
						const plantCodesList = [];
						for (const item of this.selectedPlantCodes) {
							plantCodesList.push(item.name);
						}
						clearToBuildTracker.plantCode = plantCodesList.join('|');
					}
					if (this.selectedPartNumbers.length > 0) {
						const partNumbersList = [];
						for (const item of this.selectedPartNumbers) {
							partNumbersList.push(item.name);
						}
						clearToBuildTracker.partNumber = partNumbersList.join('|');
					}
					clearToBuildTracker.supplierMailId = sessionStorage.getItem('emailAddress');
					if (downloadReport) {
						this.clearToBuildTrackerService.generateCTBReport(clearToBuildTracker).subscribe((data) => {
							// tslint:disable-next-line:max-line-length
							this.clearToBuildTrackerService.downLoadFile(data, 'text/csv', clearToBuildTracker.clearToBuildIdString.toString(), clearToBuildTracker.weekDate.toString());
						});
						} else {
						this.clearToBuildTrackerService.generateCTBReport(clearToBuildTracker).subscribe((data) => {
							this.fileFromDatabase = data;
							this.spinnerService.show();
							ExcelUtility.load(this.fileFromDatabase).then((workbook) => {
								if (!this.isFordUser) {
									this.isSubmitVisible = true;
								}
								this.spreadsheet.workbook = workbook;
								this.spreadsheet.isRenamingWorksheet = false;
								this.spreadsheet.allowAddWorksheet = false;
								this.spreadsheet.allowDeleteWorksheet = false;
								this.spinnerService.hide();
								console.log('Excel Loaded');
								this.triggerSubtotalFormula();
							});
						});
					}
				} else {
					this.pushMessage('error', 'Error', 'Please select any CTB Id');
				}
			} else {
				this.pushDialog('CTB Ids for the specific Week are not ready yet.');
				// this.pushMessage('info', 'Info', 'Constraints for Current Week are not ready yet.');
			}
		} else {
			this.pushMessage('error', 'Error', 'Please select any Week Date');
		}
	}
	saveSpreadsheet() {
		this.cellsData = [];
		this.spinnerService.show();
		this.questionCount = 0;
		for (let i = 3; ; i++) {
			const supportBill = this.spreadsheet.workbook.worksheets(0).rows(i).cells(0).value;
			if (supportBill === 'Next shipment updated') {
				const particulars = this.spreadsheet.workbook.worksheets(0).rows(i).cells(24).value;
				if (particulars === 'Cum Gap to Release') {
					let nagativeWeek = false;
					let firstWeeksSetNagative = 'no';
					let secondWeeksSetNagative = 'no';
					for (let j = 25; j <= 36; j++) {
						const week: any = this.spreadsheet.workbook.worksheets(0).rows(i).cells(j).value;
						if (week < 0) {
							if (j === 25 || j === 26 || j === 27) {
								firstWeeksSetNagative = 'Yes';
							} else {
								secondWeeksSetNagative = 'Yes';
							}
							this.questionCount++;
							nagativeWeek = true;
						}
					}
					if (nagativeWeek === true) {
						const obj = {
							Supportability_Remarks: this.spreadsheet.workbook.worksheets(0).rows(i).cells(0).value,
							CTB_Id: this.spreadsheet.workbook.worksheets(0).rows(i).cells(1).value,
							BU_Actual: this.spreadsheet.workbook.worksheets(0).rows(i).cells(2).value,
							Plant_Id: this.spreadsheet.workbook.worksheets(0).rows(i).cells(4).value,
							Pleant_Name: this.spreadsheet.workbook.worksheets(0).rows(i).cells(5).value,
							Part_Name: this.spreadsheet.workbook.worksheets(0).rows(i).cells(6).value,
							firstWeekSet: firstWeeksSetNagative,
							seconsWeekSet: secondWeeksSetNagative
						};
						this.ctbId = obj.CTB_Id;
						this.cellsData.push(obj);
						console.log(this.cellsData);
						nagativeWeek = false;
					}
				}
			}
			const ctbId = this.spreadsheet.workbook.worksheets(0).rows(i).cells(1).value;
			if (ctbId === '') {
				break;
			}
		}

		this.downloadSpreadSheet();
	}

	closeModal = () => {
		this.basicShow = false;
	}
	downloadSpreadSheet = () => {
		const opt = new WorkbookSaveOptions();
		opt.type = 'blob';
		const workbook = this.spreadsheet.workbook;
		workbook.save(opt, (d) => {
			const fileExt = ExcelUtility.getExtension(workbook.currentFormat);
			const constraintList = [];
			for (const item of this.selectedConstraintIds) {
				constraintList.push(item.name);
			}
			const constraintId = constraintList.join(',');
			// const constraintId = this.selectedConstraintIds[0];
			// tslint:disable-next-line:max-line-length
			this.clearToBuildTrackerService.downloadSupplierCopy(d, 'text/csv', constraintId, this.datePipe.transform(this.clearToBuildForm.controls['weekDate'].value, 'yyyy-MM-dd'));
			this.clearToBuildTrackerService.supplierUpload(d as File, sessionStorage.getItem('emailAddress')).subscribe((data) => {
					if (this.questionCount !== 0) {
						this.pushDialog('Please fill in below questionnaire as the support plan updated do not meet Ford Production requirements ');
						this.basicShow = true;

					} else {
						this.pushMessage('success', 'Success',
							'Data Updated Successfully!');
					}
					this.isSubmitVisible = false;
					this.spreadsheet.workbook = new Workbook();
					// const s: Sheet = null;
					// s.this.spreadsheet.workbook.sheets().add(s);
				},
				error => {
					this.pushDialog('Unable to Update Data, Please try again later');
				}
			);
		}, (e) => {
			this.pushDialog('Unable to Update Data, Please try again later');
		});
	}

	fordUserUpload(event, form) {
		const weekDate = this.datePipe.transform(this.clearToBuildForm.controls['weekDateUpload'].value, 'yyyy-MM-dd');
		for (const file of event.files) {
			this.selectedFiles.push(file);
		}
		if ((weekDate !== null) && this.selectedClearToBuildIdsForUpload.length === 1) {
			// tslint:disable-next-line:max-line-length
			this.clearToBuildTrackerService.fordUserUpload(this.selectedFiles[0], weekDate, this.selectedClearToBuildIdsForUpload[0].name).subscribe((data) => {
					this.response = data;
					if (JSON.parse(this.response.success)) {
						this.pushDialog('File Uploaded Successfully');
					} else {
						this.pushDialog(this.response.message);
					}
					form.clear();
					this.selectedFiles.pop();
				},
				error => {
					this.pushDialog('Unable to Upload File, Please try again later');
					form.clear();
					this.selectedFiles.pop();
				}
			);
		} else {
			this.pushMessage('error', 'Error', 'Please select Week Date & CTB Id to Upload');
		}

	}

	supplierUpload(event, form) {
		for (const file of event.files) {
			this.selectedFiles.push(file);
		}
		this.clearToBuildTrackerService.supplierUpload(this.selectedFiles[0], sessionStorage.getItem('emailAddress')).subscribe((data) => {
				this.pushDialog('Data Updated Successfully!');
				// this.pushMessage('success', 'Success',
				// 	'Data Updated Successfully!');
				form.clear();
				this.selectedFiles.pop();
			},
			error => {
				this.pushDialog('Unable to Update Data, Please try again later');
				// this.pushMessage('error', 'Error', 'Unable to Update Data, Please try again later');
				form.clear();
				this.selectedFiles.pop();
			}
		);
	}

	onVersionNumberChange(event: any) {
		this.selectedVersionNumbers = event.value;
		if (this.selectedVersionNumbers.length > 0) {
			const versionNumberList = [];
			for (const item of this.selectedVersionNumbers) {
				versionNumberList.push(item.code);
			}
			this.clearToBuildTracker.versionNumber = versionNumberList[0];
		}
	}

	downSupplierFile() {
		const clearToBuildTracker = new ClearToBuildTracker();
		// @ts-ignore
		clearToBuildTracker.weekDate = this.datePipe.transform(this.clearToBuildForm.controls['weekDateCal'].value, 'yyyy-MM-dd');
		if (clearToBuildTracker.weekDate !== null) {
			if (this.constraintIds.length > 0) {
				if (this.selectedConstraintIds.length > 0) {
					if (this.versionNumbers.length > 0) {
						if (this.selectedConstraintIds.length > 0) {
							const constraintIdList = [];
							for (const item of this.selectedConstraintIds) {
								constraintIdList.push(item.name);
							}
							clearToBuildTracker.clearToBuildId = constraintIdList[0];
						}
						if (this.selectedVersionNumbers.length > 0) {
							const versionNumberList = [];
							for (const item of this.selectedVersionNumbers) {
								versionNumberList.push(item.code);
							}
							clearToBuildTracker.versionNumber = versionNumberList[0];
							console.log('downloadSupplierFile Request payload : ' + clearToBuildTracker);
							this.clearToBuildTrackerService.downloadSupplierFile(clearToBuildTracker);
						} else {
							this.pushMessage('error', 'Error', 'Please select any File Version');
						}
					} else {
						this.pushMessage('error', 'Error', 'No files are currently available for the selected Ctb Id.');
					}
				} else {
					this.pushMessage('error', 'Error', 'Please select any Ctb Id');
				}
			} else {
				this.pushDialog('Please check if Ctb Ids are available for the selected Week Date.');
				// this.pushMessage('info', 'Info', 'Please check if constraints are available for the selected Week Date');
			}
		} else {
			this.pushMessage('error', 'Error', 'Please select any Week Date');
		}
	}

	private getMondayFromCurrentDate(): Date {
		const currentDate = new Date();
		const currentDay = currentDate.getDay();
		const diff = currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1); // adjust when day is sunday
		return new Date(currentDate.setDate(diff));
	}

	pushDialog(dialogBody: any) {
		this.ref = this.dialogService.open(CustomDialogComponent, {
			header: 'Clear To Build Tracker',
			footer: '',
			data: dialogBody,
			width: '50%',
			contentStyle: {
				'max-height': '1000px',
				overflow: 'auto'
			},
			baseZIndex: 10000
		});
	}

	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 60000
		});
	}

	onClearToBuildIdChange(event: any) {
		this.selectedClearToBuildIdsForUpload = event.value;
	}

	downloadFordFile() {
		const clearToBuildTracker = new ClearToBuildTracker();
		// @ts-ignore
		clearToBuildTracker.weekDate = this.datePipe.transform(this.clearToBuildForm.controls['weekDateCal'].value, 'yyyy-MM-dd');
		if (clearToBuildTracker.weekDate !== null) {
			if (this.constraintIds.length > 0) {
				if (this.selectedConstraintIds.length > 0) {
							const constraintIdList = [];
							for (const item of this.selectedConstraintIds) {
								constraintIdList.push(item.name);
							}
							clearToBuildTracker.clearToBuildId = constraintIdList[0];
							this.clearToBuildTrackerService.checkFordFileExistence(clearToBuildTracker).subscribe(response => {
								if (response) {
									this.clearToBuildTrackerService.downloadFordFile(clearToBuildTracker);
								} else {
									this.pushMessage('error', 'Error', 'File does not exists');
								}
							});
				} else {
					this.pushMessage('error', 'Error', 'Please select any Ctb Id');
				}
			} else {
				this.pushDialog('Please check if Ctb Ids are available for the selected Week Date.');
			}
		} else {
			this.pushMessage('error', 'Error', 'Please select any Week Date');
		}
	}

	displayDataLoadDialog() {
		this.clearToBuildIdsForUpload = [];
		this.selectedClearToBuildIdsForUpload = [];
		this.clearToBuildIds = [];
		this.selectedClearToBuildIds = [];
		this.clearToBuildForm.controls['weekDateUpload'].reset();
		this.clearToBuildForm.controls['weekDateEmail'].reset();
		this.displayDialog = true;
		if (this.isSuperAdmin) {
			this.clearToBuildTrackerService.getAllCtbIds().subscribe(response => {
				this.clearToBuildIdsForUpload = response;
			});
		} else if (this.isCoeAdmin) {
			// @ts-ignore
			const clearToBuildAdmin = new ClearToBuildAdmin();
			clearToBuildAdmin.purchasingCoeLead = sessionStorage.getItem('emailAddress');
			this.clearToBuildTrackerService.getActiveCtbIdsFromEmailId(clearToBuildAdmin).subscribe(response => {
				this.clearToBuildIdsForUpload = response;
			});
		}
	}
	triggerEmail() {
		const weekDate = this.datePipe.transform(this.clearToBuildForm.controls['weekDateEmail'].value, 'yyyy-MM-dd');
		if ((weekDate !== null) && this.selectedClearToBuildIds.length > 0) {
			const ctbIds = [];
			for (const item of this.selectedClearToBuildIds) {
				ctbIds.push(item.name);
			}
			const clearToBuildTracker = new ClearToBuildTracker();
			// @ts-ignore
			clearToBuildTracker.weekDate = weekDate;
			clearToBuildTracker.clearToBuildIdString = ctbIds.join(',');
			this.clearToBuildTrackerService.triggerManualEmail(clearToBuildTracker).subscribe(response => {
				this.response = response;
				if (JSON.parse(this.response.success)) {
					this.pushDialog(this.response.message);
				} else {
					this.pushDialog(this.response.message);
				}
			});
		} else {
			this.pushMessage('error', 'Error', 'Please select Week Date & CTB Id to Trigger Email');
		}
	}

	onWeekDateEmailChange() {
		this.clearToBuildIds = [];
		this.selectedClearToBuildIds = [];
		this.clearToBuildTracker = new ClearToBuildTracker();
		// @ts-ignore
		// tslint:disable-next-line:max-line-length
		this.clearToBuildTracker.weekDate = this.datePipe.transform(this.clearToBuildForm.controls['weekDateEmail'].value, 'yyyy-MM-dd');
		if (this.isSuperAdmin) {
			this.clearToBuildTrackerService.getActiveConstraintsForWeek(this.clearToBuildTracker).subscribe(data => {
				this.clearToBuildIds = data;
				if (this.clearToBuildIds.length === 0) {
					this.pushDialog('Ctb Ids are not available for the selected Week.');
				}
			});
		} else if (this.isCoeAdmin) {
			// @ts-ignore
			const clearToBuildAdmin = new ClearToBuildAdmin();
			clearToBuildAdmin.weekDate = this.datePipe.transform(this.clearToBuildForm.controls['weekDateEmail'].value, 'yyyy-MM-dd');
			clearToBuildAdmin.purchasingCoeLead = sessionStorage.getItem('emailAddress');
			this.clearToBuildTrackerService.getCtbIdsFromWeekDateAndEmailId(clearToBuildAdmin).subscribe(data => {
				this.clearToBuildIds = data;
				if (this.clearToBuildIds.length === 0) {
					this.pushDialog('Ctb Ids are not available for the selected Week.');
				}
			});
		}
	}

	private createDialogMessage(weekDate: Date) {
		const date1 = new Date(this.datePipe.transform(weekDate, 'MM.dd.yyyy'));
		const date2 = new Date(this.datePipe.transform(this.getMondayFromCurrentDate(), 'MM.dd.yyyy'));
		if (date1.getTime() < date2.getTime()) {
			this.pushDialog('No CTB Ids were active for the selected Week.');
		} else if (date1.getTime() > date2.getTime()) {
			this.pushDialog('Ctb Ids for Selected Week are not ready yet.');
		} else {
			this.pushDialog('Ctb Ids for Current Week are not ready yet.');
		}
	}
}
