import {
	Component,
	OnInit,
	ViewChild
} from '@angular/core';
import {Router} from '@angular/router';
import {
	FormBuilder,
	FormGroup,
	Validators
} from '@angular/forms';
import {Option} from '../../../home/model/ntier.option';
import {NTierMasterAdminService} from '../../n-tier-master-admin/service/n-tier-master-admin.service';
import {DatePipe} from '@angular/common';
import {TigersharkConstraintAdmin} from '../model/tigershark-constraint-admin';
import {MessageService, DialogService, DynamicDialogRef} from 'primeng/api';
import {TigerSharkConstraintAdminService} from '../service/tiger-shark-constraint-admin.service';
import {ConstraintidList} from '../model/constraintid-list';
import {IgxSpreadsheetComponent} from 'igniteui-angular-spreadsheet';
import {ExcelUtility} from '../model/excel-utility';
import {TigerSharkAdminDashboardService} from '../service/tiger-shark-admin-dashboard.service';
import {
	Workbook,
	WorkbookSaveOptions
} from 'igniteui-angular-excel';
import {SupplierService} from '../../../home/services/supplier.service';
import {CustomDialogComponent} from '../../../custom-dialog/custom-dialog/custom-dialog.component';

@Component({
	selector: 'tiger-shark-constraint-tracker-admin',
	templateUrl: './tiger-shark-constraint-tracker-admin.component.html',
	styleUrls: ['./tiger-shark-constraint-tracker-admin.component.scss']
})
export class TigerSharkConstraintTrackerAdminComponent implements OnInit {
	// tslint:disable-next-line:max-line-length
	constructor(private supplierService: SupplierService, private fb: FormBuilder, private router: Router, private datepipe: DatePipe, private messageService: MessageService, private tigerSharkConstraintAdminService: TigerSharkConstraintAdminService, private tigerSharkAdminDashboardService: TigerSharkAdminDashboardService, private masterAdminService: NTierMasterAdminService, private dialogService: DialogService) {
	}

	tigerSharkConstraintInfo: FormGroup;
	tigersharkConstraintAdmin: TigersharkConstraintAdmin;
	isDisabled = false;
	isTigerSharkConstraintFormValid = false;
	constIdList = [];
	allConstIdList = [];
	gsdbCodeList = [];
	constraintIdResponse: ConstraintidList;
	yesNoOptions: Option[] = [];
	partTypeOptions: Option[] = [];
	selectedConstraintId: string;
	selectedGsdbCode: string;
	fileFromDatabase: any;
	file: File = null;
	selectedFiles: any[] = [];
	isSubmitVisible = false;
	disableGsdbCodeDropdown = false;
	constraintIdObject: any;
	allConstraintIdObject: any;
	gsdbCodeObject: any;
	isSuperAdmin = false;
	displayDialog = false;
	reportDisplay = false;
	toggle = true;
	ref: DynamicDialogRef;
	selectedConstraintIds: any[] = [];
	constraintIds: any[] = [];

	@ViewChild('spreadsheet', {static: false, read: IgxSpreadsheetComponent})
	public spreadsheet: IgxSpreadsheetComponent;

	showDialog() {
		this.tigerSharkConstraintInfo.reset();
		this.tigerSharkConstraintInfo.patchValue({numberOfMeetings: 0});
		this.displayDialog = true;
	}

	showReportDialog() {
		this.tigerSharkConstraintInfo.reset();
		this.selectedConstraintIds = [];
		this.reportDisplay = true;
	}

	ngOnInit() {
		this.router.navigate(['/']);

		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
			return;
			// } else {
			// 	// tslint:disable-next-line:max-line-length
			// 	this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'EDPP  Screen').subscribe(userHasAdminAccess => {
			// 		if (!userHasAdminAccess) {
			// 			this.router.navigate(['/']);
			// 		}
			// 	});
		} else if (sessionStorage.getItem('uid').includes('-')) {
			this.router.navigate(['/']);
			return;
		}
		// this.supplierService.pushSelectedSurvey('WPP');

		this.yesNoOptions = [
			{name: 'Yes', code: 'Yes'},
			{name: 'No', code: 'No'}];
		this.partTypeOptions = [
			{name: 'GlobalReference_VOParts', code: 'GlobalReference_VOParts'},
			{name: 'GlobalReference_MFGParts', code: 'GlobalReference_MFGParts'},
			{name: 'VO_AND_MFG_Parts', code: 'VO_AND_MFG_Parts'}];
		this.tigerSharkConstraintInfo = this.fb.group({
			constraintIdList: [{value: '', disabled: this.isDisabled}],
			gsdbCodeList: [{value: '', disabled: this.isDisabled}],
			weekDate: [{value: '', disabled: this.isDisabled}],
			weekNumber: [{value: '', disabled: this.isDisabled}],
			constraintId: ['', Validators.required],
			gsdbCode: [{value: '', disabled: this.isDisabled}, Validators.required],
			constraintIsActive: ['', Validators.required],
			purchasingCoeLead: [{value: '', disabled: this.isDisabled}, Validators.required],
			supplierName: [{value: '', disabled: this.isDisabled}, Validators.required],
			mpnlCoeLead: [{value: '', disabled: this.isDisabled}, Validators.required],
			purchasingBuyer: [{value: '', disabled: this.isDisabled}, Validators.required],
			purchasingLead: [{value: '', disabled: this.isDisabled}, Validators.required],
			globalLead: [{value: '', disabled: this.isDisabled}, Validators.required],
			coGlobalLead: [{value: '', disabled: this.isDisabled}, Validators.required],
			impactedBuNa: [{value: '', disabled: this.isDisabled}, Validators.required],
			impactedBuFsao: [{value: '', disabled: this.isDisabled}, Validators.required],
			impactedBuEu: [{value: '', disabled: this.isDisabled}, Validators.required],
			impactedBuImg: [{value: '', disabled: this.isDisabled}, Validators.required],
			impactedBuGcn: [{value: '', disabled: this.isDisabled}, Validators.required],
			highMarginVehicles: [{value: '', disabled: this.isDisabled}, Validators.required],
			f150Lightening: [{value: '', disabled: this.isDisabled}, Validators.required],
			commodityDetails: [{value: '', disabled: this.isDisabled}, Validators.required],
			purchasingBusinessCoordinators: [{value: '', disabled: this.isDisabled}, Validators.required],
			partType: [{value: '', disabled: this.isDisabled}, Validators.required],
			commodity: [{value: '', disabled: this.isDisabled}, Validators.required],
			pmt: [{value: '', disabled: this.isDisabled}, Validators.required],
			semiconductorSupplier: [{value: '', disabled: this.isDisabled}, Validators.required],
			meetings: [{value: '', disabled: this.isDisabled}],
			Frequency: [{value: '', disabled: this.isDisabled}],
			meetingTime: [{value: '', disabled: this.isDisabled}],
			numberOfMeetings: [{value: 0, disabled: this.isDisabled}],
			meetingLink: [{value: '', disabled: this.isDisabled}],
			day: [{value: '', disabled: this.isDisabled}],
			location: [{value: '', disabled: this.isDisabled}],
			contactName1: [{value: '', disabled: this.isDisabled}, Validators.required],
			contactNumber1: [{value: '', disabled: this.isDisabled}, Validators.required],
			email1: [{value: '', disabled: this.isDisabled}, Validators.required],
			contactName2: [{value: '', disabled: this.isDisabled}],
			contactNumber2: [{value: '', disabled: this.isDisabled}],
			email2: [{value: '', disabled: this.isDisabled}],
			contactName3: [{value: '', disabled: this.isDisabled}],
			contactNumber3: [{value: '', disabled: this.isDisabled}],
			email3: [{value: '', disabled: this.isDisabled}],
			supplierEmailIdTo: [{value: '', disabled: this.isDisabled}, Validators.required],
			supplierEmailIdCc: [{value: '', disabled: this.isDisabled}, Validators.required],
			fordUsersEmailTo: [{value: '', disabled: this.isDisabled}],
			fordUsersEmailCC: [{value: '', disabled: this.isDisabled}]
		});
		// tslint:disable-next-line:max-line-length
		this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'WPP Super Admin').subscribe(userHasEdppSuperAdminAccess => {
			this.isSuperAdmin = userHasEdppSuperAdminAccess;
			if (this.isSuperAdmin) {
				this.getAllConstraintIdList();
			} else {
				this.fetchConstraintIdsFromEmail();
			}
		});
	}

	private getAllConstraintIdList() {
		this.constraintIds = [];
		this.tigerSharkConstraintAdminService.getAllConstraintId().subscribe((data) => {
			if (data.length > 0) {
				this.constraintIds = data;
			} else {
				this.pushDialog('Constraints are not currently available');
			}
	});
	}

	private fetchConstraintIdsFromEmail() {
		this.constraintIds = [];
		this.tigerSharkConstraintAdminService.getConstraintIdFromEmail(sessionStorage.getItem('emailAddress')).subscribe((data) => {
			if (data.length > 0) {
				this.constraintIds = data;
			} else {
				this.pushDialog('Constraints are not currently available');
			}
		});
	}

	getCurrentWeekNumber(): number {
		const currentDate = new Date();
		const d = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()));
		const dayNum = d.getUTCDay() || 7;
		d.setUTCDate(d.getUTCDate() + 4 - dayNum);
		const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
		// @ts-ignore
		const weekNum = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
		// this.tigerSharkConstraintInfo.patchValue({weekNumber : weekNum});
		return weekNum;

	}

	getWeekNumberFromWeekDate() {
		const currentDate = this.tigerSharkConstraintInfo.get('weekDate').value;
		const d = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()));
		const dayNum = d.getUTCDay() || 7;
		d.setUTCDate(d.getUTCDate() + 4 - dayNum);
		const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
		// @ts-ignore
		const weekNum = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
		this.tigerSharkConstraintInfo.patchValue({weekNumber: weekNum});
	}

	private getMondayFromCurrentDate(): Date {
		const currentDate = new Date();
		const currentDay = currentDate.getDay();
		const diff = currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1); // adjust when day is sunday
		return new Date(currentDate.setDate(diff));
	}

	loadData() {
		if (this.selectedConstraintIds.length > 0) {
			const constraintList = [];
			for (const item of this.selectedConstraintIds) {
				constraintList.push(item.name);
			}
			// @ts-ignore
			const tigersharkConstraintAdmin = new TigersharkConstraintAdmin();
			tigersharkConstraintAdmin.constraintId = constraintList.join(';!');
			tigersharkConstraintAdmin.purchasingCoeLead = sessionStorage.getItem('emailAddress');
			tigersharkConstraintAdmin.weekDate = this.datepipe.transform(this.getMondayFromCurrentDate(), 'yyyy-MM-dd');
			tigersharkConstraintAdmin.weekNumber = this.getCurrentWeekNumber();
			// tslint:disable-next-line:max-line-length
			this.tigerSharkConstraintAdminService.generateWppAdminReport(tigersharkConstraintAdmin).subscribe((data) => {
				this.fileFromDatabase = data;
				ExcelUtility.load(this.fileFromDatabase).then((w) => {
					this.isSubmitVisible = true;
					this.spreadsheet.workbook = w;
				});
			});
		} else {
			this.pushMessage('error', 'Error', 'Please Select any Constraint Id.');
		}
	}

	downloadData() {
		if (this.selectedConstraintIds.length > 0) {
			const constraintList = [];
			for (const item of this.selectedConstraintIds) {
				constraintList.push(item.name);
			}
			// @ts-ignore
			const tigersharkConstraintAdmin = new TigersharkConstraintAdmin();
			tigersharkConstraintAdmin.constraintId = constraintList.join(';!');
			tigersharkConstraintAdmin.purchasingCoeLead = sessionStorage.getItem('emailAddress');
			tigersharkConstraintAdmin.weekDate = this.datepipe.transform(this.getMondayFromCurrentDate(), 'yyyy-MM-dd');
			tigersharkConstraintAdmin.weekNumber = this.getCurrentWeekNumber();
			// tslint:disable-next-line:max-line-length
			this.tigerSharkConstraintAdminService.generateWppAdminReport(tigersharkConstraintAdmin).subscribe((data) => {
				this.tigerSharkAdminDashboardService.downLoadFile(data, 'text/csv');
			});
		} else {
			this.pushMessage('error', 'Error', 'Please Select any Constraint Id.');
		}
	}

	loadSuperAdminData() {
		if (this.selectedConstraintIds.length > 0) {
			const constraintList = [];
			for (const item of this.selectedConstraintIds) {
				constraintList.push(item.name);
			}
			// @ts-ignore
			const tigersharkConstraintAdmin = new TigersharkConstraintAdmin();
			tigersharkConstraintAdmin.constraintId = constraintList.join(';!');
			tigersharkConstraintAdmin.purchasingCoeLead = sessionStorage.getItem('emailAddress');
			tigersharkConstraintAdmin.weekDate = this.datepipe.transform(this.getMondayFromCurrentDate(), 'yyyy-MM-dd');
			tigersharkConstraintAdmin.weekNumber = this.getCurrentWeekNumber();
			// tslint:disable-next-line:max-line-length
			this.tigerSharkConstraintAdminService.generateWppAdminReport(tigersharkConstraintAdmin).subscribe((data) => {
				this.fileFromDatabase = data;
				ExcelUtility.load(this.fileFromDatabase).then((w) => {
					this.isSubmitVisible = true;
					this.spreadsheet.workbook = w;
				});
			});
		} else {
			this.pushMessage('error', 'Error', 'Please Select any Constraint Id.');
		}
	}

	downloadSuperAdminData() {
		if (this.selectedConstraintIds.length > 0) {
			const constraintList = [];
			for (const item of this.selectedConstraintIds) {
				constraintList.push(item.name);
			}
			// @ts-ignore
			const tigersharkConstraintAdmin = new TigersharkConstraintAdmin();
			tigersharkConstraintAdmin.constraintId = constraintList.join(';!');
			tigersharkConstraintAdmin.purchasingCoeLead = sessionStorage.getItem('emailAddress');
			tigersharkConstraintAdmin.weekDate = this.datepipe.transform(this.tigerSharkConstraintInfo.controls['weekDate'].value, 'yyyy-MM-dd');
			tigersharkConstraintAdmin.weekNumber = this.tigerSharkConstraintInfo.get('weekNumber').value;
			if (this.validateFilter(tigersharkConstraintAdmin)) {
				// tslint:disable-next-line:max-line-length
				this.tigerSharkConstraintAdminService.generateWppAdminReport(tigersharkConstraintAdmin).subscribe((data) => {
					this.tigerSharkAdminDashboardService.downLoadFile(data, 'text/csv');
					this.selectedConstraintIds = [];
					this.reportDisplay = false;
				});
			} else {
				this.pushMessage('error', 'Error', 'Please Complete the filter.');
			}
		} else {
			this.pushMessage('error', 'Error', 'Please Select any Constraint Id.');
		}
	}

	private validateFilter(tigerSharkConstraintAdmin: TigersharkConstraintAdmin): boolean {
		let isValidFilter = false;
		if ((tigerSharkConstraintAdmin.constraintId) &&
			tigerSharkConstraintAdmin.weekDate !== null) {
			isValidFilter = true;
		}
		return isValidFilter;
	}

	isNotDigit(value: number) {
		return isNaN(value);
	}

	onUpdate() {
		const opt = new WorkbookSaveOptions();
		opt.type = 'blob';
		const workbook = this.spreadsheet.workbook;
		// @ts-ignore
		// const tigersharkConstraintAdmin = new TigersharkConstraintAdmin();
		// tigersharkConstraintAdmin.weekDate = this.datepipe.transform(this.tigerSharkConstraintInfo.controls['weekDate'].value, 'yyyy-MM-dd');
		workbook.save(opt, (d) => {
			const fileExt = ExcelUtility.getExtension(workbook.currentFormat);
			const fileName = 'Tiger Shark Constraint Admin Tracker' + fileExt;
			// this.tigerSharkAdminDashboardService.downLoadFile(d, 'text/csv');
			this.tigerSharkAdminDashboardService.update(d as File).subscribe((data) => {
					this.pushDialog('Data Updated Successfully!');
					// this.pushMessage('success', 'success',
					// 	'Data Updated Successfully!');
					this.isSubmitVisible = false;
					this.spreadsheet.workbook = new Workbook();
				},
				error => {
					this.pushDialog('Unable to Update Data, Please try again later');
					// this.pushMessage('error', 'error',
					// 	'Unable to Update Data, Please try again later');
				}
			);
		}, (e) => {
			this.pushDialog('Unable to Update Data, Please try again later');
			// this.pushMessage('error', 'Error', 'Unable to Update Data, Please try again later');
		});
	}

	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 60000
		});
	}

	tigersharkConstraintForm() {
		this.router.navigate(['/tiger-shark-constraint-tracker']);
	}

	scheduler() {
		this.router.navigate(['/scheduler']);
	}

	onCreate() {
		let constraintId = this.tigerSharkConstraintInfo.get('constraintId').value;
		this.tigersharkConstraintAdmin = this.tigerSharkConstraintInfo.getRawValue() as TigersharkConstraintAdmin;
		this.tigersharkConstraintAdmin.constraintId = constraintId;
				// tslint:disable-next-line:max-line-length
		this.tigersharkConstraintAdmin.constraintIsActive = (this.tigerSharkConstraintInfo.get('constraintIsActive').value.code === 'Yes') ? true : false;
				// tslint:disable-next-line:max-line-length
		this.tigersharkConstraintAdmin.partType = this.tigerSharkConstraintInfo.get('partType').value.code;

				// console.log(this.tigersharkConstraintAdmin);
		if (!this.tigerSharkConstraintFormValid(this.tigersharkConstraintAdmin)) {
					this.isTigerSharkConstraintFormValid = false;
					this.pushMessage('error', 'Error', 'Please fill all mandatory fields for adding new constraint information.');
				} else {
					this.isTigerSharkConstraintFormValid = true;
				}
		if (this.isTigerSharkConstraintFormValid) {
			constraintId = constraintId.trim();
			this.tigerSharkConstraintAdminService.checkConstraintExistence(constraintId).subscribe(isConstraintExists => {
						if (isConstraintExists) {
							this.pushMessage('error', 'Error', 'Constraint #' + constraintId + ' already Exists');
						} else {
							// tslint:disable-next-line:max-line-length
							this.tigerSharkConstraintAdminService.createTigerSharkAdmin(this.tigersharkConstraintAdmin).subscribe(response => {
								this.tigersharkConstraintAdmin = response;
								// tslint:disable-next-line:max-line-length
								this.pushMessage('success', 'Success', 'Constraint Id : ' + this.tigersharkConstraintAdmin.constraintId + ' has been Created successfully');
								this.tigerSharkConstraintInfo.reset();
								this.displayDialog = false;
								this.getAllConstraintIdList();
							});
						}
					});
				}
	}

	private tigerSharkConstraintFormValid(tigerSharkConstraintAdmin: TigersharkConstraintAdmin): boolean {
		let isTigerSharkConstraintFormValid = false;
		if (tigerSharkConstraintAdmin.constraintId !== null && tigerSharkConstraintAdmin.constraintId !== '' &&
			tigerSharkConstraintAdmin.gsdbCode !== null && tigerSharkConstraintAdmin.gsdbCode !== '' &&
			tigerSharkConstraintAdmin.constraintIsActive !== null &&
			tigerSharkConstraintAdmin.partType !== null && tigerSharkConstraintAdmin.partType !== '' &&
			tigerSharkConstraintAdmin.purchasingCoeLead !== null && tigerSharkConstraintAdmin.purchasingCoeLead !== '' &&
			tigerSharkConstraintAdmin.semiconductorSupplier !== null && tigerSharkConstraintAdmin.semiconductorSupplier !== '' &&
			tigerSharkConstraintAdmin.commodity !== null && tigerSharkConstraintAdmin.commodity !== '' &&
			tigerSharkConstraintAdmin.globalLead !== null && tigerSharkConstraintAdmin.globalLead !== '' &&
			tigerSharkConstraintAdmin.mpnlCoeLead !== null && tigerSharkConstraintAdmin.mpnlCoeLead !== '' &&
			tigerSharkConstraintAdmin.purchasingLead !== null && tigerSharkConstraintAdmin.purchasingLead !== '' &&
			tigerSharkConstraintAdmin.purchasingBuyer !== null && tigerSharkConstraintAdmin.purchasingBuyer !== '' &&
			tigerSharkConstraintAdmin.pmt !== null && tigerSharkConstraintAdmin.pmt !== '' &&
			tigerSharkConstraintAdmin.supplierName !== null && tigerSharkConstraintAdmin.supplierName !== '' &&
			tigerSharkConstraintAdmin.contactName1 !== null && tigerSharkConstraintAdmin.contactName1 !== '' &&
			tigerSharkConstraintAdmin.contactNumber1 !== null && tigerSharkConstraintAdmin.contactNumber1 !== '' &&
			tigerSharkConstraintAdmin.email1 !== null && tigerSharkConstraintAdmin.email1 !== '' &&
			tigerSharkConstraintAdmin.supplierEmailIdTo !== null && tigerSharkConstraintAdmin.supplierEmailIdTo !== '') {
			isTigerSharkConstraintFormValid = true;
		}
		return isTigerSharkConstraintFormValid;
	}
	pushDialog(dialogBody: any) {
		this.ref = this.dialogService.open(CustomDialogComponent, {
			header: 'WPP Constraint Tracker Administrator',
			footer: '',
			data: dialogBody,
			width: '50%',
			contentStyle: { 'max-height': '1000px',
				overflow: 'auto' },
			baseZIndex: 10000,
		});
	}
	onConstraintIdChange(event: any) {
		this.selectedConstraintIds = event.value;
	}
}
