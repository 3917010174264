import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ConfirmationService, DialogService, DynamicDialogRef, MenuItem, MessageService, TreeNode} from 'primeng/api';
import {IgxSpreadsheetComponent, LongValue} from 'igniteui-angular-spreadsheet';
import {ExcelUtility} from '../../tiger-shark-constraint-tracker/model/excel-utility';
import {WorkbookSaveOptions} from 'igniteui-angular-excel';
import {SustainabilitySurveyService} from '../Servies/sustainability-survey.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AbstractControl} from '@angular/forms';
import {isInteger, now, toInteger} from 'lodash';
import {resolve} from '@angular/compiler-cli/src/ngtsc/file_system';
import {CustomDialogComponent} from '../../../custom-dialog/custom-dialog/custom-dialog.component';
import {SupplierService} from '../../../home/services/supplier.service';
import {
	NTierMasterAdminService
} from '../../n-tier-master-admin/service/n-tier-master-admin.service';
import {any} from 'codelyzer/util/function';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {ContextMenu} from 'primeng/contextmenu';
import {Router} from "@angular/router";

function integerValidator(control: AbstractControl): { [key: string]: boolean } | null {
	// tslint:disable-next-line:no-shadowed-variable
	function isInteger(str: string): boolean {
		// Regular expression to match only digits
		const regExp = /^\d+$/;

		return regExp.test(str);
	}

	console.log(control, isInteger(control.value));
	if (isInteger(control.value)) {
		return null;
	}
	return {integer: true};
}

@Component({
	selector: 'sustainability-survey',
	templateUrl: './sustainability-survey.component.html',
	styleUrls: ['./sustainability-survey.component.scss'],
	providers: [MessageService, ConfirmationService],
	encapsulation: ViewEncapsulation.None
})
export class SustainabilitySurveyComponent implements OnInit {

	@ViewChild('spreadsheet', {static: false, read: IgxSpreadsheetComponent})
	public spreadsheet: IgxSpreadsheetComponent;

	@ViewChild('cm', {static: true}) cm: ContextMenu;

	treeDirectoryData: any;
	partNumber: any;
	description: any;
	name:any;
	splitButtonItemsPart: any[] = [
		{label: 'Add Excel', command: () => this.addNewPartExcel()}
	];
	supplierName: any;
	// tslint:disable-next-line:variable-name
	TierNumber: any;
	pageTotalRows = 10;
	vehicleCode: any;
	partNumbers: any = [];
	supplierNames: any = [];
	vehicleCodes: any = [];
	documentNames: any = [];
	// tslint:disable-next-line:max-line-length
	documentName = [];
	cols: any[];
	objectKeys = Object.keys;
	tier2 = false;
	supInfo;
	projectRAccess;
	addDialog = false;
	viewDialog = false;
	editDialog = false;
	productDialogCopy = false;
	t1TableView = false;
	t2TableView = false;
	t3TableView = false;
	childInformation: any = [];
	checkQuestionnaireForm: any = {};
	rightClickContextTable2: MenuItem[];
	rightClickContextTable1: MenuItem[];
	rightClickContextTable3: MenuItem[];
	rightClickContextTable4: MenuItem[];
	rightClickContextForTree: any;
	enableShowAttachements = false;
	childFlag = false;
	enableShowQuestionnaire = false;
	answerQuestionnaire = true;
	uploadEvidences = false;
	loadMemoryData = false;

	partTableView = false;
	excelFile: any;
	gsdb: string;
	tier1Supplier: string;

	baseInformation: any = [];
	selectedItem1: any = {};
	selectedItem: any = {};
	selectedItem2: any = {};
	selectedItemLoc1 = [];
	selectedItemLoc2 = [];
	formGroup: any;
	selectedchildDataContext: any;
	partsPerPageOptions = [
		{parts_per_page: 10},
		{parts_per_page: 20},
		{parts_per_page: 30},
		{parts_per_page: 40},
		{parts_per_page: 50}
	];
	selectedPartsPerPage = {parts_per_page: 10};
	selectedchildEvidenceDataContext: any;
	hideAndShowBaseOrChildInformation = false;
	response: any = {success: false, message: ''};
	acceptedFiles = '.pdf, .jpg, .png, .doc, .docx, .xls, .xlsx, .csv';

	// tslint:disable-next-line:max-line-length
	selectedBaseDataContext: any;
	showMainContentExcelSheet = false;
	showMainContent = false;
	// tslint:disable-next-line:max-line-length
	selectedFiles: File[] = [];

	successModal: boolean;

	private fileFromDatabase: any;

	private rootColumnNo23: any;
	private prevSupId22: any;
	private fordPartIDColumnNo19: any;
	private tierLevelColumnNo21: any;
	private flagNewTier = false;
	newTierButtonLabel: any;
	private flagNewPart = false;
	private limit: number;
	private totalPageCount = 10;
	private page = 1;
	private partTierSuppROWID: number;
	evidenceTableData = ['Evidence_Document_Name', 'Additional_Comments', 'Upload_Date_time', 'Updated_By'];

	// tslint:disable-next-line:max-line-length
	modelArraySupplier = [
		'tier_part_description',
		'supplier_name',
		'mfg_GSDB_code',
		'mfg_address',
		'mfg_city',
		'mfg_state',
		'mfg_country',
		'mfg_zip_code',
		'mfg_duns_number',
		'ship_GSDB_code',
		'ship_address',
		'ship_city',
		'ship_state',
		'ship_country',

		'ship_zip_code',
		'ship_duns_number',
		'supplier_role',
		'email_address',
		'contact_number',
		'part_name',
		'logistics_lead_time_to_FORD',
		'mode_of_shipment',
		'mfg_lead_time',
		'supp_row_ID',
		'part_tier_supp_ID',
		'ford_part_ID',
		'update_1_insert_2',
		'tier_level',
		'prev_part_tier_supp_ID',
		'root_part_tier_supp_ID',

	];
	modelArrayPart = [
		'ford_full_part_no',
		'prefix',
		'base',
		'suffix',
		'vehicle_code',
		'ford_commodity_code',
		'ford_commodity_name',
		'high_risk_material',
		'usage_qty',
		'ford_part_ID',
		'part_tier_supp_ID',
		'update_1_insert_2',
		'tier_level'
	];

	supplierForm: FormGroup;
	private getCallJson: FormGroup;
	partForm: FormGroup;
	QuestionnaireForm: FormGroup;

	// tslint:disable-next-line:max-line-length
	private pageNo = 1;
	pageRows = 10;
	baseInformationFlag = false;

	// tslint:disable-next-line:max-line-length
	private selectedPartNumber: any = null;
	private selectedVehicleNumber: any = null;
	private selectedSupplierChange: any = null;
	private noRecordsFound = false;
	supplierLogin = false;

	// tslint:disable-next-line:max-line-length
	private severity: string;
	private summary: string;

	addNewPartFlag: boolean;

	uploadedEvidences = false;
	partTierSupplierId: any;
	private email: any;
	private file: File = null;
	submitStatus = true;
	additionalComments: any = '';

	ref: DynamicDialogRef;
	evidenceList = [];
	evidenceAccessFlags = [];
	isEvidenceCollectionRequired: boolean;
	isQuestionnaireRequired = true;
	$index = 1;

	// tslint:disable-next-line:max-line-length
	popup = false;
	locationFlag1 = false;
	locationFlag2 = false;
	// tslint:disable-next-line:max-line-length
	private fordPartNumber: any;
	answeredQuestionnaire = false;
	// tslint:disable-next-line:max-line-length
	private resID: any;
	source: any;

	// tslint:disable-next-line:max-line-length
	private enableBatterySymbol = false;
	private collectionId: number;
	businessName: string;
	projectRflag: boolean = false;

	flagRegexCheck: boolean;
	gSDBCodeDropDown: any;
	gSDBCode: any;

	// tslint:disable-next-line:max-line-length
	private disableLink: boolean;
	constructor(private spinnerService1: SpinnerService, private masterAdminService: NTierMasterAdminService, private supplierService: SupplierService, private formBuilder: FormBuilder, private confirmationService: ConfirmationService, private sustainabilitySurveyService: SustainabilitySurveyService, private messageService: MessageService, private dialogService: DialogService, private router: Router) {
	}

	ngOnInit() {

		this.businessName = sessionStorage.getItem('Admin');
		if (this.businessName === 'sustainability survey') {
			console.log('sustainability survey');
			this.supplierService.pushSelectedSurvey('sustainability survey');
		} else if (this.businessName === 'Project R') {
			console.log('Project R');
			this.projectRflag = true;
			this.supplierService.pushSelectedSurvey('Project R');
		}
		if (sessionStorage.getItem('uid').includes('-')) {
			this.supplierLogin = true;
		} else {
			this.supplierLogin = false;
		}

		this.QuestionnaireForm = this.formBuilder.group({
			resId: new FormControl(''),
			Question1: new FormControl('N'),
			Locations1: new FormArray([]),
			Question2: new FormControl('N'),
			Locations2: new FormArray([]),
			bufferStockCommoditymin: new FormControl(''),
			bufferStockCommoditymax: new FormControl('Y'),
			pcba: new FormControl('')
		});
		this.supplierForm = this.formBuilder.group({
			tier_part_description: [''],
			supplier_name: [''],
			mfg_GSDB_code: [''],
			mfg_address: [''],
			mfg_city: [''],
			mfg_state: [''],
			mfg_country: [''],
			mfg_zip_code: [''],

			ship_GSDB_code: [''],
			ship_address: [''],
			ship_city: [''],
			ship_state: [''],
			ship_country: [''],

			ship_zip_code: [''],
			supplier_role: [''],
			email_address: ['', [Validators.required, Validators.email]],
			contact_number: [''],
			supp_row_ID: [''],
			part_tier_supp_ID: [''],
			ford_part_ID: [''],
			update_1_insert_2: [''],
			Tier_Level: [''],
			prev_part_tier_supp_ID: [''],
			root_part_tier_supp_ID: [''],
			collection_Id: [''],
			source: ['']
		});

		this.partForm = this.formBuilder.group({
			ford_full_part_no: [''],
			prefix: [''],
			base: [''],
			suffix: [''],
			vehicle_code: [''],
			ford_commodity_code: [''],
			ford_commodity_name: [''],
			high_risk_material: [''],
			usage_qty: [''],
			ford_part_ID: [''],
			part_tier_supp_ID: [''],
			update_1_insert_2: [''],
			Tier_Level: [''],
			collection_Id: [''],
			source: ['']
		});
		this.getCallJson = this.formBuilder.group({
			fordPartNumber: new FormArray([]),
			vehicleCode: new FormArray([]),
			supplierName: new FormArray([]),
			pageNo: this.pageNo,
			pageRows: this.pageRows,
			email: sessionStorage.getItem('emailAddress'),
			source: sessionStorage.getItem('Admin')
		});

		this.excelFile = './assets/image/SustainabilitySurvey.xlsx';

		this.getAllDataForSustainabilitySurvey();
		this.rightClickContextTable2 = [
			{
				label: 'View', icon: 'pi pi-eye', command: () => {
					this.addNewPartFlag = false;
					this.hideAndShowBaseOrChildInformation = false;
					this.showMainContent = false;
					this.viewDialog = true;
					this.addDialog = false;
					this.editDialog = false;
					this.t1TableView = false;
					this.t2TableView = false;
					this.t3TableView = false;
					this.partTableView = false;
				}
			},
			{
				label: 'Add', icon: 'pi pi-plus',
				items: [
					{
						label: 'Form',
						icon: 'pi pi-pencil',
						command: () => {
							this.addNewPartFlag = false;
							this.hideAndShowBaseOrChildInformation = false;
							this.addDialog = true;
							this.showMainContentExcelSheet = false;
							this.showMainContent = false;
							this.editDialog = false;
							this.viewDialog = false;
							this.t1TableView = false;
							this.t2TableView = false;
							this.t3TableView = false;
							this.partTableView = false;
							console.log(this.childInformation[0]);
						}
					},
					{
						label: 'Excel ',
						icon: 'pi pi-file-excel',
						command: () => {
							this.addNewPartFlag = false;
							if (this.excelFile) {
								this.flagNewTier = true;
								ExcelUtility.loadFromUrl(this.excelFile).then((w) => {
									this.spreadsheet.workbook = w;

									for (const item of this.childInformation) {
										let k = 0;
										for (const [key, value] of Object.entries(item)) {

											if (k < 23) {
												// @ts-ignore
												this.spreadsheet.workbook.worksheets(0).rows(2).cells(k).value = key.replaceAll('_', ' ');
											}

											if (k === 25) {
												this.fordPartIDColumnNo19 = value;
											}

											if (k === 27) {
												this.tierLevelColumnNo21 = value;
											}

											if (k === 28) {
												this.prevSupId22 = value;
											}

											if (k === 29) {
												this.rootColumnNo23 = value;
											}

											k++;
										}
										if (this.spreadsheet.workbook.worksheets(0).rows(2).cells(0).value === 'Ford Full Part No') {
											this.spreadsheet.workbook.worksheets(0).columns(9).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(10).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(11).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(12).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(13).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(14).hidden = true;
										} else {
											// this.spreadsheet.workbook.worksheets(0).columns(20).hidden = true;
											if(this.projectRflag && this.tierLevelColumnNo21 > 1){
												this.spreadsheet.workbook.worksheets(0).columns(17).hidden = true;
												this.spreadsheet.workbook.worksheets(0).columns(18).hidden = true;
											}
											this.spreadsheet.workbook.worksheets(0).columns(19).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(20).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(21).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(22).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(23).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(24).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(25).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(26).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(27).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(28).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(29).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(30).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(31).hidden = true;
										}
									}

								});
							}

							this.hideAndShowBaseOrChildInformation = false;
							this.addDialog = false;
							this.showMainContentExcelSheet = true;
							this.showMainContent = false;
							this.editDialog = false;
							this.viewDialog = false;
							this.t1TableView = false;
							this.t2TableView = false;
							this.t3TableView = false;
							this.partTableView = false;

						}
					},

				]
			},
			{
				label: 'Edit', icon: 'pi pi-pencil',
				items: [
					{
						label: 'Form',
						icon: 'pi pi-pencil',
						command: () => {
							this.addNewPartFlag = false;
							this.hideAndShowBaseOrChildInformation = false;
							this.showMainContentExcelSheet = false;
							this.showMainContent = false;
							this.editDialog = true;
							this.viewDialog = false;
							this.t1TableView = false;
							this.t2TableView = false;
							this.t3TableView = false;
							this.partTableView = false;
							this.addDialog = false;
						}
					},
					{
						label: 'Excel',
						icon: 'pi pi-file-excel',
						command: () => {
							console.log(this.excelFile);

							if (this.excelFile) {
								this.showMainContentExcelSheet = true;
								ExcelUtility.loadFromUrl(this.excelFile).then((w) => {
									this.spreadsheet.workbook = w;
									// tslint:disable-next-line:prefer-for-of
									// for (let i = 0; i < this.childInformation.length; i++) {
									let k = 0;
									for (const [key, value] of Object.entries(this.selectedchildDataContext)) {
										console.log(key, value);
										// @ts-ignore
										this.spreadsheet.workbook.worksheets(0).rows(2).cells(k).value = key.replaceAll('_', ' ');
										this.spreadsheet.workbook.worksheets(0).rows(3).cells(k).value = value;
										k++;
									}
									if (this.spreadsheet.workbook.worksheets(0).rows(2).cells(0).value === 'Ford Full Part No') {
										this.spreadsheet.workbook.worksheets(0).rows(3).cells(13).value = this.collectionId;
										this.spreadsheet.workbook.worksheets(0).rows(3).cells(14).value = sessionStorage.getItem('Admin');
										this.spreadsheet.workbook.worksheets(0).columns(9).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(10).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(11).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(12).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(13).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(14).hidden = true;

									} else {
										this.spreadsheet.workbook.worksheets(0).rows(3).cells(30).value = this.collectionId;
										this.spreadsheet.workbook.worksheets(0).rows(3).cells(31).value = sessionStorage.getItem('Admin');
										// this.spreadsheet.workbook.worksheets(0).columns(20).hidden = true;
										if(this.projectRflag && this.selectedchildDataContext.Tier_Level >1){
											this.spreadsheet.workbook.worksheets(0).columns(17).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(18).hidden = true;
										}
										this.spreadsheet.workbook.worksheets(0).columns(19).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(20).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(21).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(22).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(23).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(24).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(25).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(26).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(27).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(28).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(29).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(30).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(31).hidden = true;
									}
									// }

								});
							}
							this.hideAndShowBaseOrChildInformation = false;
							this.showMainContentExcelSheet = true;
							this.showMainContent = false;
							this.editDialog = false;
							this.viewDialog = false;
							this.t1TableView = false;
							this.t2TableView = false;
							this.t3TableView = false;
							this.partTableView = false;
							this.addDialog = false;
							// tslint:disable-next-line:prefer-for-of

						}
					},

				],

			},
			{
				label: 'Delete', icon: 'pi pi-times', command: () => {
					this.addNewPartFlag = false;
					this.supplierForm.value['source'] = sessionStorage.getItem('Admin');
					this.partForm.value['source'] = sessionStorage.getItem('Admin');
					this.partForm.value['collection_Id'] = this.collectionId;
					this.supplierForm.value['collection_Id'] = this.collectionId;
					this.supplierForm.value.ford_part_ID = this.selectedchildDataContext['Ford_Part_ID'];
					// tslint:disable-next-line:max-line-length
					this.supplierForm.value.part_tier_supp_ID = this.selectedchildDataContext['Part_Tier_Supp_ROW_ID'];
					console.log(this.supplierForm.value);
					this.sustainabilitySurveyService.deleteData(this.supplierForm.value).subscribe((res: any) => {
							if (res.status === 'Success') {
								this.messageService.add({
									severity: 'success',
									summary: 'Success',
									detail: 'Deleted',
									life: 300000
								});
							} else {
								this.messageService.add({
									severity: 'error',
									summary: 'Error',
									detail: 'Try again',
									life: 300000
								});
							}
							this.getAllDataForSustainabilitySurvey();
							this.showMainContent = false;
							this.showMainContentExcelSheet = false;
						},
						error => {
							this.messageService.add({
								severity: 'error',
								summary: 'Error',
								detail: 'Try again',
								life: 300000
							});
						});

				},
			}
		];
		this.rightClickContextTable1 = [
			{
				label: 'View', icon: 'pi pi-eye', command: () => {
					this.addNewPartFlag = false;
					this.hideAndShowBaseOrChildInformation = true;
					this.showMainContent = false;
					this.viewDialog = true;
					this.addDialog = false;
					this.editDialog = false;
					this.t1TableView = false;
					this.t2TableView = false;
					this.t3TableView = false;
					this.partTableView = false;
				}
			},
			{
				label: 'Add', icon: 'pi pi-plus',
				items: [
					{
						label: 'Form',
						icon: 'pi pi-pencil',
						command: () => {
							this.addNewPartFlag = false;
							this.baseInformationFlag = true;
							this.hideAndShowBaseOrChildInformation = true;
							this.showMainContent = false;
							this.addDialog = true;
							this.editDialog = false;
							this.viewDialog = false;
							this.t1TableView = false;
							this.t2TableView = false;
							this.t3TableView = false;
							this.partTableView = false;
						},
					},
					{
						label: 'Excel ',
						icon: 'pi pi-file-excel',
						command: () => {
							this.addNewPartFlag = false;
							if (this.baseInformation[0]['Ford_Full_Part_No']) {
								this.excelFile = './assets/image/SustainabilitySurveyPart.xlsx';
								this.limit = 8;
							} else {
								this.excelFile = './assets/image/SustainabilitySurvey.xlsx';
								this.limit = 22;
							}
							if (this.excelFile) {
								ExcelUtility.loadFromUrl(this.excelFile).then((w) => {
									this.spreadsheet.workbook = w;
									// tslint:disable-next-line:prefer-for-of
									// for (let i = 0; i < this.baseInformation.length; i++) {
									let k = 0;
									for (const [key, value] of Object.entries(this.selectedBaseDataContext)) {
										console.log(key, value);
										if (this.limit >= k) {
											// @ts-ignore
											this.spreadsheet.workbook.worksheets(0).rows(2).cells(k).value = key.replaceAll('_', ' ');
										}

										if (this.spreadsheet.workbook.worksheets(0).rows(2).cells(0).value === 'Ford Full Part No') {
											this.flagNewPart = true;
										} else {
											this.flagNewTier = true;
											if (k === 25) {
												this.fordPartIDColumnNo19 = value;
												// this.spreadsheet.workbook.worksheets(0).rows(3).cells(k).value = value;
											}
											if (k === 27) {
												this.tierLevelColumnNo21 = value;
												// this.spreadsheet.workbook.worksheets(0).rows(3).cells(k).value = 2;
											}

											if (k === 28) {
												this.prevSupId22 = value;
											}
											if (k === 29) {
												this.rootColumnNo23 = value;
											}

										}

										// this.spreadsheet.workbook.worksheets(0).rows(3).cells(k).value = value;
										k++;
									}
									if (this.spreadsheet.workbook.worksheets(0).rows(2).cells(0).value === 'Ford Full Part No') {
										this.spreadsheet.workbook.worksheets(0).columns(9).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(10).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(11).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(12).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(13).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(14).hidden = true;
									} else {
										// this.spreadsheet.workbook.worksheets(0).columns(20).hidden = true;
										if(this.projectRflag && this.tierLevelColumnNo21 >1){
											this.spreadsheet.workbook.worksheets(0).columns(17).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(18).hidden = true;
										}
										this.spreadsheet.workbook.worksheets(0).columns(19).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(20).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(21).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(22).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(23).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(24).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(25).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(26).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(27).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(28).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(29).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(30).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(31).hidden = true;
									}
									// }

								});
							}

							this.hideAndShowBaseOrChildInformation = false;
							this.showMainContent = false;
							this.showMainContentExcelSheet = true;
							this.addDialog = false;
							this.editDialog = false;
							this.viewDialog = false;
							this.t1TableView = false;
							this.t2TableView = false;
							this.t3TableView = false;
							this.partTableView = false;
							this.excelFile = './assets/image/SustainabilitySurvey.xlsx';

						}
					},

				]
			},
			{
				label: 'Edit', icon: 'pi pi-pencil',
				items: [
					{
						label: 'Form',
						icon: 'pi pi-pencil',
						command: () => {
							this.addNewPartFlag = false;
							this.baseInformationFlag = true;
							console.log(this.baseInformation[0]);
							this.hideAndShowBaseOrChildInformation = true;
							this.showMainContentExcelSheet = false;
							this.showMainContent = false;
							this.editDialog = true;
							this.viewDialog = false;
							this.t1TableView = false;
							this.t2TableView = false;
							this.t3TableView = false;
							this.partTableView = false;
							this.addDialog = false;
						}
					},
					{
						label: 'Excel',
						icon: 'pi pi-file-excel',
						command: () => {
							this.addNewPartFlag = false;
							console.log(this.excelFile);
							if (this.selectedBaseDataContext['Ford_Full_Part_No']) {
								this.excelFile = './assets/image/SustainabilitySurveyPart.xlsx';
							} else {
								this.excelFile = './assets/image/SustainabilitySurvey.xlsx';
							}
							if (this.excelFile) {
								ExcelUtility.loadFromUrl(this.excelFile).then((w) => {
									this.spreadsheet.workbook = w;
									// tslint:disable-next-line:prefer-for-of
									// for (let i = 0; i < this.selectedBaseDataContext.length; i++) {
									let k = 0;
									for (const [key, value] of Object.entries(this.selectedBaseDataContext)) {
// @ts-ignore
										this.spreadsheet.workbook.worksheets(0).rows(2).cells(k).value = key.replaceAll('_', ' ');
										this.spreadsheet.workbook.worksheets(0).rows(3).cells(k).value = value;
										k++;
									}
									if (this.spreadsheet.workbook.worksheets(0).rows(2).cells(0).value === 'Ford Full Part No') {
										this.spreadsheet.workbook.worksheets(0).rows(3).cells(13).value = this.collectionId;
										this.spreadsheet.workbook.worksheets(0).rows(3).cells(14).value = sessionStorage.getItem('Admin');
										this.spreadsheet.workbook.worksheets(0).columns(9).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(10).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(11).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(12).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(13).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(14).hidden = true;
									} else {
										this.spreadsheet.workbook.worksheets(0).rows(3).cells(30).value = this.collectionId;
										this.spreadsheet.workbook.worksheets(0).rows(3).cells(31).value = sessionStorage.getItem('Admin');

										// this.spreadsheet.workbook.worksheets(0).columns(20).hidden = true;
										if(this.projectRflag && this.selectedBaseDataContext.Tier_Level >1){
											this.spreadsheet.workbook.worksheets(0).columns(17).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(18).hidden = true;
										}
										this.spreadsheet.workbook.worksheets(0).columns(19).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(20).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(21).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(22).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(23).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(24).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(25).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(26).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(27).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(28).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(29).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(30).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(31).hidden = true;
									}

									// }

								});
							}
							this.hideAndShowBaseOrChildInformation = true;
							this.showMainContentExcelSheet = true;
							this.showMainContent = false;
							this.editDialog = false;
							this.viewDialog = false;
							this.t1TableView = false;
							this.t2TableView = false;
							this.t3TableView = false;
							this.partTableView = false;
							this.addDialog = false;
							this.excelFile = './assets/image/SustainabilitySurvey.xlsx';

						}
					},

				],

			},
			{
				label: 'Delete', icon: 'pi pi-times', command: () => {
					this.addNewPartFlag = false;
					this.supplierForm.value['source'] = sessionStorage.getItem('Admin');
					this.partForm.value['source'] = sessionStorage.getItem('Admin');
					this.partForm.value['collection_Id'] = this.collectionId;
					this.supplierForm.value['collection_Id'] = this.collectionId;

					console.log(JSON.stringify(this.selectedBaseDataContext));
					if (this.selectedBaseDataContext.Ford_Full_Part_No) {
						this.supplierForm.value.ford_part_ID = this.selectedBaseDataContext['Ford_Part_ID'];
						this.supplierForm.value.part_tier_supp_ID = this.selectedBaseDataContext['Part_Tier_Supp_ID'];
					} else {
						this.supplierForm.value.ford_part_ID = this.selectedBaseDataContext['Ford_Part_ID'];
						// tslint:disable-next-line:max-line-length
						this.supplierForm.value.part_tier_supp_ID = this.selectedBaseDataContext['Part_Tier_Supp_ROW_ID'];
					}
					console.log(this.supplierForm.value);
					this.sustainabilitySurveyService.deleteData(this.supplierForm.value).subscribe((res: any) => {

							this.messageService.add({
								severity: 'success',
								summary: 'Success',
								detail: 'Deleted',
								life: 300000
							});
							this.getAllDataForSustainabilitySurvey();
							this.showMainContent = false;
							this.showMainContentExcelSheet = false;
						},
						error => {
							this.messageService.add({
								severity: 'error',
								summary: 'Error',
								detail: 'Try again',
								life: 300000
							});
						});
				}
			},
			// {label: 'Duplicate', icon: 'pi pi-copy'},
		];
		this.rightClickContextTable3 = [
			{
				label: 'View', icon: 'pi pi-eye', command: () => {
					this.addNewPartFlag = false;
					this.hideAndShowBaseOrChildInformation = true;
					this.showMainContent = false;
					this.viewDialog = true;
					this.addDialog = false;
					this.editDialog = false;
					this.t1TableView = false;
					this.t2TableView = false;
					this.t3TableView = false;
					this.partTableView = false;
				}
			},
			{
				label: 'Add', icon: 'pi pi-plus',
				items: [
					{
						label: 'Form',
						icon: 'pi pi-pencil',
						command: () => {
							this.addNewPartFlag = false;
							this.baseInformationFlag = true;
							this.hideAndShowBaseOrChildInformation = true;
							this.showMainContent = false;
							this.addDialog = true;
							this.editDialog = false;
							this.viewDialog = false;
							this.t1TableView = false;
							this.t2TableView = false;
							this.t3TableView = false;
							this.partTableView = false;
						},
					},
					{
						label: 'Excel ',
						icon: 'pi pi-file-excel',
						command: () => {
							this.addNewPartFlag = false;
							if (this.baseInformation[0]['Ford_Full_Part_No']) {
								this.excelFile = './assets/image/SustainabilitySurveyPart.xlsx';
								this.limit = 8;
							} else {
								this.excelFile = './assets/image/SustainabilitySurvey.xlsx';
								this.limit = 22;
							}
							if (this.excelFile) {
								ExcelUtility.loadFromUrl(this.excelFile).then((w) => {
									this.spreadsheet.workbook = w;
									// tslint:disable-next-line:prefer-for-of
									// for (let i = 0; i < this.baseInformation.length; i++) {
									let k = 0;
									for (const [key, value] of Object.entries(this.selectedBaseDataContext)) {
										console.log(key, value);
										if (this.limit >= k) {
											// @ts-ignore
											this.spreadsheet.workbook.worksheets(0).rows(2).cells(k).value = key.replaceAll('_', ' ');
										}

										if (this.spreadsheet.workbook.worksheets(0).rows(2).cells(0).value === 'Ford Full Part No') {
											this.flagNewPart = true;
										} else {
											this.flagNewTier = true;
											if (k === 25) {
												this.fordPartIDColumnNo19 = value;
												// this.spreadsheet.workbook.worksheets(0).rows(3).cells(k).value = value;
											}
											if (k === 27) {
												this.tierLevelColumnNo21 = value;
												// this.spreadsheet.workbook.worksheets(0).rows(3).cells(k).value = 2;
											}

											if (k === 28) {
												this.prevSupId22 = value;
											}
											if (k === 29) {
												this.rootColumnNo23 = value;
											}

										}

										// this.spreadsheet.workbook.worksheets(0).rows(3).cells(k).value = value;
										k++;
									}
									if (this.spreadsheet.workbook.worksheets(0).rows(2).cells(0).value === 'Ford Full Part No') {
										this.spreadsheet.workbook.worksheets(0).columns(9).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(10).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(11).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(12).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(13).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(14).hidden = true;
									} else {
										// this.spreadsheet.workbook.worksheets(0).columns(20).hidden = true;
										if(this.projectRflag && this.tierLevelColumnNo21 >1){
											this.spreadsheet.workbook.worksheets(0).columns(17).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(18).hidden = true;
										}
										this.spreadsheet.workbook.worksheets(0).columns(19).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(20).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(21).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(22).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(23).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(24).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(25).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(26).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(27).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(28).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(29).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(30).hidden = true;
										this.spreadsheet.workbook.worksheets(0).columns(31).hidden = true;
									}
									// }

								});
							}

							this.hideAndShowBaseOrChildInformation = false;
							this.showMainContent = false;
							this.showMainContentExcelSheet = true;
							this.addDialog = false;
							this.editDialog = false;
							this.viewDialog = false;
							this.t1TableView = false;
							this.t2TableView = false;
							this.t3TableView = false;
							this.partTableView = false;
							this.excelFile = './assets/image/SustainabilitySurvey.xlsx';

						}
					},

				]
			},
			// {label: 'Duplicate', icon: 'pi pi-copy'},
		];
		this.rightClickContextTable4 = [
			{
				label: 'View', icon: 'pi pi-eye', command: () => {
					this.addNewPartFlag = false;
					this.hideAndShowBaseOrChildInformation = false;
					this.showMainContent = false;
					this.viewDialog = true;
					this.addDialog = false;
					this.editDialog = false;
					this.t1TableView = false;
					this.t2TableView = false;
					this.t3TableView = false;
					this.partTableView = false;
				}
			},
			{
				label: 'Add', icon: 'pi pi-plus',
				items: [
					{
						label: 'Form',
						icon: 'pi pi-pencil',
						command: () => {
							this.addNewPartFlag = false;
							this.hideAndShowBaseOrChildInformation = false;
							this.addDialog = true;
							this.showMainContentExcelSheet = false;
							this.showMainContent = false;
							this.editDialog = false;
							this.viewDialog = false;
							this.t1TableView = false;
							this.t2TableView = false;
							this.t3TableView = false;
							this.partTableView = false;
							console.log(this.childInformation[0]);
						}
					},
					{
						label: 'Excel ',
						icon: 'pi pi-file-excel',
						command: () => {
							this.addNewPartFlag = false;
							if (this.excelFile) {
								this.flagNewTier = true;
								ExcelUtility.loadFromUrl(this.excelFile).then((w) => {
									this.spreadsheet.workbook = w;

									for (const item of this.childInformation) {
										let k = 0;
										for (const [key, value] of Object.entries(item)) {

											if (k < 23) {
												// @ts-ignore
												this.spreadsheet.workbook.worksheets(0).rows(2).cells(k).value = key.replaceAll('_', ' ');
											}

											if (k === 25) {
												this.fordPartIDColumnNo19 = value;
											}

											if (k === 27) {
												this.tierLevelColumnNo21 = value;
											}

											if (k === 28) {
												this.prevSupId22 = value;
											}

											if (k === 29) {
												this.rootColumnNo23 = value;
											}

											k++;
										}
										if (this.spreadsheet.workbook.worksheets(0).rows(2).cells(0).value === 'Ford Full Part No') {
											this.spreadsheet.workbook.worksheets(0).columns(9).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(10).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(11).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(12).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(13).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(14).hidden = true;
										} else {
											// this.spreadsheet.workbook.worksheets(0).columns(20).hidden = true;
											if(this.projectRflag && this.tierLevelColumnNo21 >1){
												this.spreadsheet.workbook.worksheets(0).columns(17).hidden = true;
												this.spreadsheet.workbook.worksheets(0).columns(18).hidden = true;
											}
											this.spreadsheet.workbook.worksheets(0).columns(19).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(20).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(21).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(22).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(23).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(24).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(25).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(26).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(27).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(28).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(29).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(30).hidden = true;
											this.spreadsheet.workbook.worksheets(0).columns(31).hidden = true;
										}
									}

								});
							}

							this.hideAndShowBaseOrChildInformation = false;
							this.addDialog = false;
							this.showMainContentExcelSheet = true;
							this.showMainContent = false;
							this.editDialog = false;
							this.viewDialog = false;
							this.t1TableView = false;
							this.t2TableView = false;
							this.t3TableView = false;
							this.partTableView = false;

						}
					},

				]
			},
			{
				label: '',
				visible: false,
			},
		];

		this.rightClickContextForTree = [
			{
				label: 'No Flag', icon: 'pi pi-flag', command: () => {
					this.toggleFlag(5, this.partTierSuppROWID);
				}
			},
			{
				label: 'Yellow Flag', icon: 'pi pi-flag', command: () => {
					this.toggleFlag(3, this.partTierSuppROWID);
				}
			},
			{
				label: 'Red Flag', icon: 'pi pi-flag', command: () => {
					this.toggleFlag(2, this.partTierSuppROWID);
				}
			},
		];
		this.partTableView = true;
		// tslint:disable-next-line:max-line-length
		this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Sustainability Survey Super Admin').subscribe((superAdminAccess) => {
			this.projectRAccess = superAdminAccess;
			if (!superAdminAccess) {
				this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Project R Access').subscribe((projectRAccess) => {
					this.projectRAccess = projectRAccess;
					console.log(this.projectRAccess);
				});
			}
			console.log(this.projectRAccess);
		});
		this.sustainabilitySurveyService.getSSEvidenceDropdownValues().subscribe((response) => {
			console.log(response);
			let i = 0;
			// tslint:disable-next-line:forin
			for (const key in response) {
				const temp = {};
				temp['documentName'] = response[key];
				this.documentNames.push(temp);
				i = i + 1;
				console.log(key, this.documentNames);
			}
			console.log(this.documentNames);
		});
	}

	getScreenAccess() {
		this.isEvidenceCollectionRequired = false;
		this.uploadEvidences = false;
		this.evidenceAccessFlags['DownloadFlag'] = 'N';
		this.evidenceAccessFlags['DeleteFlag'] = 'N';
		this.sustainabilitySurveyService.uploadEvidenceRequiredFlags(this.gsdb, this.tier1Supplier, this.businessName).subscribe((response1) => {
			console.log(response1);
			if (response1.isEvidenceRequired === 'Y') {
				this.isEvidenceCollectionRequired = true;
				this.uploadEvidences = true;
				this.evidenceAccessFlags['ViewFlag'] = 'Y';
			} else {
				this.isEvidenceCollectionRequired = false;
				this.uploadEvidences = false;
				this.evidenceAccessFlags['ViewFlag'] = 'N';
			}
			console.log(this.isEvidenceCollectionRequired, this.supplierLogin);
			if (this.supplierLogin) {
				if (this.isEvidenceCollectionRequired) {
					this.evidenceAccessFlags['ViewFlag'] = 'Y';
					this.evidenceAccessFlags['DownloadFlag'] = 'Y';
					this.evidenceAccessFlags['DeleteFlag'] = 'Y';
				} else {
					this.evidenceAccessFlags['ViewFlag'] = 'N';
					this.evidenceAccessFlags['DownloadFlag'] = 'N';
					this.evidenceAccessFlags['DeleteFlag'] = 'N';
				}
			} else {
				this.sustainabilitySurveyService.getScreenAccessFlags(sessionStorage.getItem('emailAddress')).subscribe((response2) => {
					this.evidenceAccessFlags = response2;
					console.log(this.evidenceAccessFlags);

					if (this.evidenceAccessFlags['DownloadFlag'] === 'Y') {
						this.evidenceAccessFlags['ViewFlag'] = 'Y';
						this.uploadEvidences = true;
					}
					if (this.evidenceAccessFlags['DeleteFlag'] === 'Y') {
						this.evidenceAccessFlags['ViewFlag'] = 'Y';
						this.evidenceAccessFlags['DownloadFlag'] = 'Y';
						this.uploadEvidences = true;
					}
					if (this.evidenceAccessFlags['ViewFlag'] === 'Y') {
						this.uploadEvidences = true;
					}
					console.log(this.evidenceAccessFlags, this.uploadEvidences, this.isEvidenceCollectionRequired);
				});
			}
		});
		this.isQuestionnaireRequired = false;
		this.answerQuestionnaire = false;
		this.sustainabilitySurveyService.resilienceQuestionsRequiredFlags(this.gsdb, this.tier1Supplier, this.businessName).subscribe((response) => {
			console.log(response);
			if (response.isResilienceSurveyRequired === 'Y') {
				this.isQuestionnaireRequired = true;
				this.answerQuestionnaire = true;
				if (this.supplierLogin) {
					this.projectRAccess = true;
				}
			} else {
				this.isQuestionnaireRequired = false;
				this.answerQuestionnaire = false;
			}
		});
	}

	nodeSelect(evt: any): void {
		this.addNewPartFlag = false;
		this.showMainContentExcelSheet = false;
		console.log(evt.node);
		console.log(evt.node.data);
		if (evt.node.Collection_Id) {
			this.collectionId = evt.node.Collection_Id;
		} else if (evt.node.parent.Collection_Id) {
			this.collectionId = evt.node.parent.Collection_Id;
		}
		this.showMainContent = true;
		this.addDialog = false;
		this.editDialog = false;
		this.viewDialog = false;
		this.t1TableView = true;
		this.baseInformation = evt.node.baseData;
		this.enableShowAttachements = false;
		this.enableShowQuestionnaire = false;
		this.newTierButtonLabel = 'Add New Tier ' + (evt.node.baseData[0].Tier_Level + 1);
		this.childInformation = evt.node.data;
		this.fordPartNumber = '';
		if (this.baseInformation[0].Ford_Full_Part_No) {
			// tslint:disable-next-line:prefer-for-of
			for (let i = 0; i < this.baseInformation.length; i++) {
				this.fordPartNumber = this.fordPartNumber + (this.baseInformation[i].Ford_Full_Part_No);
				this.fordPartNumber = this.fordPartNumber + ('|');
			}
			this.gsdb = this.childInformation[0].MFG_GSDB_Code;
			this.tier1Supplier = this.childInformation[0].Supplier_Name;
		} else {
			if (evt.node.baseData[0].Tier_Level === 2) {
				// tslint:disable-next-line:prefer-for-of
				for (let i = 0; i < evt.node.parent.parent.baseData.length; i++) {
					this.fordPartNumber = this.fordPartNumber + (evt.node.parent.parent.baseData[i].Ford_Full_Part_No);
					this.fordPartNumber = this.fordPartNumber + ('|');
				}
				this.gsdb = evt.node.parent.baseData[0].MFG_GSDB_Code;
				this.tier1Supplier = evt.node.parent.baseData[0].Supplier_Name;
			} else {
				if (evt.node.baseData[0].Tier_Level === 1) {
					// tslint:disable-next-line:prefer-for-of
					for (let i = 0; i < evt.node.parent.baseData.length; i++) {
						this.fordPartNumber = this.fordPartNumber + (evt.node.parent.baseData[i].Ford_Full_Part_No);
						this.fordPartNumber = this.fordPartNumber + ('|');
					}
					this.gsdb = this.baseInformation[0].MFG_GSDB_Code;
					this.tier1Supplier = this.baseInformation[0].Supplier_Name;
				} else {
					if (evt.node.baseData[0].Tier_Level === 3) {
						// tslint:disable-next-line:prefer-for-of
						for (let i = 0; i < evt.node.parent.parent.parent.baseData.length; i++) {
							this.fordPartNumber = this.fordPartNumber + (evt.node.parent.parent.parent.baseData[i].Ford_Full_Part_No);
							this.fordPartNumber = this.fordPartNumber + ('|');
						}
						this.gsdb = evt.node.parent.parent.baseData[0].MFG_GSDB_Code;
						this.tier1Supplier = evt.node.parent.parent.baseData[0].Supplier_Name;
					}
				}
			}
		}
		this.baseInformation.forEach((group, index) => {
			this.checkIfQuestionnaireAnswered(group.Part_Tier_Supp_ROW_ID, this.fordPartNumber);
			console.log(group.Part_Description, group.Part_Tier_Supp_ROW_ID, this.fordPartNumber, this.checkQuestionnaireForm);
		});
		this.childInformation.forEach((group, index) => {
			this.checkIfQuestionnaireAnswered(group.Part_Tier_Supp_ROW_ID, this.fordPartNumber);
			console.log(group.Part_Description, group.Part_Tier_Supp_ROW_ID, this.fordPartNumber, this.checkQuestionnaireForm);
		});
		this.getScreenAccess();
		console.log('gsdb code', this.gsdb);
		console.log('tier1 Supplier', this.tier1Supplier);
		console.log('this.fordPartNumber', this.fordPartNumber);
		const firstFourLetters = this.fordPartNumber.substring(0, 4);
		if (firstFourLetters === 'XXXX') {
			this.disableLink = true;
		} else {
			this.disableLink = false;
		}
	}

	nodeExpand(evt: any) {
		this.baseInformation = evt.node.baseData;
		this.childInformation = evt.node.data;
		console.log(evt.node);
		console.log(evt.node.data);
		this.newTierButtonLabel = 'Add New Tier ' + (evt.node.baseData[0].Tier_Level + 1);
		if (this.baseInformation[0].Ford_Full_Part_No) {
			this.fordPartNumber = this.baseInformation[0].Ford_Full_Part_No;
			this.gsdb = this.childInformation[0].MFG_GSDB_Code;
			this.tier1Supplier = this.childInformation[0].Supplier_Name;
		} else {
			if (evt.node.baseData[0].Tier_Level === 2) {
				this.fordPartNumber = evt.node.parent.parent.baseData[0].Ford_Full_Part_No;
				this.gsdb = evt.node.parent.baseData[0].MFG_GSDB_Code;
				this.tier1Supplier = evt.node.parent.baseData[0].Supplier_Name;
			} else {
				if (evt.node.baseData[0].Tier_Level === 1) {
					this.fordPartNumber = evt.node.parent.baseData[0].Ford_Full_Part_No;
					this.gsdb = this.baseInformation[0].MFG_GSDB_Code;
					this.tier1Supplier = this.baseInformation[0].Supplier_Name;
				} else {
					if (evt.node.baseData[0].Tier_Level === 3) {
						this.fordPartNumber = evt.node.parent.parent.parent.baseData[0].Ford_Full_Part_No;
						this.gsdb = evt.node.parent.parent.baseData[0].MFG_GSDB_Code;
						this.tier1Supplier = evt.node.parent.parent.baseData[0].Supplier_Name;
					}
				}
			}
		}
		this.baseInformation.forEach((group, index) => {
			this.checkIfQuestionnaireAnswered(group.Part_Tier_Supp_ROW_ID, this.fordPartNumber);
			console.log(group.Part_Description, group.Part_Tier_Supp_ROW_ID, this.fordPartNumber, this.checkQuestionnaireForm);
		});
		this.childInformation.forEach((group, index) => {
			this.checkIfQuestionnaireAnswered(group.Part_Tier_Supp_ROW_ID, this.fordPartNumber);
			console.log(group.Part_Description, group.Part_Tier_Supp_ROW_ID, this.fordPartNumber, this.checkQuestionnaireForm);
		});
		console.log('this.fordPartNumber', this.fordPartNumber);
		this.getScreenAccess();
		console.log('gsdb code', this.gsdb);
		console.log('tier1 Supplier', this.tier1Supplier);
		const firstFourLetters = this.fordPartNumber.substring(0, 4);
		if (firstFourLetters === 'XXXX') {
			this.disableLink = true;
		} else {
			this.disableLink = false;
		}
	}

	getAllDataForSustainabilitySurvey() {
		this.loadMemoryData = true;
		this.getCallJson.value.pageNo = this.pageNo;
		this.getCallJson.value.pageRows = this.pageRows;
		this.getCallJson.value.source = sessionStorage.getItem('Admin');
		// tslint:disable-next-line:max-line-length
		this.sustainabilitySurveyService.getAllDataForSustainabilitySurvey(this.getCallJson.value).subscribe((res: any) => {
			this.treeDirectoryData = JSON.parse(res.data);
			this.addNewPartFlag = true;
			console.log(res);
			console.log(res.ford_full_part_no);
			console.log(res.vehicle_code);
			console.log(res.t1_supplier_name);
			this.partNumber = (res.ford_full_part_no);
			this.vehicleCode = (res.vehicle_code);
			this.supplierName = (res.t1_supplier_name);
			this.pageTotalRows = res.count;
			this.loadMemoryData = false;
		});
	}

	excelSubmit() {
		this.flagRegexCheck = true;
		const opt = new WorkbookSaveOptions();
		const regex = /^[\x00-\x7F\x00]*$/;
		opt.type = 'blob';
		const workbook = this.spreadsheet.workbook;
		let l = '';

		for (let i = 2; (workbook.worksheets(0).rows(i).cells(0).value) !== null
		|| (workbook.worksheets(0).rows(i).cells(1).value) !== null
		|| (workbook.worksheets(0).rows(i).cells(2).value) !== null
		|| (workbook.worksheets(0).rows(i).cells(3).value) !== null
		|| (workbook.worksheets(0).rows(i).cells(4).value) !== null
		|| (workbook.worksheets(0).rows(i).cells(5).value) !== null
		|| (workbook.worksheets(0).rows(i).cells(6).value) !== null; i++) {
			for (let j = 0; j < 26; j++) {
				l += 'row ' + i + ' col ' + j + ' ' + workbook.worksheets(0).rows(i).cells(j).value + ' \n';
			}
			l += ' \n';
		}
		console.log(l);
		if (this.flagNewTier) {
			for (let i = 2; (workbook.worksheets(0).rows(i).cells(0).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(1).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(2).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(3).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(4).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(5).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(6).value) !== null; i++) {

				workbook.worksheets(0).rows(i).cells(25).value = this.fordPartIDColumnNo19;
				workbook.worksheets(0).rows(i).cells(26).value = 2;
				workbook.worksheets(0).rows(i).cells(27).value = this.tierLevelColumnNo21;
				workbook.worksheets(0).rows(i).cells(28).value = this.prevSupId22;
				workbook.worksheets(0).rows(i).cells(29).value = this.rootColumnNo23;
				workbook.worksheets(0).rows(i).cells(30).value = this.collectionId;
				workbook.worksheets(0).rows(i).cells(31).value = sessionStorage.getItem('Admin');
			}
			this.flagNewTier = false;
		}
		if (this.flagNewPart) {
			for (let i = 2; (workbook.worksheets(0).rows(i).cells(0).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(1).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(2).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(3).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(4).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(5).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(6).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(7).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(8).value) !== null; i++) {
				workbook.worksheets(0).rows(i).cells(11).value = 2;
				workbook.worksheets(0).rows(i).cells(12).value = 0;
				workbook.worksheets(0).rows(i).cells(13).value = this.collectionId;
				workbook.worksheets(0).rows(i).cells(14).value = sessionStorage.getItem('Admin');
			}
			this.flagNewPart = false;
		}

		workbook.save(opt, (d) => {
			this.flagRegexCheck = true;
			if (workbook.worksheets(0).rows(2).cells(0).value === 'Ford Full Part No') {
				for (let i = 2; (workbook.worksheets(0).rows(i).cells(0).value) !== null
				|| (workbook.worksheets(0).rows(i).cells(1).value) !== null
				|| (workbook.worksheets(0).rows(i).cells(2).value) !== null
				|| (workbook.worksheets(0).rows(i).cells(3).value) !== null
				|| (workbook.worksheets(0).rows(i).cells(4).value) !== null
				|| (workbook.worksheets(0).rows(i).cells(5).value) !== null
				|| (workbook.worksheets(0).rows(i).cells(6).value) !== null
				|| (workbook.worksheets(0).rows(i).cells(7).value) !== null
				|| (workbook.worksheets(0).rows(i).cells(8).value) !== null; i++) {
					if (regex.test(workbook.worksheets(0).rows(i).cells(0).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(1).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(2).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(3).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(4).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(5).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(6).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(7).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(8).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(9).value)) {
					} else {
						this.flagRegexCheck = false;
					}
				}
				if (this.flagRegexCheck) {
					const gsdbb = this.gSDBCode ? this.gSDBCode.gsdb : null;
					this.sustainabilitySurveyService.uploadSustainabilityPartFile(d as File, gsdbb, this.businessName).subscribe((data) => {
							this.gSDBCodeDropDown = null;
							this.gSDBCode = null;
							this.fileFromDatabase = data;
							console.log(this.fileFromDatabase);
							ExcelUtility.load(this.fileFromDatabase).then((w) => {
								this.spreadsheet.workbook = w;
								this.spreadsheet.activeWorksheet.protect();
								console.log('Excel Loaded');
							});
							if (data.status === 'failure') {
								this.severity = 'error';
								this.summary = 'Error';
							} else if (data.status === 'Success') {
								this.getAllDataForSustainabilitySurvey();
								this.showMainContentExcelSheet = false;
								this.showMainContent = false;
								this.severity = 'success';
								this.summary = 'Success';
								data.Message = 'Saved';
							} else {
								this.severity = 'success';
								this.summary = 'Success';
								data.Message = 'Saved';
							}
							this.messageService.add({
								severity: this.severity,
								summary: this.summary,
								detail: data ? (data.Message.replaceAll('_', ' ')).charAt(0).toUpperCase() + data.Message.replaceAll('_', ' ').slice(1) : null,
								life: 300000
							});
						},
						error => {
							this.gSDBCodeDropDown = null;
							this.gSDBCode = null;
							this.messageService.add({
								severity: 'error',
								summary: 'Error',
								detail: 'Try again',
								life: 300000
							});
						}
					);
				} else {
					this.messageService.add({
						severity: 'error',
						summary: 'Error',
						detail: 'Please Check All Fields',
						life: 300000
					});

				}
			} else {
				for (let i = 2; (workbook.worksheets(0).rows(i).cells(0).value) !== null
				|| (workbook.worksheets(0).rows(i).cells(1).value) !== null
				|| (workbook.worksheets(0).rows(i).cells(2).value) !== null
				|| (workbook.worksheets(0).rows(i).cells(3).value) !== null
				|| (workbook.worksheets(0).rows(i).cells(4).value) !== null
				|| (workbook.worksheets(0).rows(i).cells(5).value) !== null
				|| (workbook.worksheets(0).rows(i).cells(6).value) !== null; i++) {
					if (regex.test(workbook.worksheets(0).rows(i).cells(0).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(1).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(2).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(3).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(4).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(5).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(6).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(7).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(8).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(9).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(10).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(11).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(12).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(13).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(14).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(15).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(16).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(17).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(18).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(19).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(20).value) &&
						regex.test(workbook.worksheets(0).rows(i).cells(21).value)) {
					} else {
						this.flagRegexCheck = false;
					}
				}

				if (this.flagRegexCheck) {
					this.sustainabilitySurveyService.uploadSustainabilitySuplFile(d as File).subscribe((data) => {
							this.fileFromDatabase = data;
							console.log(this.fileFromDatabase);
							ExcelUtility.load(this.fileFromDatabase).then((w) => {
								this.spreadsheet.workbook = w;
								this.spreadsheet.activeWorksheet.protect();
								console.log('Excel Loaded');
							});
							if (data.status === 'failure') {
								this.severity = 'error';
								this.summary = 'Error';
							} else if (data.status === 'Success') {
								this.getAllDataForSustainabilitySurvey();
								this.showMainContentExcelSheet = false;
								this.showMainContent = false;
								this.severity = 'success';
								this.summary = 'Success';
								data.Message = 'Saved';
							} else {
								this.severity = 'success';
								this.summary = 'Success';
								data.Message = 'Saved';
							}
							this.messageService.add({
								severity: this.severity,
								summary: this.summary,
								detail: data ? (data.Message.replaceAll('_', ' ')).charAt(0).toUpperCase() + data.Message.replaceAll('_', ' ').slice(1) : null,
								life: 300000
							});
						},
						error => {
							this.messageService.add({
								severity: 'error',
								summary: 'Error',
								detail: 'Try again',
								life: 300000
							});
						}
					);
				} else {
					this.messageService.add({
						severity: 'error',
						summary: 'Error',
						detail: 'Please Check All Fields',
						life: 300000
					});
				}
			}
		}, (e) => {
		});
	}

	addNewTierExcel() {
		// this.collectionId = null;
		this.excelFile = './assets/image/SustainabilitySurvey.xlsx';
		console.log(this.baseInformation);
		this.showMainContent = false;
		this.tierLevelColumnNo21 = this.baseInformation[0].Tier_Level;

		if (this.baseInformation[0].Root_Part_Tier_Supp_ID) {
			this.tierLevelColumnNo21 = this.baseInformation[0].Tier_Level + 1;
			this.rootColumnNo23 = this.baseInformation[0].Root_Part_Tier_Supp_ID;
			this.prevSupId22 = this.baseInformation[0]['Part_Tier_Supp_ROW_ID'];
			this.fordPartIDColumnNo19 = this.baseInformation[0]['Ford_Part_ID'];
		} else {
			this.rootColumnNo23 = this.baseInformation[0].Part_Tier_Supp_ID;
			this.tierLevelColumnNo21 = this.baseInformation[0].Tier_Level + 1;
			this.prevSupId22 = null;
			this.fordPartIDColumnNo19 = this.baseInformation[0].Ford_Part_ID;
		}
		console.log('root i think so' + this.rootColumnNo23);
		console.log('ford part id' + this.fordPartIDColumnNo19);
		console.log('tier level' + this.tierLevelColumnNo21);
		console.log('prev' + this.prevSupId22);

		this.showMainContentExcelSheet = true;
		this.flagNewTier = true;
		ExcelUtility.loadFromUrl(this.excelFile).then((w) => {
			this.spreadsheet.workbook = w;

			this.spreadsheet.workbook.worksheets(0).rows(2).cells(0).value = 'T' + this.tierLevelColumnNo21 + ' Part Description';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(1).value = 'T' + this.tierLevelColumnNo21 + ' Supplier Name';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(2).value = 'T' + this.tierLevelColumnNo21 + ' GSDB Code';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(3).value = 'T' + this.tierLevelColumnNo21 + ' Address';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(4).value = 'T' + this.tierLevelColumnNo21 + ' City';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(5).value = 'T' + this.tierLevelColumnNo21 + ' State';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(6).value = 'T' + this.tierLevelColumnNo21 + ' Country';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(7).value = 'T' + this.tierLevelColumnNo21 + ' Postal Code';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(8).value = 'T' + this.tierLevelColumnNo21 + ' MFG Duns Number';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(9).value = 'T' + this.tierLevelColumnNo21 + ' Supplier GSDB Code';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(10).value = 'T' + this.tierLevelColumnNo21 + ' Shipping Street Address';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(11).value = 'T' + this.tierLevelColumnNo21 + ' Shipping City Name';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(12).value = 'T' + this.tierLevelColumnNo21 + ' Shipping State';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(13).value = 'T' + this.tierLevelColumnNo21 + ' Shipping Country';

			this.spreadsheet.workbook.worksheets(0).rows(2).cells(14).value = 'T' + this.tierLevelColumnNo21 + ' Shipping  Postal Code';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(15).value = 'T' + this.tierLevelColumnNo21 + ' Ship Duns Number';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(16).value = 'T' + this.tierLevelColumnNo21 + ' Supplier Role';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(17).value = 'T' + this.tierLevelColumnNo21 + ' Email Address';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(18).value = 'T' + this.tierLevelColumnNo21 + ' Contact Number';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(19).value = 'T' + this.tierLevelColumnNo21 + ' Part Number';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(20).value = 'T' + this.tierLevelColumnNo21 + ' Logistics Lead Time To FORD';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(21).value = 'T' + this.tierLevelColumnNo21 + ' Mode of Shipment';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(22).value = 'T' + this.tierLevelColumnNo21 + ' Mfg Lead Time';
			this.spreadsheet.workbook.worksheets(0).rows(3).cells(0).value = ' ';
			if(this.projectRflag && this.tierLevelColumnNo21 > 1){
				this.spreadsheet.workbook.worksheets(0).columns(17).hidden = true;
				this.spreadsheet.workbook.worksheets(0).columns(18).hidden = true;
			}
			this.spreadsheet.workbook.worksheets(0).columns(19).hidden = true;
			this.spreadsheet.workbook.worksheets(0).columns(20).hidden = true;
			this.spreadsheet.workbook.worksheets(0).columns(21).hidden = true;
			this.spreadsheet.workbook.worksheets(0).columns(22).hidden = true;
			this.spreadsheet.workbook.worksheets(0).columns(23).hidden = true;
			this.spreadsheet.workbook.worksheets(0).columns(24).hidden = true;
			this.spreadsheet.workbook.worksheets(0).columns(25).hidden = true;
			this.spreadsheet.workbook.worksheets(0).columns(26).hidden = true;
			this.spreadsheet.workbook.worksheets(0).columns(27).hidden = true;
			this.spreadsheet.workbook.worksheets(0).columns(28).hidden = true;
			this.spreadsheet.workbook.worksheets(0).columns(29).hidden = true;
			this.spreadsheet.workbook.worksheets(0).columns(30).hidden = true;
			this.spreadsheet.workbook.worksheets(0).columns(31).hidden = true;
		});
	}

	addNewPartExcel() {
		this.collectionId = null;
		this.gSDBCode = null;
		this.addNewPartFlag = false;
		this.showMainContent = false;
		this.flagNewPart = true;
		this.noRecordsFound = false;
		this.excelFile = './assets/image/SustainabilitySurveyPart.xlsx';
		if (this.supplierLogin) {
			// tslint:disable-next-line:max-line-length
			this.sustainabilitySurveyService.getGSDBBasedOnEmailId(sessionStorage.getItem('emailAddress'), this.businessName).subscribe((res: any) => {
				this.gSDBCodeDropDown = res;
				if (this.supplierLogin) {
					this.successModal = true;
				}
			});
		}
		this.showMainContentExcelSheet = true;
		ExcelUtility.loadFromUrl(this.excelFile).then((w) => {
			this.spreadsheet.workbook = w;

			this.spreadsheet.workbook.worksheets(0).rows(2).cells(0).value = 'Ford Full Part No';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(1).value = 'Prefix';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(2).value = 'Base Part';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(3).value = 'Suffix';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(4).value = 'Vehicle Code';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(5).value = 'Ford Commodity Code';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(6).value = 'Ford Commodity Name';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(7).value = 'High Risk Material';
			this.spreadsheet.workbook.worksheets(0).rows(2).cells(8).value = 'Usage Qty';
		});
	}

	onPageChange(e: any) {
		this.pageNo = e.page + 1;
		this.pageRows = e.rows;
		this.showMainContent = false;
		this.enableShowAttachements = false;
		this.enableShowQuestionnaire = false;
		this.getAllDataForSustainabilitySurvey();
	}

	formSubmit($event) {
		if (this.objectKeys(this.baseInformation[0]).length < 15 && this.baseInformationFlag) {
			this.partForm.value['collection_Id'] = this.collectionId;
			this.partForm.value['source'] = sessionStorage.getItem('Admin');
			for (let i = 0; i < this.modelArrayPart.length; i++) {
				this.partForm.value[this.modelArrayPart[i]] = $event.target[this.modelArrayPart[i]].value;
				if (this.addDialog && i === 12) {
					this.partForm.value[this.modelArrayPart[i]] = 0;
				} else if (this.addDialog && i === 11) {
					this.partForm.value[this.modelArrayPart[i]] = 2;
					//1-Update 2-Insert
				} else if (this.addDialog && i === 9) {
					this.partForm.value[this.modelArrayPart[i]] = '';
				}
			}
			console.log(this.partForm.value);
			console.log('hello');
			console.log('hello');
			const regex = /^[\x00-\x7F\x00]*$/;

			if (regex.test(this.partForm.value['ford_full_part_no']) &&
				regex.test(this.partForm.value['prefix']) &&
				regex.test(this.partForm.value['base']) &&
				regex.test(this.partForm.value['suffix']) &&
				regex.test(this.partForm.value['vehicle_code']) &&
				regex.test(this.partForm.value['ford_commodity_code']) &&
				regex.test(this.partForm.value['ford_commodity_name']) &&
				regex.test(this.partForm.value['high_risk_material'])) {
				this.sustainabilitySurveyService.editPart(this.partForm.value).subscribe((res: any) => {
						if (res.status === 'failure') {
							this.severity = 'error';
							this.summary = 'Error';
						} else if (res.status === 'Success') {
							this.editDialog = false;
							this.addDialog = false;
							this.baseInformationFlag = false;
							this.showMainContentExcelSheet = false;
							this.showMainContent = false;
							this.severity = 'success';
							this.summary = 'Success';
							res.Message = 'Saved';
						} else {
							this.editDialog = false;
							this.addDialog = false;
							this.baseInformationFlag = false;
							this.showMainContentExcelSheet = false;
							this.showMainContent = false;
							this.severity = 'success';
							this.summary = 'Success';
							res.Message = 'Saved';
						}
						this.messageService.add({
							severity: this.severity,
							summary: this.summary,
							detail: res ? (res.Message.replaceAll('_', ' ')).charAt(0).toUpperCase() + res.Message.replaceAll('_', ' ').slice(1) : null,
							life: 300000
						});
						this.getAllDataForSustainabilitySurvey();

					},
					error => {
						this.messageService.add({
							severity: 'error',
							summary: 'Error',
							detail: 'Try again',
							life: 300000
						});
						this.editDialog = true;
						this.showMainContent = false;
					});
			} else {
				if (this.addDialog === true) {
					this.showMainContent = false;
					this.messageService.add({
						severity: 'error',
						summary: 'Error',
						detail: 'Please Check All Fields',
						life: 300000
					});
				}
				if (this.editDialog === true) {
					this.showMainContent = false;
					this.messageService.add({
						severity: 'error',
						summary: 'Error',
						detail: 'Please Check All Fields',
						life: 300000
					});
				}
			}
		} else {
			this.supplierForm.value['collection_Id'] = this.collectionId;
			this.supplierForm.value['source'] = sessionStorage.getItem('Admin');
			for (let i = 0; i < this.modelArraySupplier.length; i++) {
				{
					console.log($event.target[this.modelArraySupplier[i]]);
					console.log('Model Array ' + i + ' ' + $event.target[this.modelArraySupplier[i]].value);
					console.log($event.target[1].name);

					this.supplierForm.value[this.modelArraySupplier[i]] = $event.target[this.modelArraySupplier[i]].value;
					if (this.addDialog && i === 23) {
						this.supplierForm.value[this.modelArraySupplier[i]] = '';
					} else if (this.addDialog && i === 24) {
						this.supplierForm.value[this.modelArraySupplier[i]] = '';
					} else if (this.addDialog && i === 26) {
						this.supplierForm.value[this.modelArraySupplier[i]] = 2;
					}
				}
			}
			const regex = /^[\x00-\x7F\x00]*$/;
			if (this.supplierForm.value['supplier_name'] === null || this.supplierForm.value['supplier_name'] === ''
				|| this.supplierForm.value['mfg_address'] === null || this.supplierForm.value['mfg_address'] === ''
				|| this.supplierForm.value['mfg_city'] === null || this.supplierForm.value['mfg_city'] === ''
				|| this.supplierForm.value['mfg_zip_code'] === null || this.supplierForm.value['mfg_zip_code'] === ''
				|| this.supplierForm.value['ship_address'] === null || this.supplierForm.value['ship_address'] === ''
				|| this.supplierForm.value['ship_city'] === null || this.supplierForm.value['ship_city'] === ''
				|| this.supplierForm.value['ship_zip_code'] === null || this.supplierForm.value['ship_zip_code'] === ''
				||( this.baseInformation[0].Tier_Level === 1 && this.supplierForm.value['email_address'] === null || this.supplierForm.value['email_address'] === '')
				||( this.baseInformation[0].Tier_Level === 1 && this.supplierForm.value['contact_number'] === null || this.supplierForm.value['contact_number'] === '')
			) {

				if (this.addDialog === true) {
					this.showMainContent = false;
					this.messageService.add({
						severity: 'error',
						summary: 'Error',
						detail: 'Please fill mandatory fields',
						life: 300000
					});
				}
				if (this.editDialog === true) {
					this.showMainContent = false;
					this.messageService.add({
						severity: 'error',
						summary: 'Error',
						detail: 'Please fill mandatory fields',
						life: 300000
					});
				}
			} else if (regex.test(this.supplierForm.value['supplier_name']) &&
				(regex.test(this.supplierForm.value['tier_part_description'])) &&
				(regex.test(this.supplierForm.value['supplier_name'])) &&
				(regex.test(this.supplierForm.value['mfg_GSDB_code'])) &&
				(regex.test(this.supplierForm.value['mfg_address'])) &&
				(regex.test(this.supplierForm.value['mfg_city'])) &&
				(regex.test(this.supplierForm.value['mfg_state'])) &&
				(regex.test(this.supplierForm.value['mfg_country'])) &&
				(regex.test(this.supplierForm.value['mfg_zip_code'])) &&

				(regex.test(this.supplierForm.value['ship_GSDB_code'])) &&
				(regex.test(this.supplierForm.value['ship_address'])) &&
				(regex.test(this.supplierForm.value['ship_city'])) &&
				(regex.test(this.supplierForm.value['ship_state'])) &&
				(regex.test(this.supplierForm.value['ship_country'])) &&

				(regex.test(this.supplierForm.value['ship_zip_code'])) &&
				(regex.test(this.supplierForm.value['supplier_role'])) &&
				// (regex.test(this.supplierForm.value['email_address'])) &&
				// (regex.test(this.supplierForm.value['contact_number'])) &&
				(regex.test(this.supplierForm.value['source']))) {
				this.sustainabilitySurveyService.editSupplier(this.supplierForm.value).subscribe((res: any) => {

						this.baseInformationFlag = false;
						this.showMainContentExcelSheet = false;
						this.showMainContent = false;
						if (res.status === 'failure') {
							this.severity = 'error';
							this.summary = 'Error';
						} else if (res.status === 'Success') {
							this.editDialog = false;
							this.addDialog = false;
							this.showMainContent = false;
							this.severity = 'success';
							this.summary = 'Success';
							res.Message = 'Saved';
						} else {
							this.editDialog = false;
							this.addDialog = false;
							this.showMainContent = false;
							this.severity = 'success';
							this.summary = 'Success';
							res.Message = 'Saved';
						}
						this.messageService.add({
							severity: this.severity,
							summary: this.summary,
							detail: res ? (res.Message.replaceAll('_', ' ')).charAt(0).toUpperCase() + res.Message.replaceAll('_', ' ').slice(1) : null,
							life: 300000
						});
						this.getAllDataForSustainabilitySurvey();
					},
					error => {
						this.messageService.add({
							severity: 'error',
							summary: 'Error',
							detail: 'Try again',
							life: 300000
						});
					});
				this.baseInformationFlag = false;
				this.showMainContentExcelSheet = false;
				this.showMainContent = false;
			} else {
				if (this.addDialog === true) {
					this.showMainContent = false;
					this.messageService.add({
						severity: 'error',
						summary: 'Error',
						detail: 'Please Check All Fields',
						life: 300000
					});
				}
				if (this.editDialog === true) {
					this.showMainContent = false;
					this.messageService.add({
						severity: 'error',
						summary: 'Error',
						detail: 'Please Check All Fields',
						life: 300000
					});
				}
			}
		}

		console.log(JSON.stringify(this.supplierForm.value));
		console.log(this.modelArraySupplier);
		console.log($event.target);

	}

	onPartNumberChange(event) {
		this.selectedPartNumber = event.itemValue.ford_full_part_no;
		this.selectedPartNumber = event.value.ford_full_part_no;
		this.sustainabilitySurveyService.getSupplierName(this.selectedPartNumber, this.selectedVehicleNumber).subscribe((ress: any) => {
				console.log(ress);
				// this.supplierName = null;
				this.supplierName = ress;
				this.messageService.add({
					severity: 'success',
					summary: 'Success',
					detail: 'Saved',
					life: 300000
				});
				// this.getAllDataForSustainabilitySurvey();
				this.showMainContent = false;
				this.showMainContentExcelSheet = false;
			},
			error => {
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Try again',
					life: 300000
				});
			});

		this.sustainabilitySurveyService.getVehicleNumber(this.selectedSupplierChange, this.selectedPartNumber).subscribe((res1: any) => {
				console.log(res1);
				// this.vehicleCode = null;
				this.vehicleCode = res1;
				this.messageService.add({
					severity: 'success',
					summary: 'Success',
					detail: 'Saved',
					life: 300000
				});
				// this.getAllDataForSustainabilitySurvey();
				this.showMainContent = false;
				this.showMainContentExcelSheet = false;
			},
			error => {
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Try again',
					life: 300000
				});
			});
	}

	onVehicleChange(event) {

		this.selectedVehicleNumber = event.itemValue.vehicle_code;
		this.selectedVehicleNumber = event.value[0].vehicle_code;
		//alert(JSON.stringify(event.value[0].vehicle_code));
		this.sustainabilitySurveyService.getSupplierName(this.selectedPartNumber, this.selectedVehicleNumber).subscribe((ress: any) => {
				console.log(ress);
				// this.supplierName = null;
				this.supplierName = ress;
				this.messageService.add({
					severity: 'success',
					summary: 'Success',
					detail: 'Saved',
					life: 300000
				});
				// this.getAllDataForSustainabilitySurvey();
				this.showMainContent = false;
				this.showMainContentExcelSheet = false;
			},
			error => {
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Try again',
					life: 300000
				});
			});

		this.sustainabilitySurveyService.getPartNumber(this.selectedSupplierChange, this.selectedVehicleNumber).subscribe((res1: any) => {
				console.log(res1);
				// this.vehicleCode = null;
				this.partNumber = res1;
				this.messageService.add({
					severity: 'success',
					summary: 'Success',
					detail: 'Saved',
					life: 300000
				});
				// this.getAllDataForSustainabilitySurvey();
				this.showMainContent = false;
				this.showMainContentExcelSheet = false;
			},
			error => {
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Try again',
					life: 300000
				});
			});
	}

	onSupplierChange(event) {
		this.selectedSupplierChange = '';
		//alert(this.selectedSupplierChange);
		//alert(JSON.stringify(event.value[0].t1_supplier_name));
		// tslint:disable-next-line:prefer-for-of
		for (let i = 0; i < event.value.length; i++) {
			this.selectedSupplierChange.append(event.value[0].t1_supplier_name + '|');
		}
		this.sustainabilitySurveyService.getVehicleNumber(this.selectedSupplierChange, this.selectedPartNumber).subscribe((ress: any) => {
				console.log(ress);
				// this.supplierName = null;
				this.vehicleCode = ress;
				this.messageService.add({
					severity: 'success',
					summary: 'Success',
					detail: 'Saved',
					life: 300000
				});
				// this.getAllDataForSustainabilitySurvey();
				this.showMainContent = false;
				this.showMainContentExcelSheet = false;
			},
			error => {
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Try again',
					life: 300000
				});
			});

		this.sustainabilitySurveyService.getPartNumber(this.selectedSupplierChange, this.selectedVehicleNumber).subscribe((res1: any) => {
				console.log(res1);
				// this.vehicleCode = null;
				this.partNumber = res1;
				this.messageService.add({
					severity: 'success',
					summary: 'Success',
					detail: 'Saved',
					life: 300000
				});
				// this.getAllDataForSustainabilitySurvey();
				this.showMainContent = false;
				this.showMainContentExcelSheet = false;
			},
			error => {
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Try again',
					life: 300000
				});
			});
	}

	getBasedOnSearch() {

		this.enableBatterySymbol = false;

		this.showMainContent = false;
		this.getCallJson.value.fordPartNumber = this.partNumbers;
		this.getCallJson.value.vehicleCode = this.vehicleCodes;
		this.getCallJson.value.supplierName = this.supplierNames;
		this.getCallJson.value.pageNo = this.pageNo;
		this.getCallJson.value.pageRows = this.pageRows;
		// tslint:disable-next-line:max-line-length
		this.getCallJson.value.source = sessionStorage.getItem('Admin');

		this.getAllDataForSustainabilitySurvey();
		this.showMainContent = false;
		this.showMainContentExcelSheet = false;
		this.addDialog = false;
		this.editDialog = false;
		this.viewDialog = false;
	}

	resetSearchSelection() {
		this.showMainContent = false;
		this.getCallJson.value.fordPartNumber = [];
		this.getCallJson.value.vehicleCode = [];
		this.getCallJson.value.supplierName = [];
		this.getCallJson.value.pageNo = 1;
		this.getCallJson.value.pageRows = 10;
		this.getCallJson.value.source = sessionStorage.getItem('Admin');
		this.partNumbers = [];
		this.vehicleCodes = [];
		this.supplierNames = [];
		this.getAllDataForSustainabilitySurvey();
	}

	templateDashboard(){
		this.router.navigate(['/non-microchip-template']);
	}

	downloadSSReport() {
		if (sessionStorage.getItem('Admin') === 'Project R') {
			this.sustainabilitySurveyService.generateRReport(sessionStorage.getItem('emailAddress')).subscribe((data) => {
				// tslint:disable-next-line:max-line-length
				this.sustainabilitySurveyService.downLoadRFile(data, 'text/csv');
			});
		} else if (sessionStorage.getItem('Admin') === 'sustainability survey') {
			this.sustainabilitySurveyService.generateSSReport(sessionStorage.getItem('emailAddress')).subscribe((data) => {
				// tslint:disable-next-line:max-line-length
				this.sustainabilitySurveyService.downLoadSSFile(data, 'text/csv');
			});
		}
	}

	onUploadEvidence(suplierInfo) {
		console.log('suplierInfo' + suplierInfo.toString());
		this.supInfo = suplierInfo;
		this.enableShowAttachements = true;
		this.partTierSupplierId = suplierInfo.Part_Tier_Supp_ROW_ID;
		this.email = suplierInfo.Email_Address;
		this.name = suplierInfo.Supplier_Name;
		this.TierNumber = suplierInfo.Tier_Level;
		this.description = suplierInfo.Part_Description;
		this.evidenceList = [];
		this.sustainabilitySurveyService.getDataForEvidences(this.partTierSupplierId, this.email).subscribe((response) => {
			for (const evi of response.evidences) {
				console.log(evi, evi.type);
				if (evi['isActive'] === 'Y') {
					this.evidenceList.push(evi);
				}
			}
			console.log(this.evidenceList, this.evidenceList.length);
		});
	}

	onFileChangeEvent(action: any) {
		this.file = action.target.files[0];
	}

	pushDialog(dialogBody: any) {
		this.ref = this.dialogService.open(CustomDialogComponent, {
			header: 'Sustainability Survey - Response',
			footer: '',
			data: dialogBody,
			width: '50%',
			contentStyle: {
				'max-height': '1000px',
				overflow: 'auto'
			},
			baseZIndex: 10000,
		});
	}

	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 60000
		});
	}

	loadEvidence(event, form) {
		for (const file of event.files) {
			this.selectedFiles.push(file);
		}
		console.log('loadEvidence processing starts');
		// tslint:disable-next-line:max-line-length
		if (!this.documentName[0]) {
			this.messageService.add({
				severity: 'error',
				summary: 'Please Select a Evidence Document Name',
				detail: 'Try again',
				life: 300000
			});
		}
		if (!this.additionalComments) {
			this.messageService.add({
				severity: 'error',
				summary: 'Please Add Additional Comments',
				detail: 'Try again',
				life: 300000
			});
		}
		if (!this.selectedFiles[0]) {
			this.messageService.add({
				severity: 'error',
				summary: 'Please Upload the file',
				detail: 'Try again',
				life: 300000
			});
		}
		// tslint:disable-next-line:max-line-length
		this.sustainabilitySurveyService.uploadEvidence(this.selectedFiles[0], this.partTierSupplierId, this.selectedFiles[0].name, this.documentName[0]['documentName'], this.additionalComments).subscribe((res) => {
				console.log(res);
				this.selectedFiles.pop();
				this.onUploadEvidence(this.supInfo);
			}
		);

	}

	onViewEvidence(childInformationElement: any) {
		// tslint:disable-next-line:max-line-length
		this.sustainabilitySurveyService.downloadEvidence(childInformationElement.rowId, childInformationElement.fileName, sessionStorage.getItem('emailAddress')).subscribe((res) => {
			console.log(res);
			console.log('view Evidence service starts for the supplier row id');
			this.sustainabilitySurveyService.downloadEvidenceDocument(res, childInformationElement.fileName.type, childInformationElement.fileName);
		});
	}

	onDeleteEvidence(childInformationElement: any) {
		this.popup = false;
		console.log('deleteEvidence service starts' + childInformationElement.rowId);
		this.sustainabilitySurveyService.deleteEvidence(childInformationElement.rowId).subscribe((res) => {
				console.log(res);
				this.messageService.add({
					severity: 'Delete',
					summary: 'Success',
					detail: 'You Have Deleted the Item ',
					life: 300000
				});

				this.onUploadEvidence(this.supInfo);
			},
			error => {
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Try again',
					life: 300000
				});
			});
	}

	checkIfQuestionnaireAnswered(partTierSupId, fordPartNum)
		:
		any {
		// tslint:disable-next-line:max-line-length
		this.spinnerService1.show();
		const fordPartNumberArray = fordPartNum.split('|');
		// tslint:disable-next-line:max-line-length
		this.sustainabilitySurveyService.getQuestionnaireData(this.Locations1.value, this.Locations2.value, '', '', '', '', partTierSupId, fordPartNumberArray[0]).subscribe(
			res => {

				console.log(res['pcba'], res['pcba'] === 'Y');
				// tslint:disable-next-line:max-line-length
				this.selectedItem1[partTierSupId] = res['isGlobalManufacturingFootprint'] === 'Y' ? {
					title: 'Yes',
					selected: true
				} : {title: 'No', selected: true};
				this.selectedItem2[partTierSupId] = res['isOtherSuppliers'] === 'Y' ? {
					title: 'Yes',
					selected: true
				} : {title: 'No', selected: true};
				this.selectedItem[partTierSupId] = res['pcba'] === 'Y' ? {title: 'Yes', selected: true} : {
					title: 'No',
					selected: true
				};
				console.log(this.selectedItem1, this.selectedItem2, this.selectedItem);
				this.QuestionnaireForm.reset();
				this.QuestionnaireForm = new FormGroup({
					resId: new FormControl(res['resId']),
					Question1: new FormControl(res['isGlobalManufacturingFootprint']),
					Locations1: new FormArray([]),
					Question2: new FormControl(res['isOtherSuppliers']),
					Locations2: new FormArray([]),
					bufferStockCommoditymin: new FormControl(res['bufferStockCommodityMin']),
					bufferStockCommoditymax: new FormControl(res['bufferStockCommodityMax']),
					pcba: new FormControl(res['pcba'])
				});
				console.log(res);
				console.log(this.QuestionnaireForm);
				this.answeredQuestionnaire = true;
				console.log(this.answeredQuestionnaire);
				if (this.selectedItem1[partTierSupId].title === 'Yes') {
					this.locationFlag1 = true;
					this.Locations1.clear();
					this.selectedItemLoc1 = [];
					res['globalManufacturingFPList'].map(globalManufacturingFP => {
						if (globalManufacturingFP.isActive === 'Y') {
							// tslint:disable-next-line:max-line-length
							this.selectedItemLoc1.push(globalManufacturingFP.isOpenCapacity === 'Y' ? {
								title: 'Yes',
								selected: true
							} : {title: 'No', selected: true});
							this.Locations1.push(new FormGroup({
								resDtlId: new FormControl(globalManufacturingFP.resDtlId),
								resId: new FormControl(globalManufacturingFP.resId),
								locationName: new FormControl(globalManufacturingFP.locationName),
								address: new FormControl(globalManufacturingFP.address),
								city: new FormControl(globalManufacturingFP.city),
								state: new FormControl(globalManufacturingFP.state),
								country: new FormControl(globalManufacturingFP.country),
								dunsNo: new FormControl(globalManufacturingFP.dunsNo),
								isOpenCapacity: new FormControl(globalManufacturingFP.isOpenCapacity),
								leadTime: new FormControl(globalManufacturingFP.leadTime)
							}));
						}
					});
				} else {
					this.locationFlag1 = false;
					this.Locations1.clear();
					this.selectedItemLoc1 = [];
				}
				if (this.selectedItem2[partTierSupId].title === 'Yes') {
					this.locationFlag2 = true;
					this.Locations2.clear();
					this.selectedItemLoc2 = [];
					res['otherSuppliersList'].map(otherSuppliers => {
						if (otherSuppliers.isActive === 'Y') {
							this.selectedItemLoc2.push(otherSuppliers.isOpenCapacity === 'Y' ? {
								title: 'Yes',
								selected: true
							} : {title: 'No', selected: true});
							this.Locations2.push(new FormGroup({
								resDtlId: new FormControl(otherSuppliers.resDtlId),
								resId: new FormControl(otherSuppliers.resId),
								locationName: new FormControl(otherSuppliers.locationName),
								address: new FormControl(otherSuppliers.address),
								city: new FormControl(otherSuppliers.city),
								state: new FormControl(otherSuppliers.state),
								country: new FormControl(otherSuppliers.country),
								dunsNo: new FormControl(otherSuppliers.dunsNo),
								isOpenCapacity: new FormControl(otherSuppliers.isOpenCapacity),
								leadTime: new FormControl(otherSuppliers.leadTime)
							}));
						}
					});
				} else {
					this.locationFlag2 = false;
					this.Locations2.clear();
					this.selectedItemLoc2 = [];
				}
				this.checkQuestionnaireForm[partTierSupId] = this.answeredQuestionnaire;
				this.spinnerService1.hide();
			},
			error => {
				this.spinnerService1.hide();
				this.answeredQuestionnaire = false;
				this.checkQuestionnaireForm[partTierSupId] = this.answeredQuestionnaire;
				if (this.answeredQuestionnaire === false) {
					console.log('hello world');
				}
			}
		);
	}
	onClickAnswerQuestionnaire(suplierInfo) {
		this.supInfo = suplierInfo;
		this.enableShowQuestionnaire = true;
		this.partTierSupplierId = suplierInfo.Part_Tier_Supp_ROW_ID;
		this.email = suplierInfo.Email_Address;
		this.name = suplierInfo.Supplier_Name;
		this.TierNumber = suplierInfo.Tier_Level;
		this.description = suplierInfo.Part_Description;
		console.log(suplierInfo, this.fordPartNumber, this.partTierSupplierId);
		this.QuestionnaireForm.reset();
		this.Locations1.clear();
		this.selectedItemLoc1 = [];
		this.Locations2.clear();
		this.selectedItemLoc2 = [];
		this.locationFlag1 = false;
		this.locationFlag2 = false;

		this.checkIfQuestionnaireAnswered(this.partTierSupplierId, this.fordPartNumber);

		console.log(2);
		if (this.checkQuestionnaireForm[this.partTierSupplierId] === false) {
			console.log('hgfdsxcfghjk');
			this.selectedItem1[this.partTierSupplierId] = null;
			this.selectedItem2[this.partTierSupplierId] = null;
		}
	}

	get Locations1() {
		return this.QuestionnaireForm.get('Locations1') as FormArray;
	}

	addLocations1() {
		this.selectedItemLoc1.push(null);
		this.Locations1.push(
			this.formBuilder.group({
				resDtlId: [''],
				resId: [''],
				locationName: new FormControl('', [Validators.required]),
				address: new FormControl('', [Validators.required]),
				city: new FormControl('', [Validators.required]),
				state: new FormControl('', [Validators.required]),
				country: new FormControl('', [Validators.required]),
				dunsNo: new FormControl('-'),
				isOpenCapacity: new FormControl('N'),
				leadTime: new FormControl('', [Validators.required, integerValidator])
			})
		);
	}

	removeLocations1(i) {
		if (i) {
			this.Locations1.removeAt(i);
		} else {
			this.Locations1.removeAt(this.Locations1.length - 1);
		}
	}

	get Locations2() {
		return this.QuestionnaireForm.get('Locations2') as FormArray;
	}

	addLocations2() {
		this.Locations2.push(
			this.formBuilder.group({
				resDtlId: [''],
				resId: [''],
				locationName: new FormControl('', [Validators.required]),
				address: new FormControl('', [Validators.required]),
				city: new FormControl('', [Validators.required]),
				state: new FormControl('', [Validators.required]),
				country: new FormControl('', [Validators.required]),
				dunsNo: new FormControl('-'),
				isOpenCapacity: new FormControl('N'),
				leadTime: new FormControl('', [Validators.required, integerValidator])
			})
		);
		this.selectedItemLoc2.push(null);
	}

	removeLocations2(i) {
		if (i) {
			this.Locations2.removeAt(i);
		} else {
			this.Locations2.removeAt(this.Locations2.length - 1);
		}
	}

	onSegmentedItemChange($event, q) {
		if ($event.title === 'Yes') {
			if (q === 1) {
				this.locationFlag1 = true;
				this.QuestionnaireForm.get('Question1').setValue('Y');
				if (this.Locations1.length === 0) {
					this.addLocations1();
					this.Locations1.controls.forEach((group, index) => {
						// Set street value for each group
						group.get('resId').setValue('9');
						group.get('dunsNo').setValue('-');
						group.get('isOpenCapacity').setValue('N');
						group.get('resDtlId').setValue('9');
					});
				}
			} else {
				if (q === 0) {
					this.QuestionnaireForm.get('pcba').setValue('Y');
				} else {
					this.locationFlag2 = true;
					this.QuestionnaireForm.get('Question2').setValue('Y');
					if (this.Locations2.length === 0) {
						this.addLocations2();
						this.Locations2.controls.forEach((group, index) => {
							// Set street value for each group
							group.get('resId').setValue('9');
							group.get('dunsNo').setValue('-');
							group.get('isOpenCapacity').setValue('N');
							group.get('resDtlId').setValue('9');
						});
					}
				}
			}
		} else {
			if (q === 1) {
				this.locationFlag1 = false;
				this.QuestionnaireForm.get('Question1').setValue('N');
				this.Locations1.clear();
				console.log(this.Locations1);
				this.selectedItemLoc1 = [];
			} else {
				if (q === 0) {
					this.QuestionnaireForm.get('pcba').setValue('N');
				} else {
					this.locationFlag2 = false;
					this.QuestionnaireForm.get('Question2').setValue('N');
					this.Locations2.clear();
					this.selectedItemLoc2 = [];
				}
			}
		}
		console.log('changed', $event);
	}

	getlocationNameControl(i, q) {
		if (q === 1) {
			return this.Locations1.at(i).get('locationName');
		} else {
			return this.Locations2.at(i).get('locationName');
		}

	}

	getaddressControl(i, q) {
		if (q === 1) {
			return this.Locations1.at(i).get('address');
		} else {
			return this.Locations2.at(i).get('address');
		}
	}

	getcityControl(i, q) {
		if (q === 1) {
			return this.Locations1.at(i).get('city');
		} else {
			return this.Locations2.at(i).get('city');
		}
	}

	getstateControl(i, q) {
		if (q === 1) {
			return this.Locations1.at(i).get('state');
		} else {
			return this.Locations2.at(i).get('state');
		}
	}

	getcountryControl(i, q) {
		if (q === 1) {
			return this.Locations1.at(i).get('country');
		} else {
			return this.Locations2.at(i).get('country');
		}
	}

	getdunsNoControl(i, q) {
		if (q === 1) {
			return this.Locations1.at(i).get('dunsNo');
		} else {
			return this.Locations2.at(i).get('dunsNo');
		}
	}

	getleadtimeControl(i, q) {
		if (q === 1) {
			return this.Locations1.at(i).get('leadTime');
		} else {
			return this.Locations2.at(i).get('leadTime');
		}
	}

	QuestionnaireFormSubmit($event, tierNum) {
		if (tierNum === 1) {
			function isInteger(str: string): boolean {
				// Regular expression to match only digits
				const regExp = /^\d+$/;

				return regExp.test(str);
			}

			const bufferStockmin = this.QuestionnaireForm.get('bufferStockCommoditymin').value;
			const bufferStockmax = this.QuestionnaireForm.get('bufferStockCommoditymax').value;
			const pcba = this.QuestionnaireForm.get('pcba').value;
			const partTierSupplierId = this.partTierSupplierId;
			const fordPartNum = this.fordPartNumber;
			if (!bufferStockmin || !bufferStockmax || !isInteger(bufferStockmax) || !isInteger(bufferStockmin)) {
				this.messageService.add({
					severity: 'error',
					summary: 'Please Enter all the Fields Correctly',
					detail: 'Try again',
					life: 300000
				});
			} else {
				// tslint:disable-next-line:max-line-length
				this.sustainabilitySurveyService.submitQuestionnaire(this.Locations1.value, this.Locations2.value, bufferStockmin, bufferStockmax, pcba, null, null, partTierSupplierId, fordPartNum).subscribe(res => {
					console.log(res);
					this.messageService.add({
						severity: 'success',
						summary: 'Successfully completed the Questionnaire',
						detail: '',
						life: 300000
					});
					this.enableShowQuestionnaire = false;
					this.answeredQuestionnaire = true;
					this.QuestionnaireForm.reset();
					this.Locations1.clear();
					this.selectedItemLoc1 = [];
					this.Locations2.clear();
					this.selectedItemLoc2 = [];
					this.addLocations1();
					this.Locations1.controls.forEach((group, index) => {
						// Set street value for each group
						group.get('resId').setValue('9');
						group.get('dunsNo').setValue('-');
						group.get('isOpenCapacity').setValue('N');
						group.get('resDtlId').setValue('9');
					});
					this.addLocations2();
					this.Locations2.controls.forEach((group, index) => {
						// Set street value for each group
						group.get('resId').setValue('9');
						group.get('dunsNo').setValue('-');
						group.get('isOpenCapacity').setValue('N');
						group.get('resDtlId').setValue('9');
					});
					this.locationFlag1 = false;
					this.locationFlag2 = false;
					this.checkQuestionnaireForm[partTierSupplierId] = true;
				});
			}
		} else {
			const globalsup = this.QuestionnaireForm.get('Question1').value === 'Y' ? 'Y' : 'N';
			const otherSup = this.QuestionnaireForm.get('Question2').value === 'Y' ? 'Y' : 'N';
			const pcba = this.QuestionnaireForm.get('pcba').value === 'Y' ? 'Y' : 'N';
			const partTierSupplierId = this.partTierSupplierId;
			const fordPartNum = this.fordPartNumber;
			console.log(this.QuestionnaireForm.value);
			this.Locations1.controls.reduce(
				(validSoFar, group) => {
					console.log(group);
					console.log(validSoFar, group.valid);
					return validSoFar && group.valid;
				}, true);
			// tslint:disable-next-line:max-line-length
			if ((globalsup === 'Y' && !this.Locations1.controls.reduce((validSoFar, group) => {
				return validSoFar && group.valid;
			}, true)) || (otherSup === 'Y' && !this.Locations2.controls.reduce((validSoFar, group) => {
				return validSoFar && group.valid;
			}, true))) {
				this.messageService.add({
					severity: 'error',
					summary: 'Please Enter all the Fields Correctly',
					detail: 'Try again',
					life: 300000
				});
			} else {
				console.log(this.Locations1.value);
				this.selectedItem1[this.partTierSupplierId] = globalsup === 'Y' ? {
					title: 'Yes',
					selected: true
				} : {title: 'No', selected: true};
				this.selectedItem2[this.partTierSupplierId] = otherSup === 'Y' ? {
					title: 'Yes',
					selected: true
				} : {title: 'No', selected: true};
				this.selectedItem[this.partTierSupplierId] = pcba === 'Y' ? {
					title: 'Yes',
					selected: true
				} : {title: 'No', selected: true};
				// tslint:disable-next-line:max-line-length
				this.sustainabilitySurveyService.submitQuestionnaire(this.Locations1.value, this.Locations2.value, null, null, null, globalsup, otherSup, partTierSupplierId, fordPartNum).subscribe(res => {
					console.log(res);
					this.messageService.add({
						severity: 'success',
						summary: 'Successfully completed the Questionnaire',
						detail: '',
						life: 300000
					});
					this.enableShowQuestionnaire = false;
					this.answeredQuestionnaire = true;
					this.QuestionnaireForm.reset();
					this.Locations1.clear();
					this.selectedItemLoc1 = [];
					this.Locations2.clear();
					this.selectedItemLoc2 = [];
					this.addLocations1();
					this.Locations1.controls.forEach((group, index) => {
						// Set street value for each group
						group.get('resId').setValue('9');
						group.get('dunsNo').setValue('-');
						group.get('isOpenCapacity').setValue('N');
						group.get('resDtlId').setValue('9');
					});
					this.addLocations2();
					this.Locations2.controls.forEach((group, index) => {
						// Set street value for each group
						group.get('resId').setValue('9');
						group.get('dunsNo').setValue('-');
						group.get('isOpenCapacity').setValue('N');
						group.get('resDtlId').setValue('9');
					});
					this.locationFlag1 = false;
					this.locationFlag2 = false;
					this.checkQuestionnaireForm[partTierSupplierId] = true;

				});
			}
		}
	}

	QuestionnaireFormUpdate($event, tierNum) {
		if (tierNum === 1) {
			const bufferStockmin = this.QuestionnaireForm.get('bufferStockCommoditymin').value;
			const bufferStockmax = this.QuestionnaireForm.get('bufferStockCommoditymax').value;
			const pcba = this.QuestionnaireForm.get('pcba').value;
			const resId = this.QuestionnaireForm.get('resId').value;
			const partTierSupplierId = this.partTierSupplierId;
			const fordPartNum = this.fordPartNumber;
			if (!bufferStockmin || !bufferStockmax) {
				this.messageService.add({
					severity: 'error',
					summary: 'Please Enter all the Fields',
					detail: 'Try again',
					life: 300000
				});
			} else {
				// tslint:disable-next-line:max-line-length
				this.sustainabilitySurveyService.updateQuestionnaire(resId, this.Locations1.value, this.Locations2.value, bufferStockmin, bufferStockmax, pcba, null, null, partTierSupplierId, fordPartNum).subscribe(res => {
					console.log(res);
					this.messageService.add({
						severity: 'success',
						summary: 'Successfully completed the Questionnaire',
						detail: '',
						life: 300000
					});
					this.enableShowQuestionnaire = false;
					this.answeredQuestionnaire = true;
					this.checkQuestionnaireForm[partTierSupplierId] = true;
					this.QuestionnaireForm.reset();
					this.Locations1.clear();
					this.selectedItemLoc1 = [];
					this.Locations2.clear();
					this.selectedItemLoc2 = [];
					this.addLocations1();
					this.Locations1.controls.forEach((group, index) => {
						// Set street value for each group
						group.get('resId').setValue('9');
						group.get('dunsNo').setValue('-');
						group.get('isOpenCapacity').setValue('N');
						group.get('resDtlId').setValue('9');
					});
					this.addLocations2();
					this.Locations2.controls.forEach((group, index) => {
						// Set street value for each group
						group.get('resId').setValue('9');
						group.get('dunsNo').setValue('-');
						group.get('isOpenCapacity').setValue('N');
						group.get('resDtlId').setValue('9');
					});
					this.locationFlag1 = false;
					this.locationFlag2 = false;

				});
			}
		} else {
			console.log(this.Locations1.value);
			const resId = this.QuestionnaireForm.get('resId').value;
			const globalsup = this.QuestionnaireForm.get('Question1').value === 'Y' ? 'Y' : 'N';
			const otherSup = this.QuestionnaireForm.get('Question2').value === 'Y' ? 'Y' : 'N';
			const pcba = this.QuestionnaireForm.get('pcba').value === 'Y' ? 'Y' : 'N';
			const partTierSupplierId = this.partTierSupplierId;
			const fordPartNum = this.fordPartNumber;
			console.log(this.QuestionnaireForm.value);
			// tslint:disable-next-line:max-line-length
			if ((globalsup === 'Y' && !this.Locations1.controls.reduce((validSoFar, group) => {
				return validSoFar && group.valid;
			}, true)) || (otherSup === 'Y' && !this.Locations2.controls.reduce((validSoFar, group) => {
				return validSoFar && group.valid;
			}, true))) {
				this.messageService.add({
					severity: 'error',
					summary: 'Please Enter all the Fields',
					detail: 'Try again',
					life: 300000
				});
			} else {
				this.selectedItem1[this.partTierSupplierId] = globalsup === 'Y' ? {
					title: 'Yes',
					selected: true
				} : {title: 'No', selected: true};
				this.selectedItem2[this.partTierSupplierId] = otherSup === 'Y' ? {
					title: 'Yes',
					selected: true
				} : {title: 'No', selected: true};
				this.selectedItem[this.partTierSupplierId] = pcba === 'Y' ? {
					title: 'Yes',
					selected: true
				} : {title: 'No', selected: true};

				console.log(this.QuestionnaireForm, this.Locations1.value);
				// tslint:disable-next-line:max-line-length
				this.sustainabilitySurveyService.updateQuestionnaire(resId, this.Locations1.value, this.Locations2.value, null, null, null, globalsup, otherSup, partTierSupplierId, fordPartNum).subscribe(res => {
					console.log(res);
					this.messageService.add({
						severity: 'success',
						summary: 'Successfully completed the Questionnaire',
						detail: '',
						life: 300000
					});
					this.enableShowQuestionnaire = false;
					this.answeredQuestionnaire = true;
					this.checkQuestionnaireForm[partTierSupplierId] = true;
					this.QuestionnaireForm.reset();
					this.Locations1.clear();
					this.selectedItemLoc1 = [];
					this.Locations2.clear();
					this.selectedItemLoc2 = [];
					this.addLocations1();
					this.Locations1.controls.forEach((group, index) => {
						// Set street value for each group
						group.get('resId').setValue('9');
						group.get('dunsNo').setValue('-');
						group.get('isOpenCapacity').setValue('N');
						group.get('resDtlId').setValue('9');
					});
					this.addLocations2();
					this.Locations2.controls.forEach((group, index) => {
						// Set street value for each group
						group.get('resId').setValue('9');
						group.get('dunsNo').setValue('-');
						group.get('isOpenCapacity').setValue('N');
						group.get('resDtlId').setValue('9');
					});
					this.locationFlag1 = false;
					this.locationFlag2 = false;
				});
			}
		}
	}

	onSegmentedLocationItemChange($event: any, q: number, i:
		number
	) {
		if ($event.title === 'Yes') {
			if (q === 1) {
				this.Locations1.at(i).get('isOpenCapacity').setValue('Y');
			} else {
				this.Locations2.at(i).get('isOpenCapacity').setValue('Y');
			}
		} else {
			if (q === 1) {
				this.Locations1.at(i).get('isOpenCapacity').setValue('N');
			} else {
				this.Locations2.at(i).get('isOpenCapacity').setValue('N');
			}
		}
		console.log($event);
	}

	updatePartsPerPage(event: any) {
		const selectedValue = event.value;

		if (this.selectedPartsPerPage.parts_per_page === undefined || this.pageRows === this.selectedPartsPerPage.parts_per_page) {
			if (selectedValue <= 50) {
				// tslint:disable-next-line:indent
				this.pageRows = selectedValue;
			} else {
				this.selectedPartsPerPage = {parts_per_page: 50};
				this.pageRows = this.selectedPartsPerPage.parts_per_page;
				alert('The max page rows is 50');
			}
		} else {
			this.pageRows = this.selectedPartsPerPage.parts_per_page;
		}

		this.getAllDataForSustainabilitySurvey();

	}

	onNodeRightClick(event: any, node: TreeNode) {

		event.preventDefault();
		// alert(JSON.stringify(node));
		this.rightClickContextForTree = [];
		// tslint:disable-next-line:prefer-for-of
		for (let j = 0; j < 25; j++) {
			// tslint:disable-next-line:prefer-for-of
			for (let i = 0; i < this.treeDirectoryData.length; i++) {
				this.rightClickContextForTree.push({
					label: this.treeDirectoryData[0].baseData[i].Ford_Full_Part_No, icon: 'pi pi-eye'
				});
			}
		}
		const newNode: TreeNode = {label: 'New Child Node'};
		const parentNode = node;
		parentNode.children.push(newNode);
		// }
		// Check if the right-click event was triggered on the selected node
		if (node.label === this.treeDirectoryData) {
			// Implement your right-click functionality here
			// alert(JSON.stringify(node));
			console.log('Node right-clicked:', node);
		}
	}

	toggleFlag(num: any, partTierSupplierId: any) {
		// alert(JSON.stringify(partTierSupplierId));
		// this.confirmationService.confirm({
		// 	message: 'Are you sure that you want to proceed?',
		// 	icon: 'pi pi-exclamation-triangle',
		// 	header: 'Supplier Verification',
		// 	accept: () => {
		// this.messageService.add({severity: 'info', summary: 'Confirmed', detail: 'You have accepted'});
		this.sustainabilitySurveyService.updateSupplierFlag(num, partTierSupplierId).subscribe((res: any) => {
				if (res === 'success') {
					this.messageService.add({
						severity: 'success',
						summary: 'Success',
						detail: 'Deleted',
						life: 300000
					});
				} else {
					this.messageService.add({
						severity: 'error',
						summary: 'Error',
						detail: 'Try again',
						life: 300000
					});
				}
				this.getAllDataForSustainabilitySurvey();
				this.showMainContent = false;
				// alert();
				this.showMainContentExcelSheet = false;
			},
			error => {
				if (num === 2) {
					this.messageService.add({
						severity: 'success',
						summary: 'Success',
						detail: 'Red Flag',
						life: 300000
					});
				} else if (num === 3) {
					this.messageService.add({
						severity: 'success',
						summary: 'Success',
						detail: 'Yellow Flag',
						life: 300000
					});
				} else if (num === 5) {
					this.messageService.add({
						severity: 'success',
						summary: 'Success',
						detail: 'No Flag',
						life: 300000
					});
				}
				this.getAllDataForSustainabilitySurvey();
				this.showMainContent = false;
				this.showMainContentExcelSheet = false;
			});
		// 	},
		// 	reject: () => {
		// 		this.messageService.add({severity: 'error', summary: 'Rejected', detail: 'You have rejected'});
		// 	}
		// });
	}

	toggleFlagPart(num: any, collectionId: any) {
		// this.sustainabilitySurveyService.updatePartFlag(num, collectionId);
		this.confirmationService.confirm({
			message: 'Are you sure that you want to proceed?',
			icon: 'pi pi-exclamation-triangle',
			header: 'Part Collection Verification',
			accept: () => {
				this.messageService.add({severity: 'info', summary: 'Confirmed', detail: 'You have accepted'});
				this.sustainabilitySurveyService.updatePartFlag(num, collectionId).subscribe((res: any) => {
						if (res === 'success') {
							this.messageService.add({
								severity: 'success',
								summary: 'Success',
								detail: 'Deleted',
								life: 300000
							});
						} else {
							this.messageService.add({
								severity: 'error',
								summary: 'Error',
								detail: 'Try again',
								life: 300000
							});
						}
						this.getAllDataForSustainabilitySurvey();
						this.showMainContent = false;
						this.showMainContentExcelSheet = false;
					},
					error => {
						if (num === 0) {
							this.messageService.add({
								severity: 'success',
								summary: 'Success',
								detail: 'Part Number Not Verified',
								life: 300000
							});
						} else if (num === 1) {
							this.messageService.add({
								severity: 'success',
								summary: 'Success',
								detail: 'Part Number Verified',
								life: 300000
							});
						}
						this.getAllDataForSustainabilitySurvey();
						this.showMainContent = false;
						this.showMainContentExcelSheet = false;
					});
			},
			reject: () => {
				this.messageService.add({severity: 'error', summary: 'Rejected', detail: 'You have rejected'});
			}
		});
	}

	showContextMenu(event, partTierSuppROWID) {
		// prevent the default context menu from showing
		event.preventDefault();
		this.partTierSuppROWID = partTierSuppROWID;

		// set the context menu target
		this.cm.show(event.originalEvent);
	}

	contextMenuSelected(event) {
		// get the selected item
		const selectedItem = event.item;

		// perform your desired action here
	}

	gsdbSubmitCheck() {
		if (this.gSDBCode['gsdb'] !== null && this.gSDBCode['gsdb'] !== '') {
			this.successModal = false;
			this.messageService.add({
				severity: 'success',
				summary: 'Success',
				detail: 'Selected GSDB is ' + this.gSDBCode['gsdb'],
				life: 300000
			});
		} else {
			this.messageService.add({
				severity: 'error',
				summary: 'Error',
				detail: 'Please select GSDB',
				life: 300000
			});
		}
	}

	closeGsdbButton() {
		this.showMainContentExcelSheet = false;
		this.successModal = false;
	}
}
