import {
	Component,
	OnInit,
	ViewChild
} from '@angular/core';
import {Router} from '@angular/router';
import {
	FormBuilder,
	FormGroup
} from '@angular/forms';
import {
	ConfirmationService,
	MessageService,
	DialogService,
	DynamicDialogRef
} from 'primeng/api';
import {TigersharkConstraintTrackerService} from '../service/tigershark-constraint-tracker.service';
import {DatePipe} from '@angular/common';
import {NTierMasterAdminService} from '../../n-tier-master-admin/service/n-tier-master-admin.service';
import {HttpClient} from '@angular/common/http';
import {TigersharkDashboardDownloadService} from '../service/tigershark-dashboard-download.service';
import {SpinnerService} from '../../../services/spinner/spinner.service';
// @ts-ignore
import {MatTableDataSource} from '@angular/material';
import {IgxSpreadsheetComponent} from 'igniteui-angular-spreadsheet';
import {ExcelUtility} from '../model/excel-utility';
import {
	Workbook,
	WorkbookSaveOptions
} from 'igniteui-angular-excel';
import {TigerSharkConstraintTracker} from '../model/tiger-shark-constraint-tracker';
import {SupplierService} from '../../../home/services/supplier.service';
import {CustomDialogComponent} from '../../../custom-dialog/custom-dialog/custom-dialog.component';

@Component({
	selector: 'tiger-shark-constraint-tracker',
	templateUrl: './tiger-shark-constraint-tracker.component.html',
	styleUrls: ['./tiger-shark-constraint-tracker.component.scss']
})
export class TigerSharkConstraintTrackerComponent implements OnInit {

	tigerSharkConstraintForm: FormGroup;
	isDisabled = false;
	isFordUser = false;
	constraintIdsList = [];
	supplierSiteCodeList = [];
	plantCodeList = [];
	partNumberList = [];
	actualBusinessUnitList = [];
	selectedConstraintId: any;
	selectedActualBusinessUnit: string;
	selectedSupplierSiteCode: string;
	selectedPlantCode: string;
	selectedPartNumber: string;
	fileFromDatabase: any;
	file: File = null;
	selectedFiles: any[] = [];
	isSubmitVisible = false;
	disableActualBusinessUniDropdown = false;
	disableSupplierSiteCodeDropdown = false;
	disablePlantCodeDropdown = false;
	disablePartNumberDropdown = false;

	constraintIdObject: any;
	actualBusinessUnitObject: any;
	supplierSiteCodeObject: any;
	plantCodeObject: any;
	partNumberObject: any;

	constraintIds: any[] = [];
	businessUnits: any[] = [];
	supplierSiteCodes: any[] = [];
	plantCodes: any[] = [];
	partNumbers: any[] = [];
	selectedConstraintIds: any[] = [];
	selectedBusinessUnits: any[] = [];
	selectedSupplierSiteCodes: any[] = [];
	selectedPlantCodes: any[] = [];
	selectedPartNumbers: any[] = [];
	versionNumbers: any[] = [];
	selectedVersionNumbers: any[] = [];
	tigerSharkConstraintTracker: TigerSharkConstraintTracker;
	ref: DynamicDialogRef;

	// tslint:disable-next-line:max-line-length
	constructor(private supplierService: SupplierService, private confirmationService: ConfirmationService, private http: HttpClient, private masterAdminService: NTierMasterAdminService, private fb: FormBuilder, private datepipe: DatePipe, private messageService: MessageService, private router: Router, private tigersharkConstraintTrackerService: TigersharkConstraintTrackerService, private tigersharkDashboardDownloadService: TigersharkDashboardDownloadService, private spinnerService: SpinnerService, private dialogService: DialogService) {
	}

	@ViewChild('spreadsheet', {static: false, read: IgxSpreadsheetComponent})
	public spreadsheet: IgxSpreadsheetComponent;

	ngOnInit() {
		this.router.navigate(['/']);

		// this.supplierService.pushSelectedSurvey('WPP');
		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
		} else {
			if (!(sessionStorage.getItem('uid').includes('-'))) {
				this.isFordUser = true;
				// tslint:disable-next-line:max-line-length
				this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'WPP Screen').subscribe(userHasAdminAccess => {
					if (!userHasAdminAccess) {
						this.router.navigate(['/']);
					}
				});
			} else {
				this.masterAdminService.isSupplierHasTigerSharkAccess(sessionStorage.getItem('emailAddress')).subscribe((userHasAccess) => {
					if (!userHasAccess) {
						this.router.navigate(['/']);
					}
				});
			}
		}
		this.tigerSharkConstraintForm = this.fb.group({
			constraintIdList: [{value: '', disabled: this.isDisabled}],
			plantCodeList: [{value: '', disabled: this.isDisabled}],
			partNumberList: [{value: '', disabled: this.isDisabled}],
			actualBusinessUnitList: [{value: '', disabled: this.isDisabled}],
			supplierSiteCodeList: [{value: '', disabled: this.isDisabled}],
			weekDate: [{value: '', disabled: true}],
			weekDateCal: [{value: '', disabled: this.isDisabled}],
			weekNumber: [{value: '', disabled: true}],
			constraintIds: [{value: ''}],
			actualBusinessUnits: [{value: ''}],
			supplierSiteCodes: [{value: ''}],
			plantCodes: [{value: ''}],
			partNumbers: [{value: ''}],
			versionNumbers: [{value: ''}]
		});
		if (!this.isFordUser) {
			this.tigerSharkConstraintForm.patchValue({weekDate: this.datepipe.transform(this.getMondayFromCurrentDate(), 'yyyy-MM-dd')});
			this.tigerSharkConstraintForm.patchValue({weekNumber: this.getCurrentWeekNumber()});
			this.fetchConstraintIdsFromEmailV2();
		}
	}

	contactInfo() {
		this.router.navigate(['/tiger-shark-constraint-tracker-admin']);
	}

	getCurrentWeekNumber(): number {
		const currentDate = new Date();
		// const oneJan = new Date(currentDate.getFullYear(), 0, 1);
		// // @ts-ignore
		// const numberOfDays = Math.floor((currentDate - oneJan) / (24 * 60 * 60 * 1000));
		// const weekNumber = Math.ceil((currentDate.getDay() + 1 + numberOfDays) / 7);
		// return weekNumber;
		const d = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()));
		const dayNum = d.getUTCDay() || 7;
		d.setUTCDate(d.getUTCDate() + 4 - dayNum);
		const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
		// @ts-ignore
		const weekNumber = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
		return weekNumber;
	}

	private getMondayFromCurrentDate(): Date {
		const currentDate = new Date();
		const currentDay = currentDate.getDay();
		const diff = currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1); // adjust when day is sunday
		return new Date(currentDate.setDate(diff));
	}

	private fetchConstraintIdsFromEmail() {
		this.tigersharkConstraintTrackerService.getConstraintIdsFromEmail(sessionStorage.getItem('emailAddress')).subscribe((data) => {
			// if (data.length > 0) {
			// 	this.constraintIdObject = {constraintId: 'All'};
			// 	data.splice(0, 0, {constraintId: 'All'});
			// }
			this.constraintIdsList = data;
		});
	}

	private fetchAllConstraintIdsFordFordUser() {
		this.tigersharkConstraintTrackerService.getAllConstraintIdsForFordUser().subscribe((data) => {
			this.constraintIdsList = data;
		});
	}

	fetchActualBusinessUnit() {
		console.log('Fetching Business Tracking Ids');
		this.actualBusinessUnitList = [];
		this.plantCodeList = [];
		this.partNumberList = [];
		this.actualBusinessUnitObject = undefined;
		this.plantCodeObject = undefined;
		this.partNumberObject = undefined;
		if (this.selectedConstraintId === 'All') {
			console.log('Selected ALL');
			this.actualBusinessUnitObject = {actualBusinessUnit: 'All'};
			this.disableActualBusinessUniDropdown = true;
			this.selectedConstraintId = '0';
		} else {
			console.log('Selected Constraint : ' + this.selectedConstraintId);
			this.disableActualBusinessUniDropdown = false;
			this.disablePlantCodeDropdown = false;
			const tigerSharkConstraintTacker = new TigerSharkConstraintTracker();

			tigerSharkConstraintTacker.constraintId = this.selectedConstraintId;
			this.tigersharkConstraintTrackerService.getActualBusinessUnitFromConstraintIds(tigerSharkConstraintTacker).subscribe((data) => {
				if (data.length > 0) {
					data.splice(0, 0, {actualBusinessUnit: 'All'});
				}
				this.actualBusinessUnitList = data;
				console.log(this.actualBusinessUnitList);
			});
		}
	}

	fetchSupplierSiteCode() {
		this.supplierSiteCodeList = [];
		this.plantCodeList = [];
		this.partNumberList = [];
		this.supplierSiteCodeObject = undefined;
		this.plantCodeObject = undefined;
		this.partNumberObject = undefined;
		if (this.selectedActualBusinessUnit === 'All') {
			this.supplierSiteCodeObject = {supplierSiteCode: 'All'};
			this.plantCodeObject = {plantCode: 'All'};
			this.partNumberObject = {partNumber: 'All'};
			console.log('Selected ALL');
			this.disableSupplierSiteCodeDropdown = true;
		} else {
			this.disableSupplierSiteCodeDropdown = false;
			const tigerSharkConstraintTacker = new TigerSharkConstraintTracker();

			tigerSharkConstraintTacker.constraintId = this.selectedConstraintId;
			tigerSharkConstraintTacker.actualBusinessUnit = this.selectedActualBusinessUnit;

			// tslint:disable-next-line:max-line-length
			this.tigersharkConstraintTrackerService.getSupplierSiteCodeFromConstraintIdsAndActualBusinessUnit(tigerSharkConstraintTacker).subscribe((data) => {
				if (data.length > 0) {
					data.splice(0, 0, {supplierSiteCode: 'All'});
				}
				this.supplierSiteCodeList = data;
			});
		}
	}

	fetchPlantCodes() {
		this.plantCodeList = [];
		this.partNumberList = [];
		this.plantCodeObject = undefined;
		this.partNumberObject = undefined;
		if (this.selectedSupplierSiteCode === 'All') {
			this.plantCodeObject = {plantCode: 'All'};
			this.partNumberObject = {partNumber: 'All'};
			console.log('Selected ALL');
			this.disablePlantCodeDropdown = true;
		} else {
			this.disablePlantCodeDropdown = false;
			const tigerSharkConstraintTacker = new TigerSharkConstraintTracker();

			tigerSharkConstraintTacker.constraintId = this.selectedConstraintId;
			tigerSharkConstraintTacker.actualBusinessUnit = this.selectedActualBusinessUnit;
			tigerSharkConstraintTacker.supplierSiteCode = this.selectedSupplierSiteCode;

			// tslint:disable-next-line:max-line-length
			this.tigersharkConstraintTrackerService.getPlantCodesFromConstraintIdsAndActualBusinessUnitAndSupplierSiteCode(tigerSharkConstraintTacker).subscribe((data) => {
				if (data.length > 0) {
					data.splice(0, 0, {plantCode: 'All'});
				}
				this.plantCodeList = data;
			});
		}
	}

	fetchPartNumbers() {
		this.partNumberList = [];
		this.partNumberObject = undefined;
		if (this.selectedPlantCode === 'All') {
			console.log('Selected ALL');
			this.partNumberObject = {partNumber: 'All'};
			this.disablePartNumberDropdown = true;
		} else {
			this.disablePartNumberDropdown = false;
			const tigerSharkConstraintTacker = new TigerSharkConstraintTracker();

			tigerSharkConstraintTacker.constraintId = this.selectedConstraintId;
			tigerSharkConstraintTacker.actualBusinessUnit = this.selectedActualBusinessUnit;
			tigerSharkConstraintTacker.supplierSiteCode = this.selectedSupplierSiteCode;
			tigerSharkConstraintTacker.plantCode = this.selectedPlantCode;

			// tslint:disable-next-line:max-line-length
			this.tigersharkConstraintTrackerService.getPartNumbersFromConstraintIdAndActualBusinessUnitAndSupplierSiteCodeAndPlantCode(tigerSharkConstraintTacker).subscribe((data) => {
				//this.staticDataReset();
				if (data.length > 0) {
					data.splice(0, 0, {partNumber: 'All'});
				}
				this.partNumberList = data;
			});
		}
	}

	loadData() {
		const tigerSharkConstraintTacker = new TigerSharkConstraintTracker();

		tigerSharkConstraintTacker.constraintId = this.selectedConstraintId;
		tigerSharkConstraintTacker.actualBusinessUnit = this.selectedActualBusinessUnit;
		tigerSharkConstraintTacker.supplierSiteCode = this.selectedSupplierSiteCode;
		tigerSharkConstraintTacker.plantCode = this.selectedPlantCode;
		tigerSharkConstraintTacker.partNumber = this.selectedPartNumber;
		tigerSharkConstraintTacker.weekNumber = this.getCurrentWeekNumber();
		tigerSharkConstraintTacker.weekDate = this.getMondayFromCurrentDate();
		tigerSharkConstraintTacker.supplierMailId = sessionStorage.getItem('emailAddress');
		console.log(tigerSharkConstraintTacker.weekDate);
		if (this.validateFilter(tigerSharkConstraintTacker)) {
			// tslint:disable-next-line:max-line-length
			this.tigersharkConstraintTrackerService.generateReportForSupplierUpdate(tigerSharkConstraintTacker).subscribe((data) => {
				this.fileFromDatabase = data;
				this.spinnerService.show();
				ExcelUtility.load(this.fileFromDatabase).then((w) => {
					if (!this.isFordUser) {
						this.isSubmitVisible = true;
					}
					this.spreadsheet.workbook = w;
					this.spreadsheet.isRenamingWorksheet = false;
					this.spreadsheet.allowAddWorksheet = false;
					this.spreadsheet.allowDeleteWorksheet = false;
					this.spinnerService.hide();
					console.log('Excel Loaded');
				});
			});
		} else {
			this.pushMessage('error', 'Error', 'Please Complete the filter.');
		}
	}

	// downloadData() {
	// 	const tigerSharkConstraintTacker = new TigerSharkConstraintTracker();
	//
	// 	tigerSharkConstraintTacker.constraintId = this.selectedConstraintId;
	// 	tigerSharkConstraintTacker.actualBusinessUnit = this.selectedActualBusinessUnit;
	// 	tigerSharkConstraintTacker.supplierSiteCode = this.selectedSupplierSiteCode;
	// 	tigerSharkConstraintTacker.plantCode = this.selectedPlantCode;
	// 	tigerSharkConstraintTacker.partNumber = this.selectedPartNumber;
	// 	tigerSharkConstraintTacker.weekNumber = this.getCurrentWeekNumber();
	// 	tigerSharkConstraintTacker.weekDate = this.getMondayFromCurrentDate();
	// 	tigerSharkConstraintTacker.supplierMailId = sessionStorage.getItem('emailAddress');
	// 	if (this.validateFilter(tigerSharkConstraintTacker)) {
	// 		this.tigersharkConstraintTrackerService.generateReportForSupplierUpdate(tigerSharkConstraintTacker).subscribe((data) => {
	// 			this.tigersharkDashboardDownloadService.downLoadFile(data, 'text/csv', tigerSharkConstraintTacker.constraintId);
	// 		});
	// 	} else {
	// 		this.pushMessage('error', 'Error', 'Please Complete the filter.');
	// 	}
	// }

	private validateFilter(tigerSharkConstraintTracker: TigerSharkConstraintTracker): boolean {
		let isValidFilter = false;
		if (!(tigerSharkConstraintTracker.constraintId) &&
			tigerSharkConstraintTracker.actualBusinessUnit && tigerSharkConstraintTracker.actualBusinessUnit !== null &&
			tigerSharkConstraintTracker.plantCode && tigerSharkConstraintTracker.plantCode !== null &&
			tigerSharkConstraintTracker.partNumber && tigerSharkConstraintTracker.partNumber !== null) {
			isValidFilter = true;
		}
		return isValidFilter;
	}

	isNotDigit(value: number) {
		return isNaN(value);
	}

	confirm() {
		this.confirmationService.confirm({
			message: 'Are you sure you want to confirm ?',
			header: 'Confirm !',
			accept: () => {
				console.log('Acceoted');
			},
			reject: () => {
				console.log('Rejected');
			}
		});
	}

	saveSpreadsheet() {
		this.spinnerService.show();
		const opt = new WorkbookSaveOptions();
		opt.type = 'blob';
		const workbook = this.spreadsheet.workbook;
		workbook.save(opt, (d) => {
			const fileExt = ExcelUtility.getExtension(workbook.currentFormat);
			const constraintList = [];
			for (const item of this.selectedConstraintIds) {
				constraintList.push(item.name);
			}
			const constraintId = constraintList.join(',');
			// const constraintId = this.selectedConstraintIds[0];
			// tslint:disable-next-line:max-line-length
			this.tigersharkDashboardDownloadService.downLoadFile(d, 'text/csv', constraintId, this.getMondayFromCurrentDate().toString());
			this.tigersharkDashboardDownloadService.upload(d as File).subscribe((data) => {
					this.pushMessage('success', 'success',
						'Data Updated Successfully!');
					this.isSubmitVisible = false;
					this.spreadsheet.workbook = new Workbook();
					// const s: Sheet = null;
					// s.this.spreadsheet.workbook.sheets().add(s);
				},
				error => {
					this.pushDialog('Unable to Update Data, Please try again later');
					// this.pushMessage('error', 'error',
					// 	'Unable to Update Data, Please try again later');
				}
			);
		}, (e) => {
			this.pushDialog('Unable to Update Data, Please try again later');
			// this.pushMessage('error', 'Error', 'Unable to Update Data, Please try again later');
		});
	}

	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 60000
		});
	}

	upload(event, form) {
		for (const file of event.files) {
			this.selectedFiles.push(file);
		}
		this.tigersharkDashboardDownloadService.upload(this.selectedFiles[0]).subscribe((data) => {
				this.pushDialog('Data Updated Successfully!');
				// this.pushMessage('success', 'Success',
				// 	'Data Updated Successfully!');
				form.clear();
				this.selectedFiles.pop();
			},
			error => {
				this.pushDialog('Unable to Update Data, Please try again later');
				// this.pushMessage('error', 'Error', 'Unable to Update Data, Please try again later');
				form.clear();
				this.selectedFiles.pop();
			}
		);
	}

	submitButtonVisibility(event) {
		this.isSubmitVisible = true;
	}

	private fetchConstraintIdsFromEmailV2() {
		this.tigerSharkConstraintTracker = new TigerSharkConstraintTracker();
		// @ts-ignore
		this.tigerSharkConstraintTracker.weekDate = this.datepipe.transform(this.getMondayFromCurrentDate(), 'yyyy-MM-dd');
		this.tigerSharkConstraintTracker.supplierMailId = sessionStorage.getItem('emailAddress');
		this.tigersharkConstraintTrackerService.getConstraintIdsFromEmailV2(this.tigerSharkConstraintTracker).subscribe((data) => {
			this.constraintIds = [];
			this.constraintIds = data;
			if (this.constraintIds.length === 0) {
				this.pushDialog('Constraints for current week are not ready yet.');
				// this.pushMessage('info', 'Info', 'Constraints for current week are not ready yet.');
			}
		});
	}
	onWeekDateChange() {
		const weekDate = this.tigerSharkConstraintForm.get('weekDateCal').value;
		const d = new Date(Date.UTC(weekDate.getFullYear(), weekDate.getMonth(), weekDate.getDate()));
		const dayNum = d.getUTCDay() || 7;
		d.setUTCDate(d.getUTCDate() + 4 - dayNum);
		const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
		// @ts-ignore
		const weekNum = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
		this.tigerSharkConstraintForm.patchValue({weekNumber: weekNum});
		this.constraintIds = [];
		this.businessUnits = [];
		this.supplierSiteCodes = [];
		this.plantCodes = [];
		this.partNumbers = [];
		this.selectedConstraintIds = [];
		this.selectedBusinessUnits = [];
		this.selectedSupplierSiteCodes = [];
		this.selectedPlantCodes = [];
		this.selectedPartNumbers = [];
		this.tigerSharkConstraintTracker = new TigerSharkConstraintTracker();
		// @ts-ignore
		// tslint:disable-next-line:max-line-length
		this.tigerSharkConstraintTracker.weekDate = this.datepipe.transform(this.tigerSharkConstraintForm.controls['weekDateCal'].value, 'yyyy-MM-dd');
		this.tigersharkDashboardDownloadService.getActiveConstraintsForWeek(this.tigerSharkConstraintTracker).subscribe(data => {
			this.constraintIds = data;
			if (this.constraintIds.length === 0) {
				const date1 = new Date(this.datepipe.transform(this.tigerSharkConstraintTracker.weekDate, 'MM.dd.yyyy'));
				const date2 = new Date(this.datepipe.transform(this.getMondayFromCurrentDate(), 'MM.dd.yyyy'));
				if (date1.getTime() < date2.getTime()) {
					this.pushDialog('No Constraints were active for the selected Week.');
					// this.pushMessage('info', 'Info', 'No Constraints were active for the selected Week.');
				} else if (date1.getTime() > date2.getTime()) {
					this.pushDialog('Constraints for Selected Week are not ready yet.');
					// this.pushMessage('info', 'Info', 'Constraints for Selected Week are not ready yet.');
				} else {
					this.pushDialog('Constraints for Current Week are not ready yet.');
					// this.pushMessage('info', 'Info', 'Constraints for Current Week are not ready yet.');
				}
			}
		});
	}
	private fetchAllConstraintIdsFordFordUserV2() {
		this.tigerSharkConstraintTracker = new TigerSharkConstraintTracker();
		// @ts-ignore
		this.tigerSharkConstraintTracker.weekDate = this.datepipe.transform(this.getMondayFromCurrentDate(), 'yyyy-MM-dd');
		this.tigersharkDashboardDownloadService.getActiveConstraintsForWeek(this.tigerSharkConstraintTracker).subscribe(data => {
			this.constraintIds = [];
			this.constraintIds = data;
			if (this.constraintIds.length === 0) {
				this.pushMessage('info', 'Info', 'Constraints for Current Week are not ready yet.');
			}
		});
	}
	onConstraintIdChange(event: any) {
		this.selectedConstraintIds = event.value;
		this.businessUnits = [];
		this.supplierSiteCodes = [];
		this.plantCodes = [];
		this.partNumbers = [];
		this.versionNumbers = [];
		this.selectedBusinessUnits = [];
		this.selectedSupplierSiteCodes = [];
		this.selectedPlantCodes = [];
		this.selectedPartNumbers = [];
		this.selectedVersionNumbers = [];

		if (this.selectedConstraintIds.length > 0) {
			const constraintList = [];
			for (const item of this.selectedConstraintIds) {
				constraintList.push(item.name);
			}
			this.tigerSharkConstraintTracker.constraintIdString = constraintList.join(';!');
			this.tigerSharkConstraintTracker.constraintId = constraintList[0];
			this.tigersharkDashboardDownloadService.getActualBusinessUnits(this.tigerSharkConstraintTracker).subscribe(data => {
				this.businessUnits = data;
			});
			this.tigersharkDashboardDownloadService.getSupplierFileVersionNumbers(this.tigerSharkConstraintTracker).subscribe(data => {
				this.versionNumbers = data;
			});
		}
	}

	onBUChange(event: any) {
		this.selectedBusinessUnits = event.value;
		this.supplierSiteCodes = [];
		this.plantCodes = [];
		this.partNumbers = [];
		this.selectedSupplierSiteCodes = [];
		this.selectedPlantCodes = [];
		this.selectedPartNumbers = [];

		if (this.selectedBusinessUnits.length > 0) {
			const actualBuList = [];
			for (const item of this.selectedBusinessUnits) {
				actualBuList.push(item.name);
			}
			this.tigerSharkConstraintTracker.actualBusinessUnit = actualBuList.join(';!');
			this.tigersharkConstraintTrackerService.getSupplierSiteCodes(this.tigerSharkConstraintTracker).subscribe(data => {
				this.supplierSiteCodes = data;
			});
		}
	}

	onSupplierSiteCodeChange(event: any) {
		this.selectedSupplierSiteCodes = event.value;
		this.plantCodes = [];
		this.partNumbers = [];
		this.selectedPlantCodes = [];
		this.selectedPartNumbers = [];
		if (this.selectedSupplierSiteCodes.length > 0) {
			const supplierSiteCodeList = [];
			for (const item of this.selectedSupplierSiteCodes) {
				supplierSiteCodeList.push(item.name);
			}
			this.tigerSharkConstraintTracker.supplierSiteCode = supplierSiteCodeList.join(';!');
			this.tigersharkConstraintTrackerService.getPlantCodes(this.tigerSharkConstraintTracker).subscribe(data => {
				this.plantCodes = data;
			});
		}
	}

	onPlantCodeChange(event: any) {
		this.selectedPlantCodes = event.value;
		this.partNumbers = [];
		this.selectedPartNumbers = [];
		if (this.selectedPlantCodes.length > 0) {
			const plantCodesList = [];
			for (const item of this.selectedPlantCodes) {
				plantCodesList.push(item.name);
			}
			this.tigerSharkConstraintTracker.plantCode = plantCodesList.join(';!');
			this.tigersharkConstraintTrackerService.getPartNumbers(this.tigerSharkConstraintTracker).subscribe(data => {
				this.partNumbers = data;
			});
		}
	}

	onPartNumberChange(event: any) {
		this.selectedPartNumbers = event.value;
		if (this.selectedPartNumbers.length > 0) {
			const partNumbersList = [];
			for (const item of this.selectedPartNumbers) {
				partNumbersList.push(item.name);
			}
			this.tigerSharkConstraintTracker.partNumber = partNumbersList.join(';!');
		}
	}

	loadDataV2() {
		const tigerSharkConstraintTracker = new TigerSharkConstraintTracker();
		if (this.isFordUser) {
			// @ts-ignore
			// tslint:disable-next-line:max-line-length
			tigerSharkConstraintTracker.weekDate = this.datepipe.transform(this.tigerSharkConstraintForm.controls['weekDateCal'].value, 'yyyy-MM-dd');
		} else {
			// @ts-ignore
			// tslint:disable-next-line:max-line-length
			tigerSharkConstraintTracker.weekDate = this.datepipe.transform(this.getMondayFromCurrentDate(), 'yyyy-MM-dd');
		}
		if (tigerSharkConstraintTracker.weekDate !== null) {
			if (this.constraintIds.length > 0) {
				if (this.selectedConstraintIds.length > 0) {
					if (this.selectedConstraintIds.length > 0) {
						const constraintList = [];
						for (const item of this.selectedConstraintIds) {
							constraintList.push(item.name);
						}
						tigerSharkConstraintTracker.constraintIdString = constraintList.join(';!');
					}
					if (this.selectedBusinessUnits.length > 0) {
						const actualBuList = [];
						for (const item of this.selectedBusinessUnits) {
							actualBuList.push(item.name);
						}
						tigerSharkConstraintTracker.actualBusinessUnit = actualBuList.join(';!');
					}
					if (this.selectedSupplierSiteCodes.length > 0) {
						const supplierSiteCodeList = [];
						for (const item of this.selectedSupplierSiteCodes) {
							supplierSiteCodeList.push(item.name);
						}
						tigerSharkConstraintTracker.supplierSiteCode = supplierSiteCodeList.join(';!');
					}
					if (this.selectedPlantCodes.length > 0) {
						const plantCodesList = [];
						for (const item of this.selectedPlantCodes) {
							plantCodesList.push(item.name);
						}
						tigerSharkConstraintTracker.plantCode = plantCodesList.join(';!');
					}
					if (this.selectedPartNumbers.length > 0) {
						const partNumbersList = [];
						for (const item of this.selectedPartNumbers) {
							partNumbersList.push(item.name);
						}
						tigerSharkConstraintTracker.partNumber = partNumbersList.join(';!');
					}
					tigerSharkConstraintTracker.supplierMailId = sessionStorage.getItem('emailAddress');
					console.log(tigerSharkConstraintTracker);
					// return tigerSharkConstraintTracker;
					this.tigersharkConstraintTrackerService.generateWppReport(tigerSharkConstraintTracker).subscribe((data) => {
						this.fileFromDatabase = data;
						this.spinnerService.show();
						ExcelUtility.load(this.fileFromDatabase).then((w) => {
							if (!this.isFordUser) {
								this.isSubmitVisible = true;
							}
							this.spreadsheet.workbook = w;
							this.spreadsheet.isRenamingWorksheet = false;
							this.spreadsheet.allowAddWorksheet = false;
							this.spreadsheet.allowDeleteWorksheet = false;
							this.spinnerService.hide();
							console.log('Excel Loaded');
							this.triggerSubtotalFormula();
						});
					});
				} else {
					this.pushMessage('error', 'Error', 'Please select any Constraint Id');
				}
			} else {
				this.pushDialog('Constraints for the specific Week are not ready yet.');
				// this.pushMessage('info', 'Info', 'Constraints for Current Week are not ready yet.');
			}
		} else {
			this.pushMessage('error', 'Error', 'Please select any Week Date');
		}
	}
	triggerSubtotalFormula() {
		console.log('Triggering Subtotal Formula');
		this.spreadsheet.workbook.worksheets(0).rows(5).cells(23).value = this.spreadsheet.workbook.worksheets(0).rows(5).cells(23).value + 1;
		this.spreadsheet.workbook.worksheets(0).rows(5).cells(23).value = this.spreadsheet.workbook.worksheets(0).rows(5).cells(23).value - 1;
	}

	downloadDataV2() {
		const tigerSharkConstraintTracker = new TigerSharkConstraintTracker();
		if (this.isFordUser) {
			// @ts-ignore
			// tslint:disable-next-line:max-line-length
			tigerSharkConstraintTracker.weekDate = this.datepipe.transform(this.tigerSharkConstraintForm.controls['weekDateCal'].value, 'yyyy-MM-dd');
		} else {
			// @ts-ignore
			// tslint:disable-next-line:max-line-length
			tigerSharkConstraintTracker.weekDate = this.datepipe.transform(this.getMondayFromCurrentDate(), 'yyyy-MM-dd');
		}
		if (tigerSharkConstraintTracker.weekDate !== null) {
			if (this.constraintIds.length > 0) {
				if (this.selectedConstraintIds.length > 0) {
					if (this.selectedConstraintIds.length > 0) {
						const constraintList = [];
						for (const item of this.selectedConstraintIds) {
							constraintList.push(item.name);
						}
						tigerSharkConstraintTracker.constraintIdString = constraintList.join(';!');
					}
					if (this.selectedBusinessUnits.length > 0) {
						const actualBuList = [];
						for (const item of this.selectedBusinessUnits) {
							actualBuList.push(item.name);
						}
						tigerSharkConstraintTracker.actualBusinessUnit = actualBuList.join(';!');
					}
					if (this.selectedSupplierSiteCodes.length > 0) {
						const supplierSiteCodeList = [];
						for (const item of this.selectedSupplierSiteCodes) {
							supplierSiteCodeList.push(item.name);
						}
						tigerSharkConstraintTracker.supplierSiteCode = supplierSiteCodeList.join(';!');
					}
					if (this.selectedPlantCodes.length > 0) {
						const plantCodesList = [];
						for (const item of this.selectedPlantCodes) {
							plantCodesList.push(item.name);
						}
						tigerSharkConstraintTracker.plantCode = plantCodesList.join(';!');
					}
					if (this.selectedPartNumbers.length > 0) {
						const partNumbersList = [];
						for (const item of this.selectedPartNumbers) {
							partNumbersList.push(item.name);
						}
						tigerSharkConstraintTracker.partNumber = partNumbersList.join(';!');
					}
					tigerSharkConstraintTracker.supplierMailId = sessionStorage.getItem('emailAddress');
					console.log(tigerSharkConstraintTracker);
					// return tigerSharkConstraintTracker;
					this.tigersharkConstraintTrackerService.generateWppReport(tigerSharkConstraintTracker).subscribe((data) => {
						// tslint:disable-next-line:max-line-length
						this.tigersharkDashboardDownloadService.downLoadFile(data, 'text/csv', tigerSharkConstraintTracker.constraintIdString.toString(), tigerSharkConstraintTracker.weekDate.toString());
					});
				} else {
					this.pushMessage('error', 'Error', 'Please select any Constraint Id');
				}
			} else {
				this.pushDialog('Constraints for the specific Week are not ready yet.');
				// this.pushMessage('info', 'Info', 'Constraints for Current Week are not ready yet.');
			}
		} else {
				this.pushMessage('error', 'Error', 'Please select any Week Date');
			}
	}

	// loadPreviousWeekData() {
	// 	const tigerSharkConstraintTracker = new TigerSharkConstraintTracker();
	// 	if (this.isFordUser) {
	// 		// @ts-ignore
	// tslint:disable-next-line:max-line-length
	// 		tigerSharkConstraintTracker.weekDate = this.datepipe.transform(this.tigerSharkConstraintForm.controls['weekDateCal'].value, 'yyyy-MM-dd');
	// 	} else {
	// 		// @ts-ignore
	// 		// tslint:disable-next-line:max-line-length
	// 		tigerSharkConstraintTracker.weekDate = this.datepipe.transform(this.getMondayFromCurrentDate(), 'yyyy-MM-dd');
	// 	}
	// 	if (this.constraintIds.length > 0) {
	// 		if (this.selectedConstraintIds.length > 0) {
	// 			if (this.selectedConstraintIds.length > 0) {
	// 				const constraintList = [];
	// 				for (const item of this.selectedConstraintIds) {
	// 					constraintList.push(item.name);
	// 				}
	// 				tigerSharkConstraintTracker.constraintIdString = constraintList.join(';!');
	// 			}
	// 			if (this.selectedBusinessUnits.length > 0) {
	// 				const actualBuList = [];
	// 				for (const item of this.selectedBusinessUnits) {
	// 					actualBuList.push(item.name);
	// 				}
	// 				tigerSharkConstraintTracker.actualBusinessUnit = actualBuList.join(';!');
	// 			}
	// 			if (this.selectedSupplierSiteCodes.length > 0) {
	// 				const supplierSiteCodeList = [];
	// 				for (const item of this.selectedSupplierSiteCodes) {
	// 					supplierSiteCodeList.push(item.name);
	// 				}
	// 				tigerSharkConstraintTracker.supplierSiteCode = supplierSiteCodeList.join(';!');
	// 			}
	// 			if (this.selectedPlantCodes.length > 0) {
	// 				const plantCodesList = [];
	// 				for (const item of this.selectedPlantCodes) {
	// 					plantCodesList.push(item.name);
	// 				}
	// 				tigerSharkConstraintTracker.plantCode = plantCodesList.join(';!');
	// 			}
	// 			if (this.selectedPartNumbers.length > 0) {
	// 				const partNumbersList = [];
	// 				for (const item of this.selectedPartNumbers) {
	// 					partNumbersList.push(item.name);
	// 				}
	// 				tigerSharkConstraintTracker.partNumber = partNumbersList.join(';!');
	// 			}
	// 			tigerSharkConstraintTracker.supplierMailId = sessionStorage.getItem('emailAddress');
	// 			console.log(tigerSharkConstraintTracker);
	// 			// return tigerSharkConstraintTracker;
	// 			this.tigersharkConstraintTrackerService.generatePreviousWeekWppReport(tigerSharkConstraintTracker).subscribe((data) => {
	// 				this.fileFromDatabase = data;
	// 				this.spinnerService.show();
	// 				ExcelUtility.load(this.fileFromDatabase).then((w) => {
	// 					if (!this.isFordUser) {
	// 						this.isSubmitVisible = true;
	// 					}
	// 					this.spreadsheet.workbook = w;
	// 					this.spreadsheet.isRenamingWorksheet = false;
	// 					this.spreadsheet.allowAddWorksheet = false;
	// 					this.spreadsheet.allowDeleteWorksheet = false;
	// 					this.spinnerService.hide();
	// 					console.log('Excel Loaded');
	// 				});
	// 			});
	// 		} else {
	// 			this.pushMessage('error', 'Error', 'Please select any Constraint Id');
	// 		}
	// 	}
	// 	// else {
	// 	// 	this.pushMessage('info', 'Info', 'Constraints for Current Week are not ready yet.');
	// 	// }
	// }
	// downloadPreviousData() {
	// 	const tigerSharkConstraintTracker = new TigerSharkConstraintTracker();
	// 	if (this.isFordUser) {
	// 		// @ts-ignore
	// tslint:disable-next-line:max-line-length
	// 		tigerSharkConstraintTracker.weekDate = this.datepipe.transform(this.tigerSharkConstraintForm.controls['weekDateCal'].value, 'yyyy-MM-dd');
	// 	} else {
	// 		// @ts-ignore
	// 		// tslint:disable-next-line:max-line-length
	// 		tigerSharkConstraintTracker.weekDate = this.datepipe.transform(this.getMondayFromCurrentDate(), 'yyyy-MM-dd');
	// 	}
	// 	if (this.constraintIds.length > 0) {
	// 		if (this.selectedConstraintIds.length > 0) {
	// 			if (this.selectedConstraintIds.length > 0) {
	// 				const constraintList = [];
	// 				for (const item of this.selectedConstraintIds) {
	// 					constraintList.push(item.name);
	// 				}
	// 				tigerSharkConstraintTracker.constraintIdString = constraintList.join(';!');
	// 			}
	// 			if (this.selectedBusinessUnits.length > 0) {
	// 				const actualBuList = [];
	// 				for (const item of this.selectedBusinessUnits) {
	// 					actualBuList.push(item.name);
	// 				}
	// 				tigerSharkConstraintTracker.actualBusinessUnit = actualBuList.join(';!');
	// 			}
	// 			if (this.selectedSupplierSiteCodes.length > 0) {
	// 				const supplierSiteCodeList = [];
	// 				for (const item of this.selectedSupplierSiteCodes) {
	// 					supplierSiteCodeList.push(item.name);
	// 				}
	// 				tigerSharkConstraintTracker.supplierSiteCode = supplierSiteCodeList.join(';!');
	// 			}
	// 			if (this.selectedPlantCodes.length > 0) {
	// 				const plantCodesList = [];
	// 				for (const item of this.selectedPlantCodes) {
	// 					plantCodesList.push(item.name);
	// 				}
	// 				tigerSharkConstraintTracker.plantCode = plantCodesList.join(';!');
	// 			}
	// 			if (this.selectedPartNumbers.length > 0) {
	// 				const partNumbersList = [];
	// 				for (const item of this.selectedPartNumbers) {
	// 					partNumbersList.push(item.name);
	// 				}
	// 				tigerSharkConstraintTracker.partNumber = partNumbersList.join(';!');
	// 			}
	// 			tigerSharkConstraintTracker.supplierMailId = sessionStorage.getItem('emailAddress');
	// 			console.log(tigerSharkConstraintTracker);
	// 			// return tigerSharkConstraintTracker;
	// 			this.tigersharkConstraintTrackerService.generatePreviousWeekWppReport(tigerSharkConstraintTracker).subscribe((data) => {
	// 				this.tigersharkDashboardDownloadService.downLoadFile(data, 'text/csv', tigerSharkConstraintTracker.constraintIdString.toString());
	// 			});
	// 		} else {
	// 			this.pushMessage('error', 'Error', 'Please select any Constraint Id');
	// 		}
	// 	}
	// }

	onVersionNumberChange(event: any) {
		this.selectedVersionNumbers = event.value;
		if (this.selectedVersionNumbers.length > 0) {
			const versionNumberList = [];
			for (const item of this.selectedVersionNumbers) {
				versionNumberList.push(item.code);
			}
			this.tigerSharkConstraintTracker.versionNumber = versionNumberList[0];		}
	}

	downSupplierFile() {
		const tigerSharkConstraintTracker = new TigerSharkConstraintTracker();
		// @ts-ignore
		tigerSharkConstraintTracker.weekDate = this.datepipe.transform(this.tigerSharkConstraintForm.controls['weekDateCal'].value, 'yyyy-MM-dd');
		if (tigerSharkConstraintTracker.weekDate !== null) {
		if (this.constraintIds.length > 0) {
			if (this.selectedConstraintIds.length > 0) {
				if (this.versionNumbers.length > 0) {
					if (this.selectedConstraintIds.length > 0) {
						const constraintIdList = [];
						for (const item of this.selectedConstraintIds) {
							constraintIdList.push(item.name);
						}
						tigerSharkConstraintTracker.constraintId = constraintIdList[0];
					}
					if (this.selectedVersionNumbers.length > 0) {
						const versionNumberList = [];
						for (const item of this.selectedVersionNumbers) {
							versionNumberList.push(item.code);
						}
						tigerSharkConstraintTracker.versionNumber = versionNumberList[0];
						console.log('downloadSupplierFile Request payload : ' + tigerSharkConstraintTracker);
						this.tigersharkDashboardDownloadService.downloadSupplierFile(tigerSharkConstraintTracker);
					} else {
						this.pushMessage('error', 'Error', 'Please select any File Version');
					}
				} else {
					this.pushMessage('error', 'Error', 'No files are currently available for the selected Constraint Id.');
				}
			} else {
				this.pushMessage('error', 'Error', 'Please select any Constraint Id');
			}
		} else {
			this.pushDialog('Please check if constraints are available for the selected Week Date.');
			// this.pushMessage('info', 'Info', 'Please check if constraints are available for the selected Week Date');
		}
	} else {
	this.pushMessage('error', 'Error', 'Please select any Week Date');
	}
	}

	pushDialog(dialogBody: any) {
		this.ref = this.dialogService.open(CustomDialogComponent, {
			header: 'WPP Constraint Tracker',
			footer: '',
			data: dialogBody,
			width: '50%',
			contentStyle: { 'max-height': '1000px',
				overflow: 'auto' },
			baseZIndex: 10000,
		});
	}
}
