import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonModule} from 'primeng/button';
import {
	FormsModule,
	ReactiveFormsModule
} from '@angular/forms';
import {DropdownModule} from 'primeng/dropdown';
import {MessageModule} from 'primeng/message';
import {CalendarModule} from 'primeng/calendar';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ListboxModule} from 'primeng/listbox';
import {CheckboxModule} from 'primeng/checkbox';
import {AccordionModule} from 'primeng/accordion';
import {TableModule} from 'primeng/table';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputTextModule} from 'primeng/inputtext';
import {ValueStreamMapRoutingModule} from './value-stream-map-routing.module';
import {
	ValueStreamDataCollectionComponent
} from './value-stream-data-collection-admin/value-stream-data-collection.component';
import {
	ValueStreamDataCollectionStepTwoComponent
} from './value-stream-data-collection-two/value-stream-data-collection-two.component';
import {
	ValueStreamDataCollectionUserComponent
} from './value-stream-data-collection-user/value-stream-data-collection-user.component';
import {FileUploadModule} from 'primeng/fileupload';
import {IgxSpreadsheetModule} from 'igniteui-angular-spreadsheet';
// tslint:disable-next-line:max-line-length
import {
	ValueStreamDataAdminColumnAccessComponent
} from './value-stream-data-admin-column-access/value-stream-data-admin-column-access.component';
import {MultiSelectModule} from 'primeng/multiselect';
import {
	ValueStreamAdminColumnTemplateComponent
} from './value-stream-admin-column-template/value-stream-admin-column-template.component';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogModule} from 'primeng/dialog';
import {ValueStreamUserReportComponent} from './value-stream-user-report/value-stream-user-report.component';
import {ValueStreamAuditReportComponent} from './value-stream-audit-report/value-stream-audit-report.component';
import {
	ValueStreamDataReportApprovalComponent
} from './value-stream-data-report-approval/value-stream-data-report-approval.component';
import { VsmUserTemplateDataUpdateComponent } from './vsm-user-template-data-update/vsm-user-template-data-update.component';

@NgModule({
	// tslint:disable-next-line:max-line-length
	declarations: [ValueStreamDataCollectionComponent,
		ValueStreamDataCollectionStepTwoComponent,
		ValueStreamDataCollectionUserComponent,
		ValueStreamDataAdminColumnAccessComponent,
		ValueStreamAdminColumnTemplateComponent,
		ValueStreamUserReportComponent,
		ValueStreamAuditReportComponent,
		ValueStreamDataReportApprovalComponent,
		VsmUserTemplateDataUpdateComponent],
	imports: [
		CommonModule,
		ButtonModule,
		ValueStreamMapRoutingModule,
		ReactiveFormsModule,
		ConfirmDialogModule,
		DropdownModule,
		MessageModule,
		CalendarModule,
		MultiSelectModule,
		ReactiveFormsModule,
		AutoCompleteModule,
		FormsModule,
		ListboxModule,
		CheckboxModule,
		AccordionModule,
		TableModule,
		InputTextareaModule,
		InputTextModule,
		FileUploadModule,
		IgxSpreadsheetModule,
		DialogModule
	]
})
export class ValueStreamMapModule {
}
