import {
	Component,
	OnInit
} from '@angular/core';
import {SupplierService} from '../../home/services/supplier.service';

@Component({
	selector: 'no-access-for-n-tier',
	templateUrl: './no-access-for-n-tier.component.html',
	styleUrls: ['./no-access-for-n-tier.component.scss']
})
export class NoAccessForNTierComponent implements OnInit {

	businessName: any;
	constructor() {
	}

	ngOnInit() {
		this.businessName = sessionStorage.getItem('Admin');
	}

}
