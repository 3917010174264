/**This file contains a constant object that has properties that are necessary
 * for the build found in the `production` configuration in `angular.json`.
 */
export const environment = {
	production: true,
	tokenUrl: 'https://corp.sts.ford.com/adfs/oauth2/authorize',
	clientId: 'urn:icantvi:clientid:web_icantvisite:prod',
	resource: 'urn:icantvi:resource:web_icantvisite:prod',
	apiUrl: 'https://www.online-supp-collab.ford.com/api/v1/'
};
