import {NgModule} from '@angular/core';
import {
	RouterModule,
	Routes
} from '@angular/router';
import {AuthGuardService} from '../../oauth/auth-guard.service';

const routes: Routes = [

];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ValueStreamMapRoutingModule {
}
