import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {TigerSharkConstraintTracker} from '../../tiger-shark-constraint-tracker/model/tiger-shark-constraint-tracker';
import {HttpClient} from '@angular/common/http';
import {ClearToBuildTracker} from '../model/clear-to-build-tracker';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {DatePipe} from '@angular/common';
import {ClearToBuildAdmin} from '../model/clear-to-build-admin';

@Injectable({
	providedIn: 'root'
})
export class ClearToBuildTrackerService {

	private CLEAR_TO_BUILD_TRACKER_ENDPOINT = environment.apiUrl + 'clearToBuildTracker';
	private CLEAR_TO_BUILD_ADMIN_ENDPOINT = environment.apiUrl + 'clearToBuildAdmin';

	constructor(private http: HttpClient, private spinnerService: SpinnerService, private datePipe: DatePipe) {
	}

	public getConstraintIdsFromEmail(email: string): Observable<any> {
		return this.http.get(this.CLEAR_TO_BUILD_TRACKER_ENDPOINT + `/getConstraintIdsFromEmail/${email}`, {responseType: 'json'});
	}

	public getAllConstraintIdsForFordUser(): Observable<any> {
		return this.http.get(this.CLEAR_TO_BUILD_TRACKER_ENDPOINT + `/getAllConstraintIdsForFordUser`, {responseType: 'json'});
	}

	public getActualBusinessUnitFromConstraintIds(clearToBuildTracker: ClearToBuildTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.CLEAR_TO_BUILD_TRACKER_ENDPOINT + `/getActualBusinessUnitFromConstraintId`, clearToBuildTracker, {responseType: 'json'});
	}

	// tslint:disable-next-line:max-line-length
	public getSupplierSiteCodeFromConstraintIdsAndActualBusinessUnit(clearToBuildTracker: ClearToBuildTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.CLEAR_TO_BUILD_TRACKER_ENDPOINT + `/getSupplierSiteCodeFromConstraintIdAndActualBusinessUnit`, clearToBuildTracker, {responseType: 'json'});
	}

	// tslint:disable-next-line:max-line-length
	public getPlantCodesFromConstraintIdsAndActualBusinessUnitAndSupplierSiteCode(clearToBuildTracker: ClearToBuildTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.CLEAR_TO_BUILD_TRACKER_ENDPOINT + `/getPlantCodesFromConstraintIdAndActualBusinessUnitAndSupplierSiteCode`, clearToBuildTracker, {responseType: 'json'});
	}

	// tslint:disable-next-line:max-line-length
	public getPartNumbersFromConstraintIdAndActualBusinessUnitAndSupplierSiteCodeAndPlantCode(clearToBuildTracker: ClearToBuildTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.CLEAR_TO_BUILD_TRACKER_ENDPOINT + `/getPartNumbersFromConstraintIdAndActualBusinessUnitAndSupplierSiteCodeAndPlantCode`, clearToBuildTracker, {responseType: 'json'});
	}

	generateReportForSupplierUpdate(clearToBuildTracker: ClearToBuildTracker) {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.CLEAR_TO_BUILD_TRACKER_ENDPOINT + `/generateReportForSupplierUpdate`, clearToBuildTracker, {responseType: 'blob'});
	}

	public getCtbIdsFromEmailAndWeekDate(clearToBuildTracker: ClearToBuildTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.CLEAR_TO_BUILD_TRACKER_ENDPOINT + `/getCtbIdsFromEmailAndWeekDate`, clearToBuildTracker, {responseType: 'json'});
	}

	getSupplierSiteCodes(clearToBuildTracker: ClearToBuildTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.CLEAR_TO_BUILD_TRACKER_ENDPOINT + `/getSupplierSiteCodes`, clearToBuildTracker, {responseType: 'json'});
	}

	getPlantCodes(clearToBuildTracker: ClearToBuildTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.CLEAR_TO_BUILD_TRACKER_ENDPOINT + `/getPlantCodes`, clearToBuildTracker, {responseType: 'json'});
	}

	getPartNumbers(clearToBuildTracker: ClearToBuildTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.CLEAR_TO_BUILD_TRACKER_ENDPOINT + `/getPartNumbers`, clearToBuildTracker, {responseType: 'json'});
	}

	generateCTBReport(clearToBuildTracker: ClearToBuildTracker) {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.CLEAR_TO_BUILD_TRACKER_ENDPOINT + `/generateCtbReport`, clearToBuildTracker, {responseType: 'blob'});
	}

	getActualBusinessUnits(clearToBuildTracker: ClearToBuildTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.CLEAR_TO_BUILD_TRACKER_ENDPOINT + `/getActualBusinessUnits`, clearToBuildTracker, {responseType: 'json'});
	}

	downLoadFile(data: any, type: string, constraintId: string, weekDate: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		this.prepareFileName(constraintId, weekDate).subscribe((response: any) => {
			anchor.download = response.fileName + '_' + this.datePipe.transform(weekDate, 'dd-MM-yyyy') + '.xlsx';
			anchor.href = url;
			anchor.click();
		});
	}

	downloadSupplierCopy(data: any, type: string, constraintId: string, weekDate: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		this.prepareFileName(constraintId, weekDate).subscribe((response: any) => {
			this.spinnerService.show();
			anchor.download = response.fileName + '_' + this.datePipe.transform(weekDate, 'dd-MM-yyyy') + '.xlsx';
			anchor.href = url;
			anchor.click();
		});
	}

	fordUserUpload(fileToUpload: File, weekDate: string, clearToBuildId: string): Observable<any> {
		const endpoint = this.CLEAR_TO_BUILD_TRACKER_ENDPOINT + '/fordUserUpload';
		const formData: FormData = new FormData();
		formData.append('File', fileToUpload);
		formData.append('weekDate', weekDate);
		formData.append('ctbId', clearToBuildId);
		return this.http.post(endpoint, formData);
	}

	supplierUpload(fileToUpload: File, supplierEmailId: string): Observable<any> {
		const endpoint = this.CLEAR_TO_BUILD_TRACKER_ENDPOINT + '/supplierUpload';
		const formData: FormData = new FormData();
		formData.append('File', fileToUpload);
		formData.append('EmailAddress', supplierEmailId);
		return this.http.post(endpoint, formData);
	}

	public getActiveConstraintsForWeek(clearToBuildTracker: ClearToBuildTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.CLEAR_TO_BUILD_TRACKER_ENDPOINT + `/getActiveCtbIdsForWeek`, clearToBuildTracker, {responseType: 'json'});
	}

	getSupplierFileVersionNumbers(clearToBuildTracker: ClearToBuildTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.CLEAR_TO_BUILD_TRACKER_ENDPOINT + `/getVersionNumbers`, clearToBuildTracker, {responseType: 'json'});
	}
	checkFordFileExistence(clearToBuildTracker: ClearToBuildTracker): Observable<boolean>  {
		return this.http.post<boolean>(this.CLEAR_TO_BUILD_TRACKER_ENDPOINT + `/checkFordFileExistence`, clearToBuildTracker);
	}
	downloadFordFile(clearToBuildTracker: ClearToBuildTracker) {
		this.http.post(this.CLEAR_TO_BUILD_TRACKER_ENDPOINT + `/downloadFordFile`, clearToBuildTracker, {responseType: 'blob'})
			.subscribe((response) => {
				// tslint:disable-next-line:max-line-length
				this.downLoadFile(response, 'text/csv', clearToBuildTracker.clearToBuildId.toString(), clearToBuildTracker.weekDate.toString());
				this.spinnerService.hide();
			});
	}
	downloadSupplierFile(clearToBuildTracker: ClearToBuildTracker) {

		this.http.post(this.CLEAR_TO_BUILD_TRACKER_ENDPOINT + `/downloadSupplierFile`, clearToBuildTracker, {responseType: 'blob'})
			.subscribe((response) => {
				// tslint:disable-next-line:max-line-length
				this.downLoadFile3(response, 'text/csv', clearToBuildTracker.clearToBuildId.toString(), clearToBuildTracker.weekDate.toString(), clearToBuildTracker.versionNumber);
				this.spinnerService.hide();
			});
	}

	downLoadFile3(data: any, type: string, constraintId: string, weekDate: string, versionNumber: number) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		this.prepareFileName(constraintId, weekDate).subscribe((response: any) => {
			anchor.download = response.fileName + '_' + this.datePipe.transform(weekDate, 'dd-MM-yyyy') + '_V' + versionNumber + '.xlsx';
			anchor.href = url;
			anchor.click();
		});
	}
	getAllCtbIds(): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.get(this.CLEAR_TO_BUILD_TRACKER_ENDPOINT + `/getAllCtbIds`, {responseType: 'json'});
	}

	findLatestWeekAvailable(emailAddress: string) {
		return this.http.get(this.CLEAR_TO_BUILD_TRACKER_ENDPOINT + `/findLatestWeekAvailable/${emailAddress}`, {responseType: 'json'});
	}

	findCtbIdsFromEmailAndWeekDate(clearToBuildTracker: ClearToBuildTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.CLEAR_TO_BUILD_TRACKER_ENDPOINT + `/getCtbIdsFromEmailAndWeekDate`, clearToBuildTracker, {responseType: 'json'});
	}
	triggerManualEmail(clearToBuildTracker: ClearToBuildTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.CLEAR_TO_BUILD_TRACKER_ENDPOINT + `/triggerManualEmail`, clearToBuildTracker, {responseType: 'json'});
	}

	getActiveCtbIdsFromEmailId(clearToBuildAdmin: ClearToBuildAdmin): Observable<any> {
		return this.http.post(this.CLEAR_TO_BUILD_ADMIN_ENDPOINT + `/getActiveCtbIdsFromEmailId`, clearToBuildAdmin, {responseType: 'json'});
	}

	getCtbIdsFromWeekDateAndEmailId(clearToBuildAdmin: ClearToBuildAdmin): Observable<any> {
		return this.http.post(this.CLEAR_TO_BUILD_ADMIN_ENDPOINT + `/getCtbIdsFromWeekDateAndEmailId`, clearToBuildAdmin, {responseType: 'json'});
	}
	submitQuestionaire(objectData: any): Observable<any> {
		return this.http.patch(this.CLEAR_TO_BUILD_ADMIN_ENDPOINT + '/addCtbNineQuestions', objectData, {responseType: 'json'});
	}
	prepareFileName(ctbId: string, weekDate: string) {
		return this.http.get(this.CLEAR_TO_BUILD_ADMIN_ENDPOINT + `/prepareFileName/${ctbId}/${weekDate}`, {responseType: 'json'});
	}
}
