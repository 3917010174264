export class DropDown {
	id: number;
	columnName: string;
	dropDownValue: string;
	createdBy: string;
	createdDate: Date;
	updatedBy: string;
	updatedDate: Date;
	constructor(dropDown: DropDown) {
		Object.assign(this, dropDown);
	}
}
/* tslint:disable */
export const defaultDropDown = {
	'id': 0,
	'columnName': '',
	'dropDownValue': '',
	'createdBy': '',
	'createdDate': null,
	'updatedBy': '',
	'updatedDate': null
};
