import {Component, OnInit} from '@angular/core';
import {T2VisibilityService} from '../services/t2-visibility.service';
import {Router} from "@angular/router";

@Component({
	selector: 't2-visibility-help-tab',
	templateUrl: './t2-visibility-help-tab.component.html',
	styleUrls: ['./t2-visibility-help-tab.component.scss']
})
export class T2VisibilityHelpTabComponent implements OnInit {

	constructor(private t2VisibilityService: T2VisibilityService, private router: Router) {
	}

	ngOnInit() {
		this.router.navigate(['/']);

		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
			return;
		} else if (sessionStorage.getItem('uid').includes('-')) {
			this.router.navigate(['/']);
			return;
		}
	}

	download(contentType: string, num: number) {
		if (num === 1) {
			this.t2VisibilityService.helpEndPointDownload(num).subscribe((data) => {
				this.t2VisibilityService.downloadFileMp4(data, contentType, 'Tier 2 Visibility - Video');
			});
		} else if (num === 6) {
			this.t2VisibilityService.helpEndPointDownload(num).subscribe((data) => {
				this.t2VisibilityService.downLoadFileDocument(data, contentType, 'Tier 2 Visibility - Supplier Training Document');
			});

		} else if (num === 7) {
			this.t2VisibilityService.helpEndPointDownload(num).subscribe((data) => {
				this.t2VisibilityService.downLoadFileDocument(data, contentType, 'FAQ');
			});

		}
	}
}
