import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'clear-to-build-mpnl-admin',
	templateUrl: './clear-to-build-mpnl-admin.component.html',
	styleUrls: ['./clear-to-build-mpnl-admin.component.scss']
})
export class ClearToBuildMpnlAdminComponent implements OnInit {

	constructor() {
	}

	ngOnInit() {
	}

}
