import {Component, OnInit} from '@angular/core';
import {SustainabilitySurveyService} from '../Servies/sustainability-survey.service';
import {SupplierService} from '../../../home/services/supplier.service';

@Component({
	selector: 'sustainability-survey-help-tab',
	templateUrl: './sustainability-survey-help-tab.component.html',
	styleUrls: ['./sustainability-survey-help-tab.component.scss']
})
export class SustainabilitySurveyHelpTabComponent implements OnInit {

	businessName: string;

	constructor(private supplierService: SupplierService, private sustainabilitySurveyService: SustainabilitySurveyService) {
	}

	ngOnInit() {
		this.businessName = sessionStorage.getItem('Admin');
		if (this.businessName === 'sustainability survey') {
			console.log('sustainability survey');
			this.supplierService.pushSelectedSurvey('sustainability survey');
		} else if (this.businessName === 'Project R') {
			console.log('Project R');
			this.supplierService.pushSelectedSurvey('Project R');
		}
	}

	download(contentType: string, num: number) {
		if (num === 11) {
			this.sustainabilitySurveyService.helpEndPointDownload(num).subscribe((data) => {
				this.sustainabilitySurveyService.downloadFileMp4(data, contentType, 'Sustainability Survey Tutorial - Video');
			});
		} else if (num === 12) {
			this.sustainabilitySurveyService.helpEndPointDownload(num).subscribe((data) => {
				this.sustainabilitySurveyService.downLoadFileDocument(data, contentType, 'Sustainability Survey Training Document');
			});

		} else if (num === 13) {
			this.sustainabilitySurveyService.helpEndPointDownload(num).subscribe((data) => {
				this.sustainabilitySurveyService.downloadFileMp4(data, contentType, 'Non-Microchip Survey Tutorial - Video');
			});
		} else if (num === 18) {
			this.sustainabilitySurveyService.helpEndPointDownload(num).subscribe((data) => {
				this.sustainabilitySurveyService.downloadFileMp4(data, contentType, 'New Part - New VSM - Video');
			});
		} else if (num === 19) {
			this.sustainabilitySurveyService.helpEndPointDownload(num).subscribe((data) => {
				this.sustainabilitySurveyService.downloadFileMp4(data, contentType, 'New Part - Existing VSM - Video');
			});
		} else if (num === 20) {
			this.sustainabilitySurveyService.helpEndPointDownload(num).subscribe((data) => {
				this.sustainabilitySurveyService.downloadFileMp4(data, contentType, 'Edit Existing Part - Existing VSM - Video');
			});
		} else if (num === 14) {
			this.sustainabilitySurveyService.helpEndPointDownload(num).subscribe((data) => {
				this.sustainabilitySurveyService.downLoadFileDocument(data, contentType, 'Non-Microchip Survey Training Document');
			});
		} else if (num === 17) {
			this.sustainabilitySurveyService.helpEndPointDownload(num).subscribe((data) => {
				this.sustainabilitySurveyService.downLoadFilePDF(data, contentType, 'Non-Microchip Survey Training Instructions');
			});
		} else if (num === 21) {
			this.sustainabilitySurveyService.helpEndPointDownload(num).subscribe((data) => {
				this.sustainabilitySurveyService.downloadFileMp4(data, contentType, 'Non-Microchip Survey Template Dashboard Training');
			});
		}

	}

}
