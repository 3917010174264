import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {environment} from '../../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class SmashedDataService {

	constructor(private http: HttpClient, private spinnerService: SpinnerService) {
	}

	private DASHBOARD_ENDPOINT = environment.apiUrl + 'smashedDataReporting';

	generateSmashedDataInExcel(uid: string) {

		this.http.get(this.DASHBOARD_ENDPOINT + `/generateReport`, {responseType: 'blob'})
			.subscribe((response) => {
				this.downLoadFile(response, 'text/csv');
				this.spinnerService.hide();

			});
	}

	downLoadFile(data: any, type: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = 'Smashed_Data.xlsx';
		anchor.href = url;
		anchor.click();
	}

}
