import {
	Component,
	OnInit
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	Validators
} from '@angular/forms';
import {Option} from '../../../home/model/ntier.option';
import {NTierMasterAdminService} from '../../n-tier-master-admin/service/n-tier-master-admin.service';
import {Router} from '@angular/router';
import {ValueStreamDataService} from '../service/value-stream-data.service';
import {MessageService, ConfirmationService} from 'primeng/api';
import {VsmColumn} from '../model/vsm-column';
import {VsmTemplate} from '../model/vsm-template';
import {SupplierService} from '../../../home/services/supplier.service';

@Component({
	selector: 'value-stream-admin-column-template',
	templateUrl: './value-stream-admin-column-template.component.html',
	styleUrls: ['./value-stream-admin-column-template.component.scss']
})
export class ValueStreamAdminColumnTemplateComponent implements OnInit {
	masterAdminForm: FormGroup;
	options: Option[] = [];
	userLoginId: string;
	columnAccessList: any = [];
	createTemplate = false;
	columnAccess = [];
	viewType = 'cdsid';
	templateList: any[] = [];
	selectedTemplate: any;
	createTemplateBtn = true;
	isEdit = false;
	systemTemplateDisable = false;
	modifyAccess = false;
	insertAccess = false;

	// tslint:disable-next-line:max-line-length
	constructor(private supplierService: SupplierService, private confirmationService: ConfirmationService, private fb: FormBuilder, private masterAdminService: NTierMasterAdminService, private router: Router, private valueStreamDataService: ValueStreamDataService, private messageService: MessageService) {
	}

	selectAllModifyAccess() {
		for (const item of this.columnAccess) {
			item.writeAccess = !this.modifyAccess;
		}
	}
	selectAllInsertAccess() {
		for (const item of this.columnAccess) {
			item.insertAccess = !this.insertAccess;
		}
	}

	ngOnInit() {
		this.router.navigate(['/']);
		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
			return;
		}  else if (sessionStorage.getItem('uid').includes('-')) {
			this.router.navigate(['/']);
			return;
		}
		// this.supplierService.pushSelectedSurvey('VSM');
		this.userLoginId = sessionStorage.getItem('uid');
		this.masterAdminForm = this.fb.group({
			templateName: [{value: '', disabled: false}, Validators.required],
			columnAccess: [{value: '', disabled: false}, Validators.required]
		});
		this.getAllTemplate();
		this.getAllColumn();
	}
	onVsmTemplateChange(event) {
		this.isEdit = false;
		this.selectedTemplate = event.value;
		this.valueStreamDataService.getVsmTemplateColumn(event.value.id).subscribe((res: any) => {
			this.columnAccess = res;
		});
	}

	onCreateTemplate() {
		this.onReset();
		this.createTemplateBtn = false;
		this.systemTemplateDisable = false;
		this.isEdit = false;
		this.selectedTemplate = null;
		this.createTemplate = true;
		this.columnAccess = [];
	}

	onDeleteTemplate() {
		this.confirmationService.confirm({
			message: 'Are you sure that you want to delete?',
			header: 'Confirmation',
			icon: 'pi pi-exclamation-triangle',
			accept: () => {
				this.valueStreamDataService.deleteVsmTemplate(this.selectedTemplate.id).subscribe(() => {
					this.onReset();
					this.pushMessage('success', 'Success',
						'Template deleted successfully');
					this.onBack();
				}, (() => {
					this.pushMessage('error', 'Error', 'Unable to create template, Please try again later');
				}));
			},
			reject: () => {
			}
		});
	}
	onEditTemplate() {
		const columnAccessList = Object.assign([], this.columnAccess);
		this.createTemplate = true;
		this.createTemplateBtn = true;
		this.columnAccess = [];
		this.masterAdminForm.get('templateName').setValue(this.selectedTemplate.tntvVsmTemplateName);
		if (this.selectedTemplate.tntvDataSource === 'ALL') {
			this.systemTemplateDisable = false;
		} else {
			this.systemTemplateDisable = true;
		}
		for (const columnAccessListElement of columnAccessList) {
			for (const item of this.columnAccessList) {
				if (item.id === columnAccessListElement.tntvColumnId) {
					item.readAccess = columnAccessListElement.tntvReadAccess;
					item.writeAccess = columnAccessListElement.tntvWriteAccess;
					item.insertAccess = columnAccessListElement.tntvInsertAccess;
					this.columnAccess.push(item);
				}
			}
		}
		this.isEdit = true;
		console.log(this.columnAccess);
		this.masterAdminForm.get('columnAccess').setValue(this.columnAccess);
		console.log(this.masterAdminForm.value);

	}

	onColumnAccess(event) {
		this.columnAccess = event.value;
		console.log(this.columnAccess);
	}

	onBack() {
		this.onReset();
		this.selectedTemplate = null;
		this.createTemplateBtn = true;
		this.createTemplate = false;
	}

	getAllColumn() {
		this.valueStreamDataService.getAccessColumn().subscribe((res: VsmColumn) => {
			this.columnAccessList = res;
			for (const item of this.columnAccessList) {
				item.readAccess = true;
				item.writeAccess = false;
			}
		});
	}

	onSubmit() {
		if (this.masterAdminForm.valid) {
			const form: any = this.masterAdminForm.value;
			form.columnAccess = this.columnAccess;
			console.log(form);
			this.valueStreamDataService.createVsmTemplate(form).subscribe(() => {
				this.onReset();
				this.createTemplateBtn = true;
				this.pushMessage('success', 'Success',
					'Template created successfully');
			}, (() => {
				this.pushMessage('error', 'Error', 'Unable to create template, Please try again later');
			}));
		} else {
			this.pushMessage('error', 'Error', 'Please enter valid template and select the column access');
		}
	}

	async onReset() {
		this.systemTemplateDisable = false;
		this.masterAdminForm.reset();
		this.columnAccess = [];
		this.createTemplate = false;
		this.isEdit = false;
		await this.getAllTemplate();
		await this.getAllColumn();
	}

	onUpdateSubmit() {
		if (this.masterAdminForm.valid) {
			const form: any = this.masterAdminForm.value;
			form.columnAccess = this.columnAccess;
			form.id = this.selectedTemplate.id;

			console.log(form);
			this.valueStreamDataService.updateVsmTemplate(form).subscribe(() => {
				this.onReset();
				this.pushMessage('success', 'Success',
					'Template updated successfully');
			}, (() => {
				this.pushMessage('error', 'Error', 'Unable to create template, Please try again later');
			}));
		} else {
			this.pushMessage('error', 'Error', 'Please enter valid template and select the column access');
		}
	}

	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 100000
		});
	}

	private getAllTemplate() {
		this.valueStreamDataService.getVsmTemplate().subscribe((res: any) => {
			this.templateList = res;
		});
	}
}
