import {
	Component,
	OnInit
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	Validators
} from '@angular/forms';
import {NTierMasterAdminService} from '../service/n-tier-master-admin.service';
import {AdminUsers} from '../model/admin-users';
import {MessageService} from 'primeng/api';
import {AdminAccess} from '../model/admin-access';
import {Router} from '@angular/router';
import {Option} from '../../../home/model/ntier.option';
import {SupplierService} from '../../../home/services/supplier.service';

@Component({
	selector: 'n-tier-master-admin',
	templateUrl: './n-tier-master-admin.component.html',
	styleUrls: ['./n-tier-master-admin.component.scss']
})
export class NTierMasterAdminComponent implements OnInit {
	masterAdminForm: FormGroup;
	userLoginId: string;
	screenName: string;
	screenList: Option[] = [];
	screenObject: Option;
	adminUser: AdminUsers;
	adminAccess: AdminAccess;
	adminAccess2: AdminAccess;
	accessForm: FormGroup;
	accessList1: AdminAccess[] = [];
	accessList2: AdminAccess[] = [];
	searchByScreen = false;
	searchByUserId = false;
	searchModeList: Option[] = [];
	searchModeObject: Option;
	searchMode: string;
	userId: string;
	isDisplayFilterData = false;
	isMasterAdmin = false;
	isT2VisibilitySuperAdmin = false;
	isVsmSuperAdmin = false;
	admin: string;

	// tslint:disable-next-line:max-line-length
	constructor(private supplierService: SupplierService, private fb: FormBuilder, private masterAdminService: NTierMasterAdminService, private router: Router, private messageService: MessageService) {
	}

	ngOnInit() {
		this.supplierService.pushSelectedSurvey(sessionStorage.getItem('Admin'));
		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
			return;
		} else if (sessionStorage.getItem('uid').includes('-')) {
			this.router.navigate(['/']);
			return;
		} else {
			this.admin = sessionStorage.getItem('Admin');
			if (this.admin === 'NTierMasterAdmin') {
				this.screenList = [
					{name: 'Main Admin Tab', code: 'Main Admin Tab'},
					{name: 'VSM Screen', code: 'VSM Screen'},
					{name: 'VSM Super Admin', code: 'VSM Super Admin'},
					{name: 'VSM Access Control Admin', code: 'VSM Access Control Admin'},
					{name: 'WPP Screen', code: 'WPP Screen'},
					{name: 'WPP Super Admin', code: 'WPP Super Admin'},
					{name: 'E-Comp Shortage Admin', code: 'E-Comp Shortage Admin'},
					{name: 'E-Comp Shortage Super Admin', code: 'E-Comp Shortage Super Admin'},
					{name: 'E-Comp Shortage Access Control Admin', code: 'E-Comp Shortage Access Control Admin'},
					{name: 'Smashed Report Screen', code: 'Smashed Report Screen'},
					{name: 'T2 Visibility Screen', code: 'T2 Visibility Screen'},
					{name: 'T2 Visibility Super Admin', code: 'T2 Visibility Super Admin'},
					{name: 'T2 Visibility Access Control Admin', code: 'T2 Visibility Access Control Admin'},
					{name: 'Sustainability Survey Screen', code: 'Sustainability Survey Screen'},
					{name: 'Sustainability Survey Super Admin', code: 'Sustainability Survey Super Admin'},
					{
						name: 'Sustainability Survey Access Control Admin',
						code: 'Sustainability Survey Access Control Admin'
					},
					{name: 'Project-R Non Microchip Survey Screen', code: 'Project-R Non Microchip Survey Screen'},
					{
						name: 'Project-R Non Microchip Survey Super Admin',
						code: 'Project-R Non Microchip Survey Super Admin'
					},
					{
						name: 'Project-R Non Microchip Survey Access Control Admin',
						code: 'Project-R Non Microchip Survey Access Control Admin'
					},
					{name: 'Project-R Access', code: 'Project-R Access'},
					{
						name: 'Sustainability Survey Evidence View Only',
						code: 'Sustainability Survey Evidence View Only'
					},
					{name: 'Sustainability Survey Evidence Download', code: 'Sustainability Survey Evidence Download'},
					{name: 'Sustainability Survey Evidence Delete', code: 'Sustainability Survey Evidence Delete'},
					{name: 'Clear To Build Screen', code: 'Clear To Build Screen'},
					{name: 'Clear To Build COE Admin', code: 'Clear To Build COE Admin'},
					{name: 'Clear To Build Super Admin', code: 'Clear To Build Super Admin'},
					{name: 'Clear To Build Access Control Admin', code: 'Clear To Build Access Control Admin'},
					{name: 'Clear To Build MP&L Admin', code: 'Clear To Build MP&L Admin'},
					{name: 'Clear To Build DRI Access', code: 'Clear To Build DRI Access'},
					{name: 'Clear To Build MP&L Super Admin', code: 'Clear To Build MP&L Super Admin'},
					{name: 'Project-R Microchip Screen', code: 'Project-R Microchip Screen'},
					{name: 'Project-R Microchip Super Admin', code: 'Project-R Microchip Super Admin'}
				];
				this.searchModeList = [{name: 'Screen', code: 'Screen'}, {name: 'CDSID', code: 'CDSID'}];
			} else if (this.admin === 'VSM') {
				this.screenList = [
					{name: 'VSM Screen', code: 'VSM Screen'},
					{name: 'VSM Super Admin', code: 'VSM Super Admin'}
				];
				this.searchModeList = [{name: 'Screen', code: 'Screen'}];
			} else if (this.admin === 'T2Visibility Data Refresh') {



				this.router.navigate(['/']);

				//todo Following is the T2V Data Refresh Decommission commented code

				// this.screenList = [
				// 	{name: 'T2 Visibility Screen', code: 'T2 Visibility Screen'},
				// 	{name: 'T2 Visibility Super Admin', code: 'T2 Visibility Super Admin'}
				// ];
				// this.searchModeList = [{name: 'Screen', code: 'Screen'}];
			} else if (this.admin === 'SemiSupp') {
				// this.screenList = [
				// 	{name: 'E-Comp Shortage Admin', code: 'E-Comp Shortage Admin'},
				// 	{name: 'E-Comp Shortage Super Admin', code: 'E-Comp Shortage Super Admin'},
				// ];
				// this.searchModeList = [{name: 'Screen', code: 'Screen'}];
			} else if (this.admin === 'sustainability survey') {
				this.screenList = [
					{name: 'Sustainability Survey Super Admin', code: 'Sustainability Survey Super Admin'},
					{
						name: 'Sustainability Survey Access Control Admin',
						code: 'Sustainability Survey Access Control Admin'
					},
					{
						name: 'Sustainability Survey Evidence View Only',
						code: 'Sustainability Survey Evidence View Only'
					},
					{name: 'Sustainability Survey Evidence Download', code: 'Sustainability Survey Evidence Download'},
					{name: 'Sustainability Survey Evidence Delete', code: 'Sustainability Survey Evidence Delete'},
					{name: 'Project-R Access', code: 'Project-R Access'},
				];
				this.searchModeList = [{name: 'Screen', code: 'Screen'}];
			} else if (this.admin === 'Project R') {
				this.screenList = [
					{name: 'Project-R Non Microchip Survey Screen', code: 'Project-R Non Microchip Survey Screen'},
					{
						name: 'Project-R Non Microchip Survey Super Admin',
						code: 'Project-R Non Microchip Survey Super Admin'
					},
					{
						name: 'Project-R Non Microchip Survey Access Control Admin',
						code: 'Project-R Non Microchip Survey Access Control Admin'
					},
					{name: 'Project-R Access', code: 'Project-R Access'},
				];
				this.searchModeList = [{name: 'Screen', code: 'Screen'}];
			} else if (this.admin === 'clearToBuild') {
				this.screenList = [

					{name: 'Clear To Build Screen', code: 'Clear To Build Screen'},
					{name: 'Clear To Build COE Admin', code: 'Clear To Build COE Admin'},
					{name: 'Clear To Build Super Admin', code: 'Clear To Build Super Admin'},
					{name: 'Clear To Build MP&L Admin', code: 'Clear To Build MP&L Admin'},
					{name: 'Clear To Build DRI Access', code: 'Clear To Build DRI Access'},
					{name: 'Clear To Build MP&L Super Admin', code: 'Clear To Build MP&L Super Admin'},
				];
				this.searchModeList = [{name: 'Screen', code: 'Screen'}];
			} else if (this.admin === 'Project-R Microchip') {
				this.screenList = [
					{name: 'Project-R Microchip Screen', code: 'Project-R Microchip Screen'},
					{name: 'Project-R Microchip Super Admin', code: 'Project-R Microchip Super Admin'}
				];
				this.searchModeList = [{name: 'Screen', code: 'Screen'}];
			}
		}
		this.userLoginId = sessionStorage.getItem('uid');
		this.masterAdminForm = this.fb.group({
			cdsid: [{value: '', disabled: false}, Validators.required],
			screens: [''],
			accessLevel: [{value: '', disabled: false}]
		});
		this.accessForm = this.fb.group({
			searchMode: [''],
			screenList: [''],
			userId: [{value: '', disabled: false}]
		});
	}

	// onScreenChange() {
	// 	if (this.screenObject && this.screenObject !== null && this.screenObject.name && this.screenObject.name !== null) {
	// 		if (this.screenObject.name === 'Main Admin Tab') {
	// 			this.masterAdminForm.get('accessLevel').setValue('Master Admin');
	// 		} else if (this.screenObject.name === 'VSM Screen') {
	// 			this.masterAdminForm.get('accessLevel').setValue('VSM Admin');
	// 		} else if (this.screenObject.name === 'VSM Super Admin') {
	// 			this.masterAdminForm.get('accessLevel').setValue('VSM Super Admin');
	// 		} else if (this.screenObject.name === 'VSM Access Control Admin') {
	// 			this.masterAdminForm.get('accessLevel').setValue('VSM Access Control Admin');
	// 		} else if (this.screenObject.name === 'WPP Screen') {
	// 			this.masterAdminForm.get('accessLevel').setValue('WPP Admin');
	// 		} else if (this.screenObject.name === 'WPP Super Admin') {
	// 			this.masterAdminForm.get('accessLevel').setValue('WPP Super Admin Access');
	// 		} else if (this.screenObject.name === 'Smashed Report Screen') {
	// 			this.masterAdminForm.get('accessLevel').setValue('Smashed Report Admin');
	// 		} else if (this.screenObject.name === 'T2 Visibility Screen') {
	// 			this.masterAdminForm.get('accessLevel').setValue('T2 Visibility Screen Access');
	// 		} else if (this.screenObject.name === 'T2 Visibility Super Admin') {
	// 			this.masterAdminForm.get('accessLevel').setValue('T2 Visibility Super Admin');
	// 		} else if (this.screenObject.name === 'T2 Visibility Access Control Admin') {
	// 			this.masterAdminForm.get('accessLevel').setValue('T2 Visibility Access Control Admin');
	// 		} else {
	// 			this.masterAdminForm.get('accessLevel').setValue('E-Comp Shortage Admin');
	// 		}
	// 	}
	// }

	onSubmitForApproveAccess() {
		console.log('submitted');
		this.adminAccess = this.masterAdminForm.getRawValue() as AdminAccess;
		this.adminUser = this.masterAdminForm.getRawValue() as AdminUsers;
		const userToHaveAccess = this.masterAdminForm.get('cdsid').value;
		if (userToHaveAccess.includes('-')) {
			this.pushMessage('error', 'Error', 'Please enter CDSID of type Ford User');
		} else {
			// this.masterAdminService.isUserHasFunctionAdminAccess(userToHaveAccess, this.screenObject.name).subscribe(userHasFunctionAccess => {
				// if (userHasFunctionAccess) {
				// 	this.pushMessage('error', 'Error', 'This user already has ' + this.screenObject.name + ' Administrator Access.');
				// }

				// else {
				// 	this.masterAdminService.isUserPresentInAdminUser(userToHaveAccess).subscribe(userPresent => {
				// 		if (!userPresent) {
				// 			this.adminUser.adminUserId = userToHaveAccess;
				// 			this.adminUser.userInfo = 'Ford User to access N-Tier Admin screens';
				// 			this.masterAdminService.createAdminUser(this.adminUser).subscribe(newUser => {
				// 				this.adminUser = newUser;
				// 				this.adminAccess.adminUserId = userToHaveAccess;
				// 				this.adminAccess.functionName = this.screenObject.name;
				// 				this.masterAdminService.createAdminAccessForUser(this.adminAccess).subscribe(response => {
				// 					this.adminAccess = response;
				// 					this.pushMessage('success', 'Success',
				// 						'Access for ' + this.screenObject.name + ' has been granted successfully');
				// 					this.refresh();
				// 					// this.masterAdminForm.reset();
				// 				}, error => {
				// 					this.pushMessage('error', 'Error', 'Unable to grant access, Please try again later');
				// 				});
				// 			});
				// 		}
				// 		else {

							this.adminAccess.adminUserId = userToHaveAccess;
							this.adminAccess.functionName = this.screenObject.name;
							console.log('user present');
							this.masterAdminService.createAdminAccessForUser(this.adminAccess).subscribe(response => {
								this.adminAccess = response;
								this.pushMessage('success', 'Success',
									'Access for ' + this.screenObject.name + ' has been granted successfully');
								this.refresh();
								// this.masterAdminForm.reset();
							}, error => {
								this.pushMessage('error', 'Error', 'Unable to grant access, Please try again later');
							});
						// }
			// 		});
			// 	}
			// });
		}
	}

	onSubmitForRemoveAccess() {
		console.log('submitted');
		this.adminAccess = this.masterAdminForm.getRawValue() as AdminAccess;
		this.adminUser = this.masterAdminForm.getRawValue() as AdminUsers;
		const userToHaveAccess = this.masterAdminForm.get('cdsid').value;
		if (userToHaveAccess.includes('-')) {
			this.pushMessage('error', 'Error', 'Please enter CDSID of type Ford User');
		} else {
			// this.masterAdminService.isUserHasFunctionAdminAccess(userToHaveAccess, this.screenObject.name).subscribe(userHasFunctionAccess => {
			// 	if (!userHasFunctionAccess) {
			// 		this.pushMessage('error', 'Error', 'This user does not have ' + this.screenObject.name + ' Administrator Access.');
			// 	} else {
					this.adminAccess.adminUserId = userToHaveAccess;
					this.adminAccess.functionName = this.screenObject.name;
					console.log('user has access');
					this.deleteAccess(this.adminAccess);
				}
			// });
		// }
	}

	deleteAccess(adminAccess: AdminAccess) {
		this.masterAdminService.removeAdminAccessForUser(adminAccess).subscribe(response => {
			this.pushMessage('success', 'Success',
				'Access for ' + adminAccess.functionName + ' has been removed successfully');
			console.log('Refresh : ' + this.searchMode);
			this.refresh();
		}, error => {
			this.pushMessage('error', 'Error', 'Unable to remove access, Please try again later');
		});
	}

	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 100000
		});
	}

	retrieveAccessListForFunction() {
		this.accessList1.length = 0;
		this.accessList2.length = 0;
		this.masterAdminService.retrieveAccessListForFunction(this.screenName).subscribe(response => {
			if (response.length > 0) {
				this.accessList1 = response;
			} else {
				this.pushMessage('error', 'Error', this.screenName + ' is not assigned to any CDSID\'s');
			}
		}, error => {
			this.pushMessage('error', 'Error', 'Unable to retrieve the Access Details, Please try again later');
		});
	}

	retrieveAccessListForUserId() {
		this.accessList1.length = 0;
		this.accessList2.length = 0;
		this.masterAdminService.retrieveAccessListForUserId(this.userId).subscribe(response => {
			if (response.length > 0) {
				this.accessList2 = response;
			} else {
				this.pushMessage('error', 'Error', this.userId + ' does not have access to any Screens');
			}
		}, error => {
			this.pushMessage('error', 'Error', 'Unable to retrieve the Access Details, Please try again later');
		});
	}

	onRemoveAccess(product: any) {
		this.deleteAccess(product);
	}

	changeType(event) {
		this.searchMode = event.value.code;
		if (this.searchMode === 'Screen') {
			this.accessForm.controls['screenList'].reset();
			this.accessForm.controls['userId'].reset();
			this.searchByScreen = true;
			this.searchByUserId = false;
			this.accessList1.length = 0;
			this.accessList2.length = 0;
		} else {
			this.accessForm.controls['screenList'].reset();
			this.accessForm.controls['userId'].reset();
			this.searchByScreen = false;
			this.searchByUserId = true;
			this.accessList1.length = 0;
			this.accessList2.length = 0;
		}
	}

	refresh() {
		if (this.searchMode !== undefined && this.screenName !== undefined) {
			if (this.searchMode === 'Screen' && this.screenName !== undefined) {
				this.retrieveAccessListForFunction();
			} else if (this.userId !== undefined) {
				this.retrieveAccessListForUserId();
			}
		}
	}

	showDataFilters() {
		this.isDisplayFilterData = !this.isDisplayFilterData;
		this.refresh();
	}

	clearDataFilters() {
		this.refresh();
	}
}
