import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {Scheduler} from '../model/scheduler';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SchedulerService {
	private SCHEDULER_ENDPOINT = environment.apiUrl + 'tigerSharkSacInterface';

	constructor(private http: HttpClient) {
	}
	createSchedule(scheduler: Scheduler): Observable<Scheduler> {
		console.log('Create Schedule service is called');
		return this.http.post<Scheduler>(this.SCHEDULER_ENDPOINT + '/createSchedule', scheduler);
	}
	updateSchedule(scheduler: Scheduler): Observable<Scheduler> {
		console.log('Update Schedule service is called');
		return this.http.post<Scheduler>(this.SCHEDULER_ENDPOINT + '/updateSchedule', scheduler);
	}
	public getAllSchedules(): Observable<any> {
		return this.http.get(this.SCHEDULER_ENDPOINT + `/findAllSchedules`, {responseType: 'json'});
	}
	public adhocPull(scheduler: Scheduler): Observable<any> {
		return this.http.post(this.SCHEDULER_ENDPOINT + `/adhocPull`, scheduler);
	}
	public getConstraintIds(partType: string): Observable<any> {
		return this.http.get(this.SCHEDULER_ENDPOINT + `/getConstraintIds/${partType}`, {responseType: 'json'});
	}
	public deleteSchedule(id: number): Observable<boolean> {
		return this.http.delete<boolean>(this.SCHEDULER_ENDPOINT + `/deleteSchedule/${id}`);
	}
}
