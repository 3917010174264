import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ClearToBuildAdmin} from '../model/clear-to-build-admin';
import {CtbDropdown} from '../model/ctb-dropdown';
import {ClearToBuildMpnlAdmin} from '../model/clear-to-build-mpnl-admin';

@Injectable({
	providedIn: 'root'
})
export class ClearToBuildAdminService {
	private ADMIN_ENDPOINT = environment.apiUrl + 'clearToBuildAdmin';

	constructor(private http: HttpClient) {
	}

	public getAllCtbId(): Observable<any> {
		return this.http.get(this.ADMIN_ENDPOINT + `/AllCtbId`, {responseType: 'json'});
	}
	generateCtbAdminReport(clearToBuildAdmin: ClearToBuildAdmin) {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.ADMIN_ENDPOINT + `/generateCtbAdminReport`, clearToBuildAdmin, {responseType: 'blob'});
	}
	generateCtbAdminWeeklyReport(clearToBuildAdmin: ClearToBuildAdmin) {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.ADMIN_ENDPOINT + `/generateCtbAdminWeeklyReport`, clearToBuildAdmin, {responseType: 'blob'});
	}

	downLoadFile(data: any, type: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = 'CTB_Admin_Data.xlsx';
		anchor.href = url;
		anchor.click();
	}

	update(fileToUpload: File): Observable<any> {
		const endpoint = this.ADMIN_ENDPOINT + '/update';
		const formData: FormData = new FormData();
		formData.append('File', fileToUpload);
		// @ts-ignore
		return this.http.post(endpoint, formData);
	}

	updateMpnlData(fileToUpload: File): Observable<any> {
		const endpoint = this.ADMIN_ENDPOINT + '/updateShortfallSupplierData';
		const formData: FormData = new FormData();
		formData.append('File', fileToUpload);
		// @ts-ignore
		return this.http.post(endpoint, formData);
	}

	public getCtbIdsForWeekDate(clearToBuildAdmin: ClearToBuildAdmin): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.ADMIN_ENDPOINT + `/getCtbIdsForWeekDate`, clearToBuildAdmin, {responseType: 'json'});
	}

	checkCtbIdExistence(clearToBuildAdmin: ClearToBuildAdmin) {
		return this.http.post(this.ADMIN_ENDPOINT + `/checkExistence`, clearToBuildAdmin, {responseType: 'json'});
	}

	loadCtbIdsBasedOnSupplierName(clearToBuildAdmin: ClearToBuildAdmin) {
		return this.http.post(this.ADMIN_ENDPOINT + '/ctbIdsFromSupplierName', clearToBuildAdmin, {responseType: 'json'});
	}

	loadCtbIds() {
		return this.http.get(this.ADMIN_ENDPOINT + '/AllCtbId', {responseType: 'json'});
	}

	loadSupplierCodesBasedOnSupplierName(clearToBuildAdmin: ClearToBuildAdmin) {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.ADMIN_ENDPOINT + '/supplierCodesFromSupplierName', clearToBuildAdmin, {responseType: 'json'});
	}

	loadSupplierCode(): Observable<any> {
		return this.http.get(this.ADMIN_ENDPOINT + '/AllSupplierCode', {responseType: 'json'});
	}
	loadSupplierNames(): Observable<any> {
		return this.http.get(this.ADMIN_ENDPOINT + '/AllSupplierName', {responseType: 'json'});
	}
	loadCtbIdsBasedOnSupplierNameAndSupplierCode(clearToBuildAdmin: ClearToBuildAdmin) {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.ADMIN_ENDPOINT + '/ctbIdsFromSupplierNameAndSupplierCode', clearToBuildAdmin, {responseType: 'json'});
	}
	getCtbIdsFromEmailId(clearToBuildAdmin: ClearToBuildAdmin): Observable<any> {
		return this.http.post(this.ADMIN_ENDPOINT + `/getCtbIdsFromEmailId`, clearToBuildAdmin, {responseType: 'json'});
	}
	getSupplierNameFromEmailId(clearToBuildAdmin: ClearToBuildAdmin): Observable<any> {
		return this.http.post(this.ADMIN_ENDPOINT + `/getSupplierNameFromEmailId`, clearToBuildAdmin, {responseType: 'json'});
	}
	getSupplierCodeFromEmailId(clearToBuildAdmin: ClearToBuildAdmin): Observable<any> {
		return this.http.post(this.ADMIN_ENDPOINT + `/getSupplierCodeFromEmailId`, clearToBuildAdmin, {responseType: 'json'});
	}

	findAllCtbDropDownData(columnName: string): Observable<any> {
		return this.http.get(this.ADMIN_ENDPOINT + `/findAllCtbDropDownData/${columnName}`, {responseType: 'json'});
	}
	createCtbDropDownData(ctbDropdown: CtbDropdown): Observable<any> {
		return this.http.post(this.ADMIN_ENDPOINT + `/createCtbDropDownData`, ctbDropdown, {responseType: 'json'});
	}
	updateCtbDropDownData(ctbDropdown: CtbDropdown): Observable<any> {
		return this.http.put(this.ADMIN_ENDPOINT + `/updateCtbDropDownData`, ctbDropdown, {responseType: 'json'});
	}
	deleteCtbDropDownData(ids: string): Observable<any> {
		return this.http.delete(this.ADMIN_ENDPOINT + `/deleteCtbDropDownData/${ids}`, {responseType: 'json'});
	}

	getCtbIdsFromMpnlEmailId(clearToBuildMpnlAdmin: ClearToBuildMpnlAdmin): Observable<any> {
		return this.http.post(this.ADMIN_ENDPOINT + `/getCtbIdsFromMpnlEmailId`, clearToBuildMpnlAdmin, {responseType: 'json'});
	}

	public getAllCtbIdForMpnl(): Observable<any> {
		return this.http.get(this.ADMIN_ENDPOINT + `/AllCtbIdForMpnl`, {responseType: 'json'});
	}
}
