import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Tier1EComponentShortageAdmin} from '../model/tier1-e-component-shortage-admin';
import {SemiconductorSupplierAdmin} from '../model/semiconductor-supplier-admin';

@Injectable({
	providedIn: 'root'
})
export class Tier1EComponentShortageAdminService {
	private TRACKER_ENDPOINT = environment.apiUrl + 'tier1EComponentShortageTracker/admin';

	constructor(private http: HttpClient) {
	}

	public getAllTier1Suppliers(): Observable<any> {
		return this.http.get(this.TRACKER_ENDPOINT, {responseType: 'json'});
	}

	public getContactDataFromTier1Supplier(tier1EComponentShortageAdmin: Tier1EComponentShortageAdmin): Observable<any> {
		return this.http.post(this.TRACKER_ENDPOINT + `/adminDataFromTier1Supplier`, tier1EComponentShortageAdmin, {responseType: 'json'});
	}

	// tslint:disable-next-line:max-line-length
	public createTier1EComponentShortageAdmin(tier1EComponentShortageAdmin: Tier1EComponentShortageAdmin): Observable<Tier1EComponentShortageAdmin> {
		return this.http.post<Tier1EComponentShortageAdmin>(this.TRACKER_ENDPOINT + `/create`, tier1EComponentShortageAdmin);
	}

	// tslint:disable-next-line:max-line-length
	public updateTier1EComponentShortageAdmin(tier1EComponentShortageAdmin: Tier1EComponentShortageAdmin): Observable<Tier1EComponentShortageAdmin> {
		return this.http.post<Tier1EComponentShortageAdmin>(this.TRACKER_ENDPOINT + '/update', tier1EComponentShortageAdmin);
	}

	retrieveAllAdminData(): Observable<any> {
		return this.http.get<Tier1EComponentShortageAdmin>(this.TRACKER_ENDPOINT + '/getAllAdminData');
	}

	checkTier1SupplierExistence(tier1Supplier: any) {
		return this.http.get(this.TRACKER_ENDPOINT + `/checkExistence/${tier1Supplier}`, {responseType: 'json'});
	}
	//-----------------------Semiconductor supplier setup---------------------------

	public getAdminDataFromSemiconductorSupplier(semiconductorSupplierAdmin: SemiconductorSupplierAdmin): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.TRACKER_ENDPOINT + `/semiconductorSupplier/adminDataFromSemiconductorSupplierName`, semiconductorSupplierAdmin, {responseType: 'json'});
	}

	public createSemiconductorSupplierAdmin(semiconductorSupplierAdmin: SemiconductorSupplierAdmin): Observable<SemiconductorSupplierAdmin> {
		return this.http.post<SemiconductorSupplierAdmin>(this.TRACKER_ENDPOINT + `/semiconductorSupplier/create`, semiconductorSupplierAdmin);
	}

	// tslint:disable-next-line:max-line-length
	public updateSemiconductorSupplierAdmin(semiconductorSupplierAdmin: SemiconductorSupplierAdmin): Observable<SemiconductorSupplierAdmin> {
		return this.http.post<SemiconductorSupplierAdmin>(this.TRACKER_ENDPOINT + '/semiconductorSupplier/update', semiconductorSupplierAdmin);
	}

	getAllSemiSupplierAdminDataForTable(): Observable<any> {
		return this.http.get<Tier1EComponentShortageAdmin>(this.TRACKER_ENDPOINT + '/semiconductorSupplier/getAllSemiconductorSupplierAdminData');
	}

	public getAllSemiconductorSupplierNames(): Observable<any> {
		return this.http.get(this.TRACKER_ENDPOINT + '/semiconductorSupplier/semiconductorSupplierList', {responseType: 'json'});
	}

	checkSemiconductorSupplierExistence(semiconductorSupplier: any) {
		return this.http.get(this.TRACKER_ENDPOINT + `/semiconductorSupplier/checkExistence/${semiconductorSupplier}`, {responseType: 'json'});
	}
}
