import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {SpinnerService} from "../../../services/spinner/spinner.service";
import {Observable} from "rxjs";
import {T2Visibility} from "../model/t2_visibility";
import {T2VisibilityAdmin} from "../model/t2_visibility_admin";
import {T2VisibilityEmailTrigger} from "../model/t2_email_trigger";
import {T2VDataRefreshEmailConfig} from "../model/t2_refresh_email_config";

@Injectable({
  providedIn: 'root'
})
export class T2DataRefreshService {
	private T2_DATA_REFRESH_ENDPOINT = environment.apiUrl + 't2VisibilityRefresh';
	private HELP_ENDPOINT = environment.apiUrl + 'helpContent/download/';
	private T2_ADMIN_ENDPOINT = environment.apiUrl + 't2visibility/notification';
	private GET_T2_VISIBILITY_REPORT = environment.apiUrl + 't2visibility/t2-visibility-template';

	constructor(private http: HttpClient, private spinnerService: SpinnerService) {
	}


	public generateSupplierReport(): Observable<any> {
		const email = sessionStorage.getItem('emailAddress');
		return this.http.get(this.T2_DATA_REFRESH_ENDPOINT + `/generateT2vRefreshSupplierReport/${email}`, {responseType: 'blob'});
	}

	public submitAllDataNoChanges(): Observable<any> {
		const email = sessionStorage.getItem('emailAddress');
		return this.http.post(this.T2_DATA_REFRESH_ENDPOINT + `/validateDataWithNoChanges/${email}`, {responseType: 'json'});
	}

	public downloadT2CleanedReport(): Observable<any> {
		return this.http.get(this.T2_DATA_REFRESH_ENDPOINT + `/t2_download_cleaned_report`, {responseType: 'blob'});
	}

	downLoadFile(data: any, type: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = 'T2_Data_Refresh_Report.xlsx';
		anchor.href = url;
		anchor.click();
	}

	adminUploadforEnrichment(fileToUpload: File): Observable<any> {
		const endpoint = this.T2_DATA_REFRESH_ENDPOINT + `/upload/fordUserReport`;
		const formData: FormData = new FormData();
		formData.append('File', fileToUpload);
		return this.http.post(endpoint, formData);
	}

	deleteByRowIds(rowIds:any) {
		let obj = {
			'rowIds':rowIds
		}
		return this.http.post(this.T2_DATA_REFRESH_ENDPOINT + '/bulkDelete', obj, {responseType: 'text'});
	}



	upload(fileToUpload: File, uid: string, email: string, fordSiteCode: string): Observable<any> {
		const endpoint = this.T2_DATA_REFRESH_ENDPOINT + `/upload/${uid}/${email}/${fordSiteCode}`;
		const formData: FormData = new FormData();
		formData.append('File', fileToUpload);
		return this.http.post(endpoint, formData);
	}

	loadT1SupplierSiteNames(): Observable<any> {
		return this.http.get(this.T2_DATA_REFRESH_ENDPOINT + '/t1SupplierSiteNames', {responseType: 'json'});
	}

	loadParentCodes(): Observable<any> {
		return this.http.get(this.T2_DATA_REFRESH_ENDPOINT + '/parentCodes', {responseType: 'json'});
	}

	loadSupplierEmails() {
		return this.http.get(this.T2_DATA_REFRESH_ENDPOINT + '/supplierEmails', {responseType: 'json'});
	}

	loadSupplierEmailsBasedOnT1SupplierSite(t2Visibility: T2Visibility) {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.T2_DATA_REFRESH_ENDPOINT + '/supplierEmailsFromT1SupplierSites', t2Visibility, {responseType: 'json'});
	}

	loadParentCodesBasedOnT1SupplierSite(t2Visibility: T2Visibility) {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.T2_DATA_REFRESH_ENDPOINT + '/parentCodesFromT1SupplierSites', t2Visibility, {responseType: 'json'});
	}

	loadSupplierEmailsBasedOnT1SupplierSiteAndParentCodes(t2Visibility: T2Visibility) {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.T2_DATA_REFRESH_ENDPOINT + '/supplierEmailsFromT1SupplierSitesAndParentCodes', t2Visibility, {responseType: 'json'});
	}

	generateFordAdminReport(t2Visibility: T2Visibility): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.T2_DATA_REFRESH_ENDPOINT + '/generateT2vRefreshFordUserReport', t2Visibility, {responseType: 'blob'});
		// .subscribe((response) => {
		// 	this.downLoadFile(response, 'text/csv');
		// });
	}

	generateFordAdminReportforDataEnrichment(t2Visibility: T2Visibility): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.T2_DATA_REFRESH_ENDPOINT + '/generateT2vRefreshReportForFordUserUpdate', t2Visibility, {responseType: 'blob'});
		// .subscribe((response) => {
		// 	this.downLoadFile(response, 'text/csv');
		// });
	}

	uploadAdminDetails(fileToUpload: File): Observable<any> {
		const endpoint = this.T2_DATA_REFRESH_ENDPOINT + `/uploadT2vRefreshAdminReport`;
		const formData: FormData = new FormData();
		formData.append('File', fileToUpload);
		return this.http.post(endpoint, formData, {responseType: 'json'});
	}

	getDataRefreshAdminReport(): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.get(this.T2_DATA_REFRESH_ENDPOINT + '/generateT2vRefreshAdminReport', {responseType: 'blob'});
	}
	generateRawReport(t2Visibility: T2Visibility): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.T2_DATA_REFRESH_ENDPOINT + '/generateRawT2Report', t2Visibility, {responseType: 'blob'});
	}

	removeDuplicatesAdhoc(): Observable<any> {
		// @ts-ignore
		return this.http.delete(this.T2_DATA_REFRESH_ENDPOINT + '/removeDuplicatesAdhoc');
	}

	uploadAdminFile(fileToUpload: File): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('File', fileToUpload);
		return this.http.post(this.T2_DATA_REFRESH_ENDPOINT + '/upload_admin_excel_sheet', formData, {responseType: 'blob'});
	}

	getAllAdminNotifications(): Observable<any> {
		return this.http.get(this.T2_ADMIN_ENDPOINT + '/allNotifications', {responseType: 'json'});
	}

	createNotification(t2VisibilityAdmin: T2VisibilityAdmin): Observable<any> {
		return this.http.post(this.T2_ADMIN_ENDPOINT + '/create', t2VisibilityAdmin, {responseType: 'json'});
	}

	updateNotification(t2VisibilityAdmin: T2VisibilityAdmin): Observable<any> {
		return this.http.post(this.T2_ADMIN_ENDPOINT + '/update', t2VisibilityAdmin, {responseType: 'json'});
	}

	getAllActiveSupplierNotifications(): Observable<any> {
		return this.http.get(this.T2_ADMIN_ENDPOINT + '/activeNotifications', {responseType: 'json'});
	}

	createOrUpdateEmailDraft(templateDraft): Observable<any> {
		return this.http.post(this.T2_DATA_REFRESH_ENDPOINT + '/createOrUpdateEmailDraft', templateDraft, {responseType: 'json'});
	}

	findAllEmailTemplateName(businessName: any): Observable<any> {
		return this.http.get(this.T2_DATA_REFRESH_ENDPOINT + `/getAllEmailTemplates/${businessName}`, {responseType: 'json'});
	}

	downloadAdministratorDetailsExcel(): Observable<any> {
		return this.http.get(this.T2_DATA_REFRESH_ENDPOINT + '/t2_visibility_administrator', {responseType: 'blob'});
	}

	triggerEmailForT2(t2TriggerEmailModel: T2VisibilityEmailTrigger) {
		return this.http.post(this.T2_DATA_REFRESH_ENDPOINT + '/triggerT2Email', t2TriggerEmailModel, {responseType: 'text'});
	}

	uploadCleanedReport(fileToUpload: File): Observable<any> {
		const endpoint = this.T2_DATA_REFRESH_ENDPOINT + `/uploadCleanedReport`;
		const formData: FormData = new FormData();
		formData.append('File', fileToUpload);
		return this.http.post(endpoint, formData);
	}

	checkIfLessThanFiveSuppliers(emailAddress: string) {
		return this.http.get(this.T2_DATA_REFRESH_ENDPOINT + `/checkExistence/${emailAddress}`, {responseType: 'json'});
	}

	confirmationStatusUpdate(emailAddress: string) {
		return this.http.post(this.T2_DATA_REFRESH_ENDPOINT + `/confirmationStatusUpdate/${emailAddress}`, {responseType: 'json'});
	}

	public helpEndPointDownload(temp: any): Observable<any> {
		return this.http.get(this.HELP_ENDPOINT + temp, {responseType: 'blob'});
	}

	downloadFileMp4(data: any, type: string, fileName: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = fileName + '.mp4';
		anchor.href = url;
		anchor.click();
	}

	downLoadFileDocument(data: any, type: string, fileName: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = fileName+'.xlsx';
		anchor.href = url;
		anchor.click();
	}

	triggerEmailForT2Count(t2TriggerEmailModel: T2VisibilityEmailTrigger) {
		return this.http.post(this.T2_DATA_REFRESH_ENDPOINT + '/triggerT2EmailCount', t2TriggerEmailModel, {responseType: 'json'});
	}

	findAllDropDownData() {
		return this.http.get(this.T2_DATA_REFRESH_ENDPOINT + '/getAllAdminData', {responseType: 'json'});
	}

	createDropDownData(dropDownObject: T2VDataRefreshEmailConfig) {
		return this.http.post(this.T2_DATA_REFRESH_ENDPOINT + '/createAdminData', dropDownObject, {responseType: 'json'});
	}

	updateDropDownData(dropDownObject: T2VDataRefreshEmailConfig) {
		return this.http.post(this.T2_DATA_REFRESH_ENDPOINT + '/updateAdminData', dropDownObject, {responseType: 'json'});
	}
}
