import {
	Component, Input, Output, EventEmitter
} from '@angular/core';

@Component({
	selector: 'ngx-segmented-bar',
	styleUrls: ['./ngx-segmented-bar.component.scss'],
	templateUrl: './ngx-segmented-bar.component.html'
})
export class NgxSegmentedBarComponent {
	private _items: any[];
	@Input() set items(items: any[]) {
		this._items = this.transformItems(items);
	}
	@Input() selectedItem: any;
	@Output() selectedIndexChange: EventEmitter<any> = new EventEmitter();
	// tslint:disable-next-line:use-lifecycle-interface
	/*ngOnInit() {
		this.items.forEach(item => item.selected = false);
		this.selectItem(this.selectedItem);
		console.log(this.items);
	}
	selectItem(item) {
		this.items.forEach((it, title) => it.selected = false);
		// update selected item
		this.selectedIndexChange.emit(item);
		item.selected = true;
	}
	transformItems(items: any[]): any[] {
		return items;
	}
	get items(): any[] {
		return this._items;
	}*/
	ngOnInit() {
		let hasSelectedItem = false;
		let i;
		if (this.items) {
			if (this.selectedItem) {
				this.items.forEach((item) => item.title === this.selectedItem.title ? item.selected = true : i = 1);
				hasSelectedItem = this.items.filter(res => res.selected).length > 0;
				console.log(this.selectedItem, this.items.filter(res => res.selected), hasSelectedItem, this.items);
			}
			if (!hasSelectedItem) {
				console.log(this.selectedItem);
				this.selectItem(this.items[0]);
			}
		}
	}

	selectSegmentedItem(item: any): void {
		if (this.items !== null) {
			this.items.map(res => {
				return this.selectItem(res, false);
			});
		}
		item = this.selectItem(item);
		this.selectedIndexChange.emit(item);
	}

	private selectItem(item: any, selected = true): any {
		if ('set' in item) {
			if (typeof item.set === 'function') {
				try {
					item.set('selected', selected);
					return (<any>Object).assign(item, {
						selected: selected
					});
				} catch (error) { }
			}
		}
		else {
			item.selected = selected;
			return item;
		}
	}

	transformItems(items: any[]): any[] {
		return items;
	}

	get items(): any[] {
		return this._items;
	}

}
