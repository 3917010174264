import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Tier1EComponentShortageAdmin} from '../model/tier1-e-component-shortage-admin';
import {DatePipe} from '@angular/common';
import {SupplierService} from '../../../home/services/supplier.service';
import {DashboardDownloadService} from '../service/dashboard-download.service';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {MessageService} from 'primeng/api';
import {Tier1EComponentShortageAdminService} from '../service/tier1-e-component-shortage-admin.service';
import {SemiconductorSupplierAdmin} from '../model/semiconductor-supplier-admin';

@Component({
	selector: 'semiconductor-supplier-setup',
	templateUrl: './semiconductor-supplier-setup.component.html',
	styleUrls: ['./semiconductor-supplier-setup.component.scss']
})
export class SemiconductorSupplierSetupComponent implements OnInit {

	// tslint:disable-next-line:max-line-length
	constructor(private supplierService: SupplierService, private fb: FormBuilder, private router: Router, private datepipe: DatePipe, private downloadServiceObj: DashboardDownloadService, private spinnerService: SpinnerService, private messageService: MessageService, private tier1EComponentShortageAdminService: Tier1EComponentShortageAdminService) {
	}

	semiconductorSupplierForm: FormGroup;
	isDisabled = false;
	showSubmit = false;
	showUpdate = false;
	showDropdown = false;
	semiconductorSupplierList = [];
	selectedSemiconductorSupplier: any;
	semiconductorSupplierAdmin: SemiconductorSupplierAdmin;
	createSemiconductorSupplierAdmin: SemiconductorSupplierAdmin;
	newSemiconductorSupplierAdmin: SemiconductorSupplierAdmin;
	retrieve: any;
	isContactFormValid = false;
	isEdit = false;
	isCreate = false;
	semisupplierAdminDataList: SemiconductorSupplierAdmin[] = [];
	isDisplayFilterData = false;

	ngOnInit() {

		this.router.navigate(['/']);
		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
			return;
		} else if (sessionStorage.getItem('uid').includes('-')) {
			this.router.navigate(['/']);
			return;
		}
		// this.supplierService.pushSelectedSurvey('SemiSupp');
		this.semiconductorSupplierForm = this.fb.group({
			semiconductorSupplierName: [{value: '', disabled: this.isDisabled}, [ Validators.required]],
			semisupplierEmail: [{value: '', disabled: this.isDisabled}, [Validators.required, Validators.email]],
		});
		// this.getSemiconductorSupplierList();
		// this.retrieveAllSemiSupplierAdminData();
	}

	getSemiconductorSupplierList() {
		this.semiconductorSupplierForm.reset();
		this.onReset();
		this.tier1EComponentShortageAdminService.getAllSemiconductorSupplierNames().subscribe((data) => {
			this.semiconductorSupplierList = [];
			// @ts-ignore
			this.semiconductorSupplierList = data;
		});
	}

	retrieveAllSemiSupplierAdminData() {
		this.tier1EComponentShortageAdminService.getAllSemiSupplierAdminDataForTable().subscribe((data) => {
			this.semisupplierAdminDataList = data;
		});
	}

	showDataFilters() {
		this.isDisplayFilterData = !this.isDisplayFilterData;
	}

	getSemiconductorSupplierAdmin(event) {
		const semiconductorSupplierModel = new SemiconductorSupplierAdmin();
		// tslint:disable-next-line:max-line-length
		semiconductorSupplierModel.semiconductorSupplierName = this.selectedSemiconductorSupplier.name;
		console.log('selected semi supplier : ', this.selectedSemiconductorSupplier.name);
		this.tier1EComponentShortageAdminService.getAdminDataFromSemiconductorSupplier(semiconductorSupplierModel).subscribe((data) => {
			this.semiconductorSupplierAdmin = data;
			console.log('semi supplier data  : ', this.semiconductorSupplierAdmin);
			if (!(this.semiconductorSupplierAdmin)) {
				this.showSubmit = true;
				this.showUpdate = false;
			} else {
				console.log('new semi supplier at 89 ? : ', this.semiconductorSupplierAdmin.semisupplierEmail);
				this.showUpdate = true;
				this.showSubmit = false;
			}
			this.semiconductorSupplierForm.patchValue({semisupplierEmail: this.semiconductorSupplierAdmin.semisupplierEmail});
		});
		this.onReset();
	}

	createSemiSupplierInfo() {
		console.log('selected semi supplier : ', this.selectedSemiconductorSupplier.name);
		this.createSemiconductorSupplierAdmin = this.semiconductorSupplierForm.getRawValue() as SemiconductorSupplierAdmin;
		this.createSemiconductorSupplierAdmin.semiconductorSupplierName = this.selectedSemiconductorSupplier.name;
		console.log('created semi supplier : ', this.createSemiconductorSupplierAdmin);
		if (!this.validateContactDetails(this.createSemiconductorSupplierAdmin)) {
			this.isContactFormValid = false;
			this.pushMessage('error', 'Error', 'Please fill all mandatory fields in Contact Details Form.');
		} else {
			this.isContactFormValid = true;
		}
		if (this.isContactFormValid) {
			// tslint:disable-next-line:max-line-length
		this.tier1EComponentShortageAdminService.checkSemiconductorSupplierExistence(this.createSemiconductorSupplierAdmin.semiconductorSupplierName).subscribe(isTier1SupplierExists => {
			if (isTier1SupplierExists) {
				// tslint:disable-next-line:max-line-length
				this.pushMessage('error', 'Error', 'Semiconductor Supplier : ' + this.createSemiconductorSupplierAdmin.semiconductorSupplierName + ' already Exists');
			} else {
				// tslint:disable-next-line:max-line-length
				this.tier1EComponentShortageAdminService.createSemiconductorSupplierAdmin(this.createSemiconductorSupplierAdmin).subscribe(response => {
					this.createSemiconductorSupplierAdmin = response;
					console.log('Success: Shortage Tracker Contact Details Id #' + response.id + ' has been created successfully');
					// tslint:disable-next-line:max-line-length
					this.pushMessage('success', 'Success', ' Creation : Semiconductor Supplier Details of  " ' + this.createSemiconductorSupplierAdmin.semiconductorSupplierName + ' "  has been Created successfully');
					this.semiconductorSupplierForm.reset();
					this.retrieveAllSemiSupplierAdminData();
				});
			}
		});
		}
	}

	private  validateContactDetails(newSemiconductorSupplierAdmin: SemiconductorSupplierAdmin): boolean {
		let isContactFormValid = false;
		console.log('Contact Form Valid : ', isContactFormValid);
		console.log('Contact Form Valid email : ', newSemiconductorSupplierAdmin.semisupplierEmail);

		if ((newSemiconductorSupplierAdmin.semiconductorSupplierName && newSemiconductorSupplierAdmin.semiconductorSupplierName.trim() !== '') &&
			(newSemiconductorSupplierAdmin.semisupplierEmail && newSemiconductorSupplierAdmin.semisupplierEmail.trim() !== '')) {

			isContactFormValid = true;
			console.log('Contact Form Valid');
		}
		console.log('Contact Form Valid : ', isContactFormValid);
		return isContactFormValid;
	}

	onReset() {
		this.semiconductorSupplierForm.controls['semisupplierEmail'].reset();
		this.isCreate = false;
		this.isEdit = false;
	}

	onSubmit() {
		this.isEdit = false;
		this.isCreate = true;
		this.showSubmit = true;
		this.onReset();
	}

	updateSemiSupplierInfo() {
		this.newSemiconductorSupplierAdmin = this.semiconductorSupplierForm.getRawValue() as SemiconductorSupplierAdmin;
		this.newSemiconductorSupplierAdmin.id = this.semiconductorSupplierAdmin.id;
		this.newSemiconductorSupplierAdmin.semiconductorSupplierName = this.selectedSemiconductorSupplier.name;
		this.tier1EComponentShortageAdminService.updateSemiconductorSupplierAdmin(this.newSemiconductorSupplierAdmin).subscribe(response => {
			this.newSemiconductorSupplierAdmin = response;
			console.log('Success: Shortage Tracker Contact Details Id #' + response.id + ' has been Updated successfully');
			// tslint:disable-next-line:max-line-length
			this.pushMessage('success', 'Success', ' Updation : Semiconductor Supplier Details of  " ' + this.newSemiconductorSupplierAdmin.semiconductorSupplierName + ' "  has been Updated successfully');
			this.semiconductorSupplierForm.reset();
			this.getSemiconductorSupplierList();
			// tslint:disable-next-line:indent
			this.retrieveAllSemiSupplierAdminData();
		});

	}

	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 60000
		});
	}

	adminScreen() {
		this.router.navigate(['/tier1-e-component-shortage-admin']);
	}
}
