import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Survey} from '../model/survey.model';
import {
	lrSurveyJSON,
	surveyJSON
} from '../../../assets/survey';
//import {surveyJSON} from '../../../assets/survey-test-data';
import * as SurveyJS from 'survey-angular';
import {SurveyModel} from 'survey-angular';
import {Supplier} from '../model/supplier.model';
import {
	Observable,
	throwError
} from 'rxjs';
import {
	catchError,
	map
} from 'rxjs/operators';

@Injectable()
export class SurveyService {
	private SURVEY_ENDPOINT = environment.apiUrl + 'surveys';
	private _completedSurvey: Survey;
	isLaunchSruvey = false;
	/** Creates a new instance of the SurveyService
	 *
	 * @param http used for calls to the agent API
	 * @param messageService used to display success/failure messages
	 * @param errorHandler handles generic http request errors
	 */
	constructor(private http: HttpClient) {
	}

	showSurvey( supplierId: number, surveyResult?) {
		SurveyJS.StylesManager.applyTheme('darkblue');
		const surveyModel = new SurveyJS.Model(this.isLaunchSruvey ? lrSurveyJSON : surveyJSON);
		surveyModel.completedHtml =
			`<h3>Thank you for completing the survey! Please use the <b>Print Survey</b> button to print</h3>`;
		this.emptyCompletedSurvey(); //emptying the previous survey result
		if (surveyResult) {
			surveyModel.data = surveyResult;
			if (sessionStorage.getItem('dashboard-role') === 'true') {
				surveyModel.mode = 'display';
			} else {
				surveyModel.mode = 'edit';
			}
			this._completedSurvey = this.calculateOverallRating(supplierId, surveyModel);
			this.showSurveyReview(surveyResult);
		}
		SurveyJS.SurveyNG.render('surveyElement', {model: surveyModel});
		surveyModel.onComplete.add((surveyAnswerModel) => {
			this.save(supplierId, surveyAnswerModel).subscribe((survey) => {
				this._completedSurvey = survey;
				this.showSurveyReview(JSON.parse(survey.surveyAnswer));
			});
		});
	}

	showSurveyReview(surveyAnswer) {
		const surveyModel = new SurveyJS.Model(this.isLaunchSruvey ? lrSurveyJSON : surveyJSON);
		surveyModel.data = surveyAnswer;
		surveyModel.mode = 'display';
		surveyModel.isSinglePage = true;
		surveyModel.showProgressBar = 'off';
		SurveyJS.SurveyNG.render('surveyReviewElement', {model: surveyModel});
	}

	get completedSurvey(): Survey {
		return this._completedSurvey;
	}

	set completedSurvey(value: Survey) {
		this._completedSurvey = value;
	}

	/** Gets a list of cites based on a country and state code
	 *
	 * @param supplier The supplier for the survey
	 * @returns a http response
	 */
	get(supplier: Supplier) {
		const params = {
			supplierId: supplier.supplierId
		};

		// Get the survey from backend API - HTTP-POST
		return this.http.get(this.SURVEY_ENDPOINT, Object.assign({responseType: 'json', params}));
	}

	/** Saves a Survey object
	 * a UUID already or not (uuid is created by backend)
	 * @param supplierId the id of the supplier
	 * @param surveyModel the SurveyJS model to manipulate for save
	 * @returns a http response
	 */
	save(supplierId: number, surveyModel: SurveyModel): Observable<Survey> {
		const calculatedSurvey = this.calculateOverallRating(supplierId, surveyModel);
		return this.http.post(this.SURVEY_ENDPOINT, calculatedSurvey, Object.assign({responseType: 'json'}))
			.pipe(
				map((surveyResponse) => {
					return surveyResponse['result'].surveyAnswerApi as Survey;
				}),
				catchError(errorResponse => {
					return throwError(errorResponse.error);
				})
			);
	}

	/** Calculates the overall rating for a survey
	 * a UUID already or not (uuid is created by backend)
	 * @param supplierId the id of the supplier
	 * @param surveyModel the SurveyJS model to manipulate for save
	 * @returns a Survey object
	 */
	calculateOverallRating(supplierId: number, surveyModel: SurveyModel): Survey {
		const ratingCounts = {
			green: 0,
			yellow: 0,
			red: 0,
			overall: ''
		};
		let overallRating = '';
		for (const key of Object.keys(surveyModel.data)) {
			if (key.indexOf('overallRating') >= 0) {
				if (surveyModel.data[key] === 'G') {
					ratingCounts.green = ratingCounts.green + 1;
				} else if (surveyModel.data[key] === 'Y') {
					ratingCounts.yellow = ratingCounts.yellow + 1;
				} else if (surveyModel.data[key] === 'R') {
					ratingCounts.red = ratingCounts.red + 1;
				}
			}
		}
		if (ratingCounts.green === surveyModel.pageCount) {
			overallRating = 'Green';
		} else if (ratingCounts.red >= 1) {
			overallRating = 'Red';
		} else {
			overallRating = 'Yellow';
		}

		const survey: Survey = {
			supplierId,
			surveyAnswer: JSON.stringify(surveyModel.data),
			surveyOverallRating: overallRating
		};
		return survey;
	}

	emptyCompletedSurvey() {
		this._completedSurvey = undefined;
	}

	getSurveyAnsewr(gsdb: string, commodityCode: string) {
		return this.http.get(environment.apiUrl + 'lrsuppliers/' + gsdb + '/surveyanswer/' + commodityCode).pipe(
			map((surveyResponse) => {
				return surveyResponse['result'].surveyAnswerApi as Survey;
			}),
			catchError(errorResponse => {
				return throwError(errorResponse.error);
			})
		);
	}
}
