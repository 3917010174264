import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {T2Visibility} from '../model/t2_visibility';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {T2VisibilityAdmin} from '../model/t2_visibility_admin';
import {T2VisibilityEmailTrigger} from '../model/t2_email_trigger';

@Injectable({
	providedIn: 'root'
})
export class T2VisibilityService {
	private T2_ENDPOINT = environment.apiUrl + 't2visibility';
	private HELP_ENDPOINT = environment.apiUrl + 'helpContent/download/';
	private T2_ADMIN_ENDPOINT = environment.apiUrl + 't2visibility/notification';
	private GET_T2_VISIBILITY_REPORT = environment.apiUrl + 't2visibility/t2-visibility-template';

	constructor(private http: HttpClient, private spinnerService: SpinnerService) {
	}

	public downloadT2Report(uid: string, reportValue: number, onoffValue: number): Observable<any> {
		return this.http.get(this.T2_ENDPOINT + `/t2_visibility_report/${uid}/${reportValue}/${onoffValue}`, {responseType: 'blob'});
	}

	public downloadT2CleanedReport(): Observable<any> {
		return this.http.get(this.T2_ENDPOINT + `/t2_download_cleaned_report`, {responseType: 'blob'});
	}

	downLoadFile(data: any, type: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = 'T2_Visibility_Report.xlsx';
		anchor.href = url;
		anchor.click();
	}

	upload(fileToUpload: File, uid: string, email: string, fordSiteCode: string): Observable<any> {
		const endpoint = this.T2_ENDPOINT + `/upload/${uid}/${email}/${fordSiteCode}`;
		const formData: FormData = new FormData();
		formData.append('File', fileToUpload);
		return this.http.post(endpoint, formData);
	}

	loadT1SupplierSiteNames(): Observable<any> {
		return this.http.get(this.T2_ENDPOINT + '/t1SupplierSiteNames', {responseType: 'json'});
	}

	loadParentCodes(): Observable<any> {
		return this.http.get(this.T2_ENDPOINT + '/parentCodes', {responseType: 'json'});
	}

	loadSupplierEmails() {
		return this.http.get(this.T2_ENDPOINT + '/supplierEmails', {responseType: 'json'});
	}

	loadSupplierEmailsBasedOnT1SupplierSite(t2Visibility: T2Visibility) {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.T2_ENDPOINT + '/supplierEmailsFromT1SupplierSites', t2Visibility, {responseType: 'json'});
	}

	loadParentCodesBasedOnT1SupplierSite(t2Visibility: T2Visibility) {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.T2_ENDPOINT + '/parentCodesFromT1SupplierSites', t2Visibility, {responseType: 'json'});
	}

	loadSupplierEmailsBasedOnT1SupplierSiteAndParentCodes(t2Visibility: T2Visibility) {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.T2_ENDPOINT + '/supplierEmailsFromT1SupplierSitesAndParentCodes', t2Visibility, {responseType: 'json'});
	}

	generateFordAdminReport(t2Visibility: T2Visibility): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.T2_ENDPOINT + '/generateFordAdminReport', t2Visibility, {responseType: 'blob'});
		// .subscribe((response) => {
		// 	this.downLoadFile(response, 'text/csv');
		// });
	}

	generateRawReport(t2Visibility: T2Visibility): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.T2_ENDPOINT + '/generateRawT2Report', t2Visibility, {responseType: 'blob'});
	}

	removeDuplicatesAdhoc(): Observable<any> {
		// @ts-ignore
		return this.http.delete(this.T2_ENDPOINT + '/generateRemoveDuplicatesAdhoc');
	}

	uploadAdminFile(fileToUpload: File): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('File', fileToUpload);
		return this.http.post(this.T2_ENDPOINT + '/upload_admin_excel_sheet', formData, {responseType: 'blob'});
	}

	getAllAdminNotifications(): Observable<any> {
		return this.http.get(this.T2_ADMIN_ENDPOINT + '/allNotifications', {responseType: 'json'});
	}

	createNotification(t2VisibilityAdmin: T2VisibilityAdmin): Observable<any> {
		return this.http.post(this.T2_ADMIN_ENDPOINT + '/create', t2VisibilityAdmin, {responseType: 'json'});
	}

	updateNotification(t2VisibilityAdmin: T2VisibilityAdmin): Observable<any> {
		return this.http.post(this.T2_ADMIN_ENDPOINT + '/update', t2VisibilityAdmin, {responseType: 'json'});
	}

	getAllActiveSupplierNotifications(): Observable<any> {
		return this.http.get(this.T2_ADMIN_ENDPOINT + '/activeNotifications', {responseType: 'json'});
	}

	createOrUpdateEmailDraft(templateDraft): Observable<any> {
		return this.http.post(this.T2_ENDPOINT + '/createOrUpdateEmailDraft', templateDraft, {responseType: 'json'});
	}

	findAllEmailTemplateName(businessName: any): Observable<any> {
		return this.http.get(this.T2_ENDPOINT + `/getAllEmailTemplates/${businessName}`, {responseType: 'json'});
	}

	downloadAdministratorDetailsExcel(): Observable<any> {
		return this.http.get(this.T2_ENDPOINT + '/t2_visibility_administrator', {responseType: 'blob'});
	}

	triggerEmailForT2(t2TriggerEmailModel: T2VisibilityEmailTrigger) {
		return this.http.post(this.T2_ENDPOINT + '/triggerT2Email', t2TriggerEmailModel, {responseType: 'text'});
	}

	uploadCleanedReport(fileToUpload: File): Observable<any> {
		const endpoint = this.T2_ENDPOINT + `/uploadCleanedReport`;
		const formData: FormData = new FormData();
		formData.append('File', fileToUpload);
		return this.http.post(endpoint, formData);
	}

	checkIfLessThanFiveSuppliers(uId: any) {
		return this.http.get(this.T2_ENDPOINT + `/checkExistence/${uId}`, {responseType: 'json'});
	}

	confirmationStatusUpdate(uId: any) {
		return this.http.get(this.T2_ENDPOINT + `/confirmationStatusUpdate/${uId}`, {responseType: 'json'});
	}

	public helpEndPointDownload(temp: any): Observable<any> {
		return this.http.get(this.HELP_ENDPOINT + temp, {responseType: 'blob'});
	}

	downloadFileMp4(data: any, type: string, fileName: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = fileName + '.mp4';
		anchor.href = url;
		anchor.click();
	}

	downLoadFileDocument(data: any, type: string, fileName: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = fileName + '.docx';
		anchor.href = url;
		anchor.click();
	}

	triggerEmailForT2Count(t2TriggerEmailModel: T2VisibilityEmailTrigger) {
		return this.http.post(this.T2_ENDPOINT + '/triggerT2EmailCount', t2TriggerEmailModel, {responseType: 'json'});
	}
}
