import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {TigerSharkConstraintTrackerRoutingModule} from './tiger-shark-constraint-tracker-routing.module';
// tslint:disable-next-line:max-line-length
import {TigerSharkConstraintTrackerDashboardComponent} from './tiger-shark-constraint-tracker-dashboard/tiger-shark-constraint-tracker-dashboard.component';
import {TigerSharkConstraintTrackerComponent} from './tiger-shark-constraint-tracker/tiger-shark-constraint-tracker.component';
// tslint:disable-next-line:max-line-length
import {TigerSharkConstraintTrackerAdminComponent} from './tiger-shark-constraint-tracker-admin/tiger-shark-constraint-tracker-admin.component';
import {ButtonModule} from 'primeng/button';
import {
	FormsModule,
	ReactiveFormsModule
} from '@angular/forms';
import {DropdownModule} from 'primeng/dropdown';
import {MessageModule} from 'primeng/message';
import {CalendarModule} from 'primeng/calendar';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ListboxModule} from 'primeng/listbox';
import {CheckboxModule} from 'primeng/checkbox';
import {AccordionModule} from 'primeng/accordion';
import {TableModule} from 'primeng/table';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputTextModule} from 'primeng/inputtext';
// tslint:disable-next-line:max-line-length

// @ts-ignore
import {IgxSpreadsheetModule} from 'igniteui-angular-spreadsheet';

import {FileUploadModule} from 'primeng/fileupload';
import { SchedularComponent } from './schedular/schedular.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import {PanelModule} from 'primeng/panel';
import {
	DialogModule,
	FieldsetModule,
	MultiSelectModule,
	ScrollPanelModule,
	TabViewModule,
	ToggleButtonModule
// tslint:disable-next-line:import-blacklist
} from 'primeng/primeng';

@NgModule({
	// tslint:disable-next-line:max-line-length
	declarations: [TigerSharkConstraintTrackerDashboardComponent, TigerSharkConstraintTrackerComponent, TigerSharkConstraintTrackerAdminComponent, SchedularComponent],
	imports: [
		NgMultiSelectDropDownModule.forRoot(),
		CommonModule,
		TigerSharkConstraintTrackerRoutingModule,
		ButtonModule,
		ReactiveFormsModule,
		DropdownModule,
		MessageModule,
		CalendarModule,
		ReactiveFormsModule,
		AutoCompleteModule,
		FormsModule,
		ListboxModule,
		CheckboxModule,
		AccordionModule,
		TableModule,
		InputTextareaModule,
		InputTextModule,
		FileUploadModule,
		IgxSpreadsheetModule,
		PanelModule,
		TabViewModule,
		FieldsetModule,
		DialogModule,
		ScrollPanelModule,
		ToggleButtonModule,
		MultiSelectModule
	]
})
export class TigerSharkConstraintTrackerModule {
}
