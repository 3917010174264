import {
	Component,
	HostListener,
	OnInit
} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {SupplierService} from '../home/services/supplier.service';
import {
	NTierMasterAdminService
} from '../tier1-visibility-survey/n-tier-master-admin/service/n-tier-master-admin.service';
import {
	ValueStreamDataCollectionUserComponent
} from '../tier1-visibility-survey/value-stream-map/value-stream-data-collection-user/value-stream-data-collection-user.component';

/** Angular component that utilizes the PrimeNG p-menubar component
 * to display the navigation menu for the application
 */
@Component({
	selector: 'app-nav',
	templateUrl: './nav.component.html',
	styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

	/** Create a nav component and populate with necessary MenuItems (static data) */
	constructor(private supplierService: SupplierService, private masterAdminService: NTierMasterAdminService) {
		this.navMenuItems = [{
			label: 'Home',
			routerLink: '/landing',
			visible: true,
			id: 'landing'
		}];
	}

	/** Array of PrimeNG MenuItems that are used to populate the p-menubar */
	navMenuItems: MenuItem[];
	// nTierSubMenuItems: MenuItem[];
	/** whether or not the sidebar is open or not */
	sidebarVisible: boolean;
	employeeType = '';
	/** toggles visibility of sidebar to true */
	showSidebar = () => {
		this.sidebarVisible = true;
	}
	/** toggles visibility of sidebar to false */
	hideSidebar = () => {
		this.sidebarVisible = false;
	}

	/** Listener to toggle sidebar to hidden when it is not displayed */
	@HostListener('window:resize', []) onResize() {
		if (this.sidebarVisible === true) {
			const sideBar = document.getElementById('app-sidebar');
			if (window.getComputedStyle(sideBar).display === 'none') {
				this.sidebarVisible = false;
			}
		}
	}

	ngOnInit(): void {
		this.supplierService.selectedMenuSubject.subscribe(value => {
			console.log('nav > selectedMenuSubject subscribe ' + value);
			this.navMenuItems = [];
			this.navMenuItems = [{
				label: 'Home',
				routerLink: '/landing',
				visible: true,
				id: 'landing'
			}];
			if (value === 'NTierMasterAdmin') {
				this.navMenuItems.push({
					label: 'Master Admin',
					routerLink: '/n-tier-master-admin',
					command: this.hideSidebar
				});
			} else if (value === 'sustainability survey') {
				// tslint:disable-next-line:max-line-length
				if (sessionStorage.getItem('uid').includes('-')) {
					this.navMenuItems = [];

					this.navMenuItems = [{
						label: 'Home',
						routerLink: '/landing',
						visible: true,
						id: 'landing'
					}];
					this.navMenuItems.push({
						label: 'Sustainability Survey',
						routerLink: '/sustainability-survey',
						visible: true,
						command: this.hideSidebar,
						id: 'SustainabilitySurveyComponent'
					});
					this.navMenuItems.push({
						label: 'Help',
						routerLink: '/help-tab',
						visible: true,
						command: this.hideSidebar,
						id: 'SustainabilitySurveyHelpTabComponent'
					});
				}
				// tslint:disable-next-line:max-line-length
				this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Sustainability Survey Super Admin').subscribe(userHasSustainabilityAdminAccess => {
					if (userHasSustainabilityAdminAccess) {
						this.navMenuItems.push({
							label: 'Sustainability Survey',
							routerLink: '/sustainability-survey',
							visible: true,
							command: this.hideSidebar,
							id: 'SustainabilitySurveyComponent'
						});
						this.navMenuItems.push({
							label: 'E-Mail Setup',
							routerLink: '/email-setup',
							visible: true,
							command: this.hideSidebar,
							id: 'T2VisibilityEmailComponent'
						});
						this.navMenuItems.push({
							label: 'Supplier Access Control',
							routerLink: '/supplier-access',
							visible: true,
							command: this.hideSidebar,
							id: 'SustainabilitySupplierAccessComponent'
						});
						this.navMenuItems.push({
							label: 'Help',
							routerLink: '/help-tab',
							visible: true,
							command: this.hideSidebar,
							id: 'SustainabilitySurveyHelpTabComponent'
						});
						this.navMenuItems.push({
							label: 'Access Control',
							routerLink: '/n-tier-master-admin',
							command: this.hideSidebar
						});
						// tslint:disable-next-line:max-line-length
					} else {
						// tslint:disable-next-line:max-line-length
						this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Sustainability Survey Screen').subscribe(userHasT2Access => {
							if (userHasT2Access) {
								this.navMenuItems.push({
									label: 'Sustainability Survey',
									routerLink: '/sustainability-survey',
									visible: true,
									command: this.hideSidebar,
									id: 'SustainabilitySurveyComponent'
								});
								this.navMenuItems.push({
									label: 'Help',
									routerLink: '/help-tab',
									visible: true,
									command: this.hideSidebar,
									id: 'SustainabilitySurveyHelpTabComponent'
								});
								// tslint:disable-next-line:max-line-length
								this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Sustainability Survey Access Control Admin').subscribe(userHasSustainabilityAccessControlAdminAccess => {
									if (userHasSustainabilityAccessControlAdminAccess) {
										this.navMenuItems.push({
											label: 'Access Control',
											routerLink: '/n-tier-master-admin',
											command: this.hideSidebar
										});
									}
								});
							} else {
								// tslint:disable-next-line:max-line-length
								this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Sustainability Survey Access Control Admin').subscribe(userHasSustainabilityAccessControlAdminAccess => {
									if (userHasSustainabilityAccessControlAdminAccess) {
										this.navMenuItems.push({
											label: 'Access Control',
											routerLink: '/n-tier-master-admin',
											command: this.hideSidebar
										});
										this.navMenuItems.push({
											label: 'Help',
											routerLink: '/help-tab',
											visible: true,
											command: this.hideSidebar,
											id: 'SustainabilitySurveyHelpTabComponent'
										});
									}
								});
							}
						});
					}
				});
			} else if (value === 'Project R') {
				// tslint:disable-next-line:max-line-length
				if (sessionStorage.getItem('uid').includes('-')) {
					this.navMenuItems = [];

					this.navMenuItems = [{
						label: 'Home',
						routerLink: '/landing',
						visible: true,
						id: 'landing'
					}];
					this.navMenuItems.push({
						label: 'Targeted N-Tier Survey',
						routerLink: '/project-r-non-microchip',
						visible: true,
						command: this.hideSidebar,
						id: 'SustainabilitySurveyComponent'
					});
					this.navMenuItems.push({
						label: 'Help',
						routerLink: '/help-tab',
						visible: true,
						command: this.hideSidebar,
						id: 'SustainabilitySurveyHelpTabComponent'
					});
				}
				// tslint:disable-next-line:max-line-length
				this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Project-R Non Microchip Survey Super Admin').subscribe(userHasSustainabilityAdminAccess => {
					if (userHasSustainabilityAdminAccess) {
						this.navMenuItems.push({
							label: 'Targeted N-Tier Survey',
							routerLink: '/project-r-non-microchip',
							visible: true,
							command: this.hideSidebar,
							id: 'SustainabilitySurveyComponent'
						});
						this.navMenuItems.push({
							label: 'E-Mail Setup',
							routerLink: '/email-setup',
							visible: true,
							command: this.hideSidebar,
							id: 'T2VisibilityEmailComponent'
						});
						this.navMenuItems.push({
							label: 'Supplier Access Control',
							routerLink: '/supplier-access',
							visible: true,
							command: this.hideSidebar,
							id: 'SustainabilitySupplierAccessComponent'
						});
						this.navMenuItems.push({
							label: 'Help',
							routerLink: '/help-tab',
							visible: true,
							command: this.hideSidebar,
							id: 'SustainabilitySurveyHelpTabComponent'
						});
						this.navMenuItems.push({
							label: 'Access Control',
							routerLink: '/n-tier-master-admin',
							command: this.hideSidebar
						});
						// tslint:disable-next-line:max-line-length
					} else {
						// tslint:disable-next-line:max-line-length
						this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Project-R Non Microchip Survey Screen').subscribe(userHasT2Access => {
							if (userHasT2Access) {
								this.navMenuItems.push({
									label: 'Targeted N-Tier Survey',
									routerLink: '/project-r-non-microchip',
									visible: true,
									command: this.hideSidebar,
									id: 'SustainabilitySurveyComponent'
								});
								this.navMenuItems.push({
									label: 'Help',
									routerLink: '/help-tab',
									visible: true,
									command: this.hideSidebar,
									id: 'SustainabilitySurveyHelpTabComponent'
								});
								// tslint:disable-next-line:max-line-length
								this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Project-R Non Microchip Survey Access Control Admin').subscribe(userHasSustainabilityAccessControlAdminAccess => {
									if (userHasSustainabilityAccessControlAdminAccess) {
										this.navMenuItems.push({
											label: 'Access Control',
											routerLink: '/n-tier-master-admin',
											command: this.hideSidebar
										});
									}
								});
							} else {
								// tslint:disable-next-line:max-line-length
								this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Project-R Non Microchip Survey Access Control Admin').subscribe(userHasSustainabilityAccessControlAdminAccess => {
									if (userHasSustainabilityAccessControlAdminAccess) {
										this.navMenuItems.push({
											label: 'Access Control',
											routerLink: '/n-tier-master-admin',
											command: this.hideSidebar
										});
										this.navMenuItems.push({
											label: 'Help',
											routerLink: '/help-tab',
											visible: true,
											command: this.hideSidebar,
											id: 'SustainabilitySurveyHelpTabComponent'
										});
									}
								});
							}
						});
					}
				});
			} else if (value === 'ConsolidatedDashboard') {
				this.navMenuItems.push({
					label: 'Smashed Data Reporting',
					routerLink: '/smashed-data-reporting',
					visible: true,
					command: this.hideSidebar
				});
				this.navMenuItems.push({
					label: 'N-Tier Location Dashboard',
					routerLink: ['/qlik-sense-dashboard'],
					visible: true,
					command: this.hideSidebar,
					id: 'QlikSenseDashboardComponent'
				});
			// } else if (value === 'T2Visibility') {
			// 	if (sessionStorage.getItem('uid').includes('-')) {
			// 		this.navMenuItems = [];
			// 		this.navMenuItems.push({
			// 			label: 'T2 Visibility',
			// 			routerLink: '/t2-visibility',
			// 			visible: true,
			// 			command: this.hideSidebar,
			// 			id: 'T2VisibilityComponent'
			// 		});
			// 		this.navMenuItems.push({
			// 			label: 'Help',
			// 			routerLink: '/t2-visibility-help',
			// 			visible: true,
			// 			command: this.hideSidebar,
			// 			id: 'T2VisibilityHelpTabComponent'
			// 		});
			// 	} else {
			// 		// tslint:disable-next-line:max-line-length
			// 		this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'T2 Visibility Super Admin').subscribe(userHasT2AdminAccess => {
			// 			if (userHasT2AdminAccess) {
			// 				this.navMenuItems.push({
			// 					label: 'T2 Visibility Dashboard',
			// 					routerLink: '/t2-visibility-dashboard',
			// 					visible: true,
			// 					command: this.hideSidebar,
			// 					id: 'T2VisibilityDashboardComponent'
			// 				});
			// 				this.navMenuItems.push({
			// 					label: 'T2 Visibility Notification',
			// 					routerLink: '/t2-visibility-admin',
			// 					visible: true,
			// 					command: this.hideSidebar,
			// 					id: 'T2VisibilityAdminComponent'
			// 				});
			// 				this.navMenuItems.push({
			// 					label: 'E-Mail Setup',
			// 					routerLink: '/t2-visibility-email',
			// 					visible: true,
			// 					command: this.hideSidebar,
			// 					id: 'T2VisibilityEmailComponent'
			// 				});
			// 				this.navMenuItems.push({
			// 					label: 'SuperG Data Administration',
			// 					routerLink: '/t2-visibility-administrator',
			// 					visible: true,
			// 					command: this.hideSidebar,
			// 					id: 'T2VisibilityAdministratorComponent'
			// 				});
			// 				this.navMenuItems.push({
			// 					label: 'Qlik Sense Progress Dashboard',
			// 					routerLink: ['/qlik-sense-dashboard'],
			// 					visible: true,
			// 					command: this.hideSidebar,
			// 					id: 'QlikSenseDashboardComponent'
			// 				});
			//
			// 				this.navMenuItems.push({
			// 					label: 'Help',
			// 					routerLink: '/t2-visibility-help',
			// 					visible: true,
			// 					command: this.hideSidebar,
			// 					id: 'T2VisibilityHelpTabComponent'
			// 				});
			//
			// 				// tslint:disable-next-line:max-line-length
			// 				this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'T2 Visibility Access Control Admin').subscribe(userHasT2AccessControlAdminAccess => {
			// 					if (userHasT2AccessControlAdminAccess) {
			// 						this.navMenuItems.push({
			// 							label: 'Access Control',
			// 							routerLink: '/n-tier-master-admin',
			// 							command: this.hideSidebar
			// 						});
			// 					}
			// 				});
			// 			} else {
			// 				// tslint:disable-next-line:max-line-length
			// 				this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'T2 Visibility Screen').subscribe(userHasT2Access => {
			// 					if (userHasT2Access) {
			// 						this.navMenuItems.push({
			// 							label: 'T2 Visibility Dashboard',
			// 							routerLink: '/t2-visibility-dashboard',
			// 							visible: true,
			// 							command: this.hideSidebar,
			// 							id: 'T2VisibilityDashboardComponent'
			// 						});
			// 						this.navMenuItems.push({
			// 							label: 'Help',
			// 							routerLink: '/t2-visibility-help',
			// 							visible: true,
			// 							command: this.hideSidebar,
			// 							id: 'T2VisibilityHelpTabComponent'
			// 						});
			// 						// tslint:disable-next-line:max-line-length
			// 						this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'T2 Visibility Access Control Admin').subscribe(userHasT2AccessControlAdminAccess => {
			// 							if (userHasT2AccessControlAdminAccess) {
			// 								this.navMenuItems.push({
			// 									label: 'Access Control',
			// 									routerLink: '/n-tier-master-admin',
			// 									command: this.hideSidebar
			// 								});
			// 							}
			// 						});
			// 					} else {
			// 						// tslint:disable-next-line:max-line-length
			// 						this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'T2 Visibility Access Control Admin').subscribe(userHasT2AccessControlAdminAccess => {
			// 							if (userHasT2AccessControlAdminAccess) {
			// 								this.navMenuItems.push({
			// 									label: 'Access Control',
			// 									routerLink: '/n-tier-master-admin',
			// 									command: this.hideSidebar
			// 								});
			// 								this.navMenuItems.push({
			// 									label: 'Help',
			// 									routerLink: '/t2-visibility-help',
			// 									visible: true,
			// 									command: this.hideSidebar,
			// 									id: 'T2VisibilityHelpTabComponent'
			// 								});
			// 							}
			// 						});
			// 					}
			// 				});
			// 			}
			// 		});
			// 	}
			}else if (value === 'T2Visibility Data Refresh') {
				//Todo T2V Data Refresh Decommissioned

				// if (sessionStorage.getItem('uid').includes('-')) {
				// 	this.navMenuItems = [];
				// 	this.navMenuItems.push({
				// 		label: 'T2 Visibility Data Refresh',
				// 		routerLink: '/t2-visibility-data-refresh-spreedsheet',
				// 		visible: true,
				// 		command: this.hideSidebar,
				// 		id: 'T2VisibilityDataRefreshSpreedsheetComponent'
				// 	});
				// 	this.navMenuItems.push({
				// 		label: 'Help',
				// 		routerLink: '/t2v-data-refresh-help',
				// 		visible: true,
				// 		command: this.hideSidebar,
				// 		id: 'T2vDataRefreshHelpComponent'
				// 	});
				// } else {
				// 	// tslint:disable-next-line:max-line-length
				// 	this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'T2 Visibility Super Admin').subscribe(userHasT2AdminAccess => {
				// 		if (userHasT2AdminAccess) {
				// 			this.navMenuItems.push({
				// 				label: 'T2 Visibility Data Refresh',
				// 				routerLink: '/t2-visibility-data-refresh',
				// 				visible: true,
				// 				command: this.hideSidebar,
				// 				id: 'T2VisibilityDataRefreshComponent'
				// 			});
				// 			this.navMenuItems.push({
				// 				label: 'T2 Visibility Notification',
				// 				routerLink: '/t2-visibility-admin',
				// 				visible: true,
				// 				command: this.hideSidebar,
				// 				id: 'T2VisibilityAdminComponent'
				// 			});
				// 			this.navMenuItems.push({
				// 				label: 'E-Mail Setup',
				// 				routerLink: '/t2-visibility-email',
				// 				visible: true,
				// 				command: this.hideSidebar,
				// 				id: 'T2VisibilityEmailComponent'
				// 			});
				// 			this.navMenuItems.push({
				// 				label: 'T2 Visibility Data Refresh Administration',
				// 				routerLink: '/t2-visibility-administrator',
				// 				visible: true,
				// 				command: this.hideSidebar,
				// 				id: 'T2VisibilityAdministratorComponent'
				// 			});
				// 			// this.navMenuItems.push({
				// 			// 	label: 'Qlik Sense Progress Dashboard',
				// 			// 	routerLink: ['/qlik-sense-dashboard'],
				// 			// 	visible: true,
				// 			// 	command: this.hideSidebar,
				// 			// 	id: 'QlikSenseDashboardComponent'
				// 			// });
				//
				// 			this.navMenuItems.push({
				// 				label: 'Record Enrichment',
				// 				routerLink: '/record-enrichment',
				// 				visible: true,
				// 				command: this.hideSidebar,
				// 				id: 'T2VisibilityDataRefreshRecordEnrichmentComponent'
				// 			});
				//
				// 			this.navMenuItems.push({
				// 				label: 'Help',
				// 				routerLink: '/t2v-data-refresh-help',
				// 				visible: true,
				// 				command: this.hideSidebar,
				// 				id: 'T2vDataRefreshHelpComponent'
				// 			});
				//
				// 			// tslint:disable-next-line:max-line-length
				// 			this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'T2 Visibility Access Control Admin').subscribe(userHasT2AccessControlAdminAccess => {
				// 				if (userHasT2AccessControlAdminAccess) {
				// 					this.navMenuItems.push({
				// 						label: 'Access Control',
				// 						routerLink: '/n-tier-master-admin',
				// 						command: this.hideSidebar
				// 					});
				// 				}
				// 			});
				// 		} else {
				// 			// tslint:disable-next-line:max-line-length
				// 			this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'T2 Visibility Screen').subscribe(userHasT2Access => {
				// 				if (userHasT2Access) {
				// 					this.navMenuItems.push({
				// 						label: 'T2 Visibility Data Refresh',
				// 						routerLink: '/t2-visibility-data-refresh',
				// 						visible: true,
				// 						command: this.hideSidebar,
				// 						id: 'T2VisibilityDataRefreshComponent'
				// 					});
				// 					this.navMenuItems.push({
				// 						label: 'Help',
				// 						routerLink: '/t2v-data-refresh-help',
				// 						visible: true,
				// 						command: this.hideSidebar,
				// 						id: 'T2vDataRefreshHelpComponent'
				// 					});
				// 					// tslint:disable-next-line:max-line-length
				// 					this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'T2 Visibility Access Control Admin').subscribe(userHasT2AccessControlAdminAccess => {
				// 						if (userHasT2AccessControlAdminAccess) {
				// 							this.navMenuItems.push({
				// 								label: 'Access Control',
				// 								routerLink: '/n-tier-master-admin',
				// 								command: this.hideSidebar
				// 							});
				// 						}
				// 					});
				// 				} else {
				// 					// tslint:disable-next-line:max-line-length
				// 					this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'T2 Visibility Access Control Admin').subscribe(userHasT2AccessControlAdminAccess => {
				// 						if (userHasT2AccessControlAdminAccess) {
				// 							this.navMenuItems.push({
				// 								label: 'Access Control',
				// 								routerLink: '/n-tier-master-admin',
				// 								command: this.hideSidebar
				// 							});
				// 							this.navMenuItems.push({
				// 								label: 'Help',
				// 								routerLink: '/t2v-data-refresh-help',
				// 								visible: true,
				// 								command: this.hideSidebar,
				// 								id: 'T2vDataRefreshHelpComponent'
				// 							});
				// 						}
				// 					});
				// 				}
				// 			});
				// 		}
				// 	});
				// }
			} else if (value === 'clearToBuild') {
				if (sessionStorage.getItem('uid').includes('-')) {
					this.navMenuItems.push({
						label: 'Clear To Build Tracker',
						routerLink: '/clear-to-build-tracker',
						visible: true,
						command: this.hideSidebar,
						id: 'ClearToBuildTrackerComponent'
					});
					this.navMenuItems.push({
						label: 'Help',
						routerLink: ['/clear-to-build-help'],
						visible: true,
						command: this.hideSidebar,
						id: 'ClearToBuildHelpComponent'
					});
				} else {
					// tslint:disable-next-line:max-line-length
					this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build Super Admin').subscribe(userHasCtbSuperAdminAccess => {
						if (userHasCtbSuperAdminAccess) {
							this.navMenuItems.push({
								label: 'Clear To Build Tracker',
								routerLink: '/clear-to-build-tracker',
								visible: true,
								command: this.hideSidebar,
								id: 'ClearToBuildTrackerComponent'
							});
							this.navMenuItems.push({
								label: 'Clear To Build Admin',
								routerLink: '/clear-to-build-admin',
								visible: true,
								command: this.hideSidebar,
								id: 'ClearToBuildAdminComponent'
							});
							this.navMenuItems.push({
								label: 'Help',
								routerLink: ['/clear-to-build-help'],
								visible: true,
								command: this.hideSidebar,
								id: 'ClearToBuildHelpComponent'
							});
							// tslint:disable-next-line:max-line-length
							this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build Access Control Admin').subscribe(userHasCtbAccessControlAdminAccess => {
								if (userHasCtbAccessControlAdminAccess) {
									this.navMenuItems.push({
										label: 'Access Control',
										routerLink: '/n-tier-master-admin',
										command: this.hideSidebar
									});
								}
							});
						} else {
							// tslint:disable-next-line:max-line-length
							this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build COE Admin').subscribe(userHasCtbCoeAdminAccess => {
								if (userHasCtbCoeAdminAccess) {
									this.navMenuItems.push({
										label: 'Clear To Build Tracker',
										routerLink: '/clear-to-build-tracker',
										visible: true,
										command: this.hideSidebar,
										id: 'ClearToBuildTrackerComponent'
									});
									this.navMenuItems.push({
										label: 'Clear To Build Admin',
										routerLink: '/clear-to-build-admin',
										visible: true,
										command: this.hideSidebar,
										id: 'ClearToBuildAdminComponent'
									});
									this.navMenuItems.push({
										label: 'Help',
										routerLink: ['/clear-to-build-help'],
										visible: true,
										command: this.hideSidebar,
										id: 'ClearToBuildHelpComponent'
									});
									// tslint:disable-next-line:max-line-length
									this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build Access Control Admin').subscribe(userHasCtbAccessControlAdminAccess => {
										if (userHasCtbAccessControlAdminAccess) {
											this.navMenuItems.push({
												label: 'Access Control',
												routerLink: '/n-tier-master-admin',
												command: this.hideSidebar
											});
										}
									});
								} else {
									// tslint:disable-next-line:max-line-length
									this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build MP&L Admin').subscribe(userHasMpnlAdminAccess => {
										if (userHasMpnlAdminAccess) {
											this.navMenuItems.push({
												label: 'Clear To Build Tracker',
												routerLink: '/clear-to-build-tracker',
												visible: true,
												command: this.hideSidebar,
												id: 'ClearToBuildTrackerComponent'
											});
											this.navMenuItems.push({
												label: 'Clear To Build Admin',
												routerLink: '/clear-to-build-admin',
												visible: true,
												command: this.hideSidebar,
												id: 'ClearToBuildAdminComponent'
											});
											this.navMenuItems.push({
												label: 'Help',
												routerLink: ['/clear-to-build-help'],
												visible: true,
												command: this.hideSidebar,
												id: 'ClearToBuildHelpComponent'
											});
											// tslint:disable-next-line:max-line-length
											this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build Access Control Admin').subscribe(userHasAccessControlAdminAccess => {
												if (userHasAccessControlAdminAccess) {
													this.navMenuItems.push({
														label: 'Access Control',
														routerLink: '/n-tier-master-admin',
														command: this.hideSidebar
													});
												}
											});
										} else {
											// tslint:disable-next-line:max-line-length
											this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build DRI Access').subscribe(userHasDriAdminAccess => {
												if (userHasDriAdminAccess) {
													this.navMenuItems.push({
														label: 'Clear To Build Admin',
														routerLink: '/clear-to-build-admin',
														visible: true,
														command: this.hideSidebar,
														id: 'ClearToBuildAdminComponent'
													});
													this.navMenuItems.push({
														label: 'Help',
														routerLink: ['/clear-to-build-help'],
														visible: true,
														command: this.hideSidebar,
														id: 'ClearToBuildHelpComponent'
													});
													// tslint:disable-next-line:max-line-length
													this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build Access Control Admin').subscribe(userHasAccessControlAdminAccess => {
														if (userHasAccessControlAdminAccess) {
															this.navMenuItems.push({
																label: 'Access Control',
																routerLink: '/n-tier-master-admin',
																command: this.hideSidebar
															});
														}
													});
												} else {
													// tslint:disable-next-line:max-line-length
													this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build MP&L Super Admin').subscribe(userHasMpnlSuperAdminAccess => {
														if (userHasMpnlSuperAdminAccess) {
															this.navMenuItems.push({
																label: 'Clear To Build Tracker',
																routerLink: '/clear-to-build-tracker',
																visible: true,
																command: this.hideSidebar,
																id: 'ClearToBuildTrackerComponent'
															});
															this.navMenuItems.push({
																label: 'Clear To Build Admin',
																routerLink: '/clear-to-build-admin',
																visible: true,
																command: this.hideSidebar,
																id: 'ClearToBuildAdminComponent'
															});
															this.navMenuItems.push({
																label: 'Help',
																routerLink: ['/clear-to-build-help'],
																visible: true,
																command: this.hideSidebar,
																id: 'ClearToBuildHelpComponent'
															});
															// tslint:disable-next-line:max-line-length
															this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build Access Control Admin').subscribe(userHasAccessControlAdminAccess => {
																if (userHasAccessControlAdminAccess) {
																	this.navMenuItems.push({
																		label: 'Access Control',
																		routerLink: '/n-tier-master-admin',
																		command: this.hideSidebar
																	});
																}
															});
														} else {
															// tslint:disable-next-line:max-line-length
															this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build Screen').subscribe(userHasCtbScreenAccess => {
																if (userHasCtbScreenAccess) {
																	this.navMenuItems.push({
																		label: 'Clear To Build Tracker',
																		routerLink: '/clear-to-build-tracker',
																		visible: true,
																		command: this.hideSidebar,
																		id: 'ClearToBuildTrackerComponent'
																	});
																	this.navMenuItems.push({
																		label: 'Help',
																		routerLink: ['/clear-to-build-help'],
																		visible: true,
																		command: this.hideSidebar,
																		id: 'ClearToBuildHelpComponent'
																	});
																	// tslint:disable-next-line:max-line-length
																	this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build Access Control Admin').subscribe(userHasCtbAccessControlAdminAccess => {
																		if (userHasCtbAccessControlAdminAccess) {
																			this.navMenuItems.push({
																				label: 'Access Control',
																				routerLink: '/n-tier-master-admin',
																				command: this.hideSidebar
																			});
																		}
																	});
																} else {
																	// tslint:disable-next-line:max-line-length
																	this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build Access Control Admin').subscribe(userHasCtbAccessControlAdminAccess => {
																		if (userHasCtbAccessControlAdminAccess) {
																			this.navMenuItems.push({
																				label: 'Access Control',
																				routerLink: '/n-tier-master-admin',
																				command: this.hideSidebar
																			});
																			this.navMenuItems.push({
																				label: 'Help',
																				routerLink: ['/clear-to-build-help'],
																				visible: true,
																				command: this.hideSidebar,
																				id: 'ClearToBuildHelpComponent'
																			});
																		}
																	});
																}
															});
														}
													});
												}
											});
										}
									});
								}
							});
						}
					});
				}
			} else if (value === 'WPP') {
				if (sessionStorage.getItem('uid').includes('-')) {
					this.masterAdminService.isSupplierHasTigerSharkAccess(sessionStorage.getItem('emailAddress')).subscribe((tigerSharkAccess) => {
						if (tigerSharkAccess) {
							this.navMenuItems.push({
								label: 'Tiger Shark N Tier Constraint Shortage',
								routerLink: ['/tiger-shark-constraint-tracker'],
								visible: true,
								command: this.hideSidebar,
								id: 'TigerSharkConstraintTrackerComponent'
							});
						}
					});
				} else {
					this.navMenuItems.push({
						label: 'Tiger Shark N Tier Constraint Shortage',
						routerLink: ['/tiger-shark-constraint-tracker'],
						visible: true,
						command: this.hideSidebar,
						id: 'TigerSharkConstraintTrackerComponent'
					});
					this.navMenuItems.push({
						label: 'TigerShark Constraint Tracker Dashboard',
						routerLink: ['/tiger-shark-constraint-tracker-dashboard'],
						visible: true,
						command: this.hideSidebar,
						id: 'TigerSharkConstraintTrackerDashboardComponent'
					});
					this.navMenuItems.push({
						label: 'TigerShark Constraint Tracker Administrator',
						routerLink: ['/tiger-shark-constraint-tracker-admin'],
						visible: true,
						command: this.hideSidebar,
						id: 'TigerSharkConstraintTrackerAdminComponent'
					});
					// tslint:disable-next-line:max-line-length
					this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'WPP Super Admin').subscribe(userHasWppSuperAdminAccess => {
						if (userHasWppSuperAdminAccess) {
							this.navMenuItems.push({
								label: 'Qlik Sense Progress Dashboard',
								routerLink: ['/qlik-sense-dashboard'],
								visible: true,
								command: this.hideSidebar,
								id: 'QlikSenseDashboardComponent'
							});
						}
					});
				}
			} else if (value === 'VSM') {
				if (sessionStorage.getItem('uid').includes('-')) {
					// this.navMenuItems.push({
					// 	label: 'Value Stream Map',
					// 	routerLink: '/value-stream-data-collection-user',
					// 	visible: true,
					// 	command: this.hideSidebar,
					// 	id: 'ValueStreamDataCollectionUserComponent'
					// });
				} else {
					// tslint:disable-next-line:max-line-length
					this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'VSM Super Admin').subscribe(userHasVsmSuperAdminAccess => {
						if (userHasVsmSuperAdminAccess) {
							this.navMenuItems.push({
								label: 'Value Stream Map',
								routerLink: '/value-stream-data-collection-user',
								visible: true,
								command: this.hideSidebar,
								id: 'ValueStreamDataCollectionUserComponent'
							});
							this.navMenuItems.push({
								label: 'VSM Report Template Data Update Management',
								routerLink: '/value-stream-update-data',
								visible: true,
								command: this.hideSidebar,
								id: 'VsmUserTemplateDataUpdateComponent'
							});
							this.navMenuItems.push({
								label: 'VSM User Template Access Management',
								routerLink: ['/value-stream-admin-column-access'],
								visible: true,
								command: this.hideSidebar,
								id: 'ValueStreamDataAdminColumnAccessComponent'
							});
							this.navMenuItems.push({
								label: 'VSM User Template Access Report',
								routerLink: ['/value-stream-user-report'],
								visible: true,
								command: this.hideSidebar,
								id: 'ValueStreamUserReportComponent'
							});
							this.navMenuItems.push({
								label: 'VSM User Template Audit Report',
								routerLink: ['/value-stream-audit-report'],
								visible: true,
								command: this.hideSidebar,
								id: 'ValueStreamAuditReportComponent'
							});
							this.navMenuItems.push({
								label: 'VSM Data Report Approval',
								routerLink: ['/value-stream-data-report-approval'],
								visible: true,
								command: this.hideSidebar,
								id: 'ValueStreamDataReportApprovalComponent'
							});
							this.navMenuItems.push({
								label: 'VSM Report Template Management',
								routerLink: ['/value-stream-admin-column-template'],
								visible: true,
								command: this.hideSidebar,
								id: 'ValueStreamAdminColumnTemplateComponent'
							});
							// tslint:disable-next-line:max-line-length
							this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'VSM Access Control Admin').subscribe(userHasT2AccessControlAdminAccess => {
								if (userHasT2AccessControlAdminAccess) {
									this.navMenuItems.push({
										label: 'Access Control',
										routerLink: '/n-tier-master-admin',
										command: this.hideSidebar
									});
								}
							});
						} else {
							this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'VSM Screen').subscribe(userHasVsmAccess => {
								if (userHasVsmAccess) {
									this.navMenuItems.push({
										label: 'Value Stream Map',
										routerLink: '/value-stream-data-collection-user',
										visible: true,
										command: this.hideSidebar,
										id: 'ValueStreamDataCollectionUserComponent'
									});
									// tslint:disable-next-line:max-line-length
									this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'VSM Access Control Admin').subscribe(userHasT2AccessControlAdminAccess => {
										if (userHasT2AccessControlAdminAccess) {
											this.navMenuItems.push({
												label: 'Access Control',
												routerLink: '/n-tier-master-admin',
												command: this.hideSidebar
											});
										}
									});
								} else {
									// tslint:disable-next-line:max-line-length
									this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'VSM Access Control Admin').subscribe(userHasT2AccessControlAdminAccess => {
										if (userHasT2AccessControlAdminAccess) {
											this.navMenuItems.push({
												label: 'Access Control',
												routerLink: '/n-tier-master-admin',
												command: this.hideSidebar
											});
										}
									});
								}
							});
						}
					});
				}
			} else if (value === 'SemiSupp') {
				this.employeeType = '';
				if (sessionStorage.getItem('uid').includes('-')) {
					this.masterAdminService.isSupplierHasSemiSuppliersAccess(sessionStorage.getItem('emailAddress')).subscribe((semiSupplierAccess) => {
						if (semiSupplierAccess) {
							this.navMenuItems.push({
								label: 'Tier 1 E-Component Shortage',
								routerLink: ['/tier1-e-component-shortage/true'],
								visible: true,
								command: this.hideSidebar,
								id: 'Tier1EComponentShortageSurvey'
							});
							this.navMenuItems.push({
								label: 'Help',
								routerLink: ['/tier1-e-component-shortage-help'],
								visible: true,
								command: this.hideSidebar,
								id: 'Tier1EComponentShortageHelpComponent'
							});
						}
					});
					// tslint:disable-next-line:max-line-length
					this.masterAdminService.isSupplierHasSeparateSemiconductorSuppliersAccess(sessionStorage.getItem('emailAddress')).subscribe((semiconductorSupplierAccess) => {
						if (semiconductorSupplierAccess) {
							this.navMenuItems.push({
								label: 'Semiconductor Supplier E-Component Shortage ',
								routerLink: ['e-comp-semiconductor-supplier'],
								visible: true,
								command: this.hideSidebar,
								id: 'ECompShortageSemiconductorSupplier'
							});
						}
					});
				} else if (sessionStorage.getItem('employeeType') === 'F') {
					this.employeeType = 'F';
					this.navMenuItems.push({
						label: 'Tier 1 E-Component Shortage',
						routerLink: ['/tier1-e-component-shortage/false'],
						visible: true,
						command: this.hideSidebar,
						id: 'Tier1EComponentShortageSurvey'
					});
					this.eCompAccessManagement();
					// this.navMenuItems.push({
					// 	label: 'Help',
					// 	routerLink: ['/tier1-e-component-shortage-help'],
					// 	visible: true,
					// 	command: this.hideSidebar,
					// 	id: 'Tier1EComponenetShortageHelpComponent'
					// });
				} else {
					this.eCompAccessManagement();
				}
			}
			/*this.navMenuItems.push({
	        label: 'Help',
	        routerLink: '/help',
	        command: this.hideSidebar
            });*/
		});
	}

	eCompAccessManagement() {
		this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'),
			'E-Comp Shortage Super Admin').subscribe(userHasECompSuperAdminAccess => {
			if (userHasECompSuperAdminAccess) {
				if (!(this.employeeType === 'F')) {
					this.navMenuItems.push({
						label: 'Tier 1 E-Component Shortage',
						routerLink: ['/tier1-e-component-shortage/false'],
						visible: true,
						command: this.hideSidebar,
						id: 'Tier1EComponentShortageSurvey'
					});
				}
				this.navMenuItems.push({
					label: 'Tier 1 E-Component Shortage Administrator',
					routerLink: ['/tier1-e-component-shortage-admin'],
					visible: true,
					command: this.hideSidebar,
					id: 'Tier1EComponentShortageAdmin'
				});
				this.navMenuItems.push({
					label: 'E-Mail Setup',
					routerLink: '/email-setup',
					visible: true,
					command: this.hideSidebar,
					id: 'T2VisibilityEmailComponent'
				});
				// tslint:disable-next-line:max-line-length
				this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'E-Comp Shortage Access Control Admin').subscribe(userHasECompAccessControlAdminAccess => {
					if (userHasECompAccessControlAdminAccess) {
						this.navMenuItems.push({
							label: 'Access Control',
							routerLink: '/n-tier-master-admin',
							command: this.hideSidebar
						});
					}
				});
				// if (!(this.employeeType === 'F')) {
				this.navMenuItems.push({
					label: 'Help',
					routerLink: ['/tier1-e-component-shortage-help'],
					visible: true,
					command: this.hideSidebar,
					id: 'Tier1EComponenetShortageHelpComponent'
				});
				// }
			} else {
				this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'),
					'E-Comp Shortage Admin').subscribe(userHasECompAdminAccess => {
					if (userHasECompAdminAccess) {
						if (!(this.employeeType === 'F')) {
							this.navMenuItems.push({
								label: 'Tier 1 E-Component Shortage',
								routerLink: ['/tier1-e-component-shortage/false'],
								visible: true,
								command: this.hideSidebar,
								id: 'Tier1EComponentShortageSurvey'
							});
						}
						// tslint:disable-next-line:max-line-length
						this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'E-Comp Shortage Access Control Admin').subscribe(userHasECompAccessControlAdminAccess => {
							if (userHasECompAccessControlAdminAccess) {
								this.navMenuItems.push({
									label: 'Access Control',
									routerLink: '/n-tier-master-admin',
									command: this.hideSidebar
								});
							}
						});
						// if (!(this.employeeType === 'F')) {
						this.navMenuItems.push({
							label: 'Help',
							routerLink: ['/tier1-e-component-shortage-help'],
							visible: true,
							command: this.hideSidebar,
							id: 'Tier1EComponenetShortageHelpComponent'
						});
						// }
					} else {
						// tslint:disable-next-line:max-line-length
						this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'E-Comp Shortage Access Control Admin').subscribe(userHasECompAccessControlAdminAccess => {
							if (userHasECompAccessControlAdminAccess) {
								this.navMenuItems.push({
									label: 'Access Control',
									routerLink: '/n-tier-master-admin',
									command: this.hideSidebar
								});
								// if (!(this.employeeType === 'F')) {
								this.navMenuItems.push({
									label: 'Help',
									routerLink: ['/tier1-e-component-shortage-help'],
									visible: true,
									command: this.hideSidebar,
									id: 'Tier1EComponenetShortageHelpComponent'
								});
								// }
							} else {
								this.navMenuItems.push({
									label: 'Help',
									routerLink: ['/tier1-e-component-shortage-help'],
									visible: true,
									command: this.hideSidebar,
									id: 'Tier1EComponenetShortageHelpComponent'
								});
							}
						});
					}
				});
			}
		});
	}
}
