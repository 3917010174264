import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class TigerSharkAdminDashboardService {

	constructor(private http: HttpClient, private spinnerService: SpinnerService) {
	}

	private DASHBOARD_ENDPOINT = environment.apiUrl + 'edppAdminReport';

	generateEdppAdminDataInExcel(uid: string) {

		this.http.get(this.DASHBOARD_ENDPOINT + `/generateEdppAdminReport/${uid}`, {responseType: 'blob'})
			.subscribe((response) => {
				this.downLoadFile(response, 'text/csv');
				this.spinnerService.hide();

			});
	}

	downLoadFile(data: any, type: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = 'WPP_Admin_Data.xlsx';
		anchor.href = url;
		anchor.click();
	}

	update(fileToUpload: File): Observable<any> {
		const endpoint = this.DASHBOARD_ENDPOINT + '/update';
		const formData: FormData = new FormData();
		formData.append('File', fileToUpload);
		// @ts-ignore
		return this.http.post(endpoint, formData);
	}
}
