import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IgxSpreadsheetModule} from 'igniteui-angular-spreadsheet';
import {T2VisibilityComponent} from './t2-visibility-spreadsheet/t2-visibility.component';
import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {T2VisibilityDashboardComponent} from './t2-visibility-dashboard/t2-visibility-dashboard.component';
import {MessageModule} from 'primeng/message';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DropdownModule} from 'primeng/dropdown';
import {MultiSelectModule} from 'primeng/multiselect';
import {CarouselModule} from 'primeng/carousel';
import {CardModule} from 'primeng/card';
import {FieldsetModule} from 'primeng/fieldset';
// tslint:disable-next-line:import-blacklist
import {
	CalendarModule,
	ChipsModule,
	OrderListModule,
	ProgressBarModule,
	ScrollPanelModule,
	TabViewModule, ToolbarModule
} from 'primeng/primeng';
import {T2VisibilityAdminComponent} from './t2-visibility-admin/t2-visibility-admin.component';
import {TableModule} from 'primeng/table';
import {DataViewModule} from 'primeng/dataview';
import {PanelModule} from 'primeng/panel';
import {ToastModule} from 'primeng/toast';
import {T2VisibilityEmailComponent} from './t2-visibility-email/t2-visibility-email.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {T2VisibilityAdministratorComponent} from './t2-visibility-administrator/t2-visibility-administrator.component';
import {T2VisibilityHelpTabComponent} from './t2-visibility-help-tab/t2-visibility-help-tab.component';
import {T2VisibilityDataRefreshComponent} from './t2-visibility-data-refresh/t2-visibility-data-refresh.component';
import { T2VisibilityDataRefreshSpreedsheetComponent } from './t2-visibility-data-refresh-spreedsheet/t2-visibility-data-refresh-spreedsheet.component';
import { T2vDataRefreshAlternativeEmailConfigComponent } from './t2v-data-refresh-alternative-email-config/t2v-data-refresh-alternative-email-config.component';
import { T2VDataRefreshHelpComponent } from './t2v-data-refresh-help/t2v-data-refresh-help.component';
import { T2VisibilityDataRefreshRecordEnrichmentComponent } from './t2-visibility-data-refresh-record-enrichment/t2-visibility-data-refresh-record-enrichment.component';

@NgModule({
	declarations: [
		T2VisibilityComponent,
		T2VisibilityDashboardComponent,
		T2VisibilityAdminComponent,
		T2VisibilityEmailComponent,
		T2VisibilityAdministratorComponent,
		T2VisibilityHelpTabComponent,
		T2VisibilityDataRefreshComponent,
		T2VisibilityDataRefreshSpreedsheetComponent,
		T2vDataRefreshAlternativeEmailConfigComponent,
		T2VDataRefreshHelpComponent,
		T2VisibilityDataRefreshRecordEnrichmentComponent
	],
	imports: [
		CommonModule,
		IgxSpreadsheetModule,
		ButtonModule,
		DialogModule,
		MessageModule,
		ReactiveFormsModule,
		DropdownModule,
		MultiSelectModule,
		CarouselModule,
		TableModule,
		PanelModule,
		CardModule,
		FieldsetModule,
		ScrollPanelModule,
		CalendarModule,
		OrderListModule,
		DataViewModule,
		ToastModule,
		CKEditorModule,
		FormsModule,
		ChipsModule,
		ProgressBarModule,
		TabViewModule,
		ToolbarModule
	]
})
export class T2VisibilityModule {
}
