import {NgModule} from '@angular/core';
import {
	RouterModule,
	Routes
} from '@angular/router';

import {AuthGuardService} from '../oauth/auth-guard.service';
import {HomeComponent} from './home.component';

const routes: Routes = [
	{
		path: 'home',
		component: HomeComponent,
		canActivate: [AuthGuardService]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
	providers: []
})
export class HomeRoutingModule {
}
