import {
	Component,
	OnInit
} from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators
} from '@angular/forms';
import {HomeService} from './services/home.service';
import {ActivatedRoute} from '@angular/router';
import {Supplier} from './model/supplier.model';
import {GsdbCode} from './model/gsdbcode.model';
import {SurveyService} from './services/survey.service';
import {SupplierService} from './services/supplier.service';
import {GsdbCodesService} from './services/gsdb-codes.service';
import {CommodityCode} from './model/commoditycode.model';
import {DatePipe} from '@angular/common';
import {Option} from '../home/model/ntier.option';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
	isLaunchSurvey = false;
	covid19SurveyForm: FormGroup;
	showSurvey = true;
	minEndDate: Date;
	today: Date;
	overallRating: string;
	isDisabled = false;
	isValidApprovedSupplierContactEmail = false;
	/** A full list of all supplier GSDB codes */
	gsdbCodes: GsdbCode[] = [];
	/** A list of GSDB codes based on text in the supplierGSDB input field */
	filteredGsdbCodes: GsdbCode[] = [];
	/** A bool to determine if a GSDB code was found during the supplier lookup */
	noGsdbCodeFound: boolean;
	newGsdbCode: string;
	approvedSupplierContactEmailList: string [];
	plantOptions: Option[] = [];
	vlineOptions: Option[] = [];
	selectedPlants: Option[];
	selectedVline: Option[];

	// Launch field declaration starts
	selectedGSDBCode: string;
	selectedCommodityCode: string;
	lrCommodityCodes: CommodityCode[] = [];
	lrGsdbCodes: GsdbCode[] = [];
	displayGsdb: GsdbCode[] = [];
	// programsSupportedOptions: NtierOption[] = [];
	isCopyAvailable = false;
	copyChecked = false;
	answeredCommodityOptions: CommodityCode[] = []; //for copy option
	isSupplierUser = true;
	enteredGSDBCode: string;
	disableFilterBtn = true;
	copyWarnMsg = false;
	copyLastModifiedBy: string;
	copyLastModifiedOn: string;

	// Launch field declaration ends
	/** Creates a new instance of the HomeComponent and initializes required variables
	 *
	 * @param fb Angular form builder to generate agent details form
	 * @param homeService service which manages home component functions
	 * @param surveyService service which manages state of overall Survey flow
	 * @param route used to gather skills data
	 */
	constructor(
		private fb: FormBuilder,
		private homeService: HomeService,
		private surveyService: SurveyService,
		private route: ActivatedRoute,
		private supplerService: SupplierService,
		private gsdbCodesService: GsdbCodesService) {
		//this.gsdbCodes = this.route.snapshot.data['gsdbCodes'];
	}

	ngOnInit() {
		this.retrieveRouteParameter();
		this.copyChecked = false;
		this.approvedSupplierContactEmailList = [];
		const approvedSupplierContactEmailFormControl = new FormControl({
			value: sessionStorage.getItem('emailAddress'),
			disabled: this.isDisabled
		}, [
			Validators.required,
			Validators.pattern(/^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?)+$/)
		]);
		if (sessionStorage.getItem('dashboard-role') === 'true') {
			this.isSupplierUser = false;
		} else {
			this.isSupplierUser = true;
		}
		this.surveyService.isLaunchSruvey = this.isLaunchSurvey;
		if (this.isLaunchSurvey) {
			// For Launch Survey form
			if (this.isSupplierUser) {
				this.gsdbCodesService.getLrGsdbCodes(this.isLaunchSurvey).subscribe(value => {
					this.displayGsdb = value;
				});
			}
			this.covid19SurveyForm = this.fb.group({
				supplierId: [{value: 0, disabled: this.isDisabled}, null],
				supplierLoginId: [{
					value: sessionStorage.getItem('userId'),
					disabled: this.isDisabled
				}, Validators.required],
				supplierName: [{value: '', disabled: true}, Validators.required],
				supplierGSDB: ['', [
					Validators.required,
					Validators.minLength(5)
				]],
				commodityCode: [{value: ''}, Validators.required],
				programManagerName: [{value: '', disabled: true}, Validators.required],
				programManagerEmail: [{value: '', disabled: true}, [Validators.required, Validators.email]],
				pmt: [{value: '', disabled: true}, Validators.required],
				programsSupported: [{value: '', disabled: true}, Validators.required],
				noOfPartNumbers: [{value: '', disabled: true}, Validators.required],
				supplierLocationCountry: [{value: '', disabled: true}, Validators.required],
				supplierLocationCity: [{value: '', disabled: true}, Validators.required],
				dateOfAssessment: [{value: new Date(), disabled: this.isDisabled}, Validators.required],
				assessmentPersonName: [{value: '', disabled: true}, Validators.required],
				assessmentPersonEmailId: [{
					value: sessionStorage.getItem('emailAddress'),
					disabled: true
				}, [Validators.required, Validators.email]],
				approvedSupplierContactEmail: approvedSupplierContactEmailFormControl,
				siteEngineerName: [{value: '', hidden: true}],
				siteSupervisorName: [{value: '', hidden: true}],
				siteManagerName: [{value: '', hidden: true}],
				copySurvey: [{value: 'false'}],
				availableCommodityList: [{value: ''}],
				supplierParentCode: [{value: '', hidden: true}]
			});

			this.covid19SurveyForm.controls['copySurvey'].valueChanges.subscribe(checked => {
				if (checked === true) {
					this.covid19SurveyForm.controls['availableCommodityList'].setValidators([Validators.required]);
					this.copyChecked = true;
				} else {
					this.covid19SurveyForm.controls['availableCommodityList'].setValidators(null);
					this.copyChecked = false;
				}
				this.covid19SurveyForm.controls['availableCommodityList'].updateValueAndValidity();
			});
			this.covid19SurveyForm.controls['availableCommodityList'].valueChanges.subscribe(valueChanges => {
				console.log(valueChanges);
				if (valueChanges !== null && valueChanges.value !== '') {
					const datePipe = new DatePipe('en-US');
					this.copyLastModifiedBy = valueChanges.lastModifiedBy;
					this.copyLastModifiedOn = datePipe.transform(valueChanges.lastModifiedDate, 'MM/dd/yyyy');
				}
			});
		} else {
			// For normal survey
			this.displayGsdb = this.gsdbCodes;
			this.loadPlantOptions();
			this.loadVlineOptions();
			if (this.isSupplierUser) {
				this.gsdbCodesService.getGsdbCodes().subscribe(value => {
					this.displayGsdb = value;
				});
			}
			this.covid19SurveyForm = this.fb.group({
				supplierId: [{value: 0, disabled: this.isDisabled}, null],
				supplierLoginId: [{
					value: sessionStorage.getItem('userId'),
					disabled: this.isDisabled
				}, Validators.required],
				supplierName: [{value: '', disabled: true}, Validators.required],
				supplierGSDB: ['', [
					Validators.required,
					Validators.minLength(5)
				]],
				supplierLocationCountry: [{value: '', disabled: true}, Validators.required],
				supplierLocationCity: [{value: '', disabled: true}, Validators.required],
				dateOfAssessment: [{value: new Date(), disabled: this.isDisabled}, Validators.required],
				vehiclesSupported: [{value: '', disabled: this.isDisabled}, Validators.required],
				fordPlantsSupported: [{value: '', disabled: this.isDisabled}, Validators.required],
				plantManagerContactName: [{value: '', disabled: true}, Validators.required],
				plantManagerEmailId: [{value: '', disabled: true}, [Validators.required, Validators.email]],
				assessmentPersonName: [{value: '', disabled: true}, Validators.required],
				assessmentPersonEmailId: [{
					value: sessionStorage.getItem('emailAddress'),
					disabled: true
				}, [Validators.required, Validators.email]],
				approvedSupplierContactEmail: approvedSupplierContactEmailFormControl,
				supplierParentCode: [{value: '', hidden: true}]
			});
		}

		if (sessionStorage.getItem('dashboard-role') === 'true') {
			this.isDisabled = true;
			this.covid19SurveyForm.disable();
			this.covid19SurveyForm.get('supplierGSDB').enable();
			if (this.isLaunchSurvey) {
				this.covid19SurveyForm.get('commodityCode').enable();
			}
			this.isSupplierUser = false;
		}
		if (sessionStorage.getItem('dashboard-role') === 'false') {
			const loggedinMailId = sessionStorage.getItem('emailAddress');
			if (loggedinMailId) {
				if (loggedinMailId.toUpperCase().indexOf('FORD.COM') > -1) {
					this.covid19SurveyForm.disable();
					this.covid19SurveyForm.get('supplierGSDB').disable();
				}
			}
			this.isSupplierUser = true;
		}

		sessionStorage.setItem('isSurveyCompleted', 'false');
		this.validateApprovedSupplierList(this.covid19SurveyForm.getRawValue().approvedSupplierContactEmail);
	}

	private loadVlineOptions() {
		this.supplerService.getVlineOptions().subscribe(value => {
			this.vlineOptions = value;
		});
	}

	private loadPlantOptions() {
		this.supplerService.getPlantOptions().subscribe(value => {
			this.plantOptions = value;
		});
	}

	/**
	 * this method filters the list of customer emails based on the query that is a part of an the event
	 *
	 * @param event triggered as a part of the change of the customer email input
	 */
	filterGsdbCodes(event) {
		this.filteredGsdbCodes = [];
		this.noGsdbCodeFound = false;
		console.log(this.enteredGSDBCode);
		/*for (const gsdbCode of this.gsdbCodes) {
			if (gsdbCode.code.toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
				this.filteredGsdbCodes.push(gsdbCode);
			}
		}*/
		this.homeService.getFilteredGSDB(this.isLaunchSurvey, this.enteredGSDBCode).subscribe(gsdbCodes => {
			this.displayGsdb = gsdbCodes;
		});
		if (this.filteredGsdbCodes.length === 0) {
			this.noGsdbCodeFound = true;
			this.newGsdbCode = event.query;
		}
	}

	//Add the (onBlur)="updateForm($event)" to the autocomplete HTML tag generate test data for the Get Survey form
	updateForm(event) {
		if (this.noGsdbCodeFound) {
			Object.keys(this.covid19SurveyForm.controls).forEach(key => {
				this.covid19SurveyForm.get(key).setErrors(null);
			});
			this.covid19SurveyForm.reset();
			this.showSurvey = false;
			this.surveyService.completedSurvey = undefined;
			this.covid19SurveyForm.get('supplierId').setValue(0);
			this.covid19SurveyForm.get('supplierLoginId').setValue((sessionStorage.getItem('userId')));
			this.covid19SurveyForm.get('assessmentPersonEmailId').setValue(sessionStorage.getItem('emailAddress'));
			this.covid19SurveyForm.get('supplierGSDB').setValue({code: this.newGsdbCode});
			this.covid19SurveyForm.get('approvedSupplierContactEmail').setValue(this.covid19SurveyForm.get('assessmentPersonEmailId').value);
			this.covid19SurveyForm.get('dateOfAssessment').setValue(new Date());
			//Test Supplier Data
			// const testSupplier = {
			// 	supplierLoginId: sessionStorage.getItem('userId'),
			// 	supplierName: 'Test Supplier',
			// 	supplierLocationCountry: 'Test Country',
			// 	supplierLocationCity: 'Test City',
			// 	dateOfAssessment: new Date(),
			// 	vehiclesSupported: 'Test Vehicles',
			// 	fordPlantsSupported: 'Test Plants',
			// 	plantManagerContactName: 'Test Contact',
			// 	plantManagerEmailId: 'manager@test.com',
			// 	assessmentPersonName: 'Test Person',
			// 	assessmentPersonEmailId: sessionStorage.getItem('emailAddress')
			// };
			// this.covid19SurveyForm.patchValue(testSupplier);
		}
	}

	/**
	 * Gets the details of a supplier based on a GSDB code. Searches the full list of suppliers and return the one with the specified
	 * GSDB code.
	 *
	 * @param selectedSupplierGSDB GSDB code of the supplier that needs to be found
	 */
	getSupplierDetails(selectedSupplierGSDB) {
		return this.homeService.getSupplier(selectedSupplierGSDB.value['code']).subscribe((supplierResponse) => {
			if (this.validateSelectedAssesmentIdPartOfApprovedIdList(supplierResponse)) {
				let dateOfAssessment = new Date(supplierResponse.dateOfAssessment);
				if (supplierResponse.dateOfAssessment == null) {
					dateOfAssessment = new Date();
				}
				delete supplierResponse.supplierGSDB;
				delete supplierResponse.dateOfAssessment;
				this.covid19SurveyForm.patchValue(supplierResponse);
				this.covid19SurveyForm.get('supplierLoginId').setValue(sessionStorage.getItem('userId'));
				this.covid19SurveyForm.get('dateOfAssessment').setValue(dateOfAssessment);
				this.covid19SurveyForm.markAsPristine();
				this.showSurvey = false;
				if (sessionStorage.getItem('dashboard-role') === 'false' && !supplierResponse.supplierId) { // Supplier - create Mode
					this.covid19SurveyForm.get('assessmentPersonEmailId').setValue(sessionStorage.getItem('emailAddress'));
					this.covid19SurveyForm.get('approvedSupplierContactEmail').setValue(sessionStorage.getItem('emailAddress'));
					this.covid19SurveyForm.get('assessmentPersonName').setValue(sessionStorage.getItem('assessmentPersonName'));
				}
			} else {
				this.showSurvey = false;
				this.covid19SurveyForm.reset();
			}
			this.copyWarnMsg = false;
		});
	}

	private validateSelectedAssesmentIdPartOfApprovedIdList(supplierResponse: Supplier) {
		let isValid = false;
		if (sessionStorage.getItem('dashboard-role') === 'false') {
			const rawValue = this.covid19SurveyForm.getRawValue();
			const supplierLoginId = rawValue.assessmentPersonEmailId;
			const approvedSupplierContactEmailList: string[] = this.buildApprovedIdList(rawValue.approvedSupplierContactEmail);
			if (supplierLoginId) {
				isValid = this.validateAssesmentPersonMailIdInTheList(supplierLoginId, approvedSupplierContactEmailList);
			}
		} else {
			isValid = true;
		}
		console.log('validateSelectedAssesmentIdPartOfApprovedIdList isValid ' + isValid);
		return isValid;
	}

	/**
	 * Gets the latest survey based on supplier data in the form.
	 *
	 */
	getSurvey() {
		const supplier = this.covid19SurveyForm.getRawValue() as Supplier;
		document.querySelector('#surveyReviewElement').innerHTML = '';
		if (supplier.supplierGSDB['code']) {
			supplier.supplierGSDB = supplier.supplierGSDB['code'];
		}
		if (this.isLaunchSurvey && supplier.commodityCode['code']) {
			supplier.commodityName = supplier.commodityCode['name'];
			supplier.commodityCode = supplier.commodityCode['code'];
		}
		this.homeService.getSurveyForSupplier(this.isLaunchSurvey, supplier, this.covid19SurveyForm.dirty, false, null);
		this.showSurvey = true;
	}

	isSurveyCompleted() {
		const completedSurvey = this.surveyService.completedSurvey;
		if (completedSurvey) {
			this.overallRating = this.surveyService.completedSurvey.surveyOverallRating;
		}
		return completedSurvey !== undefined;
	}

	validateApprovedSupplierList(approvedSupplierContactEmailListTemp: any) {
		this.approvedSupplierContactEmailList = [];
		if (approvedSupplierContactEmailListTemp) {
			this.approvedSupplierContactEmailList = this.buildApprovedIdList(approvedSupplierContactEmailListTemp);
			// tslint:disable-next-line:max-line-length
			this.isValidApprovedSupplierContactEmail = this.validateAssesmentPersonMailIdInTheList(this.covid19SurveyForm.getRawValue().assessmentPersonEmailId, this.approvedSupplierContactEmailList);
			if (!this.isValidApprovedSupplierContactEmail) {
				this.covid19SurveyForm.controls['approvedSupplierContactEmail'].setErrors({incorrect: true});
			} else {
				this.covid19SurveyForm.controls['approvedSupplierContactEmail'].setErrors(null);
			}
		}
		console.log(this.approvedSupplierContactEmailList);
	}

	private buildApprovedIdList(approvedSupplierContactEmailListTemp: any) {
		const approvedSupplierContactEmailList: string[] = [];
		const emails = approvedSupplierContactEmailListTemp.split(',');
		emails.forEach(email => {
			if (email && email.length > 0) {
				email = email.trim();
				approvedSupplierContactEmailList.push(email);
			}
		});
		return approvedSupplierContactEmailList;
	}

	validateAssesmentPersonMailIdInTheList(assessmentPersonEmailId, approvedSupplierContactEmailList): boolean {
		let isValidApprovedSupplierContactEmail = false;
		//console.log('assessmentPersonEmailId ' + assessmentPersonEmailId);
		//console.log('this.approvedSupplierContactEmailList ');
		//console.log(approvedSupplierContactEmailList);
		if (approvedSupplierContactEmailList && approvedSupplierContactEmailList.length > 0 && assessmentPersonEmailId) {
			approvedSupplierContactEmailList.forEach((email, index) => {
				if (email.toUpperCase() === assessmentPersonEmailId.toUpperCase()) {
					isValidApprovedSupplierContactEmail = true;
				}
			});
		}
		//console.log('finaly' + isValidApprovedSupplierContactEmail);
		return isValidApprovedSupplierContactEmail;
	}

	onPrint() {
		window.print();
	}

	private retrieveRouteParameter() {
		this.route.paramMap.subscribe(params => {
			this.isLaunchSurvey = params.get('mode') === 'launch' ? true : false;
			console.log('Mode captured:' + this.isLaunchSurvey);
		});
	}

	// Launch Survey Methods here
	getCommodityCode(selectedSupplierGSDB) {
		console.log('GSDB :' + selectedSupplierGSDB);
		this.homeService.getCommodityCode(selectedSupplierGSDB).subscribe(value => {
			this.lrCommodityCodes = value;
		});
	}

	onChangeGSDBCode(selectedSupplierGSDB) {
		console.log('getSupplierDetails....' + selectedSupplierGSDB.value['code']);
		this.surveyService.emptyCompletedSurvey();
		this.selectedGSDBCode = selectedSupplierGSDB.value['code'];
		this.copyWarnMsg = false;
		if (this.isLaunchSurvey) {
			this.resetForm();
			this.getCommodityCode(this.selectedGSDBCode);
		} else {
			this.getSupplierDetails(selectedSupplierGSDB);
		}
	}

	onChangeCommodityCode(selectedCommodity) {
		this.surveyService.emptyCompletedSurvey();
		this.selectedCommodityCode = selectedCommodity.value['code'];
		if (this.selectedGSDBCode !== null && this.selectedCommodityCode !== null) {
			this.getLrSupplierDetails(this.selectedGSDBCode, this.selectedCommodityCode);
		}
	}

	getLrSupplierDetails(gsdbCode, commodityCode) {
		return this.homeService.getLrSupplier(gsdbCode, commodityCode).subscribe((supplierResponse) => {
			if (this.validateSelectedAssesmentIdPartOfApprovedIdList(supplierResponse)) {
				let dateOfAssessment = new Date(supplierResponse.dateOfAssessment);
				if (supplierResponse.dateOfAssessment == null) {
					dateOfAssessment = new Date();
				}
				delete supplierResponse.supplierGSDB;
				delete supplierResponse.commodityCode;
				delete supplierResponse.dateOfAssessment;
				this.covid19SurveyForm.patchValue(supplierResponse);
				this.covid19SurveyForm.get('supplierLoginId').setValue(sessionStorage.getItem('userId'));
				this.covid19SurveyForm.get('dateOfAssessment').setValue(dateOfAssessment);
				this.covid19SurveyForm.markAsPristine();
				this.showSurvey = false;
				if (sessionStorage.getItem('dashboard-role') === 'false' && !supplierResponse.supplierId) { // Supplier - create Mode
					this.covid19SurveyForm.get('assessmentPersonEmailId').setValue(sessionStorage.getItem('emailAddress'));
					this.covid19SurveyForm.get('approvedSupplierContactEmail').setValue(sessionStorage.getItem('emailAddress'));
					this.covid19SurveyForm.get('assessmentPersonName').setValue(sessionStorage.getItem('assessmentPersonName'));
				}
				const answeredCommodityList = supplierResponse.answeredCommodityList;
				if (answeredCommodityList) {
					this.isCopyAvailable = answeredCommodityList.length > 0 ? true : false;
				}

				this.answeredCommodityOptions = supplierResponse.answeredCommodityList;
			} else {
				this.showSurvey = false;
				this.covid19SurveyForm.reset();
			}
			this.copyWarnMsg = false;
		});
	}

	/*private loadProgramsSupportedOptions() {
		this.supplerService.getProgramsSupportedOptions().subscribe(value => {
			this.programsSupportedOptions = value;
		});
	}*/

	private resetForm() {

		const siteEngName = this.covid19SurveyForm.controls['siteEngineerName'].value;
		const siteSupName = this.covid19SurveyForm.controls['siteSupervisorName'].value;
		const siteMngrName = this.covid19SurveyForm.controls['siteManagerName'].value;

		this.covid19SurveyForm.reset();
		this.covid19SurveyForm.get('supplierGSDB').setValue({code: this.selectedGSDBCode});
		this.covid19SurveyForm.get('supplierLoginId').setValue(sessionStorage.getItem('userId'));
		this.covid19SurveyForm.get('dateOfAssessment').setValue(new Date());
		this.covid19SurveyForm.markAsPristine();
		this.showSurvey = false;
		this.covid19SurveyForm.get('assessmentPersonEmailId').setValue(sessionStorage.getItem('emailAddress'));
		this.covid19SurveyForm.get('approvedSupplierContactEmail').setValue(sessionStorage.getItem('emailAddress'));
		this.covid19SurveyForm.get('assessmentPersonName').setValue(sessionStorage.getItem('assessmentPersonName'));
		this.covid19SurveyForm.get('siteEngineerName').setValue(siteEngName);
		this.covid19SurveyForm.get('siteSupervisorName').setValue(siteSupName);
		this.covid19SurveyForm.get('siteManagerName').setValue(siteMngrName);
		this.copyWarnMsg = false;
	}

	copySurvey() {
		const supplier = this.covid19SurveyForm.getRawValue() as Supplier;
		document.querySelector('#surveyReviewElement').innerHTML = '';
		if (supplier.supplierGSDB['code']) {
			supplier.supplierGSDB = supplier.supplierGSDB['code'];
		}
		if (this.isLaunchSurvey && supplier.commodityCode['code']) {
			supplier.commodityName = supplier.commodityCode['name'];
			supplier.commodityCode = supplier.commodityCode['code'];
		}
		const copyFromCommodity = this.covid19SurveyForm.controls['availableCommodityList'].value['code'];
		const supplierResponse = this.homeService.getSurveyForSupplier(this.isLaunchSurvey, supplier, this.covid19SurveyForm.dirty,
			this.copyChecked, copyFromCommodity);
		this.showSurvey = true;
		this.copyWarnMsg = true;
	}

	onChangeFilterGSDB() {
		if (this.enteredGSDBCode) {
			this.disableFilterBtn = this.enteredGSDBCode.length > 1 ? false : true;
		} else {
			this.disableFilterBtn = true;
		}
	}
}
