import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {DialogService, DynamicDialogRef, MessageService} from "primeng/api";
import {SupplierService} from "../../../home/services/supplier.service";
import {Router} from "@angular/router";
import {CustomDialogComponent} from "../../../custom-dialog/custom-dialog/custom-dialog.component";
import {defaultConfig, T2VDataRefreshEmailConfig} from "../model/t2_refresh_email_config";
import {T2DataRefreshService} from "../services/t2-data-refresh.service";

@Component({
  selector: 't2v-data-refresh-alternative-email-config',
  templateUrl: './t2v-data-refresh-alternative-email-config.component.html',
  styleUrls: ['./t2v-data-refresh-alternative-email-config.component.scss']
})
export class T2vDataRefreshAlternativeEmailConfigComponent implements OnInit {

	private response: any;
	standardModuleNames:  any;
	semiConductorSuppliers: T2VDataRefreshEmailConfig[] = [];
	selectedStandardModuleNames: T2VDataRefreshEmailConfig[] = [];
	selectedSemiConductorSuppliers: T2VDataRefreshEmailConfig[] = [];
	dropDownForm: FormGroup;
	display = false;
	displayDeleteDialog = false;
	dropDownObject: T2VDataRefreshEmailConfig;
	isCreateOperation = false;
	isUpdateOperation = false;
	dropDownColumnName1 = 'Country Name';
	dropDownColumnName2 = 'Semi Conductor Supplier';
	isDisabled = false;
	ref: DynamicDialogRef;
	// gsdb: FormControl;
	// ccEmails: FormControl;
	// alternativeEmail: FormControl;
	isDisplayStandardModuleNameFilterData = false;
	isDisplaySemiConductorSupplierFilterData = false;
	// deleteObject: DropDown;
	isMultiDelete = false;
	deletionDropDownColumnName: string;
	// tslint:disable-next-line:max-line-length
	constructor(private supplierService: SupplierService, private router: Router, private fb: FormBuilder, private t2DataRefreshService: T2DataRefreshService, private dialogService: DialogService, private messageService: MessageService) {
	}

	ngOnInit() {
		this.router.navigate(['/']);
		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
			return;
		} else if (sessionStorage.getItem('uid').includes('-')) {
			this.router.navigate(['/']);
			return;
		}

		// this.supplierService.pushSelectedSurvey('T2Visibility Data Refresh');

		this.dropDownForm = this.fb.group({
			t1SiteGSDB: ['', Validators.required],
			ccEmailIds: ['', Validators.required],
			alternateSupplierEmailIds: ['', Validators.required]
		});

		//TODO Following commented out to decommission of T2V Data Refresh

		// this.findAllDropDownData();
	}

	findAllDropDownData() {
		this.t2DataRefreshService.findAllDropDownData().subscribe((data) => {
			this.standardModuleNames = [];
			this.standardModuleNames = data;
			// console.log(this.standardModuleNames);
		});
		// this.projectRMicrochipService.findAllDropDownData(this.dropDownColumnName2).subscribe((data) => {
		// 	this.semiConductorSuppliers = [];
		// 	this.semiConductorSuppliers = data;
		// });
	}
	adminScreen() {
		this.router.navigate(['/t2-visibility-administrator']);
	}

	enableCreateNewDataDialog(dropDownColumnName: string) {
		this.dropDownForm.reset();
		// this.dropDownForm.get('t1SiteGSDB').disable({onlySelf:false, emitEvent:false})
		this.isCreateOperation = true;
		this.isUpdateOperation = false;
		this.isDisabled = false;
		this.dropDownObject = new T2VDataRefreshEmailConfig(defaultConfig);
		// this.dropDownObject. = dropDownColumnName;
		// this.formFieldName = dropDownColumnName;
		this.display = true;
	}

	createData() {
		this.dropDownObject.t1SiteGSDB = this.dropDownForm.get('t1SiteGSDB').value;
		this.dropDownObject.ccEmailIds = this.dropDownForm.get('ccEmailIds').value;
		this.dropDownObject.alternateSupplierEmailIds = this.dropDownForm.get('alternateSupplierEmailIds').value;
		if (this.validateData(this.dropDownObject)) {
			this.t2DataRefreshService.createDropDownData(this.dropDownObject).subscribe((data) => {
				this.response = data;
				// console.log(this.response);
				if (JSON.parse(this.response.Success)) {
					this.display = false;
					this.pushDialog(this.response.Message);
					this.findAllDropDownData();
				} else {
					this.pushDialog(this.response.Message);
				}
			});
		} else {
			this.pushMessage('error', 'Error', 'Please fill the mandatory field');
		}
	}

	enableUpdateDataDialog(product: any, dropDownColumnName: string) {
		this.dropDownForm.reset();
		this.isDisabled = true;
		// console.log(product);
		// this.formFieldName = dropDownColumnName;
		this.isCreateOperation = false;
		this.isUpdateOperation = true;
		this.dropDownObject = new T2VDataRefreshEmailConfig(defaultConfig);
		this.dropDownObject.id = product['id'];
		this.dropDownObject.t1SiteGSDB = product['t1SiteGSDB'];
		this.dropDownObject.ccEmailIds = product['ccEmailIds'];
		this.dropDownObject.alternateSupplierEmailIds = product['alternativeEmailId'];
		this.dropDownObject.createdBy = product['createdBy'];
		this.dropDownObject.createdOn = product['createdOn'];
		this.dropDownObject.updatedBy = product['updatedBy'];
		this.dropDownObject.updatedOn = product['updatedOn'];
		this.dropDownForm.patchValue({t1SiteGSDB: product['t1SiteGSDB'],disable:true,ccEmailIds: product['ccEmailIds'],alternateSupplierEmailIds: product['alternateSupplierEmailIds']});
		// this.dropDownForm.get('t1SiteGSDB').disable();
		this.display = true;
		// console.log(this.dropDownForm);
	}
	updateData() {
		this.dropDownObject.t1SiteGSDB = this.dropDownForm.get('t1SiteGSDB').value;
		this.dropDownObject.ccEmailIds = this.dropDownForm.get('ccEmailIds').value;
		this.dropDownObject.alternateSupplierEmailIds = this.dropDownForm.get('alternateSupplierEmailIds').value;
		if (this.validateData(this.dropDownObject)) {
			this.t2DataRefreshService.updateDropDownData(this.dropDownObject).subscribe((data) => {
				this.response = data;
				// console.log(this.response);
				if (JSON.parse(this.response.Success)) {
					this.display = false;
					this.pushDialog(this.response.Message);
					this.findAllDropDownData();
				} else {
					this.pushDialog(this.response.Message);
				}
			});
		} else {
			this.pushMessage('error', 'Error', 'Please fill the mandatory field');
		}
	}
	// deleteData(product: any) {
	// 	this.projectRMicrochipService.deleteDropDownData(product['id']).subscribe((data) => {
	// 		this.response = data;
	// 		console.log(this.response);
	// 		if (JSON.parse(this.response.success)) {
	// 			this.pushDialog(this.response.message);
	// 			this.findAllDropDownData();
	// 		} else {
	// 			this.pushDialog(this.response.message);
	// 		}
	// 	});
	// }

	// deleteSelectedData(dropDownColumnName: string) {
	// 	let selectedDropDownData: any[];
	// 	if (dropDownColumnName === this.dropDownColumnName1) {
	// 		selectedDropDownData = this.selectedStandardModuleNames;
	// 	} else {
	// 		selectedDropDownData = this.selectedSemiConductorSuppliers;
	// 	}
	// 	if (selectedDropDownData.length > 0) {
	// 		const idList = [];
	// 		for (const item of selectedDropDownData) {
	// 			idList.push(item.id);
	// 		}
	// 		this.projectRMicrochipService.deleteDropDownData(idList.join(',')).subscribe((data) => {
	// 			this.response = data;
	// 			console.log(this.response);
	// 			if (JSON.parse(this.response.success)) {
	// 				this.pushDialog(this.response.message);
	// 				this.selectedStandardModuleNames = [];
	// 				this.selectedSemiConductorSuppliers = [];
	// 				this.findAllDropDownData();
	// 			} else {
	// 				this.pushDialog(this.response.message);
	// 			}
	// 		});
	// 	}
	// }
	disableDialog() {
		this.display = false;
		this.dropDownForm.reset();
	}
	pushDialog(dialogBody: any) {
		this.ref = this.dialogService.open(CustomDialogComponent, {
			header: 'Drop Down Config - Response',
			footer: '',
			data: dialogBody,
			width: '50%',
			contentStyle: { 'max-height': '1000px',
				overflow: 'auto' },
			baseZIndex: 10000,
		});
	}
	validateData(dropDownObject: T2VDataRefreshEmailConfig): boolean {
		return dropDownObject.t1SiteGSDB !== null && dropDownObject.t1SiteGSDB.length ==5
			&& dropDownObject.ccEmailIds !== null && dropDownObject.alternateSupplierEmailIds !== null;
	}
	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 60000
		});
	}
	showStandardModuleNameDataFilters() {
		this.isDisplayStandardModuleNameFilterData = !this.isDisplayStandardModuleNameFilterData;
	}
	showSemiConductorSupplierDataFilters() {
		this.isDisplaySemiConductorSupplierFilterData = !this.isDisplaySemiConductorSupplierFilterData;
	}
	closeDialog() {
		this.displayDeleteDialog = false;
	}
	deleteConfirmationDialog(product: any) {
		this.isMultiDelete = false;
		this.displayDeleteDialog = true;
		// this.deleteObject = product;
	}
	multiDeleteConfirmationDialog(dropDownColumnName: string) {
		this.deletionDropDownColumnName = dropDownColumnName;
		this.isMultiDelete = true;
		this.displayDeleteDialog = true;
	}
	// proceedDeletion() {
	// 	this.displayDeleteDialog = false;
	// 	if (this.isMultiDelete) {
	// 		this.deleteSelectedData(this.deletionDropDownColumnName);
	// 	} else {
	// 		this.deleteData(this.deleteObject);
	// 	}
	// }

}
