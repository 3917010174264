import {
	Component,
	OnInit
} from '@angular/core';
import {SupplierService} from '../../../home/services/supplier.service';
import {NTierMasterAdminService} from '../../n-tier-master-admin/service/n-tier-master-admin.service';
import {Router} from '@angular/router';
import {ValueStreamDataService} from '../service/value-stream-data.service';
import {MessageService} from 'primeng/api';
import {
	FormBuilder,
	FormGroup,
	Validators
} from '@angular/forms';

@Component({
	selector: 'vsm-user-template-data-update',
	templateUrl: './vsm-user-template-data-update.component.html',
	styleUrls: ['./vsm-user-template-data-update.component.scss']
})
export class VsmUserTemplateDataUpdateComponent implements OnInit {
	masterAdminForm: FormGroup;
	// tslint:disable-next-line:max-line-length
	userLoginId: string;
	templateList: any = [];
	selectedTemplate: any;
	columnAccess: any = [];
	searchResult: any = [];

	// tslint:disable-next-line:max-line-length
	constructor(private supplierService: SupplierService, private fb: FormBuilder, private masterAdminService: NTierMasterAdminService, private router: Router, private valueStreamDataService: ValueStreamDataService, private messageService: MessageService) {
	}

	ngOnInit() {
		this.router.navigate(['/']);
		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
			return;
		} else if (sessionStorage.getItem('uid').includes('-')) {
			this.router.navigate(['/']);
			return;
		}
		// this.supplierService.pushSelectedSurvey('VSM');
		this.userLoginId = sessionStorage.getItem('uid');
		this.masterAdminForm = this.fb.group({
			tntvVsmTemplateId: [{value: '', disabled: false}, Validators.required],
			tntvVsmTemplateColumnId: [{value: '', disabled: false}, Validators.required],
			oldValue: [{value: '', disabled: false}, Validators.required],
			newValue: [{value: '', disabled: false}, Validators.required]
		});
		this.getAllTemplate();
		// tslint:disable-next-line:indent
	}

	private getAllTemplate() {
		this.valueStreamDataService.getVsmTemplate().subscribe((res: any) => {
			this.templateList = res;
		});
	}

	onVsmTemplateChange(event) {
		this.selectedTemplate = event.value;
		this.valueStreamDataService.getVsmTemplateColumn(event.value.id).subscribe((res: any) => {
			this.columnAccess = res;
		});
	}

}
