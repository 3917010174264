export class TigersharkConstraintAdmin {
	id: number;
	constraintId: string;
	constraintIsActive: boolean;
	supplierName: string;
	semiconductorSupplier: string;
	commodity: string;
	pmt: string;
	gsdbCode: string;
	purchasingCoeLead: string;
	mpnlCoeLead: string;
	purchasingLead: string;
	purchasingBuyer: string;
	globalLead: string;
	impactedBuNa: string;
	impactedBuFsao: string;
	impactedBuEu: string;
	impactedBuImg: string;
	impactedBuGcn: string;
	highMarginVehicles: string;
	f150Lightening: string;
	commodityDetails: string;
	purchasingBusinessCoordinators: string;
	partType: string;
	meetings: string;
	frequency: string;
	meetingTime: string;
	numberOfMeetings: number;
	meetingLink: string;
	day: string;
	location: string;
	contactName1: string;
	contactNumber1: string;
	contactName2: string;
	contactNumber2: string;
	contactName3: string;
	contactNumber3: string;
	supplierEmailIdTo: string;
	supplierEmailIdCc: string;
	coGlobalLead: string;
	email1: string;
	email2: string;
	email3: string;
	// admin proj fields
	weekDate: Date;
	weekNumber: number;
	conditionForReceived: string;
	constraintStatus: string;
	lessThan8WeeksShipmentPlan: string;
	supplierShipmentStatus: string;
	conditionForShipmentCoe: string;
	meetingAttended: string;
	shipmentPlanAvailabilityDate: Date;
	shipmentPlanAvailabilityWeekNumber: number;
	edppOutputFileUploadedDateAndTime: Date;
	edppMailSentDateAndTime: Date;
	followupDate1: Date;
	followupDate2: Date;
	followupDate3: Date;
	supplierRationale: string;
	causalFactor: string;
	causalFactorCode: string;
	subTierSuppliers: string;
	remarks: string;
	riskCategory: string;
	globalSalesAllocation: string;
	meetingComments: string;
	numberOfWeeksShipmentAvailable: number;
	createdBy: string;
	createdOn: Date;
	lastUpdatedBy: string;
	lastUpdatedOn: Date;
	fordUsersEmailTo: string;
	fordUsersEmailCC: string;
	constructor(tigersharkConstraintAdmin: TigersharkConstraintAdmin) {
		Object.assign(this, tigersharkConstraintAdmin);
	}
}
