export class T2VDataRefreshEmailConfig {
	id: number;
	t1SiteGSDB: string;
	ccEmailIds: string;
	alternateSupplierEmailIds: string;
	createdBy: string;
	createdOn: Date;
	updatedBy: string;
	updatedOn: Date;
	constructor(defaultConfig: T2VDataRefreshEmailConfig) {
		Object.assign(this, defaultConfig);
	}
}
/* tslint:disable */
export const defaultConfig = {
	'id': 0,
	't1SiteGSDB': '',
	'ccEmailIds': '',
	'alternateSupplierEmailIds': '',
	'createdBy': '',
	'createdOn': null,
	'updatedBy': '',
	'updatedOn': null
};
