export class Tier1EComponentShortageAdmin {
	id: number;
	tier1Supplier: string;
	gsdbCode: string;
	commodity: string;
	managerEmail: string;
	buyerEmail1: string;
	supplierEmailId: string;
	sources: string;
	isEvidenceCollectionRequired: string;
	isResilienceQuestionnaireEnabled: string;
	remarks: string;
	createdBy: string;
	createdOn: Date;
	lastUpdatedBy: string;
	lastUpdatedOn: Date;
}
