import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {TigersharkConstraintAdmin} from '../model/tigershark-constraint-admin';

@Injectable({
	providedIn: 'root'
})
export class TigerSharkConstraintAdminService {

	private ADMIN_ENDPOINT = environment.apiUrl + 'tigerSharkTracker/admin';
	private DASHBOARD_ENDPOINT = environment.apiUrl + 'edppAdminReport';

	constructor(private http: HttpClient) {
	}

	public createTigerSharkAdmin(tigersharkConstraintAdmin: TigersharkConstraintAdmin): Observable<TigersharkConstraintAdmin> {
		console.log('create service is called');
		return this.http.post<TigersharkConstraintAdmin>(this.ADMIN_ENDPOINT + `/create`, tigersharkConstraintAdmin);
	}

	public getConstraintIdFromEmail(email: string): Observable<any> {
		console.log('constraint id list is called');
		return this.http.get(this.ADMIN_ENDPOINT + `/ConstraintIdFromEmail/${email}`, {responseType: 'json'});
	}

	public getGsdbCodeFromConstraintId(constraintId: string): Observable<any> {
		return this.http.get(this.ADMIN_ENDPOINT + `/GsdbCodeFromConstraintId/${constraintId}`, {responseType: 'json'});
	}

	public getAdminDataFromConstraintIdAndGsdbCode(constraintId: string, gsdbCode: string): Observable<any> {
		return this.http.get(this.ADMIN_ENDPOINT + `/getData/${constraintId}/${gsdbCode}`, {responseType: 'json'});
	}

	public getAllConstraintId(): Observable<any> {
		return this.http.get(this.DASHBOARD_ENDPOINT + `/AllConstraintId`, {responseType: 'json'});
	}
	generateReportForEdppAdmin(tigersharkConstraintAdmin: TigersharkConstraintAdmin) {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.DASHBOARD_ENDPOINT + `/generateWppReportForAdminUpdate`, tigersharkConstraintAdmin, {responseType: 'blob'});
	}
	generateWppAdminReport(tigersharkConstraintAdmin: TigersharkConstraintAdmin) {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.DASHBOARD_ENDPOINT + `/generateWppAdminReport`, tigersharkConstraintAdmin, {responseType: 'blob'});
	}

	checkConstraintExistence(constraintId: any) {
		return this.http.get(this.ADMIN_ENDPOINT + `/checkExistence/${constraintId}`, {responseType: 'json'});
	}
}
