import {
	Component,
	OnInit
} from '@angular/core';
import {environment} from '../../environments/environment';

/** This component handles redirecting users to the auth provider */
@Component({
	template: `
		<h3>Redirecting to login server</h3>
	`
})
export class LoginComponent implements OnInit {
	private tokenUrl = environment.tokenUrl;
	private clientId = environment.clientId;
	private resource = environment.resource;

	tokenFullUrl = this.tokenUrl
		+ '?client_id=' + this.clientId
		+ '&resource=' + this.resource
		+ '&response_type=token+id_token'
		+ '&redirect_uri=' + encodeURIComponent(window.location.origin + '/');

	/** once the component is up and running, redirect the user to the auth provider */
	ngOnInit() {
		window.location.replace(this.tokenFullUrl);
	}
}
