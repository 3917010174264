import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SmashedDataReportingRoutingModule} from './smashed-data-reporting-routing.module';
import {SmashedDataDashboardComponent} from './smashed-data-dashboard/smashed-data-dashboard.component';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {ReactiveFormsModule} from '@angular/forms';
import {MessageModule} from 'primeng/message';
@NgModule({
	declarations: [SmashedDataDashboardComponent],
	imports: [
		CommonModule,
		SmashedDataReportingRoutingModule,
		ButtonModule,
		DropdownModule,
		ReactiveFormsModule,
		MessageModule
	]
})
export class SmashedDataReportingModule {
}
