import {Component, OnInit, ViewChild} from '@angular/core';
import {SupplierService} from '../../../home/services/supplier.service';
import {Router} from '@angular/router';
import {NTierMasterAdminService} from '../../n-tier-master-admin/service/n-tier-master-admin.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Option} from '../../../home/model/ntier.option';
import {DatePipe} from '@angular/common';
import {DialogService, DynamicDialogRef, MessageService} from 'primeng/api';
import {IgxSpreadsheetComponent} from 'igniteui-angular-spreadsheet';
import {ExcelUtility} from '../../tiger-shark-constraint-tracker/model/excel-utility';
import {Workbook, WorkbookSaveOptions} from 'igniteui-angular-excel';
import {CustomDialogComponent} from '../../../custom-dialog/custom-dialog/custom-dialog.component';
import {ClearToBuildAdmin} from '../model/clear-to-build-admin';
import {ClearToBuildAdminService} from '../service/clear-to-build-admin.service';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {ClearToBuildTrackerService} from '../service/clear-to-build-tracker.service';
import {T2Visibility} from '../../t2-visibility/model/t2_visibility';

@Component({
	selector: 'clear-to-build-admin',
	templateUrl: './clear-to-build-admin.component.html',
	styleUrls: ['./clear-to-build-admin.component.scss']
})
export class ClearToBuildAdminComponent implements OnInit {
	// tslint:disable-next-line:max-line-length
	constructor(private supplierService: SupplierService, private spinnerService: SpinnerService, private datePipe: DatePipe, private fb: FormBuilder, private router: Router, private datepipe: DatePipe, private messageService: MessageService, private masterAdminService: NTierMasterAdminService, private dialogService: DialogService, private clearToBuildAdminService: ClearToBuildAdminService, private clearToBuildTrackerService: ClearToBuildTrackerService) {
	}

	clearToBuildAdminForm: FormGroup;
	clearToBuildAdmin: ClearToBuildAdmin;
	isDisabled = false;
	isClearToBuildAdminFormValid = false;
	constIdList = [];
	allConstIdList = [];
	gsdbCodeList = [];
	// ctbIdResponse: CtbidList;
	yesNoOptions: Option[] = [];
	partTypeOptions: Option[] = [];
	selectedCtbId: string;
	selectedGsdbCode: string;
	fileFromDatabase: any;
	file: File = null;
	selectedFiles: any[] = [];
	isSubmitVisible = false;
	disableGsdbCodeDropdown = false;
	ctbIdObject: any;
	allCtbIdObject: any;
	gsdbCodeObject: any;
	isSuperAdmin = false;
	isCoeAdmin = false;
	isMpnlAdmin = false;
	isDriAdmin = false;
	isMpnlSuperAdmin = false;
	isMpnlSubmitVisible = false;
	displayDialog = false;
	reportDisplay = false;
	toggle = true;
	ref: DynamicDialogRef;
	response: any = {success: false, message: ''};
	ctbIds: any[] = [];
	supplierName: any[] = [];
	supplierCode: any[] = [];
	selectedCtbIds: any[] = [];
	selectedSupplierName: any[] = [];
	selectedSupplierCode: any[] = [];
	selectedClearToBuildIds: any[] = [];
	clearToBuildIds: any[] = [];
	// tslint:disable-next-line:ban-types
	existingCtbIds: Object = [];

	@ViewChild('spreadsheet', {static: false, read: IgxSpreadsheetComponent})
	public spreadsheet: IgxSpreadsheetComponent;

	showDialog() {
		this.clearToBuildAdminForm.reset();
		this.clearToBuildAdminForm.patchValue({numberOfMeetings: 0});
		this.displayDialog = true;
	}

	showReportDialog() {
		this.clearToBuildAdminForm.reset();
		this.selectedCtbIds = [];
		this.reportDisplay = true;
	}

	ngOnInit() {
		this.router.navigate(['/']);
		// this.supplierService.pushSelectedSurvey('clearToBuild');
		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
		} else {
			// tslint:disable-next-line:max-line-length
			this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build Super Admin').subscribe(userHasCtbSuperAdminAccess => {
				if (userHasCtbSuperAdminAccess) {
					this.isSuperAdmin = userHasCtbSuperAdminAccess;
					this.getAllCtbIdList();
					this.loadSupplierNames();
					this.loadSupplierCodes();
				} else {
					// tslint:disable-next-line:max-line-length
					this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build COE Admin').subscribe(userHasCtbCoeAdminAccess => {
						if (userHasCtbCoeAdminAccess) {
							this.isCoeAdmin = userHasCtbCoeAdminAccess;
							this.fetchCtbIdsFromEmail();
							this.fetchSupplierNameFromEmail();
							this.fetchSupplierCodeFromEmail();
						} else {
							// tslint:disable-next-line:max-line-length
							this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build Mp&L Admin').subscribe(userHasCtbMpnlAccess => {
								if (userHasCtbMpnlAccess) {
									this.isMpnlAdmin = userHasCtbMpnlAccess;
									this.getAllCtbIdListForMpnl();
								} else {
									// tslint:disable-next-line:max-line-length
									this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build DRI Access').subscribe(userHasCtbDriAccess => {
										if (userHasCtbDriAccess) {
											this.isDriAdmin = userHasCtbDriAccess;
											this.getAllCtbIdListForMpnl();
										} else {
											// tslint:disable-next-line:max-line-length
											this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build MP&L Super Admin').subscribe(userHasCtbMpnlSuperAdminAccess => {
												if (userHasCtbMpnlSuperAdminAccess) {
													this.isMpnlSuperAdmin = userHasCtbMpnlSuperAdminAccess;
													this.getAllCtbIdListForMpnl();
												} else {
													this.router.navigate(['/']);
												}
											});
										}});
								}
							});
					}});
				}
			});
			// tslint:disable-next-line:max-line-length
			// this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'Clear To Build Mp&L Admin').subscribe(userHasCtbMpnlAccess => {
			// 		if (userHasCtbMpnlAccess) {
			// 			this.fetchCtbIdsFromMpnlEmail();
			// 		} else {
			// 			this.router.navigate(['/']);
			// 		}
			// });
			}

		this.clearToBuildAdminForm = this.fb.group({
			ctbIdList: [{value: '', disabled: this.isDisabled}],
			gsdbCodeList: [{value: '', disabled: this.isDisabled}],
			weekDate: [{value: '', disabled: this.isDisabled}],
			weekDateCal: [{value: '', disabled: this.isDisabled}],
			weekNumber: [{value: '', disabled: this.isDisabled}],
			ctbIds: [{value: '', disabled: false}],
			ctbIsActive: ['', Validators.required],
			purchasingCoeLead: [{value: '', disabled: this.isDisabled}, Validators.required],
			supplierName: [{value: '', disabled: this.isDisabled}, Validators.required],
			mpnlCoeLead: [{value: '', disabled: this.isDisabled}, Validators.required],
			supplierCode: [{value: '', disabled: false}],
			partType: [{value: '', disabled: this.isDisabled}, Validators.required],
			commodity: [{value: '', disabled: this.isDisabled}, Validators.required],
			pmt: [{value: '', disabled: this.isDisabled}, Validators.required]
		});
	}

	private getAllCtbIdList() {
		this.ctbIds = [];
		this.clearToBuildAdminService.getAllCtbId().subscribe((data) => {
			if (data.length > 0) {
				this.ctbIds = data;
			} else {
				this.pushDialog('CTB Ids are not currently available');
			}
		});
		// this.clearToBuildAdminService.getAutomation().subscribe((data) => {
		// 		this.pushDialog('CTB Ids are not currently available');
		// });
	}

	private fetchCtbIdsFromEmail() {
		this.ctbIds = [];
		// @ts-ignore
		const clearToBuildAdmin = new ClearToBuildAdmin();
		clearToBuildAdmin.purchasingCoeLead = sessionStorage.getItem('emailAddress');
		this.clearToBuildAdminService.getCtbIdsFromEmailId(clearToBuildAdmin).subscribe(response => {
			this.ctbIds = response;
		});
	}
	private fetchSupplierNameFromEmail() {
		this.supplierName = [];
		// @ts-ignore
		const clearToBuildAdmin = new ClearToBuildAdmin();
		clearToBuildAdmin.purchasingCoeLead = sessionStorage.getItem('emailAddress');
		this.clearToBuildAdminService.getSupplierNameFromEmailId(clearToBuildAdmin).subscribe(response => {
			this.supplierName = response;
		});
	}
	private fetchSupplierCodeFromEmail() {
		this.supplierCode = [];
		// @ts-ignore
		const clearToBuildAdmin = new ClearToBuildAdmin();
		clearToBuildAdmin.purchasingCoeLead = sessionStorage.getItem('emailAddress');
		this.clearToBuildAdminService.getSupplierCodeFromEmailId(clearToBuildAdmin).subscribe(response => {
			this.supplierCode = response;
		});
	}

	private fetchCtbIdsFromMpnlEmail() {
		this.ctbIds = [];
		// @ts-ignore
		const clearToBuildMpnlAdmin = new ClearToBuildMpnlAdmin();
		clearToBuildMpnlAdmin.mpnlCoeLead = sessionStorage.getItem('emailAddress');
		this.clearToBuildAdminService.getCtbIdsFromMpnlEmailId(clearToBuildMpnlAdmin).subscribe(response => {
			this.ctbIds = response;
		});
	}

	private getAllCtbIdListForMpnl() {
		this.ctbIds = [];
		this.clearToBuildAdminService.getAllCtbIdForMpnl().subscribe((data) => {
			if (data.length > 0) {
				this.ctbIds = data;
			} else {
				this.pushDialog('CTB Ids are not currently available');
			}
		});
	}

	getCurrentWeekNumber(): number {
		const currentDate = new Date();
		const d = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()));
		const dayNum = d.getUTCDay() || 7;
		d.setUTCDate(d.getUTCDate() + 4 - dayNum);
		const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
		// @ts-ignore
		const weekNum = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
		// this.tigerSharkConstraintInfo.patchValue({weekNumber : weekNum});
		return weekNum;

	}

	getWeekNumberFromWeekDate() {
		const currentDate = this.clearToBuildAdminForm.get('weekDate').value;
		const d = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()));
		const dayNum = d.getUTCDay() || 7;
		d.setUTCDate(d.getUTCDate() + 4 - dayNum);
		const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
		// @ts-ignore
		const weekNum = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
		this.clearToBuildAdminForm.patchValue({weekNumber: weekNum});
	}

	private getMondayFromCurrentDate(): Date {
		const currentDate = new Date();
		const currentDay = currentDate.getDay();
		const diff = currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1); // adjust when day is sunday
		return new Date(currentDate.setDate(diff));
	}

	// loadData() {
	// 	if (this.selectedCtbIds.length > 0) {
	// 		const ctbIdList = [];
	// 		for (const item of this.selectedCtbIds) {
	// 			ctbIdList.push(item.name);
	// 		}
	// 		// @ts-ignore
	// 		const clearToBuildAdmin = new clearToBuildAdmin();
	// 		clearToBuildAdmin.ctbId = ctbIdList.join(';!');
	// 		clearToBuildAdmin.purchasingCoeLead = sessionStorage.getItem('emailAddress');
	// 		clearToBuildAdmin.weekDate = this.datepipe.transform(this.getMondayFromCurrentDate(), 'yyyy-MM-dd');
	// 		clearToBuildAdmin.weekNumber = this.getCurrentWeekNumber();
			// tslint:disable-next-line:max-line-length
			// 	this.clearToBuildAdminService.generateWppAdminReport(clearToBuildAdmin).subscribe((data) => {
			// 		this.fileFromDatabase = data;
			// 		ExcelUtility.load(this.fileFromDatabase).then((w) => {
			// 			this.isSubmitVisible = true;
			// 			this.spreadsheet.workbook = w;
			// 		});
			// 	});
			// } else {
			// 	this.pushMessage('error', 'Error', 'Please Select any Ctb Id.');
			// }
	// 	}
	// }

	// downloadData() {
		// if (this.selectedCtbIds.length > 0) {
		// 	const ctbIdList = [];
		// 	for (const item of this.selectedCtbIds) {
		// 		ctbIdList.push(item.name);
		// 	}
		// 	// @ts-ignore
		// 	const clearToBuildAdmin = new ClearToBuildAdmin();
		// 	clearToBuildAdmin.ctbId = ctbIdList.join(';!');
		// 	clearToBuildAdmin.purchasingCoeLead = sessionStorage.getItem('emailAddress');
		// 	clearToBuildAdmin.weekDate = this.datepipe.transform(this.getMondayFromCurrentDate(), 'yyyy-MM-dd');
		// 	clearToBuildAdmin.weekNumber = this.getCurrentWeekNumber();
			// tslint:disable-next-line:max-line-length
			// 	this.clearToBuildAdminService.generateWppAdminReport(clearToBuildAdmin).subscribe((data) => {
			// 		this.tigerSharkAdminDashboardService.downLoadFile(data, 'text/csv');
			// 	});
			// } else {
			// 	this.pushMessage('error', 'Error', 'Please Select any Ctb Id.');
			// }
		// }
	// }

	loadSuperAdminData() {
		if (this.selectedCtbIds.length > 0 || this.selectedSupplierName.length > 0 || this.selectedSupplierCode.length > 0) {
			// @ts-ignore
			const clearToBuildAdmin = new ClearToBuildAdmin();
			if (this.selectedSupplierName.length > 0) {
				const supplierNames = [];
				for (const item of this.selectedSupplierName) {
					supplierNames.push(item.name);
				}
				clearToBuildAdmin.supplierName = supplierNames.join(';!');
			}
			if (this.selectedSupplierCode.length > 0) {
				const supplierCodes = [];
				for (const item of this.selectedSupplierCode) {
					supplierCodes.push(item.name);
				}
				clearToBuildAdmin.supplierCode = supplierCodes.join(';!');
			}
			if (this.selectedCtbIds.length > 0) {
				const ctbIds = [];
				for (const item of this.selectedCtbIds) {
					ctbIds.push(item.name);
				}
				console.log('ctb ids at load :', ctbIds.join(';!'));
				clearToBuildAdmin.ctbId = ctbIds.join(';!');
			}
			clearToBuildAdmin.purchasingCoeLead = sessionStorage.getItem('emailAddress');
			// @ts-ignore
			clearToBuildAdmin.weekDate = this.datepipe.transform(this.getMondayFromCurrentDate(), 'yyyy-MM-dd');
			clearToBuildAdmin.weekNumber = this.getCurrentWeekNumber();
			// tslint:disable-next-line:max-line-length
			this.clearToBuildAdminService.generateCtbAdminReport(clearToBuildAdmin).subscribe((data) => {
				this.fileFromDatabase = data;
				ExcelUtility.load(this.fileFromDatabase).then((w) => {
					if (this.isMpnlAdmin || this.isDriAdmin || this.isMpnlSuperAdmin) {
						this.isMpnlSubmitVisible = true;
					} else {
						this.isSubmitVisible = true;
					}
					this.spreadsheet.workbook = w;
				});
			});
		} else {
			this.pushMessage('error', 'Error', 'Please Select any Supplier Name / Supplier Code/ CTB Id.');
		}
	}

	downloadSuperAdminData() {
		if (this.selectedCtbIds.length > 0 || this.selectedSupplierName.length > 0 || this.selectedSupplierCode.length > 0) {
			// @ts-ignore
			const clearToBuildAdmin = new ClearToBuildAdmin();
			if (this.selectedSupplierName.length > 0) {
				const supplierNames = [];
				for (const item of this.selectedSupplierName) {
					supplierNames.push(item.name);
				}
				clearToBuildAdmin.supplierName = supplierNames.join(';!');
			}
			if (this.selectedSupplierCode.length > 0) {
				const supplierCodes = [];
				for (const item of this.selectedSupplierCode) {
					supplierCodes.push(item.name);
				}
				clearToBuildAdmin.supplierCode = supplierCodes.join(';!');
			}
			if (this.selectedCtbIds.length > 0) {
				const ctbIdList = [];
				for (const item of this.selectedCtbIds) {
					ctbIdList.push(item.name);
				}
				clearToBuildAdmin.ctbId = ctbIdList.join(';!');
			}
			clearToBuildAdmin.purchasingCoeLead = sessionStorage.getItem('emailAddress');
			// @ts-ignore
			clearToBuildAdmin.weekDate = this.datepipe.transform(this.clearToBuildAdminForm.controls['weekDate'].value, 'yyyy-MM-dd');
			clearToBuildAdmin.weekNumber = this.clearToBuildAdminForm.get('weekNumber').value;
			this.clearToBuildAdminService.generateCtbAdminReport(clearToBuildAdmin).subscribe((data) => {
					this.clearToBuildAdminService.downLoadFile(data, 'text/csv');
					this.selectedCtbIds = [];
					this.reportDisplay = false;
				});
		} else {
			this.pushMessage('error', 'Error', 'Please Select any Supplier Name / Supplier Code/ CTB Id.');
		}
	}

	downloadWeekBasedReportForSuperAdmin() {
		if (this.selectedCtbIds.length > 0) {
			// @ts-ignore
			const clearToBuildAdmin = new ClearToBuildAdmin();
			if (this.selectedCtbIds.length > 0) {
				const ctbIdList = [];
				for (const item of this.selectedCtbIds) {
					ctbIdList.push(item.name);
				}
				clearToBuildAdmin.ctbId = ctbIdList.join(';!');
			}
			clearToBuildAdmin.purchasingCoeLead = sessionStorage.getItem('emailAddress');
			// @ts-ignore
			clearToBuildAdmin.weekDate = this.datepipe.transform(this.clearToBuildAdminForm.controls['weekDateCal'].value, 'yyyy-MM-dd');
			clearToBuildAdmin.weekNumber = this.clearToBuildAdminForm.get('weekNumber').value;
			this.clearToBuildAdminService.generateCtbAdminWeeklyReport(clearToBuildAdmin).subscribe((data) => {
				this.clearToBuildAdminService.downLoadFile(data, 'text/csv');
				this.selectedCtbIds = [];
				this.reportDisplay = false;
			});
		} else {
			this.pushMessage('error', 'Error', 'Please Select any CTB Id.');
		}
	}
	private validateFilter(clearToBuildAdmin: ClearToBuildAdmin): boolean {
		let isValidFilter = false;
		if ((clearToBuildAdmin.ctbId)) {
			isValidFilter = true;
		}
		return isValidFilter;
	}

	isNotDigit(value: number) {
		return isNaN(value);
	}
	saveCTBSpreadsheet() {
		this.spinnerService.show();
		const opt = new WorkbookSaveOptions();
		opt.type = 'blob';
		const workbook = this.spreadsheet.workbook;
		workbook.save(opt, (d) => {
			const fileExt = ExcelUtility.getExtension(workbook.currentFormat);
			// const fileName = 'C' + fileExt;
			let i ;
			console.log('newCtbId : ', workbook.worksheets(0).rows(i).cells(3).value);
			const newCtbIdList = [];
			let newCtbId;
			for (i = 5; workbook.worksheets(0).rows(i).cells(0).value != null || workbook.worksheets(0).rows(i).cells(0).value == null ; i++) {
				console.log('Into the for loop');
				console.log('value of i: ', i);
				console.log('cell 0  : ', workbook.worksheets(0).rows(i).cells(0).value);
				console.log('cell 1 : ', workbook.worksheets(0).rows(i).cells(1).value);
				console.log('cell 2 : ', workbook.worksheets(0).rows(i).cells(2).value);
				console.log('cell 3: ', workbook.worksheets(0).rows(i).cells(3).value);
				if (workbook.worksheets(0).rows(i).cells(0).value == null && workbook.worksheets(0).rows(i).cells(3).value != null) {
					newCtbId = workbook.worksheets(0).rows(i).cells(3).value;
					console.log('newCtbId : ', newCtbId);
				} else if (workbook.worksheets(0).rows(i).cells(0).value == null && workbook.worksheets(0).rows(i).cells(3).value == null) {
					break;
				}
				console.log('i value in loop: : ', i);
				newCtbIdList.push(newCtbId);
				console.log('newCtbIdList : ', newCtbIdList);
			}
			// @ts-ignore
			const clearToBuildAdmin = new ClearToBuildAdmin();
			clearToBuildAdmin.ctbId = newCtbIdList.join(';!');
//service
			this.clearToBuildAdminService.checkCtbIdExistence(clearToBuildAdmin).subscribe(isExistsData => {
				this.existingCtbIds = [];
				this.existingCtbIds = isExistsData;
				console.log('Exist List : ', isExistsData);
				const existingDataLength = Object.keys(this.existingCtbIds).length;
				console.log('Exist List Length : ', existingDataLength);
				if (existingDataLength > 0) {
					this.pushDialog( JSON.stringify(this.existingCtbIds) + ' already Exists');
			} else {
			// tslint:disable-next-line:max-line-length
			this.clearToBuildAdminService.update(d as File).subscribe((data) => {
					this.response = data;
					console.log(this.response);
					if (JSON.parse(this.response.success)) {
						this.pushDialog(this.response.message);
						if (this.isSuperAdmin === false) {
							this.fetchCtbIdsFromEmail();
							this.fetchSupplierNameFromEmail();
							this.fetchSupplierCodeFromEmail();
						} else {
							this.loadCtbIds();
							this.loadSupplierCodes();
							this.loadSupplierNames();
							// tslint:disable-next-line:align
						}						this.isSubmitVisible = false;
						this.spreadsheet.workbook = new Workbook();
					} else {
						this.pushDialog(this.response.message);
					}
					if (this.isSuperAdmin === false) {
						this.fetchCtbIdsFromEmail();
						this.fetchSupplierNameFromEmail();
						this.fetchSupplierCodeFromEmail();
					} else {
						this.loadCtbIds();
						this.loadSupplierCodes();
						this.loadSupplierNames();
					}				},
				error => {
					this.pushMessage('error', 'error',
						'Unable to Update Data, Please try again later');
				}
			);
		}
		});
		}, (e) => {
			this.pushMessage('error', 'Error', 'Unable to Update Data, Please try again later');
		});
	}

	saveMpnlSpreadsheet() {
			this.spinnerService.show();
			const opt = new WorkbookSaveOptions();
			opt.type = 'blob';
			const workbook = this.spreadsheet.workbook;
			workbook.save(opt, (d) => {
				const fileExt = ExcelUtility.getExtension(workbook.currentFormat);
				// tslint:disable-next-line:max-line-length
				this.clearToBuildAdminService.updateMpnlData(d as File).subscribe((data) => {
						this.response = data;
						console.log(this.response);
						if (JSON.parse(this.response.success)) {
							this.pushDialog(this.response.message);
							this.getAllCtbIdListForMpnl();
							// tslint:disable-next-line:align indent
							 this.isMpnlSubmitVisible = false;
							this.spreadsheet.workbook = new Workbook();
						} else {
							this.pushDialog(this.response.message);
						}
					},
					error => {
						this.pushMessage('error', 'error',
							'Unable to Update Data, Please try again later');
					}
				);
			}, (e) => {
				this.pushMessage('error', 'Error', 'Unable to Update Data, Please try again later');
			});
	}

	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 60000
		});
	}
	scheduler() {
		this.router.navigate(['/clear-to-build-scheduler']);
	}

	// onCreate() {
	// 	const ctbId = this.clearToBuildAdminForm.get('ctbId').value;
	// 	this.clearToBuildAdmin = this.clearToBuildAdminForm.getRawValue() as ClearToBuildAdmin;
	// 	this.clearToBuildAdmin.ctbId = ctbId;
	// 	// tslint:disable-next-line:max-line-length
	// 	this.clearToBuildAdmin.ctbIsActive = (this.clearToBuildAdminForm.get('ctbIsActive').value.code === 'Yes') ? true : false;
	// 	// tslint:disable-next-line:max-line-length
	// 	this.clearToBuildAdmin.partType = this.clearToBuildAdminForm.get('partType').value.code;
	//
	// 	// console.log(this.tigersharkConstraintAdmin);
	// 	if (!this.clearToBuildAdminFormValid(this.clearToBuildAdmin)) {
	// 		this.isClearToBuildAdminFormValid = false;
	// 		this.pushMessage('error', 'Error', 'Please fill all mandatory fields for adding new constraint information.');
	// 	} else {
	// 		this.isClearToBuildAdminFormValid = true;
	// 	}
	// 	// if (this.isClearToBuildAdminFormValid) {
	// 	// 	constraintId = constraintId.trim();
	// 	// 	this.clearToBuildAdminService.checkConstraintExistence(constraintId).subscribe(isConstraintExists => {
	// 	// 		if (isConstraintExists) {
	// 	// 			this.pushMessage('error', 'Error', 'Constraint #' + constraintId + ' already Exists');
	// 	// 		} else {
	// 	// 			// tslint:disable-next-line:max-line-length
	// 	// 			this.clearToBuildAdminService.createTigerSharkAdmin(this.clearToBuildAdmin).subscribe(response => {
	// 	// 				this.clearToBuildAdmin = response;
	// 	// tslint:disable-next-line:max-line-length
	// tslint:disable-next-line:max-line-length
	// 	// 				this.pushMessage('success', 'Success', 'Constraint Id : ' + this.clearToBuildAdmin.constraintId + ' has been Created successfully');
	// 	// 				this.clearToBuildAdminForm.reset();
	// 	// 				this.displayDialog = false;
	// 	// 				this.getAllConstraintIdList();
	// 	// 			});
	// 	// 		}
	// 	// 	});
	// 	// }
	// }

	// private clearToBuildAdminFormValid(clearToBuildAdmin: ClearToBuildAdmin): boolean {
	// 	let isClearToBuildAdminFormValid = false;
	// 	if (clearToBuildAdmin.ctbId !== null && clearToBuildAdmin.ctbId !== '' &&
	// 		clearToBuildAdmin.gsdbCode !== null && clearToBuildAdmin.gsdbCode !== '' &&
	// 		clearToBuildAdmin.ctbIsActive !== null &&
	// 		clearToBuildAdmin.partType !== null && clearToBuildAdmin.partType !== '' &&
	// 		clearToBuildAdmin.purchasingCoeLead !== null && clearToBuildAdmin.purchasingCoeLead !== '' &&
	// 		clearToBuildAdmin.semiconductorSupplier !== null && clearToBuildAdmin.semiconductorSupplier !== '' &&
	// 		clearToBuildAdmin.commodity !== null && clearToBuildAdmin.commodity !== '' &&
	// 		clearToBuildAdmin.globalLead !== null && clearToBuildAdmin.globalLead !== '' &&
	// 		clearToBuildAdmin.mpnlCoeLead !== null && clearToBuildAdmin.mpnlCoeLead !== '' &&
	// 		clearToBuildAdmin.purchasingLead !== null && clearToBuildAdmin.purchasingLead !== '' &&
	// 		clearToBuildAdmin.purchasingBuyer !== null && clearToBuildAdmin.purchasingBuyer !== '' &&
	// 		clearToBuildAdmin.pmt !== null && clearToBuildAdmin.pmt !== '' &&
	// 		clearToBuildAdmin.supplierName !== null && clearToBuildAdmin.supplierName !== '' &&
	// 		clearToBuildAdmin.contactName1 !== null && clearToBuildAdmin.contactName1 !== '' &&
	// 		clearToBuildAdmin.contactNumber1 !== null && clearToBuildAdmin.contactNumber1 !== '' &&
	// 		clearToBuildAdmin.email1 !== null && clearToBuildAdmin.email1 !== '' &&
	// 		clearToBuildAdmin.supplierEmailIdTo !== null && clearToBuildAdmin.supplierEmailIdTo !== '') {
	// 		isClearToBuildAdminFormValid = true;
	// 	}
	// 	return isClearToBuildAdminFormValid;
	// }
	pushDialog(dialogBody: any) {
		this.ref = this.dialogService.open(CustomDialogComponent, {
			header: 'Clear To Build Administrator - Response',
			footer: '',
			data: dialogBody,
			width: '50%',
			contentStyle: {
				'max-height': '1000px',
				overflow: 'auto'
			},
			baseZIndex: 10000,
		});
	}

	onCtbIdChange(event: any) {
		this.selectedCtbIds = event.value;
	}

	onWeekDateChange() {
		const weekDate = this.clearToBuildAdminForm.get('weekDateCal').value;
		const d = new Date(Date.UTC(weekDate.getFullYear(), weekDate.getMonth(), weekDate.getDate()));
		const dayNum = d.getUTCDay() || 7;
		d.setUTCDate(d.getUTCDate() + 4 - dayNum);
		const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
		// @ts-ignore
		const weekNum = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
		this.clearToBuildAdminForm.patchValue({weekNumber: weekNum});
		this.clearToBuildIds = [];
		this.selectedClearToBuildIds = [];
		// @ts-ignore
		this.clearToBuildAdmin = new ClearToBuildAdmin();
		// @ts-ignore
		// tslint:disable-next-line:max-line-length
		this.clearToBuildAdmin.weekDate = this.datePipe.transform(this.clearToBuildAdminForm.controls['weekDateCal'].value, 'yyyy-MM-dd');
		this.clearToBuildAdminService.getCtbIdsForWeekDate(this.clearToBuildAdmin).subscribe(data => {
			this.clearToBuildIds = data;
			if (this.clearToBuildIds.length === 0) {
				const date1 = new Date(this.datePipe.transform(this.clearToBuildAdmin.weekDate, 'MM.dd.yyyy'));
				const date2 = new Date(this.datePipe.transform(this.getMondayFromCurrentDate(), 'MM.dd.yyyy'));
				if (date1.getTime() < date2.getTime()) {
					this.pushDialog('No CTB Ids were active for the selected Week.');
				} else if (date1.getTime() > date2.getTime()) {
					this.pushDialog('Ctb Ids for Selected Week are not ready yet.');
				} else {
					this.pushDialog('Ctb Ids for Current Week are not ready yet.');
				}
			}
		});
	}

	uploadFile(event, form) {
		for (const file of event.files) {
			this.selectedFiles.push(file);
		}
		this.clearToBuildAdminService.update(this.selectedFiles[0]).subscribe((data) => {
				this.pushDialog('Data Updated Successfully!');
				form.clear();
				this.selectedFiles.pop();
				if (this.isSuperAdmin === false) {
					this.fetchCtbIdsFromEmail();
					this.fetchSupplierNameFromEmail();
					this.fetchSupplierCodeFromEmail();
				} else {
					this.loadCtbIds();
					this.loadSupplierCodes();
					this.loadSupplierNames();
				}
			},
			error => {
				this.pushDialog('Unable to Update Data, Please try again later');
				form.clear();
				this.selectedFiles.pop();
			}
		);
	}

	onSupplierNamesChange($event: any) {
		this.loadCtbIdsBasedOnSupplierName();
		this.loadSupplierCodesBasedOnSupplierName();
	}

	onSupplierCodeChange($event: any) {
		this.loadCtbIdsBasedOnSupplierNameAndSupplierCode();
	}

	onCtbIdsChange($event: any) {
		if (this.selectedCtbIds.length > 0) {
			const ctbIds = [];
			for (const item of this.selectedCtbIds) {
				ctbIds.push(item.name);
			}
			console.log('ctb ids at change  :', ctbIds.join(';!'));
			this.clearToBuildAdmin.ctbId = ctbIds.join(';!');
		}
	}

	loadCtbIds() {
		this.clearToBuildAdminService.loadCtbIds().subscribe(ctbIds => {
			this.ctbIds = [];
			// @ts-ignore
			this.ctbIds = ctbIds;
		});
	}
	loadSupplierNames() {
		this.clearToBuildAdminService.loadSupplierNames().subscribe(supplierNames => {
			this.supplierName = [];
			this.supplierName = supplierNames;
		});
	}
	loadSupplierCodes() {
		this.clearToBuildAdminService.loadSupplierCode().subscribe(supplierCodes => {
			this.supplierCode = [];
			this.supplierCode = supplierCodes;
		});
	}
	loadCtbIdsBasedOnSupplierName() {
		this.selectedCtbIds = [];
		if (this.selectedSupplierName.length > 0) {
			this.ctbIds = [];
			const supplierNames = [];
			for (const item of this.selectedSupplierName) {
				supplierNames.push(item.name);
			}
			// @ts-ignore
			this.clearToBuildAdmin = new ClearToBuildAdmin();
			this.clearToBuildAdmin.supplierName = supplierNames.join('|');
			this.clearToBuildAdminService.loadCtbIdsBasedOnSupplierName(this.clearToBuildAdmin).subscribe(ctbIds => {
				// @ts-ignore
				this.ctbIds = ctbIds;
			});
		} else if (this.isSuperAdmin === false) {
			this.fetchCtbIdsFromEmail();
		} else {
			this.loadCtbIds();
		}
	}
	loadSupplierCodesBasedOnSupplierName() {
		this.selectedSupplierCode = [];
		if (this.selectedSupplierName.length > 0) {
			this.supplierCode = [];
			const supplierNames = [];
			for (const item of this.selectedSupplierName) {
				supplierNames.push(item.name);
			}
			// @ts-ignore
			this.clearToBuildAdmin = new ClearToBuildAdmin();
			this.clearToBuildAdmin.supplierName = supplierNames.join('|');
			this.clearToBuildAdminService.loadSupplierCodesBasedOnSupplierName(this.clearToBuildAdmin).subscribe(supplierCodes => {
				// @ts-ignore
				this.supplierCode = supplierCodes;
			});
		} else if (this.isSuperAdmin === false) {
			this.fetchSupplierCodeFromEmail();
		} else {
			this.loadSupplierCodes();
		}
	}

	loadCtbIdsBasedOnSupplierNameAndSupplierCode() {
		this.selectedCtbIds = [];
		if (this.selectedSupplierName.length > 0 || this.selectedSupplierCode.length > 0) {
			this.ctbIds = [];
			// @ts-ignore
			this.clearToBuildAdmin = new ClearToBuildAdmin();
			if (this.selectedSupplierName.length > 0) {
				const supplierNames = [];
				for (const item of this.selectedSupplierName) {
					supplierNames.push(item.name);
				}
				this.clearToBuildAdmin.supplierName = supplierNames.join('|');
			}
			if (this.selectedSupplierCode.length > 0) {
				const supplierCode = [];
				for (const item of this.selectedSupplierCode) {
					supplierCode.push(item.name);
				}
				this.clearToBuildAdmin.supplierCode = supplierCode.join('|');
			}
			this.clearToBuildAdminService.loadCtbIdsBasedOnSupplierNameAndSupplierCode(this.clearToBuildAdmin).subscribe(ctbIds => {
				// @ts-ignore
				this.ctbIds = ctbIds;
			});
		} else if (this.isSuperAdmin === false) {
			this.fetchCtbIdsFromEmail();
		} else {
			this.loadCtbIds();
		}
	}

	dropDownConfig() {
		this.router.navigate(['/ctb-admin-dropdown-configuration']);
	}
}
