import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Tier1EComponentShortageAdmin} from '../../tier1-e-component-shortage/model/tier1-e-component-shortage-admin';
import {LongValue} from 'igniteui-angular-spreadsheet';
import {FormArray} from '@angular/forms';

@Injectable({
	providedIn: 'root'
})
export class SustainabilitySurveyService {
	private isSupplierUser: boolean;

	constructor(private http: HttpClient) {
	}

	private GET_SUSTAINABILITY_SURVEY = environment.apiUrl + 'ss/survey/data/get';
	private UPDATE_PART_FLAG_SUSTAINABILITY_SURVEY = environment.apiUrl + 'ss/survey/updatePartFlag';
	private UPDATE_SUPPLIER_FLAG_SUSTAINABILITY_SURVEY = environment.apiUrl + 'ss/survey/updateSupplierFlag';
	private DELETE_SUPPLIER_ID = environment.apiUrl + 'ss/admin/delete';
	private CRT_UPD_SUPL_SUSTAINABILITY_SURVEY = environment.apiUrl + 'ss/survey/supplier-excel/update-create';
	private CRT_UPD_PRT_SUSTAINABILITY_SURVEY = environment.apiUrl + 'ss/survey/part-excel/update-create';
	private CRT_UPD_SUPL_FORM_SUSTAINABILITY_SURVEY = environment.apiUrl + 'ss/survey/supplier-form/update-create';
	private CRT_UPD_PRT_FORM_SUSTAINABILITY_SURVEY = environment.apiUrl + 'ss/survey/part-form/update-create';
	private CRT_UPD_DEL_FORM_SUSTAINABILITY_SURVEY = environment.apiUrl + 'ss/survey/part-supplier/del';
	private DROP_DOWN_GET_SUPL_NAME = environment.apiUrl + 'ss/survey/dropdown/suppliername';
	private DROP_DOWN_GET_VEHICLE_NO = environment.apiUrl + 'ss/survey/dropdown/vehiclecode';
	private DROP_DOWN_GSDB = environment.apiUrl + 'ss/survey/getGSDBBasedOnEmail';
	private DROP_DOWN_GET_PART_NO = environment.apiUrl + 'ss/survey/dropdown/partno';
	private TRACKER_ENDPOINT = environment.apiUrl + 'ss/admin';
	private DOWNLOAD_R_REPORT = environment.apiUrl + 'r/survey/report';
	private DOWNLOAD_SS_REPORT = environment.apiUrl + 'ss/survey/downloadSSReport';
	private HELP_ENDPOINT = environment.apiUrl + 'helpContent/download/';
	private EVIDENCE_GET = environment.apiUrl + 'ss/docs/evidence/get';
	private EVIDENCE_UPLOAD = environment.apiUrl + 'ss/docs/evidence/upload';
	private EVIDENCE_DELETE = environment.apiUrl + 'ss/docs/evidence/delete';
	private EVIDENCE_DOWNLOAD = environment.apiUrl + 'ss/docs/evidence/download';
	private EVIDENCE_ACCESS = environment.apiUrl + 'ss/docs/evidence/access';
	private EVIDENCE_REQUIRED = environment.apiUrl + 'ss/admin/isEvidenceReqd';
	private EVIDENCE_DROPDOWN = environment.apiUrl + 'ss/docs/evidence/dropdownvalues';
	private LOAD_QUESTIONNAIRE = environment.apiUrl + 'r/survey/data/save';
	private UPDATE_QUESTIONNAIRE = environment.apiUrl + 'r/survey/data/update';
	private GET_QUESTIONNAIRE = environment.apiUrl + 'r/survey/data/get';
	private RESILIENCE_REQUIRED = environment.apiUrl + 'ss/admin/isResilienceReqd';
	private EXCEL_TEMPLATE_URL = environment.apiUrl + 'r/survey';

	getAllDataForSustainabilitySurvey(json: object): Observable<any> {
		console.log('get access column service is called');
		return this.http.post<any>(this.GET_SUSTAINABILITY_SURVEY, json);
	}

	getDataForEvidences(partTierSupplierId: any, emailAddress: any): Observable<any> {
		console.log('get evidences service triggered');
		const params = new HttpParams()
			.set('part_tier_supp_ID', partTierSupplierId)
			.set('email', emailAddress);
		return this.http.post(this.EVIDENCE_GET, null, {params});
	}

	uploadEvidence(fileToUpload: File, partTierSupplierId: any, fileName: any, docName: any, addlComments: any): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('File', fileToUpload);
		formData.append('fileName', fileName);
		formData.append('documentName', docName);
		formData.append('addlComments', addlComments);
		const headers = new HttpHeaders();
		headers.set('Content-Type', 'multipart/form-data');
		const params = new HttpParams()
			.set('part_tier_supp_ID', partTierSupplierId);
		return this.http.post(this.EVIDENCE_UPLOAD, formData, {headers, params});
	}

	deleteEvidence(supplierRowId: any): Observable<any> {
		const params = new HttpParams()
			.set('evedience_row_id', supplierRowId);
		console.log(params);
		return this.http.post<any>(this.EVIDENCE_DELETE, null, {params});
	}

	downloadEvidence(supplierRowId: any, fileName, email): Observable<any> {
		const params = new HttpParams()
			.set('evedience_row_id', supplierRowId)
			.set('fileName', fileName)
			.set('email', email);
		// @ts-ignore
		return this.http.post<any>(this.EVIDENCE_DOWNLOAD, null, {params, responseType: 'blob'});
	}

	getScreenAccessFlags(email): Observable<any> {
		const params = new HttpParams()
			.set('email', email);
		return this.http.post<any>(this.EVIDENCE_ACCESS, null, {params});
	}

	uploadEvidenceRequiredFlags(gsdb, tier1Supplier, businessName): Observable<any> {
		console.log('service supplier evidence required being called', gsdb);
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		const body = {
			gsdb: gsdb,
			tier1Supplier: tier1Supplier,
			businessName: businessName
		};
		return this.http.post<any>(this.EVIDENCE_REQUIRED, body, {headers});
	}

	uploadSustainabilitySuplFile(fileToUpload: File): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('file', fileToUpload);
		return this.http.post(this.CRT_UPD_SUPL_SUSTAINABILITY_SURVEY, formData);
	}

	uploadSustainabilityPartFile(fileToUpload: File, gsdb: string, source: string): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('file', fileToUpload);
		formData.append('gsdb', gsdb);
		formData.append('source', source);
		return this.http.post(this.CRT_UPD_PRT_SUSTAINABILITY_SURVEY, formData);

	}

	editSupplier(bookMovieForm: any): Observable<any> {
		return this.http.post<any>(this.CRT_UPD_SUPL_FORM_SUSTAINABILITY_SURVEY, bookMovieForm);
	}

	editPart(bookMovieForm: any): Observable<any> {
		return this.http.post<any>(this.CRT_UPD_PRT_FORM_SUSTAINABILITY_SURVEY, bookMovieForm);
	}

	deleteData(baseInformationElement: any): Observable<any> {
		return this.http.post<any>(this.CRT_UPD_DEL_FORM_SUSTAINABILITY_SURVEY, baseInformationElement);
	}

	public getAllTier1Suppliers(businessName: string): Observable<any> {
		const params = new HttpParams()
			.set('businessName', businessName);
		return this.http.get(this.TRACKER_ENDPOINT + `/get`, {params});
	}

	public getAllTier1Suppliers1(businessName: string): Observable<any> {
		const params = new HttpParams()
			.set('businessName', businessName);
		return this.http.get(this.TRACKER_ENDPOINT + `/getSupplierList`, {params});
	}

	public getContactDataFromTier1Supplier(tier1EComponentShortageAdmin: Tier1EComponentShortageAdmin): Observable<any> {
		return this.http.post(this.TRACKER_ENDPOINT + `/adminDataFromTier1Supplier`, tier1EComponentShortageAdmin, {responseType: 'json'});
	}

	// tslint:disable-next-line:max-line-length
	public createTier1EComponentShortageAdmin(tier1EComponentShortageAdmin: Tier1EComponentShortageAdmin): Observable<Tier1EComponentShortageAdmin> {
		return this.http.post<Tier1EComponentShortageAdmin>(this.TRACKER_ENDPOINT + `/create`, tier1EComponentShortageAdmin);
	}

	// tslint:disable-next-line:max-line-length
	public updateTier1EComponentShortageAdmin(tier1EComponentShortageAdmin: Tier1EComponentShortageAdmin): Observable<Tier1EComponentShortageAdmin> {
		return this.http.post<Tier1EComponentShortageAdmin>(this.TRACKER_ENDPOINT + '/update', tier1EComponentShortageAdmin);
	}

	retrieveAllAdminData(businessName: string): Observable<any> {
		const params = new HttpParams()
			.set('businessName', businessName);
		return this.http.get<Tier1EComponentShortageAdmin>(this.TRACKER_ENDPOINT + '/getAllAdminData', {params});
	}

	partNumberChangeSupplierNameToGetVehicleNumber(fordFullPartNo: any): Observable<any> {
		console.log(fordFullPartNo);
		const params = new HttpParams()
			.set('fordFullPartNo', fordFullPartNo)
			.set('suppliername', null);
		return this.http.get<any>(this.DROP_DOWN_GET_VEHICLE_NO, {params});
	}

	partNumberChangeAndVehicleCodeChangeToGetSupplierName(fordFullPartNo: any): Observable<any> {
		console.log(fordFullPartNo);
		const params = new HttpParams()
			.set('fordFullPartNo', fordFullPartNo)
			.set('vehicleCode', null);
		return this.http.get<any>(this.DROP_DOWN_GET_SUPL_NAME, {params});
	}

	getVehicleNumber(suppliername: any, fordFullPartNo: any): Observable<any> {
		console.log(suppliername);
		const params = new HttpParams()
			.set('suppliername', !suppliername || suppliername === 'undefined' ? null : suppliername)
			.set('fordFullPartNo', !fordFullPartNo || fordFullPartNo === 'undefined' ? null : fordFullPartNo);
		return this.http.get<any>(this.DROP_DOWN_GET_VEHICLE_NO, {params});
	}

	getPartNumber(suppliername: any, vehicleCode: any): Observable<any> {
		console.log(vehicleCode);
		const params = new HttpParams()
			.set('suppliername', !suppliername || suppliername === 'undefined' ? null : suppliername)
			.set('vehicleCode', !vehicleCode || vehicleCode === 'undefined' ? null : vehicleCode);
		return this.http.get<any>(this.DROP_DOWN_GET_PART_NO, {params});
	}

	getSupplierName(fordFullPartNo: any, vehicleCode: any): Observable<any> {
		console.log(vehicleCode);
		const params = new HttpParams()
			.set('fordFullPartNo', !fordFullPartNo || fordFullPartNo === 'undefined' ? null : fordFullPartNo)
			.set('vehicleCode', !vehicleCode || vehicleCode === 'undefined' ? null : vehicleCode);
		return this.http.get<any>(this.DROP_DOWN_GET_SUPL_NAME, {params});
	}

	generateSSReport(str: string) {
		console.log('DOWNLOAD_SS_REPORT service is called');
		return this.http.post(this.DOWNLOAD_SS_REPORT, str, {responseType: 'blob'});
	}

	generateRReport(str: string) {
		console.log('DOWNLOAD_R_REPORT service is called');
		return this.http.post(this.DOWNLOAD_R_REPORT, null, {responseType: 'blob'});
	}

	downLoadSSFile(data: any, type: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = 'Ford_VSM_UFLPA.xlsx';
		anchor.href = url;
		anchor.click();
	}

	downLoadRFile(data: any, type: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = 'Ford_Project_R_Non_Microchip.xlsx';
		anchor.href = url;
		anchor.click();
	}

	public helpEndPointDownload(temp: any): Observable<any> {
		return this.http.get(this.HELP_ENDPOINT + temp, {responseType: 'blob'});
	}

	downloadFileMp4(data: any, type: string, fileName: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = fileName + '.mp4';
		anchor.href = url;
		anchor.click();
	}

	downLoadFileDocument(data: any, type: string, fileName: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = fileName + '.docx';
		anchor.href = url;
		anchor.click();
	}

	downLoadFilePDF(data: any, type: string, fileName: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = fileName + '.pdf';
		anchor.href = url;
		anchor.click();
	}

	downloadEvidenceDocument(res: any, type: string, fileName: string) {
		console.log('downloadEvidenceDocument');
		const blob = new Blob([res], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = fileName;
		anchor.href = url;
		anchor.click();
	}

	getSSEvidenceDropdownValues() {
		console.log('get dropdown values');
		return this.http.post<any>(this.EVIDENCE_DROPDOWN, null);
	}

	resilienceQuestionsRequiredFlags(gsdb, tier1Supplier, businessName): Observable<any> {
		console.log('service supplier evidence required being called', gsdb);
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		const body = {
			gsdb: gsdb,
			tier1Supplier: tier1Supplier,
			businessName: businessName
		};
		return this.http.post<any>(this.RESILIENCE_REQUIRED, body, {headers});
	}

	// tslint:disable-next-line:max-line-length
	getQuestionnaireData(globalManufacturingList: FormArray, otherSuppList: FormArray, bufferStockmin: any, bufferStockmax: any, isGlobalManufacturing: any, isOtherSupp: any, parTierSupId: any, partNo: any) {
		console.log('invoke getProjectRSurveyData ');
		const resilienceQuestionDetails = this.getJson(globalManufacturingList);
		const otherSuppliers = this.getJson(otherSuppList);
		// tslint:disable-next-line:variable-name
		const ResilienceQuestionsMain = {
			partTierSupplierId: parTierSupId,
			fordPartNo: partNo,
			bufferStockCommodityMin: bufferStockmin,
			bufferStockCommodityMax: bufferStockmax,
			isGlobalManufacturingFootprint: isGlobalManufacturing,
			isOtherSuppliers: isOtherSupp,
			globalManufacturingFPList: resilienceQuestionDetails,
			otherSuppliersList: otherSuppliers
		};
		console.log(ResilienceQuestionsMain);
		return this.http.post(this.GET_QUESTIONNAIRE, ResilienceQuestionsMain);
	}

	// tslint:disable-next-line:max-line-length
	submitQuestionnaire(globalManufacturingList: FormArray, otherSuppList: FormArray, bufferStockmin: any, bufferStockmax: any, pcba: any, isGlobalManufacturing: any, isOtherSupp: any, partierSupId: any, partNo: any) {
		console.log('invoke saveProjectRSurveyData ');
		const resilienceQuestionDetails = this.getJson(globalManufacturingList);
		const otherSuppliers = this.getJson(otherSuppList);
		// tslint:disable-next-line:variable-name
		const ResilienceQuestionsMain = {
			partTierSupplierId: partierSupId,
			fordPartNo: partNo,
			bufferStockCommodityMin: bufferStockmin,
			bufferStockCommodityMax: bufferStockmax,
			pcba,
			isGlobalManufacturingFootprint: isGlobalManufacturing,
			isOtherSuppliers: isOtherSupp,
			globalManufacturingFPList: resilienceQuestionDetails,
			otherSuppliersList: otherSuppliers
		};
		console.log(ResilienceQuestionsMain);
		return this.http.post(this.LOAD_QUESTIONNAIRE, ResilienceQuestionsMain);
	}

	// tslint:disable-next-line:max-line-length
	updateQuestionnaire(resId: any, globalManufacturingList: FormArray, otherSuppList: FormArray, bufferStockmin: any, bufferStockmax: any, pcba: any, isGlobalManufacturing: any, isOtherSupp: any, partierSupId: any, partNo: any) {
		console.log('invoke saveProjectRSurveyData ');
		const resilienceQuestionDetails = this.getJson(globalManufacturingList);
		const otherSuppliers = this.getJson(otherSuppList);
		// tslint:disable-next-line:variable-name
		const ResilienceQuestionsMain = {
			resId,
			partTierSupplierId: partierSupId,
			fordPartNo: partNo,
			bufferStockCommodityMin: bufferStockmin,
			bufferStockCommodityMax: bufferStockmax,
			pcba,
			isGlobalManufacturingFootprint: isGlobalManufacturing,
			isOtherSuppliers: isOtherSupp,
			globalManufacturingFPList: resilienceQuestionDetails,
			otherSuppliersList: otherSuppliers
		};
		console.log(ResilienceQuestionsMain);
		return this.http.post(this.UPDATE_QUESTIONNAIRE, ResilienceQuestionsMain);
	}

	private getJson(globalManufacturingList: FormArray) {
		const resilienceQuestionDetails = [];
		// tslint:disable-next-line:prefer-for-of
		for (let i = 0; i < globalManufacturingList.length; i++) {
			const one = globalManufacturingList[i];
			resilienceQuestionDetails.push(globalManufacturingList[i]);

		}
		console.log('list of obj ' + resilienceQuestionDetails);
		return resilienceQuestionDetails;
	}

	updatePartFlag(num: any, collectionId: any): Observable<any> {
		const params = new HttpParams()
			.set('flagNum', num)
			.set('collectionId', collectionId);
		return this.http.get<any>(this.UPDATE_PART_FLAG_SUSTAINABILITY_SURVEY, {params});
	}

	updateSupplierFlag(num: any, partTierSupplierId: any): Observable<any> {
		const params = new HttpParams()
			.set('flagNum', num)
			.set('partTierSupplierId', partTierSupplierId);
		return this.http.get<any>(this.UPDATE_SUPPLIER_FLAG_SUSTAINABILITY_SURVEY, {params});
	}

	deleteSupplierById(id: any, businessName: string): Observable<any> {
		const params = new HttpParams()
			.set('id', id)
			.set('businessName', businessName);
		return this.http.post(this.DELETE_SUPPLIER_ID, null, {params});
	}

	public getGSDBBasedOnEmailId(emailAddress: string, businessName: string): Observable<any> {
		const params = new HttpParams()
			.set('emailAddress', emailAddress)
			.set('businessName', businessName);
		return this.http.get(this.DROP_DOWN_GSDB, {params});
	}

	uploadTemplate(fileToUpload: File, fileName: string, email: string): Observable<any> {
		// const endpoint = this.DASHBOARD_ENDPOINT + '/upload';
		const endpoint = this.EXCEL_TEMPLATE_URL + '/uvsm';
		// const headers = new HttpHeaders().set('Content-Type', 'application/json');
		const formData: FormData = new FormData();
		formData.append('file', fileToUpload);
		formData.append('source', 'Project R');
		formData.append('fileName', fileName);
		formData.append('email', email);
		return this.http.post(endpoint, formData);
	}

	getTemplateDropDownValues(){
		const endpoint = this.EXCEL_TEMPLATE_URL + `/dropDownValues`;
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.http.get(endpoint, {responseType: 'json'});
	}

	getTemplateData(json:Object){
		const endpoint = this.EXCEL_TEMPLATE_URL + `/dvsm`;
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		// const body = {
		// 	source: source,
		// 	fordPartNumber: [],
		// 	vehicleCode: [],
		// 	supplierName: [],
		// 	pageNo: 1,
		// 	pageRows: 1000,
		// 	email:emailAddress,
		// 	enablePopulatingdata: enablePopulatingdataFlag ? "YES" : "NO"
		// };
		return this.http.post(endpoint, json,{responseType: 'blob'});
	}

	downLoadNonMicrochipExcelFile(data: any, type: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = 'Ford_Project_R_Non_Microchip.xlsx';
		anchor.href = url;
		anchor.click();
	}

}
