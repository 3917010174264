import {
	Component,
	OnInit
} from '@angular/core';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {TigersharkDashboardDownloadService} from '../service/tigershark-dashboard-download.service';
import {Router} from '@angular/router';
import {NTierMasterAdminService} from '../../n-tier-master-admin/service/n-tier-master-admin.service';
import {TigerSharkConstraintTracker} from '../model/tiger-shark-constraint-tracker';
import {
	FormBuilder,
	FormGroup
} from '@angular/forms';
import {MessageService, DialogService, DynamicDialogRef} from 'primeng/api';
import {TigersharkConstraintTrackerService} from '../service/tigershark-constraint-tracker.service';
import {DatePipe} from '@angular/common';
import {SupplierService} from '../../../home/services/supplier.service';
import {CustomDialogComponent} from '../../../custom-dialog/custom-dialog/custom-dialog.component';

@Component({
	selector: 'tiger-shark-constraint-tracker-dashboard',
	templateUrl: './tiger-shark-constraint-tracker-dashboard.component.html',
	styleUrls: ['./tiger-shark-constraint-tracker-dashboard.component.scss']
})
export class TigerSharkConstraintTrackerDashboardComponent implements OnInit {
	constraintFilterForm: FormGroup;
	constraintIdsList = [];
	selectedConstraintId: any;
	isDisabled = false;
	consolidatedReportFilterForm: FormGroup;
	constraintIds: any[] = [];
	businessUnitList: any[] = [];
	plantCodeList: any[] = [];
	vehicleNameList: any[] = [];
	selectedConstraintIds: any[] = [];
	selectedBusinessUnits: any[] = [];
	selectedPlantCodes: any[] = [];
	selectedVehicleNames: any[] = [];
	tigerSharkConstraintTracker: TigerSharkConstraintTracker;
	ref: DynamicDialogRef;

	// tslint:disable-next-line:max-line-length
	constructor(private supplierService: SupplierService, private messageService: MessageService, private fb: FormBuilder, private datepipe: DatePipe, private tigersharkConstraintTrackerService: TigersharkConstraintTrackerService, private tigersharkDashboardDownloadService: TigersharkDashboardDownloadService, private spinnerService: SpinnerService, private router: Router, private masterAdminService: NTierMasterAdminService, private dialogService: DialogService) {
	}

	ngOnInit() {
		this.router.navigate(['/']);
		// this.supplierService.pushSelectedSurvey('WPP');
		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
		} else {
			// tslint:disable-next-line:max-line-length
			this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'WPP Screen').subscribe(userHasAdminAccess => {
				if (!userHasAdminAccess) {
					this.router.navigate(['/']);
				}
			});
		}
		this.constraintFilterForm = this.fb.group({
			constraintIdList: [{value: '', disabled: this.isDisabled}],
			weekDate: [{value: '', disabled: this.isDisabled}],
			weekNumber: [{value: '', disabled: this.isDisabled}]
		});
		this.fetchAllConstraintIdsFordFordUser();
		this.consolidatedReportFilterForm = this.fb.group({
			constraintIds: [{value: '', disabled: this.isDisabled}],
			weekDate_cr: [{value: '', disabled: this.isDisabled}],
			weekNumber_cr: [{value: '', disabled: true}],
			vehicleName: [{value: '', disabled: this.isDisabled}],
			actualBusinessUnit: [{value: '', disabled: this.isDisabled}],
			plantCode: [{value: '', disabled: this.isDisabled}]
		});
	}

	getCurrentWeekNumber(): number {
		const currentDate = new Date();
		const oneJan = new Date(currentDate.getFullYear(), 0, 1);
		// @ts-ignore
		const numberOfDays = Math.floor((currentDate - oneJan) / (24 * 60 * 60 * 1000));
		const weekNumber = Math.ceil((currentDate.getDay() + 1 + numberOfDays) / 7);
		return weekNumber;
	}

	private getMondayFromCurrentDate(): Date {
		const currentDate = new Date();
		const currentDay = currentDate.getDay();
		const diff = currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1); // adjust when day is sunday
		return new Date(currentDate.setDate(diff));
	}

	// getWeekNumberFromWeekDate() {
	// 	const currentDate = this.constraintFilterForm.get('weekDate').value;
	// 	const d = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()));
	// 	const dayNum = d.getUTCDay() || 7;
	// 	d.setUTCDate(d.getUTCDate() + 4 - dayNum);
	// 	const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
	// 	// @ts-ignore
	// 	const weekNum = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
	// 	this.constraintFilterForm.patchValue({weekNumber: weekNum});
	// }

	private fetchAllConstraintIdsFordFordUser() {
		this.tigersharkConstraintTrackerService.getAllConstraintIdsForFordUser().subscribe((data) => {
			this.constraintIdsList = data;
		});
	}

	// downloadDashboard() {
	// 	const tigerSharkConstraintTacker = new TigerSharkConstraintTracker();
	// 	// @ts-ignore
	// 	tigerSharkConstraintTacker.weekDate = this.datepipe.transform(this.constraintFilterForm.controls['weekDate'].value, 'yyyy-MM-dd');
	// 	tigerSharkConstraintTacker.constraintId = this.selectedConstraintId;
	// 	console.log(tigerSharkConstraintTacker);
	// 	if (this.validateFilter(tigerSharkConstraintTacker)) {
	// 		this.tigersharkDashboardDownloadService.downloadDashboardInExcel(tigerSharkConstraintTacker);
	// 		this.spinnerService.show();
	// 	} else {
	// 		this.pushMessage('error', 'Error', 'Please Complete the filter.');
	// 	}
	// }

	// private validateFilter(tigerSharkConstraintTracker: TigerSharkConstraintTracker): boolean {
	// 	let isValidFilter = false;
	// 	if (tigerSharkConstraintTracker.constraintId !== null &&
	// 		tigerSharkConstraintTracker.weekDate !== null) {
	// 		isValidFilter = true;
	// 	}
	// 	return isValidFilter;
	// }

	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 60000
		});
	}

	onWeekDateChange() {
		const weekDate = this.consolidatedReportFilterForm.get('weekDate_cr').value;
		const d = new Date(Date.UTC(weekDate.getFullYear(), weekDate.getMonth(), weekDate.getDate()));
		const dayNum = d.getUTCDay() || 7;
		d.setUTCDate(d.getUTCDate() + 4 - dayNum);
		const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
		// @ts-ignore
		const weekNum = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
		this.consolidatedReportFilterForm.patchValue({weekNumber_cr: weekNum});
		this.constraintIds = [];
		this.businessUnitList = [];
		this.vehicleNameList = [];
		this.plantCodeList = [];
		this.selectedConstraintIds = [];
		this.selectedBusinessUnits = [];
		this.selectedPlantCodes = [];
		this.selectedVehicleNames = [];
		this.tigerSharkConstraintTracker = new TigerSharkConstraintTracker();
		// @ts-ignore
		// tslint:disable-next-line:max-line-length
		this.tigerSharkConstraintTracker.weekDate = this.datepipe.transform(this.consolidatedReportFilterForm.controls['weekDate_cr'].value, 'yyyy-MM-dd');
		this.tigersharkDashboardDownloadService.getActiveConstraintsForWeek(this.tigerSharkConstraintTracker).subscribe(data => {
			this.constraintIds = data;
			if (this.constraintIds.length === 0) {
				const date1 = new Date(this.datepipe.transform(this.tigerSharkConstraintTracker.weekDate, 'MM.dd.yyyy'));
				const date2 = new Date(this.datepipe.transform(this.getMondayFromCurrentDate(), 'MM.dd.yyyy'));
				if (date1.getTime() < date2.getTime()) {
					this.pushDialog('No Constraints were active for the selected Week.');
					// this.pushMessage('info', 'Info', 'No Constraints were active for the selected Week.');
				} else if (date1.getTime() > date2.getTime()) {
					this.pushDialog('Constraints for Selected Week are not ready yet.');
					// this.pushMessage('info', 'Info', 'Constraints for Selected Week are not ready yet.');
				} else {
					this.pushDialog('Constraints for Current Week are not ready yet.');
					// this.pushMessage('info', 'Info', 'Constraints for Current Week are not ready yet.');
				}
			}
		});
	}

	onConstraintIdChange(event: any) {
		this.selectedConstraintIds = event.value;
		this.businessUnitList = [];
		this.plantCodeList = [];
		this.vehicleNameList = [];
		this.selectedBusinessUnits = [];
		this.selectedPlantCodes = [];
		this.selectedVehicleNames = [];
		if (this.selectedConstraintIds.length > 0) {
			const constraintList = [];
			for (const item of this.selectedConstraintIds) {
				constraintList.push(item.name);
			}
			this.tigerSharkConstraintTracker.constraintIdString = constraintList.join(';!');
			this.tigersharkDashboardDownloadService.getActualBusinessUnits(this.tigerSharkConstraintTracker).subscribe(data => {
				this.businessUnitList = data;
			});
		}
	}

	onBUChange(event: any) {
		this.selectedBusinessUnits = event.value;
		this.plantCodeList = [];
		this.vehicleNameList = [];
		this.selectedPlantCodes = [];
		this.selectedVehicleNames = [];
		if (this.selectedBusinessUnits.length > 0) {
			const actualBuList = [];
			for (const item of this.selectedBusinessUnits) {
				actualBuList.push(item.name);
			}
			this.tigerSharkConstraintTracker.actualBusinessUnit = actualBuList.join(';!');
			this.tigersharkDashboardDownloadService.getPlantCodes(this.tigerSharkConstraintTracker).subscribe(data => {
				this.plantCodeList = data;
			});
		}
	}

	onPlantCodeChange(event: any) {
		this.selectedPlantCodes = event.value;
		this.vehicleNameList = [];
		this.selectedVehicleNames = [];
		if (this.selectedPlantCodes.length > 0) {
			const plantCodesList = [];
			for (const item of this.selectedPlantCodes) {
				plantCodesList.push(item.name);
			}
			this.tigerSharkConstraintTracker.plantCode = plantCodesList.join(';!');
			this.tigersharkDashboardDownloadService.getVehicleNames(this.tigerSharkConstraintTracker).subscribe(data => {
				this.vehicleNameList = data;
			});
		}
	}

	onVehicleNameChange(event: any) {
		this.selectedVehicleNames = event.value;
		if (this.selectedVehicleNames.length > 0) {
			const vehicleNameList = [];
			for (const item of this.selectedVehicleNames) {
				vehicleNameList.push(item.name);
			}
			this.tigerSharkConstraintTracker.vehicleName = vehicleNameList.join(';!');
		}
	}

	downloadConsolidatedReport() {
		const tigerSharkConstraintTracker = new TigerSharkConstraintTracker();
		// @ts-ignore
		// tslint:disable-next-line:max-line-length
		tigerSharkConstraintTracker.weekDate = this.datepipe.transform(this.consolidatedReportFilterForm.controls['weekDate_cr'].value, 'yyyy-MM-dd');
		if (tigerSharkConstraintTracker.weekDate != null && tigerSharkConstraintTracker.weekDate.toString().trim().length > 0) {
			if (this.constraintIds.length > 0) {
				if (this.selectedConstraintIds.length > 0) {
					const constraintList = [];
					for (const item of this.selectedConstraintIds) {
						constraintList.push(item.name);
					}
					tigerSharkConstraintTracker.constraintIdString = constraintList.join(';!');
				}
				if (this.selectedBusinessUnits.length > 0) {
					const actualBuList = [];
					for (const item of this.selectedBusinessUnits) {
						actualBuList.push(item.name);
					}
					tigerSharkConstraintTracker.actualBusinessUnit = actualBuList.join(';!');
				}
				if (this.selectedPlantCodes.length > 0) {
					const plantCodesList = [];
					for (const item of this.selectedPlantCodes) {
						plantCodesList.push(item.name);
					}
					tigerSharkConstraintTracker.plantCode = plantCodesList.join(';!');
				}
				if (this.selectedVehicleNames.length > 0) {
					const vehicleNameList = [];
					for (const item of this.selectedVehicleNames) {
						vehicleNameList.push(item.name);
					}
					tigerSharkConstraintTracker.vehicleName = vehicleNameList.join(';!');
				}
				console.log(tigerSharkConstraintTracker);
				this.tigersharkDashboardDownloadService.downloadConsolidatedReport(this.tigerSharkConstraintTracker);
				this.spinnerService.show();
				if (this.selectedConstraintIds.length > 10) {
					this.pushMessage('warn', 'Warning', 'Selecting more constraints may take a while to generate the report');
				}
			} else {
				this.pushDialog('Constraints are not available for the Selected Week Date.');
				// this.pushMessage('error', 'Error', 'Constraints are not available for the Selected Week Date.');
			}
		} else {
			this.pushMessage('error', 'Error', 'Please Select a Week Date.');
		}
	}
	pushDialog(dialogBody: any) {
		this.ref = this.dialogService.open(CustomDialogComponent, {
			header: 'WPP Constraint Tracker Dashboard',
			footer: '',
			data: dialogBody,
			width: '50%',
			contentStyle: { 'max-height': '1000px',
				overflow: 'auto' },
			baseZIndex: 10000,
		});
	}
}
