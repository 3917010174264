import {Injectable} from '@angular/core';
import {Supplier} from '../model/supplier.model';
import {SurveyService} from './survey.service';
import {SupplierService} from './supplier.service';
import {Observable} from 'rxjs';
import {Survey} from '../model/survey.model';
import {GsdbCodesService} from './gsdb-codes.service';

@Injectable()
export class HomeService {
	/** Creates a new instance of the HomeService
	 *
	 * @param http used for calls to the agent API
	 * @param surveyService used to make REST calls to the survey endpoint
	 * @param supplierService used to make REST calls to the supplier endpoint
	 * @returns a http response
	 */
	constructor(private surveyService: SurveyService, private supplierService: SupplierService, private gsdbService: GsdbCodesService) {
	}

	/** returns a supplier object based on the supplied GSDB code
	 *
	 * @param gsdbCode the  GSDB code to lookup the supplier
	 * @returns an observable of type Supplier
	 */
	getSupplier(gsdbCode: string): Observable<Supplier> {
		return this.supplierService.get(gsdbCode);
	}

	/** Returns the appropriate survey object based on the existence of a supplier.
	 * Creates a supplier if non exists.
	 * Updates existing supplier.
	 *
	 * @param supplier Supplier the supplier to lookup or create
	 * @param shouldUpdate boolean to determine if the supplier should be updated
	 * @returns an observable of type Supplier
	 */
	getSurveyForSupplier(isLaunchSurvey: boolean, supplier: Supplier, shouldUpdate: boolean, copy: boolean, copyFromCommodity: string) {
		//Create/update the supplier and return the appropriate survey for display.
		return this.supplierService.save(isLaunchSurvey, supplier, shouldUpdate).subscribe((supplierResponse) => {
			if (copy) {
				return this.surveyService.getSurveyAnsewr(supplier.supplierGSDB, copyFromCommodity).subscribe(surveyResp => {
					if (surveyResp.surveyAnswer) {
						const surveyResult = JSON.parse(surveyResp.surveyAnswer);
						this.surveyService.showSurvey(supplierResponse.supplierId, surveyResult);
					}
					return surveyResp;
				});
			} else {
				return this.surveyService.get(supplierResponse).subscribe((surveyResponse) => {
					const survey = surveyResponse['result'].surveyAnswerApi as Survey;
					if (survey.surveyAnswer !== null) {
						const surveyResult = JSON.parse(survey.surveyAnswer);
						this.surveyService.showSurvey(supplierResponse.supplierId, surveyResult);
					} else {
						this.surveyService.showSurvey(supplierResponse.supplierId);
					}
					return survey;
				});
			}
		});
	}

	//Launch Survey Methods Starts here
	getCommodityCode(supplierGSDB: string) {
		return this.gsdbService.getLrCommodityCodes(supplierGSDB);
	}

	getLrSupplier(gsdbCode: string, commodityCode: string): Observable<Supplier> {
		return this.supplierService.getLrSupplier(gsdbCode, commodityCode);
	}

	getFilteredGSDB(isLaunchSurvey: boolean, gsdbPattern: string) {
		if (gsdbPattern) {
			return isLaunchSurvey ? this.gsdbService.getLrGSDBCodesByPattern(gsdbPattern) : this.gsdbService.getGSDBCodesByPattern(gsdbPattern);
		}
	}
}
