import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ClearToBuildScheduler} from '../model/clear-to-build-scheduler';

@Injectable({
	providedIn: 'root'
})
export class ClearToBuildSchedulerService {
	private SCHEDULER_ENDPOINT = environment.apiUrl + 'clearToBuildScheduler';

	constructor(private http: HttpClient) {
	}

	createSchedule(scheduler: ClearToBuildScheduler): Observable<ClearToBuildScheduler> {
		console.log('Create Schedule service is called');
		return this.http.post<ClearToBuildScheduler>(this.SCHEDULER_ENDPOINT + '/createSchedule', scheduler);
	}

	updateSchedule(scheduler: ClearToBuildScheduler): Observable<ClearToBuildScheduler> {
		console.log('Update Schedule service is called');
		return this.http.post<ClearToBuildScheduler>(this.SCHEDULER_ENDPOINT + '/updateSchedule', scheduler);
	}

	public getAllSchedules(): Observable<any> {
		return this.http.get(this.SCHEDULER_ENDPOINT + `/findAllSchedules`, {responseType: 'json'});
	}

	public getCtbIds(partType: string): Observable<any> {
		return this.http.get(this.SCHEDULER_ENDPOINT + `/getCtbIds/${partType}`, {responseType: 'json'});
	}

	public checkExistence(scheduler: ClearToBuildScheduler): Observable<boolean> {
		return this.http.post<boolean>(this.SCHEDULER_ENDPOINT + `/checkExistence`, scheduler);
	}

	public deleteSchedule(id: number): Observable<boolean> {
		return this.http.delete<boolean>(this.SCHEDULER_ENDPOINT + `/deleteSchedule/${id}`);
	}
}
