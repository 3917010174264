import {NgModule} from '@angular/core';
import {
	RouterModule,
	Routes
} from '@angular/router';

import {Tier1EComponentShortageComponent} from './tier1-e-component-shortage/tier1-e-component-shortage.component';
import {AuthGuardService} from '../../oauth/auth-guard.service';
import {Tier1EComponentShortageAdminComponent} from './tier1-e-component-shortage-admin/tier1-e-component-shortage-admin.component';
// tslint:disable-next-line:max-line-length
import {Tier1EComponentShortageDashboardComponent} from './tier1-e-component-shortage-dashboard/tier1-e-component-shortage-dashboard.component';
import {NTierMasterAdminComponent} from '../n-tier-master-admin/n-tier-master-admin/n-tier-master-admin.component';
import {
	Tier1EComponenetShortageHelpComponent
} from './tier1-ecomponenet-shortage-help/tier1-ecomponenet-shortage-help.component';
import {DropDownConfigurationComponent} from './drop-down-configuration/drop-down-configuration.component';
import {SemiconductorSupplierSetupComponent} from './semiconductor-supplier-setup/semiconductor-supplier-setup.component';
// tslint:disable-next-line:max-line-length
import {ECompShortageSemiconductorSupplierComponent} from './e-comp-shortage-semiconductor-supplier/e-comp-shortage-semiconductor-supplier.component';

const routes: Routes = [
	{
		path: 'tier1-e-component-shortage',
		component: Tier1EComponentShortageComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'tier1-e-component-shortage-admin',
		component: Tier1EComponentShortageAdminComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'tier1-e-component-shortage-dashboard',
		component: Tier1EComponentShortageDashboardComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'n-tier-master-admin',
		component: NTierMasterAdminComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'tier1-e-component-shortage-help',
		component: Tier1EComponenetShortageHelpComponent,
		canActivate: [AuthGuardService]

	},
	{
		path: 'drop-down-configuration',
		component: DropDownConfigurationComponent,
		canActivate: [AuthGuardService]

	},
	{
		path: 'semiconductor-supplier-setup',
		component: SemiconductorSupplierSetupComponent,
		canActivate: [AuthGuardService]

	},
	{
		path: 'e-comp-semiconductor-supplier',
		component: ECompShortageSemiconductorSupplierComponent,
		canActivate: [AuthGuardService]

	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
	providers: []
})
export class Tier1EComponentShortageRoutingModule {
}
