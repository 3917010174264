import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {Tier1EComponentShortageTracker} from '../model/tier1-e-component-shortage-tracker';
import {Tier1EComponentShortageAdmin} from '../model/tier1-e-component-shortage-admin';

@Injectable({
	providedIn: 'root'
})
export class DashboardDownloadService {

	constructor(private http: HttpClient, private spinnerService: SpinnerService) {
	}

	private DASHBOARD_ENDPOINT = environment.apiUrl + 'tier1EComponentShortageTracker/dashboard';
	private TRACKER_ENDPOINT = environment.apiUrl + 'tier1EComponentShortageTracker/admin';

	downloadSurveyInExcel(tier1EComponentShortageTracker: Tier1EComponentShortageTracker) {

		this.http.post(this.DASHBOARD_ENDPOINT + '/downloadDashboard', tier1EComponentShortageTracker, {responseType: 'blob'})
			.subscribe((response) => {
				this.downLoadFile(response, 'text/csv');
				this.spinnerService.hide();

			});
	}

	downLoadFile(data: any, type: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = 'E-Component_Shortage_Tracker.xlsx';
		anchor.href = url;
		anchor.click();
	}

	upload(fileToUpload: File, tier1Supplier: string, emailAddress: string): Observable<any> {
		const endpoint = this.DASHBOARD_ENDPOINT + '/upload';
		const formData: FormData = new FormData();
		formData.append('File', fileToUpload);
		formData.append('t1SupplierName', tier1Supplier);
		formData.append('emailAddress', emailAddress);
		return this.http.post(endpoint, formData);
	}
	superAdminUpload(fileToUpload: File, emailAddress: string): Observable<any> {
		const endpoint = this.DASHBOARD_ENDPOINT + '/superAdminUpload';
		const formData: FormData = new FormData();
		formData.append('File', fileToUpload);
		formData.append('emailAddress', emailAddress);
		return this.http.post(endpoint, formData);
	}
	// tslint:disable-next-line:indent
     //Admin Data download
	downloadAdminDataReport(tier1EComponentShortageAdmin: Tier1EComponentShortageAdmin) {

		this.http.post(this.TRACKER_ENDPOINT + '/generateEComponentAdminReport', tier1EComponentShortageAdmin, {responseType: 'blob'})
			.subscribe((response) => {
				this.downLoadAdminFile(response, 'text/csv');
				this.spinnerService.hide();

			});
	}
	downLoadAdminFile(data: any, type: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = 'E-Component_Admin_Data.xlsx';
		anchor.href = url;
		anchor.click();
	}
}
