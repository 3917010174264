export class Option {
	name: string;
	code: string;

	constructor(data) {
		Object.assign(this, data);
	}
}

export const reportingMonthOptions: Option[] = [
	{
		name: 'August 2020',
		code: 'August 2020'
	},
	{
		name: 'September 2020',
		code: 'September 2020'
	},
	{
		name: 'October 2020',
		code: 'October 2020'
	},
	{
		name: 'November 2020',
		code: 'November 2020'
	},
	{
		name: 'December 2020',
		code: 'December 2020'
	},
	{
		name: 'January 2021',
		code: 'January 2021'
	},
	{
		name: 'February 2021',
		code: 'February 2021'
	},
	{
		name: 'March 2021',
		code: 'March 2021'
	},
	{
		name: 'April 2021',
		code: 'April 2021'
	},
	{
		name: 'May 2021',
		code: 'May 2021'
	},
	{
		name: 'June 2021',
		code: 'June 2021'
	}
];

export const reportingMonthWeekOptions: Option[] = [
	{
		name: 'Week 1',
		code: 'Week 1'
	},
	{
		name: 'Week 2',
		code: 'Week 2'
	},
	{
		name: 'Week 3',
		code: 'Week 3'
	},
	{
		name: 'Week 4',
		code: 'Week 4'
	},
	{
		name: 'Week 5',
		code: 'Week 5'
	}];

export const specialManufacturingProcessOptions: Option[] = [
	{
		name: 'Welding',
		code: 'Welding'
	}];

export const responseTypeOptions: Option[] = [
	{
		name: 'Forward Model CQI-15 Assessment',
		code: 'Forward Model CQI-15 Assessment'
	},
	{
		name: 'Current Model CQI-15 Annual Compliance',
		code: 'Current Model CQI-15 Annual Compliance'
	}];

/*export const pmtOptions: NtierOption[] = [
	{
		name: 'Axle',
		code: 'Axle'
	},
	{
		name: 'Body Exterior',
		code: 'Body Exterior'
	},
	{
		name: 'Body Interior',
		code: 'Body Interior'
	},
	{
		name: 'Chassis',
		code: 'Chassis'
	},
	{
		name: 'Electrical',
		code: 'Electrical'
	},
	{
		name: 'Engine',
		code: 'Engine'
	},
	{
		name: 'Powertrain',
		code: 'Powertrain'
	},
	{
		name: 'Transmission',
		code: 'Transmission'
	}];*/
