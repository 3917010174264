import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Option} from '../../../home/model/ntier.option';
import {Tier1EComponentShortageTrackerService} from '../service/tier1-e-component-shortage-tracker.service';
import {Tier1EComponentShortageTracker} from '../model/tier1-e-component-shortage-tracker';
import {ForecastSupplyGap} from '../model/forecast-supply-gap';
import {DialogService, DynamicDialogRef, MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {NTierMasterAdminService} from '../../n-tier-master-admin/service/n-tier-master-admin.service';
import {DashboardDownloadService} from '../service/dashboard-download.service';
import {DatePipe} from '@angular/common';
import {ExcelUtility} from '../../tiger-shark-constraint-tracker/model/excel-utility';
import {IgxSpreadsheetComponent} from 'igniteui-angular-spreadsheet';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {Workbook, WorkbookSaveOptions} from 'igniteui-angular-excel';
import {SupplierService} from '../../../home/services/supplier.service';
import {CustomDialogComponent} from '../../../custom-dialog/custom-dialog/custom-dialog.component';

@Component({
	selector: 'e-comp-shortage-semiconductor-supplier',
	templateUrl: './e-comp-shortage-semiconductor-supplier.component.html',
	styleUrls: ['./e-comp-shortage-semiconductor-supplier.component.scss']
})
export class ECompShortageSemiconductorSupplierComponent implements OnInit {
	eComponentShortageForm: FormGroup;
	directSemiconductorBuyObject: Option;
	isDisabled = false;
	showSurvey = false;
	forecastSupplyGapList: ForecastSupplyGap[] = [];
	newForecastSupplyGapList: ForecastSupplyGap[] = [];
	isSubmitVisible = false;
	isFordUser = false;
	tier1EComponentShortageTracker: Tier1EComponentShortageTracker;
	isSupplier = true;
	userType: string;
	fileFromDatabase: any;
	supplierName = '';
	selectedMfgPartNumber = '';
	indexNumber = 0;
	display = false;
	show = true;
	showDropdown = false;
	selectedFiles: any[] = [];
	semiconductorSupplierListFromEmail = [];
	semiconductorSupplierList = [];
	semiconductorSupplierObject: any;
	disableSemiconductorSupplierDropdown: any;
	selectedSemiconductorSupplier: any;
	semiconductorSupplierNameObject: any;
	newForm3AdminData: Tier1EComponentShortageTracker;
	// tslint:disable-next-line:indent
	createUniqueId: any;
	// New Fields
	semiconductorSupplierNames: any[] = [];
	selectedT1ModuleSupplierNames: any[] = [];
	selectedGsdbCodes: any[] = [];
	selectedSemiconductorSupplierNames: any[] = [];
	ref: DynamicDialogRef;
	response: any = {success: false, message: ''};

	// tslint:disable-next-line:max-line-length
	constructor(private supplierService: SupplierService, private fb: FormBuilder, private spinnerService: SpinnerService, private tier1EComponentShortageService: Tier1EComponentShortageTrackerService, private datepipe: DatePipe, private dashboardDownloadService: DashboardDownloadService, private messageService: MessageService, private activatedRoute: ActivatedRoute, private router: Router, private masterAdminService: NTierMasterAdminService, private dialogService: DialogService) {
	}

	@ViewChild('spreadsheet', {static: false, read: IgxSpreadsheetComponent})
	public spreadsheet: IgxSpreadsheetComponent;

	ngOnInit() {
		this.router.navigate(['/']);
		// this.supplierService.pushSelectedSurvey('SemiSupp');
		this.eComponentShortageForm = this.fb.group({
			t1ModuleSupplierNames: [{value: '', disabled: false}],
			gsdbCodes: [{value: '', disabled: false}],
			semiconductorSupplierNames: [{value: '', disabled: false}],
			tier1ModuleSupplierListFromEmail: [''],
			semiconductorSupplierListFromEmail: [''],
			deleteSelectedId: [{value: '', disabled: false, validators: Validators.required}],
		});
		// this.accessSupplierList();
	}

	private accessSupplierList() {
		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
		} else {
			if (sessionStorage.getItem('uid').includes('-')) {
				// tslint:disable-next-line:max-line-length
				this.masterAdminService.isSupplierHasSeparateSemiconductorSuppliersAccess(sessionStorage.getItem('emailAddress')).subscribe((userHasAccess) => {
					if (!userHasAccess) {
						this.router.navigate(['/']);
					} else {
						console.log('It is a Semiconductor Supplier');
						this.userType = 'SemiconductorSupplier';
						this.fetchSemiconductorSupplierFromEmail();
						console.log('User Type : ' + this.userType);
					}
				});
			}
		}
	}

	private fetchSemiconductorSupplierFromEmail() {
		this.tier1EComponentShortageService.getSemiconductorSupplierNameFromEmail(sessionStorage.getItem('emailAddress')).subscribe((data) => {
			this.semiconductorSupplierListFromEmail = data;
		});
	}

	getCurrentWeekNumber(): number {
		const currentDate = new Date();
		const d = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()));
		const dayNum = d.getUTCDay() || 7;
		d.setUTCDate(d.getUTCDate() + 4 - dayNum);
		const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
		// @ts-ignore
		const weekNumber = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
		return weekNumber;
	}

	private getMondayFromCurrentDate(): Date {
		const currentDate = new Date();
		const currentDay = currentDate.getDay();
		const diff = currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1); // adjust when day is sunday
		return new Date(currentDate.setDate(diff));
	}

	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 60000
		});
	}

	loadSupplierTemplate() {
		const tier1EComponentShortageTracker1 = new Tier1EComponentShortageTracker();
		tier1EComponentShortageTracker1.weekNumber = this.getCurrentWeekNumber();
		tier1EComponentShortageTracker1.supplierEmailId = sessionStorage.getItem('emailAddress');
		// @ts-ignore
		tier1EComponentShortageTracker1.weekDate = this.datepipe.transform(this.getMondayFromCurrentDate(), 'yyyy-MM-dd');
		if (this.userType === 'SemiconductorSupplier') {
			tier1EComponentShortageTracker1.tier1ModuleSupplierName = 'All';
			tier1EComponentShortageTracker1.semiConductorSupplierName = this.selectedSemiconductorSupplier;
		}
		if (this.validateFilter(tier1EComponentShortageTracker1)) {
			this.tier1EComponentShortageService.generateTemplateForSupplier(tier1EComponentShortageTracker1).subscribe((data) => {
				this.fileFromDatabase = data;
				this.spinnerService.show();
				ExcelUtility.load(this.fileFromDatabase).then((w) => {
					this.isSubmitVisible = true;
					this.spreadsheet.workbook = w;
					this.spreadsheet.isRenamingWorksheet = false;
					this.spreadsheet.allowAddWorksheet = false;
					this.spreadsheet.allowDeleteWorksheet = false;
					this.spinnerService.hide();
					console.log('Excel Loaded');
				});
			});
		} else {
			if (this.userType === 'SemiconductorSupplier') {
				this.pushMessage('error', 'Error', 'Kindly Select the Semiconductor Supplier Name.');
			}
		}
	}

	private validateFilter(tier1EComponentShortageTracker: Tier1EComponentShortageTracker): boolean {
		let isValidFilter = false;
		if (tier1EComponentShortageTracker.tier1ModuleSupplierName && tier1EComponentShortageTracker.weekDate !== null) {
			isValidFilter = true;
		}
		return isValidFilter;
	}

	downloadSupplierTemplate() {
		const tier1EComponentShortageTracker1 = new Tier1EComponentShortageTracker();
		tier1EComponentShortageTracker1.weekNumber = this.getCurrentWeekNumber();
		tier1EComponentShortageTracker1.supplierEmailId = sessionStorage.getItem('emailAddress');
		// @ts-ignore
		tier1EComponentShortageTracker1.weekDate = this.datepipe.transform(this.getMondayFromCurrentDate(), 'yyyy-MM-dd');
		tier1EComponentShortageTracker1.tier1ModuleSupplierName = 'All';
		tier1EComponentShortageTracker1.semiConductorSupplierName = this.selectedSemiconductorSupplier;
		if (this.validateFilter(tier1EComponentShortageTracker1)) {
			this.tier1EComponentShortageService.generateTemplateForSupplier(tier1EComponentShortageTracker1).subscribe((data) => {
				this.dashboardDownloadService.downLoadFile(data, 'text/csv');
			});
		} else {
			this.pushMessage('error', 'Error', 'Kindly Select the Tier1 Module Supplier Name.');
		}
	}

	generateReport(isOnline: boolean) {
		this.tier1EComponentShortageTracker = new Tier1EComponentShortageTracker();
		this.tier1EComponentShortageTracker.weekNumber = this.getCurrentWeekNumber();
		this.tier1EComponentShortageTracker.supplierEmailId = sessionStorage.getItem('emailAddress');
		// @ts-ignore
		this.tier1EComponentShortageTracker.weekDate = this.datepipe.transform(this.getMondayFromCurrentDate(), 'yyyy-MM-dd');
		if (this.selectedT1ModuleSupplierNames.length > 0) {
			const tier1ModuleSupplierNames = [];
			for (const item of this.selectedT1ModuleSupplierNames) {
				tier1ModuleSupplierNames.push(item.name);
			}
			this.tier1EComponentShortageTracker.tier1ModuleSupplierName = tier1ModuleSupplierNames.join('|');
		}

		if (this.selectedGsdbCodes.length > 0) {
			const gsdbCodes = [];
			for (const item of this.selectedGsdbCodes) {
				gsdbCodes.push(item.name);
			}
			this.tier1EComponentShortageTracker.tier1ModuleSupplierLocation = gsdbCodes.join('|');
		}

		if (this.selectedSemiconductorSupplier.length > 0) {
			const semiconductorSupplierNames = [];
			for (const item of this.selectedSemiconductorSupplier) {
				semiconductorSupplierNames.push(item.name);
			}
			this.tier1EComponentShortageTracker.semiConductorSupplierName = this.selectedSemiconductorSupplier;
		}
		this.tier1EComponentShortageService.generateSemiconductorSupplierReport(this.tier1EComponentShortageTracker).subscribe((data) => {
			if (isOnline) {
				this.loadOnline(data);
			} else {
				this.downloadOffline(data);
			}
		});
	}

	loadOnline(data: any) {
		this.spinnerService.show();
		ExcelUtility.load(data).then((w) => {
			this.spreadsheet.workbook = w;
			this.spinnerService.hide();
			console.log('Excel Loaded');
		});
	}

	downloadOffline(data: any) {
		this.tier1EComponentShortageService.downLoadFile(data, 'text/csv');
	}

	pushDialog(dialogBody: any) {
		this.ref = this.dialogService.open(CustomDialogComponent, {
			header: 'E-Component Shortage Tracker - Response',
			footer: '',
			data: dialogBody,
			width: '50%',
			contentStyle: {
				'max-height': '1000px',
				overflow: 'auto'
			},
			baseZIndex: 10000,
		});
	}
}

// fetchSemiconductorSupplierNames() {
// 	this.semiconductorSupplierList = [];
// 	this.semiconductorSupplierObject = undefined;
// 	if (this.selectedTier1ModuleSupplier === 'All') {
// 		console.log('Selected ALL');
// 		this.semiconductorSupplierObject = {semiconductorSupplier: 'All'};
// 		this.disableSemiconductorSupplierDropdown = true;
// 	} else {
// 		this.disableSemiconductorSupplierDropdown = false;
// 		const tier1EComponentShortageTracker = new Tier1EComponentShortageTracker();
// 		tier1EComponentShortageTracker.tier1ModuleSupplierName = this.selectedTier1ModuleSupplier;
// 		console.log('selected tier 1 :', this.selectedTier1ModuleSupplier);
// 		// tslint:disable-next-line:max-line-length
// 		this.tier1EComponentShortageService.getSemiconductorSupplierFromTier1Supplier(tier1EComponentShortageTracker).subscribe((data) => {
// 			//this.staticDataReset();
// 			if (data.length > 0) {
// 				data.splice(0, 0, {semiconductorSupplier: 'All'});
// 			}
// 			this.semiconductorSupplierList = data;
// 		});
// 	}
// }

// onSemiconductorSupplierNameChange($event: any) {
// 	if (this.selectedSemiconductorSupplierNames.length > 0) {
// 		const semiconductorSupplierNames = [];
// 		for (const item of this.selectedSemiconductorSupplierNames) {
// 			semiconductorSupplierNames.push(item.name);
// 		}
// 		this.tier1EComponentShortageTracker.semiConductorSupplierName = semiconductorSupplierNames.join(';!');
// 	}
// }
