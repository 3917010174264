import {
	Component,
	OnInit
} from '@angular/core';
import {
	FormArray,
	FormBuilder,
	FormGroup,
	Validators
} from '@angular/forms';
import {MessageService, DialogService, DynamicDialogRef} from 'primeng/api';
import {
	emptyScheduler,
	Scheduler
} from '../model/scheduler';
import {SchedulerService} from '../service/scheduler.service';
import {Router} from '@angular/router';
import {SupplierService} from '../../../home/services/supplier.service';
import {CustomDialogComponent} from '../../../custom-dialog/custom-dialog/custom-dialog.component';

@Component({
	selector: 'schedular',
	templateUrl: './schedular.component.html',
	styleUrls: ['./schedular.component.scss']
})
export class SchedularComponent implements OnInit {

	scheduler: FormGroup;
	adhocConstraintForm: FormGroup;
	updateForm: FormGroup;
	// isDisabled = false;
	isReadOnly = false;
	weekDays = [{weekDay: 'MON'}, {weekDay: 'TUE'}, {weekDay: 'WED'}, {weekDay: 'THU'}, {weekDay: 'FRI'}];
	hours = [];
	minutes = [];
	statusOption = [{status: 'Active'}, {status: 'Inactive'}];
	partOption = [{part: 'GlobalReference_VOParts'}, {part: 'GlobalReference_MFGParts'}, {part: 'VO_AND_MFG_Parts'}];
	weekDayObject: any;
	hourObject: any;
	minuteObject: any;
	statusObject: any;
	partObject: any;
	selectedWeekDay: any;
	selectedHour: any;
	selectedMinute: any;
	selectedStatus: any;
	selectedPart: any;
	adhocPartObject: any;
	selectedAdhocPart: any;
	newSchedule: Scheduler;
	updateScheduleObject: Scheduler;
	readOnly = false;
	display = false;
	updateDisplay = false;
	form: FormGroup;
	cols: any[];
	schedulesTemp: Scheduler[];
	schedules: Scheduler[];
	constraint: any;
	dropdownList = [];
	dropdownListAdhoc = [];
	selectedItems = [];
	selectedItemsAdhoc = [];
	dropdownSettings = {};
	isCreateButtonVisible = false;
	isUpdateButtonVisible = false;
	ref: DynamicDialogRef;
	// tslint:disable-next-line:max-line-length
	constructor(private supplierService: SupplierService, private router: Router, private fb: FormBuilder, private messageService: MessageService, private schedulerService: SchedulerService, private dialogService: DialogService) {
	}

	ngOnInit() {
		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
			return;
			// } else {
			// 	// tslint:disable-next-line:max-line-length
			// 	this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'EDPP  Screen').subscribe(userHasAdminAccess => {
			// 		if (!userHasAdminAccess) {
			// 			this.router.navigate(['/']);
			// 		}
			// 	});
		} else if (sessionStorage.getItem('uid').includes('-')) {
			this.router.navigate(['/']);
			return;
		}
		this.supplierService.pushSelectedSurvey('WPP');
		this.cols = [
			{field: 'weekDay', header: 'Week Day'},
			{field: 'hours', header: 'Hours (24 hours Format - UTC)'},
			{field: 'minutes', header: 'Minutes'},
			{field: 'part', header: 'Part Type'},
			{field: 'constraints', header: 'Constraints'},
			// {field: 'status', header: 'Status'}
			{field: 'statusText', header: 'Status'}
		];
		this.dropdownSettings = {
			singleSelection: false,
			idField: 'item_id',
			textField: 'item_text',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true,
			selectAll: false
		};
		this.findAllSchedules();
		this.adhocConstraintForm = this.fb.group({
			constraintIds: [{value: '', disabled: false}],
			adhocPart: [{value: '', disabled: false, validators: Validators.required}],
			adhocConstraints: [{value: '', disabled: false, validators: Validators.required}]
		});
		this.updateForm = this.fb.group({
			weekDays: [{value: '', disabled: false, validators: Validators.required}],
			hours: [{value: '', disabled: false, validators: Validators.required}],
			minutes: [{value: '', disabled: false, validators: Validators.required}],
			part: [{value: '', disabled: false, validators: Validators.required}],
			constraints: [{value: '', disabled: false, validators: Validators.required}],
			status: [{value: '', disabled: false, validators: Validators.required}]
		});
	}

	pushDialog(dialogBody: any) {
		this.ref = this.dialogService.open(CustomDialogComponent, {
			header: 'WPP Scheduler',
			footer: '',
			data: dialogBody,
			width: '50%',
			contentStyle: { 'max-height': '1000px',
				overflow: 'auto' },
			baseZIndex: 10000,
		});
	}
	onItemSelect($event) {
		// console.log('Selected Constraints : ' + this.selectedItems.length);
		// const data = this.getData();
		// const selectedItem = data.filter(item => item.item_id === $event.item_id);
		// const selectedItemGroup = selectedItem[0]['item_id'];
		// this.dropdownList = data.map(item => {
		// 	if (item.item_id === selectedItemGroup) {
		// 		item.isDisabled = false;
		// 	} else {
		// 		item.isDisabled = true;
		// 	}
		// 	return item;
		// });
	}

	onSelectAll(items: any) {
		// console.log(items);
	}

	onItemDeSelect($event) {
		// console.log('Selected Constraints : ' + this.selectedItems.length);
		// if (this.selectedItems && this.selectedItems.length === 0) {
		// 	this.dropdownList = this.dropdownList.map(item => {
		// 		item.isDisabled = false;
		// 		return item;
		// 	});
		// }
	}

	findConstraintIds() {
		console.log('Selected Part Type : ' + this.selectedPart);
		this.schedulerService.getConstraintIds(this.selectedPart).subscribe((data) => {
			if (data.length > 0) {
				this.dropdownList = data;
				this.selectedItems = [];
			}
		});
	}

	findConstraintIdsForAdhocPull() {
		console.log('Selected Part Type : ' + this.selectedAdhocPart);
		this.schedulerService.getConstraintIds(this.selectedAdhocPart).subscribe((data) => {
			if (data.length > 0) {
				this.dropdownListAdhoc = data;
				this.selectedItemsAdhoc = [];
			}
		});
	}
	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 60000
		});
	}

	isNotDigit(value: number): boolean {
		return isNaN(value);
	}

	isHoursNotValid(value: number): boolean {
		if (value > 0 && value < 25) {
			return false;
		} else {
			return true;
		}
	}

	isConstraintValid(value: string): boolean {
		if (value.length > 0) {
			return false;
		} else {
			return true;
		}
	}

	isMinutesNotValid(value: number): boolean {
		if ((value > 0 || value === 0) && value < 60) {
			return false;
		} else {
			return true;
		}
	}

	isDropDownNotValid(value: string) {
		if (value !== undefined) {
			return false;
		} else {
			return true;
		}
	}

	private findAllSchedules() {
		this.schedulesTemp = [];
		this.schedules = [];
		this.schedulerService.getAllSchedules().subscribe((data) => {
			// this.schedules = data;
			this.schedulesTemp = data;
			this.schedulesTemp.forEach(schedule => {
				if (schedule.status) {
					schedule.statusText = 'Active';
				} else {
					schedule.statusText = 'Inactive';
				}
				this.schedules.push(schedule);
			});
		});
	}

	createSchedule() {
		if (this.updateForm.get('weekDays').value === null || this.updateForm.get('status').value === null) {
			this.pushMessage('error', 'Error', 'Please fill all mandatory fields in Scheduler form.');
			// tslint:disable-next-line:max-line-length
		} else if (this.isDropDownNotValid(this.updateForm.get('weekDays').value.weekDay) || this.isDropDownNotValid(this.updateForm.get('status').value.status) || this.isHoursNotValid(this.updateForm.get('hours').value) || this.isMinutesNotValid(this.updateForm.get('minutes').value)) {
			this.pushMessage('error', 'Error', 'Please fill all mandatory fields in Scheduler form.');
		} else if (this.selectedItems.length === 0) {
			this.pushMessage('error', 'Error', 'Please Select at least One Constraint in Scheduler form.');
		} else {
			// this.newSchedule = this.updateForm.getRawValue() as Scheduler;
			this.newSchedule = new Scheduler(emptyScheduler);
			this.newSchedule.weekDay = this.updateForm.get('weekDays').value.weekDay;
			this.newSchedule.hours = this.updateForm.get('hours').value;
			this.newSchedule.minutes = this.updateForm.get('minutes').value;
			this.newSchedule.part = this.updateForm.get('part').value.part;
			this.newSchedule.status = (this.updateForm.get('status').value.status
				&& this.updateForm.get('status').value.status === 'Active') ? true : false;
			let constraintIds = '';
			let index = 1;
			this.selectedItems.forEach(value => {
				if (index === 1) {
					constraintIds = value.item_text;
					index++;
				} else {
					constraintIds = constraintIds + ',' + value.item_text;
				}
			});
			this.newSchedule.constraints = constraintIds;
			this.schedulerService.createSchedule(this.newSchedule).subscribe((data) => {
				this.findAllSchedules();
				if (!(data === null)) {
					console.log('Success: Schedule Id #' + data.id + ' has been Created successfully');
					this.pushMessage('success', 'Success', 'Success: Schedule Id #' + data.id + ' has been Created successfully');
				} else {
					this.pushDialog('Please verify if Schedule already exists for the specific time');
					// this.pushMessage('error', 'Error', 'Please verify if Schedule already exists for the specific time');
				}
				this.updateDisplay = false;
				this.updateForm.reset();
				this.selectedItems = [];
				this.dropdownList = [];
			});
		}
	}

	updateSchedule() {
		// tslint:disable-next-line:max-line-length
		if (this.isDropDownNotValid(this.updateForm.get('weekDays').value.weekDay) || this.isDropDownNotValid(this.updateForm.get('status').value.status) || this.isHoursNotValid(this.updateForm.get('hours').value) || this.isMinutesNotValid(this.updateForm.get('minutes').value)) {
			this.pushMessage('error', 'Error', 'Please fill all mandatory fields in Scheduler form.');
		} else if (!(this.selectedItems.length > 0)) {
			this.pushMessage('error', 'Error', 'Please Select at least One Constraint in Scheduler form.');
		} else {
			this.updateScheduleObject.weekDay = this.updateForm.get('weekDays').value.weekDay;
			this.updateScheduleObject.hours = this.updateForm.get('hours').value;
			this.updateScheduleObject.minutes = this.updateForm.get('minutes').value;
			this.updateScheduleObject.part = this.updateForm.get('part').value.part;
			this.updateScheduleObject.status = (this.updateForm.get('status').value.status
				&& this.updateForm.get('status').value.status === 'Active') ? true : false;
			let constraintIds = '';
			let index = 1;
			this.selectedItems.forEach(value => {
				if (index === 1) {
					constraintIds = value.item_text;
					index++;
				} else {
					constraintIds = constraintIds + ',' + value.item_text;
				}
			});
			this.updateScheduleObject.constraints = constraintIds;
			this.schedulerService.updateSchedule(this.updateScheduleObject).subscribe((data) => {
				this.findAllSchedules();
				console.log('Success: Schedule Id #' + data.id + ' has been Updated successfully');
				this.pushMessage('success', 'Success', 'Success: Schedule Id #' + data.id + ' has been Updated successfully');
				this.updateDisplay = false;
				this.updateForm.reset();
				this.selectedItems = [];
				this.dropdownList = [];
			}, error => {
				this.findAllSchedules();
				this.pushDialog('Please verify if Schedule already exists for the specific time');
				// this.pushMessage('error', 'Error', 'Please verify if Schedule already exists for the specific time');
				this.updateDisplay = false;
				this.updateForm.reset();
				this.selectedItems = [];
				this.dropdownList = [];
			});
		}
	}

	contactInfo() {
		this.router.navigate(['/tiger-shark-constraint-tracker-admin']);
	}

	onSubmit() {
		this.display = false;
		const scheduleObj = new Scheduler(emptyScheduler);
		let constraintIds = '';
		let index = 1;
		this.selectedItemsAdhoc.forEach(value => {
			if (index === 1) {
				constraintIds = value.item_text;
				index++;
			} else {
				constraintIds = constraintIds + ',' + value.item_text;
			}
		});
		scheduleObj.constraints = constraintIds;
		scheduleObj.part = this.selectedAdhocPart;
		console.log('Entered Constraint Ids : ' + scheduleObj.constraints);
		this.schedulerService.adhocPull(scheduleObj).subscribe((data) => {
			if (data) {
				this.pushMessage('success', 'Success', 'ADHOC Pull successful');
			} else {
				this.pushMessage('error', 'Error', 'ADHOC Pull cancelled, please try again later.');
			}
		});
	}

	enableDialog() {
		this.adhocPartObject = undefined;
		this.adhocConstraintForm.reset();
		this.dropdownListAdhoc = [];
		this.selectedItemsAdhoc = [];
		this.display = true;
	}

	disableDialog() {
		this.display = false;
	}

	deleteSchedule(id: any) {
		this.schedulerService.deleteSchedule(id).subscribe((data) => {
			if (data) {
				console.log('Success: Schedule has been Deleted successfully');
				this.pushMessage('success', 'Success', 'Success: Schedule has been Deleted successfully');
				this.findAllSchedules();
			} else {
				this.pushMessage('error', 'Error', 'Unable to Delete Schedule, Please try again');
			}
		});
	}

	enableUpdateScheduleDialog(data: any) {
		this.dropdownList = [];
		this.selectedItems = [];
		this.updateDisplay = true;
		this.isCreateButtonVisible = false;
		this.isUpdateButtonVisible = true;
		this.updateScheduleObject = new Scheduler(emptyScheduler);
		this.updateScheduleObject.id = data['id'];
		this.updateScheduleObject.createdBy = data['createdBy'];
		this.updateScheduleObject.createdDate = data['createdDate'];

		this.updateScheduleObject.weekDay = data['weekDay'];
		this.updateScheduleObject.hours = data['hours'];
		this.updateScheduleObject.minutes = data['minutes'];
		this.updateScheduleObject.part = data['part'];
		this.updateScheduleObject.constraints = data['constraints'];
		this.updateScheduleObject.status = data['status'];

		this.updateForm.patchValue({weekDays: {weekDay: data['weekDay']}});
		this.updateForm.patchValue({hours: data['hours']});
		this.updateForm.patchValue({minutes: data['minutes']});
		this.updateForm.patchValue({part: {part: data['part']}});
		if (data['status']) {
			this.updateForm.patchValue({status: this.statusOption[0]});
		} else {
			this.updateForm.patchValue({status: this.statusOption[1]});
		}
		const constraints = data['constraints'];
		let constraintList = [];
		constraintList = constraints.split(',');
		console.log(constraintList);

		let optionList = [];
		this.schedulerService.getConstraintIds(data['part']).subscribe((res) => {
			if (res.length > 0) {
				optionList = res;
			}
			const finalList = [];
			optionList.forEach(item => {
				this.constraint = item.item_text;
				if (constraintList.includes(this.constraint.toString())) {
					finalList.push(item);
					this.selectedItems = finalList;
				}
			});
		});
	}

	enableCreateScheduleDialog() {
		this.updateDisplay = true;
		this.isCreateButtonVisible = true;
		this.isUpdateButtonVisible = false;
		// this.updateForm.reset();
		this.selectedItems = [];
		this.dropdownList = [];
	}
}
