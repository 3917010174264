import {
	Component,
	OnInit
} from '@angular/core';
import {SupplierService} from '../home/services/supplier.service';
import {Router} from '@angular/router';

@Component({
	selector: 'qlik-sense-dashboard',
	templateUrl: './qlik-sense-dashboard.component.html',
	styleUrls: ['./qlik-sense-dashboard.component.scss']
})
export class QlikSenseDashboardComponent implements OnInit {

	admin: string;
	activeQlikSenseDashboardUrl: string;
	header: string;
	activeWelcomeMessage: string;
	activeEmailAddress: string;
	defaultHeader = 'Qlik Sense Progress Dashboard';
	smashedDataHeader = 'N-Tier Location Dashboard';
	defaultEmailAddress = 'bsrini10@ford.com';
	t2VisibilityEmailAddress = 'vfordsup@ford.com';
	// tslint:disable-next-line:max-line-length
	wppQlikSenseDashboardUrl = 'https://www.sharedanalytics.ford.com/sense/app/acbbbb29-1672-4b03-af22-4bf0359850ab/sheet/15d2ebf5-f3d2-45a7-a86d-18c0c67a20c8/state/analysis';
	// tslint:disable-next-line:max-line-length
	t2QlikSenseDashboardUrl = 'https://www.sharedanalytics.ford.com/sense/app/e43a6077-d535-482c-b5ea-3271a11a2d95/sheet/32d23967-c590-4f63-8c52-8cc796bb0140/state/analysis';
	// tslint:disable-next-line:max-line-length
	t2VisibilityComplianceQlikSenseUrl = 'https://www.sharedanalytics.ford.com/sense/app/ab44e5bc-1b5d-4789-872b-443a344713e7/sheet/55dd9f1c-fffa-4783-9bea-f0016eb79f4f/state/analysis';
	// tslint:disable-next-line:max-line-length
	wppWelcomeMessage = 'You may also view the Constraint Status and the Progress on the Qlik Sense Dashboard. Click on the button below to view the dashboard.';
	t2WelcomeMessage = 'You may also view the Progress on the Qlik Sense Dashboard. Click on the button below to view the dashboard.';
	// tslint:disable-next-line:max-line-length
	smashedDatasetQlikSenseUrl = 'https://www.sharedanalytics.ford.com/sense/app/ee8f5bb3-0037-4cd7-b272-b821e3ae70ac/sheet/bcabefd1-f075-41b1-ac82-ad27da8c2f93/state/analysis';
	// tslint:disable-next-line:max-line-length
	smashedDatasetWelcomeMessage = 'Click on the button below to view the Smashed Dataset on the Qlik Sense Dashboard.';

	constructor(private supplierService: SupplierService, private router: Router) {
	}

	ngOnInit() {

		if(sessionStorage.getItem('Admin') === 'T2Visibility'){
			this.router.navigate(['/']);
		}

		if (sessionStorage.getItem('Admin') == null) {
			this.router.navigate(['/']);
		}else if (sessionStorage.getItem('uid').includes('-')) {
			this.router.navigate(['/']);
		} else {
			this.admin = sessionStorage.getItem('Admin');
			if (this.admin.length === 0) {
				this.router.navigate(['/']);
			}
		}
		this.supplierService.pushSelectedSurvey(this.admin);
		if (this.admin === 'WPP') {
			this.header = this.defaultHeader;
			this.activeQlikSenseDashboardUrl = this.wppQlikSenseDashboardUrl;
			this.activeWelcomeMessage = this.wppWelcomeMessage;
			this.activeEmailAddress = this.defaultEmailAddress;
		} else if (this.admin === 'T2Visibility') {
			this.header = this.defaultHeader;
			this.activeQlikSenseDashboardUrl = this.t2QlikSenseDashboardUrl;
			this.activeWelcomeMessage = this.t2WelcomeMessage;
			this.activeEmailAddress = this.t2VisibilityEmailAddress;
		} else if (this.admin === 'T2VisibilityCompliance') {
			this.header = this.defaultHeader;
			this.activeQlikSenseDashboardUrl = this.t2VisibilityComplianceQlikSenseUrl;
			this.activeWelcomeMessage = this.t2WelcomeMessage;
			this.activeEmailAddress = this.t2VisibilityEmailAddress;
		} else if (this.admin === 'ConsolidatedDashboard') {
			this.header = this.smashedDataHeader;
			this.activeQlikSenseDashboardUrl = this.smashedDatasetQlikSenseUrl;
			this.activeWelcomeMessage = this.smashedDatasetWelcomeMessage;
			this.activeEmailAddress = this.defaultEmailAddress;
		}
	}
	goToDashboard() {
		window.open(this.activeQlikSenseDashboardUrl, '_blank');
	}
}
