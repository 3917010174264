import {
	Component,
	OnInit
} from '@angular/core';
import {DashboardService} from './services/dashboard.service';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Component({
	selector: 'dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

	dashboardData: Observable<object[]>;
	cols: any[];
	rowsPerPage: number;
	rowsPerPageOptions: any[];
	private mode: string;
	isLaunchSurveyDashboard = false;

	//For Chart
	displayCountData: any;
	private displayResponses: any;
	private totalCount: number;
	private recentUpdates: any;
	private chartLabel: string[];
	dashboardDisplayName: string;

	constructor(private dashboardService: DashboardService, private route: ActivatedRoute) {
		this.cols = [
			{field: 'mfgGSDBCode', header: 'GSDB'},
			{field: 'mfgSiteName', header: 'Name'},
			{field: 'mfgCity', header: 'City'},
			{field: 'mfgCountry', header: 'Country'},
			{field: 'vehiclesSupported', header: 'Vehicles'},
			{field: 'fordPlantsSupported', header: 'Plants'},
			{field: 'plantManagerContactName', header: 'Name'},
			{field: 'plantManagerEmail', header: 'Email ID'},
			{field: 'assessmentPersonName', header: 'Name'},
			{field: 'assessmentPersonEmail', header: 'Email ID'},
			{field: 'assessmentDate', header: 'Date'},
			{field: 'siteOpen', header: 'Open'},
			{field: 'productionStartDate', header: 'ETA Date'},
			{field: 'visitors', header: 'STAs?'},
			{field: 'overallRatingPageSite', header: 'SS'},
			{field: 'management', header: 'P/M'},
			{field: 'timeToResume', header: 'MTR (hrs)'},
			{field: 'people', header: 'P'},
			{field: 'facility', header: 'F'},
			{field: 'management2', header: 'P/M'},
			{field: 'labor', header: 'L/P'},
			{field: 'systems', header: 'S'},
			{field: 'quality', header: 'Q'},
			{field: 'material', header: 'M'},
			{field: 'equipment', header: 'E/T'},
			{field: 'facilities', header: 'F'},
			{field: 'majorChanges', header: 'Major Changes'},
			{field: 'ppeAvailabilityFlag', header: 'PPE'},
			{field: 'overallRatingFinalPage', header: 'Overall Rating'},
			{field: 'dateToGreen', header: 'Date To Green'},
			{field: 'overallCommentsFinalPage', header: 'Overall Comments'}
		];
		this.rowsPerPage = 10;
		this.rowsPerPageOptions = [10, 25, 50, {showAll: 'all'}];
	}

	ngOnInit() {
		console.log('WebDashboard Data');
		this.retrieveRouteParameter();
		this.dashboardData = this.dashboardService.getWebDashboardData(this.isLaunchSurveyDashboard);
		if (this.isLaunchSurveyDashboard) {
			this.dashboardDisplayName = 'New Model Survey Response Chart';
		} else {
			this.dashboardDisplayName = 'COVID Survey Response Chart';
		}
		this.dashboardService.getDisplayCount(this.isLaunchSurveyDashboard).subscribe(value => {
			this.displayResponses = value;
			this.totalCount = this.displayResponses.totalSurveyCount;
			this.recentUpdates = this.displayResponses.surveyCountInLast24Hr;
			this.chartLabel = ['Total Count', 'Last 24hrs Count'];
			this.displayCountData = {
				labels: this.chartLabel,
				datasets: [
					{
						data: [this.totalCount, this.recentUpdates],
						backgroundColor: ['#FFC0CB',
							'#7f00ff'
						]
					}
				]
			};
		});
	}

	downloadSurveyInExcel() {
		this.dashboardService.downloadSurveyInExcel(this.isLaunchSurveyDashboard);
	}

	private retrieveRouteParameter() {
		this.route.paramMap.subscribe(params => {
			this.mode = params.get('mode');
			this.isLaunchSurveyDashboard = this.mode === '' ? false : true;
			console.log('Dashboard Mode captured:' + this.mode);
		});
	}
}
