import {ForecastSupplyGap} from './forecast-supply-gap';

export class Tier1EComponentShortageTracker {
	id: number;
	partBaseNumber: string;
	tier1ModuleComponentDescription: string;
	tier1ModuleSupplierName: string;
	tier1ReferencePartNumber: string;
	semiConductorSupplierName: string;
	semiConductorComponentDescription: string;
	semiConductorManufacturingPartNumber: string;
	maximumICQuantityUsedPerModule: number;
	semiConductorLeadTime: number;
	isFord100PercentShareOfSupply: boolean;
	percentAverageAllocationToFord: number;
	isDirectSemiConductorBuy: string;
	comments: string;
	currentInventoryOnHand: number;
	supplyVsDemand: string;
	totalSupplyGap: number;
	siteDemand: number;
	sitePOETA: number;
	businessTrackingId: string;
	weekNumber: number;
	weekDate: Date;
	forecastSupplyGapList: ForecastSupplyGap[];
	createdBy: string;
	createdDate: Date;
	updatedBy: string;
	updatedDate: Date;
	businessTrackingIdNew: string;
	suppParentName: string;
	suppParentCode: string;
	semiconductorSupplierRaw: string;
	supplierEmailId: string;
	tier1ModuleSupplierLocation: string;

	// constructor(shortageTracker: Tier1EComponentShortageTracker) {
	// 	Object.assign(this, shortageTracker);
	// }
}
