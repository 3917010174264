import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';

/** This services manages retrieving GSDB data for the supplier lookup functionality
 */
@Injectable({
	providedIn: 'root'
})
export class GsdbCodesService {
	/** URL for gsdb API */
	private gsdbcodesUrl = environment.apiUrl + 'gsdbcodes';
	private lrGsdbcodesUrl = environment.apiUrl + 'lrgsdbcodes';

	/** creates a GsdbcodesService instance
	 *
	 * @param http the Angular HttpClient
	 */
	constructor(private http: HttpClient) {
	}

	/** gets all the gsdb codes from the gsdb API
	 *
	 * @returns an Observable list of Gsdb Codes
	 */
	getGsdbCodes() { //Inial Load for Filter
		const uid = sessionStorage.getItem('uid');
		const dashboardRole = sessionStorage.getItem('dashboard-role');
		const url = this.gsdbcodesUrl + '/' + uid + '?' + 'dashboardRole=' + dashboardRole + '&mode=false';
		return this.http.get(url, Object.assign({responseType: 'json'}))
			.pipe(
				map((gsdbCodesResponse) => {
					return gsdbCodesResponse['result'].gsdbCodeApiList;
				})
			);
	}

	//Launch Survey GSDBs
	getLrGsdbCodes(isLaunchSurvey: boolean) { //Inial Load for Filter
		const uid = sessionStorage.getItem('uid');
		const dashboardRole = sessionStorage.getItem('dashboard-role');
		const url = this.lrGsdbcodesUrl + '/' + uid + '?' + 'dashboardRole=' + dashboardRole + '&mode=' + isLaunchSurvey;
		return this.http.get(url, Object.assign({responseType: 'json'}))
			.pipe(
				map((gsdbCodesResponse) => {
					return gsdbCodesResponse['result'].gsdbCodeApiList;
				})
			);
	}

	//Getting Commodity Code
	getLrCommodityCodes(gsdbCode: string) { //Inial Load for Filter
		const dashboardRole = sessionStorage.getItem('dashboard-role');
		const url = this.lrGsdbcodesUrl + '/' + gsdbCode + '/commodities?' + 'dashboardRole=' + dashboardRole;
		return this.http.get(url, Object.assign({responseType: 'json'}))
			.pipe(
				map((commodityCodeResponse) => {
					return commodityCodeResponse['result'].commodityCodeApiList;
				})
			);
	}

	getGSDBCodesByPattern(gsdbPattern: string) {
		const dashboardRole = sessionStorage.getItem('dashboard-role');
		const url = this.gsdbcodesUrl + '/filter?' + 'dashboardRole=' + dashboardRole + '&gsdbPattern=' + gsdbPattern;
		return this.http.get(url, Object.assign({responseType: 'json'})).pipe(
			map((gsdbCodesResponse) => {
				return gsdbCodesResponse['result'].gsdbCodeApiList;
			})
		);
	}
	//For filtering GSDB codes of Launch Survey suppliers
	getLrGSDBCodesByPattern(gsdbPattern: string) {
		const dashboardRole = sessionStorage.getItem('dashboard-role');
		const url = this.lrGsdbcodesUrl + '/filter?' + 'dashboardRole=' + dashboardRole + '&gsdbPattern=' + gsdbPattern;
		return this.http.get(url, Object.assign({responseType: 'json'})).pipe(
			map((gsdbCodesResponse) => {
				return gsdbCodesResponse['result'].gsdbCodeApiList;
			})
		);
	}
}
