import {Component, OnInit, ViewChild} from '@angular/core';
import {IgxSpreadsheetComponent} from "igniteui-angular-spreadsheet";
import {SpinnerService} from "../../../services/spinner/spinner.service";
import {NTierMasterAdminService} from "../../n-tier-master-admin/service/n-tier-master-admin.service";
import {SupplierService} from "../../../home/services/supplier.service";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ConfirmationService, DialogService, DynamicDialogRef, MessageService} from "primeng/api";
import {SustainabilitySurveyService} from "../Servies/sustainability-survey.service";
import {Option} from "../../../home/model/ntier.option";
import {ForecastSupplyGap} from "../../tier1-e-component-shortage/model/forecast-supply-gap";
import {
	Tier1EComponentShortageTracker
} from "../../tier1-e-component-shortage/model/tier1-e-component-shortage-tracker";
import {
	Tier1EComponentShortageTrackerService
} from "../../tier1-e-component-shortage/service/tier1-e-component-shortage-tracker.service";
import {DatePipe} from "@angular/common";
import {DashboardDownloadService} from "../../tier1-e-component-shortage/service/dashboard-download.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ExcelUtility} from "../../tiger-shark-constraint-tracker/model/excel-utility";
import {Workbook, WorkbookSaveOptions} from "igniteui-angular-excel";
import {CustomDialogComponent} from "../../../custom-dialog/custom-dialog/custom-dialog.component";
import {sleep} from "igniteui-angular-core";

@Component({
  selector: 'non-microchip-template',
  templateUrl: './non-microchip-template.component.html',
  styleUrls: ['./non-microchip-template.component.scss']
})
export class NonMicrochipTemplateComponent implements OnInit {

	supplierName: any;
	partNumber: any;
	vehicleCode: any;
	partNumbers: any = [];
	supplierNames: any = [];
	vehicleCodes: any = [];
	private getCallJson: FormGroup;
	private pageNo = 1;
	pageRows = 1000;

	isDisabled = false;
	showSurvey = false;
	isSubmitVisible = false;
	isFordUser = false;
	tier1EComponentShortageTracker: Tier1EComponentShortageTracker;
	isSuperAdmin = false;
	fileFromDatabase: any;
	display = false;
	displayConfirmationDialog = false;
	show = true;
	selectedFiles: any[] = [];
	fileName: any;
	emailAddress: any;
	enablePopulatingdata: boolean = false;

	loadMemoryData = false;

	ref: DynamicDialogRef;
	response: any = {success: false, message: ''};

	constructor(private formBuilder: FormBuilder, private supplierService: SupplierService,private confirmationService: ConfirmationService, private fb: FormBuilder, private spinnerService: SpinnerService, private sustainabilitySurveyService: SustainabilitySurveyService, private datepipe: DatePipe, private dashboardDownloadService: DashboardDownloadService, private messageService: MessageService, private activatedRoute: ActivatedRoute, private router: Router, private masterAdminService: NTierMasterAdminService, private dialogService: DialogService) {
	}

	@ViewChild('spreadsheet', {static: false, read: IgxSpreadsheetComponent})
	public spreadsheet: IgxSpreadsheetComponent;

	ngOnInit() {
		// this.loadMemoryData = true;
		this.spinnerService.hide();
		this.spinnerService.show();
		this.supplierService.pushSelectedSurvey('Project R');

		this.emailAddress  = sessionStorage.getItem("emailAddress");
		this.getCallJson = this.formBuilder.group({
			fordPartNumber: new FormArray([]),
			vehicleCode: new FormArray([]),
			supplierName: new FormArray([]),
			pageNo: this.pageNo,
			pageRows: this.pageRows,
			email: sessionStorage.getItem('emailAddress'),
			source: sessionStorage.getItem('Admin'),
			enablePopulatingdata: 'NO'
		});

		if(!sessionStorage.getItem('uid').includes('-')){
			this.isFordUser = true;
		}

		this.getTemplateDropDownValues();
		this.getTemplateData(false);
	}

	getBasedOnSearch(isOnline: boolean,reportDownload: boolean) {

		this.getCallJson.value.fordPartNumber = this.partNumbers;
		this.getCallJson.value.vehicleCode = this.vehicleCodes;
		this.getCallJson.value.supplierName = this.supplierNames;
		this.getCallJson.value.pageNo = 1;
		this.getCallJson.value.pageRows = 1000;
		this.getCallJson.value.source = 'Project R';
		this.getCallJson.value.enablePopulatingdata = isOnline ? 'YES' : 'NO';
		this.getCallJson.value.email = sessionStorage.getItem("emailAddress");
		// this.partNumbers = [];
		// this.vehicleCodes = [];
		// this.supplierNames = [];
		this.getTemplateData(reportDownload);

	}

	resetSearchSelection() {
		// this.showMainContent = false;
		// this.getCallJson.value.fordPartNumber = [];
		// this.getCallJson.value.vehicleCode = [];
		// this.getCallJson.value.supplierName = [];
		// this.getCallJson.value.pageNo = 1;
		// this.getCallJson.value.pageRows = 10;
		// this.getCallJson.value.source = 'Project R';
		// this.getCallJson.value.enablePopulatingdata = 'NO';
		// this.getCallJson.value.email = sessionStorage.getItem("emailAddress");
		this.partNumbers = [];
		this.vehicleCodes = [];
		this.supplierNames = [];
		// this.getTemplateData(false);
	}

	getTemplateDropDownValues(){
		this.sustainabilitySurveyService.getTemplateDropDownValues().subscribe((res:any) => {
			this.partNumber = res.ford_full_part_no;
			this.vehicleCode = res.vehicle_code;
			this.supplierName = res.t1_supplier_name;
		});
		}

	getTemplateData(reportDownload: any){

		this.loadMemoryData = true;
		// let emailAddress = sessionStorage.getItem("emailAddress");
		// this.spinnerService.show();
		this.sustainabilitySurveyService.getTemplateData(this.getCallJson.value).subscribe(data =>{
			// this.sustainabilitySurveyService.getTemplateData(JSON.stringify(payload)).subscribe(data =>{
			this.fileFromDatabase = data;
			this.loadMemoryData = false;

			if(!reportDownload) {
				this.spinnerService.show();
				ExcelUtility.load(this.fileFromDatabase).then((workbook) => {
					// this.loadMemoryData = true;
					this.spreadsheet.workbook = workbook;
					// this.spreadsheet.activeWorksheet.protect();
					this.spinnerService.hide();
					console.log('Excel Loaded');
					// this.loadMemoryData = false;
				})
			}else {
				this.downloadNonMicrochipTemplate()
			}
			// this.loadMemoryData = false;
		})
	}

	homeScreen(){
		this.router.navigate(['/project-r-non-microchip']);
	}

	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 60000
		});
	}

	downloadNonMicrochipTemplate(){
		this.sustainabilitySurveyService.downLoadNonMicrochipExcelFile(this.fileFromDatabase, 'text/csv')
	}

	saveNonMicrochipSpreadsheet(){
		this.displayConfirmationDialog= false;
		this.spinnerService.show();
		const opt = new WorkbookSaveOptions();
		opt.type = 'blob';
		const workbook = this.spreadsheet.workbook;
		// @ts-ignore
		workbook.save(opt, (d)  => {
			this.sustainabilitySurveyService.uploadTemplate(d as File, this.fileName, this.emailAddress).subscribe((data) => {
				this.response = data;
				console.log(this.response);

				if (JSON.parse(this.response.success)) {
					this.pushDialog(this.response.message);
					this.isSubmitVisible = false;
					// this.spreadsheet.workbook = new Workbook();
					this.pushMessage('success', 'success',
						'Data Updated Successfully!');

					// this.router.navigate(['/project-r-non-microchip']);
				} else {
					this.pushDialog(this.response.message);
					this.pushMessage('error', 'error',
						'Unable to Upload Data, Please try again later');
					}
				},error => {
								this.pushMessage('error', 'error',
									'Unable to Upload Data, Please try again later');
							}

			);
		}, ()=>{
			}
		);
	}


	pushDialog(dialogBody: any) {
		this.ref = this.dialogService.open(CustomDialogComponent, {
			header: 'Non-Microchip Survey Upload - Response',
			footer: '',
			data: dialogBody,
			width: '50%',
			contentStyle: { 'max-height': '1000px',
				overflow: 'auto' },
			baseZIndex: 10000,
		});
	}

	superAdminUpload(event: any, form2) {
		// this.loadMemoryData = true;
		this.spinnerService.show();
		this.selectedFiles = [];
		for (const file of event.files) {
			this.selectedFiles.push(file);
		}
		console.log('Uplaoded FileName : '+this.selectedFiles[0].name);
		this.fileName = this.selectedFiles[0].name;
		ExcelUtility.load(this.selectedFiles[0]).then((workbook) => {
			this.spreadsheet.workbook = workbook;
			form2.clear();
			this.spinnerService.hide();
		});
	}
}
