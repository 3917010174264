import {
	Component,
	OnInit
} from '@angular/core';
import {Router} from '@angular/router';

@Component({
	selector: 'logout',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

	constructor(private router: Router) {
	}

	ngOnInit() {
		console.log('Inside Logout Component');
		sessionStorage.clear();
		sessionStorage.setItem('redirectURL', '/landing');
		this.router.navigate(['/login']);
	}
}
