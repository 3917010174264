import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Option} from '../../../home/model/ntier.option';
import {Tier1EComponentShortageTrackerService} from '../service/tier1-e-component-shortage-tracker.service';
import {Tier1EComponentShortageTracker} from '../model/tier1-e-component-shortage-tracker';
import {ForecastSupplyGap} from '../model/forecast-supply-gap';
import {DialogService, DynamicDialogRef, MessageService} from 'primeng/api';

import {ActivatedRoute, Router} from '@angular/router';
import {NTierMasterAdminService} from '../../n-tier-master-admin/service/n-tier-master-admin.service';
import {DashboardDownloadService} from '../service/dashboard-download.service';
import {DatePipe} from '@angular/common';
import {ExcelUtility} from '../../tiger-shark-constraint-tracker/model/excel-utility';
import {IgxSpreadsheetComponent} from 'igniteui-angular-spreadsheet';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {Workbook, WorkbookSaveOptions} from 'igniteui-angular-excel';
import {SupplierService} from '../../../home/services/supplier.service';
import {CustomDialogComponent} from '../../../custom-dialog/custom-dialog/custom-dialog.component';

@Component({
	selector: 'tier1-e-component-shortage',
	templateUrl: './tier1-e-component-shortage.component.html',
	styleUrls: ['./tier1-e-component-shortage.component.scss']
})
export class Tier1EComponentShortageComponent implements OnInit {
	eComponentShortageForm: FormGroup;
	yesNoOptions: Option[] = [];
	directSemiconductorBuyObject: Option;
	isDisabled = false;
	showSurvey = false;
	forecastSupplyGapList: ForecastSupplyGap[] = [];
	newForecastSupplyGapList: ForecastSupplyGap[] = [];
	isSubmitVisible = false;
	isSuperAdminSubmitVisible = false;
	isFordUser = false;
	tier1EComponentShortageTracker: Tier1EComponentShortageTracker;
	isSuperAdmin = false;
	userType: string;
	fileFromDatabase: any;
	supplierName = '';
	selectedMfgPartNumber = '';
	indexNumber = 0;
	display = false;
	displayRemoveDataDialog = false;
	displayDeleteConfirmationDialog = false;
	displayDuplicateDeleteConfirmationDialog = false;
	show = true;
	showDropdown = false;
	selectedFiles: any[] = [];
	tier1ModuleSupplierList = [];
	tier1ModuleSupplierListFromEmail = [];
	semiconductorSupplierListFromEmail = [];
	semiconductorSupplierList = [];
	selectedTier1ModuleSupplier: string;
	tier1ModuleSupplierObject: any;
	semiconductorSupplierObject: any;
	disableSemiconductorSupplierDropdown: any;
	selectedSemiconductorSupplier: any;
	semiconductorSupplierNameObject: any;
	selectedBusinessTrackingId = '';
	newForm3AdminData: Tier1EComponentShortageTracker;
	// tslint:disable-next-line:indent
	createUniqueId: any;
	// New Fields
	t1ModuleSupplierNames: any[] = [];
	gsdbCodes: any[] = [];
	semiconductorSupplierNames: any[] = [];
	deleteSelectedId = [];
	selectedT1ModuleSupplierNames: any[] = [];
	selectedGsdbCodes: any[] = [];
	selectedIdForDeletion: any[] = [];
	selectedSemiconductorSupplierNames: any[] = [];
	ref: DynamicDialogRef;
	response: any = {success: false, message: ''};
	removeDataTier1EComp: Tier1EComponentShortageTracker;
	displayDuplicateDataTier1EComp: Tier1EComponentShortageTracker;
	// tslint:disable-next-line:ban-types
	duplicateEComp: Object = [];

	// tslint:disable-next-line:max-line-length
	constructor(private supplierService: SupplierService, private fb: FormBuilder, private spinnerService: SpinnerService, private tier1EComponentShortageService: Tier1EComponentShortageTrackerService, private datepipe: DatePipe, private dashboardDownloadService: DashboardDownloadService, private messageService: MessageService, private activatedRoute: ActivatedRoute, private router: Router, private masterAdminService: NTierMasterAdminService, private dialogService: DialogService) {
	}

	@ViewChild('spreadsheet', {static: false, read: IgxSpreadsheetComponent})
	public spreadsheet: IgxSpreadsheetComponent;

	ngOnInit() {
		this.router.navigate(['/']);
		// this.supplierService.pushSelectedSurvey('SemiSupp');
		this.yesNoOptions = [
			{name: 'Yes', code: 'Yes'},
			{name: 'No', code: 'No'}];
		// this.eComponentShortageForm = this.fb.group({
		// 	supplierId: [{value: 0, disabled: this.isDisabled}, null],
		// 	supplierLoginId: [{
		// 		value: sessionStorage.getItem('userId'),
		// 		disabled: this.isDisabled
		// 	}, Validators.required],
		// 	supplierName: [{value: '', disabled: true}, Validators.required],
		// 	supplierGSDB: ['', [
		// 		Validators.required,
		// 		Validators.minLength(5)
		// 	]],
		// 	tier1ModuleSupplierList: [''],
		// 	tier1ModuleSupplierListFromEmail: [''],
		// 	semiconductorSupplierList: [{value: '', disabled: this.isDisabled}],
		// 	partBaseNumber: [{value: '', disabled: this.isDisabled}],
		// 	tier1ModuleComponentDescription: [{value: '', disabled: this.isDisabled}, Validators.required],
		// 	tier1ModuleSupplierName: [{value: '', disabled: this.isDisabled}],
		// 	tier1ReferencePartNumber: [{value: '', disabled: this.isDisabled}],
		// 	semiConductorSupplierName: [{value: '', disabled: this.isDisabled}],
		// 	semiConductorComponentDescription: [{value: '', disabled: this.isDisabled}, Validators.required],
		// 	semiConductorManufacturingPartNumber: [{value: '', disabled: this.isDisabled}, Validators.required],
		// 	semiConductorManufacturingPartNumberList: [''],
		// 	maximumICQuantityUsedPerModule: [{value: '', disabled: this.isDisabled}, Validators.required],
		// 	semiConductorLeadTime: [{value: '', disabled: this.isDisabled}],
		// 	isFord100PercentShareOfSupply: [''],
		// 	percentAverageAllocationToFord: [{value: '', disabled: this.isDisabled}],
		// 	isDirectSemiConductorBuy: [''],
		// 	comments: [{value: '', disabled: this.isDisabled}]
		// });
		this.eComponentShortageForm = this.fb.group({
			t1ModuleSupplierNames: [{value: '', disabled: false}],
			gsdbCodes: [{value: '', disabled: false}],
			semiconductorSupplierNames: [{value: '', disabled: false}],
			tier1ModuleSupplierListFromEmail: [''],
			semiconductorSupplierListFromEmail: [''],
			deleteSelectedId: [{value: '', disabled: false, validators: Validators.required} ],

		});
		// this.accessSupplierList();
		// this.retrieveRouteParameter();
	}

	private accessSupplierList() {
		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
		} else {
			if (sessionStorage.getItem('uid').includes('-')) {
				this.masterAdminService.isSupplierHasSemiSuppliersAccess(sessionStorage.getItem('emailAddress')).subscribe((userHasAccess) => {
					if (userHasAccess) {
						console.log('It is a Supplier');
						this.userType = 'Supplier';
						this.fetchTier1SupplierNameFromEmail();
						console.log('User Type : ' + this.userType);
					} else {
						// tslint:disable-next-line:max-line-length
						this.masterAdminService.isSupplierHasSeparateSemiconductorSuppliersAccess(sessionStorage.getItem('emailAddress')).subscribe((userHasSemiconductorAccess) => {
							if (userHasSemiconductorAccess) {
								this.router.navigate(['/e-comp-semiconductor-supplier']);
								console.log('It is a Semiconductor Supplier');
								this.userType = 'SemiconductorSupplier';
								this.fetchSemiconductorSupplierFromEmail();
								console.log('User Type : ' + this.userType);
							} else {
								this.router.navigate(['/']);
							}
						});
					}
				});
			} else {
				this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'),
					'E-Comp Shortage Super Admin').subscribe(userHasECompSuperAdminAccess => {
					this.isSuperAdmin = userHasECompSuperAdminAccess;
					});
				console.log('It is a Ford User');
				this.userType = 'FordUserWithAdmin';
				// this.getTier1ModuleSupplierList();
				this.loadT1ModuleSupplierNames();
				this.loadGsdbCodes();
				this.loadSemiconductorSupplierNames();
				this.loadAllIdFromDb();
			}
		}
	}
	getCurrentWeekNumber(): number {
		const currentDate = new Date();
		const d = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()));
		const dayNum = d.getUTCDay() || 7;
		d.setUTCDate(d.getUTCDate() + 4 - dayNum);
		const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
		// @ts-ignore
		const weekNumber = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
		return weekNumber;
	}

	private getMondayFromCurrentDate(): Date {
		const currentDate = new Date();
		const currentDay = currentDate.getDay();
		const diff = currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1); // adjust when day is sunday
		return new Date(currentDate.setDate(diff));
	}
	// getTier1ModuleSupplierList() {
	// 	this.eComponentShortageForm.reset();
	// 	this.tier1EComponentShortageService.getAllTier1ModuleSupplierForForduser().subscribe((data) => {
	// 		if (data.length > 0) {
	// 			this.tier1ModuleSupplierObject = {tier1Supplier: 'All'};
	// 			data.splice(0, 0, {tier1Supplier: 'All'});
	// 		}
	// 		this.tier1ModuleSupplierList = data;
	// 	});
	// }

	private fetchTier1SupplierNameFromEmail() {
		this.tier1EComponentShortageService.getTier1SupplierNameFromEmail(sessionStorage.getItem('emailAddress')).subscribe((data) => {
			this.tier1ModuleSupplierListFromEmail = data;
		});
	}

	private fetchSemiconductorSupplierFromEmail() {
		this.tier1EComponentShortageService.getSemiconductorSupplierNameFromEmail(sessionStorage.getItem('emailAddress')).subscribe((data) => {
			this.semiconductorSupplierListFromEmail = data;
		});
	}

	fetchSemiconductorSupplierNames() {
		this.semiconductorSupplierList = [];
		this.semiconductorSupplierObject = undefined;
		if (this.selectedTier1ModuleSupplier === 'All') {
			console.log('Selected ALL');
			this.semiconductorSupplierObject = {semiconductorSupplier: 'All'};
			this.disableSemiconductorSupplierDropdown = true;
		} else {
			this.disableSemiconductorSupplierDropdown = false;
			const tier1EComponentShortageTracker = new Tier1EComponentShortageTracker();
			tier1EComponentShortageTracker.tier1ModuleSupplierName = this.selectedTier1ModuleSupplier;
			console.log('selected tier 1 :', this.selectedTier1ModuleSupplier);
			// tslint:disable-next-line:max-line-length
			this.tier1EComponentShortageService.getSemiconductorSupplierFromTier1Supplier(tier1EComponentShortageTracker).subscribe((data) => {
				//this.staticDataReset();
				if (data.length > 0) {
					data.splice(0, 0, {semiconductorSupplier: 'All'});
				}
				this.semiconductorSupplierList = data;
			});
		}
	}
	// fetchSemiconductorSupplierNames() {
	// 	this.semiconductorSupplierList = [];
	// 	this.semiconductorSupplierObject = undefined;
	// 	if (this.selectedTier1ModuleSupplier === 'All') {
	// 		console.log('Selected ALL');
	// 		this.semiconductorSupplierObject = {semiconductorSupplier: 'All'};
	// 		this.disableSemiconductorSupplierDropdown = true;
	// 	} else {
	// 		this.disableSemiconductorSupplierDropdown = false;
	// 		const tier1EComponentShortageTracker = new Tier1EComponentShortageTracker();
	// 		tier1EComponentShortageTracker.tier1ModuleSupplierName = this.selectedTier1ModuleSupplier;
	// 		console.log('selected tier 1 :', this.selectedTier1ModuleSupplier);
	// 		// tslint:disable-next-line:max-line-length
	// 		this.tier1EComponentShortageService.getSemiconductorSupplierFromTier1Supplier(tier1EComponentShortageTracker).subscribe((data) => {
	// 			//this.staticDataReset();
	// 			if (data.length > 0) {
	// 				data.splice(0, 0, {semiconductorSupplier: 'All'});
	// 			}
	// 			this.semiconductorSupplierList = data;
	// 		});
	// 	}
	// }
	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 60000
		});
	}
	// private retrieveRouteParameter() {
	// 	console.log('Inside retrieveRouteParameter');
	// 	const uid = sessionStorage.getItem('uid');
	// 	if (!uid.includes('-')) {
	// 		console.log('retrieveRouteParameter -> It is a Ford User');
	// 		this.userType = 'FordUserWithAdmin';
	// 		} else {
	// 			this.userType = uid.includes('-') ? 'Supplier' : 'FordUser';
	// 		}
	// 	this.activatedRoute.paramMap.subscribe(params => {
	// 			const isSupplier = params.get('isSupplier');
	// 			if (this.userType === 'Supplier' && isSupplier === 'true') {
	// 				this.isSupplier = true;
	// 			} else {
	// 				this.isSupplier = false;
	// 			}
	// 		});
	// }

	private validateFilter(tier1EComponentShortageTracker: Tier1EComponentShortageTracker): boolean {
		let isValidFilter = false;
		if (tier1EComponentShortageTracker.tier1ModuleSupplierName && tier1EComponentShortageTracker.weekDate !== null) {
			isValidFilter = true;
		}
		return isValidFilter;
	}

	// loadTemplate() {
	// 	const tier1EComponentShortageTracker1 = new Tier1EComponentShortageTracker();
	// 	tier1EComponentShortageTracker1.weekNumber = this.getCurrentWeekNumber();
	// 	tier1EComponentShortageTracker1.supplierEmailId = sessionStorage.getItem('emailAddress');
	// 	// @ts-ignore
	// 	tier1EComponentShortageTracker1.weekDate = this.getMondayFromCurrentDate();
	// 	tier1EComponentShortageTracker1.tier1ModuleSupplierName = this.selectedTier1ModuleSupplier;
	// 	tier1EComponentShortageTracker1.semiConductorSupplierName = this.selectedSemiconductorSupplier;
	// 	if (this.validateFilter(tier1EComponentShortageTracker1)) {
	// 		this.tier1EComponentShortageService.generateTemplateForSupplier(tier1EComponentShortageTracker1).subscribe((data) => {
	// 			this.fileFromDatabase = data;
	// 			this.spinnerService.show();
	// 			ExcelUtility.load(this.fileFromDatabase).then((w) => {
	// 				this.isSubmitVisible = true;
	// 				this.spreadsheet.workbook = w;
	// 				this.spreadsheet.isRenamingWorksheet = false;
	// 				this.spreadsheet.allowAddWorksheet = false;
	// 				this.spreadsheet.allowDeleteWorksheet = false;
	// 				this.spinnerService.hide();
	// 			});
	// 		});
	// 	} else {
	// 		this.pushMessage('error', 'Error', 'Please Complete the filter.');
	// 	}
	// }

	loadSupplierTemplate() {
		const tier1EComponentShortageTracker1 = new Tier1EComponentShortageTracker();
		tier1EComponentShortageTracker1.weekNumber = this.getCurrentWeekNumber();
		tier1EComponentShortageTracker1.supplierEmailId = sessionStorage.getItem('emailAddress');
		// @ts-ignore
		tier1EComponentShortageTracker1.weekDate = this.datepipe.transform(this.getMondayFromCurrentDate(), 'yyyy-MM-dd');
		tier1EComponentShortageTracker1.tier1ModuleSupplierName = this.selectedTier1ModuleSupplier;
		tier1EComponentShortageTracker1.semiConductorSupplierName = 'All';
		if (this.validateFilter(tier1EComponentShortageTracker1)) {
			this.tier1EComponentShortageService.generateTemplateForSupplier(tier1EComponentShortageTracker1).subscribe((data) => {
				this.fileFromDatabase = data;
				this.spinnerService.show();
				ExcelUtility.load(this.fileFromDatabase).then((w) => {
					this.isSubmitVisible = true;
					this.spreadsheet.workbook = w;
					this.spreadsheet.isRenamingWorksheet = false;
					this.spreadsheet.allowAddWorksheet = false;
					this.spreadsheet.allowDeleteWorksheet = false;
					this.spinnerService.hide();
					console.log('Excel Loaded');
				});
			});
		} else {
			this.pushMessage('error', 'Error', 'Kindly Select the Tier1 Module Supplier Name.');
		}
	}

// 	downloadTemplate() {
// 		const tier1EComponentShortageTracker1 = new Tier1EComponentShortageTracker();
// 		tier1EComponentShortageTracker1.weekNumber = this.getCurrentWeekNumber();
// 		tier1EComponentShortageTracker1.supplierEmailId = sessionStorage.getItem('emailAddress');
// 		// @ts-ignore
// 		tier1EComponentShortageTracker1.weekDate = this.datepipe.transform(this.getMondayFromCurrentDate(), 'yyyy-MM-dd');
// 		tier1EComponentShortageTracker1.tier1ModuleSupplierName = this.selectedTier1ModuleSupplier;
// 		tier1EComponentShortageTracker1.semiConductorSupplierName = this.selectedSemiconductorSupplier;
// 		if (this.validateFilter(tier1EComponentShortageTracker1)) {
// 			this.tier1EComponentShortageService.generateTemplateForSupplier(tier1EComponentShortageTracker1).subscribe((data) => {
// 				this.dashboardDownloadService.downLoadFile(data, 'text/csv');
// 			});
// } else {
// 	this.pushMessage('error', 'Error', 'Please Complete the filter.');
// }
// }

	downloadSupplierTemplate() {
		const tier1EComponentShortageTracker1 = new Tier1EComponentShortageTracker();
		tier1EComponentShortageTracker1.weekNumber = this.getCurrentWeekNumber();
		tier1EComponentShortageTracker1.supplierEmailId = sessionStorage.getItem('emailAddress');
		// @ts-ignore
		tier1EComponentShortageTracker1.weekDate = this.datepipe.transform(this.getMondayFromCurrentDate(), 'yyyy-MM-dd');
		tier1EComponentShortageTracker1.tier1ModuleSupplierName = this.selectedTier1ModuleSupplier;
		tier1EComponentShortageTracker1.semiConductorSupplierName = 'All';
		if (this.validateFilter(tier1EComponentShortageTracker1)) {
			this.tier1EComponentShortageService.generateTemplateForSupplier(tier1EComponentShortageTracker1).subscribe((data) => {
				this.dashboardDownloadService.downLoadFile(data, 'text/csv');
			});
		} else {
			this.pushMessage('error', 'Error', 'Kindly Select the Tier1 Module Supplier Name.');
		}
	}

	saveEComponentSpreadsheet() {
		if (this.selectedTier1ModuleSupplier !== null && this.selectedTier1ModuleSupplier.length > 0) {
			this.spinnerService.show();
			const opt = new WorkbookSaveOptions();
			opt.type = 'blob';
			const workbook = this.spreadsheet.workbook;
			workbook.save(opt, (d) => {
				const fileExt = ExcelUtility.getExtension(workbook.currentFormat);
				const fileName = 'Tier 1 E-Component Shortage Form' + fileExt;
				// this.dashboardDownloadService.downLoadFile(d, 'text/csv');
				// tslint:disable-next-line:max-line-length
				this.dashboardDownloadService.upload(d as File, this.selectedTier1ModuleSupplier, sessionStorage.getItem('emailAddress')).subscribe((data) => {
						this.display = false;
						this.response = data;
						console.log(this.response);
						if (JSON.parse(this.response.success)) {
							this.pushDialog(this.response.message);
							this.isSubmitVisible = false;
							this.spreadsheet.workbook = new Workbook();
						} else {
							this.pushDialog(this.response.message);
						}
						// this.pushMessage('success', 'success',
						// 	'Data Updated Successfully!');
						// this.isSubmitVisible = false;
						// this.display = false;
						// this.spreadsheet.workbook = new Workbook();
						// const s: Sheet = null;
						// s.this.spreadsheet.workbook.sheets().add(s);
						// tslint:disable-next-line:indent
						// this.accessSupplierList();
					},
					error => {
						this.pushMessage('error', 'error',
							'Unable to Update Data, Please try again later');
					}
				);
			}, (e) => {
				this.pushMessage('error', 'Error', 'Unable to Update Data, Please try again later');
			});
		} else {
			this.pushMessage('error', 'Error', 'Please select T1 Supplier Name');
		}
	}

	upload(event, form) {
		if (this.selectedTier1ModuleSupplier !== null && this.selectedTier1ModuleSupplier.length > 0) {
			for (const file of event.files) {
				this.selectedFiles.push(file);
			}
			// tslint:disable-next-line:max-line-length
			this.dashboardDownloadService.upload(this.selectedFiles[0], this.selectedTier1ModuleSupplier, sessionStorage.getItem('emailAddress')).subscribe((data) => {
					this.response = data;
					console.log(this.response);
					if (JSON.parse(this.response.success)) {
						this.pushDialog(this.response.message);
					} else {
						this.pushDialog(this.response.message);
					}
					form.clear();
					this.selectedFiles.pop();
					// this.pushMessage('success', 'Success',
					// 	'Data Updated Successfully!');
					// form.clear();
					// this.selectedFiles.pop();
					// this.display = false;
					// this.accessSupplierList();
				},
				error => {
					this.pushMessage('error', 'Error', 'Unable to Update Data, Please try again later');
					form.clear();
					this.selectedFiles.pop();
				}
			);
		} else {
			this.pushMessage('error', 'Error', 'Please select T1 Supplier Name');
		}
	}
	supplierInfo() {
		this.router.navigate(['/tier1-e-component-shortage-admin']);
	}
	onPrint() {
		window.print();
	}
	onReset() {
		this.eComponentShortageForm.controls['partBaseNumber'].reset();
		this.eComponentShortageForm.controls['tier1ModuleComponentDescription'].reset();
		this.eComponentShortageForm.controls['tier1ReferencePartNumber'].reset();
		this.eComponentShortageForm.controls['semiConductorSupplierName'].reset();
		this.eComponentShortageForm.controls['semiConductorComponentDescription'].reset();
		this.eComponentShortageForm.controls['semiConductorManufacturingPartNumber'].reset();
		this.eComponentShortageForm.controls['maximumICQuantityUsedPerModule'].reset();
		this.eComponentShortageForm.controls['semiConductorLeadTime'].reset();
		this.eComponentShortageForm.controls['isFord100PercentShareOfSupply'].reset();
		this.eComponentShortageForm.controls['percentAverageAllocationToFord'].reset();
		this.eComponentShortageForm.controls['isDirectSemiConductorBuy'].reset();
		this.eComponentShortageForm.controls['comments'].reset();
		this.yesNoOptions = [
			{name: 'Yes', code: 'Yes'},
			{name: 'No', code: 'No'}];
	}

	showRemoveDataDialog() {
		this.displayRemoveDataDialog = true;
	}

	showDeleteConfirmationDialog() {
		this.displayRemoveDataDialog = false;
		this.displayDeleteConfirmationDialog = true;
	}

	showDuplicateDeleteConfirmationDialog() {
		this.displayDuplicateDeleteConfirmationDialog = true;
		// tslint:disable-next-line:indent
  this.displayDuplicateCall();
	}

	closeDuplicateDeleteDialog() {
		this.displayDuplicateDeleteConfirmationDialog = false;
		// tslint:disable-next-line:indent
  this.duplicateEComp = [];
	}

	displayDuplicateCall() {
		this.displayDuplicateDataTier1EComp = new Tier1EComponentShortageTracker();
		if (this.selectedT1ModuleSupplierNames.length > 0) {
			const t1ModuleSupplierNames = [];
			for (const item of this.selectedT1ModuleSupplierNames) {
				t1ModuleSupplierNames.push(item.name);
			}
			this.displayDuplicateDataTier1EComp.tier1ModuleSupplierName = t1ModuleSupplierNames.join('|');
		}
		if (this.selectedGsdbCodes.length > 0) {
			const gsdbCodes = [];
			for (const item of this.selectedGsdbCodes) {
				gsdbCodes.push(item.name);
			}
			this.displayDuplicateDataTier1EComp.tier1ModuleSupplierLocation = gsdbCodes.join('|');
		}
		if (this.selectedSemiconductorSupplierNames.length > 0) {
			const semiconductorSupplierNames = [];
			for (const item of this.selectedSemiconductorSupplierNames) {
				semiconductorSupplierNames.push(item.name);
			}
			this.displayDuplicateDataTier1EComp.semiConductorSupplierName = semiconductorSupplierNames.join('|');
		}
		this.tier1EComponentShortageService.displayEComponentDuplicatesService(this.displayDuplicateDataTier1EComp).subscribe(data => {
			console.log('Duplicate data: ' , data);
			this.duplicateEComp = [];
			this.duplicateEComp = data;
		});
	}
	showDialog() {
		this.display = true;
	}

	closeDialog() {
		this.display = false;
	}
	loadT1ModuleSupplierNames() {
		this.tier1EComponentShortageService.loadT1ModuleSupplierNames().subscribe(t1ModuleSupplierNames => {
			this.t1ModuleSupplierNames = [];
			this.t1ModuleSupplierNames = t1ModuleSupplierNames;
		});
	}
	loadGsdbCodes() {
		this.tier1EComponentShortageService.loadGsdbCodes().subscribe(gsdbCodes => {
			this.gsdbCodes = [];
			this.gsdbCodes = gsdbCodes;
		});
	}
	loadSemiconductorSupplierNames() {
		this.tier1EComponentShortageService.loadSemiconductorSupplierNames().subscribe(semiconductorSupplierNames => {
			this.semiconductorSupplierNames = [];
			// @ts-ignore
			this.semiconductorSupplierNames = semiconductorSupplierNames;
		});
	}
	loadAllIdFromDb() {
		this.tier1EComponentShortageService.loadAllId().subscribe(data => {
			this.deleteSelectedId = [];
			this.deleteSelectedId = data;
		});
	}

	onT1ModuleSupplierNameChange($event: any) {
		this.loadSemiconductorSupplierNamesBasedOnT1ModuleSupplierNames();
		this.loadGsdbCodesBasedOnT1ModuleSupplierNames();
	}
	onGsdbCodeChange($event: any) {
		this.loadSemiconductorSupplierNamesBasedOnT1ModuleSupplierNamesAndGsdbCode();
	}
	onSemiconductorSupplierNameChange($event: any) {
		if (this.selectedSemiconductorSupplierNames.length > 0) {
			const semiconductorSupplierNames = [];
			for (const item of this.selectedSemiconductorSupplierNames) {
				semiconductorSupplierNames.push(item.name);
			}
			this.tier1EComponentShortageTracker.semiConductorSupplierName = semiconductorSupplierNames.join(';!');
		}
	}
	loadSemiconductorSupplierNamesBasedOnT1ModuleSupplierNames() {
		this.selectedSemiconductorSupplierNames = [];
		if (this.selectedT1ModuleSupplierNames.length > 0) {
			this.semiconductorSupplierNames = [];
			const t1ModuleSupplierNames = [];
			for (const item of this.selectedT1ModuleSupplierNames) {
				t1ModuleSupplierNames.push(item.name);
			}
			this.tier1EComponentShortageTracker = new Tier1EComponentShortageTracker();
			this.tier1EComponentShortageTracker.tier1ModuleSupplierName = t1ModuleSupplierNames.join('|');
			// tslint:disable-next-line:max-line-length
			this.tier1EComponentShortageService.loadSemiconductorSupplierNamesBasedOnT1ModuleSupplierNames(this.tier1EComponentShortageTracker).subscribe(semiconductorSupplierNames => {
				// @ts-ignore
				this.semiconductorSupplierNames = semiconductorSupplierNames;
			});
		} else {
			this.loadSemiconductorSupplierNames();
		}
	}

	loadGsdbCodesBasedOnT1ModuleSupplierNames() {
		this.selectedGsdbCodes = [];
		if (this.selectedT1ModuleSupplierNames.length > 0) {
			this.gsdbCodes = [];
			const tier1ModuleSupplierNames = [];
			for (const item of this.selectedT1ModuleSupplierNames) {
				tier1ModuleSupplierNames.push(item.name);
			}
			this.tier1EComponentShortageTracker = new Tier1EComponentShortageTracker();
			this.tier1EComponentShortageTracker.tier1ModuleSupplierName = tier1ModuleSupplierNames.join('|');
			// tslint:disable-next-line:max-line-length
			this.tier1EComponentShortageService.loadGsdbCodesBasedOnT1ModuleSupplierNames(this.tier1EComponentShortageTracker).subscribe(gsdbCodes => {
				// @ts-ignore
				this.gsdbCodes = gsdbCodes;
			});
		} else {
			this.loadGsdbCodes();
		}
	}

	loadSemiconductorSupplierNamesBasedOnT1ModuleSupplierNamesAndGsdbCode() {
		this.selectedSemiconductorSupplierNames = [];
		if (this.selectedT1ModuleSupplierNames.length > 0 || this.selectedGsdbCodes.length > 0) {
			this.semiconductorSupplierNames = [];
			this.tier1EComponentShortageTracker = new Tier1EComponentShortageTracker();
			if (this.selectedT1ModuleSupplierNames.length > 0) {
				const tier1ModuleSupplierNames = [];
				for (const item of this.selectedT1ModuleSupplierNames) {
					tier1ModuleSupplierNames.push(item.name);
				}
				this.tier1EComponentShortageTracker.tier1ModuleSupplierName = tier1ModuleSupplierNames.join('|');
			}
			if (this.selectedGsdbCodes.length > 0) {
				const gsdbCodes = [];
				for (const item of this.selectedGsdbCodes) {
					gsdbCodes.push(item.name);
				}
				this.tier1EComponentShortageTracker.tier1ModuleSupplierLocation = gsdbCodes.join('|');
			}
			// tslint:disable-next-line:max-line-length
			this.tier1EComponentShortageService.loadSemiconductorSupplierNamesBasedOnT1ModuleSupplierNamesAndGsdbCode(this.tier1EComponentShortageTracker).subscribe(semiconductorSupplierNames => {
				// @ts-ignore
				this.semiconductorSupplierNames = semiconductorSupplierNames;
			});
		} else {
			this.loadSemiconductorSupplierNames();
		}
	}

	onSelectedIdForDeletionChange($event: any) {
		if (this.selectedIdForDeletion.length > 0) {
			const idForDeletion = [];
			for (const item of this.selectedSemiconductorSupplierNames) {
				idForDeletion.push(item.name);
			}
			// this.tier1EComponentShortageTracker.semiConductorSupplierName = idForDeletion.join(';!');
		}
	}

	generateReport(isOnline: boolean) {
		this.tier1EComponentShortageTracker = new Tier1EComponentShortageTracker();
		this.tier1EComponentShortageTracker.weekNumber = this.getCurrentWeekNumber();
		this.tier1EComponentShortageTracker.supplierEmailId = sessionStorage.getItem('emailAddress');
		// @ts-ignore
		this.tier1EComponentShortageTracker.weekDate = this.datepipe.transform(this.getMondayFromCurrentDate(), 'yyyy-MM-dd');
		if (this.selectedT1ModuleSupplierNames.length > 0) {
				const tier1ModuleSupplierNames = [];
				for (const item of this.selectedT1ModuleSupplierNames) {
					tier1ModuleSupplierNames.push(item.name);
				}
				this.tier1EComponentShortageTracker.tier1ModuleSupplierName = tier1ModuleSupplierNames.join('|');
			}

		if (this.selectedGsdbCodes.length > 0) {
				const gsdbCodes = [];
				for (const item of this.selectedGsdbCodes) {
					gsdbCodes.push(item.name);
				}
				this.tier1EComponentShortageTracker.tier1ModuleSupplierLocation = gsdbCodes.join('|');
			}

		if (this.selectedSemiconductorSupplierNames.length > 0) {
			const semiconductorSupplierNames = [];
			for (const item of this.selectedSemiconductorSupplierNames) {
				semiconductorSupplierNames.push(item.name);
			}
			this.tier1EComponentShortageTracker.semiConductorSupplierName = semiconductorSupplierNames.join('|');
		}
		this.tier1EComponentShortageService.generateReport(this.tier1EComponentShortageTracker).subscribe((data) => {
			if (isOnline) {
				this.loadOnline(data);
			} else {
				this.downloadOffline(data);
			}
		});
	}
	loadOnline(data: any) {
		this.spinnerService.show();
		ExcelUtility.load(data).then((w) => {
			this.isSuperAdminSubmitVisible = true;
			this.spreadsheet.workbook = w;
			this.spinnerService.hide();
			console.log('Excel Loaded');
		});
	}

	downloadOffline(data: any) {
		this.tier1EComponentShortageService.downLoadFile(data, 'text/csv');
	}
	pushDialog(dialogBody: any) {
		this.ref = this.dialogService.open(CustomDialogComponent, {
			header: 'E-Component Shortage Tracker - Response',
			footer: '',
			data: dialogBody,
			width: '50%',
			contentStyle: { 'max-height': '1000px',
				overflow: 'auto' },
			baseZIndex: 10000,
		});
	}

	removeDataBasedOnId() {
		if (this.selectedIdForDeletion !== null && this.selectedIdForDeletion.length > 0) {
			const idList = [];
			for (const item of this.selectedIdForDeletion) {
				idList.push(item.name);
			}
			console.log('id list ', idList);
			this.tier1EComponentShortageService.removeDataBasedOnIdService(idList.join('|')).subscribe(ids => {
				// @ts-ignore
				this.ids = ids;
				this.selectedIdForDeletion = [];
				this.displayRemoveDataDialog = false;
				this.displayDeleteConfirmationDialog = false;
				this.loadAllIdFromDb();
				this.pushDialog('Data removed successfully');
			}, (e) => {
				this.pushDialog('Data removal failed, Please try again later');
			});
		} else {
			this.pushMessage('error', 'Error', 'Please select the Id ');
		}
	}

	get properties() {
		return this.selectedIdForDeletion.map( (o) => o.name );
	}

	fordUserUpdate() {
		this.spinnerService.show();
		const opt = new WorkbookSaveOptions();
		opt.type = 'blob';
		const workbook = this.spreadsheet.workbook;
		workbook.save(opt, (d) => {
			this.dashboardDownloadService.superAdminUpload(d as File, sessionStorage.getItem('emailAddress')).subscribe((data) => {
					this.display = false;
					this.response = data;
					console.log(this.response);
					if (JSON.parse(this.response.success)) {
						this.pushDialog(this.response.message);
						this.isSubmitVisible = false;
						this.spreadsheet.workbook = new Workbook();
					} else {
						this.pushDialog(this.response.message);
					}
				},
				error => {
					this.pushMessage('error', 'Error',
						'Unable to Update Data, Please try again later');
				}
			);
		}, (e) => {
			this.pushMessage('error', 'Error', 'Unable to Update Data, Please try again later');
		});
	}

	superAdminUpload(event: any, form2) {
		for (const file of event.files) {
			this.selectedFiles.push(file);
		}
		this.dashboardDownloadService.superAdminUpload(this.selectedFiles[0], sessionStorage.getItem('emailAddress')).subscribe((data) => {
				this.response = data;
				console.log(this.response);
				if (JSON.parse(this.response.success)) {
					this.pushDialog(this.response.message);
				} else {
					this.pushDialog(this.response.message);
				}
				form2.clear();
				this.selectedFiles.pop();
			},
			error => {
				this.pushMessage('error', 'Error', 'Unable to Update Data, Please try again later');
				form2.clear();
				this.selectedFiles.pop();
			}
		);
	}

	removeEComponentDuplicatesAdhoc() {
		this.removeDataTier1EComp = new Tier1EComponentShortageTracker();
		if (this.selectedT1ModuleSupplierNames.length > 0) {
			const t1ModuleSupplierNames = [];
			for (const item of this.selectedT1ModuleSupplierNames) {
				t1ModuleSupplierNames.push(item.name);
			}
			this.removeDataTier1EComp.tier1ModuleSupplierName = t1ModuleSupplierNames.join('|');
		}
		if (this.selectedGsdbCodes.length > 0) {
			const gsdbCodes = [];
			for (const item of this.selectedGsdbCodes) {
				gsdbCodes.push(item.name);
			}
			this.removeDataTier1EComp.tier1ModuleSupplierLocation = gsdbCodes.join('|');
		}
		if (this.selectedSemiconductorSupplierNames.length > 0) {
			const semiconductorSupplierNames = [];
			for (const item of this.selectedSemiconductorSupplierNames) {
				semiconductorSupplierNames.push(item.name);
			}
			this.removeDataTier1EComp.semiConductorSupplierName = semiconductorSupplierNames.join('|');
		}
		// tslint:disable-next-line:indent
		this.tier1EComponentShortageService.removeEComponentDuplicates(this.removeDataTier1EComp).subscribe((data) => {
			this.spinnerService.show();
			this.displayDuplicateDeleteConfirmationDialog = false;
			this.displayDuplicateCall();
			// this.pushMessage('success', 'success', 'Duplicates Removed Successfully!');
			this.pushDialog('Duplicates Removed Successfully');
			this.spinnerService.hide();
		}, (e) => {
			// this.pushMessage('error', 'error', 'Duplicate removal failed, Please try again later');
			this.pushDialog('Duplicate removal failed, Please try again later');

		});
	}
}
