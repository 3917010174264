import {Component, OnInit, ViewChild} from '@angular/core';
import {Table} from 'primeng/table';
import {SupplierService} from '../../../home/services/supplier.service';
import {Router} from '@angular/router';
import {ValueStreamDataService} from '../service/value-stream-data.service';
import {
	DialogService,
	DynamicDialogRef,
	MessageService
} from 'primeng/api';
import {NTierMasterAdminService} from '../../n-tier-master-admin/service/n-tier-master-admin.service';
import {CustomDialogComponent} from '../../../custom-dialog/custom-dialog/custom-dialog.component';

@Component({
	selector: 'value-stream-data-report-approval',
	templateUrl: './value-stream-data-report-approval.component.html',
	styleUrls: ['./value-stream-data-report-approval.component.scss']
})
export class ValueStreamDataReportApprovalComponent implements OnInit {

	@ViewChild('dt1', {static: true}) private dataTable: Table;

	vsmDataApproval: any = [];
	isDisplayFilterData = false;
	page = 0;
	pageSize = 10;
	filterValue = '';
	totalPageCount: any = 1.0;
	displayDialogue = false;
	tntvRecords: any;
	tntvColumns: any;
	vsmColumnHeaders: any;
	selectedRecords: any;
	dropDownStatus: any = [];
	status: any;
	displayDifferentDialogue = false;
	newRecordValue: any;
	oldRecord: any;
	oldRecordValue: any;
	vsmColumns: any = [];
	ref: DynamicDialogRef;
	response: any = {success: false, message: ''};

	// tslint:disable-next-line:max-line-length
	constructor(private masterAdminService: NTierMasterAdminService, private messageService: MessageService, private supplierService: SupplierService, private router: Router, private valueStreamDataService: ValueStreamDataService, private dialogService: DialogService) {
		this.dropDownStatus = [
			{name: 'APPROVE', code: 'APPROVE'},
			{name: 'PENDING', code: 'PENDING'},
			{name: 'REJECT', code: 'REJECT'}

		];
	}

	ngOnInit() {
		this.router.navigate(['/']);
		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
			return;
		} else if (sessionStorage.getItem('uid').includes('-')) {
			this.router.navigate(['/']);
			return;
		}
		// this.supplierService.pushSelectedSurvey('VSM');
		this.getVsmDataApproval();
	}

	getVsmDataApproval() {
		this.valueStreamDataService.getAllVsmDataApproval(this.page, this.pageSize).subscribe((res: any) => {
			if (res[0]['count'] > this.pageSize) {
				this.totalPageCount = Math.ceil(res[0]['count'] / this.pageSize);
			}
			this.vsmDataApproval = res;
		});
	}

	showDataFilters() {
		this.isDisplayFilterData = !this.isDisplayFilterData;
		this.dataTable.reset();
	}

	cleareDataFilters() {
		this.isDisplayFilterData = false;
		this.filterValue = '';
		this.status = '';
		this.dataTable.reset();
		this.getVsmDataApproval();
		// this.ddRequestTypes.resetFilter();

	}

	nextPage() {
		if (this.totalPageCount === this.page + 1) {
		} else if (this.filterValue || this.status) {
			this.page++;
			this.filterWithValue();
		} else {
			this.page++;
			this.getVsmDataApproval();
		}
	}

	previousPage() {

		if (this.page + 1 === 1) {

		} else if (this.filterValue || this.status) {
			this.page--;
			this.filterWithValue();
		} else {
			this.page--;
			this.getVsmDataApproval();
		}
	}

	filter() {
		this.page = 0;
		this.totalPageCount = 1.0;
		if (this.filterValue === '' && this.status === null) {
			this.getVsmDataApproval();
		} else if (this.filterValue || this.status) {
			this.filterWithValue();
		}
	}

	filterWithValue() {
		this.valueStreamDataService.vsmDataApprovalFilter(
			(this.filterValue ? this.filterValue : 'ALL'), this.page, this.pageSize, (this.status ? this.status.code : 'ALL'))
			.subscribe((res: any) => {
				this.vsmDataApproval = [];
				if (res[0]['count'] > this.pageSize) {
					this.totalPageCount = Math.ceil(res[0]['count'] / this.pageSize);
				}
				this.vsmDataApproval = res;
			});
	}

	clickOperation(records, tntvRecords, tntvColumns) {
		this.selectedRecords = records;
		this.tntvRecords = JSON.parse(tntvRecords);
		this.tntvColumns = JSON.parse(tntvColumns);
		this.displayDialogue = true;
	}
	clickOperationDetails(row) {
		this.oldRecordValue = null;
		this.newRecordValue = null;
		this.displayDifferentDialogue = true;
		this.newRecordValue = row;
		if (row.oldRecord) {
			this.oldRecordValue = JSON.parse(row.oldRecord);
		}
	}

	approve(item: any) {
		console.log(item);
		this.valueStreamDataService.updateData({
			templateId: item.tntvTemplateId,
			updateData: this.tntvRecords,
			approvalRecordId: item.id,
		}).subscribe((data) => {
			this.response = data;
			console.log(this.response);
			this.displayDialogue = false;
			if (JSON.parse(this.response.success)) {
				this.pushDialog('Data Approved Successfully');
			} else {
				this.pushDialog(this.response.message);
			}
			this.filter();
			this.cleareDataFilters();
		});
	}

	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 100000
		});
	}

	reject(item: any) {
		this.valueStreamDataService.rejectData(item.id).subscribe((data) => {
			console.log(data);
			this.displayDialogue = false;
			this.pushMessage('success', 'success',
				'Data rejected successfully');
			this.filter();
			this.cleareDataFilters();
		});
	}

	// clickOperationDetails(oldRecord, newRecord, templateId) {
	//
	// 	this.valueStreamDataService.vsmGetColumnAccess(templateId).subscribe((res: any) => {
	// 		this.vsmColumns = res;
	// 		this.oldRecordValue = JSON.parse(oldRecord);
	// 		this.newRecordValue = JSON.parse(newRecord);
	// 		console.log('tntvColumns->', this.tntvColumns);
	// 		console.log('tntvRecords->', this.tntvRecords);
	// 		console.log('tntvSelectedRecords->', this.selectedRecords);
	// 		this.displayDifferentDialogue = true;
	// 	});
	// }
	pushDialog(dialogBody: any) {
		this.ref = this.dialogService.open(CustomDialogComponent, {
			header: 'VSM Data Approval Response',
			footer: '',
			data: dialogBody,
			width: '50%',
			contentStyle: { 'max-height': '1000px',
				overflow: 'auto' },
			baseZIndex: 10000,
		});
	}
}
