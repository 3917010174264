import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Tier1EComponentShortageAdmin} from '../model/tier1-e-component-shortage-admin';
import {Tier1EComponentShortageAdminService} from '../service/tier1-e-component-shortage-admin.service';
import {DatePipe} from '@angular/common';
import {MessageService} from 'primeng/api';
import {DashboardDownloadService} from '../service/dashboard-download.service';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {SupplierService} from '../../../home/services/supplier.service';

@Component({
	// tslint:disable-next-line:indent
	selector: 'tier1-e-component-shortage-admin',
	// tslint:disable-next-line:indent
	templateUrl: './tier1-e-component-shortage-admin.component.html',
	// tslint:disable-next-line:indent
	styleUrls: ['./tier1-e-component-shortage-admin.component.scss']
})
export class Tier1EComponentShortageAdminComponent implements OnInit {
	contactForm: FormGroup;
	contact: any;
	isDisabled = false;
	show = false;
	showInput = true;
	showDropdown = false;
	isReadOnly = false;
	tier1SupplierList = [];
	selectedTier1Supplier: string;
	tier1EComponentShortageAdmin: Tier1EComponentShortageAdmin;
	newTier1EComponentShortageAdmin: Tier1EComponentShortageAdmin;
	createTier1EComponentShortageAdmin: Tier1EComponentShortageAdmin;
	retrieve: any;
	isContactFormValid = false;
	isEdit = false;
	isCreate = false;
	adminDataList: Tier1EComponentShortageAdmin[] = [];
	isDisplayFilterData = false;

	// tslint:disable-next-line:max-line-length
	constructor(private supplierService: SupplierService, private fb: FormBuilder, private router: Router, private datepipe: DatePipe, private downloadServiceObj: DashboardDownloadService, private spinnerService: SpinnerService, private messageService: MessageService, private tier1EComponentShortageAdminService: Tier1EComponentShortageAdminService) {
	}

	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 60000
		});
	}

	ngOnInit() {

		this.router.navigate(['/']);
		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
			return;
		} else if (sessionStorage.getItem('uid').includes('-')) {
			this.router.navigate(['/']);
			return;
		}
		// this.supplierService.pushSelectedSurvey('SemiSupp');
		this.contactForm = this.fb.group({
			tier1SupplierList: [''],
			tier1Supplier: ['', Validators.required],
			commodity: [{value: '', disabled: this.isDisabled}],
			managerEmail: [{value: '', disabled: this.isDisabled}, [Validators.email]],
			buyerEmail1: [{value: '', disabled: this.isDisabled}, [Validators.email]],
			supplierEmailId: [{value: '', disabled: this.isDisabled}, [Validators.required, Validators.email]],
			remarks: [{value: '', disabled: this.isDisabled}]
		});
		// this.getTier1SupplierList();
		// this.retrieveAllAdminData();
	}

	getTier1SupplierList() {
		this.contactForm.reset();
		this.tier1EComponentShortageAdminService.getAllTier1Suppliers().subscribe((data) => {
			this.tier1SupplierList = data;
		});
	}
	getTier1EComponentShortageAdmin(event) {
		this.selectedTier1Supplier = this.contactForm.get('tier1SupplierList').value.tier1Supplier;
		const tier1EComponentShortageAdmin2 = new Tier1EComponentShortageAdmin();
		tier1EComponentShortageAdmin2.tier1Supplier = this.contactForm.get('tier1SupplierList').value.tier1Supplier;
		this.tier1EComponentShortageAdminService.getContactDataFromTier1Supplier(tier1EComponentShortageAdmin2).subscribe((data) => {
			this.tier1EComponentShortageAdmin = data;
			this.contactForm.patchValue({commodity: this.tier1EComponentShortageAdmin.commodity});
			this.contactForm.patchValue({managerEmail: this.tier1EComponentShortageAdmin.managerEmail});
			this.contactForm.patchValue({buyerEmail1: this.tier1EComponentShortageAdmin.buyerEmail1});
			this.contactForm.patchValue({supplierEmailId: this.tier1EComponentShortageAdmin.supplierEmailId});
			this.contactForm.patchValue({remarks: this.tier1EComponentShortageAdmin.remarks});
		});
	}

	onUpdate() {
		this.newTier1EComponentShortageAdmin = this.contactForm.getRawValue() as Tier1EComponentShortageAdmin;
		this.newTier1EComponentShortageAdmin.id = this.tier1EComponentShortageAdmin.id;
		// if (!this.validateContactDetails(this.newTier1EComponentShortageAdmin)) {
		// 	this.isContactFormValid = false;
		// 	this.pushMessage('error', 'Error', 'Please fill all mandatory fields in Contact Details Form.');
		// } else {
		// 	this.isContactFormValid = true;
		// }
		// tslint:disable-next-line:triple-equals
		// if (this.isContactFormValid == true) {
		this.tier1EComponentShortageAdminService.updateTier1EComponentShortageAdmin(this.newTier1EComponentShortageAdmin).subscribe(response => {
				this.newTier1EComponentShortageAdmin = response;
				console.log('Success: Shortage Tracker Contact Details Id #' + response.id + ' has been Updated successfully');
				// tslint:disable-next-line:max-line-length
				this.pushMessage('success', 'Success', 'Shortage Tracker Contact Details for Admin with Id #' + response.id + ' has been Updated successfully');
				this.contactForm.reset();
				this.getTier1SupplierList();
			// tslint:disable-next-line:indent
			 this.retrieveAllAdminData();
		});

	}

	onCreate() {
		let tier1Supplier = this.contactForm.get('tier1Supplier').value;
		this.createTier1EComponentShortageAdmin = this.contactForm.getRawValue() as Tier1EComponentShortageAdmin;
		this.createTier1EComponentShortageAdmin.tier1Supplier = tier1Supplier;
		if (!this.validateContactDetails(this.createTier1EComponentShortageAdmin)) {
			this.isContactFormValid = false;
			this.pushMessage('error', 'Error', 'Please fill all mandatory fields in Contact Details Form.');
		} else {
			this.isContactFormValid = true;
		}
		if (this.isContactFormValid) {
			tier1Supplier = tier1Supplier.trim();
			this.tier1EComponentShortageAdminService.checkTier1SupplierExistence(tier1Supplier).subscribe(isTier1SupplierExists => {
				if (isTier1SupplierExists) {
				this.pushMessage('error', 'Error', 'Tier 1 Supplier : ' + tier1Supplier + ' already Exists');
			} else {
				// tslint:disable-next-line:max-line-length
				this.tier1EComponentShortageAdminService.createTier1EComponentShortageAdmin(this.createTier1EComponentShortageAdmin).subscribe(response => {
					this.createTier1EComponentShortageAdmin = response;
					console.log('Success: Shortage Tracker Contact Details Id #' + response.id + ' has been created successfully');
					// tslint:disable-next-line:max-line-length
					this.pushMessage('success', 'Success', 'Shortage Tracker Contact Details for Admin with Id #' + response.id + ' has been Created successfully');
					this.contactForm.reset();
					this.retrieveAllAdminData();
				});
				}
			});
		}
	}

	onReset() {
		this.contactForm.controls['commodity'].reset();
		this.contactForm.controls['managerEmail'].reset();
		this.contactForm.controls['buyerEmail1'].reset();
		this.contactForm.controls['supplierEmailId'].reset();
		this.contactForm.controls['remarks'].reset();
		this.isCreate = false;
		this.isEdit = false;
	}

	private validateContactDetails(newTier1EComponentShortageAdmin: Tier1EComponentShortageAdmin): boolean {
		let isContactFormValid = false;
		if ((newTier1EComponentShortageAdmin.tier1Supplier && newTier1EComponentShortageAdmin.tier1Supplier.trim() !== '') &&
			(newTier1EComponentShortageAdmin.supplierEmailId && newTier1EComponentShortageAdmin.supplierEmailId.trim() !== '')) {
			isContactFormValid = true;
			console.log('Contact Form Valid');
		}
		return isContactFormValid;
	}

	onPrevious() {
		this.router.navigate(['/tier1-e-component-shortage/false']);
	}

	onSubmit() {
		this.isEdit = false;
		this.isCreate = true;
		this.show = true;
		this.showInput = true;
		this.showDropdown = false;
		this.isReadOnly = false;
		this.onReset();
	}
	updateForm() {
		this.isEdit = true;
		this.isCreate = true;
		this.contactForm.reset();
		this.getTier1SupplierList();
		this.show = true;
		this.showInput = false;
		this.showDropdown = true;
		this.isReadOnly = false;
	}

	downloadAdminReport() {
			const tier1EComponentShortageAdmin1 = new Tier1EComponentShortageAdmin();
			// tslint:disable-next-line:max-line-length
			// tier1EComponentShortageTrackerBusiness.supplierEmailId = sessionStorage.getItem('uid');
			this.downloadServiceObj.downloadAdminDataReport(tier1EComponentShortageAdmin1);
			this.spinnerService.show();
		}
		retrieveAllAdminData() {
			this.tier1EComponentShortageAdminService.retrieveAllAdminData().subscribe((data) => {
				this.adminDataList = data;
			});
		}
	showDataFilters() {
		this.isDisplayFilterData = !this.isDisplayFilterData;
	}

	clearDataFilters() {
		this.retrieveAllAdminData();
	}

	dropDownConfig() {
		this.router.navigate(['/drop-down-configuration']);
	}

	semiconductorSetup() {
		this.router.navigate(['/semiconductor-supplier-setup']);
	}
}
