import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Tier1EComponentShortageTracker} from '../model/tier1-e-component-shortage-tracker';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DropDown} from '../model/drop-down';

@Injectable({
	providedIn: 'root'
})
export class Tier1EComponentShortageTrackerService {
	private TRACKER_ENDPOINT = environment.apiUrl + 'tier1EComponentShortageTracker';
	private HELP_ENDPOINT = environment.apiUrl + 'helpContent/download/';

	constructor(private http: HttpClient) {
	}

	public helpEndPointDownload(temp: any): Observable<any> {
		return this.http.get(this.HELP_ENDPOINT + temp, {responseType: 'blob'});
	}

	public getTier1SupplierNameFromEmail(email: string): Observable<any> {
		return this.http.get(this.TRACKER_ENDPOINT + `/getTier1SupplierNameFromEmail/${email}`, {responseType: 'json'});
	}

	public getAllTier1ModuleSupplierForForduser(): Observable<any> {
		return this.http.get(this.TRACKER_ENDPOINT + `/getAllTier1SupplierList`, {responseType: 'json'});
	}

	public getSemiconductorSupplierFromTier1Supplier(tier1EComponentShortageTracker: Tier1EComponentShortageTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.TRACKER_ENDPOINT + `/getSemiconductorSupplierNameFromTier1SupplierName`, tier1EComponentShortageTracker, {responseType: 'json'});
	}

	generateTemplateForSupplier(tier1EComponentShortageTracker: Tier1EComponentShortageTracker) {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.TRACKER_ENDPOINT + `/dashboard/downloadDashboard`, tier1EComponentShortageTracker, {responseType: 'blob'});
	}

	loadT1ModuleSupplierNames(): Observable<any> {
		return this.http.get(this.TRACKER_ENDPOINT + '/t1ModuleSupplierNames', {responseType: 'json'});
	}

	loadGsdbCodes(): Observable<any> {
		return this.http.get(this.TRACKER_ENDPOINT + '/gsdbCodes', {responseType: 'json'});
	}

	loadSemiconductorSupplierNames() {
		return this.http.get(this.TRACKER_ENDPOINT + '/semiConductorSupplierNames', {responseType: 'json'});
	}

	loadSemiconductorSupplierNamesBasedOnT1ModuleSupplierNames(tier1EComponentShortageTracker: Tier1EComponentShortageTracker) {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.TRACKER_ENDPOINT + '/semiConductorSupplierNamesFromT1ModuleSupplierNames', tier1EComponentShortageTracker, {responseType: 'json'});
	}

	loadGsdbCodesBasedOnT1ModuleSupplierNames(tier1EComponentShortageTracker: Tier1EComponentShortageTracker) {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.TRACKER_ENDPOINT + '/gsdbCodesFromT1ModuleSupplierNames', tier1EComponentShortageTracker, {responseType: 'json'});
	}

	loadSemiconductorSupplierNamesBasedOnT1ModuleSupplierNamesAndGsdbCode(tier1EComponentShortageTracker: Tier1EComponentShortageTracker) {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.TRACKER_ENDPOINT + '/semiConductorSupplierNamesFromT1ModuleSupplierNamesAndGsdbCodes', tier1EComponentShortageTracker, {responseType: 'json'});
	}

	downLoadFile(data: any, type: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = 'E-Component_Shortage.xlsx';
		anchor.href = url;
		anchor.click();
	}

	downloadFileMp4(data: any, type: string, fileName: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = fileName + '.mp4';
		anchor.href = url;
		anchor.click();
	}

	downLoadFileDocument(data: any, type: string, fileName: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = fileName + '.docx';
		anchor.href = url;
		anchor.click();
	}

	downLoadFileDocumentInPdf(data: any, type: string, fileName: string) {
		const blob = new Blob([data], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = fileName + '.pdf';
		anchor.href = url;
		anchor.click();
	}

	generateReport(tier1EComponentShortageTracker: Tier1EComponentShortageTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.TRACKER_ENDPOINT + '/dashboard' + '/generateReport', tier1EComponentShortageTracker, {responseType: 'blob'});
	}
	findAllDropDownData(columnName: string): Observable<any> {
		return this.http.get(this.TRACKER_ENDPOINT + `/findAllDropDownData/${columnName}`, {responseType: 'json'});
	}
	createDropDownData(dropDown: DropDown): Observable<any> {
		return this.http.post(this.TRACKER_ENDPOINT + `/createDropDownData`, dropDown, {responseType: 'json'});
	}
	updateDropDownData(dropDown: DropDown): Observable<any> {
		return this.http.put(this.TRACKER_ENDPOINT + `/updateDropDownData`, dropDown, {responseType: 'json'});
	}
	deleteDropDownData(ids: string): Observable<any> {
		return this.http.delete(this.TRACKER_ENDPOINT + `/deleteDropDownData/${ids}`, {responseType: 'json'});
	}
	loadAllId(): Observable<any> {
		return this.http.get(this.TRACKER_ENDPOINT + '/getAllIdsForDeletion', {responseType: 'json'});
	}

	removeDataBasedOnIdService(ids: string): Observable<any> {
		return this.http.delete(this.TRACKER_ENDPOINT + `/removeDataBasedonId/${ids}`, {responseType: 'json'});
	}

	public getSemiconductorSupplierNameFromEmail(email: string): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.get(this.TRACKER_ENDPOINT + `/admin/semiconductorSupplier/getSemiconductorSupplierNameFromEmail/${email}`, {responseType: 'json'});
	}

	generateSemiconductorSupplierReport(tier1EComponentShortageTracker: Tier1EComponentShortageTracker): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.TRACKER_ENDPOINT + '/dashboard' + '/generateSemiconductorSupplierReport', tier1EComponentShortageTracker, {responseType: 'blob'});
	}

	removeEComponentDuplicates(tier1EComponentShortageTracker: Tier1EComponentShortageTracker) {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.TRACKER_ENDPOINT + `/dashboard/removeEComponentDuplicatesAdhoc`, tier1EComponentShortageTracker, {responseType: 'blob'});
	}
	displayEComponentDuplicatesService(tier1EComponentShortageTracker: Tier1EComponentShortageTracker) {
		// tslint:disable-next-line:max-line-length
		return this.http.post(this.TRACKER_ENDPOINT + `/dashboard/displayEComponentDuplicates`, tier1EComponentShortageTracker, {responseType: 'json'});
	}
}
