import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Supplier} from '../model/supplier.model';
import {map} from 'rxjs/operators';
import {
	Observable,
	of,
	Subject
} from 'rxjs';
import {Option} from '../../home/model/ntier.option';

@Injectable()
export class SupplierService {
	private SUPPLIER_ENDPOINT = environment.apiUrl + 'suppliers';
	private LRSUPPLIER_ENDPOINT = environment.apiUrl + 'lrsuppliers';
	private SUPPLIER_OPTIONS_ENDPOINT = environment.apiUrl + 'suppliers/options/';
	private LRSUPPLIER_OPTIONS_ENDPOINT = environment.apiUrl + 'lrsuppliers/options/';
	selectedMenuSubject = new Subject<string>();
	/** Creates a new instance of the SupplierService
	 *
	 * @param http used for calls to the agent API
	 * @param messageService used to display success/failure messages
	 * @param errorHandler handles generic http request errors
	 */
	constructor(private http: HttpClient) {
	}

	pushSelectedSurvey(selectedSurvey: string) {
		this.selectedMenuSubject.next(selectedSurvey);
	}

	/** returns a supplier object based on the supplied GSDB code
	 *
	 * @param gsdbCode the  GSDB code to lookup the supplier
	 * @returns a http response
	 */
	get(gsdbCode: string): Observable<Supplier> {
		// Send the supplier to backend API - HTTP-POST
		return this.http.get(this.SUPPLIER_ENDPOINT + '/' + gsdbCode, Object.assign({responseType: 'json'}))
			.pipe(
				map((supplierResponse) => {
					return supplierResponse['result'].supplier as Supplier;
				})
			);
	}

	/** saves a supplier object, could be update or create based on whether the supplier object has
	 * a UUID already or not (uuid is created by backend)
	 * @param supplier the supplier object to save
	 * @param shouldUpdate boolean to determine if the supplier should be updated
	 * @returns a http response
	 */
	save(isLaunchSurvey: boolean, supplier: Supplier, shouldUpdate: boolean): Observable<Supplier> {
		let URL_ENDPOINT: string;
		if ( isLaunchSurvey) {
			URL_ENDPOINT = this.LRSUPPLIER_ENDPOINT;
		} else {
			URL_ENDPOINT = this.SUPPLIER_ENDPOINT;
		}
		if (supplier.supplierId) {
			if (shouldUpdate) {
				// Send the supplier to backend API - HTTP-POST
				return this.http.put(URL_ENDPOINT, supplier, Object.assign({responseType: 'json'}))
					.pipe(
						map((supplierResponse) => {
							return supplierResponse['result'].supplier as Supplier;
						})
					);
			} else {
				return of(supplier);
			}
		} else {
			// Send the supplier to backend API - HTTP-POST
			return this.http.post(URL_ENDPOINT, supplier, Object.assign({responseType: 'json'}))
				.pipe(
					map((supplierResponse) => {
						return supplierResponse['result'].supplier as Supplier;
					})
				);
		}
	}

	getPlantOptions(): Observable<Option[]> {
		return this.http.get(this.SUPPLIER_OPTIONS_ENDPOINT + 'plant').pipe(map((options: Option[]) => {
			return options.map(option => {
				return new Option(option);
			});
		}));
	}

	getVlineOptions(): Observable<Option[]> {
		return this.http.get(this.SUPPLIER_OPTIONS_ENDPOINT + 'vline').pipe(map((options: Option[]) => {
			return options.map(option => {
				return new Option(option);
			});
		}));
	}

	//Lanucn Survey Methods below
	//Getting Supplier details based on GSDB and Commotity selected
	getLrSupplier(gsdbCode: string, commodityCode: string): Observable<Supplier> {
		// Send the supplier to backend API - HTTP-POST
		return this.http.get(this.LRSUPPLIER_ENDPOINT + '/' + gsdbCode + '/commodities/' + commodityCode , Object.assign({responseType: 'json'}))
			.pipe(
				map((supplierResponse) => {
					return supplierResponse['result'].supplier as Supplier;
				})
			);
	}

	// Loading Program Supported option list
	// commented since, program supported fields got from getSupplier call using externa service (purchasing-service)
	/*getProgramsSupportedOptions(): Observable<NtierOption[]> {
		return this.http.get(this.LRSUPPLIER_OPTIONS_ENDPOINT + 'programsSupported').pipe(map((options: NtierOption[]) => {
			return options.map(option => {
				return new NtierOption(option);
			});
		}));
	}*/
}
