import {Injectable} from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot
} from '@angular/router';
import {UserIdService} from '../services/user-id.service';

/**
 * This guard is used across all app routes that require live data,
 * determining if the auth token is valid before allowing navigation to occur
 */
@Injectable()
export class AuthGuardService implements CanActivate {

	/** Creates an instance of the AuthGuardService
	 *
	 * @param router used for navigation actions
	 */
	constructor(private router: Router, private idService: UserIdService) {
	}

	/** the actual guard for routing. Uses the current token information to determine if the user needs to go through the login flow again
	 *
	 * @param route the snapshot of the current ActivatedRoute
	 * @param state the snapshot of the current RouterState
	 * @returns whether route can be activated or not
	 */
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		// Store state url in sessionStorage for later redirect by callback component

		sessionStorage['redirectURL'] = state.url;

		// if(sessionStorage.getItem('uid').includes('-')) {
			if (state.url === '/sustainability-survey') {
				sessionStorage.setItem('Admin', 'sustainability survey');
			} else if (state.url === '/project-r-non-microchip') {
				sessionStorage.setItem('Admin', 'Project R');

				//todo Following is the T2V Data Refresh Decommission commented code
				// } else if (state.url === '/t2-visibility-data-refresh-spreedsheet') {
			// 	sessionStorage.setItem('Admin', 'T2Visibility Data Refresh');
				// }else if (state.url === '/t2-visibility-data-refresh') {
				// 	sessionStorage.setItem('Admin', 'T2Visibility Data Refresh');

				// } else if (state.url === '/t2-visibility-dashboard') {
			// 	sessionStorage.setItem('Admin', 'T2Visibility');

			}else if (state.url === '/projectRMicrochip') {
				sessionStorage.setItem('Admin', 'Project-R Microchip');
			}
		// }
		return this.checkLogin();
	}
	/** Function used to determine if the current token is valid. Checks token expiration against the current timestamp
	 * @returns whether or not the token is expired
	 */
	isTokenExpired(): boolean {
		const epoch = Math.trunc(new Date().getTime() / 1000);
		let expEpoch = null;

		if (sessionStorage.getItem('tokenExp') && sessionStorage.getItem('tokenExp') !== 'null') {
			expEpoch = parseInt(sessionStorage.getItem('tokenExp'), 10);
			return (epoch >= expEpoch);
		} else {
			return true;
		}
	}

	/** Uses isTokenExpired() to determine if the user credentials should be cleared and the user forwarded to the login component
	 * @returns for whether the user is "logged in" or not
	 */
	checkLogin(): boolean {
		const browserName = this.getBrowserName();
		console.log('Browser Name is:-   ' + browserName);
		if ('chrome' === browserName || 'edg' === browserName || 'edge' === browserName || 'firefox' === browserName) {
			// Check if token is expired (or null)
			if (this.isTokenExpired()) {
				// Clear oauth session items
				sessionStorage.removeItem('tokenExp');
				sessionStorage.removeItem('tokenIssue');
				sessionStorage.removeItem('strAccessToken');
				sessionStorage.removeItem('encodedAccessToken');
				this.idService.setUserID('Not Logged In');

				// Navigate to the login page with extras
				console.log('============Navigating to Login');
				this.router.navigate(['/login']);

				return false; // guard complete and then router redirects to /login
			}
		} else {
			console.log('Its not chrome. Please use Chrome');
			this.router.navigate(['/browser-incompatable']);
		}
		return true;
	}

	public getBrowserName() {
		const agent = window.navigator.userAgent.toLowerCase();
		switch (true) {
			case agent.indexOf('edg') > -1:
				return 'edg';
			case agent.indexOf('edge') > -1:
				return 'edge';
			case agent.indexOf('opr') > -1 && !!(window as any).opr:
				return 'opera';
			case agent.indexOf('chrome') > -1 && !!(window as any).chrome:
				return 'chrome';
			case agent.indexOf('trident') > -1:
				return 'ie';
			case agent.indexOf('firefox') > -1:
				return 'firefox';
			case agent.indexOf('safari') > -1:
				return 'safari';
			default:
				return 'other';
		}
	}
}
