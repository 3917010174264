export class T2VisibilityAdmin {
	id: number;
	subject: string;
	startDate: Date;
	endDate: Date;
	createdBy: string;
	createdOn: Date;
	updatedBy: string;
	updatedOn: Date;
	constructor(t2VisibilityAdmin: T2VisibilityAdmin) {
		Object.assign(this, t2VisibilityAdmin);
	}
}

/* tslint:disable */
export const emptyT2Admin = {
	'id': 0,
	'subject':'',
	'startDate': new Date(),
	'endDate': new Date(),
	'createdBy': '',
	'createdOn': new Date(),
	'updatedBy': '',
	'updatedOn': new Date()
};
