import {
	Component,
	OnInit,
	ViewChild
} from '@angular/core';
import {Router} from '@angular/router';
import {ValueStreamDataService} from '../service/value-stream-data.service';
import {Table} from 'primeng/table';
import {SupplierService} from '../../../home/services/supplier.service';
import {NTierMasterAdminService} from '../../n-tier-master-admin/service/n-tier-master-admin.service';

@Component({
	selector: 'value-stream-user-report',
	templateUrl: './value-stream-user-report.component.html',
	styleUrls: ['./value-stream-user-report.component.scss']
})
export class ValueStreamUserReportComponent implements OnInit {

	@ViewChild('dt1', {static: true}) private dataTable: Table;
	vsmTemplateUsers: any = [];
	isDisplayFilterData = false;
	page = 0;
	pageSize = 10;
	filterValue = '';
	totalPageCount: any = 1.0;

	// tslint:disable-next-line:max-line-length
	constructor(private masterAdminService: NTierMasterAdminService, private supplierService: SupplierService, private router: Router, private valueStreamDataService: ValueStreamDataService) {
	}

	ngOnInit() {
		this.router.navigate(['/']);
		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
			return;
		} else if (sessionStorage.getItem('uid').includes('-')) {
			this.router.navigate(['/']);
			return;
		}
		// this.supplierService.pushSelectedSurvey('VSM');
		this.getVsmUsers();
	}

	getVsmUsers() {
		this.valueStreamDataService.getAllVsmUsers(this.page, this.pageSize).subscribe((res: any) => {
			if (res[0]['count'] > this.pageSize) {
				this.totalPageCount = Math.ceil(res[0]['count'] / this.pageSize);
			}
			this.vsmTemplateUsers = res;
		});
	}

	showDataFilters() {
		this.isDisplayFilterData = !this.isDisplayFilterData;
		this.dataTable.reset();
	}

	cleareDataFilters() {
		this.isDisplayFilterData = false;
		this.filterValue = '';
		this.dataTable.reset();
		this.getVsmUsers();
		// this.ddRequestTypes.resetFilter();
	}

	nextPage() {
		if (this.totalPageCount === this.page + 1) {
		} else if (this.filterValue) {
			this.page++;
			this.filterWithValue();
		} else {
			this.page++;
			this.getVsmUsers();
		}
	}

	previousPage() {

		if (this.page + 1 === 1) {

		} else if (this.filterValue) {
			this.page--;
			this.filterWithValue();
		} else {
			this.page--;
			this.getVsmUsers();
		}
	}

	filter() {
		this.totalPageCount = 1.0;
		this.page = 0;
		if (this.filterValue === '') {
			this.filterValue = '';
			this.getVsmUsers();
		} else {
			this.filterWithValue();
		}
	}

	filterWithValue() {
		this.valueStreamDataService.vsmUserFilter(this.filterValue, this.page, this.pageSize).subscribe((res: any) => {
			this.vsmTemplateUsers = [];
			if (res[0]['count'] > this.pageSize) {
				this.totalPageCount = Math.ceil(res[0]['count'] / this.pageSize);
			}
			this.vsmTemplateUsers = res;
		});
	}

}
