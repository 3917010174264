export class T2VisibilityEmailTrigger {

	templateName: any;
	condition: any;
	shipSiteCodes: any;
	businessName: any;
	supplierNames: any;

	constructor(t2VisibilityEmailTrigger: {
		templateName: string;
		condition: string;
		shipSiteCodes: string;
		businessName: string;
		supplierNames: string
	}) {
		Object.assign(this, t2VisibilityEmailTrigger);
	}
}

export const emptyT2EmailTrigger = {
	templateName: '',
	condition: '',
	shipSiteCodes: '',
	businessName: '',
	supplierNames: '',
};
