export class ClearToBuildScheduler {
	id: number;
	weekDay: string;
	hours: number;
	minutes: number;
	part: string;
	clearToBuildIds: string;
	status: boolean;
	statusText: string;
	createdBy: string;
	createdDate: Date;
	updatedBy: string;
	updatedDate: Date;

	constructor(clearToBuildScheduler: ClearToBuildScheduler) {
		Object.assign(this, clearToBuildScheduler);
	}
}

/* tslint:disable */
export const emptyClearToBuildScheduler = {
	'id': 0,
	'weekDay': '',
	'hours': 0,
	'minutes': 0,
	'part': '',
	'clearToBuildIds': '',
	'status': true,
	'statusText': '',
	'createdBy': '',
	'createdDate': new Date(),
	'updatedBy': '',
	'updatedDate': new Date()
};
