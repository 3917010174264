import {
	Component,
	OnInit
} from '@angular/core';
import {
	DynamicDialogConfig,
	DynamicDialogRef
} from 'primeng/api';

@Component({
	selector: 'custom-dialog',
	templateUrl: './custom-dialog.component.html',
	styleUrls: ['./custom-dialog.component.scss']
})
export class CustomDialogComponent implements OnInit {

	constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
	}

	dialogContent: string;
	msgList = [];

	ngOnInit() {
		this.dialogContent = this.config.data;
		this.msgList = this.dialogContent.split(';;;');
	}
	closeDialog() {
		this.ref.close();
	}
}
