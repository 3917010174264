import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AccordionModule} from 'primeng/accordion';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {MessageModule} from 'primeng/message';
import {ListboxModule} from 'primeng/listbox';
import {DropdownModule} from 'primeng/dropdown';
import {CheckboxModule} from 'primeng/checkbox';
import {Tier1EComponentShortageRoutingModule} from './tier1-e-component-shortage-routing.module';
// tslint:disable-next-line:max-line-length
import {Tier1EComponentShortageComponent} from './tier1-e-component-shortage/tier1-e-component-shortage.component';
import {TableModule} from 'primeng/table';
import {Tier1EComponentShortageAdminComponent} from './tier1-e-component-shortage-admin/tier1-e-component-shortage-admin.component';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputTextModule} from 'primeng/inputtext';
// tslint:disable-next-line:max-line-length
import {Tier1EComponentShortageDashboardComponent} from './tier1-e-component-shortage-dashboard/tier1-e-component-shortage-dashboard.component';
import {NTierMasterAdminComponent} from '../n-tier-master-admin/n-tier-master-admin/n-tier-master-admin.component';
import {Tier1EComponentShortageTrackerService} from './service/tier1-e-component-shortage-tracker.service';
import {DashboardDownloadService} from './service/dashboard-download.service';
import {NTierMasterAdminService} from '../n-tier-master-admin/service/n-tier-master-admin.service';
import {FileUploadModule} from 'primeng/fileupload';
import {DialogModule} from 'primeng/dialog';
import {IgxSpreadsheetCoreModule} from 'igniteui-angular-spreadsheet';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {MultiSelectModule} from 'primeng/multiselect';
import {Tier1EComponenetShortageHelpComponent} from './tier1-ecomponenet-shortage-help/tier1-ecomponenet-shortage-help.component';
import {CardModule} from 'primeng/card';
import {DropDownConfigurationComponent} from './drop-down-configuration/drop-down-configuration.component';
import {TabViewModule} from 'primeng/tabview';
import {ToolbarModule} from 'primeng/toolbar';
import {SemiconductorSupplierSetupComponent} from './semiconductor-supplier-setup/semiconductor-supplier-setup.component';
// tslint:disable-next-line:max-line-length
import {ECompShortageSemiconductorSupplierComponent} from './e-comp-shortage-semiconductor-supplier/e-comp-shortage-semiconductor-supplier.component';

@NgModule({
	declarations: [Tier1EComponentShortageComponent, Tier1EComponentShortageAdminComponent, Tier1EComponentShortageDashboardComponent,
		// tslint:disable-next-line:max-line-length
		NTierMasterAdminComponent, Tier1EComponenetShortageHelpComponent, DropDownConfigurationComponent, SemiconductorSupplierSetupComponent, ECompShortageSemiconductorSupplierComponent],
	imports: [
		CommonModule,
		Tier1EComponentShortageRoutingModule,
		CalendarModule,
		ReactiveFormsModule,
		AutoCompleteModule,
		MessageModule,
		FormsModule,
		ListboxModule,
		ButtonModule,
		DropdownModule,
		CheckboxModule,
		AccordionModule,
		TableModule,
		InputTextareaModule,
		InputTextModule,
		FileUploadModule,
		DialogModule,
		IgxSpreadsheetCoreModule,
		ScrollPanelModule,
		MultiSelectModule,
		CardModule,
		TabViewModule,
		ToolbarModule
	],
	providers: [Tier1EComponentShortageTrackerService, DashboardDownloadService, NTierMasterAdminService]
})
export class Tier1EComponentShortageModule {
}
