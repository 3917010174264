import {
	Component,
	DoCheck,
	VERSION
} from '@angular/core';
import {environment} from '../environments/environment';
import {AuthGuardService} from './oauth/auth-guard.service';
import {SpinnerService} from './services/spinner/spinner.service';
import {Router} from '@angular/router';

/** Class for the app component that is bootstrapped to run the application
 */
@Component({
	selector: 'body',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements DoCheck {
	/** the Angular version */
	version = VERSION.full;
	/** whether we are production or not */
	envProd = environment.production;

	showSpinner = false;
	href: any;

	constructor(private authService: AuthGuardService, public spinnerService: SpinnerService, private router: Router) {
	}

	checkLogin(): boolean {
		return !this.authService.isTokenExpired();
	}

	checkSpinner() {
		return this.spinnerService.spinner.value;
	}

	ngDoCheck() {
		this.href = window.location.href;
	}

	logout() {
		this.router.navigate(['/logout']);
	}
}
