import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

export interface AuthzResponse {
	hasAccess: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class DashboardAuthzService {

	constructor(private http: HttpClient) {}

	getDashboardAuthz(): Observable<AuthzResponse> {
		return this.http.get<any>(environment.apiUrl + 'uisecurity')
			.pipe(
				map((data) => {
					return data.result.dashboardAccessApi as AuthzResponse;
				}));
	}
}
