import {
	Component,
	OnInit,
	ViewChild
} from '@angular/core';
import {Table} from 'primeng/table';
import {Router} from '@angular/router';
import {ValueStreamDataService} from '../service/value-stream-data.service';
import {SupplierService} from '../../../home/services/supplier.service';
import {NTierMasterAdminService} from '../../n-tier-master-admin/service/n-tier-master-admin.service';

@Component({
	selector: 'value-stream-audit-report',
	templateUrl: './value-stream-audit-report.component.html',
	styleUrls: ['./value-stream-audit-report.component.scss']
})
export class ValueStreamAuditReportComponent implements OnInit {

	@ViewChild('dt1', {static: true}) private dataTable: Table;
	vsmTemplateUsers: any = [];
	vsmColumns: any = [];
	isDisplayFilterData = false;
	page = 0;
	pageSize = 10;
	filterValue = '';
	totalPageCount: any = 1.0;
	displayDialogue = false;
	newRecordValue: any;
	oldRecordValue: any;

	// tslint:disable-next-line:max-line-length
	constructor( private masterAdminService: NTierMasterAdminService, private supplierService: SupplierService, private router: Router, private valueStreamDataService: ValueStreamDataService) {
	}

	ngOnInit() {
		this.router.navigate(['/']);
		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
			return;
		} else if (sessionStorage.getItem('uid').includes('-')) {
			this.router.navigate(['/']);
			return;
		}
		// this.supplierService.pushSelectedSurvey('VSM');
		this.getVsmUserAudit();
	}

	getVsmUserAudit() {
		this.valueStreamDataService.getAllVsmUserAudit(this.page, this.pageSize).subscribe((res: any) => {
			if (res[0]['count'] > this.pageSize) {
				this.totalPageCount = Math.ceil(res[0]['count'] / this.pageSize);
			}
			this.vsmTemplateUsers = res;

		});
	}

	showDataFilters() {
		this.isDisplayFilterData = !this.isDisplayFilterData;
		this.dataTable.reset();
	}

	cleareDataFilters() {
		this.isDisplayFilterData = false;
		this.filterValue = '';
		this.dataTable.reset();
		this.getVsmUserAudit();
		// this.ddRequestTypes.resetFilter();

	}

	nextPage() {
		if (this.totalPageCount === this.page + 1) {
		} else if (this.filterValue) {
			this.page++;
			this.filterWithValue();
		} else {
			this.page++;
			this.getVsmUserAudit();
		}
	}

	previousPage() {

		if (this.page + 1 === 1) {

		} else if (this.filterValue) {
			this.page--;
			this.filterWithValue();
		} else {
			this.page--;
			this.getVsmUserAudit();
		}
	}

	filter() {
		this.page = 0;
		this.totalPageCount = 1.0;
		if (this.filterValue === '') {
			this.getVsmUserAudit();
		} else {
			this.filterWithValue();
		}
	}

	filterWithValue() {
		this.valueStreamDataService.vsmUserAuditFilter(this.filterValue, this.page, this.pageSize).subscribe((res: any) => {
			this.vsmTemplateUsers = [];
			if (res[0]['count'] > this.pageSize) {
				this.totalPageCount = Math.ceil(res[0]['count'] / this.pageSize);
			}
			this.vsmTemplateUsers = res;
		});
	}

	clickOperation(product, oldRecord, newRecord, templateId) {
		this.oldRecordValue = null;
		this.newRecordValue = null;
		console.log(product);
		this.newRecordValue = JSON.parse(newRecord);
		console.log('new Record->', this.newRecordValue);
		if (this.newRecordValue.oldRecord) {
			this.oldRecordValue = JSON.parse(this.newRecordValue.oldRecord);
			console.log('Old Record->', this.oldRecordValue);
		} else {
			this.oldRecordValue = JSON.parse(oldRecord);
		}
		this.valueStreamDataService.vsmGetColumnAccess(templateId).subscribe((res: any) => {
			this.vsmColumns = res;
			this.displayDialogue = true;
		});
	}

}
