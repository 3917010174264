import {
	HttpEvent,
	HttpHandler,
	HttpHeaders,
	HttpInterceptor,
	HttpRequest,
	HttpResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {SpinnerService} from './spinner/spinner.service';
import {Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
/** This class is an implementation of an HttpInterceptor
 */
export class HttpRequestInterceptor implements HttpInterceptor {

	constructor(private spinnerService: SpinnerService) {
	}

	/** This method is required by the fact that this class implements HttpInterceptor.
	 * It accepts (intercepts) HttpRequests and adds the headers required by API's for this application.
	 *
	 * @param request the HttpRequest object
	 * @param next the Http handling object
	 * @returns the Observable representing the next HttpEvent
	 */
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = sessionStorage.getItem('encodedAccessToken');
		const authTokenOnly = {
			Authorization: 'Bearer ' + token
		};
		const authTokenAndContentType = {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + token
		};

		function isUploadTemplateEndpoint() {
			// tslint:disable-next-line:max-line-length
			if (request.url.indexOf('t2visibility/upload') !== -1 ||
				request.url.indexOf('valuestreamdata/vsm-user-column/upload') !== -1 ||
				request.url.indexOf('vsm/srd/vsm-user-column/upload') !== -1 ||
				request.url.indexOf('vsm-user-column/upload') !== -1 ||
				request.url.indexOf('tier2/excelUpload') !== -1 ||
				request.url.indexOf('/files') !== -1 ||
				request.url.indexOf('tigerSharkConstraintTracker/dashboard/upload') !== -1 ||
				request.url.indexOf('tier1EComponentShortageTracker/dashboard/upload') !== -1 ||
				request.url.indexOf('tier1EComponentShortageTracker/dashboard/superAdminUpload') !== -1 ||
				request.url.indexOf('edppAdminReport/update') !== -1 ||
				request.url.indexOf('ss/survey/part-excel/update-create') !== -1 ||
				request.url.indexOf('ss/survey/supplier-excel/update-create') !== -1 ||
				request.url.indexOf('clearToBuildTracker/fordUserUpload') !== -1 ||
				request.url.indexOf('clearToBuildAdmin/update') !== -1 ||
				request.url.indexOf('clearToBuildAdmin/updateShortfallSupplierData') !== -1 ||
				request.url.indexOf('clearToBuildTracker/supplierUpload') !== -1 ||
				request.url.indexOf('ss/docs/evidence/upload') !== -1 ||
				request.url.indexOf('vsm/part-excel/update-create') !== -1 ||
				request.url.indexOf('vsm/supplier-excel/update-create') !== -1 ||
				request.url.indexOf('vsm/ebom/excel/update-create') !== -1 ||
				request.url.indexOf('vsm/mpn/createExcel') !== -1 ||
				request.url.indexOf('/t2VisibilityRefresh/upload') !== -1 ||
				request.url.indexOf('/r/survey/uvsm') !== -1 ) {
				return true;
			} else {
				return false;
			}
		}

		function buildHeaders(uploadFileHeader, defaultHeader) {
			return isUploadTemplateEndpoint() ? uploadFileHeader : defaultHeader;
		}

		function getHeaders() {
			return buildHeaders(authTokenOnly, authTokenAndContentType);
		}

		request = request.clone({
			headers: new HttpHeaders(getHeaders())
		});

		// request = request.clone({
		// 	setHeaders: {
		// 		Authorization: 'Bearer ' + sessionStorage.getItem('encodedAccessToken'),
		// 		'Content-Type': 'application/json',
		// 		Y7fQPGwn: '1'
		// 	}
		// });
		this.spinnerService.show();
		return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					console.log('hide spinner');
					this.spinnerService.hide();
				}
			}, (error1) => {
				console.log('error: hide spinner');
				this.spinnerService.hide();
			}
		));
	}
}
