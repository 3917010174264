import {
	Component,
	OnInit
} from '@angular/core';
import {DashboardDownloadService} from '../service/dashboard-download.service';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {Router} from '@angular/router';
import {NTierMasterAdminService} from '../../n-tier-master-admin/service/n-tier-master-admin.service';
import {Tier1EComponentShortageTracker} from '../model/tier1-e-component-shortage-tracker';
import {DatePipe} from '@angular/common';
import {SupplierService} from '../../../home/services/supplier.service';

@Component({
	selector: 'tier1-e-component-shortage-dashboard',
	templateUrl: './tier1-e-component-shortage-dashboard.component.html',
	styleUrls: ['./tier1-e-component-shortage-dashboard.component.scss']
})
export class Tier1EComponentShortageDashboardComponent implements OnInit {

	// tslint:disable-next-line:max-line-length
	constructor(private supplierService: SupplierService, private downloadServiceObj: DashboardDownloadService, private datepipe: DatePipe, private spinnerService: SpinnerService, private router: Router, private masterAdminService: NTierMasterAdminService) {
	}

	ngOnInit() {
		this.router.navigate(['/']);
		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
			return;
		}  else if (sessionStorage.getItem('uid').includes('-')) {
			this.router.navigate(['/']);
			return;
		}
		// this.supplierService.pushSelectedSurvey('SemiSupp');
	}

	getCurrentWeekNumber(): number {
		const currentDate = new Date();
		const oneJan = new Date(currentDate.getFullYear(), 0, 1);
		// @ts-ignore
		const numberOfDays = Math.floor((currentDate - oneJan) / (24 * 60 * 60 * 1000));
		const weekNumber = Math.ceil((currentDate.getDay() + 1 + numberOfDays) / 7);
		return weekNumber;
	}

	private getMondayFromCurrentDate(): Date {
		const currentDate = new Date();
		const currentDay = currentDate.getDay();
		const diff = currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1); // adjust when day is sunday
		return new Date(currentDate.setDate(diff));
	}

	downloadSurveyInExcel() {
		const tier1EComponentShortageTrackerBusiness = new Tier1EComponentShortageTracker();
		// tslint:disable-next-line:max-line-length
		tier1EComponentShortageTrackerBusiness.supplierEmailId = sessionStorage.getItem('uid');
		tier1EComponentShortageTrackerBusiness.weekNumber = this.getCurrentWeekNumber();
		// @ts-ignore
		tier1EComponentShortageTrackerBusiness.weekDate = this.datepipe.transform(this.getMondayFromCurrentDate(), 'yyyy-MM-dd');
		console.log('weekdate', tier1EComponentShortageTrackerBusiness.weekDate);
		this.downloadServiceObj.downloadSurveyInExcel(tier1EComponentShortageTrackerBusiness);
		this.spinnerService.show();
	}

}
