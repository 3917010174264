import {
	Component,
	OnInit
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	Validators
} from '@angular/forms';
import {NTierMasterAdminService} from '../../n-tier-master-admin/service/n-tier-master-admin.service';
import {Router} from '@angular/router';
import {
	ConfirmationService,
	MessageService
} from 'primeng/api';
import {ValueStreamDataService} from '../service/value-stream-data.service';
import {VsmColumn} from '../model/vsm-column';
import {Option} from '../../../home/model/ntier.option';
import {SupplierService} from '../../../home/services/supplier.service';

@Component({
	selector: 'value-stream-data-admin-column-access',
	templateUrl: './value-stream-data-admin-column-access.component.html',
	styleUrls: ['./value-stream-data-admin-column-access.component.scss'],
	providers: [ConfirmationService]
})
export class ValueStreamDataAdminColumnAccessComponent implements OnInit {
	masterAdminForm: FormGroup;
	options: Option[] = [];
	sources: Option[] = [];
	insertSource: Option[] = [];
	userLoginId: string;
	columnAccessList: any = [];
	columnAccess = [];
	viewType = 'cdsid';
	templateList: any = [];
	supplierList: any[];
	supplierIds: any[] = [];
	selectedTemplate: any;
	isEdit = false;
	userAssignTemplateList: any = [];
	tntvAdminUserSource = false;
	tier1SupplierDatas: any[] = [];
	tier1SupplierList: any[] = [];
	grantBtnDisabled = false;
	editBtnDisabled = false;
	dataSource: any = null;
	subDataSource: any = null;
	editSource: any = null;
	editSubSource: any = null;
	tier1FordPartNumberList: any[] = [];
	tier1FordPartNumberDatas: any[] = [];
	modifyAccess = false;
	insertAccess = false;
	// tslint:disable-next-line:max-line-length
	insertAccessValue = '';
	// tslint:disable-next-line:max-line-length
	constructor(private supplierService: SupplierService, private fb: FormBuilder, private masterAdminService: NTierMasterAdminService, private router: Router, private valueStreamDataService: ValueStreamDataService, private confirmationService: ConfirmationService, private messageService: MessageService) {
	}

	ngOnInit() {
		this.router.navigate(['/']);
		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
			return;
		} else if (sessionStorage.getItem('uid').includes('-')) {
			this.router.navigate(['/']);
			return;
		}
		// this.supplierService.pushSelectedSurvey('VSM');
		this.options = [
			{name: 'CDSID', code: 'cdsid'},
			{name: 'Email', code: 'email'}];
		this.insertSource = [
			{name: 'Yes', code: 'Yes'},
			{name: 'No', code: 'No'}];
		this.sources = [
			{name: 'All', code: 'ALL'},
			{name: 'COE', code: 'COE'},
			{name: 'Caresoft', code: 'CARESOFT'},
			{name: 'Supplier BOMs', code: 'SUPPLIERBOM'},
			{name: 'Semi Conductor Data', code: 'SEMICONDUCTORDATA'},
			{name: 'Inhouse BOMs', code: 'INHOUSEBOM'},
			{name: 'Silicon Expert', code: 'SILICONEXPERT'},
		];
		this.userLoginId = sessionStorage.getItem('uid');
		this.masterAdminForm = this.fb.group({
			tntvAdminUserId: [{value: '', disabled: false}, Validators.required],
			tntvAdminUserType: [{value: this.options[0], disabled: false}, Validators.required],
			tntvVsmTemplateId: [{value: '', disabled: false}, Validators.required],
			tntvAdminUserSource: [{value: '', disabled: false}, Validators.required],
			tntvAdminUserInsertAccess: [{value: '', disabled: false}, Validators.required],
			tntvSupplierIds: [{value: '', disabled: false}],
			columnAccess: [{value: '', disabled: false}, Validators.required]
		});
		this.getAllTemplate();
		this.getAllColumn();
	}

	selectAllModifyAccess() {
		for (const item of this.columnAccess) {
			item.writeAccess = !this.modifyAccess;
		}
	}

	selectAllInsertAccess() {
		for (const item of this.columnAccess) {
			item.insertAccess = !this.insertAccess;
		}
	}

	onFordPartNumberChange(event: any) {
		this.getAllFordPartNumberSupplier();
	}

	private getAllFordPartNumberSupplier() {
		if (this.tier1SupplierDatas.length > 0) {
			const arrayList = [];
			for (const item of this.tier1SupplierDatas) {
				arrayList.push(item.name);
			}
			// tslint:disable-next-line:max-line-length
			this.valueStreamDataService.getAllFordPartNumberSupplier(this.selectedTemplate.tntvDataSource, this.selectedTemplate.tntvSubDataSource, arrayList.join('|')).subscribe((res: any) => {
				this.tier1FordPartNumberList = res;
			});
		}
	}

	private getAllTemplate() {
		this.valueStreamDataService.getVsmTemplate().subscribe((res: any) => {
			this.templateList = res;
		});
	}

	onColumnAccess(event) {
		this.columnAccess = event.value;
	}

	changeType(event) {
		this.viewType = event.value.code;
		if (this.viewType === 'email') {
			this.masterAdminForm.get('tntvAdminUserId').setValidators([Validators.required, Validators.email]);
		} else {
			this.masterAdminForm.get('tntvAdminUserId').setValidators([Validators.required]);
		}
		this.masterAdminForm.get('tntvAdminUserId').updateValueAndValidity();
	}

	getAllColumn() {
		this.valueStreamDataService.getAccessColumn().subscribe((res: VsmColumn) => {
			this.columnAccessList = res;
			for (const item of this.columnAccessList) {
				item.readAccess = true;
				item.writeAccess = false;
			}
		});
	}

	onSubmit() {
		if (this.masterAdminForm.valid) {
			if (this.masterAdminForm.controls['tntvAdminUserId'].value.includes('-')) {
				this.pushMessage('error', 'Error', 'Please enter CDSID of type Ford User');
			} else {
				const form: any = this.masterAdminForm.getRawValue();
				form.tntvAdminUserType = form.tntvAdminUserType.code;
				form.tntvAdminUserSource = form.tntvAdminUserSource.code;
				form.tntvAdminUserInsertAccess = form.tntvAdminUserInsertAccess.code;
				form.tntvVsmTemplateId = form.tntvVsmTemplateId.id;
				form.columnAccess = form.columnAccess;
				if (form.tntvSupplierIds && form.tntvSupplierIds.length > 0) {
					const arrayList = [];
					for (const item of form.tntvSupplierIds) {
						arrayList.push(item.name);
					}
					form.tntvSupplierIds = arrayList.join('|');
				} else {
					form.tntvSupplierIds = '';
				}
				this.valueStreamDataService.createUserAccessColumn(form).subscribe(() => {
					this.onReset();
					this.pushMessage('success', 'Success',
						'Column access has been granted successfully');
				}, (() => {
					this.pushMessage('error', 'Error', 'Unable to grant access, Please try again later');
				}));
			}
			this.onResetAccess();
		} else {
			this.pushMessage('error', 'Error', 'Please enter CDSID of Ford User and Select the column access');
		}
	}

	onEditSubmit() {
		if (this.masterAdminForm.valid) {
			if (this.masterAdminForm.controls['tntvAdminUserId'].value.includes('-')) {
				this.pushMessage('error', 'Error', 'Please enter CDSID of type Ford User');
			} else {
				const form: any = this.masterAdminForm.getRawValue();
				form.tntvAdminUserType = form.tntvAdminUserType.code;
				form.tntvAdminUserSource = form.tntvAdminUserSource.code;
				form.tntvAdminUserInsertAccess = form.tntvAdminUserInsertAccess.code;
				form.tntvVsmTemplateId = form.tntvVsmTemplateId.id;
				form.columnAccess = form.columnAccess;
				if (form.tntvSupplierIds && form.tntvSupplierIds.length > 0) {
					const arrayList = [];
					for (const item of form.tntvSupplierIds) {
						arrayList.push(item.name);
					}
					form.tntvSupplierIds = arrayList.join('|');
				} else {
					form.tntvSupplierIds = '';
				}
				console.log(form);
				this.valueStreamDataService.updateUserAccessColumn(form).subscribe(() => {
					this.grantBtnDisabled = true;
					this.editBtnDisabled = false;
					this.onReset();
					this.pushMessage('success', 'Success',
						'Column access has been granted successfully');
					this.masterAdminForm.get('tntvVsmTemplateId').enable();
					this.masterAdminForm.get('tntvAdminUserSource').enable();
					this.masterAdminForm.get('tntvVsmTemplateId').reset();
					this.masterAdminForm.get('tntvAdminUserSource').reset();
					this.onResetAccess();
				}, (() => {
					this.pushMessage('error', 'Error', 'Unable to grant access, Please try again later');
				}));
			}
		}
	}

	onResetAccess() {
		this.masterAdminForm.reset();
		this.masterAdminForm.get('tntvAdminUserType').setValue(this.options[0]);
		this.viewType = 'cdsid';
		this.columnAccess = [];
		this.dataSource = null;
		this.subDataSource = null;
		this.userAssignTemplateList = [];
		this.masterAdminForm.get('tntvAdminUserId').setValidators([Validators.required]);
		this.masterAdminForm.get('tntvAdminUserId').updateValueAndValidity();
		this.masterAdminForm.get('tntvVsmTemplateId').enable();
		this.masterAdminForm.get('tntvAdminUserSource').enable();
		this.grantBtnDisabled = false;
		this.editBtnDisabled = false;
	}

	onReset() {
		this.masterAdminForm.reset();
		this.masterAdminForm.get('tntvAdminUserType').setValue(this.options[0]);
		this.viewType = 'cdsid';
		this.columnAccess = [];
		this.userAssignTemplateList = [];
		this.masterAdminForm.get('tntvAdminUserId').setValidators([Validators.required]);
		this.masterAdminForm.get('tntvAdminUserId').updateValueAndValidity();
	}

	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 100000
		});
	}

	public getAllUserTemplate(value: any) {
		this.grantBtnDisabled = true;
		this.editBtnDisabled = false;
		this.masterAdminForm.get('tntvVsmTemplateId').reset();
		this.masterAdminForm.get('tntvAdminUserSource').reset();
		this.masterAdminForm.get('tntvVsmTemplateId').enable();
		this.masterAdminForm.get('tntvAdminUserSource').enable();
		this.masterAdminForm.get('tntvAdminUserInsertAccess').reset();
		this.columnAccess = [];
		this.getUserAccess(value.target.value);
	}

	private getUserAccess(value: any) {
		this.valueStreamDataService.getVsmUserTemplate(value).subscribe((res: any) => {
			this.userAssignTemplateList = res;
		});
	}

	onTier1SupplierChange(event: any) {
		this.tier1SupplierDatas = event.value;
		this.getAllICSupplier();
	}

	onVsmTemplateChange(event) {
		this.masterAdminForm.controls.tntvSupplierIds.reset();
		this.selectedTemplate = event.value;
		this.subDataSource = this.selectedTemplate.tntvSubDataSource;
		this.dataSource = this.selectedTemplate.tntvDataSource;
		this.tier1SupplierList = [];
		this.tier1SupplierDatas = [];
		this.supplierList = [];

		for (const item of this.userAssignTemplateList) {
			if (item.id === event.value.id) {
				this.pushMessage('error', 'Error', 'Template already assigned');
				this.masterAdminForm.get('tntvVsmTemplateId').reset();
				return false;
			}
		}
		this.isEdit = true;
		this.tntvAdminUserSource = false;
		this.masterAdminForm.get('tntvAdminUserSource').enable();
		this.selectedTemplate = event.value;
		let source = null;
		for (const item of this.sources) {
			if (this.selectedTemplate.tntvDataSource === item.code) {
				source = item;
			}
		}
		this.masterAdminForm.get('tntvAdminUserSource').setValue(source);
		if (this.selectedTemplate.tntvDataSource !== 'ALL') {
			this.tntvAdminUserSource = true;
			this.masterAdminForm.get('tntvAdminUserSource').disable();
		}
		this.valueStreamDataService.getVsmTemplateColumn(event.value.id).subscribe((res: any) => {
			this.columnAccess = res;
			this.columnAccess = [];
			const columnAccessList = Object.assign([], res);
			for (const columnAccessListElement of columnAccessList) {
				for (const item of this.columnAccessList) {
					if (item.id === columnAccessListElement.tntvColumnId) {
						item.readAccess = columnAccessListElement.tntvReadAccess;
						item.writeAccess = columnAccessListElement.tntvWriteAccess;
						item.insertAccess = columnAccessListElement.tntvInsertAccess;
						item.writeDisabled = item.writeAccess;
						item.insertDisabled = item.insertAccess;
						this.columnAccess.push(item);
					}
				}
			}

			this.masterAdminForm.get('columnAccess').setValue(this.columnAccess);
			console.log(this.masterAdminForm.value);
		});
		this.getAllFilter();
	}

	private getAllFilter() {
		// tslint:disable-next-line:max-line-length
		this.valueStreamDataService.getTemplateAllData(this.selectedTemplate.tntvDataSource, this.selectedTemplate.tntvSubDataSource).subscribe((res: any) => {
			if (this.subDataSource === 'VSM' && this.dataSource === 'COE') {
				this.tier1SupplierList = res.tier1SupplierName;
			} else if (this.subDataSource === 'MPN' && this.dataSource === 'COE') {
				this.tier1SupplierList = res.chipMpnName;
			} else if (this.subDataSource === 'BOM' && this.dataSource === 'COE') {
				this.tier1SupplierList = res.chipMpnName;
			} else if (this.subDataSource === 'NODE' && this.dataSource === 'COE') {
				this.tier1SupplierList = res.chipMpn;
			} else if (this.subDataSource === 'DATA' && this.dataSource === 'CARESOFT') {
				this.tier1SupplierList = res.chipMpnName;
			} else if (this.subDataSource === 'DATA' && this.dataSource === 'SUPPLIERBOM') {
				this.tier1SupplierList = res.chipMpnName;
			} else if (this.subDataSource === 'DATA' && this.dataSource === 'INHOUSEBOM') {
				this.tier1SupplierList = res.chipMpnName;
			} else if (this.subDataSource === 'DATA' && this.dataSource === 'SEMICONDUCTORDATA') {
				this.tier1SupplierList = res.chipMpnName;
			} else if (this.subDataSource === 'DATA' && this.dataSource === 'SILICONEXPERT') {
				this.tier1SupplierList = res.chipMpnName;
			}
			if (this.supplierIds.length > 0) {
				const tntvSupplierIds = [];
				for (const item of this.tier1SupplierList) {
					if (this.supplierIds.indexOf(item.name) !== -1) {
						tntvSupplierIds.push(item);
					}
				}
				this.masterAdminForm.get('tntvSupplierIds').setValue(tntvSupplierIds);
			}
		});
	}

	private getAllSupplier() {
		this.valueStreamDataService.getAllSupplier(this.selectedTemplate.tntvDataSource).subscribe((res: any) => {
			this.supplierList = res;
		});
	}

	private getAllTier1Supplier() {
		this.valueStreamDataService.getAllTier1Supplier(this.selectedTemplate.tntvDataSource).subscribe((res: any) => {
			this.tier1SupplierList = res;
		});
	}

	onRemoveAccess(row: any) {
		console.log(row);
		this.valueStreamDataService.removeUserAccessColumn(this.masterAdminForm.controls['tntvAdminUserId'].value, row.id).subscribe(() => {
			this.pushMessage('success', 'Success',
				'Column Access has been removed successfully');
			this.getUserAccess(this.masterAdminForm.controls['tntvAdminUserId'].value);
		}, (() => {
			this.pushMessage('error', 'Error', 'Unable to remove access, Please try again later');
		}));
	}

	onEditAccess(row: any) {
		this.grantBtnDisabled = false;
		this.editBtnDisabled = true;
		const templateValues = this.templateList.find(template => template.id === row.id);
		this.masterAdminForm.get('tntvVsmTemplateId').setValue(templateValues);
		this.selectedTemplate = templateValues;
		this.subDataSource = this.selectedTemplate.tntvSubDataSource;
		this.dataSource = this.selectedTemplate.tntvDataSource;
		const tntvAdminUserInsertAccess = this.insertSource.find(data => data.code === row.tntvAdminUserInsertAccess);
		let source = null;
		for (const item of this.sources) {
			if (this.selectedTemplate.tntvDataSource === item.code) {
				source = item;
			}
		}
		this.masterAdminForm.get('tntvAdminUserSource').setValue(source);
		this.masterAdminForm.get('tntvAdminUserInsertAccess').setValue(tntvAdminUserInsertAccess);
		this.masterAdminForm.get('tntvVsmTemplateId').disable();
		this.masterAdminForm.get('tntvAdminUserSource').disable();
		this.tier1SupplierList = [];
		this.tier1SupplierDatas = [];
		this.supplierList = [];
		this.supplierIds = [];
		this.supplierIds = row.tntvSupplierIds.split('|');
		this.getAllFilter();
		this.valueStreamDataService.vsmGetColumnAccessBasedOnUser(row.id, this.masterAdminForm.controls['tntvAdminUserId'].value)
			.subscribe((res: any) => {
				// this.columnAccess = res;
				this.columnAccess = [];
				const columnAccessList = Object.assign([], res);
				// console.log(JSON.stringify(columnAccessList));
				// console.log(JSON.stringify(this.columnAccessList));
				this.getAllColumn();
				for (const columnAccessListElement of columnAccessList) {
					for (const item of this.columnAccessList) {
						if (item.id === columnAccessListElement.id) {
							item.readAccess = columnAccessListElement.readAccess;
							item.writeAccess = columnAccessListElement.writeAccess;
							item.insertAccess = columnAccessListElement.insertAccess;
							item.writeDisabled = item.writeAccess;
							item.insertDisabled = item.insertAccess;
							this.columnAccess.push(item);
						}
					}
				}
				this.masterAdminForm.get('columnAccess').setValue(this.columnAccess);
			});
	}

	private getAllICSupplier() {
		if (this.tier1SupplierDatas.length > 0) {
			const arrayList = [];
			for (const item of this.tier1SupplierDatas) {
				arrayList.push(item.name);
			}
			console.log(arrayList);
			this.valueStreamDataService.getAllICSupplier(this.selectedTemplate.source, arrayList.join('|')).subscribe((res: any) => {
				console.log(res);
				this.supplierList = res;
			});
		}
	}

	enableDisableTableCheckBox() {
		const form: any = this.masterAdminForm.getRawValue();
		this.insertAccessValue = form.tntvAdminUserInsertAccess.code;
	}
}
