import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Tier1EComponentShortageAdmin} from '../../tier1-e-component-shortage/model/tier1-e-component-shortage-admin';
import {SupplierService} from '../../../home/services/supplier.service';
import {Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {DashboardDownloadService} from '../../tier1-e-component-shortage/service/dashboard-download.service';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {MessageService} from 'primeng/api';
import {
	Tier1EComponentShortageAdminService
} from '../../tier1-e-component-shortage/service/tier1-e-component-shortage-admin.service';
import {NTierMasterAdminService} from '../../n-tier-master-admin/service/n-tier-master-admin.service';
import {SustainabilitySurveyService} from '../Servies/sustainability-survey.service';

@Component({
	selector: 'sustainability-supplier-access',
	templateUrl: './sustainability-supplier-access.component.html',
	styleUrls: ['./sustainability-supplier-access.component.scss']
})
export class SustainabilitySupplierAccessComponent implements OnInit {
	contactForm: FormGroup;
	contact: any;
	isDisabled = false;
	show = false;
	showInput = true;
	showDropdown = false;
	isReadOnly = false;
	tier1SupplierList = [];
	selectedTier1Supplier: string;
	tier1EComponentShortageAdmin: Tier1EComponentShortageAdmin;
	newTier1EComponentShortageAdmin: Tier1EComponentShortageAdmin;
	createTier1EComponentShortageAdmin: Tier1EComponentShortageAdmin;
	updateTier1EComponentShortageAdmin: Tier1EComponentShortageAdmin;
	retrieve: any;
	isContactFormValid = false;
	isEdit = false;
	isCreate = false;
	adminDataList: Tier1EComponentShortageAdmin[] = [];
	isDisplayFilterData = false;

	// tslint:disable-next-line:max-line-length
	businessName: string;

	// tslint:disable-next-line:max-line-length
	constructor(private supplierService: SupplierService, private fb: FormBuilder, private router: Router, private datepipe: DatePipe, private downloadServiceObj: DashboardDownloadService, private spinnerService: SpinnerService, private messageService: MessageService, private tier1EComponentShortageAdminService: SustainabilitySurveyService, private masterAdminService: NTierMasterAdminService) {
	}

	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 60000
		});
	}

	ngOnInit() {

		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
			return;
		} else if (sessionStorage.getItem('uid').includes('-')) {
			this.router.navigate(['/']);
			return;
		}
		this.businessName = sessionStorage.getItem('Admin');
		if (this.businessName === 'sustainability survey') {
			console.log('sustainability survey');
			this.supplierService.pushSelectedSurvey('sustainability survey');
		} else if (this.businessName === 'Project R') {
			console.log('Project R');
			this.supplierService.pushSelectedSurvey('Project R');
		}

		this.contactForm = this.fb.group({
			tier1SupplierList: [''],
			tier1Supplier: ['', Validators.required],
			commodity: [{value: '', disabled: this.isDisabled}],
			managerEmail: [{value: '', disabled: this.isDisabled}, [Validators.email]],
			gsdbCode: ['', Validators.required],
			buyerEmail1: [{value: '', disabled: this.isDisabled}, [Validators.email]],
			supplierEmailId: [{value: '', disabled: this.isDisabled}, [Validators.required, Validators.email]],
			sources: this.businessName,
			isEvidenceCollectionRequired: [{value: false, disabled: this.isDisabled}],
			isResilienceQuestionnaireEnabled: [{value: false, disabled: this.isDisabled}],
			remarks: [{value: '', disabled: this.isDisabled}]
		});
		this.getTier1SupplierList();
		this.retrieveAllAdminData();
	}

	getTier1SupplierList() {
		this.contactForm.reset();
		this.tier1EComponentShortageAdminService.getAllTier1Suppliers(this.businessName).subscribe((data) => {
			this.tier1SupplierList = data;
		});
	}

	getTier1EComponentShortageAdmin(event) {
		this.selectedTier1Supplier = this.contactForm.get('tier1SupplierList').value.tier1Supplier;
		const tier1EComponentShortageAdmin2 = new Tier1EComponentShortageAdmin();
		tier1EComponentShortageAdmin2.tier1Supplier = this.contactForm.get('tier1SupplierList').value.tier1Supplier;
		tier1EComponentShortageAdmin2.sources = this.businessName;
		this.tier1EComponentShortageAdminService.getContactDataFromTier1Supplier(tier1EComponentShortageAdmin2).subscribe((data) => {
			this.tier1EComponentShortageAdmin = data;
			this.contactForm.patchValue({commodity: this.tier1EComponentShortageAdmin.commodity});
			this.contactForm.patchValue({managerEmail: this.tier1EComponentShortageAdmin.managerEmail});
			this.contactForm.patchValue({buyerEmail1: this.tier1EComponentShortageAdmin.buyerEmail1});
			this.contactForm.patchValue({gsdbCode: this.tier1EComponentShortageAdmin.gsdbCode});
			this.contactForm.patchValue({supplierEmailId: this.tier1EComponentShortageAdmin.supplierEmailId});
			this.contactForm.patchValue({sources: this.businessName});
			this.contactForm.patchValue({remarks: this.tier1EComponentShortageAdmin.remarks});
			this.contactForm.patchValue({isEvidenceCollectionRequired: this.tier1EComponentShortageAdmin.isEvidenceCollectionRequired === 'Y'});
			// tslint:disable-next-line:max-line-length
			this.contactForm.patchValue({isResilienceQuestionnaireEnabled: this.tier1EComponentShortageAdmin.isResilienceQuestionnaireEnabled === 'Y'});
		});
	}

	onUpdate() {
		this.updateTier1EComponentShortageAdmin = this.contactForm.getRawValue() as Tier1EComponentShortageAdmin;
		this.updateTier1EComponentShortageAdmin.id = this.tier1EComponentShortageAdmin.id;
		// tslint:disable-next-line:max-line-length
		this.updateTier1EComponentShortageAdmin.isEvidenceCollectionRequired = this.updateTier1EComponentShortageAdmin.isEvidenceCollectionRequired ? 'Y' : 'N';
		// tslint:disable-next-line:max-line-length
		this.updateTier1EComponentShortageAdmin.isResilienceQuestionnaireEnabled = this.updateTier1EComponentShortageAdmin.isResilienceQuestionnaireEnabled ? 'Y' : 'N';
		console.log(this.updateTier1EComponentShortageAdmin);
		// tslint:disable-next-line:max-line-length
		this.tier1EComponentShortageAdminService.updateTier1EComponentShortageAdmin(this.updateTier1EComponentShortageAdmin).subscribe(response => {
			this.updateTier1EComponentShortageAdmin = response;
			console.log('Success: Sustainability survey Contact Details Id #' + response.id + ' has been Updated successfully');
			// tslint:disable-next-line:max-line-length
			this.pushMessage('success', 'Success', 'Sustainability survey Contact Details for Admin with Id #' + response.id + ' has been Updated successfully');
			this.contactForm.reset();
			this.getTier1SupplierList();
			this.retrieveAllAdminData();
		});

	}

	onCreate() {

		this.createTier1EComponentShortageAdmin = this.contactForm.getRawValue() as Tier1EComponentShortageAdmin;
		if (!this.validateContactDetails(this.createTier1EComponentShortageAdmin)) {
			this.isContactFormValid = false;
			this.pushMessage('error', 'Error', 'Please fill all mandatory fields in Contact Details Form.');
		} else {
			this.isContactFormValid = true;
		}
		if (this.isContactFormValid) {
			// tslint:disable-next-line:max-line-length
			this.createTier1EComponentShortageAdmin.isEvidenceCollectionRequired = this.createTier1EComponentShortageAdmin.isEvidenceCollectionRequired ? 'Y' : 'N';
			// tslint:disable-next-line:max-line-length
			this.createTier1EComponentShortageAdmin.isResilienceQuestionnaireEnabled = this.createTier1EComponentShortageAdmin.isResilienceQuestionnaireEnabled ? 'Y' : 'N';
			this.createTier1EComponentShortageAdmin.sources = this.businessName;
			// tslint:disable-next-line:max-line-length
			this.tier1EComponentShortageAdminService.createTier1EComponentShortageAdmin(this.createTier1EComponentShortageAdmin).subscribe(response => {
				this.createTier1EComponentShortageAdmin = response;
				console.log('Success: Sustainability survey Contact Details Id #' + response.id + ' has been created successfully');
				// tslint:disable-next-line:max-line-length
				this.pushMessage('success', 'Success', 'Sustainability survey Contact Details for Admin with Id #' + response.id + ' has been Created successfully');
				this.contactForm.reset();
				this.retrieveAllAdminData();
			});
		}
	}

	onReset() {
		this.contactForm.controls['commodity'].reset();
		this.contactForm.controls['managerEmail'].reset();
		this.contactForm.controls['buyerEmail1'].reset();
		this.contactForm.controls['supplierEmailId'].reset();
		this.contactForm.controls['sources'].reset();
		this.contactForm.controls['remarks'].reset();
		this.contactForm.controls['gsdbCode'].reset();
		this.contactForm.controls['isEvidenceCollectionRequired'].reset();
		this.contactForm.controls['isResilienceQuestionnaireEnabled'].reset();
		this.isCreate = false;
		this.isEdit = false;
	}

	private validateContactDetails(newTier1EComponentShortageAdmin: Tier1EComponentShortageAdmin): boolean {
		let isContactFormValid = false;
		if ((newTier1EComponentShortageAdmin.tier1Supplier && newTier1EComponentShortageAdmin.tier1Supplier.trim() !== '') &&
			(newTier1EComponentShortageAdmin.supplierEmailId && newTier1EComponentShortageAdmin.supplierEmailId.trim() !== '')) {
			isContactFormValid = true;
			console.log('Contact Form Valid');
		}
		return isContactFormValid;
	}

	onPrevious() {
		this.router.navigate(['/tier1-e-component-shortage/false']);
	}

	onSubmit() {
		this.isEdit = false;
		this.isCreate = true;
		this.show = true;
		this.showInput = true;
		this.showDropdown = false;
		this.isReadOnly = false;
		this.onReset();
	}

	updateForm() {
		this.isEdit = true;
		this.isCreate = true;
		this.contactForm.reset();
		this.getTier1SupplierList();
		this.show = true;
		this.showInput = false;
		this.showDropdown = true;
		this.isReadOnly = false;
	}

	downloadAdminReport() {
		const tier1EComponentShortageAdmin1 = new Tier1EComponentShortageAdmin();
		// tslint:disable-next-line:max-line-length
		// tier1EComponentShortageTrackerBusiness.supplierEmailId = sessionStorage.getItem('uid');
		this.downloadServiceObj.downloadAdminDataReport(tier1EComponentShortageAdmin1);
		this.spinnerService.show();
	}

	retrieveAllAdminData() {
		this.tier1EComponentShortageAdminService.retrieveAllAdminData(this.businessName).subscribe((data) => {
			this.adminDataList = data;
			console.log('admindata', this.adminDataList);
		});
	}

	showDataFilters() {
		this.isDisplayFilterData = !this.isDisplayFilterData;
	}

	deleteSupplier(id: any) {
		this.tier1EComponentShortageAdminService.deleteSupplierById(id, this.businessName).subscribe((data) => {
			this.adminDataList = data;
			this.messageService.clear();
			this.messageService.add({
				severity: 'success',
				summary: 'Success',
				detail: 'Deleted',
				life: 60000
			});
		});
	}
}
